import React from 'react';
import Auth from '../auth/auth';
import {NAME_APP} from '../config/config';

const userData= Auth.getUser();
const userDataToken= Auth.getToken();
const agencyData=JSON.parse(localStorage.getItem('pos'));

const organizations=Auth.getOrganizations();
const UserDataTokenContext = React.createContext(userDataToken);
const UserDataContext = React.createContext(userData);

const PosDataContext = React.createContext(agencyData);


const PosDataProvider = (props) =>{
    const [posActive,setPosActive] = React.useState();
    return(
    <PosDataContext.Provider value={[posActive,setPosActive]}>
        {props.children}
    </PosDataContext.Provider>);
}


const OrganizationsDataContext = React.createContext(organizations!==null  ? organizations[0] : []);

const OrganizationsDataProvider = (props) =>{
    const [org,setOrg] = React.useState();
    return(
    <OrganizationsDataContext.Provider value={[org,setOrg]}>
        {props.children}
    </OrganizationsDataContext.Provider>);
}


const UserDataProvider = (props) =>{
    const [user,setUser] = React.useState(userData);
    return(
    <UserDataContext.Provider value={[user,setUser]}>
        {props.children}
    </UserDataContext.Provider>);
}

export const QUOTIAMO = 1;    

//const currentRoute= window.location.hostname; 

const id_cliente = QUOTIAMO;
    
    

    
    
export const infoClients =[
        { 
            id_cliente:QUOTIAMO,
            nome_cliente: NAME_APP,
            primary_color: '#777777',                
            appbar_color: '#ffffff',                
            text_color: '#dddddd',                
            favicon: "/images/favicon.ico",         
            logo:    "/images/logo.png",                           
            is_default: true            
        }
    ];
    
export function ReturnInfoClient(id_cliente,param){
        
        let infoClient= infoClients.find((element) => {
              return element.id_cliente === id_cliente;
            });
        if(!infoClient){
            infoClient= infoClients.find((element) => {
                return element.is_default === true;
              });
        }
        return infoClient[param];
          
}

export {UserDataContext,UserDataTokenContext,UserDataProvider,id_cliente,OrganizationsDataProvider,OrganizationsDataContext,PosDataProvider,PosDataContext};


