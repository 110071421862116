import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BackGround from './background';
import MaterialTable from 'material-table';
import { id_cliente, ReturnInfoClient } from '../containers/logincontext';
import { API_URL_AGENCY_USERS, API_URL_USERS } from '../config/config';
//import UserDetailPanel from './userdetailpanel';
import { green, red } from '@material-ui/core/colors';
import Auth from '../auth/auth';
import UserAgencyDetailPanel from './useragencydetailpanel';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import Tooltip from '@material-ui/core/Tooltip';
import BlockIcon from '@material-ui/icons/Block';
import MuiAlert from '@material-ui/lab/Alert';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    red: {
        color: red[500]
    },
    green: {
        color: green[500]
    },
}));

export default function UsersListsByAgencies(props) {
    const tableRef = React.createRef();
    const [loading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [alertseverity, setAlertSeverity] = React.useState("success");
    const [alertmsg, setAlertMsg] = React.useState(false);
    const classes = useStyles();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <main style={{ width: "100%" }}>
            <BackGround open={loading} />
            <MaterialTable
                tableRef={tableRef}
                title="Utenti"
                columns={[

                    { title: 'Cognome', field: 'surname' },
                    { title: 'Nome', field: 'name' },
                    {
                        title: 'Email',
                        field: 'email',
                        editable: 'onAdd'
                    },
                    { title: 'Telefono', field: 'phone' },
                    {
                        title: 'Livello',
                        field: 'level',
                        editable: 'never',
                        lookup: {
                            0: 'Disabilitato',
                            1: 'Livello 1: Può vedere solo i propri preventivi',
                            2: 'Livello 2: Può vedere tutti i preventivi fatti nelle filiali dove è abilitato',
                            3: 'Livello 3: Può vedere tutti i preventivi del gruppo di appartenenza',
                            4: 'Livello 4: Può vedere tutti i preventivi delle proprie organizzazioni',
                            5: 'Livello 5',
                            6: 'Livello 6',
                            7: 'Livello 7',
                            8: 'Admin: Può vedere tutti i preventivi e modificare tutti i parametri delle proprie organizzazioni',
                            9: 'Admin Test: Può vedere tutti i preventivi e modificare tutti i parametri delle proprie organizzazioni, inoltre può effettuare test su nuovi prodotti',
                            10: 'Super Admin: Può vedere tutti i preventivi e modificare tutti i parametri',
                        },
                        render: rowData => <div>{rowData.level === 10 ? "Super Admin" :
                            rowData.level === 9 ? "Admin Test" :
                                rowData.level === 8 ? "Admin" :
                                    (rowData.level === 0 || rowData.level === null) ? "Disabilitato" :
                                        "Livello " + rowData.level}
                        </div>
                    },
                    {
                        title: "Abilitato all'emissione",
                        field: 'emission_block',
                        lookup: { 1: 'Bloccato', 0: 'Abilitato' },
                        render: rowData => <Tooltip title={rowData.emission_block === 1 ? "Bloccato" : "Abilitato"}>
                            {rowData.emission_block === 1 ? <BlockIcon className={classes.red} /> : <CheckCircleOutlineIcon className={classes.green} />}
                        </Tooltip>
                    },
                ]}

                detailPanel={[
                    {
                        tooltip: 'Permessi',
                        render: rowData => {
                            return (
                                <UserAgencyDetailPanel user={rowData.id} agency={"ag_" + props.id_agency} />
                            )
                        },
                    },
                ]
                }


                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} di {count}',
                        labelRowsSelect: 'per pagina',
                        labelRowsPerPage: 'per pagina',
                        firstAriaLabel: 'prima pagina',
                        firstTooltip: 'prima pagina',
                        previousAriaLabel: 'pagina precedente',
                        previousTooltip: 'pagina precedente',
                        nextAriaLabel: 'pagina successiva',
                        nextTooltip: 'pagina successiva',
                        lastAriaLabel: 'ultima pagina',
                        lastTooltip: 'ultima pagina',
                    },
                    toolbar: {
                        nRowsSelected: '{0} elementi selezionati',
                        searchTooltip: 'Ricerca',
                        searchPlaceholder: 'Ricerca'
                    },
                    header: {
                        actions: ''
                    },
                    body: {
                        emptyDataSourceMessage: 'Nessun record da visualizzare',
                        filterRow: {
                            filterTooltip: 'Filtri'
                        },
                        editRow: {
                            saveTooltip: "Conferma",
                            cancelTooltip: "Annulla",
                            deleteText: "Sei sicuro di voler cancellare questo utente?"
                        },
                        addTooltip: "Aggiungi nuovo utente",
                        deleteTooltip: "Cancella",
                        editTooltip: "Modifica",

                    }
                }}
                options={{
                    pageSize: 10,
                    pageSizeOptions: [5, 10],
                    search: true,
                    paginationType: "stepped",
                    headerStyle: {
                        backgroundColor: ReturnInfoClient(id_cliente, "primary_color"),
                        color: '#ffffff',
                        '&:hover': {
                            color: '#ffffff',
                        },
                        '&:active': {
                            color: '#ffffff',
                        }
                    }

                }}
                data={query =>
                    new Promise((resolve, reject) => {
                        let url = API_URL_AGENCY_USERS + "/" + props.id_agency;
                        url += '?per_page=' + query.pageSize
                        url += '&page=' + (query.page + 1)

                        if (query.search.length > 3) {
                            url += '&search=' + query.search
                        }
                        if (query.orderBy !== undefined) {
                            url += '&orderby=' + query.orderBy.field
                            url += '&orderdirection=' + query.orderDirection
                        }

                        const requestOptions = {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + Auth.getToken(),
                            },
                        };


                        fetch(url, requestOptions)
                            .then(
                                response => response.json()
                            )
                            .then(
                                result => {
                                    resolve({
                                        data: result.data,
                                        page: result.current_page - 1,
                                        totalCount: result.total,
                                    })
                                })
                    })
                }

                editable={{
                    onRowAdd: newData =>
                        new Promise((resolve, reject) => {
                            const url = API_URL_AGENCY_USERS + "/" + props.id_agency;
                            axios.put(url, {
                                surname: newData.surname,
                                name: newData.name,
                                email: newData.email,
                                phone: newData.phone,
                                emission_block: newData.emission_block,
                            },
                                {
                                    headers: {
                                        'Authorization': 'Bearer ' + Auth.getToken(),
                                        'Content-Type': 'application/json'
                                    }
                                }
                            ).then(payload => {
                                setAlertMsg(payload.data.message);
                                setAlertSeverity("success");
                                setOpen(true);
                            });
                            resolve();
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            const index = oldData.id;
                            const url = API_URL_USERS + "/" + index;
                            axios.patch(url, {
                                surname: newData.surname,
                                name: newData.name,
                                //emission_block: newData.emission_block,                                        
                                phone: newData.phone,
                            },
                                {
                                    headers: {
                                        'Authorization': 'Bearer ' + Auth.getToken(),
                                        'Content-Type': 'application/json'
                                    }
                                }
                            ).then(payload => {
                                const urlAg = API_URL_AGENCY_USERS + "/" + props.id_agency;
                                axios.put(urlAg, {
                                    surname: newData.surname,
                                    name: newData.name,
                                    email: newData.email,
                                    phone: newData.phone,
                                    emission_block: newData.emission_block,
                                },
                                    {
                                        headers: {
                                            'Authorization': 'Bearer ' + Auth.getToken(),
                                            'Content-Type': 'application/json'
                                        }
                                    }
                                ).then(payload => {
                                    setAlertMsg(payload.data.message);
                                    setAlertSeverity("success");
                                    setOpen(true);
                                });
                                resolve();

                                /*setAlertMsg(payload.data.message);
                                setAlertSeverity("success");
                                setOpen(true);*/
                            });
                            //resolve();

                        }),
                    onRowDelete:

                        oldData =>
                            new Promise((resolve, reject) => {

                                const index = oldData.id;

                                const url = API_URL_AGENCY_USERS + "/" + props.id_agency + "/" + index;

                                axios.delete(url,
                                    {
                                        headers: {
                                            'Authorization': 'Bearer ' + Auth.getToken(),
                                            'Content-Type': 'application/json'
                                        }
                                    }
                                ).then(payload => {
                                    setAlertMsg(payload.data.message);
                                    setAlertSeverity("success");
                                    setOpen(true);
                                });
                                resolve();
                            })
                }
                }
                actions={[
                    {
                        icon: 'refresh',
                        tooltip: 'Aggiorna dati',
                        isFreeAction: true,
                        onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                    }
                ]}
            />

            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={alertseverity} closeText="Chiudi">
                    {alertmsg}
                </Alert>
            </Snackbar>
        </main>
    );
};

