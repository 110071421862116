import React from 'react';
import axios from 'axios';
import { API_URL_CONVENTIONS, API_URL_ORGANIZATIONS_LIST } from '../config/config';
import { makeStyles } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';
import { Card, CardContent, Accordion, AccordionSummary, FormControlLabel, Checkbox, Box, Typography, Button, CardActions, Snackbar } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import MuiAlert from '@material-ui/lab/Alert';
import Auth from '../auth/auth';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    alignFab: {
        marginLeft: 'auto',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    img: {
        display: 'block',
        maxWidth: '100%',
        maxHeight: '40px',
        minWidth: '50px',
    },
    img_service: {
        display: 'block',
        maxWidth: '40px',
        maxHeight: '20px',
    },
    red: {
        color: red[500],
    },
    green: {
        color: green[500],
    },
    hiddenDiv: {
        display: 'none'
    }
}));

export default function ConventionsOrganizationsDetailPanel(props) {
    const classes = useStyles();
    const [savestate, setsavestate] = React.useState(true);
    const [savenum, setsavenum] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const [alertseverity, setAlertSeverity] = React.useState("success");
    const [alertmsg, setAlertMsg] = React.useState(false);
    const [organizationsEnabled, setOrganizationsEnabled] = React.useState(null);
    const [allOrganizations, setAllOrganizations] = React.useState(null);

    React.useEffect(() => {
        if (props.id_convention) {
            const urlAllOrg = API_URL_ORGANIZATIONS_LIST;
            axios.get(urlAllOrg,
                {
                    headers: {
                        'Authorization': 'Bearer ' + Auth.getToken(),
                        'Content-Type': 'application/json'
                    }
                }
            ).then(payload => {
                setAllOrganizations(payload.data.organizations);
            });

            const url = API_URL_CONVENTIONS + "/organizationsenabled/" + props.id_convention;
            axios.get(url,
                {
                    headers: {
                        'Authorization': 'Bearer ' + Auth.getToken(),
                        'Content-Type': 'application/json'
                    }
                }
            ).then(payload => {
                setOrganizationsEnabled(payload.data.conventions_organizations);
            });
        }
    }, [props.id_convention]);

    const handleChange = (id) => (event) => {
        let newOrgEnabled = organizationsEnabled;
        let checked = false;

        newOrgEnabled.map((single, index) => {
            if (id === single.id_organization) {
                if (event.target.checked) {
                    newOrgEnabled[index].enabled = 1;
                } else {
                    newOrgEnabled[index].enabled = 0;
                }
                checked = true;
            }
            return false;
        }
        );

        if (checked === false) {
            let newEnabled = JSON.parse("{}");
            newEnabled.id = 0;
            newEnabled.id_organization = id;
            newEnabled.id_convention = props.id_convention;
            if (event.target.checked) {
                newEnabled.enabled = 1;
            } else {
                newEnabled.enabled = 0;
            }
            newOrgEnabled.push(newEnabled);
        }

        setOrganizationsEnabled(newOrgEnabled);
        setsavestate(false);
        setsavenum(savenum + 1);
    }

    const handleSave = () => {
        new Promise((resolve, reject) => {
            //const myObjStr = JSON.stringify(organizationsEnabled);
            const url = API_URL_CONVENTIONS + "/organizations/save";

            axios.put(url, {
                list: organizationsEnabled,
            },
                {
                    headers: {
                        'Authorization': 'Bearer ' + Auth.getToken(),
                        'Content-Type': 'application/json'
                    }
                }
            ).then(payload => {
                if (payload.status === 200) {
                    setAlertMsg("Salvataggio dati avvenuto con successo");
                    setAlertSeverity("success");
                    setsavestate(true);
                } else {
                    setAlertMsg("Errore durante il salvataggio dei dati");
                    setAlertSeverity("error");
                }
                setOpen(true);
            }).catch((error) => {
                setAlertMsg(error.response.data.error);
                setAlertSeverity("error");
                setOpen(true);
            });
            resolve();
        })
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <>
            {allOrganizations !== null && organizationsEnabled !== null &&
                <Card className={classes.root} >
                    <CardContent>
                        {allOrganizations.map((single, index) => (
                            <Accordion key={index}>
                                <AccordionSummary
                                    aria-label="Expand"
                                    aria-controls="additional-actions1-content"
                                    id="additional-actions1-header"
                                >
                                    <FormControlLabel
                                        onClick={(event) => event.stopPropagation()}
                                        onFocus={(event) => event.stopPropagation()}
                                        control={<Checkbox name="organization" onChange={handleChange(single.id)} checked={organizationsEnabled.find((orgEnabled) => single.id === orgEnabled.id_organization) === undefined ? false : organizationsEnabled.find((orgEnabled) => single.id === orgEnabled.id_organization).enabled === 1} />}
                                        label={
                                            <Box>
                                                <Typography>
                                                    {single.description}
                                                </Typography>
                                            </Box>
                                        }
                                    />
                                </AccordionSummary>
                            </Accordion>
                        ))}
                        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                            <Alert onClose={handleClose} severity={alertseverity}>
                                {alertmsg}
                            </Alert>
                        </Snackbar>
                    </CardContent>
                    <CardActions>
                        <div className={classes.hiddenDiv}>({savenum})</div>
                        <Button disabled={savestate} onClick={handleSave} startIcon={<SaveIcon />} size="small">Salva</Button>
                    </CardActions>
                </Card>
            }
        </>
    );
}