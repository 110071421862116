import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { green, red } from '@material-ui/core/colors';
import { API_URL_AGENCIES, API_URL_ORGANIZATIONS, API_URL_ORGANIZATION, API_URL_GROUPS, API_URL_ALLGUARANTEES } from '../config/config';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Auth from '../auth/auth';
import TableHead from '@material-ui/core/TableHead';
import {
    kmAnnuiOption, drivingLicenseTypes, tipoGuida, statoCivile, livelliIstruzione, etaGuidatorePiuGiovane, ElencoMassimali, tipoFrazionamento,
    ElencoMassimaliAutocarri
} from '../config/config';
import {
    durationsList, classeCostruttivaList, tipologiaCostruzioneList, TipoDimoraList, PossessoList, TipologiaList, PianoList, PosizioneList,
    subjectTypes, attesaPrevTypes,
    professioniInjuries,
    professioniRcp,
    fasciaFatturatoRcp,
    massimaliRcp,
    franchigiaFrontaleGf,
    tettoGf,
    cappottoGf,
    pannelliGf,
    tipologiaCostruzioneListGf,
    impIdraulicoGf,
    impElettricoGf
} from '../config/config';
import { getProfessions, returnElement, returnElementValue } from './utils'
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import AuthService from '../auth/authservice';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    CardFull: {
        width: "100%",
    },
    alignFab: {
        marginLeft: 'auto',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    img: {
        display: 'block',
        maxWidth: '100%',
        maxHeight: '40px',
        minWidth: '50px',

    },
    img_service: {
        display: 'block',
        maxWidth: '40px',
        maxHeight: '20px',
    },
    red: {
        color: red[500],
    },
    green: {
        color: green[500],
    },
    hiddenDiv: {
        display: 'none'
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        width: "30%"
    },
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        width: "200"
    },
}));

export default function EditDefaultParameterData(props) {
    const classes = useStyles();
    const [data, setData] = React.useState(
        props.data.defaultparameter === null ? null :
            JSON.parse(props.data.defaultparameter)
    );
    const [open, setOpen] = React.useState(false);
    const [alertseverity, setAlertSeverity] = React.useState("success");
    const [alertmsg, setAlertMsg] = React.useState(false);
    const [listProfessions, setlistProfessions] = React.useState([]);
    const [guaranteesHouse, setGuaranteesHouse] = React.useState([]);
    const [guaranteesVan, setGuaranteesVan] = React.useState([]);
    const [guaranteesCar, setGuaranteesCar] = React.useState([]);
    const [guaranteesInjuries, setGuaranteesInjuries] = React.useState([]);
    const [guaranteesTcm, setGuaranteesTcm] = React.useState([]);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [guaranteesBike, setGuaranteesBike] = React.useState([]);
    const [guaranteesMotorbike, setGuaranteesMotorbike] = React.useState([]);
    const [guaranteesRcp, setGuaranteesRcp] = React.useState([]);
    const [guaranteesGf, setGuaranteesGf] = React.useState([]);

    const [numTab, setTab] = React.useState(0);

    const handleChangeTab = (event, newValue) => {
        setTab(newValue);
    };

    React.useEffect(() => {
        const url = API_URL_ALLGUARANTEES + "/enabled/house/" + props.orgid;
        axios.get(url,
            {
                headers: {
                    'Authorization': 'Bearer ' + Auth.getToken(),
                    'Content-Type': 'application/json'
                }
            }
        ).then(payload => {
            setGuaranteesHouse(payload.data.guarantees);
        });
    }, [props.orgid]);

    React.useEffect(() => {
        const url = API_URL_ALLGUARANTEES + "/enabled/injuries/" + props.orgid;
        axios.get(url,
            {
                headers: {
                    'Authorization': 'Bearer ' + Auth.getToken(),
                    'Content-Type': 'application/json'
                }
            }
        ).then(payload => {
            setGuaranteesInjuries(payload.data.guarantees);
        });
    }, [props.orgid]);

    React.useEffect(() => {
        const url = API_URL_ALLGUARANTEES + "/enabled/tcm/" + props.orgid;
        axios.get(url,
            {
                headers: {
                    'Authorization': 'Bearer ' + Auth.getToken(),
                    'Content-Type': 'application/json'
                }
            }
        ).then(payload => {
            setGuaranteesTcm(payload.data.guarantees);
        });
    }, [props.orgid]);

    React.useEffect(() => {
        const url = API_URL_ALLGUARANTEES + "/enabled/car/" + props.orgid;
        axios.get(url,
            {
                headers: {
                    'Authorization': 'Bearer ' + Auth.getToken(),
                    'Content-Type': 'application/json'
                }
            }
        ).then(payload => {
            setGuaranteesCar(payload.data.guarantees);
        });

    }, [props.orgid]);

    React.useEffect(() => {
        const url = API_URL_ALLGUARANTEES + "/enabled/van/" + props.orgid;
        axios.get(url,
            {
                headers: {
                    'Authorization': 'Bearer ' + Auth.getToken(),
                    'Content-Type': 'application/json'
                }
            }
        ).then(payload => {
            setGuaranteesVan(payload.data.guarantees);
        });

    }, [props.orgid]);

    React.useEffect(() => {
        const url = API_URL_ALLGUARANTEES + "/enabled/bike/" + props.orgid;
        axios.get(url,
            {
                headers: {
                    'Authorization': 'Bearer ' + Auth.getToken(),
                    'Content-Type': 'application/json'
                }
            }
        ).then(payload => {
            setGuaranteesBike(payload.data.guarantees);
        });

    }, [props.orgid]);

    React.useEffect(() => {
        const url = API_URL_ALLGUARANTEES + "/enabled/motorbike/" + props.orgid;
        axios.get(url,
            {
                headers: {
                    'Authorization': 'Bearer ' + Auth.getToken(),
                    'Content-Type': 'application/json'
                }
            }
        ).then(payload => {
            setGuaranteesMotorbike(payload.data.guarantees);
        });

    }, [props.orgid]);

    React.useEffect(() => {
        const url = API_URL_ALLGUARANTEES + "/enabled/rcp/" + props.orgid;
        axios.get(url,
            {
                headers: {
                    'Authorization': 'Bearer ' + Auth.getToken(),
                    'Content-Type': 'application/json'
                }
            }
        ).then(payload => {
            setGuaranteesRcp(payload.data.guarantees);
        });

    }, [props.orgid]);

    React.useEffect(() => {
        const url = API_URL_ALLGUARANTEES + "/enabled/gf/" + props.orgid;
        axios.get(url,
            {
                headers: {
                    'Authorization': 'Bearer ' + Auth.getToken(),
                    'Content-Type': 'application/json'
                }
            }
        ).then(payload => {
            setGuaranteesGf(payload.data.guarantees);
        });

    }, [props.orgid]);

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    React.useEffect(() => {
        axios.get(API_URL_ORGANIZATION + "/" + props.orgid,
            {
                headers: {
                    'Authorization': 'Bearer ' + Auth.getToken(),
                    'Content-Type': 'application/json'
                }
            }
        ).then(payload => {
            let orgInfo = payload.data[0];

            AuthService.signin(orgInfo.username, orgInfo.password, orgInfo.url).then(
                payload => {
                    let token = payload;
                    getProfessions(token, orgInfo.url).then(payload => {
                        setlistProfessions(payload.data);
                    });
                }).catch(error => {
                });
        });
    }, [props]);

    const handleChangeValue = (event) => {
        setData({
            ...data,
            [event.target.name]: event.target.value
        });
    };

    const handleChangeChecked = (checkName) => (event) => {
        if (event.target.checked === false) {
            let resetvalue = "";
            if (checkName === "youngestLicensedAge") {
                resetvalue = "0"
            } else if (checkName === "maximal") {
                resetvalue = 0;
            } else if (checkName === "splittingUp") {
                resetvalue = 0;
            } else if (checkName === "guideType") {
                resetvalue = "0";
            } else {
                resetvalue = "";
            }

            setData({
                ...data,
                [checkName]: resetvalue
            });
        }

    };

    const handleChangeCheckedAll = (event) => {
        if (event.target.checked === false) {
            setOpenDialog(true)
        }
    };

    const ConfirmDeselect = () => {
        setOpenDialog(false)
        setData(null);
    };

    const handleBitChecked = (bitValue, checkName) => (event) => {
        let temp = 0;

        if (data === null || data[checkName] === undefined) {
            temp = 0;
        } else {
            temp = data[checkName];
        }

        if (event.target.checked === false) {
            setData({
                ...data,
                [checkName]: temp ^ bitValue
            });
        } else {
            setData({
                ...data,
                [checkName]: temp ^ bitValue
            });
        }
    };

    const handleSave = () => {
        new Promise((resolve, reject) => {
            const index = props.data.id;

            let url;
            if (props.type === "org") {
                url = API_URL_ORGANIZATIONS + "/" + index;
            } else if (props.type === "grp") {
                url = API_URL_GROUPS + "/" + index;
            }
            else {
                url = API_URL_AGENCIES + "/" + index;
            }

            axios.patch(url, {
                ...props.data,
                defaultparameter: data === null ? null : JSON.stringify(data),
            },
                {
                    headers: {
                        'Authorization': 'Bearer ' + Auth.getToken(),
                        'Content-Type': 'application/json'
                    }
                }
            ).then(payload => {
                if (payload.status === 200) {
                    setAlertMsg("Aggiornamento avvenuto con successo");
                    setAlertSeverity("success");
                } else {
                    setAlertMsg("Errore durante l'aggiornamento");
                    setAlertSeverity("error");
                }
                setOpen(true);
            }).catch((error) => {
                setAlertMsg(error.response.data.error);
                setAlertSeverity("error");
                setOpen(true);
            });

            resolve();
        })
    }

    return (
        <Card className={classes.CardFull}>
            <CardContent>
                <Dialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Confermi eliminazione parametri di default"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Premendo il pulsante conferma, i parametri di default verranno eliminati.
                            L'operazione andrà poi confermata premendo il tasto salva.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog} color="primary">
                            Annulla
                        </Button>
                        <Button onClick={ConfirmDeselect} color="primary" autoFocus>
                            Conferma
                        </Button>
                    </DialogActions>
                </Dialog>

                <FormControlLabel
                    control={<GreenCheckbox checked={data !== null} onChange={handleChangeCheckedAll} name="checkedG" />}
                    label="Utilizza parametri di default per la quotazione"
                />

                <div className={classes.root}>
                    <Tabs value={numTab} onChange={handleChangeTab} orientation="vertical" variant="scrollable" className={classes.tabs}>
                        <Tab label="Auto" {...a11yProps(0)} />
                        <Tab label="Autocarro" {...a11yProps(1)} />
                        <Tab label="Ciclomotore" {...a11yProps(2)} />
                        <Tab label="Motociclo" {...a11yProps(3)} />
                        <Tab label="Casa" {...a11yProps(4)} />
                        <Tab label="Infortuni" {...a11yProps(5)} />
                        <Tab label="TCM" {...a11yProps(6)} />
                        <Tab label="RCP" {...a11yProps(7)} />
                        <Tab label="Globale Fabbricati" {...a11yProps(8)} />
                    </Tabs>

                    {/* AUTO */}
                    <TabPanel value={numTab} index={0}>
                        <TableContainer className={classes.table} component={Paper}>
                            <Table aria-label="Parametri default auto">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Imposta default</TableCell>
                                        <TableCell>Parametro</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.auto_cvt_comp !== undefined && data.auto_cvt_comp !== ""
                                                }
                                                onChange={handleChangeChecked("auto_cvt_comp")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Opzioni per la scelta delle CVT da utilizzare"
                                                name="auto_cvt_comp"
                                                onChange={handleChangeValue}
                                                select
                                                SelectProps={{ native: true }}
                                                value={data === null ? "yes" : data.auto_cvt_comp === undefined ? "yes" : data.auto_cvt_comp}
                                                variant="outlined"
                                            >
                                                <option key="cvtCompNo" value={"no"}>
                                                    Sempre CVT di compagnie selezionate
                                                </option>
                                                <option key="cvtCompYes" value={"yes"}>
                                                    Sempre CVT delle compagnie
                                                </option>
                                                <option key="cvtCompBoth" value={"both"}>
                                                    Entrambe, possibilità di scelta (default Sempre CVT delle compagnie)
                                                </option>
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.attesaPrevCar !== undefined && data.attesaPrevCar !== ""
                                                }
                                                onChange={handleChangeChecked("attesaPrevCar")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Autocomplete
                                                options={attesaPrevTypes}
                                                id="attesaPrevCar"
                                                label="Metodo di attesa dei preventivi"
                                                name="attesaPrevCar"
                                                disableClearable
                                                getOptionLabel={(option) => option.description}
                                                value={data === null ? "waiting" : data.attesaPrevCar === undefined ? "waiting" : returnElement(attesaPrevTypes, data.attesaPrevCar)}
                                                autoHighlight
                                                onChange={(event, newValue) => {
                                                    setData({
                                                        ...data,
                                                        attesaPrevCar: newValue.id,
                                                    });
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Metodo di attesa dei preventivi" margin="normal" variant="outlined" />}
                                            />

                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.profession !== undefined && data.profession !== ""
                                                }
                                                onChange={handleChangeChecked("profession")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Autocomplete
                                                options={listProfessions}
                                                id="profession"
                                                label="Professione"
                                                name="profession"
                                                disableClearable
                                                getOptionLabel={(option) => option.description}
                                                autoHighlight
                                                value={data === null || data.profession === undefined ? "" : returnElement(listProfessions, data.profession)}
                                                onChange={(event, newValue) => {
                                                    setData({
                                                        ...data,
                                                        profession: newValue.id,
                                                    });
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Professione" margin="normal" variant="outlined" />}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.qualification !== undefined && data.qualification !== ""
                                                }
                                                onChange={handleChangeChecked("qualification")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>

                                        <TableCell>
                                            <Autocomplete
                                                options={livelliIstruzione}
                                                id="qualification"
                                                label="Livello di istruzione"
                                                name="qualification"
                                                disableClearable
                                                getOptionLabel={(option) => option.description}
                                                autoHighlight

                                                value={data === null || data.qualification === undefined ? "" : returnElement(livelliIstruzione, data.qualification)}
                                                onChange={(event, newValue) => {
                                                    setData({
                                                        ...data,
                                                        qualification: newValue.id,
                                                    });
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Livello di istruzione" margin="normal" variant="outlined" />}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.maritalStatus !== undefined && data.maritalStatus !== ""
                                                }
                                                onChange={handleChangeChecked("maritalStatus")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Autocomplete
                                                options={statoCivile}
                                                id="maritalStatus"
                                                label="Stato civile"
                                                name="maritalStatus"
                                                disableClearable
                                                getOptionLabel={(option) => option.description}
                                                autoHighlight
                                                value={data === null || data.maritalStatus === undefined ? "" : returnElement(statoCivile, data.maritalStatus)}
                                                onChange={(event, newValue) => {
                                                    setData({
                                                        ...data,
                                                        maritalStatus: newValue.id,
                                                    });
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Stato civile" margin="normal" variant="outlined" />}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.sons !== undefined && data.sons !== ""
                                                }
                                                onChange={handleChangeChecked("sons")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Hai figli?"
                                                name="sons"
                                                onChange={handleChangeValue}
                                                select
                                                SelectProps={{ native: true }}
                                                value={data === null ? "" : data.sons}
                                                variant="outlined"
                                            >
                                                <option key="sonsNothing" value="">

                                                </option>
                                                <option key="sonsNo" value={false}>
                                                    NO
                                                </option>
                                                <option key="sonsYes" value={true}>
                                                    SI
                                                </option>
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.toponym !== undefined && data.toponym !== ""
                                                }
                                                onChange={handleChangeChecked("toponym")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="toponym"
                                                label="Via,piazza,ecc"
                                                name="toponym"
                                                value={data === null ? "" : data.toponym}
                                                onChange={handleChangeValue}

                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.address !== undefined && data.address !== ""
                                                }
                                                onChange={handleChangeChecked("address")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="address"
                                                label="Indirizzo"
                                                name="address"
                                                value={data === null ? "" : data.address}
                                                onChange={handleChangeValue}

                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.streetNumber !== undefined && data.streetNumber !== ""
                                                }
                                                onChange={handleChangeChecked("streetNumber")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"

                                                fullWidth
                                                id="streetNumber"
                                                label="Numero civico"
                                                name="streetNumber"
                                                value={data === null ? "" : data.streetNumber}
                                                onChange={handleChangeValue}

                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.email !== undefined && data.email !== ""
                                                }
                                                onChange={handleChangeChecked("email")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="email"
                                                label="Email"
                                                name="email"
                                                value={data === null ? "" : data.email}
                                                onChange={handleChangeValue}

                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.phoneNumber !== undefined && data.phoneNumber !== ""
                                                }
                                                onChange={handleChangeChecked("phoneNumber")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="phoneNumber"
                                                label="Numero di telefono"
                                                name="phoneNumber"
                                                value={data === null ? "" : data.phoneNumber}
                                                onChange={handleChangeValue}

                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.drivingLicenseType !== undefined && data.drivingLicenseType !== ""
                                                }
                                                onChange={handleChangeChecked("drivingLicenseType")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Autocomplete
                                                options={drivingLicenseTypes}
                                                id="drivingLicenseType"
                                                label="Tipo di patente"
                                                name="drivingLicenseType"
                                                disableClearable
                                                getOptionLabel={(option) => option.description}
                                                value={data === null || data.drivingLicenseType === undefined ? "" : returnElement(drivingLicenseTypes, data.drivingLicenseType)}
                                                autoHighlight

                                                onChange={(event, newValue) => {
                                                    setData({
                                                        ...data,
                                                        drivingLicenseType: newValue.id,
                                                    });
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Tipo di patente" margin="normal" variant="outlined" />}
                                            />

                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.drivingLicensePoints !== undefined && data.drivingLicensePoints !== ""
                                                }
                                                onChange={handleChangeChecked("drivingLicensePoints")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="drivingLicensePoints"
                                                label="Punti patente"
                                                name="drivingLicensePoints"
                                                value={data === null ? "" : data.drivingLicensePoints}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.kmAnnui !== undefined && data.kmAnnui !== ""
                                                }
                                                onChange={handleChangeChecked("kmAnnui")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Autocomplete
                                                options={kmAnnuiOption}
                                                id="kmAnnui"
                                                label="Km annui percorsi"
                                                name="kmAnnui"
                                                disableClearable
                                                getOptionLabel={(option) => option.description}
                                                value={data === null ? "" : returnElement(kmAnnuiOption, data.kmAnnui)}
                                                autoHighlight

                                                onChange={(event, newValue) => {
                                                    setData({
                                                        ...data,
                                                        kmAnnui: newValue.id,
                                                    });
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Km annui percorsi" margin="normal" variant="outlined" />}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.insuranceYears !== undefined && data.insuranceYears !== ""
                                                }
                                                onChange={handleChangeChecked("insuranceYears")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>

                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Da quanti anni sei assicurato?"
                                                name="insuranceYears"
                                                onChange={handleChangeValue}
                                                select
                                                SelectProps={{ native: true }}
                                                value={data === null ? "" : data.insuranceYears}
                                                variant="outlined"
                                            >
                                                <option key="noOption" value="">

                                                </option>
                                                <option key="option1" value={1}>
                                                    Calcola in base all'attestato di rischio
                                                </option>
                                                <option key="option2" value={2}>
                                                    Calcola in base alla data di conseguimento della patente
                                                </option>
                                            </TextField>


                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.effectiveDate !== undefined && data.effectiveDate !== ""
                                                }
                                                onChange={handleChangeChecked("effectiveDate")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>

                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Data decorrenza"
                                                name="effectiveDate"
                                                onChange={handleChangeValue}
                                                select
                                                SelectProps={{ native: true }}
                                                value={data === null ? "" : data.effectiveDate}
                                                variant="outlined"
                                            >
                                                <option key="noOptionDec" value="">

                                                </option>
                                                <option key="option1Dec" value={1}>
                                                    Imposta al 15 o a fine mese
                                                </option>
                                                <option key="option2Dec" value={2}>
                                                    Lascia la data di scadenza
                                                </option>
                                            </TextField>


                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.guideType !== undefined && data.guideType !== "0"
                                                }
                                                onChange={handleChangeChecked("guideType")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Tipo di guida"
                                                name="guideType"
                                                onChange={handleChangeValue}

                                                select
                                                SelectProps={{ native: true }}
                                                value={(data === null || data.guideType === undefined) ? "0" : data.guideType}
                                                variant="outlined"
                                            >
                                                {tipoGuida.map(option => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.youngestLicensedAge !== undefined && data.youngestLicensedAge !== "0"
                                                }
                                                onChange={handleChangeChecked("youngestLicensedAge")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Età del guidatore più giovane"
                                                name="youngestLicensedAge"
                                                onChange={handleChangeValue}
                                                select
                                                SelectProps={{ native: true }}
                                                value={(data === null || data.youngestLicensedAge === undefined) ? "0" : data.youngestLicensedAge}
                                                variant="outlined"
                                            >
                                                {etaGuidatorePiuGiovane.map(option => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.maximal !== undefined && data.maximal !== 0
                                                }
                                                onChange={handleChangeChecked("maximal")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>

                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Massimale"
                                                name="maximal"
                                                onChange={handleChangeValue}
                                                select
                                                SelectProps={{ native: true }}
                                                value={(data === null || data.maximal === undefined) ? 0 : data.maximal}
                                                variant="outlined"
                                            >

                                                {ElencoMassimali.map(option => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.splittingUp !== undefined && data.splittingUp !== 0
                                                }
                                                onChange={handleChangeChecked("splittingUp")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Frazionamento"
                                                name="splittingUp"
                                                onChange={handleChangeValue}
                                                select
                                                SelectProps={{ native: true }}
                                                value={(data === null || data.splittingUp === undefined) ? 0 : data.splittingUp}
                                                variant="outlined"
                                            >
                                                <option
                                                    key="NOFRAZ"
                                                    value={0}
                                                >
                                                    Seleziona il frazionamento
                                                </option>
                                                {tipoFrazionamento.map(option => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.auto_data_imm !== undefined && data.auto_data_imm !== ""
                                                }
                                                onChange={handleChangeChecked("auto_data_imm")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Valore utilizzato per la data di immatricolazione"
                                                name="auto_data_imm"
                                                onChange={handleChangeValue}
                                                select
                                                SelectProps={{ native: true }}
                                                value={data === null ? "ania" : data.auto_data_imm === undefined ? "ania" : data.auto_data_imm}
                                                variant="outlined"
                                            >
                                                <option key="dataImm1" value={"ania"}>
                                                    Recuperato da ANIA
                                                </option>
                                                <option key="dataImm2" value={"vuoto"}>
                                                    Vuoto, da inserire
                                                </option>
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.auto_data_acq !== undefined && data.auto_data_acq !== ""
                                                }
                                                onChange={handleChangeChecked("auto_data_acq")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Valore utilizzato per la data di acquisto"
                                                name="auto_data_acq"
                                                onChange={handleChangeValue}
                                                select
                                                SelectProps={{ native: true }}
                                                value={data === null ? "ania" : data.auto_data_acq === undefined ? "ania" : data.auto_data_acq}
                                                variant="outlined"
                                            >
                                                <option key="dataAcq1" value={"ania"}>
                                                    Recuperato da ANIA
                                                </option>
                                                <option key="dataAcq2" value={"vuoto"}>
                                                    Vuoto, da inserire
                                                </option>
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.auto_alimentazione !== undefined && data.auto_alimentazione !== ""
                                                }
                                                onChange={handleChangeChecked("auto_alimentazione")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Valore utilizzato per l'alimentazione"
                                                name="auto_alimentazione"
                                                onChange={handleChangeValue}
                                                select
                                                SelectProps={{ native: true }}
                                                value={data === null ? "ania" : data.auto_alimentazione === undefined ? "ania" : data.auto_alimentazione}
                                                variant="outlined"
                                            >
                                                <option key="alim1" value={"ania"}>
                                                    Recuperato da ANIA
                                                </option>
                                                <option key="alim2" value={"vuoto"}>
                                                    Vuoto, da inserire
                                                </option>
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <TableContainer className={classes.table} component={Paper}>
                            <Table aria-label="CVT">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Garanzia</TableCell>
                                        <TableCell>Selezionato</TableCell>
                                        <TableCell>Obbligatorio</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {guaranteesCar.map((warranty, index) => (
                                        <>
                                            {warranty.code !== "rca" &&
                                                <TableRow>
                                                    <TableCell>
                                                        {warranty.description}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Checkbox
                                                            checked={
                                                                data !== null && data[warranty.code] !== undefined && data[warranty.code] & 1
                                                            }
                                                            onChange={handleBitChecked(1, warranty.code)}
                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Checkbox
                                                            checked={
                                                                data !== null && data[warranty.code] !== undefined && data[warranty.code] & 2
                                                            }
                                                            onChange={handleBitChecked(2, warranty.code)}
                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            }
                                        </>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TabPanel>

                    {/* AUTOCARRO */}
                    <TabPanel value={numTab} index={1}>
                        <TableContainer className={classes.table} component={Paper}>
                            <Table aria-label="Parametri default Autocarro">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Imposta default</TableCell>
                                        <TableCell>Parametro</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.van_cvt_comp !== undefined && data.van_cvt_comp !== ""
                                                }
                                                onChange={handleChangeChecked("van_cvt_comp")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Utilizzare le CVT di compagnia? Se si seleziona NO verrano richiamate le apposite COMPAGNIE configurate"
                                                name="van_cvt_comp"
                                                onChange={handleChangeValue}
                                                select
                                                SelectProps={{ native: true }}
                                                value={data === null ? "yes" : data.van_cvt_comp === undefined ? "yes" : data.van_cvt_comp}
                                                variant="outlined"
                                            >
                                                <option key="cvtCompNo" value={"no"}>
                                                    Sempre CVT di compagnie selezionate
                                                </option>
                                                <option key="cvtCompYes" value={"yes"}>
                                                    Sempre CVT delle compagnie
                                                </option>
                                                <option key="cvtCompBoth" value={"both"}>
                                                    Entrambe, possibilità di scelta (default Sempre CVT delle compagnie)
                                                </option>
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.attesaPrevVan !== undefined && data.attesaPrevVan !== ""
                                                }
                                                onChange={handleChangeChecked("attesaPrevVan")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Autocomplete
                                                options={attesaPrevTypes}
                                                id="attesaPrevVan"
                                                label="Metodo di attesa dei preventivi"
                                                name="attesaPrevVan"
                                                disableClearable
                                                getOptionLabel={(option) => option.description}
                                                value={data === null ? "waiting" : data.attesaPrevVan === undefined ? "waiting" : returnElement(attesaPrevTypes, data.attesaPrevVan)}
                                                autoHighlight
                                                onChange={(event, newValue) => {
                                                    setData({
                                                        ...data,
                                                        attesaPrevVan: newValue.id,
                                                    });
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Metodo di attesa dei preventivi" margin="normal" variant="outlined" />}
                                            />

                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.toponymVan !== undefined && data.toponymVan !== ""
                                                }
                                                onChange={handleChangeChecked("toponymVan")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="toponymVan"
                                                label="Via,piazza,ecc"
                                                name="toponymVan"
                                                value={data === null ? "" : data.toponymVan}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.addressVan !== undefined && data.addressVan !== ""
                                                }
                                                onChange={handleChangeChecked("addressVan")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="addressVan"
                                                label="Indirizzo"
                                                name="addressVan"
                                                value={data === null ? "" : data.addressVan}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.streetNumberVan !== undefined && data.streetNumberVan !== ""
                                                }
                                                onChange={handleChangeChecked("streetNumberVan")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="streetNumberVan"
                                                label="Numero civico"
                                                name="streetNumberVan"
                                                value={data === null ? "" : data.streetNumberVan}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.emailVan !== undefined && data.emailVan !== ""
                                                }
                                                onChange={handleChangeChecked("emailVan")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="emailVan"
                                                label="Email"
                                                name="emailVan"
                                                value={data === null ? "" : data.emailVan}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.phoneNumberVan !== undefined && data.phoneNumberVan !== ""
                                                }
                                                onChange={handleChangeChecked("phoneNumberVan")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="phoneNumberVan"
                                                label="Numero di telefono"
                                                name="phoneNumberVan"
                                                value={data === null ? "" : data.phoneNumberVan}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.insuranceYearsVan !== undefined && data.insuranceYearsVan !== ""
                                                }
                                                onChange={handleChangeChecked("insuranceYearsVan")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Da quanti anni sei assicurato?"
                                                name="insuranceYearsVan"
                                                onChange={handleChangeValue}
                                                select
                                                SelectProps={{ native: true }}
                                                value={data === null ? "" : data.insuranceYears === undefined ? "" : data.insuranceYears}
                                                variant="outlined"
                                            >
                                                <option key="noOption" value="">
                                                </option>
                                                <option key="option1" value={1}>
                                                    Calcola in base all'attestato di rischio
                                                </option>
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.effectiveDateVan !== undefined && data.effectiveDateVan !== ""
                                                }
                                                onChange={handleChangeChecked("effectiveDateVan")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Data decorrenza"
                                                name="effectiveDateVan"
                                                onChange={handleChangeValue}
                                                select
                                                SelectProps={{ native: true }}
                                                value={data === null ? "" : data.effectiveDateVan}
                                                variant="outlined"
                                            >
                                                <option key="noOptionDec" value="">

                                                </option>
                                                <option key="option1Dec" value={1}>
                                                    Imposta al 15 o a fine mese
                                                </option>
                                                <option key="option2Dec" value={2}>
                                                    Lascia la data di scadenza
                                                </option>
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.maximalVan !== undefined && data.maximalVan !== 0
                                                }
                                                onChange={handleChangeChecked("maximalVan")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Massimale"
                                                name="maximalVan"
                                                onChange={handleChangeValue}
                                                select
                                                SelectProps={{ native: true }}
                                                value={(data === null || data.maximalVan === undefined) ? 0 : data.maximalVan}
                                                variant="outlined"
                                            >
                                                {ElencoMassimaliAutocarri.map(option => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.splittingUpVan !== undefined && data.splittingUpVan !== 0
                                                }
                                                onChange={handleChangeChecked("splittingUpVan")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>

                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Frazionamento"
                                                name="splittingUpVan"
                                                onChange={handleChangeValue}
                                                select
                                                SelectProps={{ native: true }}
                                                value={(data === null || data.splittingUpVan === undefined) ? 0 : data.splittingUpVan}
                                                variant="outlined"
                                            >
                                                <option
                                                    key="NOFRAZ"
                                                    value={0}
                                                >
                                                    Seleziona il frazionamento
                                                </option>
                                                {tipoFrazionamento.map(option => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <TableContainer className={classes.table} component={Paper}>
                            <Table aria-label="CVT">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Garanzia</TableCell>
                                        <TableCell>Selezionato</TableCell>
                                        <TableCell>Obbligatorio</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {guaranteesVan.map((warranty, index) => (
                                        <>
                                            <TableRow>
                                                <TableCell>
                                                    {warranty.description}
                                                </TableCell>
                                                <TableCell>
                                                    <Checkbox
                                                        checked={
                                                            data !== null && data["VAN_" + warranty.code] !== undefined && data["VAN_" + warranty.code] & 1
                                                        }
                                                        onChange={handleBitChecked(1, "VAN_" + warranty.code)}
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Checkbox
                                                        checked={
                                                            data !== null && data["VAN_" + warranty.code] !== undefined && data["VAN_" + warranty.code] & 2
                                                        }
                                                        onChange={handleBitChecked(2, "VAN_" + warranty.code)}
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TabPanel>

                    {/* CASA */}
                    <TabPanel value={numTab} index={4}>
                        <TableContainer className={classes.table} component={Paper}>
                            <Table aria-label="Parametri default casa">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Imposta default</TableCell>
                                        <TableCell>Parametro</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.house_cvt_comp !== undefined && data.house_cvt_comp !== ""
                                                }
                                                onChange={handleChangeChecked("house_cvt_comp")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Utilizzare le CVT di compagnia? Se si seleziona NO verrano richiamate le apposite COMPAGNIE configurate"
                                                name="house_cvt_comp"
                                                onChange={handleChangeValue}
                                                select
                                                SelectProps={{ native: true }}
                                                value={data === null ? "yes" : data.house_cvt_comp === undefined ? "yes" : data.house_cvt_comp}
                                                variant="outlined"
                                            >
                                                <option key="cvtCompNo" value={"no"}>
                                                    Sempre CVT di compagnie selezionate
                                                </option>
                                                <option key="cvtCompYes" value={"yes"}>
                                                    Sempre CVT delle compagnie
                                                </option>
                                                <option key="cvtCompBoth" value={"both"}>
                                                    Entrambe, possibilità di scelta (default Sempre CVT delle compagnie)
                                                </option>
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.attesaPrevHouse !== undefined && data.attesaPrevHouse !== ""
                                                }
                                                onChange={handleChangeChecked("attesaPrevHouse")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Autocomplete
                                                options={attesaPrevTypes}
                                                id="attesaPrevHouse"
                                                label="Metodo di attesa dei preventivi"
                                                name="attesaPrevHouse"
                                                disableClearable
                                                getOptionLabel={(option) => option.description}
                                                value={data === null ? "waiting" : data.attesaPrevHouse === undefined ? "waiting" : returnElement(attesaPrevTypes, data.attesaPrevHouse)}
                                                autoHighlight
                                                onChange={(event, newValue) => {
                                                    setData({
                                                        ...data,
                                                        attesaPrevHouse: newValue.id,
                                                    });
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Metodo di attesa dei preventivi" margin="normal" variant="outlined" />}
                                            />

                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.durataAnni !== undefined && data.durataAnni !== "0"
                                                }
                                                onChange={handleChangeChecked("durataAnni")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Durata Anni"
                                                name="durataAnni"
                                                onChange={handleChangeValue}
                                                select
                                                SelectProps={{ native: true }}
                                                value={(data === null || data.durataAnni === undefined) ? "" : data.durataAnni}
                                                variant="outlined"
                                            >
                                                <option
                                                    value={0}
                                                >
                                                    Seleziona la durata
                                                </option>
                                                {durationsList.map(option => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.frazionamento !== undefined && data.frazionamento !== 0
                                                }
                                                onChange={handleChangeChecked("frazionamento")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>

                                        <TableCell>

                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Frazionamento"
                                                name="frazionamento"
                                                onChange={handleChangeValue}
                                                select
                                                SelectProps={{ native: true }}
                                                value={(data === null || data.frazionamento === undefined) ? 0 : data.frazionamento}
                                                variant="outlined"
                                            >
                                                <option
                                                    key="NOFRAZ"
                                                    value={0}
                                                >
                                                    Seleziona il frazionamento
                                                </option>
                                                {tipoFrazionamento.map(option => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.toponimo !== undefined && data.toponimo !== ""
                                                }
                                                onChange={handleChangeChecked("toponimo")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="toponimo"
                                                label="Via,piazza,ecc"
                                                name="toponimo"
                                                value={data === null ? "" : data.toponimo}
                                                onChange={handleChangeValue}

                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.indirizzo !== undefined && data.indirizzo !== ""
                                                }
                                                onChange={handleChangeChecked("indirizzo")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="indirizzo"
                                                label="Indirizzo"
                                                name="indirizzo"
                                                value={data === null ? "" : data.indirizzo}
                                                onChange={handleChangeValue}

                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.numeroCivico !== undefined && data.numeroCivico !== ""
                                                }
                                                onChange={handleChangeChecked("numeroCivico")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"

                                                fullWidth
                                                id="numeroCivico"
                                                label="Numero civico"
                                                name="numeroCivico"
                                                value={data === null ? "" : data.numeroCivico}
                                                onChange={handleChangeValue}

                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.classeCostruttiva !== undefined && data.classeCostruttiva !== ""
                                                }
                                                onChange={handleChangeChecked("classeCostruttiva")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Classe Costruttiva"
                                                name="classeCostruttiva"
                                                onChange={handleChangeValue}
                                                select
                                                SelectProps={{ native: true }}
                                                value={(data === null || data.classeCostruttiva === undefined) ? 0 : data.classeCostruttiva}
                                                variant="outlined"
                                            >
                                                <option
                                                    value={0}
                                                >
                                                    Seleziona la classe
                                                </option>
                                                {classeCostruttivaList.map(option => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.tipologiaCostruzione !== undefined && data.tipologiaCostruzione !== ""
                                                }
                                                onChange={handleChangeChecked("tipologiaCostruzione")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Tipologia costruzione"
                                                name="tipologiaCostruzione"
                                                onChange={handleChangeValue}
                                                select
                                                SelectProps={{ native: true }}
                                                value={(data === null || data.tipologiaCostruzione === undefined) ? 0 : data.tipologiaCostruzione}
                                                variant="outlined"
                                            >
                                                <option
                                                    value={0}
                                                >
                                                    Seleziona la classe
                                                </option>
                                                {tipologiaCostruzioneList.map(option => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.scalaInterno !== undefined && data.scalaInterno !== ""
                                                }
                                                onChange={handleChangeChecked("scalaInterno")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="scalaInterno"
                                                label="Scala Interno"
                                                name="scalaInterno"
                                                value={data === null ? "" : data.scalaInterno}
                                                onChange={handleChangeValue}

                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.annoCostruzioneRistrutturazione !== undefined && data.annoCostruzioneRistrutturazione !== ""
                                                }
                                                onChange={handleChangeChecked("annoCostruzioneRistrutturazione")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="annoCostruzioneRistrutturazione"
                                                label="Anno di costruzione/ristrutturazione"
                                                name="annoCostruzioneRistrutturazione"
                                                value={data === null ? "" : data.annoCostruzioneRistrutturazione}
                                                onChange={handleChangeValue}

                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.superficieM2 !== undefined && data.superficieM2 !== ""
                                                }
                                                onChange={handleChangeChecked("superficieM2")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="superficieM2"
                                                label="Superficie Metri quadri"
                                                name="superficieM2"
                                                value={data === null ? "" : data.superficieM2}
                                                onChange={handleChangeValue}

                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.tipologiaProp !== undefined && data.tipologiaProp !== ""
                                                }
                                                onChange={handleChangeChecked("tipologiaProp")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Relazione con l'assicurato"
                                                name="tipologiaProp"
                                                onChange={handleChangeValue}
                                                select
                                                SelectProps={{ native: true }}
                                                value={(data === null || data.tipologiaProp === undefined) ? 0 : data.tipologiaProp}
                                                variant="outlined"
                                            >
                                                <option
                                                    value={0}
                                                >
                                                    Seleziona
                                                </option>
                                                {PossessoList.map(option => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.piano !== undefined && data.piano !== ""
                                                }
                                                onChange={handleChangeChecked("piano")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Piano"
                                                name="piano"
                                                onChange={handleChangeValue}
                                                select
                                                SelectProps={{ native: true }}
                                                value={(data === null || data.piano === undefined) ? 0 : data.piano}
                                                variant="outlined"
                                            >
                                                <option
                                                    value={0}
                                                >
                                                    Seleziona
                                                </option>
                                                {PianoList.map(option => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.tipologiaImm !== undefined && data.tipologiaImm !== ""
                                                }
                                                onChange={handleChangeChecked("tipologiaImm")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Tipologia di immobile"
                                                name="tipologiaImm"
                                                onChange={handleChangeValue}
                                                select
                                                SelectProps={{ native: true }}
                                                value={(data === null || data.tipologiaImm === undefined) ? 0 : data.tipologiaImm}
                                                variant="outlined"
                                            >
                                                <option
                                                    value={0}
                                                >
                                                    Seleziona
                                                </option>
                                                {TipologiaList.map(option => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.dimora !== undefined && data.dimora !== ""
                                                }
                                                onChange={handleChangeChecked("dimora")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Tipo di dimora"
                                                name="dimora"
                                                onChange={handleChangeValue}
                                                select
                                                SelectProps={{ native: true }}
                                                value={(data === null || data.dimora === undefined) ? 0 : data.dimora}
                                                variant="outlined"
                                            >
                                                <option
                                                    value={0}
                                                >
                                                    Seleziona
                                                </option>
                                                {TipoDimoraList.map(option => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.posizione !== undefined && data.posizione !== ""
                                                }
                                                onChange={handleChangeChecked("posizione")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Posizione immobile"
                                                name="posizione"
                                                onChange={handleChangeValue}
                                                select
                                                SelectProps={{ native: true }}
                                                value={(data === null || data.posizione === undefined) ? 0 : data.posizione}
                                                variant="outlined"
                                            >
                                                <option
                                                    value={0}
                                                >
                                                    Seleziona
                                                </option>
                                                {PosizioneList.map(option => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.subjectTypeHouse !== undefined && data.subjectTypeHouse !== ""
                                                }
                                                onChange={handleChangeChecked("subjectTypeHouse")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Tipo di soggetto"
                                                name="subjectTypeHouse"
                                                onChange={handleChangeValue}
                                                select
                                                SelectProps={{ native: true }}
                                                value={(data === null || data.subjectTypeHouse === undefined) ? 0 : data.subjectTypeHouse}
                                                variant="outlined"
                                            >
                                                <option
                                                    value={0}
                                                >
                                                    Seleziona
                                                </option>
                                                {subjectTypes.map(option => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.surnameHouse !== undefined && data.surnameHouse !== ""
                                                }
                                                onChange={handleChangeChecked("surnameHouse")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="surnameHouse"
                                                label="Cognome"
                                                name="surnameHouse"
                                                value={data === null ? "" : data.surnameHouse}
                                                onChange={handleChangeValue}

                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.nameHouse !== undefined && data.nameHouse !== ""
                                                }
                                                onChange={handleChangeChecked("nameHouse")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="nameHouse"
                                                label="Nome"
                                                name="nameHouse"
                                                value={data === null ? "" : data.nameHouse}
                                                onChange={handleChangeValue}

                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.fiscalCodeHouse !== undefined && data.fiscalCodeHouse !== ""
                                                }
                                                onChange={handleChangeChecked("fiscalCodeHouse")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="fiscalCodeHouse"
                                                label="Codice fiscale"
                                                name="fiscalCodeHouse"
                                                value={data === null ? "" : data.fiscalCodeHouse}
                                                onChange={handleChangeValue}

                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.companyNameHouse !== undefined && data.companyNameHouse !== ""
                                                }
                                                onChange={handleChangeChecked("companyNameHouse")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="companyNameHouse"
                                                label="Nome Azienda"
                                                name="companyNameHouse"
                                                value={data === null ? "" : data.companyNameHouse}
                                                onChange={handleChangeValue}

                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.vatNumberHouse !== undefined && data.vatNumberHouse !== ""
                                                }
                                                onChange={handleChangeChecked("vatNumberHouse")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="vatNumberHouse"
                                                label="Partita IVA"
                                                name="vatNumberHouse"
                                                value={data === null ? "" : data.vatNumberHouse}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.emailhouse !== undefined && data.emailhouse !== ""
                                                }
                                                onChange={handleChangeChecked("emailhouse")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="emailhouse"
                                                label="Email"
                                                name="emailhouse"
                                                value={data === null ? "" : data.emailhouse}
                                                onChange={handleChangeValue}

                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.phoneNumberHouse !== undefined && data.phoneNumberHouse !== ""
                                                }
                                                onChange={handleChangeChecked("phoneNumberHouse")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="phoneNumberHouse"
                                                label="Numero di telefono"
                                                name="phoneNumberHouse"
                                                value={data === null ? "" : data.phoneNumberHouse}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.valoreFabbricato !== undefined && data.valoreFabbricato !== ""
                                                }
                                                onChange={handleChangeChecked("valoreFabbricato")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="valoreFabbricato"
                                                label="Valore Fabbricato"
                                                name="valoreFabbricato"
                                                value={data === null ? "" : data.valoreFabbricato}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.valoreContenuto !== undefined && data.valoreContenuto !== ""
                                                }
                                                onChange={handleChangeChecked("valoreContenuto")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="valoreContenuto"
                                                label="Valore contenuto"
                                                name="valoreContenuto"
                                                value={data === null ? "" : data.valoreContenuto}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.antifurto !== undefined && data.antifurto !== ""
                                                }
                                                onChange={handleChangeChecked("antifurto")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Antifurto"
                                                name="antifurto"
                                                onChange={handleChangeValue}
                                                select
                                                SelectProps={{ native: true }}
                                                value={(data === null || data.antifurto === undefined) ? false : data.antifurto}
                                                variant="outlined"
                                            >
                                                <option
                                                    value={false}
                                                >
                                                    No
                                                </option>
                                                <option
                                                    value={true}
                                                >
                                                    Si
                                                </option>

                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <TableContainer className={classes.table} component={Paper}>
                            <Table aria-label="CVT">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Garanzia</TableCell>
                                        <TableCell>Selezionato</TableCell>
                                        <TableCell>Obbligatorio</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {guaranteesHouse.map((warranty, index) => (
                                        <TableRow>
                                            <TableCell>
                                                {warranty.description}
                                            </TableCell>
                                            <TableCell>
                                                <Checkbox
                                                    checked={
                                                        data !== null && data[warranty.code] !== undefined && data[warranty.code] & 1
                                                    }
                                                    onChange={handleBitChecked(1, warranty.code)}
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Checkbox
                                                    checked={
                                                        data !== null && data[warranty.code] !== undefined && data[warranty.code] & 2
                                                    }
                                                    onChange={handleBitChecked(2, warranty.code)}
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TabPanel>

                    {/* INFORTUNI */}
                    <TabPanel value={numTab} index={5}>
                        <TableContainer className={classes.table} component={Paper}>
                            <Table aria-label="Parametri default infortuni">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Imposta default</TableCell>
                                        <TableCell>Parametro</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {/*<TableRow>
                                    <TableCell>
                                        <Checkbox
                                            checked={
                                                data !== null && data.injuries_cvt_comp !== undefined && data.injuries_cvt_comp !== ""
                                            }
                                            onChange={handleChangeChecked("injuries_cvt_comp")}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            label="Utilizzare le CVT di compagnia? Se si seleziona NO verrano richiamate le apposite COMPAGNIE configurate"
                                            name="injuries_cvt_comp"
                                            onChange={handleChangeValue}
                                            select
                                            SelectProps={{ native: true }}
                                            value={data === null ? "yes" : data.injuries_cvt_comp === undefined ? "yes" : data.injuries_cvt_comp}
                                            variant="outlined"
                                        >
                                            <option key="cvtCompNo" value={"no"}>
                                                Sempre CVT di compagnie selezionate
                                            </option>
                                            <option key="cvtCompYes" value={"yes"}>
                                                Sempre CVT delle compagnie
                                            </option>
                                            <option key="cvtCompBoth" value={"both"}>
                                                Entrambe, possibilità di scelta (default Sempre CVT delle compagnie)
                                            </option>
                                        </TextField>
                                    </TableCell>
                                        </TableRow>*/}
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.attesaPrevInjuries !== undefined && data.attesaPrevInjuries !== ""
                                                }
                                                onChange={handleChangeChecked("attesaPrevInjuries")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Autocomplete
                                                options={attesaPrevTypes}
                                                id="attesaPrevInjuries"
                                                label="Metodo di attesa dei preventivi"
                                                name="attesaPrevInjuries"
                                                disableClearable
                                                getOptionLabel={(option) => option.description}
                                                value={data === null ? "waiting" : data.attesaPrevInjuries === undefined ? "waiting" : returnElement(attesaPrevTypes, data.attesaPrevInjuries)}
                                                autoHighlight
                                                onChange={(event, newValue) => {
                                                    setData({
                                                        ...data,
                                                        attesaPrevInjuries: newValue.id,
                                                    });
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Metodo di attesa dei preventivi" margin="normal" variant="outlined" />}
                                            />

                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.frazionamentoInjuries !== undefined && data.frazionamentoInjuries !== 0
                                                }
                                                onChange={handleChangeChecked("frazionamentoInjuries")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Frazionamento"
                                                name="frazionamentoInjuries"
                                                onChange={handleChangeValue}
                                                select
                                                SelectProps={{ native: true }}
                                                value={(data === null || data.frazionamentoInjuries === undefined) ? 0 : data.frazionamentoInjuries}
                                                variant="outlined"
                                            >
                                                <option
                                                    key="NOFRAZ"
                                                    value={0}
                                                >
                                                    Seleziona il frazionamento
                                                </option>
                                                {tipoFrazionamento.map(option => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.toponimoInjuries !== undefined && data.toponimoInjuries !== ""
                                                }
                                                onChange={handleChangeChecked("toponimoInjuries")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="toponimoInjuries"
                                                label="Via,piazza,ecc"
                                                name="toponimoInjuries"
                                                value={data === null ? "" : data.toponimoInjuries}
                                                onChange={handleChangeValue}

                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.indirizzoInjuries !== undefined && data.indirizzoInjuries !== ""
                                                }
                                                onChange={handleChangeChecked("indirizzoInjuries")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="indirizzoInjuries"
                                                label="Indirizzo"
                                                name="indirizzoInjuries"
                                                value={data === null ? "" : data.indirizzoInjuries}
                                                onChange={handleChangeValue}

                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.numeroCivicoInjuries !== undefined && data.numeroCivicoInjuries !== ""
                                                }
                                                onChange={handleChangeChecked("numeroCivicoInjuries")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"

                                                fullWidth
                                                id="numeroCivicoInjuries"
                                                label="Numero civico"
                                                name="numeroCivicoInjuries"
                                                value={data === null ? "" : data.numeroCivicoInjuries}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.fiscalCodeInjuries !== undefined && data.fiscalCodeInjuries !== ""
                                                }
                                                onChange={handleChangeChecked("fiscalCodeInjuries")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="fiscalCodeInjuries"
                                                label="Codice fiscale"
                                                name="fiscalCodeInjuries"
                                                value={data === null ? "" : data.fiscalCodeInjuries}
                                                onChange={handleChangeValue}

                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.surnameInjuries !== undefined && data.surnameInjuries !== ""
                                                }
                                                onChange={handleChangeChecked("surnameInjuries")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="surnameInjuries"
                                                label="Cognome"
                                                name="surnameInjuries"
                                                value={data === null ? "" : data.surnameInjuries}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.nameInjuries !== undefined && data.nameInjuries !== ""
                                                }
                                                onChange={handleChangeChecked("nameInjuries")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="nameInjuries"
                                                label="Nome"
                                                name="nameInjuries"
                                                value={data === null ? "" : data.nameInjuries}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.professionInjuries !== undefined && data.professionInjuries !== ""
                                                }
                                                onChange={handleChangeChecked("professionInjuries")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Autocomplete
                                                options={professioniInjuries}
                                                id="professionInjuries"
                                                label="Professione"
                                                name="professionInjuries"
                                                disableClearable
                                                getOptionLabel={(option) => option.label}
                                                autoHighlight
                                                value={data === null || data.professionInjuries === undefined ? "" : returnElementValue(professioniInjuries, data.professionInjuries)}
                                                onChange={(event, newValue) => {
                                                    setData({
                                                        ...data,
                                                        professionInjuries: newValue.value,
                                                    });
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Professione" margin="normal" variant="outlined" />}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.emailInjuries !== undefined && data.emailInjuries !== ""
                                                }
                                                onChange={handleChangeChecked("emailInjuries")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="emailInjuries"
                                                label="Email"
                                                name="emailInjuries"
                                                value={data === null ? "" : data.emailInjuries}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.phoneNumberInjuries !== undefined && data.phoneNumberInjuries !== ""
                                                }
                                                onChange={handleChangeChecked("phoneNumberInjuries")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="phoneNumberInjuries"
                                                label="Numero di telefono"
                                                name="phoneNumberInjuries"
                                                value={data === null ? "" : data.phoneNumberInjuries}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <TableContainer className={classes.table} component={Paper}>
                            <Table aria-label="CVT">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Garanzia</TableCell>
                                        <TableCell>Selezionato</TableCell>
                                        <TableCell>Obbligatorio</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {guaranteesInjuries.map((warranty, index) => (
                                        <TableRow>
                                            <TableCell>
                                                {warranty.description}
                                            </TableCell>
                                            <TableCell>
                                                <Checkbox
                                                    checked={
                                                        data !== null && data["INF_" + warranty.code] !== undefined && data["INF_" + warranty.code] & 1
                                                    }
                                                    onChange={handleBitChecked(1, "INF_" + warranty.code)}
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Checkbox
                                                    checked={
                                                        data !== null && data["INF_" + warranty.code] !== undefined && data["INF_" + warranty.code] & 2
                                                    }
                                                    onChange={handleBitChecked(2, "INF_" + warranty.code)}
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TabPanel>

                    {/* TCM */}
                    <TabPanel value={numTab} index={6}>
                        <TableContainer className={classes.table} component={Paper}>
                            <Table aria-label="Parametri default TCM">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Imposta default</TableCell>
                                        <TableCell>Parametro</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {/*<TableRow>
                                    <TableCell>
                                        <Checkbox
                                            checked={
                                                data !== null && data.tcm_cvt_comp !== undefined && data.tcm_cvt_comp !== ""
                                            }
                                            onChange={handleChangeChecked("injuries_cvt_comp")}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            label="Utilizzare le CVT di compagnia? Se si seleziona NO verrano richiamate le apposite COMPAGNIE configurate"
                                            name="injuries_cvt_comp"
                                            onChange={handleChangeValue}
                                            select
                                            SelectProps={{ native: true }}
                                            value={data === null ? "yes" : data.injuries_cvt_comp === undefined ? "yes" : data.injuries_cvt_comp}
                                            variant="outlined"
                                        >
                                            <option key="cvtCompNo" value={"no"}>
                                                Sempre CVT di compagnie selezionate
                                            </option>
                                            <option key="cvtCompYes" value={"yes"}>
                                                Sempre CVT delle compagnie
                                            </option>
                                            <option key="cvtCompBoth" value={"both"}>
                                                Entrambe, possibilità di scelta (default Sempre CVT delle compagnie)
                                            </option>
                                        </TextField>
                                    </TableCell>
                                        </TableRow>*/}
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.attesaPrevTcm !== undefined && data.attesaPrevTcm !== ""
                                                }
                                                onChange={handleChangeChecked("attesaPrevTcm")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Autocomplete
                                                options={attesaPrevTypes}
                                                id="attesaPrevTcm"
                                                label="Metodo di attesa dei preventivi"
                                                name="attesaPrevTcm"
                                                disableClearable
                                                getOptionLabel={(option) => option.description}
                                                value={data === null ? "waiting" : data.attesaPrevTcm === undefined ? "waiting" : returnElement(attesaPrevTypes, data.attesaPrevTcm)}
                                                autoHighlight
                                                onChange={(event, newValue) => {
                                                    setData({
                                                        ...data,
                                                        attesaPrevTcm: newValue.id,
                                                    });
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Metodo di attesa dei preventivi" margin="normal" variant="outlined" />}
                                            />

                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.frazionamentoTcm !== undefined && data.frazionamentoTcm !== 0
                                                }
                                                onChange={handleChangeChecked("frazionamentoTcm")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Frazionamento"
                                                name="frazionamentoTcm"
                                                onChange={handleChangeValue}
                                                select
                                                SelectProps={{ native: true }}
                                                value={(data === null || data.frazionamentoTcm === undefined) ? 0 : data.frazionamentoTcm}
                                                variant="outlined"
                                            >
                                                <option
                                                    key="NOFRAZ"
                                                    value={0}
                                                >
                                                    Seleziona il frazionamento
                                                </option>
                                                {tipoFrazionamento.map(option => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.toponimoTcm !== undefined && data.toponimoTcm !== ""
                                                }
                                                onChange={handleChangeChecked("toponimoTcm")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="toponimoTcm"
                                                label="Via,piazza,ecc"
                                                name="toponimoTcm"
                                                value={data === null ? "" : data.toponimoTcm}
                                                onChange={handleChangeValue}

                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.indirizzoTcm !== undefined && data.indirizzoTcm !== ""
                                                }
                                                onChange={handleChangeChecked("indirizzoTcm")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="indirizzoTcm"
                                                label="Indirizzo"
                                                name="indirizzoTcm"
                                                value={data === null ? "" : data.indirizzoTcm}
                                                onChange={handleChangeValue}

                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.numeroCivicoTcm !== undefined && data.numeroCivicoTcm !== ""
                                                }
                                                onChange={handleChangeChecked("numeroCivicoTcm")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"

                                                fullWidth
                                                id="numeroCivicoTcm"
                                                label="Numero civico"
                                                name="numeroCivicoTcm"
                                                value={data === null ? "" : data.numeroCivicoTcm}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.fiscalCodeTcm !== undefined && data.fiscalCodeTcm !== ""
                                                }
                                                onChange={handleChangeChecked("fiscalCodeTcm")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="fiscalCodeTcm"
                                                label="Codice fiscale"
                                                name="fiscalCodeTcm"
                                                value={data === null ? "" : data.fiscalCodeTcm}
                                                onChange={handleChangeValue}

                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.surnameTcm !== undefined && data.surnameTcm !== ""
                                                }
                                                onChange={handleChangeChecked("surnameTcm")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="surnameTcm"
                                                label="Cognome"
                                                name="surnameTcm"
                                                value={data === null ? "" : data.surnameTcm}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.nameTcm !== undefined && data.nameTcm !== ""
                                                }
                                                onChange={handleChangeChecked("nameTcm")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="nameTcm"
                                                label="Nome"
                                                name="nameTcm"
                                                value={data === null ? "" : data.nameTcm}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.emailTcm !== undefined && data.emailTcm !== ""
                                                }
                                                onChange={handleChangeChecked("emailTcm")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="emailTcm"
                                                label="Email"
                                                name="emailTcm"
                                                value={data === null ? "" : data.emailTcm}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.phoneNumberTcm !== undefined && data.phoneNumberTcm !== ""
                                                }
                                                onChange={handleChangeChecked("phoneNumberTcm")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="phoneNumberTcm"
                                                label="Numero di telefono"
                                                name="phoneNumberTcm"
                                                value={data === null ? "" : data.phoneNumberTcm}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <TableContainer className={classes.table} component={Paper}>
                            <Table aria-label="CVT">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Garanzia</TableCell>
                                        <TableCell>Selezionato</TableCell>
                                        <TableCell>Obbligatorio</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {guaranteesTcm.map((warranty, index) => (
                                        <TableRow>
                                            <TableCell>
                                                {warranty.description}
                                            </TableCell>
                                            <TableCell>
                                                <Checkbox
                                                    checked={
                                                        data !== null && data["TCM_" + warranty.code] !== undefined && data["TCM_" + warranty.code] & 1
                                                    }
                                                    onChange={handleBitChecked(1, "TCM_" + warranty.code)}
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Checkbox
                                                    checked={
                                                        data !== null && data["TCM_" + warranty.code] !== undefined && data["TCM_" + warranty.code] & 2
                                                    }
                                                    onChange={handleBitChecked(2, "TCM_" + warranty.code)}
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TabPanel>

                    {/* CICLOMOTORE */}
                    <TabPanel value={numTab} index={2}>
                        <TableContainer className={classes.table} component={Paper}>
                            <Table aria-label="Parametri default auto">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Imposta default</TableCell>
                                        <TableCell>Parametro</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.professionBike !== undefined && data.professionBike !== ""
                                                }
                                                onChange={handleChangeChecked("professionBike")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Autocomplete
                                                options={listProfessions}
                                                id="professionBike"
                                                label="Professione"
                                                name="professionBike"
                                                disableClearable
                                                getOptionLabel={(option) => option.description}
                                                autoHighlight
                                                value={data === null || data.professionBike === undefined ? "" : returnElement(listProfessions, data.professionBike)}
                                                onChange={(event, newValue) => {
                                                    setData({
                                                        ...data,
                                                        professionBike: newValue.id,
                                                    });
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Professione" margin="normal" variant="outlined" />}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.qualificationBike !== undefined && data.qualificationBike !== ""
                                                }
                                                onChange={handleChangeChecked("qualificationBike")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>

                                        <TableCell>
                                            <Autocomplete
                                                options={livelliIstruzione}
                                                id="qualificationBike"
                                                label="Livello di istruzione"
                                                name="qualificationBike"
                                                disableClearable
                                                getOptionLabel={(option) => option.description}
                                                autoHighlight

                                                value={data === null || data.qualificationBike === undefined ? "" : returnElement(livelliIstruzione, data.qualificationBike)}
                                                onChange={(event, newValue) => {
                                                    setData({
                                                        ...data,
                                                        qualificationBike: newValue.id,
                                                    });
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Livello di istruzione" margin="normal" variant="outlined" />}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.maritalStatusBike !== undefined && data.maritalStatusBike !== ""
                                                }
                                                onChange={handleChangeChecked("maritalStatusBike")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Autocomplete
                                                options={statoCivile}
                                                id="maritalStatusBike"
                                                label="Stato civile"
                                                name="maritalStatusBike"
                                                disableClearable
                                                getOptionLabel={(option) => option.description}
                                                autoHighlight
                                                value={data === null || data.maritalStatusBike === undefined ? "" : returnElement(statoCivile, data.maritalStatusBike)}
                                                onChange={(event, newValue) => {
                                                    setData({
                                                        ...data,
                                                        maritalStatusBike: newValue.id,
                                                    });
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Stato civile" margin="normal" variant="outlined" />}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.toponymBike !== undefined && data.toponymBike !== ""
                                                }
                                                onChange={handleChangeChecked("toponymBike")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="toponymBike"
                                                label="Via,piazza,ecc"
                                                name="toponymBike"
                                                value={data === null ? "" : data.toponymBike}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.addressBike !== undefined && data.addressBike !== ""
                                                }
                                                onChange={handleChangeChecked("addressBike")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="addressBike"
                                                label="Indirizzo"
                                                name="addressBike"
                                                value={data === null ? "" : data.addressBike}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.streetNumberBike !== undefined && data.streetNumberBike !== ""
                                                }
                                                onChange={handleChangeChecked("streetNumberBike")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="streetNumberBike"
                                                label="Numero civico"
                                                name="streetNumberBike"
                                                value={data === null ? "" : data.streetNumberBike}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.emailBike !== undefined && data.emailBike !== ""
                                                }
                                                onChange={handleChangeChecked("emailBike")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="emailBike"
                                                label="Email"
                                                name="emailBike"
                                                value={data === null ? "" : data.emailBike}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.phoneNumberBike !== undefined && data.phoneNumberBike !== ""
                                                }
                                                onChange={handleChangeChecked("phoneNumberBike")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="phoneNumberBike"
                                                label="Numero di telefono"
                                                name="phoneNumberBike"
                                                value={data === null ? "" : data.phoneNumberBike}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.kmAnnuiBike !== undefined && data.kmAnnuiBike !== ""
                                                }
                                                onChange={handleChangeChecked("kmAnnuiBike")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Autocomplete
                                                options={kmAnnuiOption}
                                                id="kmAnnuiBike"
                                                label="Km annui percorsi"
                                                name="kmAnnuiBike"
                                                disableClearable
                                                getOptionLabel={(option) => option.description}
                                                value={data === null ? "" : returnElement(kmAnnuiOption, data.kmAnnuiBike)}
                                                autoHighlight
                                                onChange={(event, newValue) => {
                                                    setData({
                                                        ...data,
                                                        kmAnnuiBike: newValue.id,
                                                    });
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Km annui percorsi" margin="normal" variant="outlined" />}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.insuranceYearsBike !== undefined && data.insuranceYearsBike !== ""
                                                }
                                                onChange={handleChangeChecked("insuranceYearsBike")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Da quanti anni sei assicurato?"
                                                name="insuranceYearsBike"
                                                onChange={handleChangeValue}
                                                select
                                                SelectProps={{ native: true }}
                                                value={data === null ? "" : data.insuranceYearsBike}
                                                variant="outlined"
                                            >
                                                <option key="noOption" value="">
                                                </option>
                                                <option key="option1" value={1}>
                                                    Calcola in base all'attestato di rischio
                                                </option>
                                                <option key="option2" value={2}>
                                                    Calcola in base alla data di conseguimento della patente
                                                </option>
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.effectiveDateBike !== undefined && data.effectiveDateBike !== ""
                                                }
                                                onChange={handleChangeChecked("effectiveDateBike")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Data decorrenza"
                                                name="effectiveDateBike"
                                                onChange={handleChangeValue}
                                                select
                                                SelectProps={{ native: true }}
                                                value={data === null ? "" : data.effectiveDateBike}
                                                variant="outlined"
                                            >
                                                <option key="noOptionDec" value="">
                                                </option>
                                                <option key="option1Dec" value={1}>
                                                    Imposta al 15 o a fine mese
                                                </option>
                                                <option key="option2Dec" value={2}>
                                                    Lascia la data di scadenza
                                                </option>
                                            </TextField>


                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.guideTypeBike !== undefined && data.guideTypeBike !== "0"
                                                }
                                                onChange={handleChangeChecked("guideTypeBike")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Tipo di guida"
                                                name="guideTypeBike"
                                                onChange={handleChangeValue}

                                                select
                                                SelectProps={{ native: true }}
                                                value={(data === null || data.guideTypeBike === undefined) ? "0" : data.guideTypeBike}
                                                variant="outlined"
                                            >
                                                {tipoGuida.map(option => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.youngestLicensedAgeBike !== undefined && data.youngestLicensedAgeBike !== "0"
                                                }
                                                onChange={handleChangeChecked("youngestLicensedAgeBike")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Età del guidatore più giovane"
                                                name="youngestLicensedAgeBike"
                                                onChange={handleChangeValue}
                                                select
                                                SelectProps={{ native: true }}
                                                value={(data === null || data.youngestLicensedAgeBike === undefined) ? "0" : data.youngestLicensedAgeBike}
                                                variant="outlined"
                                            >
                                                {etaGuidatorePiuGiovane.map(option => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.maximalBike !== undefined && data.maximalBike !== 0
                                                }
                                                onChange={handleChangeChecked("maximalBike")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Massimale"
                                                name="maximalBike"
                                                onChange={handleChangeValue}
                                                select
                                                SelectProps={{ native: true }}
                                                value={(data === null || data.maximalBike === undefined) ? 0 : data.maximalBike}
                                                variant="outlined"
                                            >
                                                {ElencoMassimali.map(option => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.splittingUpBike !== undefined && data.splittingUpBike !== 0
                                                }
                                                onChange={handleChangeChecked("splittingUpBike")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Frazionamento"
                                                name="splittingUpBike"
                                                onChange={handleChangeValue}
                                                select
                                                SelectProps={{ native: true }}
                                                value={(data === null || data.splittingUpBike === undefined) ? 0 : data.splittingUpBike}
                                                variant="outlined"
                                            >
                                                <option
                                                    key="NOFRAZ"
                                                    value={0}
                                                >
                                                    Seleziona il frazionamento
                                                </option>
                                                {tipoFrazionamento.map(option => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <TableContainer className={classes.table} component={Paper}>
                            <Table aria-label="CVT">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Garanzia</TableCell>
                                        <TableCell>Selezionato</TableCell>
                                        <TableCell>Obbligatorio</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {guaranteesBike.map((warranty, index) => (
                                        <>
                                            <TableRow>
                                                <TableCell>
                                                    {warranty.description}
                                                </TableCell>
                                                <TableCell>
                                                    <Checkbox
                                                        checked={
                                                            data !== null && data["BIKE_" + warranty.code] !== undefined && data["BIKE_" + warranty.code] & 1
                                                        }
                                                        onChange={handleBitChecked(1, "BIKE_" + warranty.code)}
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Checkbox
                                                        checked={
                                                            data !== null && data["BIKE_" + warranty.code] !== undefined && data["BIKE_" + warranty.code] & 2
                                                        }
                                                        onChange={handleBitChecked(2, "BIKE_" + warranty.code)}
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TabPanel>

                    {/* MOTOCICLO */}
                    <TabPanel value={numTab} index={3}>
                        <TableContainer className={classes.table} component={Paper}>
                            <Table aria-label="Parametri default auto">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Imposta default</TableCell>
                                        <TableCell>Parametro</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.professionMotorbike !== undefined && data.professionMotorbike !== ""
                                                }
                                                onChange={handleChangeChecked("professionMotorbike")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Autocomplete
                                                options={listProfessions}
                                                id="professionMotorbike"
                                                label="Professione"
                                                name="professionMotorbike"
                                                disableClearable
                                                getOptionLabel={(option) => option.description}
                                                autoHighlight
                                                value={data === null || data.professionMotorbike === undefined ? "" : returnElement(listProfessions, data.professionMotorbike)}
                                                onChange={(event, newValue) => {
                                                    setData({
                                                        ...data,
                                                        professionMotorbike: newValue.id,
                                                    });
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Professione" margin="normal" variant="outlined" />}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.qualificationMotorbike !== undefined && data.qualificationMotorbike !== ""
                                                }
                                                onChange={handleChangeChecked("qualificationMotorbike")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>

                                        <TableCell>
                                            <Autocomplete
                                                options={livelliIstruzione}
                                                id="qualificationMotorbike"
                                                label="Livello di istruzione"
                                                name="qualificationMotorbike"
                                                disableClearable
                                                getOptionLabel={(option) => option.description}
                                                autoHighlight

                                                value={data === null || data.qualificationMotorbike === undefined ? "" : returnElement(livelliIstruzione, data.qualificationMotorbike)}
                                                onChange={(event, newValue) => {
                                                    setData({
                                                        ...data,
                                                        qualificationMotorbike: newValue.id,
                                                    });
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Livello di istruzione" margin="normal" variant="outlined" />}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.maritalStatusMotorbike !== undefined && data.maritalStatusMotorbike !== ""
                                                }
                                                onChange={handleChangeChecked("maritalStatusMotorbike")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Autocomplete
                                                options={statoCivile}
                                                id="maritalStatusMotorbike"
                                                label="Stato civile"
                                                name="maritalStatusMotorbike"
                                                disableClearable
                                                getOptionLabel={(option) => option.description}
                                                autoHighlight
                                                value={data === null || data.maritalStatusMotorbike === undefined ? "" : returnElement(statoCivile, data.maritalStatusMotorbike)}
                                                onChange={(event, newValue) => {
                                                    setData({
                                                        ...data,
                                                        maritalStatusMotorbike: newValue.id,
                                                    });
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Stato civile" margin="normal" variant="outlined" />}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.toponymMotorbike !== undefined && data.toponymMotorbike !== ""
                                                }
                                                onChange={handleChangeChecked("toponymMotorbike")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="toponymMotorbike"
                                                label="Via,piazza,ecc"
                                                name="toponymMotorbike"
                                                value={data === null ? "" : data.toponymMotorbike}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.addressMotorbike !== undefined && data.addressMotorbike !== ""
                                                }
                                                onChange={handleChangeChecked("addressMotorbike")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="addressMotorbike"
                                                label="Indirizzo"
                                                name="addressMotorbike"
                                                value={data === null ? "" : data.addressMotorbike}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.streetNumberMotorbike !== undefined && data.streetNumberMotorbike !== ""
                                                }
                                                onChange={handleChangeChecked("streetNumberMotorbike")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="streetNumberMotorbike"
                                                label="Numero civico"
                                                name="streetNumberMotorbike"
                                                value={data === null ? "" : data.streetNumberMotorbike}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.emailMotorbike !== undefined && data.emailMotorbike !== ""
                                                }
                                                onChange={handleChangeChecked("emailMotorbike")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="emailMotorbike"
                                                label="Email"
                                                name="emailMotorbike"
                                                value={data === null ? "" : data.emailMotorbike}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.phoneNumberMotorbike !== undefined && data.phoneNumberMotorbike !== ""
                                                }
                                                onChange={handleChangeChecked("phoneNumberMotorbike")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="phoneNumberMotorbike"
                                                label="Numero di telefono"
                                                name="phoneNumberMotorbike"
                                                value={data === null ? "" : data.phoneNumberMotorbike}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.kmAnnuiMotorbike !== undefined && data.kmAnnuiMotorbike !== ""
                                                }
                                                onChange={handleChangeChecked("kmAnnuiMotorbike")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Autocomplete
                                                options={kmAnnuiOption}
                                                id="kmAnnuiMotorbike"
                                                label="Km annui percorsi"
                                                name="kmAnnuiMotorbike"
                                                disableClearable
                                                getOptionLabel={(option) => option.description}
                                                value={data === null ? "" : returnElement(kmAnnuiOption, data.kmAnnuiMotorbike)}
                                                autoHighlight
                                                onChange={(event, newValue) => {
                                                    setData({
                                                        ...data,
                                                        kmAnnuiMotorbike: newValue.id,
                                                    });
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Km annui percorsi" margin="normal" variant="outlined" />}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.insuranceYearsMotorbike !== undefined && data.insuranceYearsMotorbike !== ""
                                                }
                                                onChange={handleChangeChecked("insuranceYearsMotorbike")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Da quanti anni sei assicurato?"
                                                name="insuranceYearsMotorbike"
                                                onChange={handleChangeValue}
                                                select
                                                SelectProps={{ native: true }}
                                                value={data === null ? "" : data.insuranceYearsMotorbike}
                                                variant="outlined"
                                            >
                                                <option key="noOption" value="">
                                                </option>
                                                <option key="option1" value={1}>
                                                    Calcola in base all'attestato di rischio
                                                </option>
                                                <option key="option2" value={2}>
                                                    Calcola in base alla data di conseguimento della patente
                                                </option>
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.effectiveDateMotorbike !== undefined && data.effectiveDateMotorbike !== ""
                                                }
                                                onChange={handleChangeChecked("effectiveDateMotorbike")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Data decorrenza"
                                                name="effectiveDateMotorbike"
                                                onChange={handleChangeValue}
                                                select
                                                SelectProps={{ native: true }}
                                                value={data === null ? "" : data.effectiveDateMotorbike}
                                                variant="outlined"
                                            >
                                                <option key="noOptionDec" value="">
                                                </option>
                                                <option key="option1Dec" value={1}>
                                                    Imposta al 15 o a fine mese
                                                </option>
                                                <option key="option2Dec" value={2}>
                                                    Lascia la data di scadenza
                                                </option>
                                            </TextField>


                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.guideTypeMotorbike !== undefined && data.guideTypeMotorbike !== "0"
                                                }
                                                onChange={handleChangeChecked("guideTypeMotorbike")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Tipo di guida"
                                                name="guideTypeMotorbike"
                                                onChange={handleChangeValue}

                                                select
                                                SelectProps={{ native: true }}
                                                value={(data === null || data.guideTypeMotorbike === undefined) ? "0" : data.guideTypeMotorbike}
                                                variant="outlined"
                                            >
                                                {tipoGuida.map(option => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.youngestLicensedAgeMotorbike !== undefined && data.youngestLicensedAgeMotorbike !== "0"
                                                }
                                                onChange={handleChangeChecked("youngestLicensedAgeMotorbike")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Età del guidatore più giovane"
                                                name="youngestLicensedAgeMotorbike"
                                                onChange={handleChangeValue}
                                                select
                                                SelectProps={{ native: true }}
                                                value={(data === null || data.youngestLicensedAgeMotorbike === undefined) ? "0" : data.youngestLicensedAgeMotorbike}
                                                variant="outlined"
                                            >
                                                {etaGuidatorePiuGiovane.map(option => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.maximalMotorbike !== undefined && data.maximalMotorbike !== 0
                                                }
                                                onChange={handleChangeChecked("maximalMotorbike")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Massimale"
                                                name="maximalMotorbike"
                                                onChange={handleChangeValue}
                                                select
                                                SelectProps={{ native: true }}
                                                value={(data === null || data.maximalMotorbike === undefined) ? 0 : data.maximalMotorbike}
                                                variant="outlined"
                                            >
                                                {ElencoMassimali.map(option => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.splittingUpMotorbike !== undefined && data.splittingUpMotorbike !== 0
                                                }
                                                onChange={handleChangeChecked("splittingUpMotorbike")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Frazionamento"
                                                name="splittingUpMotorbike"
                                                onChange={handleChangeValue}
                                                select
                                                SelectProps={{ native: true }}
                                                value={(data === null || data.splittingUpMotorbike === undefined) ? 0 : data.splittingUpMotorbike}
                                                variant="outlined"
                                            >
                                                <option
                                                    key="NOFRAZ"
                                                    value={0}
                                                >
                                                    Seleziona il frazionamento
                                                </option>
                                                {tipoFrazionamento.map(option => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <TableContainer className={classes.table} component={Paper}>
                            <Table aria-label="CVT">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Garanzia</TableCell>
                                        <TableCell>Selezionato</TableCell>
                                        <TableCell>Obbligatorio</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {guaranteesMotorbike.map((warranty, index) => (
                                        <>
                                            <TableRow>
                                                <TableCell>
                                                    {warranty.description}
                                                </TableCell>
                                                <TableCell>
                                                    <Checkbox
                                                        checked={
                                                            data !== null && data["MOTORBIKE_" + warranty.code] !== undefined && data["MOTORBIKE_" + warranty.code] & 1
                                                        }
                                                        onChange={handleBitChecked(1, "MOTORBIKE_" + warranty.code)}
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Checkbox
                                                        checked={
                                                            data !== null && data["MOTORBIKE_" + warranty.code] !== undefined && data["MOTORBIKE_" + warranty.code] & 2
                                                        }
                                                        onChange={handleBitChecked(2, "MOTORBIKE_" + warranty.code)}
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TabPanel>

                    {/* RCP */}
                    <TabPanel value={numTab} index={7}>
                        <TableContainer className={classes.table} component={Paper}>
                            <Table aria-label="Parametri default RCP">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Imposta default</TableCell>
                                        <TableCell>Parametro</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {/*<TableRow>
                                    <TableCell>
                                        <Checkbox
                                            checked={
                                                data !== null && data.tcm_cvt_comp !== undefined && data.tcm_cvt_comp !== ""
                                            }
                                            onChange={handleChangeChecked("injuries_cvt_comp")}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            label="Utilizzare le CVT di compagnia? Se si seleziona NO verrano richiamate le apposite COMPAGNIE configurate"
                                            name="injuries_cvt_comp"
                                            onChange={handleChangeValue}
                                            select
                                            SelectProps={{ native: true }}
                                            value={data === null ? "yes" : data.injuries_cvt_comp === undefined ? "yes" : data.injuries_cvt_comp}
                                            variant="outlined"
                                        >
                                            <option key="cvtCompNo" value={"no"}>
                                                Sempre CVT di compagnie selezionate
                                            </option>
                                            <option key="cvtCompYes" value={"yes"}>
                                                Sempre CVT delle compagnie
                                            </option>
                                            <option key="cvtCompBoth" value={"both"}>
                                                Entrambe, possibilità di scelta (default Sempre CVT delle compagnie)
                                            </option>
                                        </TextField>
                                    </TableCell>
                                        </TableRow>*/}
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.attesaPrevRcp !== undefined && data.attesaPrevRcp !== ""
                                                }
                                                onChange={handleChangeChecked("attesaPrevRcp")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Autocomplete
                                                options={attesaPrevTypes}
                                                id="attesaPrevRcp"
                                                label="Metodo di attesa dei preventivi"
                                                name="attesaPrevRcp"
                                                disableClearable
                                                getOptionLabel={(option) => option.description}
                                                value={data === null ? "waiting" : data.attesaPrevRcp === undefined ? "waiting" : returnElement(attesaPrevTypes, data.attesaPrevRcp)}
                                                autoHighlight
                                                onChange={(event, newValue) => {
                                                    setData({
                                                        ...data,
                                                        attesaPrevRcp: newValue.id,
                                                    });
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Metodo di attesa dei preventivi" margin="normal" variant="outlined" />}
                                            />

                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.professionRcp !== undefined && data.professionRcp !== ""
                                                }
                                                onChange={handleChangeChecked("professionRcp")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Autocomplete
                                                options={professioniRcp}
                                                id="professionRcp"
                                                label="Professione"
                                                name="professionRcp"
                                                disableClearable
                                                getOptionLabel={(option) => option.label}
                                                autoHighlight
                                                value={data === null || data.professionRcp === undefined ? "" : returnElementValue(professioniRcp, data.professionRcp)}
                                                onChange={(event, newValue) => {
                                                    setData({
                                                        ...data,
                                                        professionRcp: newValue.value,
                                                    });
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Professione" margin="normal" variant="outlined" />}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.fasciaFatturatoRcp !== undefined && data.fasciaFatturatoRcp !== ""
                                                }
                                                onChange={handleChangeChecked("fasciaFatturatoRcp")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Autocomplete
                                                options={fasciaFatturatoRcp}
                                                id="fasciaFatturatoRcp"
                                                label="Fascia fatturato"
                                                name="fasciaFatturatoRcp"
                                                disableClearable
                                                getOptionLabel={(option) => option.label}
                                                autoHighlight
                                                value={data === null || data.fasciaFatturatoRcp === undefined ? "" : returnElementValue(fasciaFatturatoRcp, data.fasciaFatturatoRcp)}
                                                onChange={(event, newValue) => {
                                                    setData({
                                                        ...data,
                                                        fasciaFatturatoRcp: newValue.value,
                                                    });
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Fascia fatturato" margin="normal" variant="outlined" />}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.massimaleRcp !== undefined && data.massimaleRcp !== ""
                                                }
                                                onChange={handleChangeChecked("massimaleRcp")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Autocomplete
                                                options={massimaliRcp}
                                                id="massimaleRcp"
                                                label="Massimale"
                                                name="massimaleRcp"
                                                disableClearable
                                                getOptionLabel={(option) => option.label}
                                                autoHighlight
                                                value={data === null || data.massimaleRcp === undefined ? "" : returnElementValue(massimaliRcp, data.massimaleRcp)}
                                                onChange={(event, newValue) => {
                                                    setData({
                                                        ...data,
                                                        massimaleRcp: newValue.value,
                                                    });
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Massimale" margin="normal" variant="outlined" />}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.toponimoRcp !== undefined && data.toponimoRcp !== ""
                                                }
                                                onChange={handleChangeChecked("toponimoRcp")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="toponimoRcp"
                                                label="Via,piazza,ecc"
                                                name="toponimoRcp"
                                                value={data === null ? "" : data.toponimoRcp}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.indirizzoRcp !== undefined && data.indirizzoRcp !== ""
                                                }
                                                onChange={handleChangeChecked("indirizzoRcp")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="indirizzoRcp"
                                                label="Indirizzo"
                                                name="indirizzoRcp"
                                                value={data === null ? "" : data.indirizzoRcp}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.numeroCivicoRcp !== undefined && data.numeroCivicoRcp !== ""
                                                }
                                                onChange={handleChangeChecked("numeroCivicoRcp")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="numeroCivicoRcp"
                                                label="Numero civico"
                                                name="numeroCivicoRcp"
                                                value={data === null ? "" : data.numeroCivicoRcp}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.fiscalCodeRcp !== undefined && data.fiscalCodeRcp !== ""
                                                }
                                                onChange={handleChangeChecked("fiscalCodeRcp")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="fiscalCodeRcp"
                                                label="Codice fiscale"
                                                name="fiscalCodeRcp"
                                                value={data === null ? "" : data.fiscalCodeRcp}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.surnameRcp !== undefined && data.surnameRcp !== ""
                                                }
                                                onChange={handleChangeChecked("surnameRcp")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="surnameRcp"
                                                label="Cognome"
                                                name="surnameRcp"
                                                value={data === null ? "" : data.surnameRcp}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.nameRcp !== undefined && data.nameRcp !== ""
                                                }
                                                onChange={handleChangeChecked("nameRcp")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="nameRcp"
                                                label="Nome"
                                                name="nameRcp"
                                                value={data === null ? "" : data.nameRcp}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.emailRcp !== undefined && data.emailRcp !== ""
                                                }
                                                onChange={handleChangeChecked("emailRcp")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="emailRcp"
                                                label="Email"
                                                name="emailRcp"
                                                value={data === null ? "" : data.emailRcp}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.phoneNumberRcp !== undefined && data.phoneNumberRcp !== ""
                                                }
                                                onChange={handleChangeChecked("phoneNumberRcp")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="phoneNumberRcp"
                                                label="Numero di telefono"
                                                name="phoneNumberRcp"
                                                value={data === null ? "" : data.phoneNumberRcp}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <TableContainer className={classes.table} component={Paper}>
                            <Table aria-label="CVT">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Garanzia</TableCell>
                                        <TableCell>Selezionato</TableCell>
                                        <TableCell>Obbligatorio</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {guaranteesRcp.map((warranty, index) => (
                                        <TableRow>
                                            <TableCell>
                                                {warranty.description}
                                            </TableCell>
                                            <TableCell>
                                                <Checkbox
                                                    checked={
                                                        data !== null && data["RCP_" + warranty.code] !== undefined && data["RCP_" + warranty.code] & 1
                                                    }
                                                    onChange={handleBitChecked(1, "RCP_" + warranty.code)}
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Checkbox
                                                    checked={
                                                        data !== null && data["RCP_" + warranty.code] !== undefined && data["RCP_" + warranty.code] & 2
                                                    }
                                                    onChange={handleBitChecked(2, "RCP_" + warranty.code)}
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TabPanel>

                    {/* GF */}
                    <TabPanel value={numTab} index={8}>
                        <TableContainer className={classes.table} component={Paper}>
                            <Table aria-label="Parametri default Globale Fabbricati">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Imposta default</TableCell>
                                        <TableCell>Parametro</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.toponimoGf !== undefined && data.toponimoGf !== ""
                                                }
                                                onChange={handleChangeChecked("toponimoGf")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="toponimoGf"
                                                label="Via,piazza,ecc"
                                                name="toponimoGf"
                                                value={data === null ? "" : data.toponimoGf}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.indirizzoGf !== undefined && data.indirizzoGf !== ""
                                                }
                                                onChange={handleChangeChecked("indirizzoGf")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="indirizzoGf"
                                                label="Indirizzo"
                                                name="indirizzoGf"
                                                value={data === null ? "" : data.indirizzoGf}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.numeroCivicoGf !== undefined && data.numeroCivicoGf !== ""
                                                }
                                                onChange={handleChangeChecked("numeroCivicoGf")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="numeroCivicoGf"
                                                label="Numero civico"
                                                name="numeroCivicoGf"
                                                value={data === null ? "" : data.numeroCivicoGf}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.inCostruzioneGf !== undefined && data.inCostruzioneGf !== ""
                                                }
                                                onChange={handleChangeChecked("inCostruzioneGf")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="In costruzione?"
                                                name="inCostruzioneGf"
                                                onChange={handleChangeValue}
                                                select
                                                SelectProps={{ native: true }}
                                                value={(data === null || data.inCostruzioneGf === undefined) ? 0 : data.inCostruzioneGf}
                                                variant="outlined"
                                            >
                                                <option
                                                    value={0}
                                                >
                                                    Seleziona
                                                </option>
                                                <option
                                                    value={"y"}
                                                >
                                                    Sì
                                                </option>
                                                <option
                                                    value={"n"}
                                                >
                                                    No
                                                </option>
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.tipologiaImmGf !== undefined && data.tipologiaImmGf !== ""
                                                }
                                                onChange={handleChangeChecked("tipologiaImmGf")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Tipologia di immobile"
                                                name="tipologiaImmGf"
                                                onChange={handleChangeValue}
                                                select
                                                SelectProps={{ native: true }}
                                                value={(data === null || data.tipologiaImmGf === undefined) ? 0 : data.tipologiaImmGf}
                                                variant="outlined"
                                            >
                                                <option
                                                    value={0}
                                                >
                                                    Seleziona
                                                </option>
                                                {TipologiaList.map(option => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.tipologiaCostruzioneGf !== undefined && data.tipologiaCostruzioneGf !== ""
                                                }
                                                onChange={handleChangeChecked("tipologiaCostruzioneGf")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Tipologia costruzione"
                                                name="tipologiaCostruzioneGf"
                                                onChange={handleChangeValue}
                                                select
                                                SelectProps={{ native: true }}
                                                value={(data === null || data.tipologiaCostruzioneGf === undefined) ? 0 : data.tipologiaCostruzioneGf}
                                                variant="outlined"
                                            >
                                                <option
                                                    value={0}
                                                >
                                                    Seleziona la classe
                                                </option>
                                                {tipologiaCostruzioneListGf.map(option => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.percentualeAbUffGf !== undefined && data.percentualeAbUffGf !== ""
                                                }
                                                onChange={handleChangeChecked("percentualeAbUffGf")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="percentualeAbUffGf"
                                                label="Percentuale abitazioni/uffici"
                                                name="percentualeAbUffGf"
                                                value={data === null ? "" : data.percentualeAbUffGf}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.franchigiaFrontaleGf !== undefined && data.franchigiaFrontaleGf !== ""
                                                }
                                                onChange={handleChangeChecked("franchigiaFrontaleGf")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Franchigia frontale"
                                                name="franchigiaFrontaleGf"
                                                onChange={handleChangeValue}
                                                select
                                                SelectProps={{ native: true }}
                                                value={(data === null || data.franchigiaFrontaleGf === undefined) ? 0 : data.franchigiaFrontaleGf}
                                                variant="outlined"
                                            >
                                                <option
                                                    value={0}
                                                >
                                                    Seleziona la franchigia frontale
                                                </option>
                                                {franchigiaFrontaleGf.map(option => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.nPianiGf !== undefined && data.nPianiGf !== ""
                                                }
                                                onChange={handleChangeChecked("nPianiGf")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="nPianiGf"
                                                label="Numero piani"
                                                name="nPianiGf"
                                                value={data === null ? "" : data.nPianiGf}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.nFabbricatiGf !== undefined && data.nFabbricatiGf !== ""
                                                }
                                                onChange={handleChangeChecked("nFabbricatiGf")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="nFabbricatiGf"
                                                label="Numero fabbricati"
                                                name="nFabbricatiGf"
                                                value={data === null ? "" : data.nFabbricatiGf}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.nUnitaImmobiliariGf !== undefined && data.nUnitaImmobiliariGf !== ""
                                                }
                                                onChange={handleChangeChecked("nUnitaImmobiliariGf")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="nUnitaImmobiliariGf"
                                                label="Numero unità immobiliari"
                                                name="nUnitaImmobiliariGf"
                                                value={data === null ? "" : data.nUnitaImmobiliariGf}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.annoCostruzioneRistrutturazioneGf !== undefined && data.annoCostruzioneRistrutturazioneGf !== ""
                                                }
                                                onChange={handleChangeChecked("annoCostruzioneRistrutturazioneGf")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="annoCostruzioneRistrutturazioneGf"
                                                label="Anno di costruzione/ristrutturazione"
                                                name="annoCostruzioneRistrutturazioneGf"
                                                value={data === null ? "" : data.annoCostruzioneRistrutturazioneGf}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.tettoGf !== undefined && data.tettoGf !== ""
                                                }
                                                onChange={handleChangeChecked("tettoGf")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Tetto"
                                                name="tettoGf"
                                                onChange={handleChangeValue}
                                                select
                                                SelectProps={{ native: true }}
                                                value={(data === null || data.tettoGf === undefined) ? 0 : data.tettoGf}
                                                variant="outlined"
                                            >
                                                <option
                                                    value={0}
                                                >
                                                    Seleziona il tipo di tetto
                                                </option>
                                                {tettoGf.map(option => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.pannelliGf !== undefined && data.pannelliGf !== ""
                                                }
                                                onChange={handleChangeChecked("pannelliGf")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Presenza pannelli solari e/o fotovoltaici"
                                                name="pannelliGf"
                                                onChange={handleChangeValue}
                                                select
                                                SelectProps={{ native: true }}
                                                value={(data === null || data.pannelliGf === undefined) ? 0 : data.pannelliGf}
                                                variant="outlined"
                                            >
                                                <option
                                                    value={0}
                                                >
                                                    Seleziona se presenti
                                                </option>
                                                {pannelliGf.map(option => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.cappottoGf !== undefined && data.cappottoGf !== ""
                                                }
                                                onChange={handleChangeChecked("cappottoGf")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Presenza cappotto termico"
                                                name="cappottoGf"
                                                onChange={handleChangeValue}
                                                select
                                                SelectProps={{ native: true }}
                                                value={(data === null || data.cappottoGf === undefined) ? 0 : data.cappottoGf}
                                                variant="outlined"
                                            >
                                                <option
                                                    value={0}
                                                >
                                                    Seleziona se presente
                                                </option>
                                                {cappottoGf.map(option => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.valoreFabbricatoGf !== undefined && data.valoreFabbricatoGf !== ""
                                                }
                                                onChange={handleChangeChecked("valoreFabbricatoGf")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="valoreFabbricatoGf"
                                                label="Valore Fabbricato"
                                                name="valoreFabbricatoGf"
                                                value={data === null ? "" : data.valoreFabbricatoGf}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.emailGf !== undefined && data.emailGf !== ""
                                                }
                                                onChange={handleChangeChecked("emailGf")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="emailGf"
                                                label="Email"
                                                name="emailGf"
                                                value={data === null ? "" : data.emailGf}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.phoneNumberGf !== undefined && data.phoneNumberGf !== ""
                                                }
                                                onChange={handleChangeChecked("phoneNumberGf")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="phoneNumberGf"
                                                label="Numero cellulare"
                                                name="phoneNumberGf"
                                                value={data === null ? "" : data.phoneNumberGf}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.nameGf !== undefined && data.nameGf !== ""
                                                }
                                                onChange={handleChangeChecked("nameGf")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="nameGf"
                                                label="Nome"
                                                name="nameGf"
                                                value={data === null ? "" : data.nameGf}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.surnameGf !== undefined && data.surnameGf !== ""
                                                }
                                                onChange={handleChangeChecked("surnameGf")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="surnameGf"
                                                label="Cognome"
                                                name="surnameGf"
                                                value={data === null ? "" : data.surnameGf}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.fiscalCodeGf !== undefined && data.fiscalCodeGf !== ""
                                                }
                                                onChange={handleChangeChecked("fiscalCodeGf")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="fiscalCodeGf"
                                                label="Codice fiscale"
                                                name="fiscalCodeGf"
                                                value={data === null ? "" : data.fiscalCodeGf}
                                                onChange={handleChangeValue}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.impIdraulicoGf !== undefined && data.impIdraulicoGf !== ""
                                                }
                                                onChange={handleChangeChecked("impIdraulicoGf")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Impianto idraulico dotato di dichiarazione conformità L. 46/90 o D.M. 37/2008"
                                                name="impIdraulicoGf"
                                                onChange={handleChangeValue}
                                                select
                                                SelectProps={{ native: true }}
                                                value={(data === null || data.impIdraulicoGf === undefined) ? 0 : data.impIdraulicoGf}
                                                variant="outlined"
                                            >
                                                <option
                                                    value={0}
                                                >
                                                    Seleziona se conforme
                                                </option>
                                                {impIdraulicoGf.map(option => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox
                                                checked={
                                                    data !== null && data.impElettricoGf !== undefined && data.impElettricoGf !== ""
                                                }
                                                onChange={handleChangeChecked("impElettricoGf")}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                label="Impianto elettrico dotato di dichiarazione conformità L. 46/90 o D.M. 37/2008"
                                                name="impElettricoGf"
                                                onChange={handleChangeValue}
                                                select
                                                SelectProps={{ native: true }}
                                                value={(data === null || data.impElettricoGf === undefined) ? 0 : data.impElettricoGf}
                                                variant="outlined"
                                            >
                                                <option
                                                    value={0}
                                                >
                                                    Seleziona se conforme
                                                </option>
                                                {impElettricoGf.map(option => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <TableContainer className={classes.table} component={Paper}>
                            <Table aria-label="CVT">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Garanzia</TableCell>
                                        <TableCell>Selezionato</TableCell>
                                        <TableCell>Obbligatorio</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {guaranteesGf.map((warranty, index) => (
                                        <TableRow>
                                            <TableCell>
                                                {warranty.description}
                                            </TableCell>
                                            <TableCell>
                                                <Checkbox
                                                    checked={
                                                        data !== null && data["GF_" + warranty.code] !== undefined && data["GF_" + warranty.code] & 1
                                                    }
                                                    onChange={handleBitChecked(1, "GF_" + warranty.code)}
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Checkbox
                                                    checked={
                                                        data !== null && data["GF_" + warranty.code] !== undefined && data["GF_" + warranty.code] & 2
                                                    }
                                                    onChange={handleBitChecked(2, "GF_" + warranty.code)}
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TabPanel>
                </div>

                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity={alertseverity}>
                        {alertmsg}
                    </Alert>
                </Snackbar>
            </CardContent>

            <CardActions>
                <Button onClick={handleSave} startIcon={<SaveIcon />} size="small">Salva</Button>
            </CardActions>
        </Card>
    );
};