import React from 'react'
import {URL_SSO_INSURSOFT} from '../config/config';
import Auth from '../auth/auth';
import moment from 'moment';

//https://crm-quotiamo.insursoft.it/sso-auth/{id_collaboratore}/{cc}


/*Buongiorno,
come da accordi, vi inviamo le informazioni necessarie per mettere in comunicazioni i vostri sistemi con i nostri.

Per il Single Sign On, dovreste generare un URL composto come segue:

https://crm-quotiamo.insursoft.it/sso-auth/{id_collaboratore}/{cc}

dove

id_collaboratore sarà il vostro ID univoco del collaboratore e cc sarà un codice di controllo dinamico generato all'occorrenza.

Il codice di controllo sarà generato come segue:
md5(id_collaboratore + '-' + email_collaboratore + '#Date=' + 'Y-m-d-H')

Es. md5('124-collaboratore@quotiamo.it#Date=2023-03-15-13')

Il link punterà al nostro CRM, il quale effettuerà le dovute verifiche sul codice di controllo, attivando così la sessione di navigazione per il collaboratore di riferimento.

Per quanto riguarda l'importazione delle vostre anagrafiche dei collaboratori nei nostri sistemi, ci aspettiamo che venga esposta una rotta Api RESTful che dia in risposta la seguente struttura dati:
*/

export default function ProfessionalRC() {
    

    React.useEffect(() => {
        var md5 = require('md5');
        const userData= Auth.getUser();
        const timeout = setTimeout(() => {
          //redirects to an external URL          
          /*var id="5";
          var email="sebastiano.tosi@finital.it";*/
          //console.log(moment().format("YYYY-MM-DD-HH"));
          //console.log(URL_SSO_INSURSOFT+id+"/"+md5(id+"-"+email+"#Date="+moment().format("YYYY-MM-DD-hh")));
          //console.log(URL_SSO_INSURSOFT+userData.id+"/"+md5(userData.id+"-"+userData.email+"#Date="+moment().format("YYYY-MM-DD-hh")));
          window.location.replace(URL_SSO_INSURSOFT+userData.id+"/"+md5(userData.id+"-"+userData.email+"#Date="+moment().format("YYYY-MM-DD-HH"))+"/"+Buffer.from("rc-professionale/step-1|"+window.location.protocol+"//"+window.location.hostname+"/home").toString('Base64'));
        }, 1000);
    
        return () => clearTimeout(timeout);
      }, []);
    
      return <>Autenticazione in corso....</>;

}