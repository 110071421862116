import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MaterialTable from 'material-table';
import { id_cliente, ReturnInfoClient } from '../containers/logincontext';
import { API_URL_ALLLINKS } from '../config/config';
import { green, red } from '@material-ui/core/colors';
import Snackbar from '@material-ui/core/Snackbar';
import Auth from '../auth/auth';
import axios from 'axios';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  cardMaxSize: {
    width: '100%'
  },
  alignFab: {
    marginLeft: 'auto',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  img: {
    maxWidth: '80px',
    maxHeight: '40px',
    justifyContent: 'center'
  },
  red_fill: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
  },
  green_fill: {
    color: '#fff',
    backgroundColor: green[500],
  },
  red: {
    color: red[500]

  },
  green: {
    color: green[500]
  },
  customBadgeOk: {
    backgroundColor: green[500],
    color: "white"
  },
  customBadgeKo: {
    backgroundColor: red[500],
    color: "white"
  }
}));

export default function FactoriesLinksTable(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [alertseverity, setAlertSeverity] = React.useState("success");
  const [alertmsg, setAlertMsg] = React.useState(false);
  const [linksList, setLinksList] = React.useState(null);
  const [isSave, setIsSave] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  React.useEffect(() => {
    const url = API_URL_ALLLINKS + "/" + props.dataPanel.id;
    axios.get(url,
      {
        headers: {
          'Authorization': 'Bearer ' + Auth.getToken(),
          'Content-Type': 'application/json'
        }
      }
    ).then(payload => {
      setLinksList(payload.data.links);
    });
  }, [isSave]);

  return (<Card className={classes.cardMaxSize}>
    <CardContent  >
      {linksList !== null &&
        <MaterialTable
          title={"URL link " + props.dataPanel.description}
          columns={[
            {
              title: 'Id',
              field: 'id',
              editable: false
            },
            {
              title: 'Descrizione',
              field: 'description',
              render: rowData => rowData.description
            },
            {
              title: 'URL link',
              field: 'link',
              render: rowData => rowData.link === null ? "" : rowData.link
            },
          ]}
          localization={{
            pagination: {
              labelDisplayedRows: '{from}-{to} di {count}',
              labelRowsSelect: 'per pagina',
              labelRowsPerPage: 'per pagina',
              firstAriaLabel: 'prima pagina',
              firstTooltip: 'prima pagina',
              previousAriaLabel: 'pagina precedente',
              previousTooltip: 'pagina precedente',
              nextAriaLabel: 'pagina successiva',
              nextTooltip: 'pagina successiva',
              lastAriaLabel: 'ultima pagina',
              lastTooltip: 'ultima pagina',
            },
            toolbar: {
              nRowsSelected: '{0} elementi selezionati',
              searchTooltip: 'Ricerca',
              searchPlaceholder: 'Ricerca'
            },
            header: {
              actions: ''
            },
            body: {
              emptyDataSourceMessage: 'Nessun record da visualizzare',
              filterRow: {
                filterTooltip: 'Filtri'
              },
              editRow: {
                saveTooltip: "Conferma",
                cancelTooltip: "Annulla",
                deleteText: "Confermi la cancellazione ?"
              },
              addTooltip: "Aggiungi",
              deleteTooltip: "Cancella",
              editTooltip: "Modifica",
            }
          }}
          options={{
            pageSize: 10,
            pageSizeOptions: [5, 10],
            paginationType: "stepped",
            search: true,
            headerStyle: {
              backgroundColor: ReturnInfoClient(id_cliente, "primary_color"),
              color: '#ffffff',
              '&:hover': {
                color: '#ffffff',
              },
              '&:active': {
                color: '#ffffff',
              }
            }

          }}
          data={linksList}
          editable={{
            onRowAdd: newData =>
              new Promise((resolve, reject) => {
                const url = API_URL_ALLLINKS + "/" + props.dataPanel.id;
                axios.put(url, {
                  description: newData.description,
                  link: newData.link,
                },
                  {
                    headers: {
                      'Authorization': 'Bearer ' + Auth.getToken(),
                      'Content-Type': 'application/json'
                    }
                  }
                ).then(payload => {
                  if (payload.status === 200) {
                    setAlertMsg("Inserimento dati avvenuto con successo");
                    setAlertSeverity("success");
                    setIsSave(!isSave);
                  } else {
                    setAlertMsg("Errore durante l'inserimento dati");
                    setAlertSeverity("error");
                  }
                  setOpen(true);
                }).catch((error) => {
                  setAlertMsg(error.response.data.error);
                  setAlertSeverity("error");
                  setOpen(true);
                });
                resolve();
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                const index = oldData.id;
                const url = API_URL_ALLLINKS + "/" + index;
                axios.patch(url, {
                  description: newData.description,
                  link: newData.link,
                },
                  {
                    headers: {
                      'Authorization': 'Bearer ' + Auth.getToken(),
                      'Content-Type': 'application/json'
                    }
                  }
                ).then(payload => {
                  if (payload.status === 200) {
                    setAlertMsg("Aggiornamento avvenuto con successo");
                    setAlertSeverity("success");
                    setIsSave(!isSave);
                  } else {
                    setAlertMsg("Errore durante l'aggiornamento");
                    setAlertSeverity("error");
                  }
                  setOpen(true);
                }).catch((error) => {
                  setAlertMsg(error.response.data.error);
                  setAlertSeverity("error");
                  setOpen(true);
                });
                resolve();
              }),
            onRowDelete: oldData =>
              new Promise((resolve, reject) => {
                const index = oldData.id;
                const url = API_URL_ALLLINKS + "/" + index;
                axios.delete(url,
                  {
                    headers: {
                      'Authorization': 'Bearer ' + Auth.getToken(),
                      'Content-Type': 'application/json'
                    }
                  }
                ).then(payload => {
                  if (payload.status === 200) {
                    setAlertMsg("Cancellazione avvenuta con successo");
                    setAlertSeverity("success");
                    setIsSave(!isSave);
                  } else {
                    setAlertMsg("Errore durante la cancellazione");
                    setAlertSeverity("error");
                  }
                  setOpen(true);
                }).catch((error) => {
                  setAlertMsg(error.response.data.error);
                  setAlertSeverity("error");
                  setOpen(true);
                });
                resolve();
              }),
          }}
        />
      }
    </CardContent>
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={alertseverity} closeText="Chiudi">
        {alertmsg}
      </Alert>
    </Snackbar>
  </Card>
  );
};

