import React from 'react';
import {makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MaterialTable from 'material-table';
import {id_cliente,ReturnInfoClient} from '../containers/logincontext';
import {API_URL_GROUPS,API_URL_ALLGOODS} from '../config/config';
import BlockIcon from '@material-ui/icons/Block';
import { green, red } from '@material-ui/core/colors';
import Tooltip from '@material-ui/core/Tooltip';
import axios   from 'axios';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Auth from '../auth/auth';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import EditDefaultParameterData from './EditDefaultParameterData';
import EditDiscountsData from './EditDiscountsData';
import EditPaymentsData from './EditPaymentsData';
import EditConventionsData from './EditConventionsData';
import EditFactoriesProperties from './EditFactoriesProperties';
import EditCompanyProfileData from './EditCompanyProfileData';

const useStyles = makeStyles((theme) => ({
    alignFab: {
        marginLeft: 'auto',
      },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),      
    },
    img: {    
        display: 'block',
        maxWidth: '100%',
        maxHeight: '40px',
    },

    img_service: {    
        display: 'block',
        maxWidth: '40px',
        maxHeight: '20px',
    },
    red: {
        color: red[500],        
    },
    green: {
        color: green[500],        
    },
}));

export default function GroupsTable(props) {    
    const classes = useStyles();
    const [goods,setGoods]= React.useState([]);    
     
    
    React.useEffect(() => {                
      const url=API_URL_ALLGOODS;
          axios.get(url,
          {
              headers:{
              'Authorization': 'Bearer '+Auth.getToken(),
              'Content-Type': 'application/json'
              }
          }
      ).then(payload=>{                       
        setGoods(payload.data.goods);                                   
      }) ;        
              
    },[]);      
    const convertBase64 = (file) =>{
        return new Promise((resolve,reject)=>{
            const fileReader = new FileReader();

            fileReader.readAsDataURL(file);
            fileReader.onload = (()=>{
                  resolve(fileReader.result)
            });

            fileReader.onerror = ((error)=>{
                reject(error);
            })

        }
        
        )
    }

    const uploadImage = async (e,props)=>{
      const file=e.target.files[0];

      const base64 = await convertBase64(file);      
      props.onChange(base64);            
    };
        
    return (  <Card>
                    <CardContent>
                    <MaterialTable 
                        title= {"Gruppi " + props.datagroups.description} 
                        columns={[                                                                                                              
                                { title: 'Descrizione', field: 'description' },                                
                                { title: 'Stato', 
                                  field: 'enabled',
                                  lookup: { 1: 'Abilitato', 0: 'Disabilitato' },
                                  render: rowData =><Tooltip title={rowData.enabled===1 ? "Abilitato":"Disabilitato"}>                                                                                                        
                                                        {rowData.enabled===1 ? <CheckCircleOutlineIcon className={classes.green} />:<BlockIcon className={classes.red}/>}                                                    
                                            </Tooltip>    
                                },          
                                { 
                                    title: 'Logo', 
                                      field: 'image' ,
                                      align: 'center',                                  
                                      render: rowData =>
                                      <div>
                                        {rowData.image!==null && rowData.image!=="" &&
                                            <img className={classes.img} alt={rowData.description} src={rowData.image} />                                  
                                        }
                                      </div>
                                      ,                           
                                      editComponent: props => {
                                          return <div>
                                                  <input type="file" onChange={(e)=>{
                                                      const base64= uploadImage(e,props);                                                  
                                                      props.onChange(base64);
                                                  }} />
    
                                                  <img alt="Immagine da caricare" className={classes.img} src={props.value}/>
                                                  
                                              </div>
                                        }     
                                      
                                    },                                                                                       
                                ]}

                        detailPanel={[
                                    {
                                      tooltip: 'Impostazioni gruppo',
                                      render: rowData => {
                                        return (
                                            <div>
                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Typography className={classes.heading}>Dati gruppo</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                    <EditCompanyProfileData type="grp" data={rowData}/>
                                                    </AccordionDetails>                                                
                                                </Accordion>
                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel2-header"
                                                    >
                                                        <Typography className={classes.heading}>Parametri default</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <EditDefaultParameterData type="grp" data={rowData}  orgid={rowData.id_organizations}/>
                                                    </AccordionDetails>
                                                            
                                                </Accordion>

                                                {goods.map((good,index) => (
                                                <Accordion key={index} disabled={!good.enabled}>
                                                    <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel2-header"
                                                    >
                                                    <Typography className={classes.heading}>Fabbriche abilitate: {good.description}</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <EditFactoriesProperties type="grp" datagroups={rowData} good={good}/>                                                    
                                                    </AccordionDetails>
                                                    
                                                </Accordion>
                                                ))
                                                }
                                                
                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel2-header"
                                                    >
                                                        <Typography className={classes.heading}>Sconti</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>                                                        
                                                        <EditDiscountsData type="grp" id={rowData.id}  orgid={rowData.id_organizations}/>
                                                    </AccordionDetails>                                                        
                                                </Accordion>

                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel2-header"
                                                    >
                                                        <Typography className={classes.heading}>Convenzioni</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>                                                        
                                                        <EditConventionsData type="grp" id={rowData.id}  orgid={rowData.id_organizations}/>
                                                    </AccordionDetails>                                                        
                                                </Accordion>
                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel2-header"
                                                    >
                                                        <Typography className={classes.heading}>Sistemi di pagamento</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>                                                        
                                                        <EditPaymentsData type="grp" id={rowData.id}  orgid={rowData.id_organizations}/>
                                                    </AccordionDetails>                                                        
                                                </Accordion>
                                                                                            
                                            </div>
                                        
                                        )
                                      },
                                    },
                                  ]
                                  }


                        localization={{
                            pagination: {
                                labelDisplayedRows: '{from}-{to} di {count}',
                                labelRowsSelect: 'per pagina',
                                labelRowsPerPage : 'per pagina',
                                firstAriaLabel: 'prima pagina',
                                firstTooltip : 'prima pagina',
                                previousAriaLabel: 'pagina precedente',
                                previousTooltip: 'pagina precedente',
                                nextAriaLabel: 'pagina successiva',
                                nextTooltip: 'pagina successiva',
                                lastAriaLabel: 'ultima pagina',
                                lastTooltip: 'ultima pagina',
                            },
                            toolbar: {  
                                nRowsSelected: '{0} elementi selezionati',
                                searchTooltip: 'Ricerca',
                                searchPlaceholder : 'Ricerca'
                            },                            
                            header: {
                                actions: ''
                            },
                            body: {
                                emptyDataSourceMessage: 'Nessun record da visualizzare',
                                filterRow: {
                                    filterTooltip: 'Filtri'
                                },
                                editRow:{
                                    saveTooltip: "Conferma",
                                    cancelTooltip: "Annulla",
                                    deleteText: "Sei sicuro di voler cancellare questo gruppo?"
                                },
                                addTooltip: "Aggiungi",
                                deleteTooltip: "Cancella",
                                editTooltip: "Modifica",
                            }                          
                        }}
                        options={{
                            pageSize: 10,
                            pageSizeOptions: [5,10],
                            paginationType: "stepped",
                            search: true,
                            headerStyle: {
                            backgroundColor: ReturnInfoClient(id_cliente,"primary_color"),
                            color:  '#ffffff',    
                                    '&:hover': {
                                        color: '#ffffff',
                                    },
                                    '&:active': {
                                        color: '#ffffff',
                                    }          
                            }
            
                        }}
                        data={query =>
                                new Promise((resolve, reject) => {            
                                    let url = API_URL_GROUPS + "/"+ props.datagroups.id;
                                        url += '?per_page=' + query.pageSize
                                        url += '&page=' + (query.page + 1)

                                    if(query.search.length>3){
                                        url += '&search=' + query.search
                                    }            
                                    if(query.orderBy!==undefined){                                    
                                        url += '&orderby=' + query.orderBy.field
                                        url += '&orderdirection=' + query.orderDirection                                    
                                    }
            
                                    const requestOptions = {                                    
                                        headers: { 
                                            'Content-Type': 'application/json',
                                            'Authorization': 'Bearer '+Auth.getToken(),                
                                        },              
                                    };


                                    fetch(url,requestOptions)            
                                    .then(                  
                                        response => response.json()
                                    )
                                    .then(                
                                        result => {                                      
                                        resolve({
                                        data: result.data,                  
                                        page: result.current_page-1,
                                        totalCount: result.total,
                                        })
                                    })
                                })
                            }
                            editable={{
                                onRowAdd: newData =>
                                  new Promise((resolve, reject) => {
                                      const url=API_URL_GROUPS ;
                                      axios.put(url,{
                                          description:newData.description,                                          
                                          enabled:newData.enabled,          
                                          id_organizations: props.datagroups.id,
                                          image:newData.image,
                                          businessname:newData.businessname,
                                          rui:newData.rui,
                                          registrationdate:newData.registrationdate,
                                          vatnumber:newData.vatnumber,
                                          fiscalcode:newData.fiscalcode,
                                          toponym:newData.toponym,
                                          address:newData.address,
                                          civicnumber:newData.civicnumber,
                                          cap:newData.cap,
                                          city:newData.city,
                                          province:newData.province,
                                      },
                                      {
                                          headers:{
                                            'Authorization': 'Bearer '+Auth.getToken(),
                                            'Content-Type': 'application/json'
                                          }
                                      }
                                    ).then(payload=>{                                        
                                        
                                    }) ;        
                                    resolve();                                                                
                                  }),
                                onRowUpdate: (newData, oldData) =>
                                  new Promise((resolve, reject) => {
                                    const index = oldData.id;                                    
                                    const url=API_URL_GROUPS +  "/" + index;                                    
                                    axios.patch(url,{
                                        description:newData.description,                                        
                                        enabled:newData.enabled,               
                                        id_organizations: props.datagroups.id,
                                        image:newData.image,
                                        businessname:newData.businessname,
                                        rui:newData.rui,
                                        registrationdate:newData.registrationdate,
                                        vatnumber:newData.vatnumber,
                                        fiscalcode:newData.fiscalcode,
                                        toponym:newData.toponym,
                                        address:newData.address,
                                        civicnumber:newData.civicnumber,
                                        cap:newData.cap,
                                        city:newData.city,
                                        province:newData.province,        
                                        printfooter :newData.printfooter,                        
                                      },
                                      {
                                          headers:{
                                            'Authorization': 'Bearer '+Auth.getToken(),
                                              'Content-Type': 'application/json'
                                          }
                                      }
                                    ).then(payload=>{
                                        
                                    }) ;                                                                        
                                    resolve();
                                    
                                  }),
                                onRowDelete: 
                                
                                oldData =>
                                new Promise((resolve, reject) => {
                                    
                                    const index = oldData.id;                                    
                                    const url=API_URL_GROUPS  + "/" + index;
                                    
                                    axios.delete(url,
                                    {
                                        headers:{
                                          'Authorization': 'Bearer '+Auth.getToken(),
                                            'Content-Type': 'application/json'
                                        }
                                    }
                                    ).then(payload=>{
                                        
                                    }) ;                                                                        
                                    resolve();
                                  })}
                              }
                        />

                    </CardContent>        
                    
                    
                </Card>          
      );
};


