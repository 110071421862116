import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import axios from 'axios';
import Link from '@material-ui/core/Link';
import { API_URL_QUOTATION_LIST_BY_DATE, API_URL_FACTORIES_LIST } from '../config/config';
import Typography from '@material-ui/core/Typography';
import { NAME_APP } from '../config/config';
import { green, red } from '@material-ui/core/colors';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Auth from '../auth/auth';

const useStyles = makeStyles((theme) => ({
    CompanyInfo: {
        /*transform: "rotate(90deg)"*/
        /*fontSize:"14px"*/
    },
    CompanyDetails: {
        /*fontSize:"10px"*/
    },
    alignFab: {
        marginLeft: 'auto',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    img: {
        maxWidth: 'auto',
        maxHeight: '40px',
        justifyContent: 'center'
    },
    dialog: {
        minWidth: 500
    },
    red: {
        color: red[500],
    },
    green: {
        color: green[500],
    },
}));

function returnInfoFactory(detail, paramurl) {
    let label = "--";

    JSON.parse(detail).forEach(element => {
        if (element.company.paramurl === paramurl) {
            if (element.datipre === undefined) {
                label = "--";
            } else {
                if (element.datipre.prices === undefined) {
                    label = element.datipre.error;
                    if (element.datipre.error === "Service Unavailable") {
                        label = "KO";
                    }
                } else {
                    label = "OK";
                }
            }
        }
    });
    return label;
}

function returnInfoFactoryPerc(detail, printok) {
    let conta = 0;
    let contaok = 0;
    let contako = 0;
    let calcolaPerc = 0;

    JSON.parse(detail).forEach(element => {
        if (element.datipre === undefined) {
        } else {
            conta++;
            if (element.datipre.prices === undefined) {
                contako++;
            } else {
                contaok++;
            }
        }
    });
    if (conta > 0) {
        if (printok) {
            calcolaPerc = 100 * contaok / conta;
        } else {
            calcolaPerc = 100 * contako / conta;
        }

        return calcolaPerc.toFixed(0);
    } else {
        return "--";
    }

}

export default function QuotationListByDate() {
    const classes = useStyles();
    const [quotations, setQuotations] = React.useState([]);
    const [factories, setFactories] = React.useState([]);

    React.useEffect(() => {
        const url = API_URL_FACTORIES_LIST;
        axios.get(url,
            {
                headers: {
                    'Authorization': 'Bearer ' + Auth.getToken(),
                    'Content-Type': 'application/json'
                }
            }
        ).then(payload => {
            setFactories(payload.data.factories);
        });
    }, []);

    React.useEffect(() => {
        const url = API_URL_QUOTATION_LIST_BY_DATE;
        axios.get(url,
            {
                /*params: {
                   from: "2021-07-01",
                   to: "2021-07-10",
                },*/
                headers: {
                    'Authorization': 'Bearer ' + Auth.getToken(),
                    'Content-Type': 'application/json'
                }
            }
        ).then(payload => {
            setQuotations(payload.data.quotation);
        });
    }, []);

    return (
        <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" href="/" >
                        {NAME_APP}
                    </Link>
                    <Typography color="textPrimary">Preventivi</Typography>
                </Breadcrumbs>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Targa</TableCell>
                                <TableCell>Tipo</TableCell>
                                <TableCell>Rete</TableCell>
                                <TableCell>POS</TableCell>
                                <TableCell>Utente</TableCell>
                                {factories.map((factory) => (
                                    <TableCell key={factory.id} className={classes.CompanyInfo}>{factory.description}</TableCell>
                                ))}
                                <TableCell>Emessi (%)</TableCell>
                                <TableCell>Errore (%)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {quotations.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell component="th" scope="row">
                                        {JSON.parse(row.clientinfo).vehicleInfo.licensePlate}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {JSON.parse(row.clientinfo).clientinfo.subjectType === "1" || JSON.parse(row.clientinfo).clientinfo.subjectType === undefined ? "FIS" : "GIU"}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.grpdescr}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.agdescr}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.surname}<br />
                                        {row.name}
                                    </TableCell>
                                    {factories.map((factory) => (
                                        <TableCell className={classes.CompanyDetails}>{returnInfoFactory(row.detail, factory.paramurl)}</TableCell>
                                    ))}
                                    <TableCell> {returnInfoFactoryPerc(row.detail, true)} %</TableCell>
                                    <TableCell> {returnInfoFactoryPerc(row.detail, false)} %</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
        </main>
    );
};