import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { green, red } from '@material-ui/core/colors';
import { API_URL_DISCOUNTS, API_URL_CONVENTIONS, API_URL_ALLGUARANTEES, elencoCVTCar } from '../config/config';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Auth from '../auth/auth';
import TableHead from '@material-ui/core/TableHead';

//elencoCVT

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  alignFab: {
    marginLeft: 'auto',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  img: {
    display: 'block',
    maxWidth: '100%',
    maxHeight: '40px',
    minWidth: '50px',

  },

  img_service: {
    display: 'block',
    maxWidth: '40px',
    maxHeight: '20px',
  },
  red: {
    color: red[500],
  },
  green: {
    color: green[500],
  },
  hiddenDiv: {
    display: 'none'
  },


}));

function ReturnListCVT(elencoCVT) {
  let arrayCVT = [];
  elencoCVT.map((item) => {
    arrayCVT.push({
      [item.value]: {
        "perc": "0",
        "val": "0"
      }
    });
    return false;
  });

  return arrayCVT;
}

function ReturnListCVTAdd(dataJson, elencoCVT) {
  let arrayCVT = [];
  elencoCVT.map((item) => {
    let garanzia = dataJson.find((cvt) => cvt[item.value]);
    if (!garanzia) {
      arrayCVT.push({
        [item.value]: {
          "perc": "0",
          "val": "0"
        }
      });
      return false;
    } else {
      arrayCVT.push({
        [item.value]: {
          "perc": garanzia[item.value].perc,
          "val": garanzia[item.value].val
        }
      });
      return false;
    }
  });
  return arrayCVT;
}

/*function getNodeIds(nodes) {
    let ids = [];
  
    if(nodes!==undefined){
      nodes.forEach(({ value, children }) => {
        ids = [...ids, value, ...getNodeIds(children)];
      });
    }
    
  
    return ids;
  }*/

export default function EditDiscountParameter(props) {
  const classes = useStyles();
  const [data, setData] = React.useState(props.data.discounts_rules === null ? [] : JSON.parse(props.data.discounts_rules));
  const [open, setOpen] = React.useState(false);
  const [alertseverity, setAlertSeverity] = React.useState("success");
  const [alertmsg, setAlertMsg] = React.useState(false);
  const [elencoCVT, setElencoCVT] = React.useState([]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  React.useEffect(() => {
    const url = API_URL_ALLGUARANTEES + "/" + props.datatype + "/enabled";
    axios.get(url,
      {
        headers: {
          'Authorization': 'Bearer ' + Auth.getToken(),
          'Content-Type': 'application/json'
        }
      }
    ).then(payload => {
      let new_elenco = [];
      payload.data.guarantees.forEach((item, key) => {
        new_elenco.push({
          value: item.code,
          label: item.description
        });
      });
      setElencoCVT(new_elenco);
      if (props.data.discounts_rules === null) {
        setData(ReturnListCVT(new_elenco));
      } else if (data.length != new_elenco.length) {
        setData(ReturnListCVTAdd(data, new_elenco));
      }
    });
  }, [props.datatype, props.data.discounts_rules, props.data.id_organization]);

  const handleSave = () => {
    new Promise((resolve, reject) => {
      const index = props.data.id;
      let url =
        props.type === 1 ?
          API_URL_DISCOUNTS + "/" + index :
          API_URL_CONVENTIONS + "/" + index
        ;
      axios.patch(url, {
        ...props.data,
        discounts_rules: JSON.stringify(data),
        updateonly: "discounts_rules"
      },
        {
          headers: {
            'Authorization': 'Bearer ' + Auth.getToken(),
            'Content-Type': 'application/json'
          }
        }
      ).then(payload => {
        if (payload.status === 200) {
          setAlertMsg("Aggiornamento avvenuto con successo");
          setAlertSeverity("success");
        } else {
          setAlertMsg("Errore durante l'aggiornamento");
          setAlertSeverity("error");
        }
        setOpen(true);
      }).catch((error) => {
        setAlertMsg(error.response.data.error);
        setAlertSeverity("error");
        setOpen(true);
      });

      resolve();

    })
  }

  const handleChangeValue = (bitValue, checkName) => (event) => {
    let NewData = data;
    let keysearch = Object.keys(checkName)[0];
    let index = -1;
    let ObjInsert;
    NewData.forEach((item, key) => {
      if (Object.keys(item)[0] === keysearch) {
        index = key;
        if (bitValue === 1) {
          ObjInsert = {
            [keysearch]: {
              "perc": Object.values(item)[0].perc,
              "val": event.target.value
            }
          };
        } else {
          ObjInsert = {
            [keysearch]: {
              "perc": event.target.value,
              "val": Object.values(item)[0].val
            }
          };
        }
      }
    })
    setData([
      ...NewData.slice(0, index),
      ObjInsert,
      ...NewData.slice(index + 1)
    ]
    );
  };

  return (
    <Card >
      <CardContent>
        <TableContainer className={classes.table}>
          <Table aria-label="CVT">
            <TableHead>
              <TableRow>
                <TableCell>Garanzia</TableCell>
                <TableCell>Importo Sconto</TableCell>
                <TableCell>Perc. Sconto</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {elencoCVT.length > 0 && data.map((item, key) => (
                <>
                  {elencoCVT[key] !== undefined &&
                    <TableRow>
                      <TableCell>
                        {elencoCVT[key].label}
                      </TableCell>
                      <TableCell>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="importo"
                          label="Importo"
                          name="importo"
                          autoFocus
                          value={Object.values(item)[0].val}
                          onChange={handleChangeValue(1, item)}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="perc"
                          label="Percentuale"
                          name="perc"
                          autoFocus
                          value={Object.values(item)[0].perc}
                          onChange={handleChangeValue(2, item)}
                        />
                      </TableCell>
                    </TableRow>
                  }
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={alertseverity}>
            {alertmsg}
          </Alert>
        </Snackbar>
      </CardContent>
      <CardActions>
        <Button onClick={handleSave} startIcon={<SaveIcon />} size="small">Salva</Button>
      </CardActions>
    </Card>
  );
};

/*
            <Typography className={classes.title} color="textSecondary" gutterBottom>
            Selezionando l'organizzazione si applica a tutte le reti, selezionado la rete si aspplica a tutti i punti vendita
            </Typography>                                              

            <CheckboxTree
                  nodes={datatree}
                  checked={checked}
                  expanded={expanded}
                  onCheck={checked => setChecked(checked)}
                  onExpand={expanded => setExpanded(expanded)}
                  showCheckbox={true}
                  noCascade={true}
                  showExpandAll={true}
                  
                  icons={{
                    check: <FontAwesomeIcon className="rct-icon rct-icon-check" icon={faCheckSquare }  />,
                    uncheck: <FontAwesomeIcon className="rct-icon rct-icon-uncheck" icon={faSquare} />,
                    halfCheck: <FontAwesomeIcon className="rct-icon rct-icon-half-check" icon={faCheckSquare} />,
                    expandClose: <FontAwesomeIcon className="rct-icon rct-icon-expand-close" icon={faChevronRight} />,
                    expandOpen: <FontAwesomeIcon className="rct-icon rct-icon-expand-open" icon={faChevronDown} />,
                    expandAll: <FontAwesomeIcon className="rct-icon rct-icon-expand-all" icon={faPlusSquare} />,
                    collapseAll: <FontAwesomeIcon className="rct-icon rct-icon-collapse-all" icon={faMinusSquare} />,
                    parentClose: <FontAwesomeIcon className="rct-icon rct-icon-parent-close" icon={faFolder} />,
                    parentOpen: <FontAwesomeIcon className="rct-icon rct-icon-parent-open" icon={faFolderOpen} />,
                    leaf: <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faFile} />
                }}
                />          

*/