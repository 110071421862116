import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { API_URL_QUOTATION_LIST, API_URL_INFOIMAGES_ALL, API_URL_FACTORIES_LIST, API_URL_QUOTATION_UPDATE, API_URL_ALLLINKS, API_URL_QUOTATION_PAYMENTS, impElettricoGf } from '../config/config';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NumberFormat from 'react-number-format';
import { Fragment } from 'react';
import QuotationDetails from './quotationdetails';
import QuotationPDF from './quotationpdf';
import { PDFViewer } from '@react-pdf/renderer';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import QRCodeGenerator from 'qrcode'
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { green, red } from '@material-ui/core/colors';
import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {
    kmAnnuiOption, drivingLicenseTypes, genders, tipoGuida, statoCivile, livelliIstruzione, subjectTypes, tipoAlimentazione, ElencoMassimaliAutocarri, etaGuidatorePiuGiovane,
    ElencoMassimali, tipoFrazionamento, domandeQuestionarioInfortunio, domandeQuestionarioMalattia, domandeQuestionarioTcm1, domandeQuestionarioTcm2
} from '../config/config';
import {
    durationsList,
    PianoList,
    PosizioneList,
    TipologiaList,
    PossessoList,
    TipoDimoraList,
    classeCostruttivaList,
    tipologiaCostruzioneList,
    clausoleInjuries,
    opzioniMesiDiGuidaMotorBike,
    opzioniCCDrivedMotorBike,
    beneficiariTcm,
    massimaliRcp,
    fasciaFatturatoRcp,
    professioniRcp,
    franchigiaFrontaleGf,
    tettoGf,
    pannelliGf,
    cappottoGf,
    domandeQuestionarioGf,
    tipologiaCostruzioneListGf,
    impIdraulicoGf
} from '../config/config';
import { returnDescription, returnLabel } from './utils';
import Chip from '@material-ui/core/Chip';
import Auth from '../auth/auth';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Carousel from 'react-material-ui-carousel'
import { CardMedia } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

/*
EMETTI POLIZZA

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Auth from '../auth/auth';
import AuthService from '../auth/authservice';
*/

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function LinearProgressWithLabel(props) {
    return (
        <Grid container spacing={3}>
            <Grid item xs={3}></Grid>
            <Grid item xs={6}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Stack sx={{ width: '100%', color: '#f9b406' }}>
                        <LinearProgress color="inherit" {...props} />
                    </Stack>
                    <Box sx={{ minWidth: 35 }}>
                        {/*<Typography variant="subtitle2">{`${Math.round(props.second)}s (${Math.round(props.value)}%)`}</Typography>*/}
                        <Typography variant="subtitle2">{`${Math.round(props.value)}%`}</Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={3}></Grid>
        </Grid>
    );
}

LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    tableCellList: {
        padding: "0 !important"
    },
    cardStyle: {
        width: "100%"
    },
    pdfform: {
        paddingTop: '64px',
        width: "100%",
        height: "100%"
    },
    TitleRow: {
        margin: "12px"
    },
    root: {
        display: 'flex',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
    },
    Carousel: {
        width: "100%",
        paddingTop: "20px",
        margin: "auto"
    },
    Media: {
        height: 900
    },
    divButtonPDF: {
        width: '100%',
        textAlign: 'right',
        paddingBottom: '10px'
    },
    alignFab: {
        marginLeft: 'auto',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    img: {
        maxWidth: 'auto',
        maxHeight: '40px',
        justifyContent: 'center'
    },
    imgCompany: {
        maxWidth: 'auto',
        maxHeight: '30px',
        justifyContent: 'center'
    },
    cellTitle: {
        fontWeight: "bold"
    },
    cellTitleSoon: {
        fontWeight: "bold",
        textAlign: "center"
    },
    cellTitleRight: {
        fontWeight: "bold",
        textAlign: "right"
    },
    cellPrice: {
        textAlign: "right"
    },
    cellSubtotalPrice: {
        fontWeight: "bold",
        textAlign: "right",
    },
    cellSubtotalTitle: {
        fontWeight: "bold",
    },
    cellFinalPrice: {
        fontWeight: "bold",
        textAlign: "right",
        fontSize: 18
    },
    cellFinalTitle: {
        fontWeight: "bold",
        fontSize: 18
    },
    tablePadding: {
        paddingTop: "100px"
    },
    titlePadding: {
        paddingTop: "20px",
        paddingBottom: "10px",
        textDecoration: "underline"
    },
    totalPadding: {
        paddingTop: "20px",
        paddingBottom: "10px",
    },
    titlePaddingPremio: {
        paddingTop: "20px",
        paddingBottom: "10px"
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    thirdHeading: {
        fontWeight: 'bold',
        color: '#f9b406',
        paddingTop: "20px",
        paddingLeft: "38px"
    },
    divContainer: {
        width: "100%"
    },
    titlePadding2: {
        fontSize: "16px",
        fontWeight: "600",
    },
    red: {
        color: red[500],
        fontSize: "16px",
        fontWeight: "600",
    },
    green: {
        color: green[500],
    },
    listpaymentok: {
        backgroundColor: '#e0e0e0'
    },
    listpayment: {
        backgroundColor: '#ffffff'
    },
    button: {
        minWidth: '200px',
        margin: '5px'
    }
}));

function CheckExpiredDate(Quotation) {
    if (Quotation.expired_date !== null) {
        return moment(Quotation.expired_date + " 23:59:59", "YYYY-MM-DD HH:mm:ss").isBefore(moment());
    } else {
        return moment(Quotation.created_at, "YYYY-MM-DD HH:mm:ss").add(7, "days").isBefore(moment());
    }
}

function HouseDetails(QuotationInfo, classes) {
    return <TableContainer component={Paper}>
        <Table className={classes.table}>
            <TableBody>
                <TableRow>
                    <TableCell className={classes.cellTitle}>
                        Data effetto
                    </TableCell>
                    <TableCell>
                        {moment(QuotationInfo.clientinfo.dataEffetto, "YYYY-MM-DD").format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell className={classes.cellTitle}>
                    </TableCell>
                    <TableCell>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.cellTitle}>
                        Durata anni
                    </TableCell>
                    <TableCell>
                        {returnLabel(durationsList, Number(QuotationInfo.clientinfo.durataAnni))}
                    </TableCell>
                    <TableCell className={classes.cellTitle}>
                        Frazionamento
                    </TableCell>
                    <TableCell>
                        {returnLabel(tipoFrazionamento, Number(QuotationInfo.clientinfo.frazionamento))}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.cellTitle}>
                        Ubicazione immobile
                    </TableCell>
                    <TableCell>
                        {QuotationInfo.clientinfo.toponimo} {QuotationInfo.clientinfo.indirizzo} {QuotationInfo.clientinfo.numeroCivico}
                    </TableCell>
                    <TableCell className={classes.cellTitle}>
                        Città
                    </TableCell>
                    <TableCell>
                        {QuotationInfo.clientinfo.cap} {QuotationInfo.clientinfo.citta} ({QuotationInfo.clientinfo.provinciaAbbr})
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.cellTitle}>
                        Classe costruttiva
                    </TableCell>
                    <TableCell>
                        {returnLabel(classeCostruttivaList, QuotationInfo.clientinfo.classeCostruttiva)}
                    </TableCell>
                    <TableCell className={classes.cellTitle}>
                        Tipologia costruzione
                    </TableCell>
                    <TableCell>
                        {returnLabel(tipologiaCostruzioneList, QuotationInfo.clientinfo.tipologiaCostruzione)}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.cellTitle}>
                        Scala interno
                    </TableCell>
                    <TableCell>
                        {QuotationInfo.clientinfo.scalaInterno}
                    </TableCell>
                    <TableCell className={classes.cellTitle}>
                        Anno di costruzione
                    </TableCell>
                    <TableCell>
                        {QuotationInfo.clientinfo.annoCostruzioneRistrutturazione}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.cellTitle}>
                        Superficie metri quadri
                    </TableCell>
                    <TableCell>
                        {QuotationInfo.clientinfo.superficieM2}
                    </TableCell>
                    <TableCell className={classes.cellTitle}>
                    </TableCell>
                    <TableCell>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.cellTitle}>
                        Piano
                    </TableCell>
                    <TableCell>
                        {returnLabel(PianoList, Number(QuotationInfo.clientinfo.piano))}
                    </TableCell>
                    <TableCell className={classes.cellTitle}>
                        Possesso
                    </TableCell>
                    <TableCell>
                        {returnLabel(PossessoList, QuotationInfo.clientinfo.tipologiaProp)}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.cellTitle}>
                        Tipologia immobile
                    </TableCell>
                    <TableCell>
                        {returnLabel(TipologiaList, QuotationInfo.clientinfo.tipologiaImm)}
                    </TableCell>
                    <TableCell className={classes.cellTitle}>
                        Tipo di dimora
                    </TableCell>
                    <TableCell>
                        {returnLabel(TipoDimoraList, QuotationInfo.clientinfo.dimora)}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.cellTitle}>
                        Posizione immobile
                    </TableCell>
                    <TableCell>
                        {returnLabel(PosizioneList, QuotationInfo.clientinfo.posizione)}
                    </TableCell>
                    <TableCell className={classes.cellTitle}>
                        Antifurto
                    </TableCell>
                    <TableCell>
                        {QuotationInfo.clientinfo.antifurto ? "Si" : "No"}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.cellTitle}>
                        Valore fabbricato
                    </TableCell>
                    <TableCell>
                        <NumberFormat fixedDecimalScale="true" decimalScale="2" value={QuotationInfo.clientinfo.valoreFabbricato} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} />
                    </TableCell>
                    <TableCell className={classes.cellTitle}>
                        Valore contenuto
                    </TableCell>
                    <TableCell>
                        <NumberFormat fixedDecimalScale="true" decimalScale="2" value={QuotationInfo.clientinfo.valoreContenuto} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.cellTitle}>
                        Numero persone nel nucleo familiare
                    </TableCell>
                    <TableCell>
                        {QuotationInfo.clientinfo.nPersNucleoFamiliare}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.cellTitle}>
                        Numero cani
                    </TableCell>
                    <TableCell>
                        {QuotationInfo.clientinfo.nCani}
                    </TableCell>
                    <TableCell className={classes.cellTitle}>
                        Numero gatti
                    </TableCell>
                    <TableCell>
                        {QuotationInfo.clientinfo.nGatti}
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    </TableContainer>;
}

function GfDetails(QuotationInfo, classes) {
    return <TableContainer component={Paper}>
        <Table className={classes.table}>
            <TableBody>
                <TableRow>
                    <TableCell className={classes.cellTitle}>
                        Data effetto
                    </TableCell>
                    <TableCell>
                        {moment(QuotationInfo.clientinfo.dataEffetto, "YYYY-MM-DD").format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell className={classes.cellTitle}>
                        In costruzione
                    </TableCell>
                    <TableCell>
                        {QuotationInfo.clientinfo.inCostruzione === "y" ? "Si" : "No"}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.cellTitle}>
                        Ubicazione immobile
                    </TableCell>
                    <TableCell>
                        {QuotationInfo.clientinfo.toponimo} {QuotationInfo.clientinfo.indirizzo} {QuotationInfo.clientinfo.numeroCivico}
                    </TableCell>
                    <TableCell className={classes.cellTitle}>
                        Città
                    </TableCell>
                    <TableCell>
                        {QuotationInfo.clientinfo.cap} {QuotationInfo.clientinfo.citta} ({QuotationInfo.clientinfo.provinciaAbbr})
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.cellTitle}>
                        Percentuale abitazioni/uffici
                    </TableCell>
                    <TableCell>
                        {QuotationInfo.clientinfo.percentualeAbUff}
                    </TableCell>
                    <TableCell className={classes.cellTitle}>
                        Tipologia costruzione
                    </TableCell>
                    <TableCell>
                        {returnLabel(tipologiaCostruzioneListGf, QuotationInfo.clientinfo.tipologiaCostruzione)}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.cellTitle}>
                        Tipologia immobile
                    </TableCell>
                    <TableCell>
                        {returnLabel(TipologiaList, QuotationInfo.clientinfo.tipologiaImm)}
                    </TableCell>
                    <TableCell className={classes.cellTitle}>
                        Franchigia frontale
                    </TableCell>
                    <TableCell>
                        {returnLabel(franchigiaFrontaleGf, parseInt(QuotationInfo.clientinfo.franchigiaFrontale))}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.cellTitle}>
                        Anno di costruzione/ristrutturazione
                    </TableCell>
                    <TableCell>
                        {QuotationInfo.clientinfo.annoCostruzioneRistrutturazione}
                    </TableCell>
                    <TableCell className={classes.cellTitle}>
                        Numero piani
                    </TableCell>
                    <TableCell>
                        {QuotationInfo.clientinfo.nPiani}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.cellTitle}>
                        Numero fabbricati
                    </TableCell>
                    <TableCell>
                        {QuotationInfo.clientinfo.nFabbricati}
                    </TableCell>
                    <TableCell className={classes.cellTitle}>
                        Numero unità immobiliari
                    </TableCell>
                    <TableCell>
                        {QuotationInfo.clientinfo.nUnitaImmobiliari}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.cellTitle}>
                        Tetto
                    </TableCell>
                    <TableCell>
                        {returnLabel(tettoGf, QuotationInfo.clientinfo.tetto)}
                    </TableCell>
                    <TableCell className={classes.cellTitle}>
                        Presenza pannelli solari e/o fotovoltaici
                    </TableCell>
                    <TableCell>
                        {returnLabel(pannelliGf, parseInt(QuotationInfo.clientinfo.pannelli))}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.cellTitle}>
                        Presenza cappotto termico
                    </TableCell>
                    <TableCell>
                        {returnLabel(cappottoGf, parseInt(QuotationInfo.clientinfo.cappotto))}
                    </TableCell>
                    <TableCell className={classes.cellTitle}>
                        Valore fabbricato
                    </TableCell>
                    <TableCell>
                        <NumberFormat fixedDecimalScale="true" decimalScale="2" value={QuotationInfo.clientinfo.valoreFabbricato} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.cellTitle}>
                        Impianto elettrico dotato di dichiarazione conformità L. 46/90 o D.M. 37/2008
                    </TableCell>
                    <TableCell>
                        {returnLabel(impElettricoGf, parseInt(QuotationInfo.clientinfo.impElettrico))}
                    </TableCell>
                    <TableCell className={classes.cellTitle}>
                        Impianto idraulico dotato di dichiarazione conformità L. 46/90 o D.M. 37/2008
                    </TableCell>
                    <TableCell>
                        {returnLabel(impIdraulicoGf, parseInt(QuotationInfo.clientinfo.impIdraulico))}
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    </TableContainer>;
}

function DetailPerson(PersonInfo, QuotationInfo, classes, expanded, handleChange, panel, title, ContractorType, product) {
    return <Accordion expanded={expanded === panel} onChange={handleChange(panel)}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
        >
            <Typography className={classes.heading}>{title}</Typography>
            <Typography className={classes.secondaryHeading}>
                {PersonInfo !== undefined ?
                    <Fragment>
                        {(PersonInfo.subjectType === "1"
                            || PersonInfo.subjectType === undefined
                            || PersonInfo.subjectType === "")
                            ?
                            PersonInfo.surname + " " + PersonInfo.name
                            :
                            PersonInfo.companyName
                        }
                    </Fragment> :
                    <Fragment>
                        {ContractorType === true &&
                            <>
                                {(QuotationInfo.clientinfo.contractorInfo.subjectType === "1"
                                    || QuotationInfo.clientinfo.contractorInfo.subjectType === undefined || QuotationInfo.clientinfo.contractorInfo.subjectType === "")
                                    ?
                                    QuotationInfo.clientinfo.contractorInfo.surname + " " + QuotationInfo.clientinfo.contractorInfo.name
                                    :
                                    QuotationInfo.clientinfo.contractorInfo.companyName
                                }
                            </>
                        }
                        {ContractorType === false &&
                            <>
                                {(QuotationInfo.clientinfo.ownerInfo.subjectType === "1"
                                    || QuotationInfo.clientinfo.ownerInfo.subjectType === undefined || QuotationInfo.clientinfo.ownerInfo.subjectType === "")
                                    ?
                                    QuotationInfo.clientinfo.ownerInfo.surname + " " + QuotationInfo.clientinfo.ownerInfo.name
                                    :
                                    QuotationInfo.clientinfo.ownerInfo.companyName
                                }
                            </>
                        }
                    </Fragment>
                }
            </Typography>
        </AccordionSummary>
        <AccordionDetails>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="Tabella impostazioni">
                    <TableBody>
                        {PersonInfo !== undefined ?
                            <Fragment>
                                {(PersonInfo.subjectType === "1" || PersonInfo.subjectType === undefined || PersonInfo.subjectType === "") ?
                                    <Fragment>
                                        <TableRow>
                                            <TableCell className={classes.cellTitle}>Cognome e nome</TableCell>
                                            <TableCell>{PersonInfo.surname + " " + PersonInfo.name}</TableCell>
                                            <TableCell className={classes.cellTitle}>Data di nascita</TableCell>
                                            <TableCell>
                                                {moment(PersonInfo.birthDate, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={classes.cellTitle}>Codice fiscale</TableCell>
                                            <TableCell>
                                                {PersonInfo.fiscalCode}
                                            </TableCell>
                                            <TableCell className={classes.cellTitle}>Luogo di nascita</TableCell>
                                            {PersonInfo.birthCity !== "" &&
                                                <TableCell>
                                                    {PersonInfo.birthCity + " (" + PersonInfo.birthProvince + ") " + PersonInfo.birthNation}
                                                </TableCell>
                                            }
                                            {PersonInfo.birthCity === "" && <TableCell>
                                                {PersonInfo.birthNation}
                                            </TableCell>
                                            }
                                        </TableRow>
                                    </Fragment>
                                    : <Fragment>
                                        <TableRow>
                                            <TableCell className={classes.cellTitle}>{returnLabel(subjectTypes, Number(PersonInfo.subjectType))}</TableCell>
                                            <TableCell>{PersonInfo.companyName}</TableCell>
                                            <TableCell className={classes.cellTitle}>Partita IVA</TableCell>
                                            <TableCell>
                                                {PersonInfo.vatNumber}
                                            </TableCell>
                                        </TableRow>
                                    </Fragment>}
                            </Fragment> :
                            <Fragment>
                                {(QuotationInfo.clientinfo.subjectType === "1" || QuotationInfo.clientinfo.subjectType === undefined) ?
                                    <Fragment>
                                        <TableRow>
                                            <TableCell className={classes.cellTitle}>Cognome e nome</TableCell>
                                            <TableCell>{QuotationInfo.clientinfo.surname + " " + QuotationInfo.clientinfo.name}</TableCell>
                                            <TableCell className={classes.cellTitle}>Data di nascita</TableCell>
                                            <TableCell>
                                                {moment(QuotationInfo.clientinfo.birthDate, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={classes.cellTitle}>Codice fiscale</TableCell>
                                            <TableCell>
                                                {QuotationInfo.clientinfo.fiscalCode}
                                            </TableCell>
                                            <TableCell className={classes.cellTitle}>Luogo di nascita</TableCell>
                                            {QuotationInfo.clientinfo.birthCity !== "" &&
                                                <TableCell>
                                                    {QuotationInfo.clientinfo.birthCity + " (" + QuotationInfo.clientinfo.birthProvince + ") " + QuotationInfo.clientinfo.birthNation}
                                                </TableCell>
                                            }
                                            {QuotationInfo.clientinfo.birthCity === "" && <TableCell>
                                                {QuotationInfo.clientinfo.birthNation}
                                            </TableCell>
                                            }
                                        </TableRow>
                                    </Fragment>
                                    : <Fragment>
                                        <TableRow>
                                            <TableCell className={classes.cellTitle}>{returnLabel(subjectTypes, Number(QuotationInfo.clientinfo.subjectType))}</TableCell>
                                            <TableCell>{QuotationInfo.clientinfo.companyName}</TableCell>
                                            <TableCell className={classes.cellTitle}>Partita IVA</TableCell>
                                            <TableCell>
                                                {QuotationInfo.clientinfo.vatNumber}
                                            </TableCell>
                                        </TableRow>
                                    </Fragment>}
                            </Fragment>
                        }
                        <TableRow>
                            <TableCell className={classes.cellTitle}>
                                Telefono
                            </TableCell>
                            <TableCell>
                                {QuotationInfo.clientinfo.phoneNumber !== undefined && QuotationInfo.clientinfo.phoneNumber}
                                {PersonInfo !== undefined && PersonInfo.phoneNumber}
                            </TableCell>
                            <TableCell className={classes.cellTitle}>
                                Email
                            </TableCell>
                            <TableCell>
                                {QuotationInfo.clientinfo !== undefined && QuotationInfo.clientinfo.email}
                                {PersonInfo !== undefined && PersonInfo.email}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.cellTitle}>
                                Indirizzo
                            </TableCell>
                            <TableCell>
                                {QuotationInfo.clientinfo.toponym !== undefined ?
                                    QuotationInfo.clientinfo.toponym + " " + QuotationInfo.clientinfo.address + " " + QuotationInfo.clientinfo.streetNumber :
                                    PersonInfo.residenceInfo.toponym + " " + PersonInfo.residenceInfo.address + " " + PersonInfo.residenceInfo.streetNumber
                                }
                            </TableCell>
                            <TableCell className={classes.cellTitle}>
                                Città
                            </TableCell>
                            {PersonInfo !== undefined &&
                                <TableCell>
                                    {PersonInfo.residenceInfo.cap + " " + PersonInfo.residenceInfo.city + " " + PersonInfo.residenceInfo.provinceAbbr}
                                </TableCell>
                            }
                            {PersonInfo === undefined &&
                                <TableCell>
                                    {QuotationInfo.clientinfo.cap + " " + QuotationInfo.clientinfo.city + " " + QuotationInfo.clientinfo.provinceAbbr}
                                </TableCell>
                            }
                        </TableRow>
                        {(PersonInfo.subjectType === "1" || PersonInfo.subjectType === undefined || PersonInfo.subjectType === "") && product !== "house" &&
                            <Fragment>
                                {((product !== undefined && product !== "house" && product !== "rcp" && product !== "gf") || (product === undefined && QuotationInfo.vehicleInfo.vehicleType !== "C" && QuotationInfo.vehicleInfo.vehicleType !== "R")) &&
                                    <TableRow>
                                        <TableCell className={classes.cellTitle}>
                                            Professione
                                        </TableCell>
                                        <TableCell>
                                            {QuotationInfo.clientlabelinfo.profession}
                                        </TableCell>
                                        {product !== "injuries" && product !== "tcm" &&
                                            <>
                                                <TableCell className={classes.cellTitle}>
                                                    Istruzione
                                                </TableCell>
                                                <TableCell>
                                                    {returnDescription(livelliIstruzione, QuotationInfo.clientinfo.qualification !== undefined ? QuotationInfo.clientinfo.qualification : PersonInfo.qualification)}
                                                </TableCell>
                                            </>
                                        }
                                        {product === "injuries" && QuotationInfo.clientinfo.lavoratoreDipendente !== null && QuotationInfo.clientinfo.lavoratoreDipendente !== undefined &&
                                            <>
                                                <TableCell className={classes.cellTitle}>
                                                    Lavoratore dipendente
                                                </TableCell>
                                                <TableCell>
                                                    {QuotationInfo.clientinfo.lavoratoreDipendente === true ? "Si" : "No"}
                                                </TableCell>
                                            </>
                                        }
                                        {product === "injuries" && (QuotationInfo.clientinfo.lavoratoreDipendente === null || QuotationInfo.clientinfo.lavoratoreDipendente === undefined) &&
                                            <>
                                                <TableCell className={classes.cellTitle}>
                                                </TableCell>
                                                <TableCell>
                                                </TableCell>
                                            </>
                                        }
                                        {product === "tcm" &&
                                            <>
                                                <TableCell className={classes.cellTitle}>
                                                </TableCell>
                                                <TableCell>
                                                </TableCell>
                                            </>
                                        }
                                    </TableRow>
                                }
                                <TableRow>
                                    <TableCell className={classes.cellTitle}>
                                        Sesso
                                    </TableCell>
                                    <TableCell>
                                        {ContractorType === true &&
                                            <>
                                                {returnLabel(genders, PersonInfo !== undefined ? PersonInfo.gender : QuotationInfo.clientinfo.contractorInfo.gender)}
                                            </>
                                        }
                                        {ContractorType === false &&
                                            <>
                                                {returnLabel(genders, PersonInfo !== undefined ? PersonInfo.gender : QuotationInfo.clientinfo.ownerInfo.gender)}
                                            </>
                                        }
                                    </TableCell>
                                    {product === undefined && QuotationInfo.vehicleInfo.vehicleType !== "C" && QuotationInfo.vehicleInfo.vehicleType !== "R" &&
                                        <>
                                            <TableCell className={classes.cellTitle}>
                                                Stato civile
                                            </TableCell>
                                            <TableCell>
                                                {ContractorType === true &&
                                                    <>
                                                        {returnDescription(statoCivile, QuotationInfo.clientinfo.maritalStatus !== undefined ? QuotationInfo.clientinfo.contractorInfo.maritalStatus : PersonInfo.maritalStatus)}
                                                    </>
                                                }
                                                {ContractorType === false &&
                                                    <>
                                                        {returnDescription(statoCivile, QuotationInfo.clientinfo.maritalStatus !== undefined ? QuotationInfo.clientinfo.ownerInfo.maritalStatus : PersonInfo.maritalStatus)}
                                                    </>
                                                }
                                            </TableCell>
                                        </>
                                    }
                                </TableRow>
                                {product === undefined && QuotationInfo.vehicleInfo.vehicleType !== "C" && QuotationInfo.vehicleInfo.vehicleType !== "R"
                                    && QuotationInfo.vehicleInfo.vehicleType !== "M" && QuotationInfo.vehicleInfo.vehicleType !== "W" &&
                                    <>
                                        {ContractorType &&
                                            <>
                                                <TableRow>
                                                    <TableCell className={classes.cellTitle}>
                                                        Figli
                                                    </TableCell>
                                                    <TableCell>
                                                        {QuotationInfo.clientinfo.sons === "true" ? "SI" : "NO"}
                                                    </TableCell>
                                                    {QuotationInfo.clientinfo.sons === "true" ?
                                                        <Fragment>
                                                            <TableCell className={classes.cellTitle}>
                                                            </TableCell>
                                                            <TableCell>
                                                            </TableCell>
                                                        </Fragment> :
                                                        <Fragment>
                                                            <TableCell className={classes.cellTitle}>
                                                            </TableCell>
                                                            <TableCell>
                                                            </TableCell>
                                                        </Fragment>
                                                    }
                                                </TableRow>
                                                {QuotationInfo.clientinfo.sons === "true" &&
                                                    <Fragment>
                                                        <TableRow>
                                                            <TableCell className={classes.cellTitle}>
                                                                Figli 0-3 anni
                                                            </TableCell>
                                                            <TableCell>
                                                                {QuotationInfo.clientinfo.nSons1}
                                                            </TableCell>
                                                            <TableCell className={classes.cellTitle}>
                                                                Figli 4-16 anni
                                                            </TableCell>
                                                            <TableCell>
                                                                {QuotationInfo.clientinfo.nSons2}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell className={classes.cellTitle}>
                                                                Figli 17-25 anni
                                                            </TableCell>
                                                            <TableCell>
                                                                {QuotationInfo.clientinfo.nSons3}
                                                            </TableCell>
                                                            <TableCell className={classes.cellTitle}>
                                                                Figli 26 anni o più
                                                            </TableCell>
                                                            <TableCell>
                                                                {QuotationInfo.clientinfo.nSons4}
                                                            </TableCell>
                                                        </TableRow>
                                                    </Fragment>
                                                }
                                            </>
                                        }
                                        {product === undefined && ContractorType &&
                                            <>
                                                <TableRow>
                                                    <TableCell className={classes.cellTitle}>
                                                        Tipo patente
                                                    </TableCell>
                                                    <TableCell>
                                                        {returnDescription(drivingLicenseTypes, QuotationInfo.clientinfo.drivingLicenseType)}
                                                    </TableCell>
                                                    <TableCell className={classes.cellTitle}>
                                                        Mese e Anno  conseguimento patente
                                                    </TableCell>
                                                    <TableCell>
                                                        {QuotationInfo.clientinfo.drivingLicenseMonth}/{QuotationInfo.clientinfo.drivingLicenseYear}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className={classes.cellTitle}>
                                                        Punti patente
                                                    </TableCell>
                                                    <TableCell>
                                                        {QuotationInfo.clientinfo.drivingLicensePoints}
                                                    </TableCell>
                                                    <TableCell className={classes.cellTitle}>
                                                    </TableCell>
                                                    <TableCell>
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        }
                                    </>
                                }
                            </Fragment>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </AccordionDetails>
    </Accordion>;
}

function ReturnCodiceCommerciale(Quotation) {
    const list = JSON.parse(Quotation.detail);
    let codice = "";
    list.forEach(element => {
        if (element.company.commerciale !== null) {
            codice = element.company.commerciale;
        }
    });
    return codice;
}

function getTotalQuotationAdd(quotationAdd) {
    let total = 0;
    for (const quote of quotationAdd) {
        total = total + parseFloat(quote.TotalRate);
    }
    return total;
}

function CustomMsgConfimPayment(type, QuoteSelect, paymentsOptions, PaymentOptionSelect, email2Send, props, classes) {
    return <>
        {type !== 2 &&
            <Typography className={classes.infoDialog}>
                Pagamento tramite {paymentsOptions.filter(active => active.id === PaymentOptionSelect)[0].description}
            </Typography>
        }
        {type === 2 &&
            <>
                <Typography className={classes.infoDialog}>
                    Non è possibile procedere con il pagamento di questo preventivo
                </Typography>
                <Typography className={classes.infoDialog}>
                    Il preventivo selezionato è soggetto di verifica, prima di essere confermato.
                </Typography>
            </>
        }
        {type !== 2 &&
            <Typography className={classes.infoDialog}>
                Importo:  <NumberFormat fixedDecimalScale="true" decimalScale="2" value={CalculateToPay(QuoteSelect, paymentsOptions.filter(active => active.id === PaymentOptionSelect)[0])} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} />
            </Typography>
        }

        <Typography className={classes.infoDialog}>
            L'ordine verrà salvato nello stato:
        </Typography>
        <Typography className={classes.infoDialog}>
            {type === 1 &&
                <Chip color='primary' style={{ backgroundColor: 'yellow', color: 'black' }} label="In attesa di pagamento" />
            }
            {type === 0 &&
                <Chip color='primary' style={{ backgroundColor: 'green' }} label="Confermato" />
            }
            {type === 2 &&
                <Chip color='primary' style={{ backgroundColor: 'yellow', color: 'black' }} label="Premio da confermare" />
            }
        </Typography>
        {type === 1 && <>
            <Typography className={classes.infoDialog}>
                Il link per il pagamento verrà inviato all'email:
            </Typography>
            <Typography className={classes.infoDialog}>
                {email2Send}
            </Typography>
        </>
        }
    </>;
}

function CalculateToPay(QuoteSelect, OptionPayment, onlyCommissions = false) {
    let rate = Number(QuoteSelect.TotalRate);

    if (OptionPayment.removecommissions === 1) {
        rate -= Number(QuoteSelect.Commissions) / Number(QuoteSelect.splittingUp);
    }
    if (OptionPayment.removefee === 1) {
        rate -= Number(QuoteSelect.Fees) / Number(QuoteSelect.splittingUp);
    }
    if (onlyCommissions) {
        return (OptionPayment.paymentcommissions * rate) / 100;
    }
    rate = rate + (OptionPayment.paymentcommissions * rate) / 100;
    rate = rate + OptionPayment.paymentfee;
    return rate;
}

function CustomMsgSnackBar(url, expirelink, classes) {
    return <>
        <Typography className={classes.infoDialog}>
            Il link per procedere al pagamento è il seguente:
        </Typography>
        <Typography className={classes.infoDialog}>
            {url}
        </Typography>
        <Typography className={classes.infoDialog}>
            Questo link scade il {moment(expirelink).format("DD/MM/YYYY")} alle {moment(expirelink).format("HH:MM")}
        </Typography>
    </>;
}

function DatiPolizzaDetails(QuotationInfo, classes) {
    return <TableContainer component={Paper}>
        <Table className={classes.table}>
            <TableBody>
                <TableRow>
                    <TableCell className={classes.cellTitle}>
                        Data effetto
                    </TableCell>
                    <TableCell>
                        {moment(QuotationInfo.clientinfo.dataEffetto, "YYYY-MM-DD").format("DD/MM/YYYY")}
                    </TableCell>
                    {QuotationInfo.quotationtype !== "rcp" &&
                        <>
                            <TableCell className={classes.cellTitle}>
                                Frazionamento
                            </TableCell>
                            <TableCell>
                                {returnLabel(tipoFrazionamento, Number(QuotationInfo.clientinfo.frazionamento))}
                            </TableCell>
                        </>
                    }
                    {QuotationInfo.quotationtype === "rcp" &&
                        <>
                            <TableCell className={classes.cellTitle}>
                                Massimale
                            </TableCell>
                            <TableCell>
                                {returnLabel(massimaliRcp, QuotationInfo.clientinfo.massimale)}
                            </TableCell>
                        </>
                    }
                </TableRow>
                {QuotationInfo.quotationtype === "tcm" &&
                    <TableRow>
                        <TableCell className={classes.cellTitle}>
                            Durata anni
                        </TableCell>
                        <TableCell>
                            {QuotationInfo.clientinfo.durataAnni}
                        </TableCell>
                        <TableCell className={classes.cellTitle}>
                            Beneficiario
                        </TableCell>
                        <TableCell>
                            {returnLabel(beneficiariTcm, Number(QuotationInfo.clientinfo.beneficiario))}
                        </TableCell>
                    </TableRow>
                }
                {QuotationInfo.quotationtype === "rcp" &&
                    <TableRow>
                        <TableCell className={classes.cellTitle}>
                            Professione
                        </TableCell>
                        <TableCell>
                            {returnLabel(professioniRcp, QuotationInfo.clientinfo.tipologiaProfessione)}
                        </TableCell>
                        <TableCell className={classes.cellTitle}>
                            Fascia fatturato
                        </TableCell>
                        <TableCell>
                            {returnLabel(fasciaFatturatoRcp, QuotationInfo.clientinfo.fatturato)}
                        </TableCell>
                    </TableRow>
                }
            </TableBody>
        </Table>
    </TableContainer>;
}

function DomandaDetails(QuotationInfo, classes) {
    return <TableContainer component={Paper}>
        <Table className={classes.table}>
            <TableBody>
                <TableRow>
                    <TableCell className={classes.cellTitle}>
                        Negli ultimi 5 anni sono state avanzate richieste di risarcimento nei confronti del cliente o è a conoscenza di circostanze che possano portare a tali richieste?
                    </TableCell>
                    <TableCell>
                        {QuotationInfo.clientinfo.questionarioRcp !== undefined && QuotationInfo.clientinfo.questionarioRcp === "si" ? "Si" : "No"}
                    </TableCell>
                </TableRow>
                {QuotationInfo.clientinfo.questionarioTipologiaProfessione !== undefined && QuotationInfo.clientinfo.tipologiaProfessione === "4" &&
                    <TableRow>
                        <TableCell className={classes.cellTitle}>
                            Il cliente svolge attività di REVISORE/ SINDACO/ O.D.V./ ATTESTATORE/ AMMINISTRATORE?
                        </TableCell>
                        <TableCell>
                            {QuotationInfo.clientinfo.questionarioTipologiaProfessione !== undefined && QuotationInfo.clientinfo.questionarioTipologiaProfessione === "si" ? "Si" : "No"}
                        </TableCell>
                    </TableRow>
                }
            </TableBody>
        </Table>
    </TableContainer>;
}

function QuestionarioDetails(questionario, domandeQuestionario) {
    return (
        domandeQuestionario.map((domanda) => (
            <>
                {questionario[domanda.value] !== null && questionario[domanda.value] !== undefined && questionario[domanda.value] !== "" &&
                    <TableRow>
                        <TableCell style={{ width: "40%" }}>
                            {domanda.description}
                            {domanda.value === "mal_domanda_17" &&
                                <>
                                    <br />
                                    <b>APPARATO RESPIRATORIO</b>: asma, enfisema, infezioni bronco polmonari, pleurite, TBC, polipi delle corde vocali
                                    <br />
                                    <b>APPARATO CARDIOCIRCOLATORIO</b>: infarto miocardio, angina pectoris, ipertensione arteriosa, valvulopatie, arteriopatie, varici degli arti
                                    inferiori, tromboflebiti
                                    <br />
                                    <b>APPARATO DIGERENTE</b>: ulcera gastroduodenale, rettocolite ulcerosa, epatite virale B-C, cirrosi, calcolosi biliare
                                    <br />
                                    <b>APPARATO UROGENITALE</b>: malattie infettive e infiammatorie del nefrone, calcolosi, insufficenza renale, ipertrofia della prostata, cisti
                                    dell'ovaio, fibroma uterino, varicocele
                                    <br />
                                    <b>APPARATO OSTEOARTICOLARE</b>: artrite, artrosi, ernia del disco, lesioni del menisco o dei legamenti del ginocchio, alluce valgo,
                                    patologie non trattate con protesi
                                    <br />
                                    <b>SISTEMA NERVOSO</b>: morbo di Parkinson, epilessia, sclerosi multipla, demenza (m. di Alzheimer), SLA
                                    <br />
                                    <b>ENDOCRINO METABOLICHE</b>: diabete mellito tipo 1-2, malattie della tiroide o delle ghiandole surrena
                                    <br />
                                    <b>DEL SANGUE</b>: anemia, leucemia
                                    <br />
                                    <b>DEL CONNETTIVO</b>: connettivite mista, lupus eritematoso sistemico, sclerodermia, poliartrite nodosa, reumatismo articolare
                                    <br />
                                    <b>DELL'OCCHIO</b>: cataratta, glaucoma, distacco di retina, malattie vitrali
                                    <br />
                                    <b>TUMORI MALIGNI E BENIGNI</b>
                                </>
                            }
                        </TableCell>
                        <TableCell>
                            {questionario[domanda.value] === "no" ? "No" : questionario[domanda.value] === "yes" ? "Si" : questionario[domanda.value]}
                        </TableCell>
                    </TableRow>
                }
            </>
        ))
    );
}

function ClausoleDetails(clausoleSelected, listaClausole) {
    return (
        listaClausole.map((clausola) => (
            <>
                {clausoleSelected.some(v => (v === clausola.value)) &&
                    <TableRow>
                        <TableCell style={{ width: "100%" }}>
                            {clausola.label}
                        </TableCell>
                    </TableRow>
                }
            </>
        ))
    );
}

function aCapo(value, classes) {
    const aCapo = value.split("\n");

    return (
        <List className={classes.tableCellList}>
            {aCapo.map((riga, i) => (
                <ListItem className={classes.tableCellList} key={i}>{riga}</ListItem>
            ))}
        </List>
    );
}

function DatiStudioDetails(QuotationInfo, classes) {
    return <TableContainer component={Paper}>
        <Table className={classes.table}>
            <TableBody>
                <TableRow>
                    <TableCell className={classes.cellTitle}>
                        Indirizzo
                    </TableCell>
                    <TableCell>
                        {QuotationInfo.clientinfo.toponimo + " " + QuotationInfo.clientinfo.indirizzo + " " + QuotationInfo.clientinfo.numeroCivico}
                    </TableCell>
                    <TableCell className={classes.cellTitle}>
                        Città
                    </TableCell>
                    <TableCell>
                        {QuotationInfo.clientinfo.cap + " " + QuotationInfo.clientinfo.citta + " " + QuotationInfo.clientinfo.provinciaAbbr}
                    </TableCell>
                </TableRow>
                {QuotationInfo.clientinfo.altriProfessionisti !== undefined && QuotationInfo.clientinfo.altriProfessionisti !== "" &&
                    <TableRow>
                        <TableCell className={classes.cellTitle}>
                            Eventuali altri professionisti
                        </TableCell>
                        <TableCell>
                            {aCapo(QuotationInfo.clientinfo.altriProfessionisti, classes)}
                        </TableCell>
                        <TableCell className={classes.cellTitle}>

                        </TableCell>
                        <TableCell>

                        </TableCell>
                    </TableRow>
                }
            </TableBody>
        </Table>
    </TableContainer>;
}

export default function QuotationSingleDetail(props) {
    const [openAlert, setOpenAlert] = React.useState(false);
    const [alertseverity, setAlertSeverity] = React.useState("success");
    const [alertmsg, setAlertMsg] = React.useState(false);
    const classes = useStyles();
    let numberquotation = props.numberquotation;
    let paymentresult = props.paymentresult;

    const [Quotation, setQuote] = React.useState(false);
    const [QuotationInfo, setQuoteInfo] = React.useState(false);
    const [QuotationPrice, setQuotePrice] = React.useState(false);
    const [QuotationSelect, setQuoteSelect] = React.useState(false);
    const [QuotationAdd, setQuotationAdd] = React.useState(false);
    //const [expanded, setExpanded] = React.useState("panelHouse");
    const [expanded, setExpanded] = React.useState("");
    const [images, setImages] = React.useState([]);
    const [links, setLinks] = React.useState([]);

    const [qrCodeImage, setqrCodeImage] = React.useState("");
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const [isOrderModule, setIsOrderModule] = React.useState(false);
    const [singleData, setsingleData] = React.useState();
    const [paymentsrequest, setPaymentsRequest] = React.useState([]);

    const [paymentsOptions, setPaymentsOptions] = React.useState([]);
    const [PaymentOptionSelect, setPaymentOptionSelect] = React.useState(false);
    const [operationConfirmSave, setOperationConfirmSave] = React.useState(0);
    const [titleConfirmSave, setTitleConfirmSave] = React.useState("");
    const [msgConfirmSave, setMsgConfirmSave] = React.useState("");
    const [openConfirmSave, setopenConfirmSave] = React.useState(false);
    const [openPaymentOptions, setopenPaymentOptions] = React.useState(false);
    const [PaymentPayBylinkActive, setPaymentPayBylinkActive] = React.useState(false);
    const [numTab, setTab] = React.useState(0);
    //const [changePrice, setChangePrice] = React.useState(0);

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlert(false);
    };

    const handleDialogClose = event => {
        setIsDialogOpen(false);
    };

    const OpenDialogPDF = (row, orderModule) => event => {
        setOpenAlert(false);
        QRCodeGenerator.toDataURL("quotationsingle/" + row.id, { errorCorrectionLevel: 'H' }, function (err, url) {
            setIsOrderModule(orderModule);
            setqrCodeImage(url);
            setsingleData(row);
            setIsDialogOpen(true);
        });
    };

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleSaveModify = (quoteInfo) => (event) => {
        let QuotationPriceCopia = QuotationPrice;
        QuotationPriceCopia.forEach(element => {
            if (element.datipre !== null && element.datipre !== undefined) {
                if (element.datipre.prices !== undefined) {
                    element.datipre.prices.forEach(price => {
                        if (quoteInfo.company === price.company &&
                            quoteInfo.nrQuotation === price.nrQuotation
                        ) {
                            price = quoteInfo;
                        }
                    });
                }
            }
        });
        setQuotePrice(QuotationPriceCopia);
        let QuotationCopia = Quotation;
        QuotationCopia.detail = JSON.stringify(QuotationPriceCopia);
        setQuote(QuotationCopia);
        const tokenApp = Auth.getToken();
        const url = API_URL_QUOTATION_UPDATE + '/' + props.numberquotation;

        axios.patch(url, {
            detail: JSON.stringify(QuotationPriceCopia),
        },
            {
                headers: {
                    'Authorization': 'Bearer ' + tokenApp,
                    'Content-Type': 'application/json'
                }
            }
        ).then(payload => {
            setAlertMsg("Salvataggio avvenuto con successo");
            setAlertSeverity("success");
            setOpenAlert(true);
        }).catch((error) => {
            setAlertMsg(error.response.data.error);
            setAlertSeverity("error");
            setOpenAlert(true);
        });
    };

    const [factories, setFactories] = React.useState([]);
    const [factoriesHub, setFactoriesHub] = React.useState([]);
    const [factoriesInfoImage, setFactoriesInfoImage] = React.useState([]);

    React.useEffect(() => {
        const url = API_URL_FACTORIES_LIST;
        axios.get(url,
            {
                headers: {
                    'Authorization': 'Bearer ' + Auth.getToken(),
                    'Content-Type': 'application/json'
                }
            }
        ).then(payload => {
            setFactories(payload.data.factories);
            setFactoriesHub(payload.data.factorieshub);
        });
    }, []);

    let quotTot = 0;
    let quotExe = 0;

    React.useEffect(() => {
        function getQuot() {
            if ((quotTot === 0 || quotExe < quotTot)) {
                const url = API_URL_QUOTATION_LIST + "/" + numberquotation;
                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + Auth.getToken(),
                            'Content-Type': 'application/json'
                        }
                    }
                ).then(payload => {
                    const dataQuot = payload.data.quotation;
                    quotTot = dataQuot[0].n_quot_tot;
                    quotExe = dataQuot[0].n_quot_exe;
                    setFactoriesInfoImage(payload.data.infoimages);

                    let paramurl = "car";
                    if (JSON.parse(dataQuot[0].clientinfo).vehicleInfo !== undefined) {
                        paramurl = "car"
                    } else if (JSON.parse(dataQuot[0].clientinfo).quotationtype !== undefined && JSON.parse(dataQuot[0].clientinfo).quotationtype === "house") {
                        paramurl = "house";
                    }

                    const url = API_URL_INFOIMAGES_ALL + "/prod/" + dataQuot[0].id_product + "/" + dataQuot[0].id_agency;
                    //const url = API_URL_INFOIMAGES_ALL + "/" + paramurl + "/" + dataQuot[0].id_agency;
                    axios.get(url,
                        {
                            headers: {
                                'Authorization': 'Bearer ' + Auth.getToken(),
                                'Content-Type': 'application/json'
                            }
                        }
                    ).then(payload => {
                        setImages(payload.data.images)
                    });

                    const urlFactLink = API_URL_ALLLINKS + "/" + dataQuot[0].id_product + "/" + dataQuot[0].orgid;
                    axios.get(urlFactLink,
                        {
                            headers: {
                                'Authorization': 'Bearer ' + Auth.getToken(),
                                'Content-Type': 'application/json'
                            }
                        }
                    ).then(payload => {
                        setLinks(payload.data.links)
                    });

                    setQuoteInfo(JSON.parse(dataQuot[0].clientinfo));
                    setQuotePrice(JSON.parse(dataQuot[0].detail));
                    //setChangePrice((changePrice) => changePrice + 1);
                    if (dataQuot[0].select !== "")
                        setQuoteSelect(JSON.parse(dataQuot[0].select));
                    setQuote(dataQuot[0]);
                    if (dataQuot[0].quotation_add !== null && dataQuot[0].quotation_add !== "") {
                        setQuotationAdd(JSON.parse(dataQuot[0].quotation_add));
                    }
                    if (dataQuot[0].status === 1) {
                        const url = API_URL_QUOTATION_LIST + "/" + numberquotation + "/listpaymentrequest";
                        axios.get(url,
                            {
                                headers: {
                                    'Authorization': 'Bearer ' + Auth.getToken(),
                                    'Content-Type': 'application/json'
                                }
                            }
                        ).then(payload => {
                            if (payload.status === 200) {
                                setPaymentsRequest(payload.data.requestlist);
                            }
                        });
                    }
                });
            }
        }
        getQuot();
        if (quotTot === 0 || quotExe < quotTot) {
            let interval = setInterval(function () {
                if (quotTot > 0 && quotExe >= quotTot) {
                    clearInterval(interval);
                } else {
                    getQuot();
                }
            }, 20000);
        }
    }, [numberquotation]);

    React.useEffect(() => {
        const search = window.location.search;
        if (paymentresult !== undefined && (paymentresult === "paymentdone" || paymentresult === "paymentcancel")) {
            const url = API_URL_QUOTATION_LIST + "/" + numberquotation + "/paymentdone" + search;
            axios.get(url,
                {
                    headers: {
                        'Authorization': 'Bearer ' + Auth.getToken(),
                        'Content-Type': 'application/json'
                    }
                }
            ).then(payload => {
                if (payload.status === 200) {
                    window.location.href = "/quotationsingle/" + numberquotation;
                }
            });
        }
    }, [numberquotation, paymentresult]);

    const handleSaveAdd = (type) => (event) => {
        const tokenApp = Auth.getToken();
        const url = API_URL_QUOTATION_PAYMENTS + '/' + props.numberquotation;

        let quoteInfo = {};
        quoteInfo.company = "";
        quoteInfo.price = getTotalQuotationAdd(QuotationAdd);
        quoteInfo.TotalPrice = getTotalQuotationAdd(QuotationAdd);
        quoteInfo.splitPrice = getTotalQuotationAdd(QuotationAdd);
        quoteInfo.TotalRate = getTotalQuotationAdd(QuotationAdd);
        quoteInfo.messages = [];
        quoteInfo.splittingUp = "1";
        quoteInfo.Commissions = "0.00";
        quoteInfo.Fees = 0;
        QuotationAdd.forEach(quote => {
            if (quote) {
                quote.messages.forEach(message => {
                    quoteInfo.messages.push(message);
                });
            }
        });

        if (quoteInfo.messages !== undefined && quoteInfo.messages.length > 0) {
            setOperationConfirmSave(1);
            setTitleConfirmSave("Conferma ordine preventivo");
            setMsgConfirmSave(CustomMsgConfimPayment(2, quoteInfo, paymentsOptions, PaymentOptionSelect, QuotationInfo.clientinfo.email !== undefined ?
                QuotationInfo.clientinfo.email : QuotationInfo.clientinfo.contractorInfo.email !== undefined ?
                    QuotationInfo.clientinfo.contractorInfo.email : Quotation.email, props, classes));
            setQuoteSelect(quoteInfo);
            setopenConfirmSave(true);
            return;
        }

        axios.get(url,
            {
                headers: {
                    'Authorization': 'Bearer ' + tokenApp,
                    'Content-Type': 'application/json'
                }
            }
        ).then(payload => {
            if (payload.status === 200) {
                setPaymentsOptions(payload.data.activePayment);
                if (payload.data.activePayment.length > 0) {
                    if (type === 0) {
                        setPaymentOptionSelect(payload.data.activePayment[0].id);
                    }
                    payload.data.activePayment.forEach(option => {
                        if (option.config !== null) {
                            JSON.parse(option.config).forEach((item, key) => {
                                if (Object.keys(item)[0] === "paybylink") {
                                    if (JSON.parse(option.config)[key][Object.keys(item)[0]].value === "1") {
                                        if (type === 1) {
                                            setPaymentOptionSelect(option.id);
                                        }
                                        setPaymentPayBylinkActive(true);
                                    }
                                }
                            });
                        }

                    });
                }

                setQuoteSelect(quoteInfo);
                setopenPaymentOptions(true);
            }
        }).catch((error) => {
            setAlertMsg(error.response.data.error);
            setAlertSeverity("error");
            setOpenAlert(true);
        });
    };

    const handleChangeTab = (event, newValue) => {
        if (newValue === 1) {
            if (paymentsOptions.length > 0) {
                paymentsOptions.forEach(option => {
                    if (option.config !== null) {
                        JSON.parse(option.config).forEach((item, key) => {
                            if (Object.keys(item)[0] === "paybylink") {
                                if (JSON.parse(option.config)[key][Object.keys(item)[0]].value === "1") {
                                    setPaymentOptionSelect(option.id);
                                }
                            }
                        });
                    }
                });
            }
        }
        setTab(newValue);
    };

    const handleCancelConfirm = () => {
        setopenConfirmSave(false);
        setQuoteSelect(false);
    };

    const handleOkSaveConfirm = () => {
        const tokenApp = Auth.getToken();
        const url = API_URL_QUOTATION_LIST + '/' + props.numberquotation;

        if (operationConfirmSave === 1) {
            axios.patch(url, {
                select: JSON.stringify(QuotationSelect),
                paymentid: null,
                payment_import: 0,
                urldone: null,
                urlback: null,
                urlms: null,
            },
                {
                    headers: {
                        'Authorization': 'Bearer ' + tokenApp,
                        'Content-Type': 'application/json'
                    }
                }
            ).then(payload => {
                if (payload.status === 200) {
                    window.location.href = "/quotationsingle/" + props.numberquotation;
                }
            }).catch((error) => {
                setAlertMsg(error.response.data.error);
                setAlertSeverity("error");
                setOpenAlert(true);
            });
        } else {
            const tokenApp = Auth.getToken();
            const url = API_URL_QUOTATION_LIST + '/' + props.numberquotation;
            let urldone;
            let urlms;
            let urlback;
            if (numTab === 0) {  //Qui pagamento
                urldone = window.location.origin + '/quotationsingle/' + props.numberquotation + '/paymentdone';
                urlms = API_URL_QUOTATION_LIST + '/' + props.numberquotation + "/paymentdone";
                urlback = window.location.origin + '/quotationsingle/' + props.numberquotation + '/paymentcancel';
            } else {    //Qui pay per link
                urldone = window.location.origin + '/resultpayment/' + props.numberquotation + '/paymentdone';
                urlms = API_URL_QUOTATION_LIST + '/' + props.numberquotation + "/paymentdone";
                urlback = window.location.origin + '/resultpayment/' + props.numberquotation + '/paymentcancel';
            }

            axios.patch(url, {
                select: JSON.stringify(QuotationSelect),
                paymentid: paymentsOptions.filter(active => active.id === PaymentOptionSelect)[0].id_payment_systems,
                payment_import: CalculateToPay(QuotationSelect, paymentsOptions.filter(active => active.id === PaymentOptionSelect)[0]),
                urldone: urldone,
                urlback: urlback,
                urlms: urlms,
                email: QuotationInfo.clientinfo.email !== undefined ? QuotationInfo.clientinfo.email :
                    QuotationInfo.clientinfo.contractorInfo.email !== undefined ? QuotationInfo.clientinfo.contractorInfo.email :
                        Quotation.email,
                paybylink: numTab
            },
                {
                    headers: {
                        'Authorization': 'Bearer ' + tokenApp,
                        'Content-Type': 'application/json'
                    }
                }
            ).then(payload => {
                if (payload.status === 200) {
                    if (payload.data.paybylink === 1) {
                        setAlertMsg(CustomMsgSnackBar(payload.data.url, payload.data.expirelink, classes));
                        setAlertSeverity("success");
                        setOpenAlert(true);
                        setopenConfirmSave(false);
                        setopenPaymentOptions(false);
                    } else {
                        if (payload.data.postparam !== "") {
                            window.location.href = payload.data.url + "?" + payload.data.postparam;
                        } else {
                            window.location.href = payload.data.url;
                        }
                    }
                }
            }).catch((error) => {
                setAlertMsg(error.response.data.error);
                setAlertSeverity("error");
                setOpenAlert(true);
            });
        }
    };

    const handleCancel = () => {
        setopenPaymentOptions(false);
    };

    const handleOk = () => {
        setOperationConfirmSave(2);
        setTitleConfirmSave("Conferma ordine preventivo");
        setMsgConfirmSave(CustomMsgConfimPayment(numTab, QuotationSelect, paymentsOptions, PaymentOptionSelect, QuotationInfo.clientinfo.email !== undefined ?
            QuotationInfo.clientinfo.email : QuotationInfo.clientinfo.contractorInfo.email !== undefined ?
                QuotationInfo.clientinfo.contractorInfo.email : Quotation.email, props, classes));
        setopenConfirmSave(true);
    }

    const handleChangePayment = (event) => {
        setPaymentOptionSelect(Number(event.target.value));
    };

    const [progress, setProgress] = React.useState(0);

    /*React.useEffect(() => {
        let timer = setInterval(function () {
            console.log(stopProg);
            if ((quotTot > 0 && quotExe >= quotTot) || stopProg == 1) {
                clearInterval(timer);
            } else {
                setProgress((prevProgress) => (prevProgress >= 20 ? 1 : prevProgress + 1));
            }
        }, 1000);
    }, []);*/

    return (<>
        <Card className={classes.cardStyle}>
            {paymentresult !== undefined && paymentresult === "paymentdone" &&
                <div className={classes.root}>
                    Verifica pagamento in corso....
                    <CircularProgress />
                </div>
            }
            {Quotation !== false && QuotationInfo !== false && QuotationPrice !== false &&
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            {Quotation.active_image !== null && Quotation.active_image !== "" &&
                                <img
                                    className={classes.img}
                                    alt="Organizzazione"
                                    src={Quotation.active_image}
                                />}
                            {Quotation.active_businessname !== null && Quotation.active_businessname !== "" &&
                                <Typography variant="subtitle2" gutterBottom>
                                    {Quotation.active_businessname}
                                </Typography>
                            }
                            {Quotation.active_address !== null && Quotation.active_address !== "" &&
                                <>
                                    <Typography variant="subtitle2" gutterBottom>
                                        {Quotation.active_toponym} {Quotation.active_address}, {Quotation.active_civicnumber}
                                    </Typography>
                                    <Typography variant="subtitle2" gutterBottom>
                                        {Quotation.active_cap} {Quotation.active_city} {Quotation.active_province}
                                    </Typography>
                                </>
                            }
                            {Quotation.active_vatnumber !== null && Quotation.active_vatnumber !== "" &&
                                <Typography variant="subtitle2" gutterBottom>
                                    P.IVA {Quotation.active_vatnumber}
                                </Typography>
                            }
                            {Quotation.active_fiscalcode !== null && Quotation.active_fiscalcode !== "" &&
                                <Typography variant="subtitle2" gutterBottom>
                                    CF {Quotation.active_fiscalcode}
                                </Typography>
                            }
                            {Quotation.active_rui !== null && Quotation.active_rui !== "" &&
                                <Typography variant="subtitle2" gutterBottom>
                                    {moment(Quotation.active_registrationdate).isValid() && "del " + moment(Quotation.active_registrationdate, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")}
                                </Typography>
                            }
                            <Typography variant="body2" gutterBottom>
                                Punto vendita: {Quotation.agdescr}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                Emesso da: {Quotation.name} {Quotation.surname}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                Email: {Quotation.email}
                            </Typography>
                            {Quotation.loginwith810 === 1 &&
                                <Typography variant="body2" gutterBottom>
                                    Utente: {Quotation.user}
                                </Typography>

                            }
                            {ReturnCodiceCommerciale(Quotation) !== "" &&
                                <Typography variant="body2" gutterBottom>
                                    Codice Commerciale: {ReturnCodiceCommerciale(Quotation)}
                                </Typography>
                            }
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.titlePadding} variant="h5" gutterBottom>
                                Preventivo {numberquotation} del {moment(Quotation.created_at, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")}
                            </Typography>
                            {Quotation.status === 1 &&
                                <>
                                    <Chip color='primary' style={{ backgroundColor: 'green' }} label={Quotation.payment_detail} />
                                </>
                            }
                            {Quotation.status === 3 && Quotation.id_salvataggio === null &&
                                <>
                                    <Chip color='primary' style={{ backgroundColor: 'yellow', color: "black", fontWeight: 600 }} label={"Premio da confermare"} />
                                </>
                            }
                            {Quotation.status === 3 && Quotation.id_salvataggio !== null &&
                                <>
                                    <Chip color='primary' style={{ backgroundColor: 'yellow', color: "black", fontWeight: 600 }} label={"Premio da confermare - " + Quotation.payment_detail} />
                                </>
                            }
                            {Quotation.status !== 1 && Quotation.status !== 3 &&
                                <>
                                    {Quotation.expired_date === null &&
                                        <>
                                            {moment(Quotation.created_at, "YYYY-MM-DD HH:mm:ss").add(7, "days").isBefore(moment()) &&
                                                <Typography className={classes.red} variant="h5" gutterBottom>
                                                    Scaduto il {moment(Quotation.created_at, "YYYY-MM-DD HH:mm:ss").add(7, "days").format("DD/MM/YYYY")}
                                                </Typography>
                                            }
                                            {moment(Quotation.created_at, "YYYY-MM-DD HH:mm:ss").add(7, "days").isSameOrAfter(moment()) &&
                                                <Typography className={classes.titlePadding2} variant="h5" gutterBottom>
                                                    Scadenza il {moment(Quotation.created_at, "YYYY-MM-DD HH:mm:ss").add(7, "days").format("DD/MM/YYYY")}
                                                </Typography>}
                                        </>
                                    }
                                    {Quotation.expired_date !== null &&
                                        <>
                                            {moment(Quotation.expired_date + " 23:59:59", "YYYY-MM-DD HH:mm:ss").isBefore(moment()) &&
                                                <Typography className={classes.red} variant="h5" gutterBottom>
                                                    Scaduto il {moment(Quotation.expired_date + " 23:59:59", "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")}
                                                </Typography>
                                            }
                                            {moment(Quotation.expired_date + " 23:59:59", "YYYY-MM-DD HH:mm:ss").isSameOrAfter(moment()) &&
                                                <Typography className={classes.titlePadding2} variant="h5" gutterBottom>
                                                    Scadenza il {moment(Quotation.expired_date + " 23:59:59", "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")}
                                                </Typography>}
                                        </>
                                    }
                                </>
                            }
                            {paymentresult !== undefined && paymentresult === "paymentcancel" &&
                                <div>
                                    Il pagamento è stato annullato
                                </div>
                            }
                        </Grid>
                    </Grid>
                    {(props.visual === undefined || props.visual === false) &&
                        <div className={classes.divButtonPDF}>
                            {QuotationSelect && (Quotation.status === 1 || Quotation.status === 3) &&
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    endIcon={<PictureAsPdfIcon />}
                                    onClick={OpenDialogPDF(Quotation, true)}
                                >
                                    Ordine
                                </Button>
                            }
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                endIcon={<PictureAsPdfIcon />}
                                onClick={OpenDialogPDF(Quotation, false)}
                            >
                                Preventivi
                            </Button>
                        </div>
                    }

                    {DetailPerson(QuotationInfo.clientinfo.contractorInfo, QuotationInfo, classes, expanded, handleChange, 'contractorPanel', 'Dati contraente', true, QuotationInfo.quotationtype)}

                    {QuotationInfo.clientinfo.ownerInfo !== undefined &&
                        (!(
                            (QuotationInfo.clientinfo.contractorInfo.fiscalCode !== '' && QuotationInfo.clientinfo.ownerInfo.fiscalCode === QuotationInfo.clientinfo.contractorInfo.fiscalCode) ||
                            (QuotationInfo.clientinfo.contractorInfo.vatNumber !== '' && QuotationInfo.clientinfo.ownerInfo.vatNumber === QuotationInfo.clientinfo.contractorInfo.vatNumber))
                        )
                        &&
                        DetailPerson(QuotationInfo.clientinfo.ownerInfo, QuotationInfo, classes, expanded, handleChange, 'ownerPanel', 'Dati intestatario', false, QuotationInfo.quotationtype)
                    }

                    {QuotationInfo.quotationtype !== undefined && QuotationInfo.quotationtype === "gf" &&
                        DetailPerson(QuotationInfo.clientinfo.amministratoreInfo, QuotationInfo, classes, expanded, handleChange, 'amministratorePanel', 'Dati amministratore', false, QuotationInfo.quotationtype)
                    }

                    {QuotationInfo.quotationtype !== undefined && QuotationInfo.quotationtype === "house" &&
                        <>
                            <Accordion expanded={expanded === 'panelHouse'} onChange={handleChange('panelHouse')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Typography className={classes.heading}>
                                        Dati immobile
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {HouseDetails(QuotationInfo, classes)}
                                </AccordionDetails>
                            </Accordion>
                        </>
                    }

                    {QuotationInfo.quotationtype !== undefined && QuotationInfo.quotationtype === "gf" &&
                        <>
                            <Accordion expanded={expanded === 'panelGf'} onChange={handleChange('panelGf')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Typography className={classes.heading}>
                                        Dati immobile
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {GfDetails(QuotationInfo, classes)}
                                </AccordionDetails>
                            </Accordion>
                        </>
                    }

                    {QuotationInfo.quotationtype !== undefined && (QuotationInfo.quotationtype === "injuries" || QuotationInfo.quotationtype === "tcm" || QuotationInfo.quotationtype === "rcp") &&
                        <>
                            <Accordion expanded={expanded === 'panelDatiPolizza'} onChange={handleChange('panelDatiPolizza')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Typography className={classes.heading}>
                                        Dati polizza
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {DatiPolizzaDetails(QuotationInfo, classes)}
                                </AccordionDetails>
                            </Accordion>
                        </>
                    }

                    {QuotationInfo.quotationtype !== undefined && QuotationInfo.quotationtype === "rcp" &&
                        <>
                            <Accordion expanded={expanded === 'panelDatiStudio'} onChange={handleChange('panelDatiStudio')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Typography className={classes.heading}>
                                        Dati studio
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {DatiStudioDetails(QuotationInfo, classes)}
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panelDomanda'} onChange={handleChange('panelDomanda')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Typography className={classes.heading}>
                                        Domande
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {DomandaDetails(QuotationInfo, classes)}
                                </AccordionDetails>
                            </Accordion>
                        </>
                    }

                    {QuotationInfo.vehicleInfo !== undefined &&
                        <>
                            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Typography className={classes.heading}>
                                        Dati Veicolo
                                    </Typography>
                                    <Typography className={classes.secondaryHeading}>
                                        {QuotationInfo.vehicleInfo.licensePlate}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <TableContainer component={Paper}>
                                        <Table className={classes.table}>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell className={classes.cellTitle}>
                                                        Targa
                                                    </TableCell>
                                                    <TableCell>
                                                        {QuotationInfo.vehicleInfo.licensePlate}
                                                    </TableCell>
                                                    <TableCell className={classes.cellTitle}>
                                                        Uso
                                                    </TableCell>
                                                    <TableCell>
                                                        {QuotationInfo.clientinfo.use === "P" &&
                                                            <>PROPRIO</>
                                                        }
                                                        {QuotationInfo.clientinfo.use === "T" &&
                                                            <>TERZI</>
                                                        }
                                                        {QuotationInfo.clientinfo.use !== "T" && QuotationInfo.clientinfo.use !== "P" &&
                                                            <>{QuotationInfo.clientinfo.use}</>
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className={classes.cellTitle}>
                                                        Data immatricolazione
                                                    </TableCell>
                                                    <TableCell>
                                                        {moment(QuotationInfo.clientinfo.registrationDate, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                                    </TableCell>
                                                    <TableCell className={classes.cellTitle}>
                                                        Data acquisto
                                                    </TableCell>
                                                    <TableCell>
                                                        {moment(QuotationInfo.clientinfo.purchaseDate, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className={classes.cellTitle}>
                                                        Marca
                                                    </TableCell>
                                                    <TableCell>
                                                        {QuotationInfo.clientinfo.brand} - {QuotationInfo.clientinfo.sicDescription}
                                                    </TableCell>
                                                    <TableCell className={classes.cellTitle}>
                                                        Modello
                                                    </TableCell>
                                                    <TableCell>
                                                        {QuotationInfo.clientinfo.model}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className={classes.cellTitle}>
                                                        Allestimento
                                                    </TableCell>
                                                    <TableCell>
                                                        {QuotationInfo.clientinfo.outfitting}
                                                    </TableCell>
                                                    {QuotationInfo.vehicleInfo.vehicleType !== "C" && QuotationInfo.vehicleInfo.vehicleType !== "R" &&
                                                        <>
                                                            <TableCell className={classes.cellTitle}>
                                                                Carrozzeria
                                                            </TableCell>
                                                            <TableCell>
                                                                {QuotationInfo.clientinfo.carBody}
                                                            </TableCell>
                                                        </>
                                                    }
                                                    {(QuotationInfo.vehicleInfo.vehicleType === "C" || QuotationInfo.vehicleInfo.vehicleType === "R") &&
                                                        <>
                                                            <TableCell className={classes.cellTitle}>
                                                                Peso (KG)
                                                            </TableCell>
                                                            <TableCell>
                                                                {QuotationInfo.clientinfo.weight}
                                                            </TableCell>
                                                        </>
                                                    }
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className={classes.cellTitle}>
                                                        Alimentazione
                                                    </TableCell>
                                                    <TableCell>
                                                        {returnLabel(tipoAlimentazione, QuotationInfo.clientinfo.supply)}

                                                    </TableCell>
                                                    {QuotationInfo.clientinfo.displacement !== "" &&
                                                        <>
                                                            <TableCell className={classes.cellTitle}>
                                                                Cilindrata
                                                            </TableCell>
                                                            <TableCell>
                                                                {QuotationInfo.clientinfo.displacement}
                                                            </TableCell>
                                                        </>
                                                    }
                                                    {QuotationInfo.clientinfo.displacement === "" &&
                                                        <>
                                                            <TableCell className={classes.cellTitle}>
                                                            </TableCell>
                                                            <TableCell>
                                                            </TableCell>
                                                        </>
                                                    }
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className={classes.cellTitle}>
                                                        Potenza
                                                    </TableCell>
                                                    <TableCell>
                                                        {QuotationInfo.clientinfo.power}
                                                    </TableCell>
                                                    <TableCell className={classes.cellTitle}>
                                                        Cavalli fiscali
                                                    </TableCell>
                                                    <TableCell>
                                                        {QuotationInfo.clientinfo.horsePower}
                                                    </TableCell>
                                                </TableRow>
                                                {QuotationInfo.clientinfo.frame !== undefined && QuotationInfo.clientinfo.frame !== "" &&
                                                    <TableRow>
                                                        <TableCell className={classes.cellTitle}>
                                                            Numero telaio
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.frame}
                                                        </TableCell>
                                                        <TableCell className={classes.cellTitle}>
                                                            Codice omologazione
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.homologationCode}
                                                        </TableCell>
                                                    </TableRow>
                                                }
                                                <TableRow>
                                                    <TableCell className={classes.cellTitle}>
                                                        Codice MotorNet
                                                    </TableCell>
                                                    <TableCell>
                                                        {QuotationInfo.clientinfo.codiceMotornet}
                                                    </TableCell>
                                                    {QuotationInfo.vehicleInfo.vehicleType !== "C" && QuotationInfo.vehicleInfo.vehicleType !== "R" &&
                                                        <>
                                                            <TableCell className={classes.cellTitle}>
                                                                Km Annui percorsi
                                                            </TableCell>
                                                            <TableCell>
                                                                {returnDescription(kmAnnuiOption, QuotationInfo.clientinfo.kmAnnui)}
                                                            </TableCell>
                                                        </>
                                                    }
                                                    {(QuotationInfo.vehicleInfo.vehicleType === "C" || QuotationInfo.vehicleInfo.vehicleType === "R") &&
                                                        <>
                                                            <TableCell className={classes.cellTitle}>

                                                            </TableCell>
                                                            <TableCell>

                                                            </TableCell>
                                                        </>
                                                    }
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className={classes.cellTitle}>
                                                        Antifurto
                                                    </TableCell>
                                                    <TableCell>
                                                        {QuotationInfo.clientinfo.alarm === "1" && "Satellitare GPS"}
                                                        {(QuotationInfo.clientinfo.alarm === undefined || QuotationInfo.clientinfo.alarm === "2") && "Generico"}
                                                        {QuotationInfo.clientinfo.alarm === "3" && "Assente"}
                                                    </TableCell>
                                                    <TableCell className={classes.cellTitle}>
                                                        Gancio traino
                                                    </TableCell>
                                                    <TableCell>
                                                        {QuotationInfo.clientinfo.towHook === "false" ? "No" : QuotationInfo.clientinfo.towHook === "true" ? "Si" : QuotationInfo.clientinfo.towHook}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className={classes.cellTitle}>
                                                        Prezzo di listino
                                                    </TableCell>
                                                    <TableCell>
                                                        <NumberFormat fixedDecimalScale="true" decimalScale="2" value={QuotationInfo.clientinfo.newValue} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} />
                                                    </TableCell>
                                                    <TableCell className={classes.cellTitle}>
                                                        Valore
                                                    </TableCell>
                                                    <TableCell>
                                                        <NumberFormat fixedDecimalScale="true" decimalScale="2" value={QuotationInfo.clientinfo.usedValue} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} />
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Typography className={classes.heading}>
                                        Dati Polizza
                                    </Typography>
                                    <Typography className={classes.secondaryHeading}>
                                        {moment(QuotationInfo.clientinfo.effectiveDate, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <TableContainer component={Paper}>
                                        <Table className={classes.table}>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell className={classes.cellTitle}>
                                                        Data decorrenza polizza
                                                    </TableCell>
                                                    <TableCell>
                                                        {moment(QuotationInfo.clientinfo.effectiveDate, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                                    </TableCell>
                                                    <TableCell className={classes.cellTitle}>
                                                        Tipologia preventivo
                                                    </TableCell>
                                                    <TableCell>
                                                        {QuotationInfo.vehicleInfo.insuranceStatus === "RIN" && "Rinnovo polizza in scadenza o appena scaduta"}
                                                        {QuotationInfo.vehicleInfo.insuranceStatus === "PRI" && "Prima immatricolazione"}
                                                        {QuotationInfo.vehicleInfo.insuranceStatus === "VOL" && "Voltura al PRA"}
                                                        {QuotationInfo.vehicleInfo.insuranceStatus === "CVT" && "Solo CVT"}
                                                    </TableCell>
                                                </TableRow>

                                                <TableRow>
                                                    <TableCell className={classes.cellTitle}>
                                                        Veicolo
                                                    </TableCell>
                                                    <TableCell>
                                                        {(QuotationInfo.vehicleInfo.vehicleType === "AUT" || QuotationInfo.vehicleInfo.vehicleType === "A") && "Automobile"}
                                                        {QuotationInfo.vehicleInfo.vehicleType === "C" && "Autocarro"}
                                                        {QuotationInfo.vehicleInfo.vehicleType === "R" && "Rimorchio"}
                                                        {QuotationInfo.vehicleInfo.vehicleType === "M" && "Motociclo"}
                                                        {QuotationInfo.vehicleInfo.vehicleType === "W" && "Ciclomotore"}
                                                    </TableCell>
                                                    {(QuotationInfo.vehicleInfo.vehicleType === "A" || QuotationInfo.vehicleInfo.vehicleType === "AUT") &&
                                                        <>
                                                            {QuotationInfo.vehicleInfo.insuranceStatus === "RIN" &&
                                                                <>
                                                                    <TableCell className={classes.cellTitle}>
                                                                        Scadenza contrattuale
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {moment(QuotationInfo.clientinfo.policyExpirationDate, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                                                    </TableCell>
                                                                </>
                                                            }
                                                            {QuotationInfo.vehicleInfo.insuranceStatus !== "RIN" &&
                                                                <>
                                                                    <TableCell className={classes.cellTitle}>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                    </TableCell>
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                    {QuotationInfo.vehicleInfo.vehicleType !== "A" && QuotationInfo.vehicleInfo.vehicleType !== "AUT" && (QuotationInfo.vehicleInfo.fascia === "" || QuotationInfo.vehicleInfo.fascia === undefined) &&
                                                        <>
                                                            {QuotationInfo.vehicleInfo.insuranceStatus === "RIN" &&
                                                                <>
                                                                    <TableCell className={classes.cellTitle}>
                                                                        Scadenza contrattuale
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {moment(QuotationInfo.clientinfo.policyExpirationDate, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                                                    </TableCell>
                                                                </>
                                                            }
                                                            {QuotationInfo.vehicleInfo.insuranceStatus !== "RIN" &&
                                                                <>
                                                                    <TableCell className={classes.cellTitle}>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                    </TableCell>
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                    {QuotationInfo.vehicleInfo.vehicleType !== "A" && QuotationInfo.vehicleInfo.vehicleType !== "AUT" && QuotationInfo.vehicleInfo.fascia !== "" && QuotationInfo.vehicleInfo.fascia !== undefined &&
                                                        <>
                                                            <TableCell className={classes.cellTitle}>
                                                                Dichiarazione altro autocarro in
                                                            </TableCell>
                                                            <TableCell>
                                                                {(QuotationInfo.vehicleInfo.fascia === "FASCIA1" || QuotationInfo.vehicleInfo.fascia === "FASCIA3") &&
                                                                    <>FASCIA 1 (4+1 anni senza sinistri)</>
                                                                }
                                                                {QuotationInfo.vehicleInfo.fascia === "FASCIA2" &&
                                                                    <>FASCIA 2 (3+1 anni senza sinistri)</>
                                                                }
                                                            </TableCell>
                                                        </>
                                                    }
                                                </TableRow>
                                                {(QuotationInfo.vehicleInfo.vehicleType !== "C" && QuotationInfo.vehicleInfo.vehicleType !== "R") && (QuotationInfo.quoteInfo.extraLicensePlate !== "" || QuotationInfo.quoteInfo.familyDecree || QuotationInfo.quoteInfo.bersaniDecree) &&
                                                    <TableRow>
                                                        <TableCell className={classes.cellTitle}>
                                                            Opzioni
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.quoteInfo.familyDecree && "Decreto famliare"}
                                                            {QuotationInfo.quoteInfo.bersaniDecree && "Legge Bersani"}
                                                            {QuotationInfo.quoteInfo.familyDecree === false && QuotationInfo.quoteInfo.bersaniDecree === false && "Importa CU da altra targa"}
                                                        </TableCell>
                                                        <TableCell className={classes.cellTitle}>
                                                            Targa associata
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.quoteInfo.extraLicensePlate}
                                                        </TableCell>
                                                    </TableRow>
                                                }
                                                {QuotationInfo.vehicleInfo.vehicleType !== "C" && QuotationInfo.vehicleInfo.vehicleType !== "R" &&
                                                    <TableRow>
                                                        <TableCell className={classes.cellTitle}>
                                                            Tipo di guida
                                                        </TableCell>
                                                        <TableCell>
                                                            {returnLabel(tipoGuida, QuotationInfo.clientinfo.guideType)}
                                                        </TableCell>
                                                        <TableCell className={classes.cellTitle}>
                                                            Età del guidatore più giovane
                                                        </TableCell>
                                                        <TableCell>
                                                            {returnLabel(etaGuidatorePiuGiovane, QuotationInfo.clientinfo.youngestLicensedAge)}
                                                        </TableCell>
                                                    </TableRow>
                                                }

                                                <TableRow>
                                                    <TableCell className={classes.cellTitle}>
                                                        Massimale
                                                    </TableCell>
                                                    <TableCell>
                                                        {QuotationInfo.vehicleInfo.vehicleType !== "C" && QuotationInfo.vehicleInfo.vehicleType !== "R" &&
                                                            <>
                                                                {returnLabel(ElencoMassimali, QuotationInfo.clientinfo.maximal)}
                                                            </>
                                                        }
                                                        {(QuotationInfo.vehicleInfo.vehicleType === "C" || QuotationInfo.vehicleInfo.vehicleType === "R") &&
                                                            <>
                                                                {returnLabel(ElencoMassimaliAutocarri, QuotationInfo.clientinfo.maximal)}
                                                            </>
                                                        }
                                                    </TableCell>
                                                    <TableCell className={classes.cellTitle}>
                                                        Frazionamento
                                                    </TableCell>
                                                    <TableCell>
                                                        {returnLabel(tipoFrazionamento, Number((QuotationInfo.clientinfo.splittingUp === undefined || QuotationInfo.clientinfo.splittingUp === "") ? 1 : QuotationInfo.clientinfo.splittingUp))}
                                                    </TableCell>
                                                </TableRow>

                                                {(QuotationInfo.vehicleInfo.vehicleType === "M" || QuotationInfo.vehicleInfo.vehicleType === "W") &&
                                                    <>
                                                        <TableRow>
                                                            <TableCell className={classes.cellTitle}>
                                                                Cilindrata più alta guidata
                                                            </TableCell>
                                                            <TableCell>
                                                                {returnLabel(opzioniCCDrivedMotorBike, QuotationInfo.clientinfo.highestCcDrived)}
                                                            </TableCell>
                                                            <TableCell className={classes.cellTitle}>
                                                                Mesi di guida consecutivi
                                                            </TableCell>
                                                            <TableCell>
                                                                {returnLabel(opzioniMesiDiGuidaMotorBike, QuotationInfo.clientinfo.drivingConsecutiveMonthCode)}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell className={classes.cellTitle}>
                                                                Copertura sospesa l'ultimo anno
                                                            </TableCell>
                                                            <TableCell>
                                                                {QuotationInfo.clientinfo.suspendedLastYear === undefined || QuotationInfo.clientinfo.suspendedLastYear === "no" ? "No" : "Si"}
                                                            </TableCell>
                                                            <TableCell className={classes.cellTitle}>
                                                            </TableCell>
                                                            <TableCell>
                                                            </TableCell>
                                                        </TableRow>
                                                    </>
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Typography className={classes.heading}>
                                        Attestazione dello stato di rischio
                                    </Typography>
                                    <Typography className={classes.secondaryHeading}>
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <TableContainer component={Paper}>
                                        <Table className={classes.table}>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell className={classes.cellTitle}>
                                                        Compagnia di provenienza
                                                    </TableCell>
                                                    <TableCell>
                                                        {QuotationInfo.clientinfo.insuranceCompany}
                                                    </TableCell>
                                                    <TableCell className={classes.cellTitle}>
                                                        Tipologia di tariffa
                                                    </TableCell>
                                                    <TableCell>
                                                        {QuotationInfo.clientinfo.declarationOrigin}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className={classes.cellTitle}>
                                                        CU di provenienza
                                                    </TableCell>
                                                    <TableCell>
                                                        {QuotationInfo.clientinfo.cuOrigin}
                                                    </TableCell>
                                                    <TableCell className={classes.cellTitle}>
                                                        CU di assegnazione
                                                    </TableCell>
                                                    <TableCell>
                                                        {QuotationInfo.clientinfo.cuAssignment}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    {Number(QuotationInfo.clientinfo.cuOrigin) === 1 && (QuotationInfo.clientinfo.currentAward === undefined || QuotationInfo.clientinfo.currentAward === "" || Number(QuotationInfo.clientinfo.currentAward) <= 0) &&
                                                        <>
                                                            <TableCell className={classes.cellTitle}>
                                                                Anni in classe 1
                                                            </TableCell>
                                                            <TableCell>
                                                                {QuotationInfo.clientinfo.insuranceYearsCU1}
                                                            </TableCell>
                                                            <TableCell className={classes.cellTitle}>
                                                            </TableCell>
                                                            <TableCell>
                                                            </TableCell>
                                                        </>
                                                    }
                                                    {Number(QuotationInfo.clientinfo.cuOrigin) !== 1 && QuotationInfo.clientinfo.currentAward !== undefined && QuotationInfo.clientinfo.currentAward !== "" && Number(QuotationInfo.clientinfo.currentAward) > 0 &&
                                                        <>
                                                            <TableCell className={classes.cellTitle}>
                                                                Premio attuale
                                                            </TableCell>
                                                            <TableCell>
                                                                <NumberFormat fixedDecimalScale="true" decimalScale="2" value={QuotationInfo.clientinfo.currentAward} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} />
                                                            </TableCell>
                                                            <TableCell className={classes.cellTitle}>
                                                            </TableCell>
                                                            <TableCell>
                                                            </TableCell>
                                                        </>
                                                    }
                                                    {Number(QuotationInfo.clientinfo.cuOrigin) === 1 && QuotationInfo.clientinfo.currentAward !== undefined && QuotationInfo.clientinfo.currentAward !== "" && Number(QuotationInfo.clientinfo.currentAward) > 0 &&
                                                        <>
                                                            <TableCell className={classes.cellTitle}>
                                                                Anni in classe 1
                                                            </TableCell>
                                                            <TableCell>
                                                                {QuotationInfo.clientinfo.insuranceYearsCU1}
                                                            </TableCell>
                                                            <TableCell className={classes.cellTitle}>
                                                                Premio attuale
                                                            </TableCell>
                                                            <TableCell>
                                                                <NumberFormat fixedDecimalScale="true" decimalScale="2" value={QuotationInfo.clientinfo.currentAward} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} />
                                                            </TableCell>
                                                        </>
                                                    }
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell className={classes.cellTitle}>
                                                        Hai già usufruito della legge Bersani?
                                                    </TableCell>
                                                    <TableCell>
                                                        {QuotationInfo.clientinfo.usedBersani ? "SI" : "NO"}
                                                    </TableCell>
                                                    <TableCell className={classes.cellTitle}>
                                                        Da quanti anni sei assicurato?
                                                    </TableCell>
                                                    <TableCell>
                                                        {QuotationInfo.clientinfo.insuranceYears}
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Typography className={classes.heading}>
                                        Dettaglio sinistri attestato
                                    </Typography>
                                    <Typography className={classes.secondaryHeading}>
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <TableContainer component={Paper}>
                                        <Table className={classes.table}>
                                            {QuotationInfo.clientinfo.dataATRC !== null &&
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell className={classes.cellTitle}>
                                                            Tipo sinistri
                                                        </TableCell>
                                                        <TableCell className={classes.cellTitle}>
                                                            {Number(QuotationInfo.clientinfo.dataATRC.anno0Atrc) - 10}
                                                        </TableCell>
                                                        <TableCell className={classes.cellTitle}>
                                                            {Number(QuotationInfo.clientinfo.dataATRC.anno0Atrc) - 9}
                                                        </TableCell>
                                                        <TableCell className={classes.cellTitle}>
                                                            {Number(QuotationInfo.clientinfo.dataATRC.anno0Atrc) - 8}
                                                        </TableCell>
                                                        <TableCell className={classes.cellTitle}>
                                                            {Number(QuotationInfo.clientinfo.dataATRC.anno0Atrc) - 7}
                                                        </TableCell>
                                                        <TableCell className={classes.cellTitle}>
                                                            {Number(QuotationInfo.clientinfo.dataATRC.anno0Atrc) - 6}
                                                        </TableCell>
                                                        <TableCell className={classes.cellTitle}>
                                                            {Number(QuotationInfo.clientinfo.dataATRC.anno0Atrc) - 5}
                                                        </TableCell>
                                                        <TableCell className={classes.cellTitle}>
                                                            {Number(QuotationInfo.clientinfo.dataATRC.anno0Atrc) - 4}
                                                        </TableCell>
                                                        <TableCell className={classes.cellTitle}>
                                                            {Number(QuotationInfo.clientinfo.dataATRC.anno0Atrc) - 3}
                                                        </TableCell>
                                                        <TableCell className={classes.cellTitle}>
                                                            {Number(QuotationInfo.clientinfo.dataATRC.anno0Atrc) - 2}
                                                        </TableCell>
                                                        <TableCell className={classes.cellTitle}>
                                                            {Number(QuotationInfo.clientinfo.dataATRC.anno0Atrc) - 1}
                                                        </TableCell>
                                                        <TableCell className={classes.cellTitle}>
                                                            {QuotationInfo.clientinfo.dataATRC.anno0Atrc}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell className={classes.cellTitle}>
                                                            Sinistri con responsabilità principale
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPrincAnno10}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPrincAnno9}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPrincAnno8}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPrincAnno7}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPrincAnno6}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPrincAnno5}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPrincAnno4}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPrincAnno3}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPrincAnno2}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPrincAnno1}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPrincAnno0}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            di cui con danni a: COSE
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniCoseAnno10}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniCoseAnno9}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniCoseAnno8}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniCoseAnno7}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniCoseAnno6}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniCoseAnno5}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniCoseAnno4}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniCoseAnno3}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniCoseAnno2}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniCoseAnno1}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniCoseAnno0}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            di cui con danni a: PERSONE
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniPersAnno10}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniPersAnno9}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniPersAnno8}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniPersAnno7}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniPersAnno6}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniPersAnno5}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniPersAnno4}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniPersAnno3}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniPersAnno2}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniPersAnno1}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniPersAnno0}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            di cui con danni a: MISTI
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniMistiAnno10}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniMistiAnno9}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniMistiAnno8}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniMistiAnno7}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniMistiAnno6}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniMistiAnno5}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniMistiAnno4}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniMistiAnno3}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniMistiAnno2}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniMistiAnno1}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniMistiAnno0}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell className={classes.cellTitle}>
                                                            Sinistri con responsabilità paritaria
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPariAnno10}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPariAnno9}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPariAnno8}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPariAnno7}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPariAnno6}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPariAnno5}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPariAnno4}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPariAnno3}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPariAnno2}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPariAnno1}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPariAnno0}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            di cui con danni a: COSE
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniCoseAnno10}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniCoseAnno9}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniCoseAnno8}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniCoseAnno7}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniCoseAnno6}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniCoseAnno5}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniCoseAnno4}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniCoseAnno3}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniCoseAnno2}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniCoseAnno1}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniCoseAnno0}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            di cui con danni a: PERSONE
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniPersAnno10}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniPersAnno9}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniPersAnno8}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniPersAnno7}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniPersAnno6}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniPersAnno5}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniPersAnno4}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniPersAnno3}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniPersAnno2}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniPersAnno1}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniPersAnno0}
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            di cui con danni a: MISTI
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniMistiAnno10}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniMistiAnno9}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniMistiAnno8}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniMistiAnno7}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniMistiAnno6}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniMistiAnno5}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniMistiAnno4}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniMistiAnno3}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniMistiAnno2}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniMistiAnno1}
                                                        </TableCell>
                                                        <TableCell>
                                                            {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniMistiAnno0}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            }
                                        </Table>
                                    </TableContainer>
                                </AccordionDetails>
                            </Accordion>
                            {QuotationInfo.clientinfo.listDataCARD !== null && QuotationInfo.clientinfo.listDataCARD.length > 0 &&
                                <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                    >
                                        <Typography className={classes.heading}>
                                            CARD
                                        </Typography>
                                        <Typography className={classes.secondaryHeading}>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <TableContainer component={Paper}>
                                            <Table className={classes.table}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell className={classes.cellTitle}>
                                                            Elenco CARD
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>
                                                            CARD
                                                        </TableCell>
                                                        <TableCell>
                                                            Sinistro
                                                        </TableCell>
                                                        <TableCell>
                                                            Accadimento
                                                        </TableCell>
                                                        <TableCell>
                                                            Aggiornamento
                                                        </TableCell>
                                                        <TableCell>
                                                            Ultima stanza
                                                        </TableCell>
                                                        <TableCell>
                                                            Debitrice
                                                        </TableCell>
                                                        <TableCell>
                                                            Gestionaria
                                                        </TableCell>
                                                        <TableCell>
                                                            Stato
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {QuotationInfo.clientinfo.listDataCARD.map((card) => (
                                                        <TableRow key={card.sinisterNumber}>
                                                            <TableCell>
                                                                {card.vehicleDebtorId === QuotationInfo.vehicleInfo.licensePlate ? "PASSIVO" : "ATTIVO"}
                                                            </TableCell>
                                                            <TableCell>
                                                                {card.sinisterNumber}
                                                            </TableCell>
                                                            <TableCell>
                                                                {moment(card.occuranceDate, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                                            </TableCell>
                                                            <TableCell>
                                                                {moment(card.updateDate, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                                            </TableCell>
                                                            <TableCell>
                                                                {moment(card.lastRoomDate, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography>{card.debtorCompany}</Typography>
                                                                <Typography>{card.vehicleDebtorType}</Typography>
                                                                <Typography>{card.vehicleManagementIdFormat} </Typography>
                                                                <Typography>{card.vehicleDebtorId}</Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography>{card.managementCompany}</Typography>
                                                                <Typography>{card.vehicleManagementType}</Typography>
                                                                <Typography>{card.vehicleManagementIdFormat}</Typography>
                                                                <Typography>{card.vehicleManagementId}</Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                {card.sinisterStatus}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </AccordionDetails>
                                </Accordion>
                            }
                        </>
                    }

                    {QuotationInfo.clientinfo.clausole !== null && QuotationInfo.clientinfo.clausole !== undefined && QuotationInfo.clientinfo.clausole.length > 0 &&
                        <>
                            {QuotationInfo.quotationtype === "injuries" &&
                                <Accordion expanded={expanded === 'panelClausole'} onChange={handleChange('panelClausole')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                    >
                                        <Typography className={classes.heading}>
                                            Clausole
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <TableContainer className={classes.table}>
                                            <Table aria-label="Clausole">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell style={{ width: "100%" }}>Clausola</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {ClausoleDetails(QuotationInfo.clientinfo.clausole, clausoleInjuries)}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </AccordionDetails>
                                </Accordion>
                            }
                        </>
                    }

                    {QuotationInfo.clientinfo.questionario !== null && QuotationInfo.clientinfo.questionario !== undefined &&
                        <>
                            {QuotationInfo.quotationtype === "injuries" && QuotationInfo.clientinfo.questionario.questionarioInfortunio !== undefined && QuotationInfo.clientinfo.questionario.questionarioInfortunio.inf_domanda_1 !== "" &&
                                <Accordion expanded={expanded === 'panelQuestionarioInfortunio'} onChange={handleChange('panelQuestionarioInfortunio')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                    >
                                        <Typography className={classes.heading}>
                                            Questionario Infortunio
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <TableContainer className={classes.table}>
                                            <Table aria-label="Questionario INFORTUNIO">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell style={{ width: "60%" }}>Domanda</TableCell>
                                                        <TableCell>Risposta</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {QuestionarioDetails(QuotationInfo.clientinfo.questionario.questionarioInfortunio, domandeQuestionarioInfortunio)}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </AccordionDetails>
                                </Accordion>
                            }
                            {QuotationInfo.quotationtype === "injuries" && QuotationInfo.clientinfo.questionario.questionarioMalattia !== undefined && QuotationInfo.clientinfo.questionario.questionarioMalattia.mal_domanda_1 !== "" &&
                                <Accordion expanded={expanded === 'panelQuestionarioMalattia'} onChange={handleChange('panelQuestionarioMalattia')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                    >
                                        <Typography className={classes.heading}>
                                            Questionario Malattia
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <TableContainer className={classes.table}>
                                            <Table aria-label="Questionario MALATTIA">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell style={{ width: "60%" }}>Domanda</TableCell>
                                                        <TableCell>Risposta</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {QuestionarioDetails(QuotationInfo.clientinfo.questionario.questionarioMalattia, domandeQuestionarioMalattia)}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </AccordionDetails>
                                </Accordion>
                            }
                            {QuotationInfo.quotationtype === "tcm" && QuotationInfo.clientinfo.questionario.questionarioTcm1.tcm_domanda_1 !== "" &&
                                <Accordion expanded={expanded === 'panelQuestionarioTcm1'} onChange={handleChange('panelQuestionarioTcm1')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                    >
                                        <Typography className={classes.heading}>
                                            Dichiarazione dell'assicurato
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <TableContainer className={classes.table}>
                                            <Table aria-label="Dichiarazione dell'assicurato">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell style={{ width: "60%" }}>Domanda</TableCell>
                                                        <TableCell>Risposta</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {QuestionarioDetails(QuotationInfo.clientinfo.questionario.questionarioTcm1, domandeQuestionarioTcm1)}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </AccordionDetails>
                                </Accordion>
                            }
                            {QuotationInfo.quotationtype === "tcm" && QuotationInfo.clientinfo.questionario.questionarioTcm2.tcm_domanda_1 !== "" &&
                                <Accordion expanded={expanded === 'panelQuestionarioTcm2'} onChange={handleChange('panelQuestionarioTcm2')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                    >
                                        <Typography className={classes.heading}>
                                            Dichiarazione professionale, sportiva e sanitaria dell'assicurato
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <TableContainer className={classes.table}>
                                            <Table aria-label="Dichiarazione dell'assicurato">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell style={{ width: "60%" }}>Domanda</TableCell>
                                                        <TableCell>Risposta</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {QuestionarioDetails(QuotationInfo.clientinfo.questionario.questionarioTcm2, domandeQuestionarioTcm2)}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </AccordionDetails>
                                </Accordion>
                            }
                            {QuotationInfo.quotationtype === "gf" && QuotationInfo.clientinfo.questionario.questionarioGf !== undefined && QuotationInfo.clientinfo.questionario.questionarioGf.gf_domanda_1 !== "" &&
                                <Accordion expanded={expanded === 'panelQuestionarioGf'} onChange={handleChange('panelQuestionarioGf')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                    >
                                        <Typography className={classes.heading}>
                                            Questionario dichiarativo
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <TableContainer className={classes.table}>
                                            <Table aria-label="Questionario dichiarativo">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell style={{ width: "60%" }}>Domanda</TableCell>
                                                        <TableCell>Risposta</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {QuestionarioDetails(QuotationInfo.clientinfo.questionario.questionarioGf, domandeQuestionarioGf)}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </AccordionDetails>
                                </Accordion>
                            }
                        </>
                    }

                    {Quotation.status === 1 &&
                        <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                <Typography className={classes.heading}>
                                    Dettagli pagamento
                                </Typography>
                                <Typography className={classes.secondaryHeading}>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className={classes.cellTitle}>
                                                    Transazione: {Quotation.payment_detail}
                                                </TableCell>
                                                <TableCell className={classes.cellTitle}>
                                                    Importo: <NumberFormat fixedDecimalScale="true" decimalScale="2" value={Quotation.payment_import} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} />
                                                </TableCell>
                                            </TableRow>
                                            {paymentsrequest.length > 0 &&
                                                <TableRow>
                                                    <TableCell>
                                                        Data
                                                    </TableCell>
                                                    <TableCell>
                                                        Numero ordine
                                                    </TableCell>
                                                    <TableCell>
                                                        Numero autorizzazione
                                                    </TableCell>
                                                    <TableCell>
                                                        Importo
                                                    </TableCell>
                                                    <TableCell>
                                                        Codice Transazione
                                                    </TableCell>
                                                    <TableCell>
                                                        Esito
                                                    </TableCell>
                                                </TableRow>
                                            }
                                        </TableHead>
                                        <TableBody>
                                            {paymentsrequest.map((item) => (
                                                <TableRow className={item.result === "00" ? classes.listpaymentok : classes.listpayment}>
                                                    <TableCell>
                                                        {moment(item.updated_at, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss")}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item.orderid}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item.authnumber}
                                                    </TableCell>
                                                    <TableCell>
                                                        <NumberFormat fixedDecimalScale="true" decimalScale="2" value={item.amount / 100} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={'€'} />
                                                    </TableCell>
                                                    <TableCell>
                                                        {item.transactionid}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item.result === "OK" && "OK"}
                                                        {item.result === "00" && "OK"}
                                                        {item.result === "--" && "--"}
                                                        {item.result !== "OK" && item.result !== "00" && item.result !== "--" && "Errore: " + item.result}
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </AccordionDetails>
                        </Accordion>
                    }

                    {Quotation.n_quot_tot > 0 && Quotation.n_quot_tot > Quotation.n_quot_exe &&
                        <Fragment>
                            <Grid className={classes.TitleRow}>
                                <Grid item xs={12}>
                                    <Typography className={classes.cellTitleSoon} variant="h6" gutterBottom>
                                        A breve saranno disponibili altri preventivi
                                        <LinearProgressWithLabel value={Quotation.n_quot_exe / Quotation.n_quot_tot * 100} second={progress} />
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Fragment>
                    }

                    {QuotationSelect && QuotationSelect.company !== "" &&
                        <Fragment>
                            <Grid className={classes.TitleRow} container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography className={classes.titlePadding} variant="h5" gutterBottom>
                                        Quotazione scelta
                                    </Typography>
                                </Grid>
                            </Grid>
                            {QuotationPrice.map((Quote, index) => (
                                <Fragment key={index}>
                                    {Quote.datipre !== null && Quote.datipre !== undefined && Quote.datipre.prices !== undefined &&
                                        <Fragment>
                                            {Quote.datipre.prices.map((singlePrice, index) => (
                                                <Fragment key={index}>
                                                    {singlePrice.nrQuotation === QuotationSelect.nrQuotation && singlePrice.company === QuotationSelect.company &&
                                                        <Fragment>
                                                            <QuotationDetails email={
                                                                QuotationInfo.clientinfo.email !== undefined ? QuotationInfo.clientinfo.email :
                                                                    QuotationInfo.clientinfo.contractorInfo.email !== undefined ? QuotationInfo.clientinfo.contractorInfo.email :
                                                                        Quotation.email} key={index} select={true} numberquotation={numberquotation} factories={factories} company={Quote.company} datipre={{ prices: [QuotationSelect] }} saveActive={false} notConfirmed={Quotation.status !== 1 && Quotation.status !== 3} />
                                                        </Fragment>
                                                    }
                                                </Fragment>
                                            ))
                                            }
                                        </Fragment>
                                    }
                                </Fragment>
                            ))}
                        </Fragment>
                    }

                    {QuotationSelect && QuotationSelect.company === "" && QuotationAdd !== false &&
                        <Fragment>
                            <Grid className={classes.TitleRow} container spacing={3}>
                                <Grid item xs={8}>
                                    <Typography className={classes.titlePadding} variant="h5" gutterBottom>
                                        Quotazioni scelte
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography className={classes.totalPadding} variant="h5" gutterBottom>
                                        Totale da pagare: <NumberFormat fixedDecimalScale="true" decimalScale="2" value={getTotalQuotationAdd(QuotationAdd)} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} />
                                    </Typography>
                                </Grid>
                            </Grid>
                            {QuotationAdd.map((QuoteAdd, index) => (
                                <Fragment key={index}>
                                    {QuotationPrice.map((Quote, index) => (
                                        <Fragment key={index}>
                                            {Quote.datipre !== null && Quote.datipre !== undefined && Quote.datipre.prices !== undefined &&
                                                <Fragment>
                                                    {Quote.datipre.prices.map((singlePrice, index) => (
                                                        <Fragment key={index}>
                                                            {singlePrice.nrQuotation === QuoteAdd.nrQuotation && singlePrice.company === QuoteAdd.company &&
                                                                <Fragment>
                                                                    <QuotationDetails email={
                                                                        QuotationInfo.clientinfo.email !== undefined ? QuotationInfo.clientinfo.email :
                                                                            QuotationInfo.clientinfo.contractorInfo.email !== undefined ? QuotationInfo.clientinfo.contractorInfo.email :
                                                                                Quotation.email} key={index} select={true} numberquotation={numberquotation} datipre={{ prices: [QuoteAdd] }} factories={factories} factoriesHub={factoriesHub} company={Quote.company} saveActive={false} notConfirmed={Quotation.status !== 1 && Quotation.status !== 3} isQuoteAdd={true} />
                                                                </Fragment>
                                                            }
                                                        </Fragment>
                                                    ))
                                                    }
                                                </Fragment>
                                            }
                                        </Fragment>
                                    ))}
                                </Fragment>
                            ))}
                        </Fragment>
                    }

                    {QuotationSelect === false && QuotationAdd !== undefined && QuotationAdd !== false && QuotationAdd !== null && QuotationAdd.length > 0 &&
                        <Fragment>
                            <Grid className={classes.TitleRow} container spacing={3}>
                                <Grid item xs={6}>
                                    <Typography className={classes.titlePadding} variant="h5" gutterBottom>
                                        Quotazioni selezionate
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography className={classes.totalPadding} variant="h5" gutterBottom>
                                        Totale da pagare: <NumberFormat fixedDecimalScale="true" decimalScale="2" value={getTotalQuotationAdd(QuotationAdd)} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} />
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography className={classes.totalPadding} variant="h5" gutterBottom>
                                        <Button className={classes.buttonClass} variant="outlined" onClick={handleSaveAdd(0)} size="small">Salva per ordine</Button>
                                    </Typography>
                                </Grid>
                            </Grid>
                            {QuotationAdd.map((QuoteAdd, index) => (
                                <Fragment key={index}>
                                    {QuotationPrice.map((Quote, index) => (
                                        <Fragment key={index}>
                                            {Quote.datipre !== null && Quote.datipre !== undefined && Quote.datipre.prices !== undefined &&
                                                <Fragment>
                                                    {Quote.datipre.prices.map((singlePrice, index) => (
                                                        <Fragment key={index}>
                                                            {singlePrice.nrQuotation === QuoteAdd.nrQuotation && singlePrice.company === QuoteAdd.company &&
                                                                <Fragment>
                                                                    <QuotationDetails email={
                                                                        QuotationInfo.clientinfo.email !== undefined ? QuotationInfo.clientinfo.email :
                                                                            QuotationInfo.clientinfo.contractorInfo.email !== undefined ? QuotationInfo.clientinfo.contractorInfo.email :
                                                                                Quotation.email} key={index} select={false} numberquotation={numberquotation} datipre={{ prices: [QuoteAdd] }} factories={factories} factoriesHub={factoriesHub} company={Quote.company} saveActive={false} notConfirmed={Quotation.status !== 1 && Quotation.status !== 3} isQuoteAdd={true} />
                                                                </Fragment>
                                                            }
                                                        </Fragment>
                                                    ))
                                                    }
                                                </Fragment>
                                            }
                                        </Fragment>
                                    ))}
                                </Fragment>
                            ))}
                        </Fragment>
                    }

                    <Grid className={classes.TitleRow} container spacing={3}>
                        <Grid item xs={4}>
                            <Typography className={classes.titlePadding} variant="h5" gutterBottom>
                                {QuotationSelect ? "Quotazioni escluse" : "Quotazioni"}
                            </Typography>
                        </Grid>
                        {QuotationInfo.clientinfo.currentAward !== undefined && QuotationInfo.clientinfo.currentAward !== "" && Number(QuotationInfo.clientinfo.currentAward) > 0 &&
                            <>
                                <Grid item xs={4}>
                                    <Typography className={classes.titlePaddingPremio} variant="h5" gutterBottom>
                                        Premio attuale
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography align="left" className={classes.thirdHeading} variant="h5" gutterBottom>
                                        <NumberFormat fixedDecimalScale="true" decimalScale="2" value={QuotationInfo.clientinfo.currentAward} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} />
                                    </Typography>
                                </Grid>
                            </>
                        }
                    </Grid>

                    {QuotationPrice.map((Quote, index) => (
                        <Fragment key={index}>
                            {Quote.datipre !== null && Quote.datipre !== undefined && Quote.datipre.prices !== undefined &&
                                <QuotationDetails
                                    email={
                                        QuotationInfo.clientinfo.email !== undefined ? QuotationInfo.clientinfo.email :
                                            QuotationInfo.clientinfo.contractorInfo.email !== undefined ? QuotationInfo.clientinfo.contractorInfo.email :
                                                Quotation.email}
                                    quotationInfo={QuotationInfo}
                                    onSave={handleSaveModify} QuotationSelect={QuotationSelect}
                                    factories={factories} factoriesHub={factoriesHub} company={Quote.company} datipre={Quote.datipre}
                                    saveActive={!CheckExpiredDate(Quotation) && !QuotationSelect} numberquotation={numberquotation} notConfirmed={false} quotationAdd={QuotationAdd}
                                    orgid={Quotation.orgid} /*isChange={changePrice}*/ />
                            }
                        </Fragment>
                    )
                    )}

                    <Dialog fullScreen open={isDialogOpen} onClose={handleDialogClose} TransitionComponent={Transition}>
                        <AppBar className={classes.appBar}>
                            <Toolbar>
                                <IconButton edge="start" color="inherit" onClick={handleDialogClose} aria-label="close">
                                    <CloseIcon />
                                </IconButton>
                                <Typography variant="h6" className={classes.title}>
                                    {singleData === undefined ? "" : (isOrderModule ? ("Modulo ordine preventivo nr. " + singleData.id) : ("Preventivo nr. " + singleData.id))}
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        {isDialogOpen &&
                            <PDFViewer className={classes.pdfform}>
                                <QuotationPDF links={links} DisplayImg={true} factories={factories} factoriesHub={factoriesHub} factoriesInfoImage={factoriesInfoImage} row={singleData} qrcode={qrCodeImage} orderModule={isOrderModule} QuotationSelect={QuotationSelect} Images={images} paymentsrequest={paymentsrequest} QuotationAdd={QuotationAdd} />
                            </PDFViewer>
                        }
                    </Dialog>
                </CardContent>
            }

            <Snackbar open={openAlert} autoHideDuration={3000} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity={alertseverity}>
                    {alertmsg}
                </Alert>
            </Snackbar>

            {images.length > 0 &&
                <Carousel
                    className={classes.Carousel}
                    autoPlay={true}
                    //animation={"fade"}
                    indicators={true}
                    timeout={500}
                    cycleNavigation={true}
                    navButtonsAlwaysVisible={true}
                    navButtonsAlwaysInvisible={false}
                    fullHeightHover={true}
                >
                    {
                        images.map((item, index) => {
                            return <CardMedia
                                key={index}
                                className={classes.Media}
                                image={item.image}
                            >
                            </CardMedia>
                        })
                    }
                </Carousel>
            }
        </Card>

        <Dialog
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={openConfirmSave}
        >
            <DialogTitle id="confirmation-dialog-title">{titleConfirmSave}</DialogTitle>
            <DialogContent dividers>
                {msgConfirmSave}
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancelConfirm} color="primary">
                    Annulla
                </Button>
                <Button onClick={handleOkSaveConfirm} color="primary">
                    Conferma
                </Button>
            </DialogActions>

        </Dialog>

        {QuotationInfo !== false && Quotation !== false &&
            <Dialog
                fullWidth={true}
                maxWidth="md"
                aria-labelledby="confirmation-dialog-title"
                open={openPaymentOptions}
            >
                <DialogTitle id="confirmation-dialog-title">
                    <AppBar position="static">
                        <Tabs value={numTab} onChange={handleChangeTab} centered>
                            <Tab label="Paga con" {...a11yProps(0)} />
                            <Tab label="Crea link pagamento" disabled={!PaymentPayBylinkActive} {...a11yProps(1)} />
                        </Tabs>
                    </AppBar>
                </DialogTitle>
                <DialogContent dividers>
                    {numTab === 0 &&
                        <>
                            <RadioGroup
                                aria-label="PaymentOptionSelect"
                                name="PaymentOptionSelect"
                                value={PaymentOptionSelect}
                                onChange={handleChangePayment}
                            >
                                {paymentsOptions.map((option) => (
                                    <FormControlLabel value={option.id} key={"TAB1_" + option.id} control={<Radio />} label={option.description} />
                                ))}
                            </RadioGroup>
                        </>
                    }

                    {numTab === 1 &&
                        <>
                            <RadioGroup
                                aria-label="PaymentOptionSelect"
                                name="PaymentOptionSelect"
                                value={PaymentOptionSelect}
                                onChange={handleChangePayment}
                            >
                                {paymentsOptions.map((option) => (
                                    <>
                                        {option.config !== "" && option.config !== null &&
                                            JSON.parse(option.config).map((item, key) => (
                                                <>
                                                    {Object.keys(item)[0] === "paybylink" &&
                                                        <>
                                                            {JSON.parse(option.config)[key][Object.keys(item)[0]].value === "1" &&
                                                                <>
                                                                    <FormControlLabel value={option.id} key={"TAB2_" + option.id} control={<Radio />} label={option.description} />
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </>
                                            ))
                                        }
                                    </>
                                ))}
                            </RadioGroup>
                        </>
                    }

                    {paymentsOptions.filter(active => active.id === PaymentOptionSelect).length > 0 &&
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="subtitle1" gutterBottom>
                                                Quotazione
                                            </Typography>
                                        </TableCell>
                                        <TableCell align='right'>
                                            <NumberFormat fixedDecimalScale="true" decimalScale="2" value={Number(QuotationSelect.TotalRate)} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} />
                                        </TableCell>
                                    </TableRow>
                                    {paymentsOptions.filter(active => active.id === PaymentOptionSelect)[0].removecommissions === 1 &&
                                        <TableRow>
                                            <TableCell>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    Commissioni
                                                </Typography>
                                            </TableCell>
                                            <TableCell align='right'>
                                                <NumberFormat fixedDecimalScale="true" decimalScale="2" value={-Number(QuotationSelect.Commissions) / Number(QuotationSelect.splittingUp)} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} />
                                            </TableCell>
                                        </TableRow>
                                    }
                                    {paymentsOptions.filter(active => active.id === PaymentOptionSelect)[0].removefee === 1 &&
                                        <TableRow>
                                            <TableCell>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    Diritti
                                                </Typography>
                                            </TableCell>
                                            <TableCell align='right'>
                                                <NumberFormat fixedDecimalScale="true" decimalScale="2" value={-Number(QuotationSelect.Fees) / Number(QuotationSelect.splittingUp)} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} />
                                            </TableCell>
                                        </TableRow>
                                    }
                                    {paymentsOptions.filter(active => active.id === PaymentOptionSelect)[0].paymentcommissions > 0 &&
                                        <TableRow>
                                            <TableCell>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    Commissioni su incasso ({paymentsOptions.filter(active => active.id === PaymentOptionSelect)[0].paymentcommissions}%)
                                                </Typography>
                                            </TableCell>
                                            <TableCell align='right'>
                                                <NumberFormat fixedDecimalScale="true" decimalScale="2" value={CalculateToPay(QuotationSelect, paymentsOptions.filter(active => active.id === PaymentOptionSelect)[0], true)} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} />
                                            </TableCell>
                                        </TableRow>
                                    }
                                    {paymentsOptions.filter(active => active.id === PaymentOptionSelect)[0].paymentfee > 0 &&
                                        <TableRow>
                                            <TableCell>
                                                <Typography variant="subtitle1" gutterBottom>
                                                    Diritti su incasso
                                                </Typography>
                                            </TableCell>
                                            <TableCell align='right'>
                                                <NumberFormat fixedDecimalScale="true" decimalScale="2" value={paymentsOptions.filter(active => active.id === PaymentOptionSelect)[0].paymentfee} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} />
                                            </TableCell>
                                        </TableRow>
                                    }
                                    <TableRow>
                                        <TableCell>
                                            <Typography className={classes.subtitle} variant="subtitle1" gutterBottom>
                                                Totale
                                            </Typography>
                                        </TableCell>
                                        <TableCell align='right'>
                                            <Typography className={classes.subtitle} variant="subtitle1" gutterBottom>
                                                <NumberFormat fixedDecimalScale="true" decimalScale="2" value={CalculateToPay(QuotationSelect, paymentsOptions.filter(active => active.id === PaymentOptionSelect)[0])} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} />
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                    <TabPanel value={numTab} index={0}>
                    </TabPanel>
                    <TabPanel value={numTab} index={1}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Indirizzo email per invio link pagamento"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={QuotationInfo.clientinfo.email !== undefined ? QuotationInfo.clientinfo.email :
                                QuotationInfo.clientinfo.contractorInfo.email !== undefined ? QuotationInfo.clientinfo.contractorInfo.email :
                                    Quotation.email !== undefined ? Quotation.email : ""}
                        //onChange={(e) => setEmail2Send(e.target.value)}
                        />
                    </TabPanel>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleCancel} color="primary">
                        Annulla
                    </Button>
                    <Button onClick={handleOk} color="primary">
                        Conferma
                    </Button>
                </DialogActions>
            </Dialog>
        }
    </>
    );
}
/*


<QuotationDetails factories={factories} company={Quote.company} datipre={QuotationSelect} saveActive={true} numberquotation={numberquotation}/>
*/

/*


const url=API_URL_QUOTATION_LIST;
    axios.put(url,{                                                  
        select:"",
    },
    {
        headers:{
          'Authorization': 'Bearer '+tokenApp,
          'Content-Type': 'application/json'
        }
    }
  ).then(payload=>{
      
      if(payload.status===200){
          setAlertMsg("Inserimento dati avvenuto con successo");
          setAlertSeverity("success");
          
          
      }else{
          setAlertMsg("Errore durante l'inserimento dati");
          setAlertSeverity("error");                                            
      }
      setOpen(true);
      
  }).catch((error)=>{
      
      setAlertMsg(error.response.data.error);
      setAlertSeverity("error");                                            
      setOpen(true);
  }) ;        



<DialogBase
                className={classes.dialog}
                title={singleData===undefined ? "" :  ("Preventivo nr. " + singleData.id)}
                isOpen={isDialogOpen}
                onClose={handleDialogClose}
                fullWidth={true}
                maxWidth="lg"
            >       
                {isDialogOpen &&
                <QuotationPDF row={singleData} qrcode={qrCodeImage}/>}
            </DialogBase>             

*/

/*
Emetti polizza


<Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confermi salvataggio polizza?"}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Premento il tasto conferma il tuo preventivo verrà trasformato in polizza.
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Annulla
                </Button>
                <Button onClick={handleConfirm} color="primary" autoFocus>
                    Conferma
                </Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
            <Alert onClose={handleCloseAlert} severity={alertseverity}>
              {alertmsg}
            </Alert>
          </Snackbar>      

<TableCell className={classes.cellPrice}>
                                                        
                                                        {(singleprice.company==="AMI" || singleprice.company==="CTE")  && Quote.company.type==="ws" &&
                                                        <Button variant="outlined" onClick={handleSave(singleprice,Quote.company)} size="small">Emetti polizza</Button>
                                                        }
                                                    </TableCell>

*/