import React,{useState} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Auth from '../auth/auth';
import {UserDataContext,id_cliente,ReturnInfoClient} from '../containers/logincontext';
import { useHistory } from "react-router-dom";
import {Helmet} from "react-helmet";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';



function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const useStyles = makeStyles((theme) => ({
    
  buttonProgress: {
    
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalpaper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit810: {
    margin: theme.spacing(3, 0, 2),
    background:'#428bca',    
  },
  submit: {
    margin: theme.spacing(3, 0, 2),    
  },
}));

export default function Login(pars) {
  const classes = useStyles();
  const [email,setEmail]=useState('');
  const [company,setCompany]=useState('');
  const [values,setValues]=useState({
    password: '',
    showPassword: false
  });
  const [,setUser] = React.useContext(UserDataContext);
  const [companies,setCompanies] = useState([]);
  const history = useHistory(); 
  
  
  const [open, setOpen] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);

  const [msgmodal,setMsgModal] = useState('');
  const [loading, setLoading] = React.useState(false);

  const handleChange = prop => event => {    
    setValues({ ...values, [prop]: event.target.value });
  };

  

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleOpen = (msg) => {
    setMsgModal(msg);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  
  
  

  const loginUser= (e) =>{
      
      e.preventDefault();      
      setLoading(true);    
      Auth.signin810(email,values.password).then(
        payload =>{                        
            setLoading(false);                                     
            setCompanies(payload);            
            setCompany(payload[0].code);            
            //history.push('/companies');        
            setOpenDialog(true);    
            
        }).catch(error=>{
            
            setLoading(false); 
            handleOpen(error);
            //alert(error);
           
        }

        );              
  }


  const loginUserCompany= (e) =>{
      
    e.preventDefault();      
    setLoading(true);    
    
    Auth.signincompany(email,values.password,company).then(
      payload =>{                                 
          
          setLoading(false);                                   
                    
          Auth.signinAppWith810(payload.user.login,payload.user.email,payload.company.code).then(payload =>{                                                                 
                setUser(payload.user);
                setLoading(false);                                   
                history.push('/home');        
            }).catch(error=>{          
                setLoading(false);                 
                handleOpen(error);                               
            });
          
          
          //setOpenDialog(true);    
          
      }).catch(error=>{
          
          setLoading(false); 
          handleOpen(error);
          //alert(error);
         
      }

      );              
}

  return (
    <Container component="main" maxWidth="xs">

      <Helmet>
        <title>{ReturnInfoClient(id_cliente,"nome_cliente")}</title>        
      </Helmet>
      <CssBaseline />
      <div className={classes.paper}>
        
        <div>                            
            <img src={ReturnInfoClient(id_cliente,"logo")} alt={ReturnInfoClient(id_cliente,"nome_cliente")}  className="siteLogo"/>                                        
        </div>
                                
        
        <form className={classes.form} noValidate onSubmit ={loginUser}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Nome utente"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange ={(e)=> setEmail(e.target.value)}
          />



          

        <FormControl className={classes.form} variant="outlined">
          <InputLabel htmlFor="password">Password</InputLabel>
          <OutlinedInput
            name="password"
            id="password"            
            
            type={values.showPassword ? 'text' : 'password'}
            value={values.password}
            onChange={handleChange('password')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            } labelWidth={70}
          />
        </FormControl>
          
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
            className={classes.submit810}
          >
            Login con 810
          </Button>
          {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          <Grid container>
            
            <Grid item xs={6}>
              <Link href="/login" variant="body2">
                {"Autenticati con email"}
              </Link>
            </Grid>
            
          </Grid>
          
          
        </form>
      </div>
      
      <Dialog open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
      <form onSubmit={loginUserCompany}>
        
        <DialogTitle id="form-dialog-title">Azienda</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Seleziona tra le aziende abilitate
          </DialogContentText>
          <FormControl variant="outlined" className={classes.form}>
        <InputLabel id="label-company">Azienda</InputLabel>

        {companies.length>0 ?
        <Select
          labelId="label-company"
          id="company"
          value={company}                    
          onChange ={(e)=> setCompany(e.target.value)}
          label="Azienda"
        >                         
                {companies.map(Item => (
                  <MenuItem key={Item.code} value={Item.code}>{Item.description}</MenuItem>
                ))}                              
        </Select>
        :<div></div>
        }
      </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Annulla
          </Button>
          
          <Button type="submit" color="primary">
            Continua
          </Button>
        </DialogActions>
        </form>
      </Dialog>
      
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
          {msgmodal}
          </Alert>
      </Snackbar>
      
    </Container>
  );

}
