import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BackGround from './background';
import Container from '@material-ui/core/Container';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';
import MaterialTable from 'material-table';
import { id_cliente, ReturnInfoClient } from '../containers/logincontext';
import { API_URL_QUOTATION_LIST } from '../config/config';
import { Fragment } from 'react';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DescriptionIcon from '@material-ui/icons/Description';
//import DialogBase from "./DialogBase.js";
import { NAME_APP } from '../config/config';
import { green, red } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useHistory } from "react-router-dom";
import Auth from '../auth/auth';
import Chip from '@material-ui/core/Chip';


const useStyles = makeStyles((theme) => ({

    BtnSearch: {
        marginBottom: 20,
        marginTop: 10,
    },
    alignFab: {
        marginLeft: 'auto',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    img: {
        maxWidth: 'auto',
        maxHeight: '40px',
        justifyContent: 'center'
    },
    dialog: {
        minWidth: 500
    },
    red: {
        color: red[500],
    },
    green: {
        color: green[500],

    },
}));

export default function QuotationList() {
    const [loading] = React.useState(false);
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [searchQuotation, setsearchQuotation] = React.useState("");

    let history = useHistory();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickGo = event => {

        if (searchQuotation !== "")
            history.push("/quotationsingle/" + searchQuotation);

        setOpen(false);
    };




    return (
        <main className={classes.content}>
            <BackGround open={loading} />
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" href="/" >
                        {NAME_APP}
                    </Link>
                    <Typography color="textPrimary">Preventivi</Typography>

                </Breadcrumbs>

                <Card>
                    <CardContent>
                        <Button onClick={handleClickOpen} className={classes.BtnSearch} variant="contained">Cerca per numero preventivo</Button>

                        <MaterialTable
                            title="Preventivi"
                            columns={[
                                {
                                    title: 'Numero',
                                    field: 'id',
                                    render: rowData => <Fragment>

                                        <Link color="inherit" href={"/quotationsingle/" + rowData.id} >
                                            {rowData.id}
                                            <IconButton color="primary" aria-label="Dettaglio preventivo" component="span">

                                                <DescriptionIcon />
                                            </IconButton>
                                        </Link>
                                    </Fragment>
                                },
                                {
                                    title: 'Data preventivo',
                                    field: 'created_at',
                                    render: rowData => <Fragment>
                                        {moment(rowData.created_at, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")}
                                    </Fragment>
                                },
                                {
                                    title: 'Stato',
                                    field: 'expired_date',
                                    render: rowData => <Fragment>

                                        {rowData.status === 1 &&
                                            <>
                                                <Chip color='primary' style={{ backgroundColor: 'green' }} label="Confermato" />
                                            </>
                                        }
                                        {rowData.status === 2 &&
                                            <>
                                                <Chip color='primary' style={{ backgroundColor: 'yellow', color: 'black' }} label="In attesa di pagamento" />
                                            </>
                                        }
                                        {rowData.status === 3 &&
                                            <>
                                                <Chip color='primary' style={{ backgroundColor: 'yellow', color: 'black' }} label="Premio da confermare" />
                                            </>
                                        }
                                        {rowData.status !== 1 && rowData.status !== 2 && rowData.status !== 3 &&
                                            <>
                                                {rowData.expired_date === null &&
                                                    <>


                                                        {moment(rowData.created_at, "YYYY-MM-DD HH:mm:ss").add(7, "days").isBefore(moment()) &&
                                                            <div className={classes.red}>
                                                                Scaduto il {moment(rowData.created_at, "YYYY-MM-DD HH:mm:ss").add(7, "days").format("DD/MM/YYYY")}
                                                            </div>}
                                                        {moment(rowData.created_at, "YYYY-MM-DD HH:mm:ss").add(7, "days").isSameOrAfter(moment()) &&
                                                            <div className={classes.green}>
                                                                Scade il {moment(rowData.created_at, "YYYY-MM-DD HH:mm:ss").add(7, "days").format("DD/MM/YYYY")}
                                                            </div>
                                                        }
                                                    </>
                                                }

                                                {rowData.expired_date !== null &&
                                                    <>
                                                        {moment(rowData.expired_date + " 23:59:59", "YYYY-MM-DD HH:mm:ss").isBefore(moment()) &&
                                                            <div className={classes.red}>
                                                                Scaduto il  {moment(rowData.expired_date + " 23:59:59", "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")}
                                                            </div>}
                                                        {moment(rowData.expired_date + " 23:59:59", "YYYY-MM-DD HH:mm:ss").isSameOrAfter(moment()) &&
                                                            <div className={classes.green}>
                                                                Scade il {moment(rowData.expired_date + " 23:59:59", "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")}
                                                            </div>
                                                        }
                                                    </>
                                                }
                                            </>
                                        }
                                    </Fragment>
                                },
                                {
                                    title: 'Organizzazione',
                                    field: 'orgdescr',
                                },
                                {
                                    title: 'Gruppo',
                                    field: 'grpdescr',
                                },
                                {
                                    title: 'Punto vendita',
                                    field: 'agdescr',
                                },
                                {
                                    title: 'Utente',
                                    field: 'id_user',
                                    render: rowData => <Fragment>
                                        {rowData.surname + " " + rowData.name}
                                    </Fragment>
                                },
                                {
                                    title: 'Cliente',
                                    field: 'user_detail',
                                    /*render: rowData =>
                                                  <Fragment>
                                                      {(rowData.client_company_contractor!=="" && rowData.client_company_contractor!==null) ?
                                                      rowData.client_company_contractor :
                                                      rowData.client_contractor_surname + " " + rowData.client_contractor_name}
                                              </Fragment>*/
                                },

                                {
                                    title: 'Settore',
                                    field: 'proddescr',
                                    /*render: rowData =><Fragment>
                                          {rowData.quotationtype==="house" ? "Casa": rowData.licencePlate}
                                    </Fragment>*/
                                },
                                {
                                    title: 'Bene',
                                    field: 'quotation_info',
                                    /*render: rowData =><Fragment>
                                          {rowData.quotationtype==="house" ? "Casa": rowData.licencePlate}
                                    </Fragment>*/
                                },
                                /*{ title: 'Cliente', 
                                  field: 'clientinfo', 
                                  render: rowData =>
                                  <Fragment>                                        
                                        {JSON.parse(rowData.clientinfo).clientinfo.contractorInfo!==undefined ?
                                        <Fragment>                                            
                                                {(JSON.parse(rowData.clientinfo).clientinfo.contractorInfo.subjectType==="1"
                                                || JSON.parse(rowData.clientinfo).clientinfo.contractorInfo.subjectType===undefined
                                                || JSON.parse(rowData.clientinfo).clientinfo.contractorInfo.subjectType==="")
                                                && 
                                                <Fragment>                                        
                                                {JSON.parse(rowData.clientinfo).clientinfo.contractorInfo.surname}{" "}
                                                {JSON.parse(rowData.clientinfo).clientinfo.contractorInfo.name}
                                                </Fragment>}
                                                {JSON.parse(rowData.clientinfo).clientinfo.contractorInfo.subjectType!=="1" && 
                                                 JSON.parse(rowData.clientinfo).clientinfo.contractorInfo.subjectType!=="" && 
                                                <Fragment>                                        
                                                {JSON.parse(rowData.clientinfo).clientinfo.contractorInfo.companyName}
                                                </Fragment>}
                                        </Fragment> :
                                        <Fragment>
                                                {(JSON.parse(rowData.clientinfo).clientinfo.subjectType==="1"
                                                || JSON.parse(rowData.clientinfo).clientinfo.subjectType===undefined)
                                                && 
                                                <Fragment>                                        
                                                {JSON.parse(rowData.clientinfo).clientinfo.surname}{" "}
                                                {JSON.parse(rowData.clientinfo).clientinfo.name}
                                                </Fragment>}
                                                {JSON.parse(rowData.clientinfo).clientinfo.subjectType!=="1" && 
                                                <Fragment>                                        
                                                {JSON.parse(rowData.clientinfo).clientinfo.companyName}
                                                </Fragment>}
                                        </Fragment>
                                        }
                                        
                                        
                                  </Fragment>
                                },
                                { title: 'Prodotto', 
                                  field: 'id', 
                                  render: rowData =><>
                                                        {JSON.parse(rowData.clientinfo).vehicleInfo!==undefined &&
                                                        <>
                                                        {JSON.parse(rowData.clientinfo).vehicleInfo.vehicleType}   
                                                        </>
                                                        }

                                                        {JSON.parse(rowData.clientinfo).quotationtype==="house" &&
                                                        <>
                                                        Casa
                                                        </>}
                                                        
                                                    </>
                                },
                                { 
                                 title: 'Bene', 
                                  field: 'id', 
                                  render: rowData =><Fragment>
                                                    {JSON.parse(rowData.clientinfo).vehicleInfo!==undefined &&
                                                        <>
                                                        {JSON.parse(rowData.clientinfo).vehicleInfo.licensePlate}
                                                        </>
                                                        }

                                                        {JSON.parse(rowData.clientinfo).quotationtype==="house" &&
                                                        <>
                                                        
                                                        </>}
                                        
                                  </Fragment>
                                }*/
                            ]}
                            localization={{
                                pagination: {
                                    labelDisplayedRows: '{from}-{to} di {count}',
                                    labelRowsSelect: 'per pagina',
                                    labelRowsPerPage: 'per pagina',
                                    firstAriaLabel: 'prima pagina',
                                    firstTooltip: 'prima pagina',
                                    previousAriaLabel: 'pagina precedente',
                                    previousTooltip: 'pagina precedente',
                                    nextAriaLabel: 'pagina successiva',
                                    nextTooltip: 'pagina successiva',
                                    lastAriaLabel: 'ultima pagina',
                                    lastTooltip: 'ultima pagina',
                                },
                                toolbar: {
                                    nRowsSelected: '{0} elementi selezionati',
                                    searchTooltip: 'Ricerca',
                                    searchPlaceholder: 'Ricerca'
                                },
                                header: {
                                    actions: ''
                                },
                                body: {
                                    emptyDataSourceMessage: 'Nessun record da visualizzare',
                                    filterRow: {
                                        filterTooltip: 'Filtri'
                                    },
                                    editRow: {
                                        saveTooltip: "Conferma",
                                        cancelTooltip: "Annulla",
                                        deleteText: "Sei sicuro di voler cancellare?"
                                    },
                                    addTooltip: "Aggiungi",
                                    deleteTooltip: "Cancella",
                                    editTooltip: "Modifica",
                                }
                            }}
                            options={{
                                pageSize: 10,
                                pageSizeOptions: [5, 10, 20, 50, 100],
                                paginationType: "stepped",
                                search: true,
                                headerStyle: {
                                    backgroundColor: ReturnInfoClient(id_cliente, "primary_color"),
                                    color: '#ffffff',
                                    '&:hover': {
                                        color: '#ffffff',
                                    },
                                    '&:active': {
                                        color: '#ffffff',
                                    }
                                }

                            }}
                            data={query =>
                                new Promise((resolve, reject) => {
                                    let url = API_URL_QUOTATION_LIST;
                                    url += '?per_page=' + query.pageSize
                                    url += '&page=' + (query.page + 1)

                                    if (query.search.length > 3) {
                                        url += '&search=' + query.search
                                    }
                                    if (query.orderBy !== undefined) {
                                        url += '&orderby=' + query.orderBy.field
                                        url += '&orderdirection=' + query.orderDirection
                                    }

                                    const requestOptions = {
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'Authorization': 'Bearer ' + Auth.getToken(),
                                        },
                                    };


                                    fetch(url, requestOptions)
                                        .then(
                                            response => response.json()
                                        )
                                        .then(
                                            result => {
                                                resolve({
                                                    data: result.data,
                                                    page: result.current_page - 1,
                                                    totalCount: result.total,
                                                })
                                            })
                                })
                            }

                        />

                    </CardContent>


                </Card>
            </Container>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Ricerca per numero preventivo</DialogTitle>
                <DialogContent>

                    <TextField
                        autoFocus
                        margin="dense"
                        id="searchQuotation"
                        label="Numero di preventivo"
                        fullWidth
                        value={searchQuotation}
                        onChange={(e) => setsearchQuotation(e.target.value)}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Annulla
                    </Button>
                    <Button onClick={handleClickGo} color="primary">
                        Cerca
                    </Button>
                </DialogActions>
            </Dialog>

        </main>
    );
};

