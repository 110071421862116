import axios from 'axios';
import {
    API_URL_LOGIN, API_URL_RECOVER, AUTH_URL, APP_TOKEN_810, AUTH_URL_TOKEN, AUTH_URL_CHECK_TOKEN, AUTH_URL_DESTROY_TOKEN, API_URL_REGISTER, API_URL_REFRESH,
    API_URL_REGISTER_WITH_AGENCY,
    API_URL_CHANGE_PASSWORD,
    API_URL_LOGIN_SSO
} from '../config/config';

function Auth() {

    const isTokenExpired = () => {
        const expires = +localStorage.getItem('app-token-expires');
        const res = (new Date()) > expires;
        if (res) {
            localStorage.removeItem('app-token-expires');
            localStorage.removeItem('authapp')
        }
        return res;
    };

    const TokenNeedRefresh = () => {
        const expires = +localStorage.getItem('app-token-expires');
        const res = expires - (new Date());
        const seconds = Math.trunc(res / 1000);
        if (seconds > 0 && seconds <= 30) {
            return true;
        }
        return false;
    };

    const handleError = (resp) => {
        let message = '';
        switch (+resp.status) {
            case 401:
                message = resp.data.error;
                break;
            case 500:
                message = resp.data.message;
                break;
            case 422:
                message = "Questa email è già stata utilizzata";
                break;
            default:
                message = 'Error contacting server';
        }
        return message;
    };

    const signincompany = async (email, password, companyCode) => {
        const json = JSON.stringify({
            user: email,
            password: password,
            applicationName: 'quotiamo',
            companyCode: companyCode,
            minutes: 30
        }
        );

        try {
            const result = await axios.post(AUTH_URL_TOKEN, json, {
                headers: {
                    'Token-Authorization': 'Basic ' + APP_TOKEN_810,
                    'Content-Type': 'application/json'
                }
            });
            return manageResult810(result, 2);

        } catch (e) {
            return Promise.reject(handleError(e.response));
        }
    };

    const signin810 = async (email, password) => {
        const json = JSON.stringify({
            user: email,
            password: password,
            applicationName: 'quotiamo',
            companyCode: ''
        }
        );
        try {
            const result = await axios.post(AUTH_URL, json, {
                headers: {
                    'Token-Authorization': 'Basic ' + APP_TOKEN_810,
                    'Content-Type': 'application/json'
                }
            });

            return manageResult810(result, 1);
        } catch (e) {
            return Promise.reject(handleError(e.response));
        }
    };

    const signin = async (email, password) => {
        try {
            const result = await axios.post(API_URL_LOGIN, {
                email,
                password
            });

            return manageResult(result);
        } catch (e) {
            return Promise.reject(handleError(e.response));
        }
    };

    const signinAppWith810 = async (username, email, org) => {
        try {
            const result = await axios.post(API_URL_LOGIN, {
                loginwith810: 1,
                user: username,
                email,
                org
            });

            return manageResult(result);
        } catch (e) {
            return Promise.reject(handleError(e.response));
        }
    };

    const verifytoken = async (remember_token) => {
        try {
            const json = JSON.stringify({
                token: remember_token,
                applicationName: 'quotiamo',
            }
            );

            const result = await axios.post(AUTH_URL_CHECK_TOKEN, json, {
                headers: {
                    'Token-Authorization': 'Basic ' + APP_TOKEN_810,
                    'Content-Type': 'application/json'
                }
            });

            return manageResult810(result, 2);
        } catch (e) {
            return Promise.reject(handleError(e.response));
        }
    };

    const getToken = () => {
        if (isTokenExpired()) {
            return null;
        }
        if (TokenNeedRefresh()) {
            refresh();
        }
        const auth = JSON.parse(localStorage.getItem('authapp'));
        if (auth) {
            return auth.token;
        }
        return null;
    }

    const getUser = () => {
        if (isTokenExpired()) {
            return null;
        }
        const auth = JSON.parse(localStorage.getItem('authapp'));

        if (auth) {
            return auth.user;
        }
        return null;
    }

    const getOrganizations = () => {
        if (isTokenExpired()) {
            return null;
        }
        const auth = JSON.parse(localStorage.getItem('authapp'));

        if (auth) {
            return auth.organizations;
        }
        return null;
    }

    /*const getServices = () =>{

        
        if(isTokenExpired()){                    
            return null;
        }
        const auth = JSON.parse(localStorage.getItem('authapp'));
                
        if(auth){            
            return auth.services;
        }
        return null;
    }*/


    function manageResult(result) {
        const data = result['data'];
        if (!data || !data['token']) {
            return Promise.reject('Invalid server response');
        }

        const expireDate = (new Date()).getTime() + data['expires_in'] * 1000;
        localStorage.setItem('app-token-expires', expireDate);
        localStorage.setItem('authapp', JSON.stringify(result.data));

        return result.data;
    }

    function manageResult810(result, typeres) {
        const data = result['data'];

        if (!data || !data['serviceInfo']['status']) {
            return Promise.reject('Invalid server response');
        }
        if (data['serviceInfo']['status'] !== 1) {
            return Promise.reject('Nome utente o password non valida');
        }

        if (typeres === 1) {
            if (data['result']['userCompanies']) {
                localStorage.setItem('userCompanies', JSON.stringify(data['result']['userCompanies']));
            }
            return data['result']['userCompanies'];
        }

        //if(typeres===2){            

        if (data['result']['application'] !== undefined) {
            localStorage.setItem('auth', JSON.stringify(data['result']['application']));
        }
        //}

        return data['result']['application'];
        /*
        const expireDate = (new Date()).getTime() + data['expires_in'] * 1000;
        localStorage.setItem('token-expires', expireDate);
        localStorage.setItem('auth', JSON.stringify(result.data));

        return result.data;*/
    }

    const signup = async (name, surname, email, password) => {
        try {
            const result = await axios.post(API_URL_REGISTER,
                {
                    name,
                    surname,
                    email,
                    password
                }
            );
            return result;
            //return manageResult(result);
        } catch (e) {
            return Promise.reject(handleError(e.response));
        }
    };

    const signupWithAgency = async (data) => {
        try {
            const result = await axios.post(API_URL_REGISTER_WITH_AGENCY,
                {
                    ...data
                }
            );
            return result;
            //return manageResult(result);
        } catch (e) {
            return Promise.reject(handleError(e.response));
        }
    };

    const recoverPassword = async (email) => {
        try {
            const result = await axios.post(API_URL_RECOVER,
                {
                    email
                }
            );
            return result;
            //return manageResult(result);
        } catch (e) {
            return Promise.reject(handleError(e.response));
        }
    };

    const logout = async () => {
        const token = getToken();

        try {
            const result = await axios.delete(AUTH_URL_DESTROY_TOKEN + "/" + token,
                {
                    headers: {
                        'Token-Authorization': 'Basic ' + APP_TOKEN_810,
                        'Content-Type': 'application/json'
                    }
                }
            );
            localStorage.removeItem('authapp');
            return result;
        }
        catch (e) {
            return Promise.reject(handleError(e.response));
        }
    };

    const changepassword = async (passwordNew, retypePassword) => {
        const auth = JSON.parse(localStorage.getItem('authapp'));
        if (!auth) {

            return false;
        }
        const token = auth.token;
        axios.defaults
            .headers
            .common['Authorization'] = 'Bearer ' + token;
        try {
            const result = await axios.post(API_URL_CHANGE_PASSWORD,
                {
                    passwordNew, retypePassword
                },
            );
            return result;
        } catch (e) {
            return Promise.reject(handleError(e.response));
        }
    };

    /*const addAxiosToken = () =>{
       const token = getToken();
  
       if(token){
  
         axios.defaults
         .headers
         .common['Authorization'] = 'Bearer ' + token;
       }
  
    }*/

    const refresh = async () => {
        //addAxiosToken();
        const auth = JSON.parse(localStorage.getItem('authapp'));
        if (!auth) {

            return false;
        }
        const token = auth.token;
        axios.defaults
            .headers
            .common['Authorization'] = 'Bearer ' + token;

        try {
            const result = await axios.post(API_URL_REFRESH);
            return manageResult(result);
        } catch (e) {
            return Promise.reject(handleError(e.response));
        }
    };

    const verifyTokenSSO = async (token_temp) => {
        try {
            const json = {
                tokenTemp: token_temp,
                applicationName: 'quotiamo',
            };

            const result = await axios.post(API_URL_LOGIN_SSO, json);

            return manageResult(result);
        } catch (e) {
            return Promise.reject(handleError(e.response));
        }
    };

    return {
        getUser,
        getOrganizations,
        //getServices,
        signin,
        signin810,
        signup,
        signupWithAgency,
        recoverPassword,
        logout,
        refresh,
        isTokenExpired,
        verifytoken,
        getToken,
        signincompany,
        signinAppWith810,
        changepassword,
        verifyTokenSSO
    }
}
const authMethods = Auth();
export default authMethods;