import React from 'react';
import {makeStyles } from '@material-ui/core/styles';
import BackGround from './background';
import Container from '@material-ui/core/Container';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';
import { green, red } from '@material-ui/core/colors';
import {NAME_APP} from '../config/config';
import Typography from '@material-ui/core/Typography';
 
  
  
const useStyles = makeStyles((theme) => ({

    
    alignFab: {
        marginLeft: 'auto',
      },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),      
    },
    img: {    
        display: 'block',
        maxWidth: '100%',
        maxHeight: '40px',
    },
    red: {
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
    },
    green: {
        color: '#fff',
        backgroundColor: green[500],
    },
    contentPermission: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      },

}));



export default function Permissions() {
    const [loading] = React.useState(false);
    const classes = useStyles();
    

    return (
        <main className={classes.content}>            

            <BackGround open={loading}/>     
            <div className={classes.appBarSpacer} />            
            
            <Container maxWidth="lg" className={classes.container}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" href="/" >
                        {NAME_APP}
                    </Link>                    
                    
                    <Typography color="textPrimary">Permessi</Typography>    
                </Breadcrumbs>
                
                <Card>
                    <CardContent className={classes.contentPermission}>
                        

                        
                    </CardContent>        
                                        
                </Card>          
          </Container>                      
        </main>
      );
};