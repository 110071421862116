import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {UserDataContext} from '../containers/logincontext';
import { parse } from 'query-string';

export default function PrivateRoute(props){    
    const [user] = React.useContext(UserDataContext);            
    const query = parse(props.location.search);


    
    if(query.token!== undefined){        
        return <Redirect to = {
            {pathname: "/confermalogin/"+query.token
            }
        } />;
    }
    
    if(user){           
        
        if(props.accesslevel===undefined  || user.level >=props.accesslevel){
            return (
                <Route {...props}/>
            )
        }else{
            
            return <Redirect to = {
                {pathname: "/"
                }
    
            } />                                
            
        }
        
        
    }else{        
        return <Redirect to = {
            {pathname: "/login"
            }

        } />                            
    }
    
}