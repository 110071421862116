import React from 'react';
import SaveIcon from '@material-ui/icons/Save';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { API_URL_DISCOUNTS, API_URL_CONVENTIONS, elencoCondizioniCar, elencoCondizioniHouse } from '../config/config';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Auth from '../auth/auth';
import TableHead from '@material-ui/core/TableHead';





function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}




function ReturnListCondition(ActualCondition, elencoCondizioni) {
  let arrayCondition = [];
  elencoCondizioni.map((item) => {
    let old = "";
    ActualCondition.forEach((element, index) => {

      if (element[item.value] !== undefined) {
        old = element[item.value].value;
      }
    });

    arrayCondition.push({
      [item.value]: {
        "value": old
      }
    });

    return false;
  });
  return arrayCondition;
}

/*function getNodeIds(nodes) {
    let ids = [];
  
    if(nodes!==undefined){
      nodes.forEach(({ value, children }) => {
        ids = [...ids, value, ...getNodeIds(children)];
      });
    }
    
  
    return ids;
  }*/

export default function EditConditionsParameter(props) {
  //const classes = useStyles();    


  const elencoCondizioni = props.datatype === "car" || props.datatype === "van" ? elencoCondizioniCar : elencoCondizioniHouse;
  const [data, setData] = React.useState(ReturnListCondition(props.data.convention_rules === null ? [] : JSON.parse(props.data.convention_rules), elencoCondizioni));
  const [open, setOpen] = React.useState(false);
  const [alertseverity, setAlertSeverity] = React.useState("success");
  const [alertmsg, setAlertMsg] = React.useState(false);






  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };












  const handleSave = () => {
    new Promise((resolve, reject) => {
      const index = props.data.id;

      let url =
        props.type === 1 ?
          API_URL_DISCOUNTS + "/" + index :
          API_URL_CONVENTIONS + "/" + index
        ;





      axios.patch(url, {

        ...props.data,
        convention_rules: JSON.stringify(data),
        updateonly: "convention_rules"
      },
        {
          headers: {
            'Authorization': 'Bearer ' + Auth.getToken(),
            'Content-Type': 'application/json'
          }
        }
      ).then(payload => {
        if (payload.status === 200) {
          setAlertMsg("Aggiornamento avvenuto con successo");
          setAlertSeverity("success");
        } else {
          setAlertMsg("Errore durante l'aggiornamento");
          setAlertSeverity("error");
        }
        setOpen(true);
      }).catch((error) => {
        setAlertMsg(error.response.data.error);
        setAlertSeverity("error");
        setOpen(true);
      });

      resolve();

    })
  }
  const handleChangeValue = (bitValue, checkName) => (event) => {
    let NewData = data;
    let keysearch = Object.keys(checkName)[0];
    let index = -1;
    let ObjInsert;


    NewData.forEach((item, key) => {

      if (Object.keys(item)[0] === keysearch) {

        index = key;


        ObjInsert = {
          [keysearch]: {
            "value": event.target.value
          }
        };

      }

    })


    setData([
      ...NewData.slice(0, index),
      ObjInsert,
      ...NewData.slice(index + 1)
    ]
    );

  };

  //
  return (
    <Card  >
      <CardContent >




        <TableContainer >
          <Table aria-label="CVT">


            <TableHead>
              <TableRow>
                <TableCell>Condizioni</TableCell>
                <TableCell>Parametri</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item, key) => (
                <TableRow>

                  <TableCell style={{ width: "30%" }}>
                    {elencoCondizioni[key].label}
                  </TableCell>


                  <TableCell style={{ width: "70%" }}>


                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="value"
                      label="Parametri"
                      name="value"
                      autoFocus
                      value={Object.values(item)[0].value}
                      onChange={handleChangeValue(1, item)}
                    />
                  </TableCell>


                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>




        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={alertseverity}>
            {alertmsg}
          </Alert>
        </Snackbar>
      </CardContent>
      <CardActions>
        <Button onClick={handleSave} startIcon={<SaveIcon />} size="small">Salva</Button>
      </CardActions>
    </Card>

  );
};

/*
            <Typography className={classes.title} color="textSecondary" gutterBottom>
            Selezionando l'organizzazione si applica a tutte le reti, selezionado la rete si aspplica a tutti i punti vendita
            </Typography>                                              

            <CheckboxTree
                  nodes={datatree}
                  checked={checked}
                  expanded={expanded}
                  onCheck={checked => setChecked(checked)}
                  onExpand={expanded => setExpanded(expanded)}
                  showCheckbox={true}
                  noCascade={true}
                  showExpandAll={true}
                  
                  icons={{
                    check: <FontAwesomeIcon className="rct-icon rct-icon-check" icon={faCheckSquare }  />,
                    uncheck: <FontAwesomeIcon className="rct-icon rct-icon-uncheck" icon={faSquare} />,
                    halfCheck: <FontAwesomeIcon className="rct-icon rct-icon-half-check" icon={faCheckSquare} />,
                    expandClose: <FontAwesomeIcon className="rct-icon rct-icon-expand-close" icon={faChevronRight} />,
                    expandOpen: <FontAwesomeIcon className="rct-icon rct-icon-expand-open" icon={faChevronDown} />,
                    expandAll: <FontAwesomeIcon className="rct-icon rct-icon-expand-all" icon={faPlusSquare} />,
                    collapseAll: <FontAwesomeIcon className="rct-icon rct-icon-collapse-all" icon={faMinusSquare} />,
                    parentClose: <FontAwesomeIcon className="rct-icon rct-icon-parent-close" icon={faFolder} />,
                    parentOpen: <FontAwesomeIcon className="rct-icon rct-icon-parent-open" icon={faFolderOpen} />,
                    leaf: <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faFile} />
                }}
                />          

*/