import React from 'react'
import Container from '@material-ui/core/Container';
import { useLocation } from 'react-router';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import { NAME_APP } from '../config/config';
import Typography from '@material-ui/core/Typography';
import MuiAlert from '@material-ui/lab/Alert';
import {
    API_URL_ALLGOODS, API_URL_ALLPRODUCTS, SUPER_ADMIN, ADMIN_TEST,
    API_URL_LIST_SERVICES_AGENCY_USER,
} from '../config/config';
import axios from 'axios';
import Auth from '../auth/auth';
import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import SvgColor from 'react-svg-color';
import { useHistory } from "react-router-dom";
import Header from './header';
import Snackbar from '@material-ui/core/Snackbar';

const useStyles = makeStyles((theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    labelBig: {
        marginTop: 10,
        marginBottom: 20,
        fontSize: 20,
        fontWeight: "bold",
        textTransform: "none"
    },
    imgContainerBig: {
        margin: "50px",
        textAlign: 'center',
        marginBottom: "20px"
    },
    label: {
        marginTop: 10,
        fontSize: 12,
        fontWeight: "bold",
        textTransform: "none",
        marginBottom: "10px"
    },
    imgContainer: {
        margin: "20px",
        marginBottom: "5px",
        textAlign: 'center'
    },
    firstSelection: {
        marginTop: "50px",
        marginBottom: "50px",
        cursor: "pointer",
    },
    secondSelection: {
        cursor: "pointer",
    }
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function HomePageOnlyProd() {
    const userData = Auth.getUser();
    const classes = useStyles();
    const history = useHistory();
    const [products, setProducts] = React.useState([]);
    const location = useLocation();
    const [alertseverity, setAlertSeverity] = React.useState("success");
    const [alertmsg, setAlertMsg] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        // da rimuovere dopo che sistemano D&N da 810
        if (location.search !== "") {
            window.location.href = "/car" + location.search;
        }
        const url = API_URL_ALLPRODUCTS;
        axios.get(url,
            {
                headers: {
                    'Authorization': 'Bearer ' + Auth.getToken(),
                    'Content-Type': 'application/json'
                }
            }
        ).then(payload => {
            setProducts(payload.data.products);
        });
    }, []);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleClickProducts = paramurl => event => {
        if (paramurl) {
            checkCompanyProduct(paramurl).then(
                payload => {
                    let companyListPrev = payload.data.services;
                    if (companyListPrev.length < 1) {
                        setAlertMsg(<div>Prodotto non attivo per questo punto vendita.<br />Si consiglia di contattare il proprio account manager per l'eventuale attivazione</div>);
                        setAlertSeverity("error");
                        setOpen(true);
                        return;
                    } else {
                        history.push('/' + paramurl);
                    }
                }
            ).catch(error => {
                setAlertMsg(<div>Prodotto non attivo per questo punto vendita.<br />Si consiglia di contattare il proprio account manager per l'eventuale attivazione</div>);
                setAlertSeverity("error");
                setOpen(true);
                return;
            });
        }
    }

    const checkCompanyProduct = async (paramurl) => {
        try {
            const result = await axios.get(API_URL_LIST_SERVICES_AGENCY_USER + "/" + paramurl, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + Auth.getToken(),
                }
            });
            return result;
        } catch (e) {
            return Promise.reject(e.response);
        }
    };

    return (
        <>
            {/*userData.id_template === null &&
                <>*/}
            <Header />
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit" href="/" >
                            {NAME_APP}
                        </Link>
                        <Typography color="textPrimary">
                            Seleziona il prodotto
                        </Typography>
                    </Breadcrumbs>

                    {products.length > 0 &&
                        <Grid className={classes.firstSelection} container spacing={0}>
                            {products.map((product, index) => (
                                <>
                                    {product.enabled === 1 && (product.paramurl !== "rcp" && product.paramurl !== "gf") &&
                                        <Grid key={"PD_" + index} item xs={2}>
                                            <Box borderRadius={16} className={classes.root} m={1} border={3} borderColor={product.enabled === 1 ? "#666666" : "#DDDDDD"} onClick={handleClickProducts(product.enabled === 1 ? product.paramurl : false)}>
                                                <Box className={classes.imgContainer}>
                                                    {product.enabled === 1 ?
                                                        <SvgColor
                                                            svg={product.image}
                                                            width={60}
                                                            colors={["#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666"]}
                                                        /> :
                                                        <SvgColor
                                                            svg={product.image}
                                                            width={60}
                                                            colors={["#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD"]}
                                                        />
                                                    }
                                                </Box>
                                                <Typography style={{ color: product.enabled === 1 ? "#666666" : "#DDDDDD" }}
                                                    className={classes.label} variant="button" display="block" gutterBottom align="center">
                                                    {product.description}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    }
                                    {product.enabled === 1 && (product.paramurl === "rcp" || product.paramurl === "gf") && userData.level >= ADMIN_TEST &&
                                        <Grid key={"PD_" + index} item xs={2}>
                                            <Box borderRadius={16} className={classes.root} m={1} border={3} borderColor={product.enabled === 1 ? "#666666" : "#DDDDDD"} onClick={handleClickProducts(product.enabled === 1 ? product.paramurl : false)}>
                                                <Box className={classes.imgContainer}>
                                                    {product.enabled === 1 ?
                                                        <SvgColor
                                                            svg={product.image}
                                                            width={60}
                                                            colors={["#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666"]}
                                                        /> :
                                                        <SvgColor
                                                            svg={product.image}
                                                            width={60}
                                                            colors={["#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD"]}
                                                        />
                                                    }
                                                </Box>
                                                <Typography style={{ color: product.enabled === 1 ? "#666666" : "#DDDDDD" }}
                                                    className={classes.label} variant="button" display="block" gutterBottom align="center">
                                                    {product.description}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    }
                                </>
                            ))}
                        </Grid>
                    }

                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                        <Alert onClose={handleClose} severity={alertseverity}>
                            {alertmsg}
                        </Alert>
                    </Snackbar>
                </Container>
            </main>
        </>
    );
}