import React from 'react';
import SaveIcon from '@material-ui/icons/Save';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Auth from '../auth/auth';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import { API_URL_CONVENTIONS_RULES } from '../config/config';
import moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';
import { Typography } from '@material-ui/core';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function EditConventionsData(props) {
  const [open, setOpen] = React.useState(false);
  const [alertseverity, setAlertSeverity] = React.useState("success");
  const [alertmsg, setAlertMsg] = React.useState(false);
  const [conventions, setConventions] = React.useState([]);
  const [isSave, setIsSave] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleChange = (id) => (event) => {
    let newArray = [];
    conventions.forEach((item, index) => {
      if (item.id === id) {
        item.checked = event.target.checked;
      }
      newArray.push(item);
    }
    );
    setConventions(newArray);
  }

  const handleSave = () => {
    let list_conventions = [];
    conventions.forEach((item) => {
      if (item.checked) {
        list_conventions.push(item.id);
      }
    });
    new Promise((resolve, reject) => {
      const url = API_URL_CONVENTIONS_RULES + "/" + props.orgid + "/" + props.type + "/" + props.id;

      axios.patch(url, {
        list: list_conventions.join(),
      },
        {
          headers: {
            'Authorization': 'Bearer ' + Auth.getToken(),
            'Content-Type': 'application/json'
          }
        }
      ).then(payload => {
        if (payload.status === 200) {
          setAlertMsg("Aggiornamento avvenuto con successo");
          setAlertSeverity("success");
          setIsSave(!isSave);
        } else {
          setAlertMsg("Errore durante l'aggiornamento");
          setAlertSeverity("error");
        }
        setOpen(true);
      }).catch((error) => {
        setAlertMsg(error.response.data.error);
        setAlertSeverity("error");
        setOpen(true);
      });
      resolve();
    })
  }

  React.useEffect(() => {
    const url = API_URL_CONVENTIONS_RULES + "/" + props.orgid + "/" + props.type + "/" + props.id;
    axios.get(url,
      {
        headers: {
          'Authorization': 'Bearer ' + Auth.getToken(),
          'Content-Type': 'application/json'
        }
      }
    ).then(payload => {
      payload.data.discounts.forEach((item) => {
        if (payload.data.discountActive.filter(active => active.id_convention === item.id).length > 0) {
          item.checked = true;
        } else {
          item.checked = false;
        }

        if (props.type === "usr") {
          if (item.checked === false) {
            if (payload.data.discountConvApp.filter(convApp => convApp.id_convention === item.id && convApp.id_agency === props.idag && convApp.id_user === null).length > 0) {
              item.active_from = "Punto vendita";
            } else if (payload.data.discountConvApp.filter(convApp => convApp.id_convention === item.id && convApp.id_group === props.idgrp && convApp.id_agency === null && convApp.id_user === null).length > 0) {
              item.active_from = "Gruppo";
            } else if (payload.data.discountConvApp.filter(convApp => convApp.id_convention === item.id && convApp.id_organization === props.orgid && convApp.id_group === null && convApp.id_agency === null && convApp.id_user === null).length > 0) {
              item.active_from = "Organizzazione";
            }
          } else {
            item.active_from = "";
          }
        } else if (props.type === "ag") {
          if (item.checked === false) {
            if (payload.data.discountConvApp.filter(convApp => convApp.id_convention === item.id && convApp.id_group === props.idgrp && convApp.id_agency === null && convApp.id_user === null).length > 0) {
              item.active_from = "Gruppo";
            } else if (payload.data.discountConvApp.filter(convApp => convApp.id_convention === item.id && convApp.id_organization === props.orgid && convApp.id_group === null && convApp.id_agency === null && convApp.id_user === null).length > 0) {
              item.active_from = "Organizzazione";
            }
          } else {
            item.active_from = "";
          }
        } else if (props.type === "grp") {
          if (item.checked === false) {
            if (payload.data.discountConvApp.filter(convApp => convApp.id_convention === item.id && convApp.id_organization === props.orgid && convApp.id_group === null && convApp.id_agency === null && convApp.id_user === null).length > 0) {
              item.active_from = "Organizzazione";
            }
          } else {
            item.active_from = "";
          }
        } else {
          item.active_from = "";
        }
      })

      setConventions(payload.data.discounts);
    });
  }, [props, isSave]);

  return (
    <Card style={{ width: "100%" }}>
      <CardContent>
        {conventions.length === 0 &&
          <Typography>Nessuna convenzione disponibile per questa organizzazione</Typography>
        }
        {conventions.length > 0 &&
          <TableContainer>
            <Table >
              <TableHead>
                <TableRow>
                  <TableCell>Attivo</TableCell>
                  <TableCell>Attivo su</TableCell>
                  <TableCell>Descrizione</TableCell>
                  <TableCell>Fabbrica</TableCell>
                  <TableCell>Dal</TableCell>
                  <TableCell>Al</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {conventions.map((item, key) => (
                  <TableRow key={"ROW_" + item.id}>
                    <TableCell>
                      <Checkbox onChange={handleChange(item.id)} checked={item.checked} />
                    </TableCell>
                    <TableCell>
                      {item.active_from}
                    </TableCell>
                    <TableCell>
                      {item.description}
                    </TableCell>
                    <TableCell>
                      {item.factory_description}
                    </TableCell>
                    <TableCell>
                      {moment(item.valid_from, "YYYY-MM-DD").format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell>
                      {moment(item.valid_to, "YYYY-MM-DD").format("DD/MM/YYYY")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        }
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={alertseverity}>
            {alertmsg}
          </Alert>
        </Snackbar>
      </CardContent>
      {conventions.length > 0 &&
        <CardActions>
          <Button onClick={handleSave} startIcon={<SaveIcon />} size="small">Salva</Button>
        </CardActions>
      }
    </Card>
  );
};

