import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { green, red } from '@material-ui/core/colors';
import { API_URL_SERVICES_LIST } from '../config/config';
import axios from 'axios';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import moment from 'moment';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Grid } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Auth from '../auth/auth';
import SharedFactories from './sharedFactories';
import MultiSession from './MultiSession';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    alignFab: {
        marginLeft: 'auto',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    img: {
        display: 'block',
        maxWidth: '100%',
        maxHeight: '40px',
    },

    img_service: {
        display: 'block',
        maxWidth: '40px',
        maxHeight: '20px',
    },
    red: {
        color: red[500],
    },
    green: {
        color: green[500],
    },
    hiddenDiv: {
        display: 'none'
    },
    cardClass: {
        marginTop: '10px',
        marginBottom: '10px',
        marginLeft: '10px',
        marginRight: '10px'
    }
}));


export default function CompaniesParametersDetailPanel(props) {
    const classes = useStyles();
    const [parameter, setParameter] = React.useState(props.dataPanel);
    const [openMultisession, setOpenMultisession] = React.useState(props.dataPanel.multisession === 1 ? true : false);

    const handleChange = event => {
        setParameter({
            ...parameter,
            [event.target.name]: event.target.value
        });
    };

    const handleChangeSwitch = (event) => {
        setParameter({ ...parameter, [event.target.name]: event.target.checked });
    };

    const handleChangeSwitchMultiSession = (event) => {
        setParameter({ ...parameter, [event.target.name]: event.target.checked });
        setOpenMultisession(event.target.checked);
    };

    const [open, setOpen] = React.useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleSave = () => {
        new Promise((resolve, reject) => {
            const url = API_URL_SERVICES_LIST + "/" + props.dataPanel.id;
            axios.patch(url, {
                id_factory: parameter.id_factory,
                id_organization: props.org_id,
                description: parameter.description,
                enabled: parameter.enabled,
                username: parameter.username,
                password: parameter.password,
                companyCode: parameter.companyCode,
                companyParams: parameter.companyParams,
                nodeCode: parameter.nodeCode,
                sharetoken: parameter.sharetoken,
                multisession: parameter.multisession,
            },
                {
                    headers: {
                        'Authorization': 'Bearer ' + Auth.getToken(),
                        'Content-Type': 'application/json'
                    }
                }
            ).then(payload => {
                //window.location.reload();
                setOpen(true);
            });
            resolve();
        })
    }

    //
    return (
        <ValidatorForm onSubmit={handleSave} onError={errors => console.log(errors)}>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Card className={classes.cardClass}>
                        <CardHeader title="Parametri collegamento compagnia" />
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={<Switch checked={parameter.enabled} onChange={handleChangeSwitch} name="enabled" />}
                                        label={parameter.enabled ? "Abilitato" : "Disabilitato"}
                                    />
                                    {parameter.type !== "share" &&
                                        <FormControlLabel
                                            control={<Switch checked={parameter.multisession} onChange={handleChangeSwitchMultiSession} name="multisession" />}
                                            label={parameter.multisession ? "Multisessione abilitata" : "Multisessione disabilitata"}
                                        />
                                    }
                                </Grid>
                            </Grid>

                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextValidator
                                        fullWidth
                                        variant="outlined"
                                        label="Descrizione"
                                        onChange={handleChange}
                                        name="description"
                                        value={parameter.description}
                                    />
                                </Grid>
                            </Grid>
                            {parameter.type !== "share" &&
                                <>
                                    {openMultisession === false &&
                                        <>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <TextValidator
                                                        fullWidth
                                                        variant="outlined"
                                                        label="Nome utente"
                                                        onChange={handleChange}
                                                        name="username"
                                                        value={parameter.username}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <TextValidator
                                                        fullWidth
                                                        variant="outlined"
                                                        label="Password/Secret"
                                                        onChange={handleChange}
                                                        name="password"
                                                        value={parameter.password}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <TextValidator
                                                        fullWidth
                                                        variant="outlined"
                                                        label="Codice"
                                                        onChange={handleChange}
                                                        name="companyCode"
                                                        value={parameter.companyCode}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <TextValidator
                                                        fullWidth
                                                        variant="outlined"
                                                        label="Parametri"
                                                        onChange={handleChange}
                                                        name="companyParams"
                                                        value={parameter.companyParams}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <TextValidator
                                                        fullWidth
                                                        variant="outlined"
                                                        label="Codice Nodo"
                                                        onChange={handleChange}
                                                        name="nodeCode"
                                                        value={parameter.nodeCode}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </>
                                    }
                                    {openMultisession === true &&
                                        <>
                                            <br />
                                            <MultiSession id={props.dataPanel.id} />
                                        </>
                                    }
                                </>
                            }
                            {parameter.type === "share" &&
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <TextValidator
                                            fullWidth
                                            variant="outlined"
                                            label="Share Token"
                                            onChange={handleChange}
                                            name="sharetoken"
                                            value={parameter.sharetoken}
                                        />
                                    </Grid>
                                </Grid>}
                        </CardContent>
                        <CardActions>
                            <Button type="sumbit" startIcon={<SaveIcon />} size="small">Salva</Button>
                        </CardActions>
                    </Card>
                    {openMultisession === false &&
                        <Card className={classes.cardClass}>
                            <CardContent>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                    Stato connessione
                                </Typography>
                                <Typography variant="h5" component="h2" className={parameter.status === 1 ? classes.green : classes.red}>
                                    {parameter.status === 1 ? "Attiva" : "Errore"}
                                </Typography>
                                <Typography className={classes.pos} color="textSecondary">
                                    Ultimo aggiornamento
                                </Typography>
                                <Typography variant="body2" component="p">
                                    {moment(parameter.lastcheck, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")} {moment(parameter.lastcheck, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss")}
                                    <br />
                                    {parameter.status !== 1 && "Tentativi: " + parameter.attempts}
                                </Typography>
                            </CardContent>
                        </Card>
                    }
                </Grid>
                <Grid item xs={6}>
                    {parameter.type !== "share" &&
                        <>
                            <Card className={classes.cardClass}>
                                <CardContent>
                                    <SharedFactories id={props.dataPanel.id} />
                                </CardContent>
                            </Card>
                        </>
                    }
                </Grid>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success" closeText="Chiudi">
                        Parametri salvati correttamente
                    </Alert>
                </Snackbar>
            </Grid>
        </ValidatorForm>
    );
};