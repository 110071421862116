import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MaterialTable from 'material-table';
import {id_cliente,ReturnInfoClient} from '../containers/logincontext';
import {API_URL_GROUPS} from '../config/config';
import AgencyGroupTable from './agenciesGroupTable';
import Auth from '../auth/auth';

export default function AgenciesTables(props) {
    
    
    

        
    return (<Card>
                    <CardContent>
                    <MaterialTable 
                        title={props.descr + " > Gruppi"}
                        columns={[                                                                          
                            { 
                                title: 'Gruppo', 
                                field: 'description' 
                            }
                        ]}

                        detailPanel={[
                            {
                              tooltip: 'Seleziona punto vendita',
                              render: rowData => {
                                return (<AgencyGroupTable groupdata={rowData} orgid={props.org_id}/>)
                              },
                            },
                          ]
                          }

                        localization={{
                            pagination: {
                                labelDisplayedRows: '{from}-{to} di {count}',
                                labelRowsSelect: 'per pagina',
                                labelRowsPerPage : 'per pagina',
                                firstAriaLabel: 'prima pagina',
                                firstTooltip : 'prima pagina',
                                previousAriaLabel: 'pagina precedente',
                                previousTooltip: 'pagina precedente',
                                nextAriaLabel: 'pagina successiva',
                                nextTooltip: 'pagina successiva',
                                lastAriaLabel: 'ultima pagina',
                                lastTooltip: 'ultima pagina',
                            },
                            toolbar: {  
                                nRowsSelected: '{0} elementi selezionati',
                                searchTooltip: 'Ricerca',
                                searchPlaceholder : 'Ricerca'
                            },                            
                            header: {
                                actions: ''
                            },
                            body: {
                                emptyDataSourceMessage: 'Nessun record da visualizzare',
                                filterRow: {
                                    filterTooltip: 'Filtri'
                                },
                                editRow:{
                                    saveTooltip: "Conferma",
                                    cancelTooltip: "Annulla",
                                    deleteText: "Sei sicuro di voler cancellare questo gruppo?"
                                },
                                addTooltip: "Aggiungi",
                                deleteTooltip: "Cancella",
                                editTooltip: "Modifica",
                            }                          
                        }}
                        options={{
                            pageSize: 10,
                            pageSizeOptions: [5,10],
                            paginationType: "stepped",
                            search: true,
                            headerStyle: {
                            backgroundColor: ReturnInfoClient(id_cliente,"primary_color"),
                            color:  '#ffffff',    
                                    '&:hover': {
                                        color: '#ffffff',
                                    },
                                    '&:active': {
                                        color: '#ffffff',
                                    }          
                            }
                        
                        }}
                        data={query =>
                                new Promise((resolve, reject) => {            
                                    let url = API_URL_GROUPS+"/"+props.org_id;
                                        url += '?per_page=' + query.pageSize
                                        url += '&page=' + (query.page + 1)

                                    if(query.search.length>3){
                                        url += '&search=' + query.search
                                    }            
                                    if(query.orderBy!==undefined){                                    
                                        url += '&orderby=' + query.orderBy.field
                                        url += '&orderdirection=' + query.orderDirection                                    
                                    }
            
                                    const requestOptions = {                                    
                                        headers: { 
                                            'Content-Type': 'application/json',
                                            'Authorization': 'Bearer '+Auth.getToken(),                
                                        },              
                                    };


                                    fetch(url,requestOptions)            
                                    .then(                  
                                        response => response.json()
                                    )
                                    .then(                
                                        result => {                                      
                                        resolve({
                                        data: result.data,                  
                                        page: result.current_page-1,
                                        totalCount: result.total,
                                        })
                                    })
                                })
                            }
                            
                        />

                    </CardContent>        
                    
                    
                </Card>                    
      );
};