import React from 'react';
import Auth from '../auth/auth';
import Header from './header';
import QuotationTcm from './quotationTcm';
import QuotationTcmFacile from './QuotationTcmFacile';

export default function QuotationHouseRedirect(props) {
    const userData = Auth.getUser();

    return (
        <>
            {userData.id_template === null &&
                <>
                    <Header />
                    <QuotationTcm />
                </>
            }
            {userData.id_template !== null && userData.id_template === 1 && userData.id_collaboratore_sso !== null && userData.id_user_sso !== null &&
                <QuotationTcmFacile />
            }
        </>
    );
}