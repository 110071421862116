import React from 'react'
import Container from '@material-ui/core/Container';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import { NAME_APP } from '../config/config';
import Typography from '@material-ui/core/Typography';
import { API_URL_ALLGOODS, API_URL_ALLPRODUCTS } from '../config/config';
import axios from 'axios';
import Auth from '../auth/auth';
import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import SvgColor from 'react-svg-color';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    labelBig: {
        marginTop: 10,
        marginBottom: 20,
        fontSize: 20,
        fontWeight: "bold",
        textTransform: "none"
    },
    imgContainerBig: {

        margin: "50px",
        textAlign: 'center',
        marginBottom: "20px"
    },
    label: {
        marginTop: 10,
        fontSize: 12,
        fontWeight: "bold",
        textTransform: "none",
        marginBottom: "10px"
    },
    imgContainer: {
        margin: "20px",
        marginBottom: "5px",
        textAlign: 'center'
    },
    firstSelection: {
        marginTop: "50px",
        marginBottom: "50px",
        cursor: "pointer",
    },
    secondSelection: {
        cursor: "pointer",
    }
}));

export default function HomePage() {
    const classes = useStyles();
    const history = useHistory();
    const [goods, setGoods] = React.useState([]);
    const [selectgood, setSelectGood] = React.useState(false);
    const [products, setProducts] = React.useState([]);
    const [waiting, setWaiting] = React.useState(true);

    React.useEffect(() => {
        const url = API_URL_ALLGOODS;
        axios.get(url,
            {
                headers: {
                    'Authorization': 'Bearer ' + Auth.getToken(),
                    'Content-Type': 'application/json'
                }
            }
        ).then(payload => {
            setGoods(payload.data.goods);
        });
    }, []);

    React.useEffect(() => {
        if (selectgood) {
            setWaiting(true);
            const url = API_URL_ALLPRODUCTS + "/" + selectgood;
            axios.get(url,
                {
                    headers: {
                        'Authorization': 'Bearer ' + Auth.getToken(),
                        'Content-Type': 'application/json'
                    }
                }
            ).then(payload => {
                setProducts(payload.data.products);
                setWaiting(false);
            });
        }
    }, [selectgood]);

    const handleClick = id => event => {
        if (id) {
            if (event) {
                setSelectGood(id);
            } else {
                setSelectGood(false);
            }
        }
    }

    const handleClickProducts = paramurl => event => {
        if (paramurl) {
            history.push('/' + paramurl);
        }
    }

    return (
        <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" href="/" >
                        {NAME_APP}
                    </Link>
                    <Typography color="textPrimary">
                        {selectgood ? goods.filter(data => selectgood === data.id)[0].description : "Seleziona tipologia di Bene"}
                    </Typography>
                </Breadcrumbs>
                {goods.length > 0 &&
                    <Grid className={classes.firstSelection} container spacing={0}>
                        {goods.map((good, index) => (
                            <>
                                <Grid key={"GD_" + index} item xs={4}>
                                    <Box borderRadius={16} className={classes.root} m={1} border={selectgood === good.id ? 3 : 3} borderColor={selectgood === good.id ? "#f9b406" : (good.enabled === 1 ? "#666666" : "#DDDDDD")} onClick={handleClick(good.enabled === 1 ? good.id : false)}>
                                        <Box className={classes.imgContainerBig}>
                                            <SvgColor
                                                svg={good.image}
                                                width={100}
                                                colors={selectgood === good.id ?
                                                    ["#f9b406", "#f9b406", "#f9b406", "#f9b406", "#f9b406", "#f9b406", "#f9b406", "#f9b406", "#f9b406", "#f9b406", "#f9b406", "#f9b406", "#f9b406", "#f9b406", "#f9b406", "#f9b406", "#f9b406", "#f9b406", "#f9b406", "#f9b406"] :
                                                    (good.enabled === 1 ?
                                                        ["#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666"] :
                                                        ["#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD"])
                                                }
                                            />
                                        </Box>
                                        <Typography
                                            style={{ color: good.enabled === 1 ? (selectgood === good.id ? "#f9b406" : "#666666") : "#DDDDDD" }}
                                            className={classes.labelBig} variant="button" display="block" gutterBottom align="center">
                                            {good.description}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </>
                        ))}
                    </Grid>
                }

                {waiting === false && products.length > 0 &&
                    <Grid className={classes.secondSelection} container spacing={0}>
                        {products.map((product, index) => (
                            <Grid key={"PD_" + index} item xs={2}>
                                <Box borderRadius={16} className={classes.root} m={1} border={3} borderColor={product.enabled === 1 ? "#666666" : "#DDDDDD"} onClick={handleClickProducts(product.enabled === 1 ? product.paramurl : false)}>
                                    <Box className={classes.imgContainer}>
                                        {product.enabled === 1 ?
                                            <SvgColor
                                                svg={product.image}
                                                width={60}
                                                colors={["#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666", "#666666"]}
                                            /> :
                                            <SvgColor
                                                svg={product.image}
                                                width={60}
                                                colors={["#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD", "#DDDDDD"]}
                                            />
                                        }
                                    </Box>
                                    <Typography style={{ color: product.enabled === 1 ? "#666666" : "#DDDDDD" }}
                                        className={classes.label} variant="button" display="block" gutterBottom align="center">
                                        {product.description}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                }
            </Container>
        </main>
    );
}