import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";



import OutlinedInput from "@material-ui/core/OutlinedInput";
import { makeStyles } from "@material-ui/core/styles";

import Auth from "../auth/auth";


import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from '@material-ui/lab/Alert';
import { Typography } from "@material-ui/core";


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  modalpaper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },

  paper: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorBox: {
    minWidth: "24px",
  },
  dialog: {
    minWidth: "600px",
  },
}));

export default function ChangePswDialog(props) {
  const { openPopup, setOpenPopup } = props;
  const [open, setOpen] = useState(false);
  const [msgmodal, setMsgModal] = useState("");
  const [title, setTitle] = useState("");
  const [errorFlag, setErrorFlag] = useState(false);

  const classes = useStyles();

  const [values, setValues] = useState({    
    passwordNew: "",
    showPassword: false,
    retypePassword: "",
  });

  

  const handleClickChangePassword = (e) => {
    Auth.changepassword( values.passwordNew, values.retypePassword)
      .then((payload) => {
        setOpenPopup(false);
        setErrorFlag(false);
        setValues({ ...values, passwordNew: "", retypePassword: "" });       
        handleOpen("success", "Password modificata con successo");
      })
      .catch((error) => {
        setErrorFlag(true);
        handleOpen("error", error);
      });
  };

  const handleOpen = (msgTitle, msg) => {
    setMsgModal(msg);
    setOpen(true);
    setTitle(msgTitle);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const onChangePasswordNew = (e) => {
    setValues({ ...values, passwordNew: e.target.value });
  };

  const onChangePasswordNewRetype = (e) => {
    setValues({ ...values, retypePassword: e.target.value });
  };
  
  

  return (
    <div>
      <Dialog
        classes={{ paper: classes.dialog }}
        open={openPopup}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <CssBaseline />
          <div className={classes.paper}>
            

            <form className={classes.form} noValidate>

            
              <FormControl className={classes.form} variant="outlined">
                <InputLabel
                  variant="outlined"
                  htmlFor="filled-adornment-password"
                >
                  Nuova password
                </InputLabel>

                <OutlinedInput
                  autoComplete="false"
                  name="passwordNew"
                  labelWidth={165}
                  value={values.passwordNew}
                  id="passwordNew"
                  onChange={onChangePasswordNew}
                  type={values.showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {values.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }                  
                  
                  error={errorFlag ? true : false}
                />
                
              </FormControl>

              <FormControl className={classes.form} variant="outlined">
                <InputLabel
                  variant="outlined"
                  htmlFor="filled-adornment-password"
                >
                  Ridigita la password
                </InputLabel>

                <OutlinedInput
                  autoComplete="false"
                  name="retypePassword"
                  placeholder="Ridigita la nuova password"
                  type={values.showPassword ? "text" : "password"}
                  value={values.retypePassword}
                  onChange={onChangePasswordNewRetype}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {values.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={165}
                  error={errorFlag ? true : false}
                />
                
              </FormControl>
              {errorFlag && <Typography>{msgmodal}</Typography>}
              <Button
                onClick={handleClickChangePassword}
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Modifica password
              </Button>
            </form>
          </div>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => setOpenPopup(false)}
            color="primary"
            variant="outlined"
          >
            Annulla
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar  autoHideDuration={6000} open={open} onClose={handleClose}>
        <Alert severity={title}>
          {msgmodal}
        </Alert>
      </Snackbar>
    </div>
  );
}
