import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import TextField from '@material-ui/core/TextField';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';
import axios from 'axios';
import Auth from '../auth/auth';
import AuthService from '../auth/authservice';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import NumberFormat from 'react-number-format';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from 'moment';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import Carousel from 'react-material-ui-carousel'
import { CardMedia } from '@material-ui/core';
import { getProfessions, returnElement, parseFloat2Decimals } from './utils'
import { PosDataContext } from '../containers/logincontext';
import BackGround from './background';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import validator from 'validator';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Card from '@material-ui/core/Card';
import {
  APP_TOKEN_QUOTIAMO, API_URL_SERVICE_SICANIA, API_URL_SERVICE_DATA_SICANIA,
  API_URL_QUOTATION, API_URL_LIST_SERVICES_AGENCY_USER, API_URL_SICANIA_LIST, API_URL_LIST_AGENCIES_USER,
  API_URL_QUOTATION_LIST,
  API_URL_GET_BRAND,
  API_URL_GET_MODEL,
  API_URL_GET_OUTFITTING,
  API_URL_GET_INFOCITYBYCITY,
  API_URL_INFOIMAGES_ALL,
  API_URL_FACTORIES_LIST,
  API_URL_GET_INFOBIRTH,
  API_URL_ALLGUARANTEES,
  API_URL_MULTISESSION
} from '../config/config';
import { DAYS_BEFORE_QUOTATION } from '../config/config';
import QuotationDetails from './quotationdetails';
import { red } from '@material-ui/core/colors';
import AssistenzaStradaleSVG from '../svg/assistenza_stradale.svg';
import CollisioneSVG from '../svg/collisione.svg';
import CristalliSVG from '../svg/cristalli.svg';
import EventiNaturaliSVG from '../svg/eventi_naturali.svg';
import FurtoIncendioSVG from '../svg/furto_incendio.svg';
import InfortunioConducenteSVG from '../svg/infortuni_conducente.svg';
import KaskoSVG from '../svg/kasko.svg';
import TutelaLegaleSVG from '../svg/tutela_legale.svg';
import AttiVandaliciSVG from '../svg/vandalism.svg';
import RivalsaSVG from '../svg/rivalsa.svg';
import CheckSvgButton from './checkboxsvg';
import { NAME_APP } from '../config/config';
import { kmAnnuiOption, tipoGuida, statoCivile, livelliIstruzione, tipoAlimentazione, etaGuidatorePiuGiovane, ElencoMassimali, tipoFrazionamento, elencoProvinceConvenzione } from '../config/config';
import LinearProgress from '@material-ui/core/LinearProgress';
import queryString from 'query-string'
import { REGISTRY_URL } from '../config/config';
import { useLocation } from 'react-router';
import QuotationSingleDetail from './quotationsingledetail';
import SubjectDetails from './subjectDetails';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Checkbox from '@material-ui/core/Checkbox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { number_format } from './utils';

const blobToBase64 = blob => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

const getInfoBirth = async (token, url, cf, nome, cognome) => {
  try {
    const result = await axios.get(url + API_URL_GET_INFOBIRTH, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      params: {
        cf: cf,
        nome: nome,
        cognome: cognome
      }
    });
    return result;

  } catch (e) {
    return Promise.reject(e.response);
  }
};

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
/*
<option value="86">AMISSIMA ASSICURAZIONI</option>
<option value="151">GROUPAMA ASSICURAZIONI</option>
<option value="5">GENERALI ITALIA</option>
<option value="474">NOBIS COMPAGNIA DI ASSICURAZIONI S.p.A.</option>
<option value="429">TUA ASSICURAZIONI</option>
<option value="82">UNIPOL ASSICURAZIONI S.p.A.</option>						
<option value="34">UNIPOLSAI ASSICURAZIONI S.p.A.</option>
Linear = 416
*/

function ReturnInfoSinistriAnno(datiania, anno, quale) {
  switch (anno) {
    case 0:
      switch (quale) {
        case 0: return datiania.dataATRC.atrcRespPrincAnno0;
        case 1: return datiania.dataATRC.atrcRespPrincDanniCoseAnno0;
        case 2: return datiania.dataATRC.atrcRespPrincDanniPersAnno0;
        case 3: return datiania.dataATRC.atrcRespPrincDanniMistiAnno0;
        case 4: return datiania.dataATRC.atrcRespPariAnno0;
        case 5: return datiania.dataATRC.atrcRespPariDanniCoseAnno0;
        case 6: return datiania.dataATRC.atrcRespPariDanniPersAnno0;
        case 7: return datiania.dataATRC.atrcRespPariDanniMistiAnno0;
        default: return null;
      }

    case 1:
      switch (quale) {
        case 0: return datiania.dataATRC.atrcRespPrincAnno1;
        case 1: return datiania.dataATRC.atrcRespPrincDanniCoseAnno1;
        case 2: return datiania.dataATRC.atrcRespPrincDanniPersAnno1;
        case 3: return datiania.dataATRC.atrcRespPrincDanniMistiAnno1;
        case 4: return datiania.dataATRC.atrcRespPariAnno1;
        case 5: return datiania.dataATRC.atrcRespPariDanniCoseAnno1;
        case 6: return datiania.dataATRC.atrcRespPariDanniPersAnno1;
        case 7: return datiania.dataATRC.atrcRespPariDanniMistiAnno1;
        default: return null;
      }

    case 2:
      switch (quale) {
        case 0: return datiania.dataATRC.atrcRespPrincAnno2;
        case 1: return datiania.dataATRC.atrcRespPrincDanniCoseAnno2;
        case 2: return datiania.dataATRC.atrcRespPrincDanniPersAnno2;
        case 3: return datiania.dataATRC.atrcRespPrincDanniMistiAnno2;
        case 4: return datiania.dataATRC.atrcRespPariAnno2;
        case 5: return datiania.dataATRC.atrcRespPariDanniCoseAnno2;
        case 6: return datiania.dataATRC.atrcRespPariDanniPersAnno2;
        case 7: return datiania.dataATRC.atrcRespPariDanniMistiAnno2;
        default: return null;
      }


    case 3:
      switch (quale) {
        case 0: return datiania.dataATRC.atrcRespPrincAnno3;
        case 1: return datiania.dataATRC.atrcRespPrincDanniCoseAnno3;
        case 2: return datiania.dataATRC.atrcRespPrincDanniPersAnno3;
        case 3: return datiania.dataATRC.atrcRespPrincDanniMistiAnno3;
        case 4: return datiania.dataATRC.atrcRespPariAnno3;
        case 5: return datiania.dataATRC.atrcRespPariDanniCoseAnno3;
        case 6: return datiania.dataATRC.atrcRespPariDanniPersAnno3;
        case 7: return datiania.dataATRC.atrcRespPariDanniMistiAnno3;
        default: return null;
      }

    case 4:
      switch (quale) {
        case 0: return datiania.dataATRC.atrcRespPrincAnno4;
        case 1: return datiania.dataATRC.atrcRespPrincDanniCoseAnno4;
        case 2: return datiania.dataATRC.atrcRespPrincDanniPersAnno4;
        case 3: return datiania.dataATRC.atrcRespPrincDanniMistiAnno4;
        case 4: return datiania.dataATRC.atrcRespPariAnno4;
        case 5: return datiania.dataATRC.atrcRespPariDanniCoseAnno4;
        case 6: return datiania.dataATRC.atrcRespPariDanniPersAnno4;
        case 7: return datiania.dataATRC.atrcRespPariDanniMistiAnno4;
        default: return null;
      }

    case 5:
      switch (quale) {
        case 0: return datiania.dataATRC.atrcRespPrincAnno5;
        case 1: return datiania.dataATRC.atrcRespPrincDanniCoseAnno5;
        case 2: return datiania.dataATRC.atrcRespPrincDanniPersAnno5;
        case 3: return datiania.dataATRC.atrcRespPrincDanniMistiAnno5;
        case 4: return datiania.dataATRC.atrcRespPariAnno5;
        case 5: return datiania.dataATRC.atrcRespPariDanniCoseAnno5;
        case 6: return datiania.dataATRC.atrcRespPariDanniPersAnno5;
        case 7: return datiania.dataATRC.atrcRespPariDanniMistiAnno5;
        default: return null;
      }

    case 6:
      switch (quale) {
        case 0: return datiania.dataATRC.atrcRespPrincAnno6;
        case 1: return datiania.dataATRC.atrcRespPrincDanniCoseAnno6;
        case 2: return datiania.dataATRC.atrcRespPrincDanniPersAnno6;
        case 3: return datiania.dataATRC.atrcRespPrincDanniMistiAnno6;
        case 4: return datiania.dataATRC.atrcRespPariAnno6;
        case 5: return datiania.dataATRC.atrcRespPariDanniCoseAnno6;
        case 6: return datiania.dataATRC.atrcRespPariDanniPersAnno6;
        case 7: return datiania.dataATRC.atrcRespPariDanniMistiAnno6;
        default: return null;
      }

    case 7:
      switch (quale) {
        case 0: return datiania.dataATRC.atrcRespPrincAnno7;
        case 1: return datiania.dataATRC.atrcRespPrincDanniCoseAnno7;
        case 2: return datiania.dataATRC.atrcRespPrincDanniPersAnno7;
        case 3: return datiania.dataATRC.atrcRespPrincDanniMistiAnno7;
        case 4: return datiania.dataATRC.atrcRespPariAnno7;
        case 5: return datiania.dataATRC.atrcRespPariDanniCoseAnno7;
        case 6: return datiania.dataATRC.atrcRespPariDanniPersAnno7;
        case 7: return datiania.dataATRC.atrcRespPariDanniMistiAnno7;
        default: return null;
      }

    case 8:
      switch (quale) {
        case 0: return datiania.dataATRC.atrcRespPrincAnno8;
        case 1: return datiania.dataATRC.atrcRespPrincDanniCoseAnno8;
        case 2: return datiania.dataATRC.atrcRespPrincDanniPersAnno8;
        case 3: return datiania.dataATRC.atrcRespPrincDanniMistiAnno8;
        case 4: return datiania.dataATRC.atrcRespPariAnno8;
        case 5: return datiania.dataATRC.atrcRespPariDanniCoseAnno8;
        case 6: return datiania.dataATRC.atrcRespPariDanniPersAnno8;
        case 7: return datiania.dataATRC.atrcRespPariDanniMistiAnno8;
        default: return null;
      }

    case 9:
      switch (quale) {
        case 0: return datiania.dataATRC.atrcRespPrincAnno9;
        case 1: return datiania.dataATRC.atrcRespPrincDanniCoseAnno9;
        case 2: return datiania.dataATRC.atrcRespPrincDanniPersAnno9;
        case 3: return datiania.dataATRC.atrcRespPrincDanniMistiAnno9;
        case 4: return datiania.dataATRC.atrcRespPariAnno9;
        case 5: return datiania.dataATRC.atrcRespPariDanniCoseAnno9;
        case 6: return datiania.dataATRC.atrcRespPariDanniPersAnno9;
        case 7: return datiania.dataATRC.atrcRespPariDanniMistiAnno9;
        default: return null;
      }

    case 10:
      switch (quale) {
        case 0: return datiania.dataATRC.atrcRespPrincAnno10;
        case 1: return datiania.dataATRC.atrcRespPrincDanniCoseAnno10;
        case 2: return datiania.dataATRC.atrcRespPrincDanniPersAnno10;
        case 3: return datiania.dataATRC.atrcRespPrincDanniMistiAnno10;
        case 4: return datiania.dataATRC.atrcRespPariAnno10;
        case 5: return datiania.dataATRC.atrcRespPariDanniCoseAnno10;
        case 6: return datiania.dataATRC.atrcRespPariDanniPersAnno10;
        case 7: return datiania.dataATRC.atrcRespPariDanniMistiAnno10;
        default: return null;
      }
    default: return null;
  }
}

function ReturnDefaultinsuranceYears(posinfo, datiania, drivingLicenceYear) {
  let defaultret = "";
  var RespPrinc = [];

  for (let i = 10; i >= 0; i--) {
    if (datiania.dataATRC !== undefined && datiania.dataATRC !== null) {

      switch (i) {
        case 0:
          RespPrinc.push(datiania.dataATRC.atrcRespPrincAnno0);
          break;
        case 1:
          RespPrinc.push(datiania.dataATRC.atrcRespPrincAnno1);
          break;
        case 2:
          RespPrinc.push(datiania.dataATRC.atrcRespPrincAnno2);
          break;
        case 3:
          RespPrinc.push(datiania.dataATRC.atrcRespPrincAnno3);
          break;
        case 4:
          RespPrinc.push(datiania.dataATRC.atrcRespPrincAnno4);
          break;
        case 5:
          RespPrinc.push(datiania.dataATRC.atrcRespPrincAnno5);
          break;
        case 6:
          RespPrinc.push(datiania.dataATRC.atrcRespPrincAnno6);
          break;
        case 7:
          RespPrinc.push(datiania.dataATRC.atrcRespPrincAnno7);
          break;
        case 8:
          RespPrinc.push(datiania.dataATRC.atrcRespPrincAnno8);
          break;
        case 9:
          RespPrinc.push(datiania.dataATRC.atrcRespPrincAnno9);
          break;
        case 10:
          RespPrinc.push(datiania.dataATRC.atrcRespPrincAnno10);
          break;
        default:
          break;
      }
    }
  }
  if (posinfo.defAg !== null && JSON.parse(posinfo.defAg).insuranceYears !== undefined ? true : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).insuranceYears !== undefined ? true : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).insuranceYears !== undefined ? true : false))) {


    //Calcola in base all'attestato di rischio
    if ((posinfo.defAg !== null && JSON.parse(posinfo.defAg).insuranceYears !== undefined ? JSON.parse(posinfo.defAg).insuranceYears : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).insuranceYears !== undefined ? JSON.parse(posinfo.defGrp).insuranceYears : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).insuranceYears !== undefined ? JSON.parse(posinfo.defOrg).insuranceYears : "0"))) === "1") {
      let calcola = 10;
      let trovato = false;
      RespPrinc.forEach((item, index) => {
        if (trovato === false) {
          if (item !== "NA" && item !== "--") {
            calcola = index;
            trovato = true;
          }
        }
      });
      defaultret = 10 - calcola;
    }
    //Calcola in base alla data di conseguimento della patente


    if ((posinfo.defAg !== null && JSON.parse(posinfo.defAg).insuranceYears !== undefined ? JSON.parse(posinfo.defAg).insuranceYears : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).insuranceYears !== undefined ? JSON.parse(posinfo.defGrp).insuranceYears : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).insuranceYears !== undefined ? JSON.parse(posinfo.defOrg).insuranceYears : "0"))) === "2") {
      var secondDate = new Date().getFullYear();
      if (datiania.contractorInfo.subjectType === "" || datiania.contractorInfo.subjectType === "1") {
        if (drivingLicenceYear !== "") {
          defaultret = secondDate - drivingLicenceYear;
        }

      }
    }
  }

  return defaultret;
}

function CheckSinistriAnno(datiania, anno) {
  if (ReturnInfoSinistriAnno(datiania, anno, 0) !== "NA" &&
    ReturnInfoSinistriAnno(datiania, anno, 0) !== "ND" &&
    ReturnInfoSinistriAnno(datiania, anno, 0) !== "--" &&
    Number(ReturnInfoSinistriAnno(datiania, anno, 0)) === 0 &&
    ReturnInfoSinistriAnno(datiania, anno, 4) !== "NA" &&
    ReturnInfoSinistriAnno(datiania, anno, 4) !== "ND" &&
    ReturnInfoSinistriAnno(datiania, anno, 0) !== "--" &&
    Number(ReturnInfoSinistriAnno(datiania, anno, 4)) === 0
  ) {
    return true;
  }
  return false;
}

//ReturnInfoSinistriAnno

function CheckAnniValorizzati(datiania) {
  let ultimoAnno = 0;
  for (let i = 0; i <= 10; i++) {
    if (ReturnInfoSinistriAnno(datiania, i, 0) !== "NA" && ReturnInfoSinistriAnno(datiania, i, 0) !== "ND" && ReturnInfoSinistriAnno(datiania, i, 0) !== "--") {
      ultimoAnno = i + 1;
    } else {
      break;
    }
  }
  return ultimoAnno;
}

function CheckValidATRC(datiania) {
  if (datiania.dataATRC === undefined) return false;
  //ATRC Deve essere dell'anno corrente
  if (datiania.dataATRC.anno0Atrc >= moment().format("YYYY")) {
    return true;
  } else {
    return false;
  }
}

function CheckATRC(datiania, sinistriCheck, numeroAnniCheck) {
  //Secondo controllo verifica numero di sinistri
  let numerosinistri = 0;
  for (let i = 0; i < numeroAnniCheck; i++) {
    if (ReturnInfoSinistriAnno(datiania, i, 0) !== "NA" && ReturnInfoSinistriAnno(datiania, i, 0) !== "ND" && ReturnInfoSinistriAnno(datiania, i, 0) !== "--") {
      numerosinistri += Number(ReturnInfoSinistriAnno(datiania, i, 0));
    }
    if (ReturnInfoSinistriAnno(datiania, i, 4) !== "NA" && ReturnInfoSinistriAnno(datiania, i, 4) !== "ND" && ReturnInfoSinistriAnno(datiania, i, 4) !== "--") {
      numerosinistri += Number(ReturnInfoSinistriAnno(datiania, i, 4));
    }
  }
  if (numerosinistri > sinistriCheck) return false;
}

function CheckMinATRC(datiania, sinistriCheck, numeroAnniCheck) {
  //Secondo controllo verifica numero di sinistri
  let numerosinistri = 0;
  for (let i = 0; i < numeroAnniCheck; i++) {
    if (ReturnInfoSinistriAnno(datiania, i, 0) !== "NA" && ReturnInfoSinistriAnno(datiania, i, 0) !== "ND" && ReturnInfoSinistriAnno(datiania, i, 0) !== "--") {
      numerosinistri += Number(ReturnInfoSinistriAnno(datiania, i, 0));
    }
    if (ReturnInfoSinistriAnno(datiania, i, 4) !== "NA" && ReturnInfoSinistriAnno(datiania, i, 4) !== "ND" && ReturnInfoSinistriAnno(datiania, i, 4) !== "--") {
      numerosinistri += Number(ReturnInfoSinistriAnno(datiania, i, 4));
    }
  }
  if (numerosinistri < sinistriCheck) return false;
}

function CheckActiveConvention(conventions, datiania, tipoprev) {

  let convention_accept = true;
  let checkAnniValorizzati = false;
  let checkAnniSinistri = false;
  let MaxNumeroSinistri = false;
  let MinNumeroSinistri = false;

  if (conventions.convention_rules !== null) {
    JSON.parse(conventions.convention_rules).forEach(
      (single_rules) => {
        if (single_rules.AtrValue !== undefined) {
          if (single_rules.AtrValue.value !== null && single_rules.AtrValue.value !== "") {
            //let checkAnniValorizzati=1;
            if (!isNaN(single_rules.AtrValue.value)) {
              checkAnniValorizzati = Number(single_rules.AtrValue.value);
              if (checkAnniValorizzati > 10) {
                checkAnniValorizzati = 10;
              }
              if (checkAnniValorizzati <= 0) {
                checkAnniValorizzati = 1;
              }
            }

            if (!CheckValidATRC(datiania) || CheckAnniValorizzati(datiania) < checkAnniValorizzati) {
              convention_accept = false;
            }

          }
        }
        if (single_rules.AtrCheckNumber !== undefined) {
          if (single_rules.AtrCheckNumber.value !== null && single_rules.AtrCheckNumber.value !== "") {
            MaxNumeroSinistri = Number(single_rules.AtrCheckNumber.value);
          }
        }
        if (single_rules.AtrCheckNumberMin !== undefined) {
          if (single_rules.AtrCheckNumberMin.value !== null && single_rules.AtrCheckNumberMin.value !== "") {
            MinNumeroSinistri = Number(single_rules.AtrCheckNumberMin.value);
          }
        }
        if (single_rules.AtrCheck !== undefined) {
          if (single_rules.AtrCheck.value !== null && single_rules.AtrCheck.value !== "") {
            checkAnniSinistri = Number(single_rules.AtrCheck.value);
            if (checkAnniSinistri > 10) {
              checkAnniSinistri = 11;
            }
            if (checkAnniSinistri <= 0) {
              checkAnniSinistri = 1;
            }
          }
        }
        if (single_rules.ProvinceList !== undefined) {

          if (single_rules.ProvinceList.value !== null && single_rules.ProvinceList.value !== "") {
            //non trovate le province
            let elencoProvince = single_rules.ProvinceList.value.split(",");

            //elencoProvinceConvenzione
            if (elencoProvince.indexOf(datiania.contractorInfo.residenceInfo.provinceAbbr) === -1) {
              convention_accept = false;
            }

          }
        }
        if (single_rules.BirthNation !== undefined) {
          if (single_rules.BirthNation.value !== null && single_rules.BirthNation.value !== "") {
            //Verifico se nato in paese straniero
            if (!isNaN(single_rules.BirthNation.value)) {
              var res = datiania.contractorInfo.fiscalCode.substring(11, 12).toUpperCase();
              if (parseInt(single_rules.BirthNation.value) === 1) {
                if (res === "Z") {
                  convention_accept = false;
                }
              } else if (parseInt(single_rules.BirthNation.value) === 2) {
                if (res !== "Z") {
                  convention_accept = false;
                }
              }
            }
          }
        }
        if (single_rules.PaymentMode !== undefined) {
          if (single_rules.PaymentMode.value !== null && single_rules.PaymentMode.value !== "") {
            if (Number(datiania.splittingUp) !== Number(single_rules.PaymentMode.value)) {
              convention_accept = false;
            }
          }
        }
        if (single_rules.PrevType !== undefined) {
          if (single_rules.PrevType.value !== null && single_rules.PrevType.value !== "") {
            if (tipoprev !== single_rules.PrevType.value) {
              convention_accept = false;
            }
          }
        }
        if (single_rules.anniVeicolo !== undefined) {
          if (single_rules.anniVeicolo.value !== null && single_rules.anniVeicolo.value !== "") {

            //Verifico il veicolo supera il limite vetustà
            var annoReg = moment(datiania.registrationDate);
            var annoOggi = moment();
            if (Number(annoOggi.diff(annoReg, 'years', true)) > Number(single_rules.anniVeicolo.value)) {
              convention_accept = false;
            }
          }
        }
      });
    if (CheckValidATRC(datiania) && MaxNumeroSinistri !== false && checkAnniSinistri !== false) {
      if (CheckATRC(datiania, MaxNumeroSinistri, checkAnniSinistri) === false) {
        convention_accept = false;
      }
    }
    if (CheckValidATRC(datiania) && MinNumeroSinistri !== false && checkAnniSinistri !== false) {
      if (CheckMinATRC(datiania, MinNumeroSinistri, checkAnniSinistri) === false) {
        convention_accept = false;
      }
    }
  }


  return convention_accept;
}

function CheckConvenzioni(datiania, company, tipoprev, conventionslist) {
  let convention = {
    code: "",
    description: "",
    flex: 0
  };

  let percmax = 0;
  let convTrovata = false;
  let convComp = false;
  /* if (tipoprev !== "RIN") {
    return convention;
  } */

  /*if (company.paramurl === "groupama") {
    if (datiania.insuranceCompany === undefined) {
      return convention;
    }

    if (datiania.insuranceCompany.indexOf("GROUPAMA") === -1) {
      if (datiania.dataATRC !== undefined) {
        let numerosinistri = 0;
        for (let i = 0; i <= 9; i++) {
          if (ReturnInfoSinistriAnno(datiania, i, 0) !== "NA" && ReturnInfoSinistriAnno(datiania, i, 0) !== "ND" && ReturnInfoSinistriAnno(datiania, i, 0) !== "--") {
            numerosinistri += Number(ReturnInfoSinistriAnno(datiania, i, 0));
          }
          if (ReturnInfoSinistriAnno(datiania, i, 4) !== "NA" && ReturnInfoSinistriAnno(datiania, i, 4) !== "ND" && ReturnInfoSinistriAnno(datiania, i, 4) !== "--") {
            numerosinistri += Number(ReturnInfoSinistriAnno(datiania, i, 4));
          }
        }
        if (numerosinistri > 1) return convention;
      } else {
        return convention;
      }
    }
  }*/

  conventionslist.forEach(conventions => {
    if (company.facid === conventions.id_factory) {
      let convention_accept = CheckActiveConvention(conventions, datiania, tipoprev);
      if (convention_accept) {
        let value_flex = 0;
        if (conventions.discounts_rules !== null) {
          JSON.parse(conventions.discounts_rules).forEach(
            single_discount_rules => {
              let selectCVT = "rca";
              if (single_discount_rules.hasOwnProperty(selectCVT)) {
                value_flex = single_discount_rules[selectCVT].perc;
              }
            }
          );
        }
        if (value_flex >= percmax) {
          convention.code = conventions.code;
          convention.description = conventions.description;
          convention.flex = value_flex;
          percmax = value_flex;
          convTrovata = true;
          if (conventions.companyapply === 1) {
            convComp = true;
          } else {
            convComp = false;
          }
        }
      }
    }
  });

  if (convComp === false) {
    convention = {
      code: "",
      description: "",
      flex: 0
    };
    return convention;
  }

  if (convTrovata) {
    return convention;
  }

  /*if (company.paramurl !== "groupama") {
    return convention;
  }

  if (datiania.insuranceCompany === undefined) {
    return convention;
  }

  if (datiania.insuranceCompany.indexOf("GROUPAMA") === -1) {
    if (datiania.dataATRC !== undefined) {
      let numerosinistri = 0;
      for (let i = 0; i <= 9; i++) {
        if (ReturnInfoSinistriAnno(datiania, i, 0) !== "NA" && ReturnInfoSinistriAnno(datiania, i, 0) !== "ND" && ReturnInfoSinistriAnno(datiania, i, 0) !== "--") {
          numerosinistri += Number(ReturnInfoSinistriAnno(datiania, i, 0));
        }
        if (ReturnInfoSinistriAnno(datiania, i, 4) !== "NA" && ReturnInfoSinistriAnno(datiania, i, 4) !== "ND" && ReturnInfoSinistriAnno(datiania, i, 4) !== "--") {
          numerosinistri += Number(ReturnInfoSinistriAnno(datiania, i, 4));
        }
      }
      if (numerosinistri > 1) return convention;

      let condition1 = true;
      for (let i = 0; i <= 5; i++) {
        if (CheckSinistriAnno(datiania, i) === false) {
          condition1 = false;
        }
      }

      if (condition1 && datiania.dataATRC.anno0Atrc >= moment().format("YYYY")) {
        /*if (company.groupid === 1 || company.groupid === 13) {  //"Rete diretta" + "expertas"
          //console.log("Conv.Rete Diretta 40%");
          convention.description = "Welcome In";
          convention.code = "I140";
          convention.flex = 40;
        }

        if (company.groupid === 2 || company.groupid === 8) {  //"Rete indiretta" + Bridge
          //console.log("Conv.Rete InDiretta 50%");
          convention.description = "Welcome In";
          convention.code = "I40";
          convention.flex = 50;
        }

        if (company.agencyid === 107) {  //Per agenzia 9201 - FINANCING & CREDIT
          convention.description = "Welcome In";
          convention.code = "I40";
          convention.flex = 30;
        }
        return convention;
      }

      return convention;
    } else {
      return convention;
    }
  }*/
  return convention;
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const checkSicAniaData = async (tokenApp, pos) => {

  try {
    const result = await axios.get(API_URL_SICANIA_LIST + "/" + pos, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + tokenApp,
      }
    });
    return result;

  } catch (e) {

    return Promise.reject(e.response);
  }
};

const checkSicAnia = async (type, targa, token, url, username, password, code, insuranceStatus, onlyATRC, extraFiscalCode, sharetoken) => {
  const json = JSON.stringify({
    username: username,
    password: password,
    licensePlate: targa,
    authority: code,
    onlyATRC: onlyATRC,
    insuranceStatus: insuranceStatus,
    applicationToken: APP_TOKEN_QUOTIAMO,
    extraFiscalCode: extraFiscalCode
  });
  let callurl = url + API_URL_SERVICE_SICANIA + "/" + type + "/sicania";

  if (type === "share") {
    callurl = url + API_URL_SERVICE_DATA_SICANIA + "/" + type + "/" + sharetoken;
  }
  try {
    const result = await axios.post(callurl, json, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      }
    });

    return result;

  } catch (e) {
    return Promise.reject(e.response);
  }
};

const getBrand = async (token, url) => {
  try {
    const result = await axios.get(url + API_URL_GET_BRAND, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      }
    });
    return result;

  } catch (e) {
    return Promise.reject(e.response);
  }
};

const getModels = async (token, url, brand) => {
  try {
    const result = await axios.get(url + API_URL_GET_MODEL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      params: {
        brand: brand
      }
    });
    return result;

  } catch (e) {
    return Promise.reject(e.response);
  }
};

const getListOfCity = async (token, url, search) => {
  try {
    const result = await axios.get(url + API_URL_GET_INFOCITYBYCITY, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      params: {
        city: search
      }
    });
    return result;

  } catch (e) {
    return Promise.reject(e.response);
  }
};

const getOutfitting = async (token, url, brand, model, homologationcode, registrationdate) => {
  try {
    const result = await axios.get(url + API_URL_GET_OUTFITTING, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      params: {
        brand: brand,
        model: model,
        homologationcode: homologationcode,
        registrationdate: registrationdate

      }
    });
    return result;

  } catch (e) {
    return Promise.reject(e.response);
  }
};

const checkPreventivo = async (tipoprev, targa, prevoption, targaoption, datiania, company, token, companyOrigin, conventionslist, guarantees, guaranteesSelect, guaranteesValue, authToken, cvtComp) => {
  if (company.multisession === 1 && company.username === "" && company.password === "" && company.companyCode === "" && company.companyParams === "" && company.nodeCode === "") {
    if (company.paramurl === "hdi") {
      const result = {
        data: {
          error: "Servizio non disponibile",
          message: "Limite utenze in uso raggiunto, riprovare tra qualche minuto",
          path: "/getquotation",
          status: 503,
          errorCode: 1003,
          timestamp: moment().format("YYYY-MM-DD HH:mm:ss")
        }
      };
      return result;
    } else {
      const result = {
        data: {
          error: "Servizio non disponibile",
          message: "Utenze non disponibili, riprovare tra qualche minuto",
          path: "/getquotation",
          status: 503,
          errorCode: 1003,
          timestamp: moment().format("YYYY-MM-DD HH:mm:ss")
        }
      };
      return result;
    }
  }
  /*Verifica se proviene dalla stessa compagnia */

  let listCVT = [];
  let convenzioneAttiva = CheckConvenzioni(datiania, company, tipoprev, conventionslist);
  guarantees.forEach((element, index) => {
    if (guaranteesSelect[index]) {
      let tipoCalcolo = company.guarantees_info[index].tipo_calcolo !== null ? (company.guarantees_info[index].tipo_calcolo !== "default" ? company.guarantees_info[index].tipo_calcolo : company.guarantees_info[index].tipo_calcolo_default) : company.guarantees_info[index].tipo_calcolo_default;
      if (tipoCalcolo !== null && tipoCalcolo === "comp") {
        if (company.guarantees_info[index].service_parameters_code !== null) {
          if (element["code"] === "revengeWaiver") {
            datiania.revengeWaiver = true;
          } else {
            company.guarantees_info[index].service_parameters_code.split(",").forEach(elementcode => {
              listCVT.push(elementcode);
            });
          }
        } else {
          if (element["code"] === "revengeWaiver") {
            datiania.revengeWaiver = true;
          } else {
            listCVT.push(element["code"]);
          }
        }
      }
    } else {
      if (element["code"] === "revengeWaiver") {
        datiania.revengeWaiver = false;
      }
    }
  });

  if (company.only_cvt === 0 && cvtComp === "no") {
    listCVT = [];
  }

  const json = JSON.stringify({
    applicationToken: APP_TOKEN_QUOTIAMO,
    username: company.username,
    password: company.password,
    companyCode: company.companyCode,
    companyParams: company.companyParams,
    nodeCode: company.nodeCode,
    commerciale: (company.commerciale === null || company.commerciale === "") ? "00493" : company.commerciale,
    agZona: (company.agZona === null || company.agZona === "") ? "00493" : company.agZona,
    reteVendita: "001",
    contractorInfo: {
      name: datiania.contractorInfo.name,
      surname: datiania.contractorInfo.surname,
      birthDate: datiania.contractorInfo.birthDate,
      gender: datiania.contractorInfo.gender,
      companyName: datiania.contractorInfo.companyName,
      phoneNumber: company.usedefaultphonenumber === 1 ? company.phonenumber : datiania.contractorInfo.phoneNumber,
      email: company.usedefaultemail === 1 ? company.email : datiania.contractorInfo.email,
      fiscalCode: datiania.contractorInfo.fiscalCode,
      vatNumber: datiania.contractorInfo.vatNumber,
      profession: datiania.contractorInfo.profession,
      qualification: datiania.contractorInfo.qualification,
      maritalStatus: datiania.contractorInfo.maritalStatus,
      personType: (datiania.contractorInfo.subjectType === "1" || datiania.contractorInfo.subjectType === "") ? "FIS" : "GIU",
      subjectType: (datiania.contractorInfo.subjectType === "") ? "1" : datiania.contractorInfo.subjectType,
      residenceInfo: {
        toponym: datiania.contractorInfo.residenceInfo.toponym,
        address: datiania.contractorInfo.residenceInfo.address,
        streetNumber: datiania.contractorInfo.residenceInfo.streetNumber,
        city: datiania.contractorInfo.residenceInfo.city,
        cap: datiania.contractorInfo.residenceInfo.cap,
        provinceAbbr: datiania.contractorInfo.residenceInfo.provinceAbbr,
        istat: datiania.contractorInfo.residenceInfo.istat,
      },
      locationInfo: {
        toponym: datiania.contractorInfo.residenceInfo.toponym,
        address: datiania.contractorInfo.residenceInfo.address,
        streetNumber: datiania.contractorInfo.residenceInfo.streetNumber,
        city: datiania.contractorInfo.residenceInfo.city,
        cap: datiania.contractorInfo.residenceInfo.cap,
        provinceAbbr: datiania.contractorInfo.residenceInfo.provinceAbbr,
        istat: datiania.contractorInfo.residenceInfo.istat,
      }
    },
    ownerInfo: {
      name: datiania.ownerInfo.name,
      surname: datiania.ownerInfo.surname,
      birthDate: datiania.ownerInfo.birthDate,
      gender: datiania.ownerInfo.gender,
      companyName: datiania.ownerInfo.companyName,
      phoneNumber: company.usedefaultphonenumber === 1 ? company.phonenumber : datiania.ownerInfo.phoneNumber,
      email: company.usedefaultemail === 1 ? company.email : datiania.ownerInfo.email,
      fiscalCode: datiania.ownerInfo.fiscalCode,
      vatNumber: datiania.ownerInfo.vatNumber,
      profession: datiania.ownerInfo.profession,
      qualification: datiania.ownerInfo.qualification,
      maritalStatus: datiania.ownerInfo.maritalStatus,
      personType: (datiania.ownerInfo.subjectType === "1" || datiania.ownerInfo.subjectType === "") ? "FIS" : "GIU",
      subjectType: (datiania.ownerInfo.subjectType === "") ? "1" : datiania.ownerInfo.subjectType,
      residenceInfo: {
        toponym: datiania.ownerInfo.residenceInfo.toponym,
        address: datiania.ownerInfo.residenceInfo.address,
        streetNumber: datiania.ownerInfo.residenceInfo.streetNumber,
        city: datiania.ownerInfo.residenceInfo.city,
        cap: datiania.ownerInfo.residenceInfo.cap,
        provinceAbbr: datiania.ownerInfo.residenceInfo.provinceAbbr,
        istat: datiania.ownerInfo.residenceInfo.istat,
      },
      locationInfo: {
        toponym: datiania.ownerInfo.residenceInfo.toponym,
        address: datiania.ownerInfo.residenceInfo.address,
        streetNumber: datiania.ownerInfo.residenceInfo.streetNumber,
        city: datiania.ownerInfo.residenceInfo.city,
        cap: datiania.ownerInfo.residenceInfo.cap,
        provinceAbbr: datiania.ownerInfo.residenceInfo.provinceAbbr,
        istat: datiania.ownerInfo.residenceInfo.istat,
      }
    },
    vehicleInfo: {
      insuranceStatus: tipoprev,
      licensePlate: targa,
      horsePower: datiania.horsePower,
      power: datiania.power,
      homologationCode: datiania.homologationCode,
      brand: datiania.brand,
      value: datiania.usedValue,
      codiceMotornet: datiania.codiceMotornet,
      registrationDate: datiania.registrationDate,
      policyExpirationDate: moment(datiania.policyExpirationDate, "YYYY-MM-DD").format("YYYY-MM-DD"),
      vehicleType: "A",
      towHook: datiania.towHook === "SI" ? true : false,
      supply: datiania.supply,
      insuranceYears: datiania.insuranceYears,
      purchaseDate: datiania.purchaseDate,
      alarm: datiania.alarm,
      carBody: datiania.carBody,
      kmAnnui: datiania.kmAnnui,
      dataATRC: datiania.dataATRC,
    },
    quoteInfo: {
      insuranceCompanyOrigin: companyOrigin,
      insuranceYearsCU1: datiania.insuranceYearsCU1,
      flex: convenzioneAttiva.flex,
      flexCode: convenzioneAttiva.code,
      flexDescription: convenzioneAttiva.description,
      conventionDiscount: "100",
      usedBersani: datiania.usedBersani,
      sons: datiania.sons,
      nSons1: datiania.nSons1,
      nSons2: datiania.nSons2,
      nSons3: datiania.nSons3,
      nSons4: datiania.nSons4,
      drivingLicenseMonth: datiania.drivingLicenseMonth === undefined ? "" : datiania.drivingLicenseMonth,
      drivingLicenseYear: datiania.drivingLicenseYear,
      drivingLicenseType: datiania.drivingLicenseType === undefined ? "" : datiania.drivingLicenseType,
      guideType: (datiania.guideType === "LIB" || datiania.guideType === undefined) ? "LIB" : datiania.guideType,
      youngestLicensedAge: datiania.youngestLicensedAge,
      cuOrigin: datiania.cuOrigin,
      cuAssignment: datiania.cuAssignment,
      declarationOrigin: datiania.declarationOrigin,
      effectiveDate: moment(datiania.effectiveDate, "YYYY-MM-DD").format("YYYY-MM-DD"),
      maximal: datiania.maximal,
      splittingUp: datiania.splittingUp,
      extraLicensePlate: targaoption,
      extraFiscalCode: datiania.extraFiscalCode,
      drivingLicensePoints: datiania.drivingLicensePoints,
      revengeWaiver: datiania.revengeWaiver,
      bersaniDecree: (prevoption === "BE" ? true : false),
      familyDecree: (prevoption === "FA" ? true : false),
      listCVT: listCVT,
    }
  }
  );

  const url_service = company.url + API_URL_QUOTATION + "/" + company.type + "/" + (company.type === "share" ? company.sharetoken : company.paramurl);

  try {
    const result = await axios.post(url_service, json, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      }
    });

    // chiamata sblocco credenziali multisession
    if (company.multisession === 1) {
      const url_multisession = API_URL_MULTISESSION + "/checkuse/" + company.multisessionId + "/0";
      const resultMultisession = await axios.get(url_multisession, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + authToken,
        }
      });
    }

    return result;
  } catch (e) {
    // chiamata sblocco credenziali multisession
    if (company.multisession === 1) {
      const url_multisession = API_URL_MULTISESSION + "/checkuse/" + company.multisessionId + "/0";
      const resultMultisession = await axios.get(url_multisession, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + authToken,
        }
      });
    }

    return Promise.reject(e.response);
  }
};

const checkPreventivoQueue = async (tipoprev, targa, prevoption, targaoption, datiania, company, token, companyOrigin, conventionslist, guarantees, guaranteesSelect, guaranteesValue, authToken, cvtComp, idQuot) => {
  if (company.multisession === 1 && company.username === "" && company.password === "" && company.companyCode === "" && company.companyParams === "" && company.nodeCode === "") {
    if (company.paramurl === "hdi") {
      const result = {
        data: {
          error: "Servizio non disponibile",
          message: "Limite utenze in uso raggiunto",
          path: "/getquotation",
          status: 503,
          errorCode: 1003,
          timestamp: moment().format("YYYY-MM-DD HH:mm:ss")
        }
      };
      return result;
    } else {
      const result = {
        data: {
          error: "Servizio non disponibile",
          message: "Utenze non disponibili, riprovare tra qualche minuto",
          path: "/getquotation",
          status: 503,
          errorCode: 1003,
          timestamp: moment().format("YYYY-MM-DD HH:mm:ss")
        }
      };
      return result;
    }
  }

  let listCVT = [];
  let convenzioneAttiva = CheckConvenzioni(datiania, company, tipoprev, conventionslist);
  guarantees.forEach((element, index) => {
    if (guaranteesSelect[index]) {
      let tipoCalcolo = company.guarantees_info[index].tipo_calcolo !== null ? (company.guarantees_info[index].tipo_calcolo !== "default" ? company.guarantees_info[index].tipo_calcolo : company.guarantees_info[index].tipo_calcolo_default) : company.guarantees_info[index].tipo_calcolo_default;
      if (tipoCalcolo !== null && tipoCalcolo === "comp") {
        if (company.guarantees_info[index].service_parameters_code !== null) {
          if (element["code"] === "revengeWaiver") {
            datiania.revengeWaiver = true;
          } else {
            company.guarantees_info[index].service_parameters_code.split(",").forEach(elementcode => {
              listCVT.push(elementcode);
            });
          }
        } else {
          if (element["code"] === "revengeWaiver") {
            datiania.revengeWaiver = true;
          } else {
            listCVT.push(element["code"]);
          }
        }
      }
    } else {
      if (element["code"] === "revengeWaiver") {
        datiania.revengeWaiver = false;
      }
    }
  });

  if (company.only_cvt === 0 && cvtComp === "no") {
    listCVT = [];
  }

  const json = JSON.stringify({
    applicationToken: APP_TOKEN_QUOTIAMO,
    username: company.username,
    password: company.password,
    companyCode: company.companyCode,
    companyParams: company.companyParams,
    nodeCode: company.nodeCode,
    commerciale: (company.commerciale === null || company.commerciale === "") ? "00493" : company.commerciale,
    agZona: (company.agZona === null || company.agZona === "") ? "00493" : company.agZona,
    reteVendita: "001",
    contractorInfo: {
      name: datiania.contractorInfo.name,
      surname: datiania.contractorInfo.surname,
      birthDate: datiania.contractorInfo.birthDate,
      gender: datiania.contractorInfo.gender,
      companyName: datiania.contractorInfo.companyName,
      phoneNumber: company.usedefaultphonenumber === 1 ? company.phonenumber : datiania.contractorInfo.phoneNumber,
      email: company.usedefaultemail === 1 ? company.email : datiania.contractorInfo.email,
      fiscalCode: datiania.contractorInfo.fiscalCode,
      vatNumber: datiania.contractorInfo.vatNumber,
      profession: datiania.contractorInfo.profession,
      qualification: datiania.contractorInfo.qualification,
      maritalStatus: datiania.contractorInfo.maritalStatus,
      personType: (datiania.contractorInfo.subjectType === "1" || datiania.contractorInfo.subjectType === "") ? "FIS" : "GIU",
      subjectType: (datiania.contractorInfo.subjectType === "") ? "1" : datiania.contractorInfo.subjectType,
      residenceInfo: {
        toponym: datiania.contractorInfo.residenceInfo.toponym,
        address: datiania.contractorInfo.residenceInfo.address,
        streetNumber: datiania.contractorInfo.residenceInfo.streetNumber,
        city: datiania.contractorInfo.residenceInfo.city,
        cap: datiania.contractorInfo.residenceInfo.cap,
        provinceAbbr: datiania.contractorInfo.residenceInfo.provinceAbbr,
      },
      locationInfo: {
        toponym: datiania.contractorInfo.residenceInfo.toponym,
        address: datiania.contractorInfo.residenceInfo.address,
        streetNumber: datiania.contractorInfo.residenceInfo.streetNumber,
        city: datiania.contractorInfo.residenceInfo.city,
        cap: datiania.contractorInfo.residenceInfo.cap,
        provinceAbbr: datiania.contractorInfo.residenceInfo.provinceAbbr,
      }
    },
    ownerInfo: {
      name: datiania.ownerInfo.name,
      surname: datiania.ownerInfo.surname,
      birthDate: datiania.ownerInfo.birthDate,
      gender: datiania.ownerInfo.gender,
      companyName: datiania.ownerInfo.companyName,
      phoneNumber: company.usedefaultphonenumber === 1 ? company.phonenumber : datiania.ownerInfo.phoneNumber,
      email: company.usedefaultemail === 1 ? company.email : datiania.ownerInfo.email,
      fiscalCode: datiania.ownerInfo.fiscalCode,
      vatNumber: datiania.ownerInfo.vatNumber,
      profession: datiania.ownerInfo.profession,
      qualification: datiania.ownerInfo.qualification,
      maritalStatus: datiania.ownerInfo.maritalStatus,
      personType: (datiania.ownerInfo.subjectType === "1" || datiania.ownerInfo.subjectType === "") ? "FIS" : "GIU",
      subjectType: (datiania.ownerInfo.subjectType === "") ? "1" : datiania.ownerInfo.subjectType,
      residenceInfo: {
        toponym: datiania.ownerInfo.residenceInfo.toponym,
        address: datiania.ownerInfo.residenceInfo.address,
        streetNumber: datiania.ownerInfo.residenceInfo.streetNumber,
        city: datiania.ownerInfo.residenceInfo.city,
        cap: datiania.ownerInfo.residenceInfo.cap,
        provinceAbbr: datiania.ownerInfo.residenceInfo.provinceAbbr,
      },
      locationInfo: {
        toponym: datiania.ownerInfo.residenceInfo.toponym,
        address: datiania.ownerInfo.residenceInfo.address,
        streetNumber: datiania.ownerInfo.residenceInfo.streetNumber,
        city: datiania.ownerInfo.residenceInfo.city,
        cap: datiania.ownerInfo.residenceInfo.cap,
        provinceAbbr: datiania.ownerInfo.residenceInfo.provinceAbbr,
      }
    },
    vehicleInfo: {
      insuranceStatus: tipoprev,
      licensePlate: targa,
      horsePower: datiania.horsePower,
      power: datiania.power,
      homologationCode: datiania.homologationCode,
      brand: datiania.brand,
      value: datiania.usedValue,
      codiceMotornet: datiania.codiceMotornet,
      registrationDate: datiania.registrationDate,
      policyExpirationDate: moment(datiania.policyExpirationDate, "YYYY-MM-DD").format("YYYY-MM-DD"),
      vehicleType: "A",
      towHook: datiania.towHook === "SI" ? true : false,
      supply: datiania.supply,
      insuranceYears: datiania.insuranceYears,
      purchaseDate: datiania.purchaseDate,
      alarm: datiania.alarm,
      carBody: datiania.carBody,
      kmAnnui: datiania.kmAnnui,
      dataATRC: datiania.dataATRC,
    },
    quoteInfo: {
      insuranceCompanyOrigin: companyOrigin,
      insuranceYearsCU1: datiania.insuranceYearsCU1,
      flex: convenzioneAttiva.flex,
      flexCode: convenzioneAttiva.code,
      flexDescription: convenzioneAttiva.description,
      conventionDiscount: "100",
      usedBersani: datiania.usedBersani,
      sons: datiania.sons,
      nSons1: datiania.nSons1,
      nSons2: datiania.nSons2,
      nSons3: datiania.nSons3,
      nSons4: datiania.nSons4,
      drivingLicenseMonth: datiania.drivingLicenseMonth === undefined ? "" : datiania.drivingLicenseMonth,
      drivingLicenseYear: datiania.drivingLicenseYear,
      drivingLicenseType: datiania.drivingLicenseType === undefined ? "" : datiania.drivingLicenseType,
      guideType: (datiania.guideType === "LIB" || datiania.guideType === undefined) ? "LIB" : datiania.guideType,
      youngestLicensedAge: datiania.youngestLicensedAge,
      cuOrigin: datiania.cuOrigin,
      cuAssignment: datiania.cuAssignment,
      declarationOrigin: datiania.declarationOrigin,
      effectiveDate: moment(datiania.effectiveDate, "YYYY-MM-DD").format("YYYY-MM-DD"),
      maximal: datiania.maximal,
      splittingUp: datiania.splittingUp,
      extraLicensePlate: targaoption,
      extraFiscalCode: datiania.extraFiscalCode,
      drivingLicensePoints: datiania.drivingLicensePoints,
      revengeWaiver: datiania.revengeWaiver,
      bersaniDecree: (prevoption === "BE" ? true : false),
      familyDecree: (prevoption === "FA" ? true : false),
      listCVT: listCVT,
    }
  }
  );

  const url = API_URL_QUOTATION_LIST + "/queue/save/company";
  try {
    const result = await axios.put(url,
      {
        id_quotation: idQuot,
        product: window.location.pathname.replace("/", ""),
        type_quotation: company.type,
        json: json,
        company: JSON.stringify(company),
        conventions_list: JSON.stringify(conventionslist),
        guarantees: JSON.stringify(guarantees),
        guarantees_select: JSON.stringify(guaranteesSelect)
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + authToken,
        }
      }
    );

    // chiamata sblocco credenziali multisession
    if (company.multisession === 1) {
      const url_multisession = API_URL_MULTISESSION + "/checkuse/" + company.multisessionId + "/0";
      const resultMultisession = await axios.get(url_multisession, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + authToken,
        }
      });
    }

    return result;
  } catch (e) {
    // chiamata sblocco credenziali multisession
    if (company.multisession === 1) {
      const url_multisession = API_URL_MULTISESSION + "/checkuse/" + company.multisessionId + "/0";
      const resultMultisession = await axios.get(url_multisession, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + authToken,
        }
      });
    }

    return Promise.reject(e.response);
  }
};

function TextFieldCustomMassimale(props) {
  return (
    <TextField label="Massimale" variant="outlined" {...props} />
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  button: {
    height: 30
  },
  cellTitleSoon: {
    fontWeight: "bold",
    textAlign: "center"
  },
  Carousel: {
    width: "100%"
  },
  Media: {
    height: 800
  },
  Media2: {
    height: 900
  },
  Carousel2: {
    width: "50%"
  },
  TitleCategory: {
    marginTop: "10px",
    fontSize: "16px",
    fontWeight: "600"
  },
  PaddingTop: {
    paddingTop: 30,
  },
  cellroot: {
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },

  prezzo: {
    align: 'right'
  },
  titleTable: {
    flex: '1 1 100%',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },


  linear_class: {
    width: '20%',
    '& > * + *': {
      marginTop: theme.spacing(2),
      zIndex: theme.zIndex.drawer + 2,
    },
  },
  buttonProgress: {

    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  root: {
    width: '90%'
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    //maxWidth:'100%',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    alignItems: 'center',
    flexDirection: 'column',
  },

  preventivo: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 800,
  },
  image: {
    width: 180,
    height: 60,
  },

  imgLogo: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '50px',
    marginBottom: '40px'
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  fixedHeight: {
    height: 240,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  pos: {
    marginBottom: 12,
  },
  containerButton: {
    alignItems: 'right',
    textAlign: 'right',
    marginBottom: '10px',
    marginTop: '10px'
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
    alignItems: 'center'
  },
  formControl: {
    width: '100%'
  },
  progress: {
    display: "none"
  },
  iconRoot: {
    color: red
  },
  linearProgressDiv: {
    width: '90%'
  }
}));

function getSteps() {
  return ['Targa', 'Anagrafiche', 'Veicolo', 'Situazione', 'ARD/CVT', 'Preventivo', 'Modifica/Conferma'];
}

export default function Quotation(props) {
  const [loading, setLoading] = React.useState(false);
  const [, setPosActive] = React.useContext(PosDataContext);
  const [NumberQuotation, setNumberQuotation] = React.useState(false);
  //const [Quote, setQuote] = React.useState(false);

  const [targa, setTarga] = React.useState('');
  const [pos, setPos] = React.useState('');
  const [posinfo, setPosInfo] = React.useState(null);
  const [tipoprev, setTipoPrev] = React.useState('RIN');
  const [datiania, setdatiania] = React.useState([]);
  const [agencieslists, setagencieslists] = React.useState([]);
  const [datipreventivo, setdatipreventivo] = React.useState([]);
  const [companyData, setcompanyData] = React.useState([]);
  const [listProfessions, setlistProfessions] = React.useState([]);
  const [listBrands, setlistBrands] = React.useState([]);
  const [listModels, setlistModels] = React.useState([]);
  const [listCity, setlistCity] = React.useState([]);
  const [listOutfitting, setlistOutfitting] = React.useState([]);
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const [progress, setProgress] = React.useState(0);
  const [targaoption, setTargaOption] = React.useState('');
  const [prevoption, setPrevOption] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [alertseverity, setAlertSeverity] = React.useState("success");
  const [alertmsg, setAlertMsg] = React.useState(false);
  const [token, setToken] = React.useState(null);
  const [urlservicequotiamo, seturlservicequotiamo] = React.useState("");
  const [prevRQ, setPrevRQ] = React.useState(0);
  const [prevRS, setPrevRS] = React.useState(0);
  const location = useLocation();
  const value = queryString.parse(location.search);
  const [PreCompile, setPreCompile] = React.useState(false);
  const token810DN = value.checktoken;
  const idDN = value.id;
  const codiceDN = value.codice;
  let contaPreventiviDaFare = 0;
  let contaPreventiviFatti = 0;

  const [images, setImages] = React.useState([]);
  const [factories, setFactories] = React.useState([]);

  const [sameOwner, setSameOwner] = React.useState(true);
  const [sameDriver, setSameDriver] = React.useState(true);
  //  const [savePDF,setSavePDF]= React.useState(false);

  const [orgid, setOrgId] = React.useState(null);
  const [guarantees, setGuarantees] = React.useState([]);
  const [guaranteesSelect, setGuaranteesSelect] = React.useState([]);
  const [guaranteesValue, setGuaranteesValue] = React.useState([]);

  const [useCvtComp, setUseCvtComp] = React.useState("yes");
  const [defCvtComp, setDefCvtComp] = React.useState("yes");

  const [factoriesHub, setFactoriesHub] = React.useState([]);

  const [background, setBackground] = React.useState(false);

  /*const OnSavePDF = () => {
    setSavePDF(false);
  };*/

  React.useEffect(() => {
    if (orgid !== null) {
      const url = API_URL_ALLGUARANTEES + "/enabled/car/" + orgid;
      axios.get(url,
        {
          headers: {
            'Authorization': 'Bearer ' + Auth.getToken(),
            'Content-Type': 'application/json'
          }
        }
      ).then(payload => {
        let temp = [];
        let tempVal = [];
        let tempGuar = payload.data.guarantees;
        payload.data.guarantees.forEach(element => {
          temp.push(false);
          if (element.input_type === 2) {
            tempVal.push(element.list_value.split(",")[0]);
          } else {
            tempVal.push("");
          }
        });
        if (orgid === 1 && posinfo.groupid !== 8) {
          payload.data.guarantees.forEach((element, index) => {
            if (element.id === 1) {
              temp.splice(index, 1);
              tempVal.splice(index, 1);
              tempGuar.splice(index, 1);
            }
          });
        }
        setGuaranteesSelect(temp);
        setGuaranteesValue(tempVal);
        setGuarantees(tempGuar);
      });
    }
  }, [orgid]);

  React.useEffect(() => {
    const url = API_URL_FACTORIES_LIST;
    axios.get(url,
      {
        headers: {
          'Authorization': 'Bearer ' + Auth.getToken(),
          'Content-Type': 'application/json'
        }
      }
    ).then(payload => {
      setFactories(payload.data.factories);
    });
  }, []);

  React.useEffect(() => {
    if (idDN !== undefined) {
      const url = REGISTRY_URL;
      axios.post(url, {
        "id": idDN,
        "codice": codiceDN
      },
        {
          headers: {
            'Authorization': 'Bearer ' + token810DN,
            'Content-Type': 'application/json'
          }
        }
      ).then(payload => {
        setPreCompile(payload.data.result);
        setTarga(payload.data.result[0].risposta);
      });
    }
  }, [idDN, codiceDN, token810DN]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleChangeOption = (event) => {
    setPrevOption(event.target.value);
  };

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 1000 ? 1 : prevProgress + 1));
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  React.useEffect(() => {
    const url = API_URL_LIST_AGENCIES_USER;
    axios.get(url,
      {
        headers: {
          'Authorization': 'Bearer ' + Auth.getToken(),
          'Content-Type': 'application/json'
        }
      }
    ).then(payload => {
      setagencieslists(payload.data);
      if (payload.data.length > 0) {
        setPos(payload.data[0].id);
        seturlservicequotiamo(payload.data[0].url);
        setPosInfo(payload.data[0]);
        setPosActive(payload.data[0]);
        setOrgId(payload.data[0].orgid);
        localStorage.setItem('pos', JSON.stringify(payload.data[0]));
        let cvtComp = (payload.data[0].defAg !== null && JSON.parse(payload.data[0].defAg).auto_cvt_comp !== undefined && JSON.parse(payload.data[0].defAg).auto_cvt_comp !== "" ? JSON.parse(payload.data[0].defAg).auto_cvt_comp : (payload.data[0].defGrp !== null && JSON.parse(payload.data[0].defGrp).auto_cvt_comp !== undefined && JSON.parse(payload.data[0].defGrp).auto_cvt_comp !== "" ? JSON.parse(payload.data[0].defGrp).auto_cvt_comp : (payload.data[0].defOrg !== null && JSON.parse(payload.data[0].defOrg).auto_cvt_comp !== undefined && JSON.parse(payload.data[0].defOrg).auto_cvt_comp !== "" ? JSON.parse(payload.data[0].defOrg).auto_cvt_comp : "yes")));
        setDefCvtComp(cvtComp);
        if (cvtComp !== "both") {
          setUseCvtComp(cvtComp);
        }

        AuthService.signin(payload.data[0].username, payload.data[0].password, payload.data[0].url).then(
          payload => {
            setToken(payload);
          }).catch(error => {
          });
        const url = API_URL_INFOIMAGES_ALL + "/car/" + payload.data[0].id;
        axios.get(url,
          {
            headers: {
              'Authorization': 'Bearer ' + Auth.getToken(),
              'Content-Type': 'application/json'
            }
          }
        ).then(payload => {
          setImages(payload.data.images)
        });
      }
    });
  }, [setPosActive]);

  const checkcompanyData = async (tokenApp, pos) => {
    try {
      const result = await axios.get(API_URL_LIST_SERVICES_AGENCY_USER + "/car/" + pos, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + tokenApp,
        }
      });
      return result;
    } catch (e) {
      return Promise.reject(e.response);
    }
  };

  const HandleSaveQuotation = (companyListPrev) => {
    const userData = Auth.getUser();

    let proflabel = "";
    listProfessions.forEach(prof => {
      if (prof.id === datiania.contractorInfo.profession) {
        proflabel = prof.description;
      }
    });

    let qualificationlabel = "";
    livelliIstruzione.forEach(qual => {
      if (qual.id === datiania.qualification) {
        qualificationlabel = qual.description;
      }
    });

    let statoCivilelabel = "";
    statoCivile.forEach(statociv => {
      if (statociv.id === datiania.maritalStatus) {
        statoCivilelabel = statociv.description;
      }
    })

    const json = JSON.stringify({
      clientinfo: datiania,
      clientlabelinfo: {
        profession: proflabel,
        qualification: qualificationlabel,
        maritalStatus: statoCivilelabel
      },
      vehicleInfo: {
        insuranceStatus: tipoprev,
        licensePlate: targa,
        vehicleType: "A",
      },
      quoteInfo: {
        extraLicensePlate: targaoption,
        bersaniDecree: (prevoption === "BE" ? true : false),
        familyDecree: (prevoption === "FA" ? true : false),
      }
    });

    const list = [];

    companyListPrev.forEach((company) => {
      company.guarantees_select = guaranteesSelect;
      list.push(
        {
          company: company,
          datipre: datipreventivo[company.sfo_id + company.paramurl + company.type]
        }
      )
    });

    const url = API_URL_QUOTATION_LIST;
    axios.put(url, {
      product: window.location.pathname,
      id_user: userData.id,
      id_agency: pos,
      clientinfo: json,
      detail: JSON.stringify(list),
      select: "",
    },
      {
        headers: {
          'Authorization': 'Bearer ' + Auth.getToken(),
          'Content-Type': 'application/json'
        }
      }
    ).then(payload => {
      if (payload.status === 200) {
        setAlertMsg("Inserimento dati avvenuto con successo");
        setAlertSeverity("success");
        setNumberQuotation(payload.data.numberquotation);
      } else {
        setAlertMsg("Errore durante l'inserimento dati");
        setAlertSeverity("error");
      }
      setOpen(true);
    }).catch((error) => {
      setAlertMsg(error.response.data.error);
      setAlertSeverity("error");
      setOpen(true);
    });
  }

  const salvataggioPreventivo = async (companyListPrev) => {
    const userData = Auth.getUser();

    let proflabel = "";
    listProfessions.forEach(prof => {
      if (prof.id === datiania.contractorInfo.profession) {
        proflabel = prof.description;
      }
    });
    let qualificationlabel = "";
    livelliIstruzione.forEach(qual => {
      if (qual.id === datiania.qualification) {
        qualificationlabel = qual.description;
      }
    });
    let statoCivilelabel = "";
    statoCivile.forEach(statociv => {
      if (statociv.id === datiania.maritalStatus) {
        statoCivilelabel = statociv.description;
      }
    })

    const json = JSON.stringify({
      clientinfo: datiania,
      clientlabelinfo: {
        profession: proflabel,
        qualification: qualificationlabel,
        maritalStatus: statoCivilelabel
      },
      vehicleInfo: {
        insuranceStatus: tipoprev,
        licensePlate: targa,
        vehicleType: "A",
      },
      quoteInfo: {
        extraLicensePlate: targaoption,
        bersaniDecree: (prevoption === "BE" ? true : false),
        familyDecree: (prevoption === "FA" ? true : false),
      }
    }
    );

    const list = [];
    companyListPrev.forEach((company) => {
      company.guarantees_select = guaranteesSelect;
      list.push(
        {
          company: company
        }
      )
    });

    const url = API_URL_QUOTATION_LIST + "/queue/save";

    try {
      const result = await axios.put(url,
        {
          product: window.location.pathname,
          id_user: userData.id,
          id_agency: pos,
          clientinfo: json,
          detail: JSON.stringify(list),
          select: "",
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + Auth.getToken(),
          }
        });
      setNumberQuotation(result.data.numberquotation);
      return result;
    } catch (e) {
      return Promise.reject(e.response);
    }
  }

  const handleNext = () => {
    switch (activeStep) {
      case 0:
        if (pos === "") {
          setAlertMsg("Nessun punto vendita abilitato, impossibile effettuare il preventivo");
          setAlertSeverity("error");
          setOpen(true);
          break;
        }

        checkcompanyData(Auth.getToken(), pos).then(
          payload => {
            let companyListPrev = payload.data.services;
            if (companyListPrev.length < 1) {
              setAlertMsg("Prodotto non attivo per questo punto vendita");
              setAlertSeverity("error");
              setOpen(true);
              return;
            }

            if (targa === "") {
              setAlertMsg("Inserire la targa per proseguire");
              setAlertSeverity("error");
              setOpen(true);
              return;
            }

            if (prevoption !== "" && targaoption === "") {
              if (prevoption === "BE") {
                setAlertMsg("Per usufruire della legge Bersani è necessario specificare la targa di un veicolo associato");
              }
              if (prevoption === "FA") {
                setAlertMsg("Per usufruire del decreto Familiare è necessario specificare la targa di un veicolo associato");
              }
              if (prevoption === "NO") {
                setAlertMsg("Per importare la CU è necessario impostare la targa di un veicolo associato");
              }
              setAlertSeverity("error");
              setOpen(true);
              return;
            }

            getProfessions(token, urlservicequotiamo).then(payload => {
              setlistProfessions(payload.data);
            });

            getBrand(token, urlservicequotiamo).then(payload => {
              setlistBrands(payload.data);
            });

            getListOfCity(token, urlservicequotiamo, "").then(payload => {
              setlistCity(payload.data);
            });

            setLoading(true);
            checkSicAniaData(Auth.getToken(), pos).then(
              payload => {
                const datiCollegamentoAnia = payload.data.sicania_services[0];

                checkSicAnia(datiCollegamentoAnia.type, targa, token, urlservicequotiamo, datiCollegamentoAnia.username, datiCollegamentoAnia.password, datiCollegamentoAnia.code, tipoprev, false, "", datiCollegamentoAnia.sharetoken).then(
                  payload => {
                    let newdatiania;
                    if (payload.data.error !== undefined) {
                      newdatiania = {
                        contractorInfo: {
                          subjectType: "1",
                          name: "",
                          surname: "",
                          birthDate: "",
                          gender: "M",
                          fiscalCode: "",
                          birthCity: "",
                          birthProvince: "",
                          birthRegion: "",
                          birthNationAbbr: "",
                          birthNation: "",
                          email: "",
                          phoneNumber: "",
                          profession: "",
                          qualification: "",
                          maritalStatus: "",
                          residenceInfo: {
                            city: "",
                            cap: "",
                            provinceAbbr: "",
                            toponym: "",
                            address: "",
                            streetNumber: "",
                            istat: "",
                          }
                        },
                        ownerInfo: {
                          subjectType: "1",
                          name: "",
                          surname: "",
                          birthDate: "",
                          gender: "M",
                          fiscalCode: "",
                          birthCity: "",
                          birthProvince: "",
                          birthRegion: "",
                          birthNationAbbr: "",
                          birthNation: "",
                          email: "",
                          phoneNumber: "",
                          profession: "",
                          qualification: "",
                          maritalStatus: "",
                          residenceInfo: {
                            city: "",
                            cap: "",
                            provinceAbbr: "",
                            toponym: "",
                            address: "",
                            streetNumber: "",
                            istat: "",
                          }
                        },
                        effectiveDate: moment().format("YYYY-MM-DD"),
                        policyExpirationDate: "",
                        carBody: "",
                        power: "",
                        displacement: "",
                        horsePower: "",
                        supply: "",
                        outfitting: "",
                        newValue: "",
                        usedValue: "",
                        model: "",
                        codiceMotornet: "",
                        drivingLicenseMonth: "",
                        drivingLicenseYear: "",
                        nSons1: "",
                        nSons2: "",
                        nSons3: "",
                        nSons4: "",
                        sons: "",
                        drivingLicenseType: "",
                        drivingLicensePoints: "",
                        kmAnnui: "",
                        guideType: "",
                        youngestLicensedAge: "",
                        maximal: "",
                        splittingUp: 1,
                        insuranceYears: "",
                        currentAward: "",
                        dataATRC: {
                          anno0Atrc: moment().format("YYYY"),
                          atrcRespPrincAnno0: "--",
                          atrcRespPrincDanniCoseAnno0: "--",
                          atrcRespPrincDanniPersAnno0: "--",
                          atrcRespPrincDanniMistiAnno0: "--",
                          atrcRespPariAnno0: "--",
                          atrcRespPariDanniCoseAnno0: "--",
                          atrcRespPariDanniPersAnno0: "--",
                          atrcRespPariDanniMistiAnno0: "--",
                          atrcRespPrincAnno1: "--",
                          atrcRespPrincDanniCoseAnno1: "--",
                          atrcRespPrincDanniPersAnno1: "--",
                          atrcRespPrincDanniMistiAnno1: "--",
                          atrcRespPariAnno1: "--",
                          atrcRespPariDanniCoseAnno1: "--",
                          atrcRespPariDanniPersAnno1: "--",
                          atrcRespPariDanniMistiAnno1: "--",
                          atrcRespPrincAnno2: "--",
                          atrcRespPrincDanniCoseAnno2: "--",
                          atrcRespPrincDanniPersAnno2: "--",
                          atrcRespPrincDanniMistiAnno2: "--",
                          atrcRespPariAnno2: "--",
                          atrcRespPariDanniCoseAnno2: "--",
                          atrcRespPariDanniPersAnno2: "--",
                          atrcRespPariDanniMistiAnno2: "--",
                          atrcRespPrincAnno3: "--",
                          atrcRespPrincDanniCoseAnno3: "--",
                          atrcRespPrincDanniPersAnno3: "--",
                          atrcRespPrincDanniMistiAnno3: "--",
                          atrcRespPariAnno3: "--",
                          atrcRespPariDanniCoseAnno3: "--",
                          atrcRespPariDanniPersAnno3: "--",
                          atrcRespPariDanniMistiAnno3: "--",
                          atrcRespPrincAnno4: "--",
                          atrcRespPrincDanniCoseAnno4: "--",
                          atrcRespPrincDanniPersAnno4: "--",
                          atrcRespPrincDanniMistiAnno4: "--",
                          atrcRespPariAnno4: "--",
                          atrcRespPariDanniCoseAnno4: "--",
                          atrcRespPariDanniPersAnno4: "--",
                          atrcRespPariDanniMistiAnno4: "--",
                          atrcRespPrincAnno5: "--",
                          atrcRespPrincDanniCoseAnno5: "--",
                          atrcRespPrincDanniPersAnno5: "--",
                          atrcRespPrincDanniMistiAnno5: "--",
                          atrcRespPariAnno5: "--",
                          atrcRespPariDanniCoseAnno5: "--",
                          atrcRespPariDanniPersAnno5: "--",
                          atrcRespPariDanniMistiAnno5: "--",
                          atrcRespPrincAnno6: "--",
                          atrcRespPrincDanniCoseAnno6: "--",
                          atrcRespPrincDanniPersAnno6: "--",
                          atrcRespPrincDanniMistiAnno6: "--",
                          atrcRespPariAnno6: "--",
                          atrcRespPariDanniCoseAnno6: "--",
                          atrcRespPariDanniPersAnno6: "--",
                          atrcRespPariDanniMistiAnno6: "--",
                          atrcRespPrincAnno7: "--",
                          atrcRespPrincDanniCoseAnno7: "--",
                          atrcRespPrincDanniPersAnno7: "--",
                          atrcRespPrincDanniMistiAnno7: "--",
                          atrcRespPariAnno7: "--",
                          atrcRespPariDanniCoseAnno7: "--",
                          atrcRespPariDanniPersAnno7: "--",
                          atrcRespPariDanniMistiAnno7: "--",
                          atrcRespPrincAnno8: "--",
                          atrcRespPrincDanniCoseAnno8: "--",
                          atrcRespPrincDanniPersAnno8: "--",
                          atrcRespPrincDanniMistiAnno8: "--",
                          atrcRespPariAnno8: "--",
                          atrcRespPariDanniCoseAnno8: "--",
                          atrcRespPariDanniPersAnno8: "--",
                          atrcRespPariDanniMistiAnno8: "--",
                          atrcRespPrincAnno9: "--",
                          atrcRespPrincDanniCoseAnno9: "--",
                          atrcRespPrincDanniPersAnno9: "--",
                          atrcRespPrincDanniMistiAnno9: "--",
                          atrcRespPariAnno9: "--",
                          atrcRespPariDanniCoseAnno9: "--",
                          atrcRespPariDanniPersAnno9: "--",
                          atrcRespPariDanniMistiAnno9: "--",
                          atrcRespPrincAnno10: "--",
                          atrcRespPrincDanniCoseAnno10: "--",
                          atrcRespPrincDanniPersAnno10: "--",
                          atrcRespPrincDanniMistiAnno10: "--",
                          atrcRespPariAnno10: "--",
                          atrcRespPariDanniCoseAnno10: "--",
                          atrcRespPariDanniPersAnno10: "--",
                          atrcRespPariDanniMistiAnno10: "--"
                        },
                        "listDataCARD": [

                        ],
                      };
                    } else {
                      newdatiania = payload.data;
                    }

                    /*
                    Imposta i parametri di dafault
                    se presenti nei default aziendali altrimenti li inizializza a vuoto
                    */

                    newdatiania.contractorInfo.profession = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).profession !== undefined ? JSON.parse(posinfo.defAg).profession : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).profession !== undefined ? JSON.parse(posinfo.defGrp).profession : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).profession !== undefined ? JSON.parse(posinfo.defOrg).profession : "")));
                    newdatiania.contractorInfo.qualification = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).qualification !== undefined ? JSON.parse(posinfo.defAg).qualification : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).qualification !== undefined ? JSON.parse(posinfo.defGrp).qualification : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).qualification !== undefined ? JSON.parse(posinfo.defOrg).qualification : "")));
                    newdatiania.contractorInfo.maritalStatus = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).maritalStatus !== undefined ? JSON.parse(posinfo.defAg).maritalStatus : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).maritalStatus !== undefined ? JSON.parse(posinfo.defGrp).maritalStatus : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).maritalStatus !== undefined ? JSON.parse(posinfo.defOrg).maritalStatus : "")));
                    newdatiania.contractorInfo.residenceInfo.toponym = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).toponym !== undefined ? JSON.parse(posinfo.defAg).toponym : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).toponym !== undefined ? JSON.parse(posinfo.defGrp).toponym : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).toponym !== undefined ? JSON.parse(posinfo.defOrg).toponym : "")));
                    newdatiania.contractorInfo.residenceInfo.address = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).address !== undefined ? JSON.parse(posinfo.defAg).address : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).address !== undefined ? JSON.parse(posinfo.defGrp).address : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).address !== undefined ? JSON.parse(posinfo.defOrg).address : "")));
                    newdatiania.contractorInfo.residenceInfo.streetNumber = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).streetNumber !== undefined ? JSON.parse(posinfo.defAg).streetNumber : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).streetNumber !== undefined ? JSON.parse(posinfo.defGrp).streetNumber : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).streetNumber !== undefined ? JSON.parse(posinfo.defOrg).streetNumber : "")));
                    newdatiania.contractorInfo.email = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).email !== undefined ? JSON.parse(posinfo.defAg).email : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).email !== undefined ? JSON.parse(posinfo.defGrp).email : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).email !== undefined ? JSON.parse(posinfo.defOrg).email : "")));
                    newdatiania.contractorInfo.phoneNumber = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).phoneNumber !== undefined ? JSON.parse(posinfo.defAg).phoneNumber : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).phoneNumber !== undefined ? JSON.parse(posinfo.defGrp).phoneNumber : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).phoneNumber !== undefined ? JSON.parse(posinfo.defOrg).phoneNumber : "")));

                    newdatiania.sons = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).sons !== undefined ? JSON.parse(posinfo.defAg).sons : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).sons !== undefined ? JSON.parse(posinfo.defGrp).sons : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).sons !== undefined ? JSON.parse(posinfo.defOrg).sons : "")));

                    newdatiania.ownerInfo.residenceInfo.toponym = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).toponym !== undefined ? JSON.parse(posinfo.defAg).toponym : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).toponym !== undefined ? JSON.parse(posinfo.defGrp).toponym : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).toponym !== undefined ? JSON.parse(posinfo.defOrg).toponym : "")));
                    newdatiania.ownerInfo.residenceInfo.address = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).address !== undefined ? JSON.parse(posinfo.defAg).address : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).address !== undefined ? JSON.parse(posinfo.defGrp).address : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).address !== undefined ? JSON.parse(posinfo.defOrg).address : "")));
                    newdatiania.ownerInfo.residenceInfo.streetNumber = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).streetNumber !== undefined ? JSON.parse(posinfo.defAg).streetNumber : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).streetNumber !== undefined ? JSON.parse(posinfo.defGrp).streetNumber : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).streetNumber !== undefined ? JSON.parse(posinfo.defOrg).streetNumber : "")));

                    newdatiania.drivingLicenseType = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).drivingLicenseType !== undefined ? JSON.parse(posinfo.defAg).drivingLicenseType : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).drivingLicenseType !== undefined ? JSON.parse(posinfo.defGrp).drivingLicenseType : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).drivingLicenseType !== undefined ? JSON.parse(posinfo.defOrg).drivingLicenseType : "")));
                    newdatiania.drivingLicensePoints = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).drivingLicensePoints !== undefined ? JSON.parse(posinfo.defAg).drivingLicensePoints : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).drivingLicensePoints !== undefined ? JSON.parse(posinfo.defGrp).drivingLicensePoints : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).drivingLicensePoints !== undefined ? JSON.parse(posinfo.defOrg).drivingLicensePoints : "")));
                    newdatiania.kmAnnui = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).kmAnnui !== undefined ? JSON.parse(posinfo.defAg).kmAnnui : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).kmAnnui !== undefined ? JSON.parse(posinfo.defGrp).kmAnnui : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).kmAnnui !== undefined ? JSON.parse(posinfo.defOrg).kmAnnui : "")));
                    newdatiania.guideType = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).guideType !== undefined ? JSON.parse(posinfo.defAg).guideType : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).guideType !== undefined ? JSON.parse(posinfo.defGrp).guideType : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).guideType !== undefined ? JSON.parse(posinfo.defOrg).guideType : "")));
                    newdatiania.youngestLicensedAge = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).youngestLicensedAge !== undefined ? JSON.parse(posinfo.defAg).youngestLicensedAge : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).youngestLicensedAge !== undefined ? JSON.parse(posinfo.defGrp).youngestLicensedAge : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).youngestLicensedAge !== undefined ? JSON.parse(posinfo.defOrg).youngestLicensedAge : "")));
                    newdatiania.maximal = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).maximal !== undefined ? JSON.parse(posinfo.defAg).maximal : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).maximal !== undefined ? JSON.parse(posinfo.defGrp).maximal : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).maximal !== undefined ? JSON.parse(posinfo.defOrg).maximal : "")));
                    newdatiania.splittingUp = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).splittingUp !== undefined ? JSON.parse(posinfo.defAg).splittingUp : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).splittingUp !== undefined ? JSON.parse(posinfo.defGrp).splittingUp : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).splittingUp !== undefined ? JSON.parse(posinfo.defOrg).splittingUp : 1)));

                    newdatiania.insuranceYearsCU1 = 0;
                    newdatiania.insuranceYears = ReturnDefaultinsuranceYears(posinfo, newdatiania, newdatiania.contractorInfo.birthDate !== "" ? moment(newdatiania.contractorInfo.birthDate, "YYYY-MM-DD").add(18, "years").add(2, "months").format("YYYY") : "");

                    let dataAcq = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).auto_data_acq !== undefined ? JSON.parse(posinfo.defAg).auto_data_acq : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).auto_data_acq !== undefined ? JSON.parse(posinfo.defGrp).auto_data_acq : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).auto_data_acq !== undefined ? JSON.parse(posinfo.defOrg).auto_data_acq : "ania")));
                    switch (dataAcq) {
                      case "ania":
                        break;
                      case "vuoto":
                        newdatiania.purchaseDate = "";
                        break;
                      default:
                        break;
                    }
                    let dataImm = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).auto_data_imm !== undefined ? JSON.parse(posinfo.defAg).auto_data_imm : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).auto_data_imm !== undefined ? JSON.parse(posinfo.defGrp).auto_data_imm : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).auto_data_imm !== undefined ? JSON.parse(posinfo.defOrg).auto_data_imm : "ania")));
                    switch (dataImm) {
                      case "ania":
                        break;
                      case "vuoto":
                        newdatiania.registrationDate = "";
                        break;
                      default:
                        break;
                    }

                    newdatiania.currentAward = "";
                    if (newdatiania.contractorInfo.residenceInfo.city === null) {
                      newdatiania.contractorInfo.residenceInfo.cap = "";
                      newdatiania.contractorInfo.residenceInfo.provinceAbbr = "";
                      newdatiania.contractorInfo.residenceInfo.city = "";
                      newdatiania.contractorInfo.residenceInfo.istat = "";
                    }

                    if (newdatiania.contractorInfo.residenceInfo.toponym === null) {
                      newdatiania.contractorInfo.residenceInfo.toponym = "";
                    }

                    if (newdatiania.contractorInfo.residenceInfo.address === null) {
                      newdatiania.contractorInfo.residenceInfo.address = "";
                    }

                    if (newdatiania.contractorInfo.residenceInfo.streetNumber === null) {
                      newdatiania.contractorInfo.residenceInfo.streetNumber = "";
                    }

                    if (newdatiania.ownerInfo.residenceInfo.city === null) {
                      newdatiania.ownerInfo.residenceInfo.cap = "";
                      newdatiania.ownerInfo.residenceInfo.provinceAbbr = "";
                      newdatiania.ownerInfo.residenceInfo.city = "";
                      newdatiania.ownerInfo.residenceInfo.istat = "";
                    }

                    newdatiania.alarm = "2"; //Generico

                    if (posinfo.defAg !== null && JSON.parse(posinfo.defAg).effectiveDate !== undefined ? true : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).effectiveDate !== undefined ? true : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).effectiveDate !== undefined ? true : false))) {
                      //Imposta al 15 o a fine mese
                      if ((posinfo.defAg !== null && JSON.parse(posinfo.defAg).effectiveDate !== undefined ? JSON.parse(posinfo.defAg).effectiveDate : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).effectiveDate !== undefined ? JSON.parse(posinfo.defGrp).effectiveDate : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).effectiveDate !== undefined ? JSON.parse(posinfo.defOrg).effectiveDate : "2"))) === "1") {
                        if (moment(newdatiania.effectiveDate, "YYYY-MM-DD").format("DD") > 15) {
                          newdatiania.effectiveDate = moment(datiania.effectiveDate).endOf('month');
                        } else {
                          newdatiania.effectiveDate = moment(datiania.effectiveDate, "YYYY-MM-DD").format("YYYY-MM") + "-15";
                        }
                      }
                      //Lascia la data di scadenza
                      if ((posinfo.defAg !== null && JSON.parse(posinfo.defAg).effectiveDate !== undefined ? JSON.parse(posinfo.defAg).effectiveDate : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).effectiveDate !== undefined ? JSON.parse(posinfo.defGrp).effectiveDate : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).effectiveDate !== undefined ? JSON.parse(posinfo.defOrg).effectiveDate : "2"))) === "2") {
                      }
                    }

                    let n = 0;
                    for (const cvt of guarantees) {
                      if (posinfo.defAg !== null && JSON.parse(posinfo.defAg)[cvt.code] !== undefined ? true : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp)[cvt.code] !== undefined ? true : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg)[cvt.code] !== undefined ? true : false))) {
                        let flag = (posinfo.defAg !== null && JSON.parse(posinfo.defAg)[cvt.code] !== undefined ? JSON.parse(posinfo.defAg)[cvt.code] : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp)[cvt.code] !== undefined ? JSON.parse(posinfo.defGrp)[cvt.code] : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg)[cvt.code] !== undefined ? JSON.parse(posinfo.defOrg)[cvt.code] : 0)));
                        if (flag > 0) {
                          guaranteesSelect[n] = true;
                        }
                      }
                      n++;
                    }

                    if (PreCompile) {
                      let addressElement = PreCompile.indirizzo.split(" ");
                      if (newdatiania.contractorInfo.fiscalCode === PreCompile.codicefiscale) {
                      } else {
                      }
                      newdatiania.contractorInfo.residenceInfo.toponym = addressElement[0];
                      addressElement.splice(0, 1);
                      let indirizzo = addressElement.join(" ");
                      newdatiania.contractorInfo.residenceInfo.address = indirizzo;
                      newdatiania.contractorInfo.residenceInfo.streetNumber = PreCompile.numerocivico;
                      newdatiania.contractorInfo.residenceInfo.provinceAbbr = PreCompile.provincia;
                      newdatiania.contractorInfo.residenceInfo.cap = PreCompile.cap;
                      newdatiania.contractorInfo.residenceInfo.istat = PreCompile.istat;
                      newdatiania.contractorInfo.fiscalCode = PreCompile.codicefiscale;
                      getInfoBirth(token, urlservicequotiamo, PreCompile.codicefiscale, PreCompile.nome, PreCompile.cognome).then(payload => {
                        newdatiania.contractorInfo.birthCity = payload.data.city;
                        newdatiania.contractorInfo.birthProvince = payload.data.province;
                        newdatiania.contractorInfo.birthRegion = payload.data.region;
                        newdatiania.contractorInfo.birthNationAbbr = payload.data.nationAbbr;
                        newdatiania.contractorInfo.birthNation = payload.data.nation;
                        newdatiania.contractorInfo.gender = payload.data.gender;
                        newdatiania.contractorInfo.birthDate = payload.data.birthDate;
                      });

                      newdatiania.contractorInfo.residenceInfo.city = PreCompile.comune;
                      newdatiania.contractorInfo.phoneNumber = PreCompile.telefonomobile;
                      newdatiania.contractorInfo.email = PreCompile.email;
                      newdatiania.contractorInfo.name = PreCompile.nome;
                      newdatiania.contractorInfo.surname = PreCompile.cognome;

                      //Massimale
                      //1: {domanda: 'Massimali RCA', risposta: '5000000', sottodomanda1: '', sottorisposta1: '', sottodomanda2: '', …}
                      if (Number(PreCompile[1].risposta) >= 10000000) {
                        newdatiania.maximal = '2';
                      } else {
                        if (Number(PreCompile[1].risposta)) {
                          newdatiania.maximal = '1';
                        }
                      }
                      //Km annui
                      //3: {domanda: 'KM annui', risposta: '7000', sottodomanda1: '', sottorisposta1: '', sottodomanda2: '', …}
                      if (Number(PreCompile[3].risposta)) {
                        if (Number(PreCompile[3].risposta) < 10000) {
                          newdatiania.kmAnnui = '1';
                        } else if (Number(PreCompile[3].risposta) < 20000) {
                          newdatiania.kmAnnui = '2';
                        } else if (Number(PreCompile[3].risposta) < 30000) {
                          newdatiania.kmAnnui = '3';
                        } else {
                          newdatiania.kmAnnui = '4';
                        }
                      }
                      //usedValue Valore Veicolo
                      //5: {domanda: 'Valore veicolo', risposta: '1000', sottodomanda1: '', sottorisposta1: '', sottodomanda2: '', …}
                      if (Number(PreCompile[5].risposta)) {
                        newdatiania.usedValue = Number(PreCompile[5].risposta);
                        newdatiania.infoCar[0].usedValue = Number(PreCompile[5].risposta)
                      }

                      //Incendio / Furto
                      //6: {domanda: 'Incendio / Furto', risposta: '', sottodomanda1: 'Capitale', sottorisposta1: '10000', sottodomanda2: 'Capitale', …}
                      if (Number(PreCompile[6].sottorisposta1) > 0) {
                        newdatiania.furto = true;
                      }

                      //7: {domanda: 'Collisione comunque verificatasi', risposta: '', sottodomanda1: 'Capitale', sottorisposta1: '', sottodomanda2: 'Capitale', …}
                      //8: {domanda: 'Collisione con altro veicolo identificato', risposta: '', sottodomanda1: 'Capitale', sottorisposta1: '', sottodomanda2: 'Capitale', …}
                      //9: {domanda: 'Collisione ungulati', risposta: '', sottodomanda1: 'Capitale', sottorisposta1: '', sottodomanda2: 'Capitale', …}
                      if (Number(PreCompile[7].sottorisposta1) > 0 || Number(PreCompile[8].sottorisposta1) > 0) {
                        newdatiania.collisione = true;
                      }
                      //10: {domanda: 'Eventi naturali', risposta: '', sottodomanda1: 'Capitale', sottorisposta1: '5000', sottodomanda2: 'Capitale', …}
                      if (Number(PreCompile[10].sottorisposta1) > 0) {
                        newdatiania.eventiNaturali = true;
                      }
                      //11: {domanda: 'Eventi sociopolitici', risposta: '', sottodomanda1: 'Capitale', sottorisposta1: '4000', sottodomanda2: 'Capitale', …}
                      //12: {domanda: 'Atti vandalici', risposta: '', sottodomanda1: 'Capitale', sottorisposta1: '3000', sottodomanda2: 'Capitale', …}

                      if (Number(PreCompile[11].sottorisposta1) > 0 || Number(PreCompile[12].sottorisposta1) > 0) {
                        newdatiania.attiVandalici = true;
                      }

                      //13: {domanda: 'Rottura cristalli', risposta: '', sottodomanda1: 'Capitale', sottorisposta1: '1000', sottodomanda2: 'Capitale', …}
                      if (Number(PreCompile[13].sottorisposta1) > 0) {
                        newdatiania.cristalli = true;
                      }
                      //14: {domanda: 'Infortuni del conducente', risposta: '', sottodomanda1: 'Capitale', sottorisposta1: '50000', sottodomanda2: 'Capitale', …}
                      if (Number(PreCompile[14].sottorisposta1) > 0) {
                        newdatiania.infortunioConducente = true;
                      }
                      //18: {domanda: 'Premio', risposta: '550', sottodomanda1: '', sottorisposta1: '', sottodomanda2: '', …}
                      if (Number(PreCompile[18].risposta) > 0) {
                        newdatiania.currentAward = Number(PreCompile[18].risposta);
                      }
                      //25: {domanda: 'Guida esperta', risposta: '1', sottodomanda1: '', sottorisposta1: '', sottodomanda2: '', …}
                      if (Number(PreCompile[25].risposta) === 1) {
                        newdatiania.guideType = 'ESP';
                      }
                      if (Number(PreCompile[25].risposta) === 2) {
                        newdatiania.guideType = 'LIB';
                      }

                      //26: {domanda: 'Guida esclusiva', risposta: '2', sottodomanda1: '', sottorisposta1: '', sottodomanda2: '', …}
                      //27: {domanda: 'Conducente sotto i 25 anni', risposta: '1', sottodomanda1: '', sottorisposta1: '', sottodomanda2: '', …}
                      if (Number(PreCompile[27].risposta) === 1) {
                        newdatiania.youngestLicensedAge = '24';
                      }

                      //28: {domanda: 'Rinuncia alla rivalsa', risposta: '1', sottodomanda1: '', sottorisposta1: '', sottodomanda2: '', …}
                      if (Number(PreCompile[28].risposta) === 1) {
                        newdatiania.revengeWaiver = true;
                      }
                      //29: {domanda: 'Assistenza', risposta: '1', sottodomanda1: '', sottorisposta1: '', sottodomanda2: '', …}
                      if (Number(PreCompile[29].risposta) === 1) {
                        newdatiania.assistenzaStradale = true;
                      }
                      //30: {domanda: 'Sospenzione patente', risposta: '', sottodomanda1: '', sottorisposta1: '', sottodomanda2: '', …}
                      //31: {domanda: 'Tutela legale', risposta: '1', sottodomanda1: '', sottorisposta1: '', sottodomanda2: '', …}
                      if (Number(PreCompile[31].risposta) === 1) {
                        newdatiania.tutelaLegale = true;
                      }
                    }

                    if (newdatiania.contractorInfo.vatNumber !== '') {
                      if (newdatiania.contractorInfo.vatNumber === newdatiania.ownerInfo.vatNumber) {
                        setSameOwner(true);
                      } else {
                        setSameOwner(false);
                      }
                    } else {
                      if (newdatiania.contractorInfo.fiscalCode === newdatiania.ownerInfo.fiscalCode) {
                        setSameOwner(true);
                      } else {
                        setSameOwner(false);
                      }
                    }

                    if (payload.data.errorCode === 2006 || payload.data.errorCode === 1006 || payload.data.errorCode === 3002 || payload.data.errorCode === 3001) {
                      setAlertMsg(payload.data.message);
                      setAlertSeverity("error");
                      setOpen(true);
                      setLoading(false);
                      return;
                    }

                    if (payload.data.error !== undefined) {
                      setAlertMsg("Non è stato possibile contattare ANIA, inserire i dati mancanti");
                      setAlertSeverity("warning");
                      setOpen(true);
                      setLoading(false);
                      setdatiania(newdatiania);
                      setActiveStep((prevActiveStep) => prevActiveStep + 1);
                      setLoading(false);
                    } else {
                      /* MICK da scommentare*/
                      if (moment().add(DAYS_BEFORE_QUOTATION, "days") <= moment(payload.data.effectiveDate, "YYYY-MM-DD")) {
                        setAlertMsg(<div>Questo veicolo risulta già assicurato con data di scadenza: {moment(payload.data.effectiveDate, "YYYY-MM-DD").format("DD-MM-YYYY")}<br />Non è possibile quotare assicurazioni con data di scadenza superiore al {moment().add(DAYS_BEFORE_QUOTATION, "days").format("DD-MM-YYYY")}</div>);
                        setAlertSeverity("error");
                        setOpen(true);
                        setLoading(false);
                        return;
                      }

                      if (payload.data.vehicleType !== "A" && payload.data.vehicleType !== "") {
                        setAlertMsg("Non è possibile quotare tipi veicoli diversi da Autovettura");
                        setAlertSeverity("error");
                        setOpen(true);
                        setLoading(false);
                        return;
                      }

                      if (payload.data.use !== "P") {
                        setAlertMsg("Non è possibile quotare veicoli con uso diverso da PROPRIO");
                        setAlertSeverity("error");
                        setOpen(true);
                        setLoading(false);
                        return;
                      }

                      /*Se il sesso è vuoto allora è un'azienda
                        solo se il fiscal code è compilato
                        altrimenti significa che non ho ricevuto dati da Sicania e lo imposto di default su privato
                      */
                      if ((newdatiania.contractorInfo.gender === null || newdatiania.contractorInfo.gender === "") && newdatiania.contractorInfo.fiscalCode !== "") {
                        newdatiania.contractorInfo.subjectType = "4";
                        newdatiania.guideType = "LIB";
                      }

                      getModels(token, urlservicequotiamo, newdatiania.brand).then(payload => {
                        setlistModels(payload.data);
                      });

                      getOutfitting(token, urlservicequotiamo, newdatiania.brand, newdatiania.infoCar[0].model, newdatiania.homologationCode, newdatiania.registrationDate).then(payload => {
                        setlistOutfitting(payload.data);
                      });

                      if (tipoprev !== "RIN") {
                        newdatiania.contractorInfo.subjectType = "1";
                        newdatiania.contractorInfo.vatNumber = "";
                        newdatiania.contractorInfo.companyName = "";
                        newdatiania.contractorInfo.fiscalCode = "";
                        newdatiania.contractorInfo.surname = "";
                        newdatiania.contractorInfo.name = "";
                        newdatiania.contractorInfo.birthDate = "";
                        newdatiania.contractorInfo.birthCity = "";
                        newdatiania.contractorInfo.birthProvince = "";
                        newdatiania.contractorInfo.birthRegion = "";
                        newdatiania.contractorInfo.birthNationAbbr = "";
                        newdatiania.contractorInfo.birthNation = "";
                        newdatiania.contractorInfo.residenceInfo.cap = "";
                        newdatiania.contractorInfo.residenceInfo.city = "";
                        newdatiania.contractorInfo.residenceInfo.province = "";
                        newdatiania.contractorInfo.residenceInfo.provinceAbbr = "";
                        //SBIANCARE DATI ATRC
                        //QUI SE HO LA SECONDA TARGA

                        let alimentazione = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).auto_alimentazione !== undefined && JSON.parse(posinfo.defAg).auto_alimentazione !== "" ? JSON.parse(posinfo.defAg).auto_alimentazione : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).auto_alimentazione !== undefined && JSON.parse(posinfo.defGrp).auto_alimentazione !== "" ? JSON.parse(posinfo.defGrp).auto_alimentazione : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).auto_alimentazione !== undefined && JSON.parse(posinfo.defOrg).auto_alimentazione !== "" ? JSON.parse(posinfo.defOrg).auto_alimentazione : "ania")));
                        let supply = "";
                        switch (alimentazione) {
                          case "ania":
                            break;
                          case "vuoto":
                            supply = "N";
                            break;
                          default:
                            break;
                        }

                        if (targaoption !== "") {
                          let checkOnlyAtrc = true;
                          /*if(tipoprev==="VOL" && prevoption==="NO"){
                            checkOnlyAtrc=false;
                          }*/
                          let extraFiscalCode = "";

                          if (prevoption === "NO") {
                            extraFiscalCode = datiania.fiscalCode;
                          }

                          checkSicAnia(datiCollegamentoAnia.type, targaoption, token, urlservicequotiamo, datiCollegamentoAnia.username, datiCollegamentoAnia.password, datiCollegamentoAnia.code, "RIN", checkOnlyAtrc, extraFiscalCode, datiCollegamentoAnia.sharetoken).then(
                            payload => {
                              const newdatianiaoption = payload.data;
                              if (payload.data.errorCode === 2006 || payload.data.errorCode === 1006) {
                                let msg = prevoption === "BE" ? "Targa Veicolo legge Bersani" :
                                  prevoption === "FA" ? "Targa Veicolo decreto familiare" : "Targa vecchio veicolo (se vuoi importare CU)";

                                setAlertMsg(msg + " : " + payload.data.message);
                                setAlertSeverity("error");
                                setOpen(true);
                                setLoading(false);
                                return;
                              }

                              if (prevoption !== "FA") {
                                if (payload.data.vehicleType !== "A" && payload.data.vehicleType !== "") {
                                  if (prevoption === "BE") {
                                    setAlertMsg("Per usufruire della legge Bersani, il secondo veicolo deve essere una autovettura");
                                  } else {
                                    setAlertMsg("Per importare la CU, il secondo veicolo deve essere una autovettura");
                                  }
                                  setAlertSeverity("error");
                                  setOpen(true);
                                  setLoading(false);
                                  return;
                                }
                              }

                              if (payload.data.error !== undefined) {
                                setLoading(false);
                                setAlertMsg(payload.data.message);
                                setAlertSeverity("error");
                                setOpen(true);
                                return;
                              }

                              if (prevoption === "NO") {
                                newdatiania.dataATRC = newdatianiaoption.dataATRC;
                                newdatiania.listDataCARD = newdatianiaoption.listDataCARD;
                              }

                              newdatiania.insuranceCompany = newdatianiaoption.insuranceCompany;
                              newdatiania.cuAssignment = newdatianiaoption.cuAssignment;
                              newdatiania.cuOrigin = newdatianiaoption.cuOrigin;
                              newdatiania.declarationOrigin = newdatianiaoption.declarationOrigin;

                              if (tipoprev === "VOL" && prevoption === "NO") {
                                newdatiania.contractorInfo.subjectType = newdatianiaoption.contractorInfo.subjectType;
                                newdatiania.contractorInfo.vatNumber = newdatianiaoption.contractorInfo.vatNumber;
                                newdatiania.contractorInfo.companyName = newdatianiaoption.contractorInfo.companyName;
                                newdatiania.contractorInfo.fiscalCode = newdatianiaoption.contractorInfo.fiscalCode;
                                newdatiania.contractorInfo.surname = newdatianiaoption.contractorInfo.surname;
                                newdatiania.contractorInfo.name = newdatianiaoption.contractorInfo.name;
                                newdatiania.contractorInfo.birthDate = newdatianiaoption.contractorInfo.birthDate;
                                newdatiania.contractorInfo.birthCity = newdatianiaoption.contractorInfo.birthCity;
                                newdatiania.contractorInfo.birthProvince = newdatianiaoption.contractorInfo.birthProvince;
                                newdatiania.contractorInfo.birthRegion = newdatianiaoption.contractorInfo.birthRegion;
                                newdatiania.contractorInfo.birthNationAbbr = newdatianiaoption.contractorInfo.birthNationAbbr;
                                newdatiania.contractorInfo.birthNation = newdatianiaoption.contractorInfo.birthNation;
                                newdatiania.contractorInfo.gender = newdatianiaoption.contractorInfo.gender;
                                newdatiania.contractorInfo.residenceInfo.cap = newdatianiaoption.contractorInfo.residenceInfo.cap;
                                newdatiania.contractorInfo.residenceInfo.city = newdatianiaoption.contractorInfo.residenceInfo.city;
                                newdatiania.contractorInfo.residenceInfo.istat = newdatianiaoption.contractorInfo.residenceInfo.istat;
                                newdatiania.contractorInfo.residenceInfo.province = newdatianiaoption.contractorInfo.residenceInfo.province;
                                newdatiania.contractorInfo.residenceInfo.provinceAbbr = newdatianiaoption.contractorInfo.residenceInfo.rovinceAbbr;
                              }

                              newdatiania.effectiveDate = moment().format("YYYY-MM-DD");
                              newdatiania.policyExpirationDate = newdatianiaoption.policyExpirationDate;

                              setdatiania({
                                ...newdatiania,
                                outfitting: newdatiania.infoCar[0].outfitting,
                                newValue: newdatiania.infoCar[0].newValue,
                                usedValue: newdatiania.infoCar[0].usedValue,
                                model: newdatiania.infoCar[0].model,
                                carBody: newdatiania.infoCar[0].carBody,
                                power: newdatiania.infoCar[0].power,
                                displacement: newdatiania.infoCar[0].displacement,
                                horsePower: newdatiania.infoCar[0].horsePower,
                                supply: supply === "" ? newdatiania.infoCar[0].supply : supply,
                                codiceMotornet: newdatiania.infoCar[0].codiceMotornet,
                                drivingLicenseMonth: newdatiania.contractorInfo.birthDate !== "" ? moment(newdatiania.contractorInfo.birthDate, "YYYY-MM-DD").add(18, "years").add(2, "months").format("M") : "",
                                drivingLicenseYear: newdatiania.contractorInfo.birthDate !== "" ? moment(newdatiania.contractorInfo.birthDate, "YYYY-MM-DD").add(18, "years").add(2, "months").format("YYYY") : "",
                                insuranceYears: ReturnDefaultinsuranceYears(posinfo, newdatiania, newdatiania.contractorInfo.birthDate !== "" ? moment(newdatiania.contractorInfo.birthDate, "YYYY-MM-DD").add(18, "years").add(2, "months").format("YYYY") : ""),
                                currentAward: "",
                                extraFiscalCode: newdatianiaoption.contractorInfo.fiscalCode,
                                nSons1: "",
                                nSons2: "",
                                nSons3: "",
                                nSons4: ""
                              });

                              setActiveStep((prevActiveStep) => prevActiveStep + 1);
                              setLoading(false);

                            }).catch(error => {
                              //console.log(error)
                              alert("QUOTIAMO service non abilitato o non raggiungibile (2)");
                              setLoading(false);
                            }
                            );

                        } else {
                          var checkDate = moment(newdatiania.effectiveDate, "YYYY-MM-DD", true);
                          if (checkDate.isValid() === false) {
                            newdatiania.effectiveDate = moment().format("YYYY-MM-DD");
                          }

                          setdatiania({
                            ...newdatiania,
                            outfitting: newdatiania.infoCar[0].outfitting,
                            newValue: newdatiania.infoCar[0].newValue,
                            usedValue: newdatiania.infoCar[0].usedValue,
                            model: newdatiania.infoCar[0].model,
                            carBody: newdatiania.infoCar[0].carBody,
                            power: newdatiania.infoCar[0].power,
                            displacement: newdatiania.infoCar[0].displacement,
                            horsePower: newdatiania.infoCar[0].horsePower,
                            supply: supply === "" ? newdatiania.infoCar[0].supply : supply,
                            codiceMotornet: newdatiania.infoCar[0].codiceMotornet,
                            drivingLicenseMonth: newdatiania.contractorInfo.birthDate !== "" ? moment(newdatiania.contractorInfo.birthDate, "YYYY-MM-DD").add(18, "years").add(2, "months").format("M") : "",
                            drivingLicenseYear: newdatiania.contractorInfo.birthDate !== "" ? moment(newdatiania.contractorInfo.birthDate, "YYYY-MM-DD").add(18, "years").add(2, "months").format("YYYY") : "",
                            extraFiscalCode: "",
                            nSons1: "",
                            nSons2: "",
                            nSons3: "",
                            nSons4: ""
                          });

                          setActiveStep((prevActiveStep) => prevActiveStep + 1);
                          setLoading(false);
                        }
                      } else {
                        let alimentazione = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).auto_alimentazione !== undefined && JSON.parse(posinfo.defAg).auto_alimentazione !== "" ? JSON.parse(posinfo.defAg).auto_alimentazione : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).auto_alimentazione !== undefined && JSON.parse(posinfo.defGrp).auto_alimentazione !== "" ? JSON.parse(posinfo.defGrp).auto_alimentazione : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).auto_alimentazione !== undefined && JSON.parse(posinfo.defOrg).auto_alimentazione !== "" ? JSON.parse(posinfo.defOrg).auto_alimentazione : "ania")));
                        let supply = "";
                        switch (alimentazione) {
                          case "ania":
                            break;
                          case "vuoto":
                            supply = "N";
                            break;
                          default:
                            break;
                        }

                        setdatiania({
                          ...newdatiania,
                          outfitting: newdatiania.infoCar[0].outfitting,
                          newValue: newdatiania.infoCar[0].newValue,
                          usedValue: newdatiania.infoCar[0].usedValue,
                          model: newdatiania.infoCar[0].model,
                          carBody: newdatiania.infoCar[0].carBody,
                          power: newdatiania.infoCar[0].power,
                          displacement: newdatiania.infoCar[0].displacement,
                          horsePower: newdatiania.infoCar[0].horsePower,
                          supply: supply === "" ? newdatiania.infoCar[0].supply : supply,
                          codiceMotornet: newdatiania.infoCar[0].codiceMotornet,
                          drivingLicenseMonth: newdatiania.contractorInfo.birthDate !== "" ? moment(newdatiania.contractorInfo.birthDate, "YYYY-MM-DD").add(18, "years").add(2, "months").format("M") : "",
                          drivingLicenseYear: newdatiania.contractorInfo.birthDate !== "" ? moment(newdatiania.contractorInfo.birthDate, "YYYY-MM-DD").add(18, "years").add(2, "months").format("YYYY") : "",
                          extraFiscalCode: "",
                          nSons1: "",
                          nSons2: "",
                          nSons3: "",
                          nSons4: ""
                        });

                        setActiveStep((prevActiveStep) => prevActiveStep + 1);
                        setLoading(false);
                      }
                    }
                  }).catch(error => {
                    console.log("ERRORE ANIA", error);
                    alert("QUOTIAMO service non abilitato o non raggiungibile (1)");
                    setLoading(false);
                  }
                  );
              }).catch(error => {
                alert("QUOTIAMO service non abilitato o non raggiungibile (3)");
                setLoading(false);
              }
              );
          }
        ).catch(error => {
          setAlertMsg("Prodotto non attivo per questo punto vendita");
          setAlertSeverity("error");
          setOpen(true);
          return;
        });

        break;
      case 1:
        if (datiania.contractorInfo.residenceInfo.toponym === "" ||
          datiania.contractorInfo.residenceInfo.address === "" ||
          datiania.contractorInfo.residenceInfo.streetNumber === "" ||
          datiania.contractorInfo.residenceInfo.city === "" ||
          datiania.contractorInfo.residenceInfo.cap === "" ||
          datiania.contractorInfo.residenceInfo.provinceAbbr === ""
        ) {
          setAlertMsg("Dati indirizzo contraente non completi");
          setAlertSeverity("error");
          setOpen(true);
          break;
        }
        if (datiania.contractorInfo.residenceInfo.provinceAbbr.length > 2) {
          setAlertMsg("Sigla Provincia contraente massimo 2 lettere");
          setAlertSeverity("error");
          setOpen(true);
          break;
        }

        if (datiania.drivingLicenseYear === undefined) datiania.drivingLicenseYear = "";
        if (datiania.drivingLicensePoints === undefined) datiania.drivingLicensePoints = "";

        /*console.log("TYPE:"+ datiania.subjectType);*/
        //console.log(datiania);

        if (datiania.contractorInfo.subjectType === "" || datiania.contractorInfo.subjectType === "1") {
          if (datiania.contractorInfo.name === "" ||
            datiania.contractorInfo.surname === "" ||
            datiania.contractorInfo.birthDate === "" ||
            datiania.contractorInfo.gender === "" ||
            datiania.contractorInfo.fiscalCode === "" ||
            datiania.contractorInfo.profession === "" ||
            datiania.contractorInfo.qualification === 0 ||
            datiania.contractorInfo.maritalStatus === 0 ||
            datiania.contractorInfo.maritalStatus === "" ||
            datiania.contractorInfo.qualification === "" ||
            datiania.contractorInfo.profession === undefined ||
            datiania.contractorInfo.qualification === undefined ||
            datiania.contractorInfo.maritalStatus === undefined ||
            datiania.contractorInfo.birthDate === undefined
          ) {
            if (datiania.contractorInfo.birthDate === undefined || datiania.contractorInfo.birthDate === "") {
              setAlertMsg("Codice fiscale contraente errato");
            } else {
              setAlertMsg("Dati contraente non completi");
            }
            setAlertSeverity("error");
            setOpen(true);
            break;
          }

          //if (datiania.drivingLicenseYear==="" || datiania.drivingLicensePoints==="" || datiania.drivingLicenseMonth==="" ){
          /*if (datiania.drivingLicenseYear===""){
            setAlertMsg("I dati della patente non sono completi");
            setAlertSeverity("error");                                            
            setOpen(true);
            break;
          }*/

          if (!Number.isInteger(Number(datiania.nSons1))) {
            datiania.nSons1 = 0;
          }
          if (!Number.isInteger(Number(datiania.nSons2))) {
            datiania.nSons2 = 0;
          }
          if (!Number.isInteger(Number(datiania.nSons3))) {
            datiania.nSons3 = 0;
          }
          if (!Number.isInteger(Number(datiania.nSons4))) {
            datiania.nSons4 = 0;
          }

          if (datiania.sons === "") {
            setAlertMsg("Rispondi alla domanda Hai figli?");
            setAlertSeverity("error");
            setOpen(true);
            break;
          }

          if (datiania.sons === "true" &&
            (Number(datiania.nSons1) + Number(datiania.nSons2) + Number(datiania.nSons3) + Number(datiania.nSons4)) === 0
          ) {
            setAlertMsg("Specificare il numero di figli");
            setAlertSeverity("error");
            setOpen(true);
            break;
          }

        } else {
          if (datiania.contractorInfo.vatNumber === undefined) datiania.contractorInfo.vatNumber = "";
          if (datiania.contractorInfo.companyName === undefined) datiania.contractorInfo.companyName = "";

          if (datiania.contractorInfo.vatNumber === "" ||
            datiania.contractorInfo.companyName === ""
          ) {
            setAlertMsg("Dati contraente non completi");
            setAlertSeverity("error");
            setOpen(true);
            break;
          }
        }

        if (datiania.contractorInfo.email === undefined) datiania.contractorInfo.email = "";
        if (!validator.isEmail(datiania.contractorInfo.email)) {
          setAlertMsg("Email contraente non valida");
          setAlertSeverity("error");
          setOpen(true);
          break;
        }
        if (datiania.contractorInfo.phoneNumber === undefined) datiania.contractorInfo.phoneNumber = "";

        var pattern = new RegExp(/^[0-9\b]+$/);

        if (datiania.contractorInfo.phoneNumber === "" || datiania.contractorInfo.phoneNumber.length < 10 || !(validator.isMobilePhone(datiania.contractorInfo.phoneNumber, 'it-IT') /*|| pattern.test(datiania.contractorInfo.phoneNumber)*/)) {
          setAlertMsg("Numero cellulare contraente non valido");
          setAlertSeverity("error");
          setOpen(true);
          break;
        }
        /*
         
      phoneNumber:datiania.phoneNumber,
      email:datiania.email,
      fiscalCode:datiania.fiscalCode,
      vatNumber:datiania.vatNumber,
      personType:(datiania.subjectType==="1" || datiania.subjectType===undefined) ? "FIS":"GIU",
      residenceInfo:{
          toponym:datiania.toponym,
          address:datiania.address,
          streetNumber:datiania.streetNumber,
          city:datiania.city,
          cap:datiania.cap,			          
          provinceAbbr:datiania.provinceAbbr,			          
      },
        */
        //MICK

        if (sameOwner) {
          setdatiania({
            ...datiania,
            ownerInfo: {
              ...datiania.contractorInfo,
            },
          });
        }
        if (sameDriver) {
          setdatiania({
            ...datiania,
            driverInfo: {
              ...datiania.contractorInfo,
            }
          });
        }

        if (sameOwner === false) {
          if (datiania.ownerInfo.residenceInfo.toponym === "" ||
            datiania.ownerInfo.residenceInfo.address === "" ||
            datiania.ownerInfo.residenceInfo.streetNumber === "" ||
            datiania.ownerInfo.residenceInfo.city === "" ||
            datiania.ownerInfo.residenceInfo.cap === "" ||
            datiania.ownerInfo.residenceInfo.provinceAbbr === ""
          ) {
            setAlertMsg("Dati indirizzo intestatario non completi");
            setAlertSeverity("error");
            setOpen(true);
            break;
          }

          if (datiania.ownerInfo.residenceInfo.provinceAbbr.length > 2) {
            setAlertMsg("Sigla Provincia intestatario massimo 2 lettere");
            setAlertSeverity("error");
            setOpen(true);
            break;
          }

          if (datiania.ownerInfo.subjectType === "" || datiania.ownerInfo.subjectType === "1") {
            if (datiania.ownerInfo.name === "" ||
              datiania.ownerInfo.surname === "" ||
              datiania.ownerInfo.birthDate === "" ||
              datiania.ownerInfo.gender === "" ||
              datiania.ownerInfo.fiscalCode === "" ||
              datiania.ownerInfo.qualification === 0 ||
              datiania.ownerInfo.maritalStatus === 0 ||
              datiania.ownerInfo.maritalStatus === "" ||
              datiania.ownerInfo.qualification === "" ||
              datiania.ownerInfo.profession === undefined ||
              datiania.ownerInfo.qualification === undefined ||
              datiania.ownerInfo.maritalStatus === undefined ||
              datiania.ownerInfo.birthDate === undefined
            ) {
              if (datiania.ownerInfo.birthDate === undefined || datiania.ownerInfo.birthDate === "") {
                setAlertMsg("Codice fiscale intestatario errato");
              } else {
                setAlertMsg("Dati intestatario non completi");
              }
              setAlertSeverity("error");
              setOpen(true);
              break;
            }
          } else {
            if (datiania.ownerInfo.vatNumber === undefined) datiania.ownerInfo.vatNumber = "";
            if (datiania.ownerInfo.companyName === undefined) datiania.ownerInfo.companyName = "";

            if (datiania.ownerInfo.vatNumber === "" || datiania.ownerInfo.companyName === "") {
              setAlertMsg("Dati intestatario non completi");
              setAlertSeverity("error");
              setOpen(true);
              break;
            }
          }

          if (datiania.ownerInfo.email === undefined) datiania.ownerInfo.email = "";
          if (!validator.isEmail(datiania.ownerInfo.email)) {
            setAlertMsg("Email intestatario non valida");
            setAlertSeverity("error");
            setOpen(true);
            break;
          }

          if (datiania.ownerInfo.phoneNumber === undefined) datiania.ownerInfo.phoneNumber = "";
          var pattern = new RegExp(/^[0-9\b]+$/);
          if (datiania.ownerInfo.phoneNumber === "" || datiania.ownerInfo.phoneNumber.length < 10 || !(validator.isMobilePhone(datiania.ownerInfo.phoneNumber, 'it-IT') /*|| pattern.test(datiania.contractorInfo.phoneNumber)*/)) {
            setAlertMsg("Numero cellulare intestatario non valido");
            setAlertSeverity("error");
            setOpen(true);
            break;
          }
        }

        if (datiania.contractorInfo.subjectType === "" || datiania.contractorInfo.subjectType === "1") {
          getInfoBirth(token, urlservicequotiamo, datiania.contractorInfo.fiscalCode, datiania.contractorInfo.name, datiania.contractorInfo.surname).then(
            payload => {
              if (payload.data.status !== undefined) {
                setAlertMsg(payload.data.message);
                setAlertSeverity("error");
                setOpen(true);
                return;
              } else {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
              }
            }
          );
        } else {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        break;
      case 2:
        if (isNaN(datiania.usedValue) || Number(datiania.usedValue) <= 0) {
          setAlertMsg("Il valore del veicolo deve essere > 0");
          setAlertSeverity("error");
          setOpen(true);
          break;
        }
        if (isNaN(datiania.newValue) === false) {
          if (Number(datiania.newValue) > 0 && Number(datiania.usedValue) > Number(datiania.newValue)) {
            setAlertMsg("Il valore del veicolo non può essere maggiore del prezzo di listino");
            setAlertSeverity("error");
            setOpen(true);
            break;
          }
        }

        if (datiania.model === "" ||
          datiania.brand === "" ||
          datiania.outfitting === "") {
          setAlertMsg("Inserire marca, modello e allestimento");
          setAlertSeverity("error");
          setOpen(true);
          break;
        }

        var checkDate = moment(datiania.registrationDate, "YYYY-MM-DD", true);
        if (checkDate.isValid() === false) {
          setAlertMsg("Data immatricolazione non valida");
          setAlertSeverity("error");
          setOpen(true);
          break;
        }

        checkDate = moment(datiania.purchaseDate, "YYYY-MM-DD", true);
        if (checkDate.isValid() === false) {
          setAlertMsg("Data di acquisto non valida");
          setAlertSeverity("error");
          setOpen(true);
          break;
        }

        //newdatiania.effectiveDate=moment().format("YYYY-MM-DD");
        //newdatiania.registrationDate=moment().format("YYYY-MM-DD");
        //newdatiania.purchaseDate=moment().format("YYYY-MM-DD");

        if (datiania.kmAnnui === '0' || datiania.kmAnnui === null || datiania.kmAnnui === undefined || datiania.kmAnnui === "") {
          setAlertMsg("Impostare i km annui percorsi");
          setAlertSeverity("error");
          setOpen(true);
          break;
        }

        if (datiania.supply === null || datiania.supply === undefined || datiania.supply === "" || datiania.supply === "N") {
          setAlertMsg("Specificare l'alimentazione");
          setAlertSeverity("error");
          setOpen(true);
          break;
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        break;
      case 3:
        if (isNaN(datiania.insuranceYears) || datiania.insuranceYears === "") {
          setAlertMsg("Inserire numero di anni (0 se mai assicurato)");
          setAlertSeverity("error");
          setOpen(true);
          break;
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        break;
      case 4:
        if (guarantees.length < 1) {
          setAlertMsg("Nessuna garanzia selezionabile, contattare l'admin di sistema");
          setAlertSeverity("error");
          setOpen(true);
          break;
        }

        if (datiania.effectiveDate === null || datiania.effectiveDate > moment().add(1, "months").endOf('month').format("YYYY-MM-DD")) {
          setAlertMsg("La data di decorrenza della polizza non può essere maggiore del " + moment().add(1, "months").endOf('month').format("DD-MM-YYYY"));
          setAlertSeverity("error");
          setOpen(true);
          break;
        }

        if (datiania.effectiveDate === null || datiania.effectiveDate < moment().format("YYYY-MM-DD")) {
          setAlertMsg("Il preventivo non può essere retroattivo, la data di decorrenza della polizza non può essere minore di " + moment().format("DD-MM-YYYY"));
          setAlertSeverity("error");
          setOpen(true);
          break;
        }

        if (datiania.guideType === "" || datiania.guideType === "0" || datiania.guideType === undefined) {
          setAlertMsg("Selezionare il tipo di guida");
          setAlertSeverity("error");
          setOpen(true);
          break;
        }

        if (datiania.maximal === "" || datiania.maximal === "0" || datiania.maximal === undefined) {
          setAlertMsg("Selezionare il massimale");
          setAlertSeverity("error");
          setOpen(true);
          break;
        }

        if (datiania.youngestLicensedAge === "" || datiania.youngestLicensedAge === "0" || datiania.youngestLicensedAge === undefined) {
          setAlertMsg("Impostare l'età del guidatore più giovane");
          setAlertSeverity("error");
          setOpen(true);
          break;
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);

        setdatipreventivo([]);

        let array_remove = [];

        AuthService.signin(posinfo.username, posinfo.password, posinfo.url).then(
          payload => {
            setToken(payload);

            checkcompanyData(Auth.getToken(), pos).then(
              payload => {
                let companyListPrev = payload.data.services;
                let conventionslist = payload.data.conventions;
                let companyOriginCode = [];

                if (datiania.insuranceCompany !== undefined) {
                  companyOriginCode = datiania.insuranceCompany.split("-");
                }

                let companyOrigin = 0;
                let companyOriginString = "";

                if (companyOriginCode.length > 1) {
                  companyOrigin = Number(companyOriginCode[0]);
                  companyOriginString = companyOriginCode[0];
                }
                let conta = 0;

                if (companyOrigin !== 0 && tipoprev === "RIN") {
                  let newCompanyListPrev = [];
                  companyListPrev.forEach(function (company) {
                    let removeCompany = false;
                    switch (company.paramurl) {
                      case "allianz":
                        if (companyOrigin === 3 /*|| companyOrigin === 139 || companyOrigin === 352 || companyOrigin === 346*/) {
                          removeCompany = true;
                        }
                        break;
                      case "axa":
                        if (companyOrigin === 14 || companyOrigin === 243) {
                          removeCompany = true;
                        }
                        break;
                      case "amissima":
                      case "hdi":
                        if (companyOrigin === 86 || companyOrigin === 133) {
                          removeCompany = true;
                        }
                        break;
                      case "conte":
                        /*if (orgid !== 37) {
                          if (companyOrigin === 911) {
                            removeCompany = true;
                          }
                        }*/
                        break;
                      case "linear":
                        if (companyOrigin === 416) {
                          removeCompany = true;
                        }
                        break;
                      case "generali":
                        /*
                        57  CATTOLICA 
                        418 BCC ASSICURAZIONI 
                        5 Generali
                        */
                        if (companyOrigin === 57 || companyOrigin === 418 || companyOrigin === 5) {
                          removeCompany = true;
                        }
                        /*if(companyOrigin===5){
                          removeCompany=conta;                                
                        }*/
                        break;
                      //MICK DA SCOMMENTARE
                      case "groupama":
                      case "groupamanexus":
                        if (companyOrigin === 151) {
                          removeCompany = true;
                        }
                        break;
                      case "nobis":
                        if (companyOrigin === 474) {
                          removeCompany = true;
                        }
                        break;
                      case "prima":
                        //<option value="899">GREAT LAKES INSURANCE SE</option>
                        //<option value="671">IPTIQ EMEA P&amp;C SA</option>							
                        //<option value="970">IPTIQ EMEA P&amp;C SA DE</option>
                        //<option value="955">WAKAM</option>
                        //NOn serve il blocco se proviene dalla stessa compagnia
                        /*if(companyOrigin===899 || companyOrigin===671|| companyOrigin===970 || companyOrigin===955){
                          removeCompany=conta;                                
                        }*/
                        break;
                      case "quixa":
                      case "quixap":
                      case "quixapoint":
                        if (companyOrigin === 440) {
                          removeCompany = true;
                        }
                        break;
                      case "tua":
                        /*
                        429 TUA 
                        57  CATTOLICA 
                        418 BCC ASSICURAZIONI 
                        5 Generali
                        */
                        if (companyOrigin === 429) {
                          removeCompany = true;
                        }
                        break;
                      case "unipolsai":
                        if (companyOrigin === 34) {
                          removeCompany = true;
                        }
                        break;
                      case "dallbogg":
                        if (companyOrigin === 920) {
                          removeCompany = true;
                        }
                        break;
                      case "adriaticg":
                      case "adriatic":
                        if (companyOrigin === 667) {
                          removeCompany = true;
                        }
                        break;
                      case "genialpiu":
                        if (companyOrigin === 139) {
                          removeCompany = true;
                        }
                        break;
                      case "realemutua":
                        if (companyOrigin === 444 || companyOrigin === 35 || companyOrigin === 9922) {
                          removeCompany = true;
                        }
                        break;
                      case "zurichconnect":
                      case "zurich":
                        if (companyOrigin === 198 || companyOrigin === 40 || companyOrigin === 864) {
                          removeCompany = true;
                        }
                        break;
                      case "bene":
                        if (companyOrigin === 486) {
                          removeCompany = true;
                        }
                        break;
                      default:
                        break;
                    }
                    conta++;
                    if (!removeCompany) {
                      newCompanyListPrev.push(company)
                    }
                  });
                  companyListPrev = newCompanyListPrev;
                }
                conta = 0;
                companyListPrev.forEach(function (company) {
                  let tempGuar = company.guarantees_info;
                  if (orgid === 1 && posinfo.groupid !== 8) {
                    tempGuar.forEach((element, index) => {
                      if (element.id === 1) {
                        company.guarantees_info.splice(index, 1);
                      }
                    });
                  }

                  switch (company.paramurl) {
                    case "hdi":
                    case "amissima":
                      /*if ((company.orgid === 1 || company.orgid === 5) && elencoProvinceConvenzione.indexOf(datiania.contractorInfo.residenceInfo.provinceAbbr) === -1) {
                        array_remove.push(conta);
                      }*/
                      break;
                    case "conte":
                      if (datiania.contractorInfo.subjectType === "" || datiania.contractorInfo.subjectType === "1") {
                        if (datiania.drivingLicenseType === "0" || datiania.drivingLicenseYear === "" || datiania.drivingLicensePoints === "" || datiania.drivingLicenseMonth === "") {
                          array_remove.push(conta);
                        }
                      }
                      break;
                    case "generali":
                      break;
                    case "groupama":
                      break;
                    case "nobis":
                      break;
                    case "prima":
                      if (datiania.contractorInfo.subjectType === "" || datiania.contractorInfo.subjectType === "1") {
                        if (datiania.drivingLicenseYear === "") {
                          array_remove.push(conta);
                        }
                      }
                      break;
                    case "quixa":
                      break;
                    case "tua":
                      break;
                    case "unipolsai":
                      break;
                    default:
                      break;
                  }
                  conta++;
                });

                array_remove.sort((a, b) => b - a);
                array_remove.forEach(function (index) {
                  companyListPrev.splice(index, 1);
                });

                // RIMUOVO COMPAGNIE SOLO CVT SE VOGLIO LE CVT DELLE COMPAGNIE
                conta = 0;
                array_remove = [];
                companyListPrev.forEach(function (company) {
                  if (useCvtComp === "yes") {
                    if (company.only_cvt === 1) {
                      array_remove.push(conta);
                    }
                  }
                  conta++;
                });
                array_remove.sort((a, b) => b - a);
                array_remove.forEach(function (index) {
                  companyListPrev.splice(index, 1);
                });

                setcompanyData(companyListPrev);
                setFactoriesHub(payload.data.factorieshub);
                let AddOnService = [];

                setPrevRS(0);
                setPrevRQ(companyListPrev.length);

                contaPreventiviDaFare = companyListPrev.length;
                contaPreventiviFatti = 0;

                if (sameOwner) {
                  datiania.ownerInfo = datiania.contractorInfo;
                }
                if (sameDriver) {
                  datiania.driverInfo = datiania.contractorInfo;
                }

                // controllo per quale tipologia di calcolo preventivo
                let attesaPrev = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).attesaPrevCar !== undefined ? JSON.parse(posinfo.defAg).attesaPrevCar : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).attesaPrevCar !== undefined ? JSON.parse(posinfo.defGrp).attesaPrevCar : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).attesaPrevCar !== undefined ? JSON.parse(posinfo.defOrg).attesaPrevCar : "waiting")));
                if (attesaPrev === "background") {
                  salvataggioPreventivo(companyListPrev).then(
                    payload => {
                      companyListPrev.map((company) => (
                        checkPreventivoQueue(tipoprev, targa, prevoption, targaoption, datiania, company, token, companyOriginString, conventionslist, guarantees, guaranteesSelect, guaranteesValue, Auth.getToken(), useCvtComp, payload.data.numberquotation).then(
                          payload => {
                            setPrevRS(prevCount => prevCount + 1);
                            contaPreventiviFatti++;

                            if (contaPreventiviFatti === contaPreventiviDaFare && contaPreventiviDaFare > 0) {
                              setAlertMsg("Inserimento dati avvenuto con successo, i preventivi saranno presto disponibili");
                              setAlertSeverity("success");
                              setOpen(true);
                            }

                            if (prevRQ === prevRS) {
                              setLoading(false);
                              setBackground(true);
                            }
                          }).catch(error => {
                            setPrevRS(prevCount => prevCount + 1);
                            contaPreventiviFatti++;
                            if (prevRQ === prevRS)
                              setLoading(false);
                          })
                      ));
                    }).catch(error => {
                      setPrevRS(prevCount => prevCount + 1);
                      contaPreventiviFatti++;
                      if (prevRQ === prevRS)
                        setLoading(false);
                    }
                    );
                } else {
                  companyListPrev.map((company) => (
                    checkPreventivo(tipoprev, targa, prevoption, targaoption, datiania, company, token, companyOriginString, conventionslist, guarantees, guaranteesSelect, guaranteesValue, Auth.getToken(), useCvtComp).then(
                      payload => {
                        const newDatiPreventivo = datipreventivo;

                        if (payload.data.prices !== undefined) {
                          payload.data.prices.forEach(element => {
                            let forceMessage = false;
                            if (company.paramurl === "groupama") {
                              let numerosinistri = 0;
                              let maxanno = datiania.dataATRC.anno0Atrc - 2013;
                              for (let i = 0; i <= maxanno; i++) {
                                if (!isNaN(ReturnInfoSinistriAnno(datiania, i, 0))) {
                                  numerosinistri += Number(ReturnInfoSinistriAnno(datiania, i, 0));
                                }
                                if (!isNaN(ReturnInfoSinistriAnno(datiania, i, 4))) {
                                  numerosinistri += Number(ReturnInfoSinistriAnno(datiania, i, 4));
                                }
                              }
                              if (numerosinistri >= 2) {
                                forceMessage = true;
                              }
                            }

                            let convenzioneAttiva = CheckConvenzioni(datiania, company, tipoprev, conventionslist);
                            element.convenzioneAttiva = convenzioneAttiva;

                            let noConvCompOrigin = false;
                            if (companyOrigin !== 0 && tipoprev === "RIN") {
                              switch (company.paramurl) {
                                case "conte":
                                  if (companyOrigin === 911) {
                                    noConvCompOrigin = true;
                                  }
                                  break;
                                default:
                                  break;
                              }
                            }
                            if (element.convenzioneAttiva.code === "" && noConvCompOrigin === false) {
                              let percmax = -200;
                              conventionslist.forEach(conventions => {
                                if (company.facid === conventions.id_factory && conventions.companyapply === 0) {
                                  let convention_accept = CheckActiveConvention(conventions, datiania, tipoprev);
                                  if (convention_accept) {
                                    //Al momento sovrascrire qualsiasi sconto commerciale
                                    //Dopo vedere il flag
                                    let value_flex = 1;
                                    if (conventions.discounts_rules !== null) {
                                      JSON.parse(conventions.discounts_rules).forEach(
                                        single_discount_rules => {
                                          let selectCVT = "rca";
                                          if (single_discount_rules.hasOwnProperty(selectCVT)) {
                                            value_flex = single_discount_rules[selectCVT].perc;
                                            if (value_flex === '0' || value_flex === 0) {
                                              value_flex = 1;
                                            }
                                          }
                                        }
                                      );
                                    }
                                    if (value_flex > percmax) {
                                      company.discount_description = conventions.description;
                                      company.discounts_rules = conventions.discounts_rules;
                                      percmax = value_flex;
                                    }
                                  }
                                }
                              }
                              );
                            }

                            let sconto_totale = 0;
                            let nettoTotalePartenza = 0;
                            let nettoTotaleScontato = 0;
                            let differenzaImposte = 0;
                            if (company.discount_description !== null && company.discounts_rules !== null) {
                              element.listResponseCVT.forEach(
                                cvt => {
                                  if (cvt.code !== "CFE" && cvt.code !== "TAX") {
                                    let selectCVT = "";
                                    nettoTotalePartenza += cvt.price;
                                    cvt.originalprice = cvt.price;
                                    switch (cvt.code) {
                                      case "RCA":
                                        selectCVT = "rca";
                                        break;
                                      case "CAN":   //Canone Telematico Generali
                                      case "ASSM":  //Assistenza Forma  Medium  Generali
                                      case "ASS":
                                        selectCVT = "assistenzaStradale";
                                        break;
                                      case "INFCONM":
                                      case "INFCONIP":
                                        selectCVT = "infortunioConducente";
                                        break;
                                      case "TL":
                                        selectCVT = "tutelaLegale";
                                        break;
                                      case "INCFUR":
                                      case "INC":
                                      case "FUR":
                                        selectCVT = "furto";
                                        break;
                                      case "AV":
                                      case "INCD":
                                      case "ESP":
                                        selectCVT = "attiVandalici";
                                        break;
                                      case "EN":
                                      case "ENG":
                                        selectCVT = "eventiNaturali";
                                        break;
                                      case "CRIS":
                                        selectCVT = "cristalli";
                                        break;
                                      case "COLL":
                                        selectCVT = "collisione";
                                        break;
                                      case "KAS":
                                        selectCVT = "kasko";
                                        break;
                                      case "RINRIV":
                                        selectCVT = "revengeWaiver";
                                        break;
                                      default:
                                        selectCVT = cvt.code;
                                        break;
                                    }
                                    if (selectCVT !== "") {
                                      JSON.parse(company.discounts_rules).forEach(
                                        single_discount_rules => {
                                          if (single_discount_rules.hasOwnProperty(selectCVT)) {
                                            if (single_discount_rules[selectCVT].val !== null && single_discount_rules[selectCVT].val !== "0" &&
                                              cvt.price >= parseFloat(single_discount_rules[selectCVT].val)) {
                                              sconto_totale += parseFloat(single_discount_rules[selectCVT].val);
                                              cvt.price -= parseFloat(single_discount_rules[selectCVT].val);
                                            }
                                            if (single_discount_rules[selectCVT].perc !== null && single_discount_rules[selectCVT].perc !== "0") {
                                              sconto_totale += (cvt.price * (parseFloat(single_discount_rules[selectCVT].perc))) / 100;
                                              cvt.price -= (cvt.price * (parseFloat(single_discount_rules[selectCVT].perc))) / 100;
                                            }
                                            nettoTotaleScontato += cvt.price;
                                          }
                                        }
                                      )
                                    }
                                  }
                                }
                              );

                              if (company.paramurl === "groupamanexus" || company.paramurl === "groupamanexuscvt") {
                                element.listResponseCVT.forEach(
                                  cvt => {
                                    if (cvt.code === "TAX") {
                                      let imposteTotale = cvt.price;
                                      let scontoPercTax = (nettoTotaleScontato * 100) / nettoTotalePartenza;
                                      cvt.price = (cvt.price * scontoPercTax) / 100;
                                      differenzaImposte = imposteTotale - cvt.price;
                                    }
                                  }
                                );
                              }
                            }

                            element.TotalPrice -= parseFloat2Decimals(sconto_totale);
                            element.price -= parseFloat2Decimals(sconto_totale);
                            if (company.paramurl === "groupamanexus" || company.paramurl === "groupamanexuscvt") {
                              element.TotalPrice -= parseFloat(differenzaImposte);
                              element.price -= parseFloat(differenzaImposte);
                            }

                            let addCalcoloCvt = 0;
                            let addCalcoloCvtNoFraz = 0;
                            guarantees.forEach((elementGuar, indexGuar) => {
                              if (guaranteesSelect[indexGuar]) {
                                let calcoloPercValue = 0;
                                let tipoCalcolo = company.guarantees_info[indexGuar].tipo_calcolo !== null ? (company.guarantees_info[indexGuar].tipo_calcolo !== "default" ? company.guarantees_info[indexGuar].tipo_calcolo : company.guarantees_info[indexGuar].tipo_calcolo_default) : company.guarantees_info[indexGuar].tipo_calcolo_default;
                                let serviceParameters = company.guarantees_info[indexGuar].tipo_calcolo !== null ? (company.guarantees_info[indexGuar].tipo_calcolo !== "default" ? company.guarantees_info[indexGuar].service_parameters : company.guarantees_info[indexGuar].service_parameters_default) : company.guarantees_info[indexGuar].service_parameters_default;
                                let tipoArrotondamento = company.guarantees_info[indexGuar].tipo_arrotondamento_calcolo;
                                if (tipoCalcolo === "perc") {
                                  calcoloPercValue = parseFloat2Decimals((element.price * (Number(serviceParameters))) / 100);
                                  if (tipoArrotondamento === "sup") {
                                    calcoloPercValue = Math.ceil(calcoloPercValue);
                                  } else if (tipoArrotondamento === "inf") {
                                    calcoloPercValue = Math.floor(calcoloPercValue);
                                  } else if (tipoArrotondamento === "mat") {
                                    calcoloPercValue = Math.round(calcoloPercValue);
                                  }
                                  addCalcoloCvt += calcoloPercValue;
                                  let guarInfo =
                                  {
                                    code: elementGuar["code"],
                                    nameCVT: elementGuar["description"],
                                    selected: true,
                                    price: calcoloPercValue
                                  };
                                  element.listResponseCVT.push(guarInfo);
                                } else if (tipoCalcolo === "value") {
                                  if (elementGuar["code"] !== "NETAASS") {
                                    calcoloPercValue = Number(serviceParameters);
                                    addCalcoloCvt += parseFloat2Decimals(calcoloPercValue);
                                    let guarInfo =
                                    {
                                      code: elementGuar["code"],
                                      nameCVT: elementGuar["description"],
                                      selected: true,
                                      price: parseFloat2Decimals(calcoloPercValue)
                                    };
                                    element.listResponseCVT.push(guarInfo);
                                  } else {
                                    calcoloPercValue = Number(serviceParameters);
                                    addCalcoloCvtNoFraz += parseFloat2Decimals(calcoloPercValue);
                                    let guarInfo =
                                    {
                                      code: elementGuar["code"],
                                      nameCVT: elementGuar["description"],
                                      selected: true,
                                      price: parseFloat2Decimals(calcoloPercValue)
                                    };
                                    element.listResponseCVT.push(guarInfo);
                                  }
                                }
                              }
                            });

                            element.TotalPrice += addCalcoloCvt;
                            element.price += addCalcoloCvt;

                            let NumRate = 1;
                            let splitPrice = element.price;
                            if (element.splittingUp !== undefined && !isNaN(element.splittingUp)) {
                              NumRate = parseFloat2Decimals(element.splittingUp);
                            }
                            if (element.splitPrice !== undefined && !isNaN(element.splitPrice)) {
                              splitPrice = parseFloat2Decimals(element.splitPrice);
                            } else {
                              splitPrice = element.price;
                            }
                            splitPrice += (addCalcoloCvt / NumRate).toFixed(2);

                            element.addCalcoloCvtNoFraz = addCalcoloCvtNoFraz;
                            element.price += addCalcoloCvtNoFraz;

                            if (company.FinalCommissionsType !== null && company.FinalCommissionsType === "euro") {
                              if (Number(company.FinalCommissionsDefault) >= 0) {
                                element.Commissions = company.FinalCommissionsDefault * NumRate;
                              } else {
                                if (Number(company.FinalCommissionsMax)) {
                                  element.Commissions = company.FinalCommissionsMax * NumRate;
                                } else {
                                  element.Commissions = 0;
                                }
                              }
                            } else {
                              if (Number(company.FinalCommissionsDefault) >= 0) {
                                element.Commissions = element.price * company.FinalCommissionsDefault / 100;
                                element.Commissions = element.Commissions.toFixed(2);
                              } else {
                                if (Number(company.FinalCommissionsMax)) {
                                  element.Commissions = element.price * company.FinalCommissionsMax / 100;
                                  element.Commissions = element.Commissions.toFixed(2);
                                } else {
                                  element.Commissions = 0;
                                }
                              }
                            }
                            /*if (pos === 615) {
                              if (Number(company.FinalCommissionsDefault) >= 0) {
                                element.Commissions = company.FinalCommissionsDefault * NumRate;
                              } else {
                                if (Number(company.FinalCommissionsMax)) {
                                  element.Commissions = company.FinalCommissionsMax * NumRate;
                                } else {
                                  element.Commissions = 0;
                                }
                              }
                            } else {
                              if (Number(company.FinalCommissionsDefault) >= 0) {
                                element.Commissions = element.price * company.FinalCommissionsDefault / 100;
                                element.Commissions = element.Commissions.toFixed(2);
                              } else {
                                if (Number(company.FinalCommissionsMax)) {
                                  element.Commissions = element.price * company.FinalCommissionsMax / 100;
                                  element.Commissions = element.Commissions.toFixed(2);
                                } else {
                                  element.Commissions = 0;
                                }
                              }
                            }*/

                            if (company.FinalfeeType !== null && company.FinalfeeType === "perc") {
                              if (Number(company.FinalfeeDefault) >= 0) {
                                element.Fees = element.price * company.FinalfeeDefault / 100;
                                element.Fees = element.Fees.toFixed(2);
                              } else {
                                if (Number(company.FinalfeeMax)) {
                                  element.Fees = element.price * company.FinalfeeMax / 100;
                                  element.Fees = element.Fees.toFixed(2);
                                } else {
                                  element.Fees = 0;
                                }
                              }
                            } else {
                              if (Number(company.FinalfeeDefault) >= 0) {
                                element.Fees = company.FinalfeeDefault * NumRate;
                              } else {
                                if (Number(company.FinalfeeMax)) {
                                  element.Fees = company.FinalfeeMax * NumRate;
                                } else {
                                  element.Fees = 0;
                                }
                              }
                            }

                            element.TotalPrice = parseFloat2Decimals(element.price) + parseFloat2Decimals(element.Commissions) + parseFloat2Decimals(element.Fees);

                            element.ListService = [];
                            AddOnService.forEach(service => {
                              let priceservice = 0;
                              if (service.type === "perc") {
                                priceservice = element.price * service.value / 100;
                                priceservice = parseFloat2Decimals(priceservice);
                              } else {
                                priceservice = service.value;
                              }
                              element.TotalPrice += parseFloat2Decimals(priceservice);

                              let ServiceInfo =
                              {
                                description: service.description,
                                priceservice: priceservice
                              };

                              element.ListService.push(ServiceInfo);
                            });
                            element.TotalPrice -= addCalcoloCvtNoFraz;

                            if (forceMessage) {
                              let Message = {
                                code: "01",
                                message: "Premio da confermare, polizza soggetta ad autorizzazione direzionale"
                              };
                              element.messages.push(Message);
                            }
                            element.TotalRate = parseFloat2Decimals(element.TotalPrice / NumRate);
                            element.TotalRate += addCalcoloCvtNoFraz;
                            element.TotalRate = element.TotalRate.toFixed(2);

                            element.TotalPrice += addCalcoloCvtNoFraz;
                            element.splitPrice += addCalcoloCvtNoFraz;
                            splitPrice = (Number(splitPrice) + addCalcoloCvtNoFraz).toFixed(2);

                            element.only_cvt = company.only_cvt;
                          });
                        }

                        newDatiPreventivo[company.sfo_id + company.paramurl + company.type] = payload.data;
                        setdatipreventivo(newDatiPreventivo);

                        setPrevRS(prevCount => prevCount + 1);
                        contaPreventiviFatti++;

                        if (contaPreventiviFatti === contaPreventiviDaFare && contaPreventiviDaFare > 0) {
                          HandleSaveQuotation(companyListPrev);
                        }

                        if (prevRQ === prevRS) {
                          setLoading(false);
                        }
                      }).catch(error => {
                        setPrevRS(prevCount => prevCount + 1);
                        contaPreventiviFatti++;
                        if (contaPreventiviFatti === contaPreventiviDaFare && contaPreventiviDaFare > 0) {
                          HandleSaveQuotation(companyListPrev);
                        }
                        if (prevRQ === prevRS)
                          setLoading(false);
                      }
                      )
                  ));
                }
              }).catch(error => {
                setPrevRS(prevCount => prevCount + 1);
                contaPreventiviFatti++;
                if (prevRQ === prevRS)
                  setLoading(false);
              }
              );
          }).catch(error => {
          });
        break;
      case 5:
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        break;
      default:
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        break;
    }
  };

  const handleBack = () => {
    if (activeStep === 5) {
      //console.log("STEP:"+activeStep);
      window.stop();
      setdatipreventivo([]);
      //setSaved(false);
    }

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChangePos = (event) => {
    agencieslists.forEach(element => {
      if (element.id === event.target.value) {
        setPos(event.target.value);

        seturlservicequotiamo(element.url)
        setPosInfo(element);
        setPosActive(element);
        setOrgId(element.orgid);
        let cvtComp = (element.defAg !== null && JSON.parse(element.defAg).auto_cvt_comp !== undefined && JSON.parse(element.defAg).auto_cvt_comp !== "" ? JSON.parse(element.defAg).auto_cvt_comp : (element.defGrp !== null && JSON.parse(element.defGrp).auto_cvt_comp !== undefined && JSON.parse(element.defGrp).auto_cvt_comp !== "" ? JSON.parse(element.defGrp).auto_cvt_comp : (element.defOrg !== null && JSON.parse(element.defOrg).auto_cvt_comp !== undefined && JSON.parse(element.defOrg).auto_cvt_comp !== "" ? JSON.parse(element.defOrg).auto_cvt_comp : "yes")));
        setDefCvtComp(cvtComp);
        if (cvtComp !== "both") {
          setUseCvtComp(cvtComp);
        }

        //const agencyData=JSON.parse(localStorage.getItem('pos'));
        localStorage.setItem('pos', JSON.stringify(element));

        AuthService.signin(element.username, element.password, element.url).then(
          payload => {
            setToken(payload);
          }).catch(error => {

          });
        /* const urlGuar = API_URL_ALLGUARANTEES + "/enabled/car/" + element.orgid;
        axios.get(urlGuar,
          {
            headers: {
              'Authorization': 'Bearer ' + Auth.getToken(),
              'Content-Type': 'application/json'
            }
          }
        ).then(payload => {
          let temp = [];
          let tempVal = [];
          let tempGuar = payload.data.guarantees;
          payload.data.guarantees.forEach(elementGuar => {
            temp.push(false);
            if (elementGuar.input_type === 2) {
              tempVal.push(elementGuar.list_value.split(",")[0]);
            } else {
              tempVal.push("");
            }

          });
          if (element.orgid === 1 && element.groupid !== 8) {
            payload.data.guarantees.forEach((element, index) => {
              if (element.id === 1) {
                temp.splice(index, 1);
                tempVal.splice(index, 1);
                tempGuar.splice(index, 1);
              }
            });
          }
          setGuaranteesSelect(temp);
          setGuaranteesValue(tempVal);
          setGuarantees(tempGuar);
        }); */
        const url = API_URL_INFOIMAGES_ALL + "/car/" + event.target.value;
        axios.get(url,
          {
            headers: {
              'Authorization': 'Bearer ' + Auth.getToken(),
              'Content-Type': 'application/json'
            }
          }
        ).then(payload => {
          setImages(payload.data.images)
        });
      }
    });
  };

  const handleChangeType = (event) => {
    setTipoPrev(event.target.value);
  };

  const handleChangeCvt = (event) => {
    setUseCvtComp(event.target.value);
  };

  const handleChange = event => {
    setdatiania({
      ...datiania,
      [event.target.name]: event.target.value
    });
  };

  const handleChangeFreeInput = index => event => {
    let newVal = guaranteesValue;
    newVal[index] = event.floatValue;
    setGuaranteesValue(newVal);
  };

  const handleChangeList = index => event => {
    let newVal = guaranteesValue;
    newVal[index] = event.target.value;
    setGuaranteesValue(newVal);
  };

  const handleChangeDateEffective = event => {
    setdatiania({
      ...datiania,
      effectiveDate: moment(event).format("YYYY-MM-DD")
    });
  };

  const handleChangeRegistrationDate = event => {
    setdatiania({
      ...datiania,
      registrationDate: moment(event).format("YYYY-MM-DD")
    });
  };

  const handleChangePurchaseDate = event => {
    setdatiania({
      ...datiania,
      purchaseDate: moment(event).format("YYYY-MM-DD")
    });
  };

  const handleClick = name => event => {
    let new_guaranteesSelect = guaranteesSelect;
    new_guaranteesSelect[name] = !guaranteesSelect[name];
    setGuaranteesSelect(new_guaranteesSelect);
  }

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <>
          <Grid container spacing={3}>
            <Grid item xs={4} />
            <Grid item xs={12} md={4}>
              <Box pb={4}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="labelPOS">Punto vendita</InputLabel>
                  <Select
                    labelId="labelPOS"
                    id="pos"
                    value={pos}
                    onChange={handleChangePos}
                    label="Punto vendita"
                  >
                    {agencieslists.length > 0 && agencieslists.map(option => (
                      <MenuItem
                        key={"ag_" + option.id}
                        value={option.id}
                      >
                        {option.description}
                      </MenuItem>
                    ))}

                  </Select>
                </FormControl>
              </Box>

              <Box pb={2}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="labeltipoprev">Tipo di preventivo</InputLabel>
                  <Select
                    labelId="labeltipoprev"
                    id="tipoprev"
                    value={tipoprev}
                    onChange={handleChangeType}
                    label="Tipo di preventivo"
                  >
                    <MenuItem key="tipo_rinnovo" value="RIN">
                      Rinnovo polizza in scadenza o appena scaduta
                    </MenuItem>
                    <MenuItem key="tipo_prima" value="PRI">
                      Prima immatricolazione
                    </MenuItem>
                    <MenuItem key="tipo_voltura" value="VOL">
                      Voltura al PRA
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>

              {defCvtComp === "both" &&
                <Box pb={2}>
                  <FormControl variant="outlined" margin="normal" className={classes.formControl}>
                    <InputLabel id="labelusocvt">Utilizzo delle CVT</InputLabel>
                    <Select
                      labelId="labelusocvt"
                      id="useCvtComp"
                      value={useCvtComp}
                      onChange={handleChangeCvt}
                      label="Utilizzo delle CVT"
                    >
                      <MenuItem key="cvt_comp" value="yes">
                        CVT delle compagnie
                      </MenuItem>
                      <MenuItem key="cvt_comp_sel" value="no">
                        CVT di compagnie selezionate
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              }

              <Box pb={2}>
                <TextValidator
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="targa"
                  label="Targa Veicolo"
                  name="targa"
                  autoFocus
                  value={targa}
                  validators={['required', 'minStringLength:7']}
                  errorMessages={['Campo obbligatorio', 'Targa troppo corta']}
                  onChange={(e) => setTarga(e.target.value.toUpperCase())} />
              </Box>

              {tipoprev !== "RIN" &&
                <>
                  <Box pb={2}>
                    <FormControl component="fieldset">
                      <FormLabel>Opzioni</FormLabel>
                      <RadioGroup aria-label="prevoption" name="prevoption" value={prevoption} onChange={handleChangeOption}>
                        <FormControlLabel value="" control={<Radio />} label="Nessuna opzione" />
                        <FormControlLabel value="NO" control={<Radio />} label="Importa CU da altra targa" />
                        <FormControlLabel value="BE" control={<Radio />} label="Legge Bersani" />
                        {false &&
                          <FormControlLabel value="FA" control={<Radio />} label="Decreto Familiare" />
                        }
                      </RadioGroup>
                    </FormControl>
                  </Box>

                  {prevoption !== "" &&
                    <Box pb={2}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required={prevoption !== ""}
                        fullWidth
                        id="targaoption"
                        label={prevoption === "BE" ? "Targa Veicolo legge Bersani" :
                          prevoption === "FA" ? "Targa Veicolo decreto familiare" : "Targa vecchio veicolo (se vuoi importare CU)"}
                        name="targaoption"
                        autoFocus
                        value={targaoption}
                        onChange={(e) => setTargaOption(e.target.value.toUpperCase())} />
                    </Box>
                  }

                  {prevoption === "NO" &&
                    <TextValidator
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="codiceFiscale2"
                      label="Codice Fiscale"
                      name="fiscalCode"
                      autoFocus
                      value={datiania.fiscalCode}
                      onChange={handleChange}
                      validators={['required']}
                      errorMessages={['Campo obbligatorio']}
                    />
                  }
                </>
              }
            </Grid>
            <Grid item xs={4} />
          </Grid>
        </>;
      case 1:
        return <>
          <SubjectDetails
            datatype={"car"}
            type={"contractorInfo"}
            token={token}
            urlservicequotiamo={urlservicequotiamo}
            datiquotazione={datiania}
            setdatiquotazione={setdatiania}
            listProfessions={listProfessions}
            listCity={listCity}
            posinfo={posinfo}
          />
          {true &&
            <Accordion defaultExpanded={sameOwner === false}>
              <AccordionSummary
                expandIcon={sameOwner === false && <ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id="additional-actions1-header"
              >
                <FormControlLabel
                  aria-label="Acknowledge"
                  onClick={(event) => {
                    setSameOwner(!sameOwner);
                    event.stopPropagation();
                  }
                  }
                  onFocus={(event) => event.stopPropagation()}
                  control={<Checkbox checked={sameOwner} />}
                  label="L'intestatario coincide con il contraente"
                />
              </AccordionSummary>
              {sameOwner === false &&
                <AccordionDetails>
                  <SubjectDetails
                    datatype={"car"}
                    type={"ownerInfo"}
                    token={token}
                    urlservicequotiamo={urlservicequotiamo}
                    datiquotazione={datiania}
                    setdatiquotazione={setdatiania}
                    listProfessions={listProfessions}
                    listCity={listCity}
                    posinfo={posinfo}
                  />
                </AccordionDetails>
              }
            </Accordion>
          }
          {false &&
            <Accordion disabled>
              <AccordionSummary
                expandIcon={sameDriver === false && <ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id="additional-actions1-header"
              >
                <FormControlLabel
                  aria-label="Acknowledge"
                  onClick={(event) => {
                    setSameDriver(!sameDriver);
                    event.stopPropagation();
                  }
                  }
                  onFocus={(event) => event.stopPropagation()}
                  control={<Checkbox checked={sameDriver} />}
                  label="Il conducente coincide con il contraente"
                />
              </AccordionSummary>
              {sameDriver === false &&
                <AccordionDetails>
                  <SubjectDetails
                    datatype={"car"}
                    type={"driverInfo"}
                    token={token}
                    urlservicequotiamo={urlservicequotiamo}
                    datiquotazione={datiania}
                    setdatiquotazione={setdatiania}
                    listProfessions={listProfessions}
                    listCity={listCity}
                    posinfo={posinfo}
                  />
                </AccordionDetails>
              }
            </Accordion>
          }
        </>
          ;
      case 2:
        return <div className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <KeyboardDatePicker
                label="Data immatricolazione"
                value={datiania.registrationDate}
                onChange={handleChangeRegistrationDate}
                format='dd/MM/yyyy'
                clearLabel="Conferma"
                cancelLabel="Annulla"
                name="registrationDate"
                fullWidth
                required
                inputVariant="outlined"
                margin="normal"
                disableFuture
              />
            </Grid>
            <Grid item xs={4}>
              <KeyboardDatePicker
                label="Data di acquisto"
                value={datiania.purchaseDate}
                onChange={handleChangePurchaseDate}
                format='dd/MM/yyyy'
                clearLabel="Conferma"
                cancelLabel="Annulla"
                name="purchaseDate"
                fullWidth
                required
                inputVariant="outlined"
                margin="normal"
                disableFuture
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="uso"
                label="Uso"
                name="use"
                autoFocus
                value={datiania.use}
                InputProps={{
                  readOnly: true,
                }}

              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={4}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="telaio"
                label="Numero telaio"
                name="frame"
                autoFocus
                disabled
                value={datiania.frame}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="codiceOmologazione"
                label="Codice di omologazione"
                name="homologationCode"
                disabled

                value={datiania.homologationCode}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="codiceMotornet"
                label="Codice MotorNet"
                name="codiceMotornet"
                disabled
                autoFocus
                value={datiania.codiceMotornet}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

          </Grid>


          <Grid container spacing={3}>
            <Grid item xs={4}>

              <Autocomplete
                options={listBrands.map((option) => option)}
                defaultValue={datiania.brand}
                id="brand"
                label="Marca"
                name="brand"
                autoHighlight
                onChange={(event, newValue) => {
                  setdatiania({
                    ...datiania,
                    brand: newValue,
                  });
                  getModels(token, urlservicequotiamo, newValue).then(payload => {
                    setlistModels(payload.data);
                  });
                }}
                renderInput={(params) => <TextField {...params} label="Marca" margin="normal" variant="outlined" />}
              />


            </Grid>
            <Grid item xs={4}>

              <Autocomplete
                options={listModels.map((option) => option)}
                defaultValue={datiania.model}
                id="model"
                label="Modello"
                name="model"
                autoHighlight
                onChange={(event, newValue) => {

                  setdatiania({
                    ...datiania,
                    model: newValue,
                  });

                  getOutfitting(token, urlservicequotiamo, datiania.brand, newValue, datiania.homologationCode, datiania.registrationDate).then(payload => {
                    setlistOutfitting(payload.data);
                  });
                }}
                renderInput={(params) => <TextField {...params} label="Modello" margin="normal" variant="outlined" />}
              />

            </Grid>


            <Grid item xs={4}>
              <Autocomplete
                options={listOutfitting.map((option) => option.outfitting)}
                defaultValue={datiania.outfitting}
                id="allestimento"
                label="Allestimento"
                name="outfitting"
                autoHighlight
                onChange={(event, newValue) => {


                  listOutfitting.map((option) => {
                    if (newValue === option.outfitting) {
                      setdatiania({
                        ...datiania,
                        outfitting: newValue,
                        newValue: option.newValue,
                        usedValue: option.usedValue,
                        codiceMotornet: option.codiceMotornet,
                        carBody: option.carBody,
                        power: option.power,
                        displacement: option.displacement,
                        horsePower: option.horsePower,
                        supply: option.supply
                      });
                      return option
                    }
                    return false;
                  }
                  )


                }}
                renderInput={(params) => <TextField {...params} label="Allestimento" margin="normal" variant="outlined" />}
              />


            </Grid>


          </Grid>



          <Grid container spacing={3}>


            <Grid item xs={4}>

              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="carrozzeria"
                label="Carrozzeria"
                disabled
                name="carBody"
                autoFocus
                value={datiania.carBody}
                InputProps={{
                  readOnly: true,
                }}
              />



            </Grid>
            <Grid item xs={4}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="newValue"
                label="Prezzo di listino"
                disabled
                name="newValue"
                autoFocus
                value={datiania.newValue}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="usedValue"
                label="Valore"
                name="usedValue"
                autoFocus
                value={datiania.usedValue}
                onChange={handleChange}
              />
            </Grid>

          </Grid>



          <Grid container spacing={3}>

            <Grid item xs={4}>
              <TextField
                margin="normal"
                fullWidth
                label="Alimentazione"
                name="supply"
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                value={datiania.supply === undefined ? "B" : datiania.supply}
                variant="outlined"
              >
                {tipoAlimentazione.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>

            </Grid>
            <Grid item xs={4}>

              <TextField
                margin="normal"
                fullWidth
                label="Gancio traino"
                name="towHook"
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                value={datiania.towHook}
                variant="outlined"
              >
                <option key="towHookNo" value="false">
                  NO
                </option>
                <option key="towHookYes" value="true">
                  SI
                </option>
              </TextField>
            </Grid>
            <Grid item xs={4}>

              <TextField
                margin="normal"
                fullWidth
                label="Antifurto"
                name="alarm"
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                value={datiania.alarm}
                variant="outlined"
              >
                <option key="GEN" value="2">
                  Generico
                </option>
                <option key="SAT" value="1">
                  Satellitare GPS
                </option>

                <option key="ASS" value="3">
                  Assente
                </option>
              </TextField>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={4}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="cilindrata"
                label="Cilindrata"
                name="displacement"
                autoFocus
                value={datiania.displacement}
                onChange={handleChange}
                disabled
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="potenza"
                label="Potenza"
                name="power"
                autoFocus
                value={datiania.power}
                onChange={handleChange}
                disabled

              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="cavalliFiscali"
                label="Cavalli Fiscali"
                name="horsePower"
                autoFocus
                value={datiania.horsePower}
                onChange={handleChange}
                disabled

              />


            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Autocomplete
                options={kmAnnuiOption}
                id="kmAnnui"
                label="Km annui percorsi"
                name="kmAnnui"
                disableClearable
                getOptionLabel={(option) => option.description}
                value={returnElement(kmAnnuiOption, datiania.kmAnnui)}
                autoHighlight
                required
                onChange={(event, newValue) => {
                  setdatiania({
                    ...datiania,
                    kmAnnui: newValue.id,
                  });
                }}
                renderInput={(params) => <TextField {...params} required label="Km annui percorsi" margin="normal" variant="outlined" />}
              />
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}></Grid>
          </Grid>

        </div>;
      case 3:
        var cols = [];
        var RespPrinc = [];
        var DanniCosePrinc = [];
        var DanniPersPrinc = [];
        var DanniMistiPrinc = [];

        var RespPar = [];
        var DanniCosePar = [];
        var DanniPersPar = [];
        var DanniMistiPar = [];

        for (let i = 10; i >= 0; i--) {
          if (datiania.dataATRC !== undefined && datiania.dataATRC !== null) {
            cols.push(datiania.dataATRC.anno0Atrc - i);
            switch (i) {
              case 0:
                RespPrinc.push(datiania.dataATRC.atrcRespPrincAnno0);
                DanniCosePrinc.push(datiania.dataATRC.atrcRespPrincDanniCoseAnno0);
                DanniPersPrinc.push(datiania.dataATRC.atrcRespPrincDanniPersAnno0);
                DanniMistiPrinc.push(datiania.dataATRC.atrcRespPrincDanniMistiAnno0);

                RespPar.push(datiania.dataATRC.atrcRespPariAnno0);
                DanniCosePar.push(datiania.dataATRC.atrcRespPariDanniCoseAnno0);
                DanniPersPar.push(datiania.dataATRC.atrcRespPariDanniPersAnno0);
                DanniMistiPar.push(datiania.dataATRC.atrcRespPariDanniMistiAnno0);
                break;
              case 1:
                RespPrinc.push(datiania.dataATRC.atrcRespPrincAnno1);
                DanniCosePrinc.push(datiania.dataATRC.atrcRespPrincDanniCoseAnno1);
                DanniPersPrinc.push(datiania.dataATRC.atrcRespPrincDanniPersAnno1);
                DanniMistiPrinc.push(datiania.dataATRC.atrcRespPrincDanniMistiAnno1);

                RespPar.push(datiania.dataATRC.atrcRespPariAnno1);
                DanniCosePar.push(datiania.dataATRC.atrcRespPariDanniCoseAnno1);
                DanniPersPar.push(datiania.dataATRC.atrcRespPariDanniPersAnno1);
                DanniMistiPar.push(datiania.dataATRC.atrcRespPariDanniMistiAnno1);
                break;
              case 2:
                RespPrinc.push(datiania.dataATRC.atrcRespPrincAnno2);
                DanniCosePrinc.push(datiania.dataATRC.atrcRespPrincDanniCoseAnno2);
                DanniPersPrinc.push(datiania.dataATRC.atrcRespPrincDanniPersAnno2);
                DanniMistiPrinc.push(datiania.dataATRC.atrcRespPrincDanniMistiAnno2);

                RespPar.push(datiania.dataATRC.atrcRespPariAnno2);
                DanniCosePar.push(datiania.dataATRC.atrcRespPariDanniCoseAnno2);
                DanniPersPar.push(datiania.dataATRC.atrcRespPariDanniPersAnno2);
                DanniMistiPar.push(datiania.dataATRC.atrcRespPariDanniMistiAnno2);
                break;
              case 3:
                RespPrinc.push(datiania.dataATRC.atrcRespPrincAnno3);
                DanniCosePrinc.push(datiania.dataATRC.atrcRespPrincDanniCoseAnno3);
                DanniPersPrinc.push(datiania.dataATRC.atrcRespPrincDanniPersAnno3);
                DanniMistiPrinc.push(datiania.dataATRC.atrcRespPrincDanniMistiAnno3);

                RespPar.push(datiania.dataATRC.atrcRespPariAnno3);
                DanniCosePar.push(datiania.dataATRC.atrcRespPariDanniCoseAnno3);
                DanniPersPar.push(datiania.dataATRC.atrcRespPariDanniPersAnno3);
                DanniMistiPar.push(datiania.dataATRC.atrcRespPariDanniMistiAnno3);
                break;
              case 4:
                RespPrinc.push(datiania.dataATRC.atrcRespPrincAnno4);
                DanniCosePrinc.push(datiania.dataATRC.atrcRespPrincDanniCoseAnno4);
                DanniPersPrinc.push(datiania.dataATRC.atrcRespPrincDanniPersAnno4);
                DanniMistiPrinc.push(datiania.dataATRC.atrcRespPrincDanniMistiAnno4);

                RespPar.push(datiania.dataATRC.atrcRespPariAnno4);
                DanniCosePar.push(datiania.dataATRC.atrcRespPariDanniCoseAnno4);
                DanniPersPar.push(datiania.dataATRC.atrcRespPariDanniPersAnno4);
                DanniMistiPar.push(datiania.dataATRC.atrcRespPariDanniMistiAnno4);
                break;
              case 5:
                RespPrinc.push(datiania.dataATRC.atrcRespPrincAnno5);
                DanniCosePrinc.push(datiania.dataATRC.atrcRespPrincDanniCoseAnno5);
                DanniPersPrinc.push(datiania.dataATRC.atrcRespPrincDanniPersAnno5);
                DanniMistiPrinc.push(datiania.dataATRC.atrcRespPrincDanniMistiAnno5);

                RespPar.push(datiania.dataATRC.atrcRespPariAnno5);
                DanniCosePar.push(datiania.dataATRC.atrcRespPariDanniCoseAnno5);
                DanniPersPar.push(datiania.dataATRC.atrcRespPariDanniPersAnno5);
                DanniMistiPar.push(datiania.dataATRC.atrcRespPariDanniMistiAnno5);
                break;
              case 6:
                RespPrinc.push(datiania.dataATRC.atrcRespPrincAnno6);
                DanniCosePrinc.push(datiania.dataATRC.atrcRespPrincDanniCoseAnno6);
                DanniPersPrinc.push(datiania.dataATRC.atrcRespPrincDanniPersAnno6);
                DanniMistiPrinc.push(datiania.dataATRC.atrcRespPrincDanniMistiAnno6);

                RespPar.push(datiania.dataATRC.atrcRespPariAnno6);
                DanniCosePar.push(datiania.dataATRC.atrcRespPariDanniCoseAnno6);
                DanniPersPar.push(datiania.dataATRC.atrcRespPariDanniPersAnno6);
                DanniMistiPar.push(datiania.dataATRC.atrcRespPariDanniMistiAnno6);
                break;
              case 7:
                RespPrinc.push(datiania.dataATRC.atrcRespPrincAnno7);
                DanniCosePrinc.push(datiania.dataATRC.atrcRespPrincDanniCoseAnno7);
                DanniPersPrinc.push(datiania.dataATRC.atrcRespPrincDanniPersAnno7);
                DanniMistiPrinc.push(datiania.dataATRC.atrcRespPrincDanniMistiAnno7);

                RespPar.push(datiania.dataATRC.atrcRespPariAnno7);
                DanniCosePar.push(datiania.dataATRC.atrcRespPariDanniCoseAnno7);
                DanniPersPar.push(datiania.dataATRC.atrcRespPariDanniPersAnno7);
                DanniMistiPar.push(datiania.dataATRC.atrcRespPariDanniMistiAnno7);
                break;
              case 8:
                RespPrinc.push(datiania.dataATRC.atrcRespPrincAnno8);
                DanniCosePrinc.push(datiania.dataATRC.atrcRespPrincDanniCoseAnno8);
                DanniPersPrinc.push(datiania.dataATRC.atrcRespPrincDanniPersAnno8);
                DanniMistiPrinc.push(datiania.dataATRC.atrcRespPrincDanniMistiAnno8);

                RespPar.push(datiania.dataATRC.atrcRespPariAnno8);
                DanniCosePar.push(datiania.dataATRC.atrcRespPariDanniCoseAnno8);
                DanniPersPar.push(datiania.dataATRC.atrcRespPariDanniPersAnno8);
                DanniMistiPar.push(datiania.dataATRC.atrcRespPariDanniMistiAnno8);
                break;
              case 9:
                RespPrinc.push(datiania.dataATRC.atrcRespPrincAnno9);
                DanniCosePrinc.push(datiania.dataATRC.atrcRespPrincDanniCoseAnno9);
                DanniPersPrinc.push(datiania.dataATRC.atrcRespPrincDanniPersAnno9);
                DanniMistiPrinc.push(datiania.dataATRC.atrcRespPrincDanniMistiAnno9);

                RespPar.push(datiania.dataATRC.atrcRespPariAnno9);
                DanniCosePar.push(datiania.dataATRC.atrcRespPariDanniCoseAnno9);
                DanniPersPar.push(datiania.dataATRC.atrcRespPariDanniPersAnno9);
                DanniMistiPar.push(datiania.dataATRC.atrcRespPariDanniMistiAnno9);
                break;
              case 10:
                RespPrinc.push(datiania.dataATRC.atrcRespPrincAnno10);
                DanniCosePrinc.push(datiania.dataATRC.atrcRespPrincDanniCoseAnno10);
                DanniPersPrinc.push(datiania.dataATRC.atrcRespPrincDanniPersAnno10);
                DanniMistiPrinc.push(datiania.dataATRC.atrcRespPrincDanniMistiAnno10);

                RespPar.push(datiania.dataATRC.atrcRespPariAnno10);
                DanniCosePar.push(datiania.dataATRC.atrcRespPariDanniCoseAnno10);
                DanniPersPar.push(datiania.dataATRC.atrcRespPariDanniPersAnno10);
                DanniMistiPar.push(datiania.dataATRC.atrcRespPariDanniMistiAnno10);
                break;
              default:
                break;

            }
          } else {
            cols.push(moment().format("YYYY") - i);
            RespPrinc.push("--");
            DanniCosePrinc.push("--");
            DanniPersPrinc.push("--");
            DanniMistiPrinc.push("--");

            RespPar.push("--");
            DanniCosePar.push("--");
            DanniPersPar.push("--");
            DanniMistiPar.push("--");
          }

        }







        return <div className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="impresaAssicuratrice"
                label="Compagnia di provenienza"
                name="insuranceCompany"
                autoFocus
                value={datiania.insuranceCompany}
                InputProps={{
                  readOnly: true,
                }}

              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="declarationOrigin"
                label="Tipologia di tariffa"
                name="declarationOrigin"
                autoFocus
                value={datiania.declarationOrigin}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="policyExpirationDate"
                label="Data scadenza contratto"
                name="policyExpirationDate"
                autoFocus

                value={moment(datiania.policyExpirationDate, "YYYY-MM-DD").format("DD/MM/YYYY")}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={2}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="cuProvenienza"
                label="CU di provenienza"
                name="cuOrigin"
                autoFocus
                value={datiania.cuOrigin}
                onChange={handleChange}

              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="cuAssegnazione"
                label="CU di assegnazione"
                name="cuAssignment"
                autoFocus
                value={datiania.cuAssignment}
                onChange={handleChange}


              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                disabled={Number(datiania.cuOrigin) !== 1}
                variant="outlined"
                margin="normal"
                fullWidth
                id="insuranceYearsCU1"
                label="Anni in classe 1"
                name="insuranceYearsCU1"
                autoFocus
                value={datiania.insuranceYearsCU1}
                onChange={handleChange}


              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                margin="normal"
                fullWidth
                label="Hai usufruito della legge Bersani?"
                name="usedBersani"
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                value={datiania.usedBersani}
                variant="outlined"
              >
                <option key="BersaniNo" value="false">
                  NO
                </option>
                <option key="BersaniYes" value="true">
                  SI
                </option>
              </TextField>

            </Grid>
            <Grid item xs={2}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="currentAward"
                label="Premio attuale €"
                name="currentAward"
                autoFocus
                value={datiania.currentAward}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="insuranceYears"
                label="Da quanti anni sei assicurato?"
                name="insuranceYears"
                autoFocus
                value={datiania.insuranceYears}
                onChange={handleChange}
              />
            </Grid>

          </Grid>


          <Grid className={classes.PaddingTop} container spacing={3}>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell>Tipo sinistri</StyledTableCell>
                      {cols.map((col, index) => (
                        <StyledTableCell key={index} align="right">{col}</StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        <ArrowDropDownIcon />
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        Sinistri con responsabilità principale
                      </StyledTableCell>
                      {RespPrinc.map((col, index) => (
                        <StyledTableCell key={index} align="right">{col}</StyledTableCell>
                      ))}
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">

                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        di cui con danni a: COSE
                      </StyledTableCell>
                      {DanniCosePrinc.map((col, index) => (
                        <StyledTableCell key={index} align="right">{col}</StyledTableCell>
                      ))}
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">

                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        di cui con danni a: PERSONE
                      </StyledTableCell>
                      {DanniPersPrinc.map((col, index) => (
                        <StyledTableCell key={index} align="right">{col}</StyledTableCell>
                      ))}
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">

                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        di cui con danni a: MISTI
                      </StyledTableCell>
                      {DanniMistiPrinc.map((col, index) => (
                        <StyledTableCell key={index} align="right">{col}</StyledTableCell>
                      ))}
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        <ArrowDropDownIcon />
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        Sinistri con responsabilità paritaria
                      </StyledTableCell>
                      {RespPar.map((col, index) => (
                        <StyledTableCell key={index} align="right">{col}</StyledTableCell>
                      ))}
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">

                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        di cui con danni a: COSE
                      </StyledTableCell>
                      {DanniCosePar.map((col, index) => (
                        <StyledTableCell key={index} align="right">{col}</StyledTableCell>
                      ))}
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">

                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        di cui con danni a: PERSONE
                      </StyledTableCell>

                      {DanniPersPar.map((col, index) => (
                        <StyledTableCell key={index} align="right">{col}</StyledTableCell>
                      ))}
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">

                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        di cui con danni a: MISTI
                      </StyledTableCell>
                      {DanniMistiPar.map((col, index) => (
                        <StyledTableCell key={index} align="right">{col}</StyledTableCell>
                      ))}
                    </StyledTableRow>

                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>

          {datiania.listDataCARD !== undefined && datiania.listDataCARD !== null && datiania.listDataCARD.length > 0 ?
            <>
              <Grid className={classes.PaddingTop} container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h6" align="center">Elenco CARD</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>CARD</StyledTableCell>
                          <StyledTableCell>Sinistro</StyledTableCell>
                          <StyledTableCell>Accadimento</StyledTableCell>
                          <StyledTableCell>Aggiornamento</StyledTableCell>
                          <StyledTableCell>Ultima stanza</StyledTableCell>
                          <StyledTableCell>Debitrice</StyledTableCell>
                          <StyledTableCell>Gestionaria</StyledTableCell>
                          <StyledTableCell>Stato</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {datiania.listDataCARD.map((card) => (
                          <StyledTableRow key={card.sinisterNumber}>
                            <StyledTableCell>{card.vehicleDebtorId === targa ?
                              <Chip style={{ backgroundColor: 'red', color: 'white' }}
                                label="PASSIVO" />
                              : <Chip style={{ backgroundColor: 'green', color: 'white' }}
                                label="ATTIVO" />
                            }


                            </StyledTableCell>
                            <StyledTableCell>{card.sinisterNumber}</StyledTableCell>
                            <StyledTableCell>{moment(card.occuranceDate, "YYYY-MM-DD").format("DD/MM/YYYY")}</StyledTableCell>
                            <StyledTableCell>{moment(card.updateDate, "YYYY-MM-DD").format("DD/MM/YYYY")}</StyledTableCell>
                            <StyledTableCell>{moment(card.lastRoomDate, "YYYY-MM-DD").format("DD/MM/YYYY")}</StyledTableCell>
                            <StyledTableCell>
                              {card.debtorCompany}<br />
                              {card.vehicleDebtorType}<br />
                              {card.vehicleManagementIdFormat}<br />
                              {card.vehicleDebtorId}

                            </StyledTableCell>
                            <StyledTableCell>
                              {card.managementCompany}<br />
                              {card.vehicleManagementType}<br />
                              {card.vehicleManagementIdFormat}<br />
                              {card.vehicleManagementId}<br />
                            </StyledTableCell>
                            <StyledTableCell>{card.sinisterStatus}</StyledTableCell>
                          </StyledTableRow>

                        ))}

                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid></> : ""}
        </div>;
      case 4:
        return <>
          <div className={classes.paper}>
            <Grid container spacing={0}>
              {guarantees.map((warranty, index) => (
                <>
                  {warranty.code !== "rca" &&
                    <>
                      {(index === 0 || guarantees[index - 1].categoriesdescription !== warranty.categoriesdescription) &&
                        <Grid key={"cat_" + index} item xs={12}>
                          <Typography className={classes.TitleCategory}>
                            {warranty.categoriesdescription}
                          </Typography>
                        </Grid>
                      }
                      <Grid key={index} item xs={2}>
                        <CheckSvgButton disabled={posinfo.defAg !== null && JSON.parse(posinfo.defAg)[warranty.code] !== undefined ? JSON.parse(posinfo.defAg)[warranty.code] >= 2 : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp)[warranty.code] !== undefined ? true : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg)[warranty.code] !== undefined ? true : false)) &&
                          (((posinfo.defAg !== null && JSON.parse(posinfo.defAg)[warranty.code] !== undefined ? JSON.parse(posinfo.defAg)[warranty.code] : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp)[warranty.code] !== undefined ? JSON.parse(posinfo.defGrp)[warranty.code] : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg)[warranty.code] !== undefined ? JSON.parse(posinfo.defOrg)[warranty.code] : 0))) & 2) >= 2)} label={warranty.description} svg={warranty.image} status={guaranteesSelect[index]} click={handleClick(index)} />

                        <Box className={classes.BoxContainer}>

                          {warranty.input_type === 1 && <>
                            <NumberFormat disabled={!guaranteesSelect[index]} onValueChange={handleChangeFreeInput(index)} customInput={TextFieldCustomMassimale} fixedDecimalScale="true" decimalScale="0" value={guaranteesValue[index]} displayType={'input'} decimalSeparator={" "} thousandSeparator={"."} prefix={'€ '} />
                          </>}

                          {warranty.input_type === 2 && <>
                            <TextField
                              disabled={!guaranteesSelect[index]}
                              fullWidth
                              label="Massimale"
                              onChange={handleChangeList(index)}
                              select
                              SelectProps={{ native: true }}
                              value={guaranteesValue[index]}
                              variant="outlined"
                            >
                              {warranty.list_value.split(",").map(option => (
                                <option
                                  key={option}
                                  value={option}
                                >
                                  {option.indexOf("%") >= 0 && "" + option}

                                  {option.indexOf("%") === -1 && "€ " + number_format(option, 0, ",", ".")}
                                </option>
                              ))}
                            </TextField>
                          </>}
                        </Box>
                      </Grid>
                    </>
                  }
                </>
              ))}
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <KeyboardDatePicker
                  label="Data decorrenza"
                  value={datiania.effectiveDate}
                  onChange={handleChangeDateEffective}
                  format='dd/MM/yyyy'
                  clearLabel="Conferma"
                  cancelLabel="Annulla"
                  name="effectiveDate"
                  fullWidth
                  required
                  invalidDateMessage="Data non valida"
                  maxDateMessage="Non è possibile effettuare un preventivo con una scadenza superiore alla fine del mese successivo"
                  minDateMessage="Il preventivo non può essere retroattivo"
                  minDate={moment()}
                  //maxDate={moment().add(1, "months").endOf('month')}
                  maxDate={moment().add(60, "days")}
                  inputVariant="outlined"
                  margin="normal"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <TextField
                  margin="normal"
                  fullWidth
                  label="Tipo di guida"
                  name="guideType"
                  onChange={handleChange}

                  required
                  select
                  SelectProps={{ native: true }}
                  value={datiania.guideType === undefined ? "LIB" : datiania.guideType}
                  variant="outlined"
                >
                  {tipoGuida.map(option => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={3}>
                <TextField
                  margin="normal"
                  fullWidth
                  label="Età del guidatore più giovane"
                  name="youngestLicensedAge"
                  onChange={handleChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={datiania.youngestLicensedAge}
                  variant="outlined"
                >
                  {etaGuidatorePiuGiovane.map(option => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  margin="normal"
                  fullWidth
                  label="Massimale"
                  name="maximal"
                  onChange={handleChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={datiania.maximal}
                  variant="outlined"
                >
                  {ElencoMassimali.map(option => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={3}>
                <TextField
                  margin="normal"
                  fullWidth
                  label="Frazionamento"
                  name="splittingUp"
                  onChange={handleChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={datiania.splittingUp}
                  variant="outlined"
                >
                  {tipoFrazionamento.map(option => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </div>
        </>;
      case 5:
        return <Paper className={classes.paper}>
          <div className={classes.progress}>{progress}</div>
          {prevRS < prevRQ &&
            <div className={classes.linearProgressDiv}>
              <LinearProgressWithLabel value={100 * prevRS / prevRQ} />
              {images.length > 0 &&
                <Carousel
                  className={classes.Carousel}
                  autoPlay={true}
                  //animation={"fade"}
                  indicators={true}
                  timeout={500}
                  cycleNavigation={true}
                  navButtonsAlwaysVisible={true}
                  navButtonsAlwaysInvisible={false}
                  fullHeightHover={true}
                >
                  {
                    images.map((item, index) => {
                      return <CardMedia
                        className={classes.Media}
                        image={item.image}
                      >
                      </CardMedia>
                    })
                  }
                </Carousel>
              }
            </div>
          }

          {background === false && companyData.map((company, index) =>
            <Box className={classes.root} m={2} >
              <QuotationDetails key={index} factories={factories} factoriesHub={factoriesHub} company={company} datipre={datipreventivo[company.sfo_id + company.paramurl + company.type]} saveActive={false} />
            </Box>
          )}

          {background === true &&
            <Box m={2}>
              <Typography className={classes.cellTitleSoon} variant="h6" gutterBottom>
                Inserimento dati avvenuto con successo, i preventivi saranno presto disponibili all'interno del riepilogo
              </Typography>
            </Box>
          }

          {prevRS === prevRQ && images.length > 0 &&
            <Carousel
              className={classes.Carousel}
              autoPlay={true}
              animation={"fade"}
              indicators={true}
              timeout={500}
              cycleNavigation={true}
              navButtonsAlwaysVisible={true}
              navButtonsAlwaysInvisible={false}
              fullHeightHover={true}
            >
              {
                images.map((item, index) => {
                  return <CardMedia
                    key={index}
                    className={classes.Media}
                    image={item.image}
                  >
                  </CardMedia>
                })
              }
            </Carousel>
          }
        </Paper>;
      case 6:
        return <div className={classes.paper}>
          {NumberQuotation &&
            <QuotationSingleDetail numberquotation={NumberQuotation} visual={true} />
          }
        </div>;
      default:
        return 'Unknown step';
    }
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (

    <main className={classes.content}>
      <BackGround open={loading} />
      <ValidatorForm
        onSubmit={() => {
        }}
        onError={errors => console.log(errors)}
      >

        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>

          <Grid container spacing={3}>
            <Grid className={classes.containerButton} item md={8}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" href="/" >
                  {NAME_APP}
                </Link>
                <Link color="inherit" href="/car" >
                  Auto
                </Link>
                <Typography color="textPrimary">Richiesta preventivo {targa}</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid className={classes.containerButton} item md={4}>

              <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                Indietro
              </Button>
              {activeStep < steps.length &&
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  className={classes.button}


                  disabled={activeStep === steps.length - 1 || (activeStep === 5 && prevRS < prevRQ && prevRQ > 0)}
                >
                  Avanti
                </Button>}

            </Grid>
          </Grid>




          <Card>

            <CardContent>
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};

                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>


              <div className={classes.wrapper}>

                {activeStep === steps.length ? (
                  <div>
                    <Typography className={classes.instructions}>
                      Richiesta Preventivo completata
                    </Typography>
                    <Button onClick={handleReset} className={classes.button}>
                      Nuovo preventivo
                    </Button>
                  </div>
                ) : (
                  <>
                    {getStepContent(activeStep)}

                  </>
                )}
              </div>

            </CardContent>


          </Card>
          <Grid container spacing={3}>
            <Grid className={classes.containerButton} item md={12}>

              <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                Indietro
              </Button>
              {activeStep < steps.length &&
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  className={classes.button}


                  disabled={activeStep === steps.length - 1 || (activeStep === 5 && prevRS < prevRQ && prevRQ > 0)}
                >
                  Avanti
                </Button>}

            </Grid>
          </Grid>
        </Container>






        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={handleClose} severity={alertseverity}>
            {alertmsg}
          </Alert>
        </Snackbar>

      </ValidatorForm>



    </main>
  );
}


/*
{savePDF && NumberQuotation  && Quote &&
            <PublicPDF Quote={Quote} NumberQuotation={NumberQuotation} images={images} factories={factories} onSave={OnSavePDF}/>                          
          }
*/
