import React from 'react';
import {makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';
import MaterialTable from 'material-table';
import {id_cliente,ReturnInfoClient} from '../containers/logincontext';
import {API_URL_CONNECTORS} from '../config/config';
import BlockIcon from '@material-ui/icons/Block';
import Avatar from '@material-ui/core/Avatar';
import { green, red,pink } from '@material-ui/core/colors';
import Tooltip from '@material-ui/core/Tooltip';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import axios   from 'axios';
import Box from '@material-ui/core/Box';
import Auth from '../auth/auth';
import Typography from '@material-ui/core/Typography';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import {NAME_APP} from '../config/config';




const useStyles = makeStyles((theme) => ({
    alignFab: {
        marginLeft: 'auto',
      },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),      
    },
    img: {            
        maxWidth: '80px',
        maxHeight: '40px',
        justifyContent: 'center'
    },
    red_fill: {
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
    },
    green_fill: {
        color: '#fff',
        backgroundColor: green[500],
    },
    red: {
      color: red[500]
      
    },
    green: {
        color: green[500]        
    },
    customBadgeOk: {
      backgroundColor: green[500],
      color: "white"
    },
    customBadgeKo: {
      backgroundColor: red[500],
      color: "white"
    },
    pink: {
        color: theme.palette.getContrastText(pink[500]),
        backgroundColor: pink[500],
      },
}));

/*const listOrg = async (tokenApp) => {         
  
    
    try {        
    const result = await axios.get( API_URL_ORGANIZATIONS_LIST ,{
      headers:{            
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+tokenApp,                            
      }});            
    return result;
  
  } catch( e ){                
      return Promise.reject(e.response) ;
  }
  };*/

export default function ConnectorsLists() {    
    const orglists=Auth.getOrganizations();    
    const classes = useStyles();    
    const myTextIcon = React.useRef(null);


    

    const initcolums=[                                              
        { 
        title: 'Organizzazione', 
        field: 'id_organization' ,
        align: 'center',                                          
        editComponent: props => {
            return <select value={props.value}
                    defaultValue={{ label: orglists[0].description, value: orglists[0].id }}
                    onChange={e => props.onChange(e.target.value)}>
                    {!Number.isInteger(props.value)    &&
                    <option value="">Selezionare l'organizzazione</option>}
                            {orglists.map((org)=>
                                <option value={org.id}>{org.description}</option>)}
            </select>            
          },                                                                               
        render: rowData =><Box><img className={classes.img} alt={rowData.orgdescription} src={rowData.orgimage} /></Box>                                  
    },
    { title: 'Descrizione', field: 'description' },                                                                
    { 
        title: 'Tipo', 
        field: 'type', 
        lookup: { 1: 'In', 2: 'Out' },                
    },
    
    { title: 'Stato', 
      field: 'enabled',
      lookup: { 1: 'Abilitato', 0: 'Disabilitato' },
      render: rowData =><Tooltip title={rowData.enabled===1 ? "Abilitato":"Disabilitato"}>                                                                                                        
            {rowData.enabled===1 ? <CheckCircleOutlineIcon className={classes.green} />:<BlockIcon className={classes.red}/>}                                                    
        </Tooltip>                                       
    },
    { title: 'Utente', field: 'username' },
    { 
      title: 'Password', 
      field: 'password',
      render: rowData => <>{rowData.password!==null && rowData.password.replace(/./g,"*")}</>
    },
    { title: 'Link', field: 'connection_link' },
    { title: 'Tentativi', field: 'relay' }        
    ];

    const [columns, ] = React.useState(initcolums);                  
    

    return (
        <main className={classes.content}>                        
            <div className={classes.appBarSpacer} />            
            <Container maxWidth="lg" className={classes.container}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" href="/" >
                        {NAME_APP}
                    </Link>                                        
                    <Typography color="textPrimary">Connettori</Typography>                                            
                </Breadcrumbs>

                <Card>
                    <CardContent>
                    <MaterialTable 
                       icons={{
                        Add: props => (
                          <div ref={myTextIcon} className={classes.addButton}>
                            <Avatar className={classes.pink}>
                            <AddToPhotosIcon/>
                            </Avatar>                                                        
                          </div>
                        ),
                      }}
                        title="Connettori"
                        columns={columns}                        
                        localization={{
                            pagination: {
                                labelDisplayedRows: '{from}-{to} di {count}',
                                labelRowsSelect: 'per pagina',
                                labelRowsPerPage : 'per pagina',
                                firstAriaLabel: 'prima pagina',
                                firstTooltip : 'prima pagina',
                                previousAriaLabel: 'pagina precedente',
                                previousTooltip: 'pagina precedente',
                                nextAriaLabel: 'pagina successiva',
                                nextTooltip: 'pagina successiva',
                                lastAriaLabel: 'ultima pagina',
                                lastTooltip: 'ultima pagina',
                            },
                            toolbar: {  
                                nRowsSelected: '{0} elementi selezionati',
                                searchTooltip: 'Ricerca',
                                searchPlaceholder : 'Ricerca'
                            },                            
                            header: {
                                actions: ''
                            },
                            body: {
                                emptyDataSourceMessage: 'Nessun record da visualizzare',
                                filterRow: {
                                    filterTooltip: 'Filtri'
                                },
                                editRow:{
                                    saveTooltip: "Conferma",
                                    cancelTooltip: "Annulla",
                                    deleteText: "Sei sicuro di voler cancellare queste credenziali?"
                                },
                                addTooltip: "Aggiungi nuove credenziali",
                                deleteTooltip: "Cancella",
                                editTooltip: "Modifica",
                            
                            }                          
                            }}
                            options={{
                            pageSize: 10,
                            pageSizeOptions: [5,10],
                            paginationType: "stepped",
                            search: true,
                            headerStyle: {
                            backgroundColor: ReturnInfoClient(id_cliente,"primary_color"),
                            color:  '#ffffff',    
                                    '&:hover': {
                                        color: '#ffffff',
                                    },
                                    '&:active': {
                                        color: '#ffffff',
                                    }          
                            }                            
                            }}
                        data={query =>
                                new Promise((resolve, reject) => {            
                                    let url = API_URL_CONNECTORS;
                                        url += '?per_page=' + query.pageSize
                                        url += '&page=' + (query.page + 1)

                                    if(query.search.length>3){
                                        url += '&search=' + query.search
                                    }            
                                    if(query.orderBy!==undefined){                                    
                                        url += '&orderby=' + query.orderBy.field
                                        url += '&orderdirection=' + query.orderDirection                                    
                                    }
            
                                    const requestOptions = {                                    
                                        headers: { 
                                            'Content-Type': 'application/json',
                                            'Authorization': 'Bearer '+Auth.getToken(),                
                                        },              
                                    };


                                    fetch(url,requestOptions)            
                                    .then(                  
                                        response => response.json()
                                    )
                                    .then(                
                                        result => {                                      
                                        resolve({
                                        data: result.data,                  
                                        page: result.current_page-1,
                                        totalCount: result.total,
                                        })
                                    })
                                })
                            }
                            editable={{
                                onRowAdd: newData =>
                                  new Promise((resolve, reject) => {
                                      const url=API_URL_CONNECTORS;
                                      axios.put(url,{
                                          description:newData.description,
                                          id_organization:newData.id_organization,
                                          enabled:newData.enabled,
                                          username:newData.username,
                                          password:newData.password, 
                                          connection_link:newData.connection_link,
                                          type:newData.type,
                                          relay:newData.relay
                                      },
                                      {
                                          headers:{
                                            'Authorization': 'Bearer '+Auth.getToken(),
                                            'Content-Type': 'application/json'
                                          }
                                      }
                                    ).then(payload=>{
                                        
                                    }) ;        
                                    resolve();                                                                
                                  }),
                                onRowUpdate: (newData, oldData) =>
                                  new Promise((resolve, reject) => {
                                    const index = oldData.id;                                    
                                    const url=API_URL_CONNECTORS + "/" + index;                                    
                                    axios.patch(url,{
                                        description:newData.description,
                                          id_organization:newData.id_organization,
                                          enabled:newData.enabled,
                                          username:newData.username,
                                          password:newData.password, 
                                          connection_link:newData.connection_link,
                                          type:newData.type,
                                          relay:newData.relay
                                      },
                                      {
                                          headers:{
                                            'Authorization': 'Bearer '+Auth.getToken(),
                                              'Content-Type': 'application/json'
                                          }
                                      }
                                    ).then(payload=>{
                                        
                                    }) ;                                                                        
                                    resolve();
                                    
                                  }),
                                onRowDelete: 
                                
                                oldData =>
                                new Promise((resolve, reject) => {
                                    
                                    const index = oldData.id;                                    
                                    const url=API_URL_CONNECTORS + "/" + index;
                                    
                                    axios.delete(url,
                                    {
                                        headers:{
                                          'Authorization': 'Bearer '+Auth.getToken(),
                                            'Content-Type': 'application/json'
                                        }
                                    }
                                    ).then(payload=>{
                                        
                                    }) ;                                                                        
                                    resolve();
                                  })}
                                }                                                                                                                                                                        
                        />

                    </CardContent>        
                    
                    
                </Card>          
          </Container>                      
        </main>
      );
};