import React from 'react';
import { Route, Switch } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import itLocale from "date-fns/locale/it";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Helmet } from "react-helmet";
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import Login from './components/login';
import Login810 from './components/login810';
import SignUp from './components/SignUp';
import SignUpWithAgencyInfo from './components/SignUpWithAgencyInfo';
import ConnectorsLists from './components/ConnectorsList';
import PrivateRoute from './components/privateroute';
import ConfirmLogin from './components/confirmlogin';
import ConfirmLoginSSO from './components/confirmloginsso';
import Header from './components/header';
import Quotation from './components/quotation';
import QuotationVan from './components/quotationvan';
import QuotationHouse from './components/quotationhouse';
import QuotationHouseFacile from './components/QuotationHouseFacile';
import QuotationInjuriesRedirect from './components/QuotationInjuriesRedirect';
import QuotationTcm from './components/quotationTcm';
import QuotationList from './components/QuotationList';
import QuotationList2 from './components/QuotationList2';
//import QuotationListReport from './components/QuotationListReport';
import QuotationListByDate from './components/QuotationListByDate';
import QuotationDataWareHouse from './components/QuotationDataWareHouse';
import QuotationSingle from './components/quotationsingle';
import QRCodeReader from './components/qrcodereader';
import HomePage from './components/HomePage';
import RecoverPassword from './components/recover';
import ProductSelect from './components/ProductSelect';
import Organizations from './components/organizations';
import Groups from './components/groups';
import Agencies from './components/agencies';
import Products from './components/Products';
import Goods from './components/Goods';
import Guarantees from './components/Guarantees';
import CompaniesParameters from './components/companiesParameters';
import Factories from './components/Factories';
import Account from './components/Account';
import HomePageOnlyProd from './components/HomePageOnlyProd';
import Permissions from './components/permissions';
import UsersList from './components/usersList';
import SicAniaList from './components/sicAniaList';
import CategoriesLists from './components/CategoriesList';
import Logout from './components/logout';
import { UserDataProvider, OrganizationsDataProvider, PosDataProvider } from './containers/logincontext';
import { SUPER_ADMIN, ADMIN } from './config/config';
import { NAME_APP } from './config/config';
import Discounts from './components/Discounts';
import Conventions from './components/Conventions';
import Payments from './components/Payments';
import ResultPayment from './components/resultpayment'
import ProfessionalRC from './components/ProfessionalRC';
import QuotationHouseRedirect from './components/QuotationHouseRedirect';
import QuotationSingleRedirect from './components/QuotationSingleRedirect';
import QuotationRcpRedirect from './components/QuotationRcpRedirect';
import QuotationMotorBikeBike from './components/QuotationMotorBikeBike';
import QuotationBike from './components/QuotationBike';
import QuotationTcmRedirect from './components/QuotationTcmRedirect';
import QuotationGfRedirect from './components/QuotationGfRedirect';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  }
}))

function App() {
  const classes = useStyles();
  const theme = createMuiTheme({
    palette: {
      background: {
        default: '#ffffff'
      },
      primary: {
        light: '#757ce8',
        main: '#000000',
        //dark: 'rgba(141, 23, 0, 1)',
        dark: '#f9b406',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ff7961',
        main: '#f9b406',  //#111111
        dark: '#ba000d',
        contrastText: '#000',
      },
    },
  });

  return (
    <UserDataProvider>
      <OrganizationsDataProvider>
        <PosDataProvider>
          <ThemeProvider theme={theme}>
            <Helmet>
              <title>{NAME_APP}</title>
              <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
              <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
              <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Poppins:300,400,500,700&display=swap" />
            </Helmet>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
              <div className={classes.root}>
                <Switch>
                  <Route path="/registrazione">
                    <SignUp />
                  </Route>
                  <Route path="/provaquotiamo">
                    <SignUpWithAgencyInfo title={"Prova"} logo={"/images/Finital.png"} profile={1} />
                  </Route>

                  <Route path="/scopriquotiamo"  >
                    <SignUpWithAgencyInfo title={"Scopri"} logo={"/images/Finital.png"} profile={1} />
                  </Route>

                  <Route path="/recrea-insurance-tools-house/quotiamogratis"  >
                    <SignUpWithAgencyInfo title={"ReCrea - Prova Gratis"} logo={"/images/recrea.svg"} profile={2} />
                  </Route>

                  <Route path="/login">
                    <Login />
                  </Route>
                  <Route path="/login810">
                    <Login810 />
                  </Route>

                  <Route path="/resultpayment/:numberquotation/:paymentresult?">
                    <ResultPayment />
                  </Route>

                  <Route exact path="/confermalogin/:check_token">
                    <ConfirmLogin />
                  </Route>

                  <Route exact path="/confermalogin/:check_token/:id/:codice">
                    <ConfirmLogin />
                  </Route>

                  <Route exact path="/sso/confermalogin">
                    <ConfirmLoginSSO />
                  </Route>

                  <PrivateRoute exact path="/" render={() => (
                    <>
                      {/* <Quotation /> */}
                      {/* <HomePage /> */}
                      <HomePageOnlyProd />
                    </>
                  )} />

                  <PrivateRoute exact path="/home" render={() => (
                    <>
                      {/* <HomePage /> */}
                      <HomePageOnlyProd />
                    </>
                  )} />

                  <PrivateRoute exact path="/car" render={() => (
                    <>
                      <Header />
                      <Quotation />
                    </>
                  )} />

                  <PrivateRoute exact path="/van" render={() => (
                    <>
                      <Header />
                      <QuotationVan />
                    </>
                  )} />

                  <PrivateRoute exact path="/motorbike" render={() => (
                    <>
                      <Header />
                      <QuotationMotorBikeBike />
                      {/*<QuotationMotorBike />*/}
                    </>
                  )} />

                  {/*<PrivateRoute exact path="/bike" render={() => (
                    <>
                      <Header />
                      <QuotationBike />
                    </>
                  )} />*/}

                  <PrivateRoute exact path="/house" render={() => (
                    <>
                      <QuotationHouseRedirect />
                    </>
                  )} />

                  <PrivateRoute exact path="/injuries" render={() => (
                    <>
                      <QuotationInjuriesRedirect />
                    </>
                  )} />

                  <PrivateRoute exact path="/tcm" render={() => (
                    <>
                      <QuotationTcmRedirect />
                    </>
                  )} />

                  {/*<PrivateRoute exact path="/professionalrc" render={() => (
                    <>
                      <ProfessionalRC />
                    </>
                  )} />*/}

                  <PrivateRoute exact path="/rcp" render={() => (
                    <>
                      <QuotationRcpRedirect />
                    </>
                  )} />

                  <PrivateRoute exact path="/gf" render={() => (
                    <>
                      <QuotationGfRedirect />
                    </>
                  )} />

                  <Route path="/passwordreset">
                    <RecoverPassword />
                  </Route>

                  <PrivateRoute exact path="/quotationlist" render={() => (
                    <>
                      <Header />
                      <QuotationList />
                    </>
                  )} />

                  <PrivateRoute exact path="/quotationlist2" render={() => (
                    <>
                      <Header />
                      <QuotationList2 />
                    </>
                  )} />

                  <PrivateRoute accesslevel={ADMIN} exact path="/quotationlistbydate" render={() => (
                    <>
                      <Header />
                      {/* <QuotationListByDate /> */}
                      <QuotationDataWareHouse />
                    </>
                  )} />

                  <PrivateRoute path="/quotationsingle/:numberquotation/:paymentresult?" render={() => (
                    <>
                      <QuotationSingleRedirect />
                    </>
                  )} />

                  <PrivateRoute exact path="/account" render={() => (
                    <>
                      <Header />
                      <Account />
                    </>
                  )} />

                  <PrivateRoute exact path="/qrcode" render={() => (
                    <>
                      <Header />
                      <QRCodeReader />
                    </>
                  )} />

                  <PrivateRoute exact path="/productselect" render={() => (
                    <>
                      <Header />
                      <ProductSelect />
                    </>
                  )} />

                  <PrivateRoute accesslevel={SUPER_ADMIN} exact path="/payments" render={() => (
                    <>
                      <Header />
                      <Payments />
                    </>
                  )} />

                  <PrivateRoute accesslevel={ADMIN} exact path="/discounts" render={() => (
                    <>
                      <Header />
                      <Discounts />
                    </>
                  )} />

                  <PrivateRoute accesslevel={ADMIN} exact path="/conventions" render={() => (
                    <>
                      <Header />
                      <Conventions />
                    </>
                  )} />

                  <PrivateRoute accesslevel={ADMIN} exact path="/factories" render={() => (
                    <>
                      <Header />
                      <Factories />
                    </>
                  )} />

                  <PrivateRoute accesslevel={ADMIN} exact path="/companiesparameters" render={() => (
                    <>
                      <Header />
                      <CompaniesParameters />
                    </>
                  )} />

                  <PrivateRoute accesslevel={SUPER_ADMIN} exact path="/sicania" render={() => (
                    <>
                      <Header />
                      <SicAniaList />
                    </>
                  )} />

                  <PrivateRoute accesslevel={SUPER_ADMIN} exact path="/connectors" render={() => (
                    <>
                      <Header />
                      <ConnectorsLists />
                    </>
                  )} />

                  <PrivateRoute accesslevel={SUPER_ADMIN} exact path="/goods" render={() => (
                    <>
                      <Header />
                      <Goods />
                    </>
                  )} />

                  <PrivateRoute accesslevel={SUPER_ADMIN} exact path="/products" render={() => (
                    <>
                      <Header />
                      <Products />
                    </>
                  )} />

                  <PrivateRoute accesslevel={SUPER_ADMIN} exact path="/categories" render={() => (
                    <>
                      <Header />
                      <CategoriesLists />
                    </>
                  )} />

                  <PrivateRoute accesslevel={SUPER_ADMIN} exact path="/guarantees" render={() => (
                    <>
                      <Header />
                      <Guarantees />
                    </>
                  )} />

                  <PrivateRoute accesslevel={ADMIN} exact path="/organizations" render={() => (
                    <>
                      <Header />
                      <Organizations />
                    </>
                  )} />

                  <PrivateRoute accesslevel={ADMIN} exact path="/groups" render={() => (
                    <>
                      <Header />
                      <Groups />
                    </>
                  )} />
                  <PrivateRoute accesslevel={ADMIN} exact path="/agencies" render={() => (
                    <>
                      <Header />
                      <Agencies />
                    </>
                  )} />

                  <PrivateRoute accesslevel={SUPER_ADMIN} exact path="/userslist" render={() => (
                    <>
                      <Header />
                      <UsersList />
                    </>
                  )} />

                  <PrivateRoute accesslevel={SUPER_ADMIN} exact path="/permissions" render={() => (
                    <>
                      <Header />
                      <Permissions />
                    </>
                  )} />

                  <PrivateRoute exact path="/logout" render={() => (
                    <Logout />
                  )} />
                </Switch>
              </div>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </PosDataProvider>
      </OrganizationsDataProvider>
    </UserDataProvider>
  );
}

export default App;

