import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';
import axios from 'axios';
import Auth from '../auth/auth';
import AuthService from '../auth/authservice';
import Grid from '@material-ui/core/Grid';
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from 'moment';
import { PosDataContext } from '../containers/logincontext';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { ValidatorForm } from 'react-material-ui-form-validator';
import validator from 'validator';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Card from '@material-ui/core/Card';
import CheckSvgButton from './checkboxsvg';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import { CardMedia } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import NumberFormat from 'react-number-format';
import { number_format, parseFloat2Decimals } from './utils';
import Box from '@material-ui/core/Box';
import QuotationDetails from './quotationdetails';
import SubjectDetails from './subjectDetails';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BackGround from './background';
import QuotationSingleDetailFacile from './QuotationSingleDetailFacile';
import {
  API_URL_LIST_AGENCIES_USER,
  API_URL_GET_INFOCITYBYCITY,
  API_URL_QUOTATION_HOUSE,
  API_URL_QUOTATION_LIST,
  API_URL_INFOIMAGES_ALL,
  APP_TOKEN_QUOTIAMO,
  API_URL_LIST_SERVICES_AGENCY_USER,
  API_URL_FACTORIES_LIST,
  API_URL_ALLGUARANTEES,
  API_URL_MULTISESSION,
  API_URL_GET_INFOBIRTH
} from '../config/config';
import { red } from '@material-ui/core/colors';
import { NAME_APP } from '../config/config';
import queryString from 'query-string'
import { useLocation } from 'react-router';
import { TextValidator } from 'react-material-ui-form-validator';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {
  tipoFrazionamento, durationsList,
  PianoList,
  PosizioneList,
  TipologiaList,
  PossessoList,
  TipoDimoraList,
  classeCostruttivaList,
  tipologiaCostruzioneList
} from '../config/config';
import Carousel from 'react-material-ui-carousel';
import CssBaseline from '@material-ui/core/CssBaseline';

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function TextFieldCustomMassimale(props) {

  return (
    <TextField label="Massimale" variant="outlined" {...props} />
  );
}

function TextFieldCustomFabbricato(props) {
  return (
    <TextField label="Valore fabbricato" variant="outlined" {...props} />
  );
}

function TextFieldCustomContenuto(props) {
  return (
    <TextField label="Valore contenuto" variant="outlined" {...props} />
  );
}

const getListOfCity = async (token, url, search) => {
  try {
    const result = await axios.get(url + API_URL_GET_INFOCITYBYCITY, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      params: {
        city: search
      }
    });
    return result;

  } catch (e) {
    return Promise.reject(e.response);
  }
};

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  BoxContainer: {
    padding: "10px"
  },
  fulldiv: {
    width: "-webkit-fill-available"
  },
  FirstDataRow: {
    marginTop: "20px",
    marginBottom: "0px",
  },
  TitleCategory: {
    marginTop: "20px",
    fontSize: "16px",
    fontWeight: "600"
  },
  DetailWarranty: {
    marginTop: "5px",
    fontSize: "12px",
    fontWeight: "400"
  },
  SubTitle: {
    marginTop: "20px",
    fontSize: "16px",
    fontWeight: "600"
  },
  button: {
    height: 30
  },
  Carousel: {
    width: "100%"
  },
  Media: {
    height: 800
  },
  Carousel2: {
    width: "50%"
  },
  Media2: {
    height: 400
  },
  PaddingTop: {
    paddingTop: 30,
  },
  cellroot: {
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  prezzo: {
    align: 'right'
  },
  titleTable: {
    flex: '1 1 100%',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  linear_class: {
    width: '20%',
    '& > * + *': {
      marginTop: theme.spacing(2),
      zIndex: theme.zIndex.drawer + 2,
    },
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  root: {
    width: '90%'
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    //maxWidth:'100%',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    alignItems: 'center',
    flexDirection: 'column',
  },
  preventivo: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 800,
  },
  image: {
    width: 180,
    height: 60,
  },
  imgLogo: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '50px',
    marginBottom: '40px'
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  fixedHeight: {
    height: 240,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  pos: {
    marginBottom: 12,
  },
  containerButton: {
    alignItems: 'right',
    textAlign: 'right',
    marginBottom: '10px',
    marginTop: '10px'
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
    alignItems: 'center'
  },
  formControl: {
    width: '100%'
  },
  progress: {
    display: "none"
  },
  iconRoot: {
    color: red
  },
  linearProgressDiv: {
    width: '90%'
  }
}));

function getSteps() {
  return ['Dati immobile', 'Anagrafiche', 'Garanzie', 'Preventivo', 'Modifica/Conferma'];
}

function CheckActiveConvention(conventions, QuotationData) {
  let convention_accept = true;
  if (conventions.convention_rules !== null) {
    JSON.parse(conventions.convention_rules).forEach(
      (single_rules) => {

        if (single_rules.ProvinceList !== undefined) {

          if (single_rules.ProvinceList.value !== null && single_rules.ProvinceList.value !== "") {
            //non trovate le province
            let elencoProvince = single_rules.ProvinceList.value.split(",");
            if (elencoProvince.indexOf(QuotationData.provinciaAbbr) === -1) {
              convention_accept = false;
            }

          }
        }
        if (single_rules.BirthNation !== undefined) {
          if (single_rules.BirthNation.value !== null && single_rules.BirthNation.value !== "") {

            //Verifico se nato in paese straniero
            if (!isNaN(single_rules.BirthNation.value)) {
              var res = QuotationData.contractorInfo.fiscalCode.substring(11, 12).toUpperCase();
              if (parseInt(single_rules.BirthNation.value) === 1) {
                if (res === "Z") {
                  convention_accept = false;
                }
              } else if (parseInt(single_rules.BirthNation.value) === 2) {
                if (res !== "Z") {
                  convention_accept = false;
                }
              }
            }
          }
        }
        if (single_rules.PaymentMode !== undefined) {
          if (single_rules.PaymentMode.value !== null && single_rules.PaymentMode.value !== "") {
            if (Number(QuotationData.frazionamento) !== Number(single_rules.PaymentMode.value)) {
              convention_accept = false;
            }
          }
        }
      });
  }


  return convention_accept;

}

function CheckConvenzioni(QuotationData, company, conventionslist, flagapply) {
  let convention_active = {
    code: "",
    description: "",
    flex: 0
  };

  if (flagapply === 1) {
    let convComp = false;
    conventionslist.forEach(conventions => {
      if (company.facid === conventions.id_factory) {
        let convention_accept = CheckActiveConvention(conventions, QuotationData);
        if (convention_accept) {
          convention_active = conventions;
          if (conventions.companyapply === 1) {
            convComp = true;
          } else {
            convComp = false;
          }
        }
      }
    }
    );
    if (convComp === false) {
      let convention = {
        code: "",
        description: "",
        flex: 0
      };
      return convention;
    }
  } else {
    conventionslist.forEach(conventions => {
      if (company.facid === conventions.id_factory && conventions.companyapply === flagapply) {
        let convention_accept = CheckActiveConvention(conventions, QuotationData);
        if (convention_accept) {
          convention_active = conventions;
        }
      }
    }
    );
  }

  return convention_active;
}

const checkPreventivo = async (QuotationData, company, token, guarantees, guaranteesSelect, guaranteesValue, conventionslist, authToken) => {
  if (company.multisession === 1 && company.username === "" && company.password === "" && company.companyCode === "" && company.companyParams === "" && company.nodeCode === "") {
    if (company.paramurl === "hdi") {
      const result = {
        data: {
          error: "Servizio non disponibile",
          message: "Limite utenze in uso raggiunto, riprovare tra qualche minuto",
          path: "/getquotation",
          status: 503,
          errorCode: 1003,
          timestamp: moment().format("YYYY-MM-DD HH:mm:ss")
        }
      };
      return result;
    } else {
      const result = {
        data: {
          error: "Servizio non disponibile",
          message: "Utenze non disponibili, riprovare tra qualche minuto",
          path: "/getquotation",
          status: 503,
          errorCode: 1003,
          timestamp: moment().format("YYYY-MM-DD HH:mm:ss")
        }
      };
      return result;
    }
  }
  /*Verifica se proviene dalla stessa compagnia */

  let listCVT = [];
  let convenzioneAttiva = CheckConvenzioni(QuotationData, company, conventionslist, 1);
  if (convenzioneAttiva !== false && convenzioneAttiva.code !== "") {
    console.log("TROVATA CONVENZIONE DA INVIARE ALLA COMPAGNIA");
    //Al momento non usato
  }

  guarantees.forEach((element, index) => {
    if (guaranteesSelect[index]) {
      let massimale = 0;
      let massimalePerc = 0;
      let franchigia = 0;
      let franchigiaPerc = 0;
      let limite = 0;
      let limitePerc = 0;
      let scopertoPerc = 0;
      let scoperto = 0;

      if (element["input_type"] !== 0) {
        if (isNaN(guaranteesValue[index]) && guaranteesValue[index].indexOf('%') !== -1) {
          massimalePerc = Number(guaranteesValue[index].replace('%', ''));
        } else {
          massimale = Number(guaranteesValue[index]);
        }
      } else {
        if (company.guarantees_info[index].massimale !== null) {
          if (isNaN(company.guarantees_info[index].massimale) && company.guarantees_info[index].massimale.indexOf('%') !== -1) {
            massimalePerc = Number(company.guarantees_info[index].massimale.replace('%', ''));
          } else {
            massimale = Number(company.guarantees_info[index].massimale);
          }

        }
      }
      if (company.guarantees_info[index].franchigia !== null) {
        if (isNaN(company.guarantees_info[index].franchigia) && company.guarantees_info[index].franchigia.indexOf('%') !== -1) {
          franchigiaPerc = Number(company.guarantees_info[index].franchigia.replace('%', ''));
        } else {
          franchigia = Number(company.guarantees_info[index].franchigia);
        }

      }

      if (company.guarantees_info[index].limite_risarcimento_perc !== null) {
        limitePerc = Number(company.guarantees_info[index].limite_risarcimento_perc);
      }
      if (company.guarantees_info[index].limite_risarcimento_val !== null) {
        limite = Number(company.guarantees_info[index].limite_risarcimento_val);
      }

      if (company.guarantees_info[index].scoperto_perc !== null) {
        scopertoPerc = Number(company.guarantees_info[index].scoperto_perc);
      }

      if (company.guarantees_info[index].scoperto_val !== null) {
        scoperto = Number(company.guarantees_info[index].scoperto_val);
      }

      let tipoCalcolo = company.guarantees_info[index].tipo_calcolo !== null ? (company.guarantees_info[index].tipo_calcolo !== "default" ? company.guarantees_info[index].tipo_calcolo : company.guarantees_info[index].tipo_calcolo_default) : company.guarantees_info[index].tipo_calcolo_default;
      if (tipoCalcolo === "comp") {
        if (company.guarantees_info[index].service_parameters_code !== null) {
          company.guarantees_info[index].service_parameters_code.split(",").forEach(elementcode => {
            //temp.push({[element.id]:element.description});
            listCVT.push(
              {
                codice: elementcode,
                massimale: massimale,
                massimalePerc: massimalePerc,
                franchigia: franchigia,
                franchigiaPerc: franchigiaPerc,
                limite: limite,
                limitePerc: limitePerc,
                scoperto: scoperto,
                scopertoPerc: scopertoPerc
              }
            );
          });
        } else {
          listCVT.push(
            {
              codice: element["code"],
              massimale: massimale,
              massimalePerc: massimalePerc,
              franchigia: franchigia,
              franchigiaPerc: franchigiaPerc,
              limite: limite,
              limitePerc: limitePerc,
              scoperto: scoperto,
              scopertoPerc: scopertoPerc
            }
          );
        }
      }
    }
  });

  const json = JSON.stringify({
    applicationToken: APP_TOKEN_QUOTIAMO,
    username: company.username,
    password: company.password,
    companyCode: company.companyCode,
    companyParams: company.companyParams,
    nodeCode: company.nodeCode,
    commerciale: (company.commerciale === null || company.commerciale === "") ? "A046" : company.commerciale,
    agZona: (company.agZona === null || company.agZona === "") ? "A046" : company.agZona,
    reteVendita: "001",
    contractorInfo: {
      name: QuotationData.contractorInfo.name,
      surname: QuotationData.contractorInfo.surname,
      birthDate: QuotationData.contractorInfo.birthDate,
      gender: QuotationData.contractorInfo.gender,
      companyName: QuotationData.contractorInfo.companyName,
      phoneNumber: company.usedefaultphonenumber === 1 ? company.phonenumber : QuotationData.contractorInfo.phoneNumber,
      email: company.usedefaultemail === 1 ? company.email : QuotationData.contractorInfo.email,
      fiscalCode: QuotationData.contractorInfo.fiscalCode,
      vatNumber: QuotationData.contractorInfo.vatNumber,
      personType: (QuotationData.contractorInfo.subjectType === "1" || QuotationData.contractorInfo.subjectType === "") ? "FIS" : "GIU",
      subjectType: (QuotationData.contractorInfo.subjectType === "") ? "1" : QuotationData.contractorInfo.subjectType,
      residenceInfo: {
        toponym: QuotationData.contractorInfo.residenceInfo.toponym,
        address: QuotationData.contractorInfo.residenceInfo.address,
        streetNumber: QuotationData.contractorInfo.residenceInfo.streetNumber,
        city: QuotationData.contractorInfo.residenceInfo.city,
        cap: QuotationData.contractorInfo.residenceInfo.cap,
        provinceAbbr: QuotationData.contractorInfo.residenceInfo.provinceAbbr,
        istat: QuotationData.contractorInfo.residenceInfo.istat,
      },
      locationInfo: {
        toponym: QuotationData.contractorInfo.residenceInfo.toponym,
        address: QuotationData.contractorInfo.residenceInfo.address,
        streetNumber: QuotationData.contractorInfo.residenceInfo.streetNumber,
        city: QuotationData.contractorInfo.residenceInfo.city,
        cap: QuotationData.contractorInfo.residenceInfo.cap,
        provinceAbbr: QuotationData.contractorInfo.residenceInfo.provinceAbbr,
        istat: QuotationData.contractorInfo.residenceInfo.istat,
      }
    },
    ownerInfo: {
      name: QuotationData.ownerInfo.name,
      surname: QuotationData.ownerInfo.surname,
      birthDate: QuotationData.ownerInfo.birthDate,
      gender: QuotationData.ownerInfo.gender,
      companyName: QuotationData.ownerInfo.companyName,
      phoneNumber: company.usedefaultphonenumber === 1 ? company.phonenumber : QuotationData.ownerInfo.phoneNumber,
      email: company.usedefaultemail === 1 ? company.email : QuotationData.ownerInfo.email,
      fiscalCode: QuotationData.ownerInfo.fiscalCode,
      vatNumber: QuotationData.ownerInfo.vatNumber,
      personType: (QuotationData.ownerInfo.subjectType === "1" || QuotationData.ownerInfo.subjectType === "") ? "FIS" : "GIU",
      subjectType: (QuotationData.ownerInfo.subjectType === "") ? "1" : QuotationData.ownerInfo.subjectType,
      residenceInfo: {
        toponym: QuotationData.ownerInfo.residenceInfo.toponym,
        address: QuotationData.ownerInfo.residenceInfo.address,
        streetNumber: QuotationData.ownerInfo.residenceInfo.streetNumber,
        city: QuotationData.ownerInfo.residenceInfo.city,
        cap: QuotationData.ownerInfo.residenceInfo.cap,
        provinceAbbr: QuotationData.ownerInfo.residenceInfo.provinceAbbr,
        istat: QuotationData.ownerInfo.residenceInfo.istat,
      },
      locationInfo: {
        toponym: QuotationData.ownerInfo.residenceInfo.toponym,
        address: QuotationData.ownerInfo.residenceInfo.address,
        streetNumber: QuotationData.ownerInfo.residenceInfo.streetNumber,
        city: QuotationData.ownerInfo.residenceInfo.city,
        cap: QuotationData.ownerInfo.residenceInfo.cap,
        provinceAbbr: QuotationData.ownerInfo.residenceInfo.provinceAbbr,
        istat: QuotationData.ownerInfo.residenceInfo.istat,
      }
    },
    quoteInfo: {
      classeCostruttiva: QuotationData.classeCostruttiva,
      tipologiaProp: QuotationData.tipologiaProp,
      tipologiaImm: QuotationData.tipologiaImm,
      dimora: QuotationData.dimora,
      posizione: QuotationData.posizione,
      dataEffetto: QuotationData.dataEffetto,
      frazionamento: QuotationData.frazionamento,
      piano: QuotationData.piano,
      scalaInterno: QuotationData.scalaInterno,
      durataAnni: QuotationData.durataAnni,
      superficieM2: QuotationData.superficieM2,
      annoCostruzioneRistrutturazione: QuotationData.annoCostruzioneRistrutturazione,
      valoreFabbricato: QuotationData.valoreFabbricato,
      valoreContenuto: QuotationData.valoreContenuto,
      antifurto: QuotationData.antifurto,
      nCani: parseInt(QuotationData.nCani),
      nGatti: parseInt(QuotationData.nGatti),
      nPersNucleoFamiliare: parseInt(QuotationData.nPersNucleoFamiliare),
      tipologiaCostruzione: QuotationData.tipologiaCostruzione,
      ubicazione: {
        provinciaAbbr: QuotationData.provinciaAbbr,
        citta: QuotationData.citta,
        cap: QuotationData.cap,
        istat: QuotationData.istat,
        numeroCivico: QuotationData.numeroCivico,
        nazioneAbbr: QuotationData.nazioneAbbr,
        toponimo: QuotationData.toponimo,
        indirizzo: QuotationData.indirizzo,
        provincia: QuotationData.provincia,
        regione: QuotationData.regione
      },
      listaCVT: listCVT
    },

  }
  );

  const url_service = company.url + API_URL_QUOTATION_HOUSE + "/" + company.type + "/" + (company.type === "share" ? company.sharetoken : company.paramurl);

  try {
    const result = await axios.post(url_service, json, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      }
    });

    // chiamata sblocco credenziali multisession
    if (company.multisession === 1) {
      const url_multisession = API_URL_MULTISESSION + "/checkuse/" + company.multisessionId + "/0";
      await axios.get(url_multisession, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + authToken,
        }
      });
    }

    return result;
  } catch (e) {
    // chiamata sblocco credenziali multisession
    if (company.multisession === 1) {
      const url_multisession = API_URL_MULTISESSION + "/checkuse/" + company.multisessionId + "/0";
      await axios.get(url_multisession, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + authToken,
        }
      });
    }

    return Promise.reject(e.response);
  }
};

function AssignDefParameter(defOrg, defGrp, defAg, userData) {
  return ({
    tipologiaCostruzione: (defAg !== null && defAg.tipologiaCostruzione !== undefined ? defAg.tipologiaCostruzione : (defGrp !== null && defGrp.tipologiaCostruzione !== undefined ? defGrp.tipologiaCostruzione : (defOrg !== null && defOrg.tipologiaCostruzione !== undefined ? defOrg.tipologiaCostruzione : 0))), // A = Antisismica C = Cemento armato  S = Strutture portanti diverse
    classeCostruttiva: (defAg !== null && defAg.classeCostruttiva !== undefined ? defAg.classeCostruttiva : (defGrp !== null && defGrp.classeCostruttiva !== undefined ? defGrp.classeCostruttiva : (defOrg !== null && defOrg.classeCostruttiva !== undefined ? defOrg.classeCostruttiva : 0))), // T = Tradizionale, B = Bioedilizia, C = Chalet
    tipologiaProp: (defAg !== null && defAg.tipologiaProp !== undefined ? defAg.tipologiaProp : (defGrp !== null && defGrp.tipologiaProp !== undefined ? defGrp.tipologiaProp : (defOrg !== null && defOrg.tipologiaProp !== undefined ? defOrg.tipologiaProp : 0))), // P = Proprietà, LT = Locata a terzi
    tipologiaImm: (defAg !== null && defAg.tipologiaImm !== undefined ? defAg.tipologiaImm : (defGrp !== null && defGrp.tipologiaImm !== undefined ? defGrp.tipologiaImm : (defOrg !== null && defOrg.tipologiaImm !== undefined ? defOrg.tipologiaImm : 0))), // A = Appartamento, V1 = Villa a schiera, V2 = Villa singola
    dimora: (defAg !== null && defAg.dimora !== undefined ? defAg.dimora : (defGrp !== null && defGrp.dimora !== undefined ? defGrp.dimora : (defOrg !== null && defOrg.dimora !== undefined ? defOrg.dimora : 0))), // A = Abituale, S = Saltuaria
    posizione: (defAg !== null && defAg.posizione !== undefined ? defAg.posizione : (defGrp !== null && defGrp.posizione !== undefined ? defGrp.posizione : (defOrg !== null && defOrg.posizione !== undefined ? defOrg.posizione : 0))), // NI = Non isolato, I = Isolato
    dataEffetto: moment().format("YYYY-MM-DD"), // YYYY-MM-DD
    frazionamento: (defAg !== null && defAg.frazionamento !== undefined ? defAg.frazionamento : (defGrp !== null && defGrp.frazionamento !== undefined ? defGrp.frazionamento : (defOrg !== null && defOrg.frazionamento !== undefined ? defOrg.frazionamento : 0))), // 1=Annuale, 2=Semestrale, 3=Quadrimestrale, 4=Trimestrale, 6=Bimestrale, 12=Mensile
    piano: (defAg !== null && defAg.piano !== undefined ? defAg.piano : (defGrp !== null && defGrp.piano !== undefined ? defGrp.piano : (defOrg !== null && defOrg.piano !== undefined ? defOrg.piano : ""))),
    scalaInterno: (defAg !== null && defAg.scalaInterno !== undefined ? defAg.scalaInterno : (defGrp !== null && defGrp.scalaInterno !== undefined ? defGrp.scalaInterno : (defOrg !== null && defOrg.scalaInterno !== undefined ? defOrg.scalaInterno : ""))),
    durataAnni: (defAg !== null && defAg.durataAnni !== undefined ? defAg.durataAnni : (defGrp !== null && defGrp.durataAnni !== undefined ? defGrp.durataAnni : (defOrg !== null && defOrg.durataAnni !== undefined ? defOrg.durataAnni : 0))),
    superficieM2: (defAg !== null && defAg.superficieM2 !== undefined ? defAg.superficieM2 : (defGrp !== null && defGrp.superficieM2 !== undefined ? defGrp.superficieM2 : (defOrg !== null && defOrg.superficieM2 !== undefined ? defOrg.superficieM2 : ""))),
    annoCostruzioneRistrutturazione: (defAg !== null && defAg.annoCostruzioneRistrutturazione !== undefined ? defAg.annoCostruzioneRistrutturazione : (defGrp !== null && defGrp.annoCostruzioneRistrutturazione !== undefined ? defGrp.annoCostruzioneRistrutturazione : (defOrg !== null && defOrg.annoCostruzioneRistrutturazione !== undefined ? defOrg.annoCostruzioneRistrutturazione : ""))),
    valoreFabbricato: (defAg !== null && defAg.valoreFabbricato !== undefined ? defAg.valoreFabbricato : (defGrp !== null && defGrp.valoreFabbricato !== undefined ? defGrp.valoreFabbricato : (defOrg !== null && defOrg.valoreFabbricato !== undefined ? defOrg.valoreFabbricato : ""))),
    valoreContenuto: (defAg !== null && defAg.valoreContenuto !== undefined ? defAg.valoreContenuto : (defGrp !== null && defGrp.valoreContenuto !== undefined ? defGrp.valoreContenuto : (defOrg !== null && defOrg.valoreContenuto !== undefined ? defOrg.valoreContenuto : ""))),
    antifurto: (defAg !== null && defAg.antifurto !== undefined ? defAg.antifurto : (defGrp !== null && defGrp.antifurto !== undefined ? defGrp.antifurto : (defOrg !== null && defOrg.antifurto !== undefined ? defOrg.antifurto : false))),
    provinciaAbbr: "",
    citta: "",
    cap: "",
    istat: "",
    numeroCivico: (defAg !== null && defAg.numeroCivico !== undefined ? defAg.numeroCivico : (defGrp !== null && defGrp.numeroCivico !== undefined ? defGrp.numeroCivico : (defOrg !== null && defOrg.numeroCivico !== undefined ? defOrg.numeroCivico : ""))),
    nazioneAbbr: "",
    toponimo: (defAg !== null && defAg.toponimo !== undefined ? defAg.toponimo : (defGrp !== null && defGrp.toponimo !== undefined ? defGrp.toponimo : (defOrg !== null && defOrg.toponimo !== undefined ? defOrg.toponimo : ""))),
    indirizzo: (defAg !== null && defAg.indirizzo !== undefined ? defAg.indirizzo : (defGrp !== null && defGrp.indirizzo !== undefined ? defGrp.indirizzo : (defOrg !== null && defOrg.indirizzo !== undefined ? defOrg.indirizzo : ""))),
    provincia: "",
    regione: "",
    nCani: (defAg !== null && defAg.nCani !== undefined ? defAg.nCani : (defGrp !== null && defGrp.nCani !== undefined ? defGrp.nCani : (defOrg !== null && defOrg.nCani !== undefined ? defOrg.nCani : 0))),
    nGatti: (defAg !== null && defAg.nGatti !== undefined ? defAg.nGatti : (defGrp !== null && defGrp.nGatti !== undefined ? defGrp.nGatti : (defOrg !== null && defOrg.nGatti !== undefined ? defOrg.nGatti : 0))),
    nPersNucleoFamiliare: (defAg !== null && defAg.nPersNucleoFamiliare !== undefined ? defAg.nPersNucleoFamiliare : (defGrp !== null && defGrp.nPersNucleoFamiliare !== undefined ? defGrp.nPersNucleoFamiliare : (defOrg !== null && defOrg.nPersNucleoFamiliare !== undefined ? defOrg.nPersNucleoFamiliare : 1))),
    contractorInfo: {
      subjectType: (defAg !== null && defAg.subjectTypeHouse !== undefined ? defAg.subjectTypeHouse : (defGrp !== null && defGrp.subjectTypeHouse !== undefined ? defGrp.subjectTypeHouse : (defOrg !== null && defOrg.subjectTypeHouse !== undefined ? defOrg.subjectTypeHouse : "1"))),
      name: (defAg !== null && defAg.nameHouse !== undefined ? defAg.nameHouse : (defGrp !== null && defGrp.nameHouse !== undefined ? defGrp.nameHouse : (defOrg !== null && defOrg.nameHouse !== undefined ? defOrg.nameHouse : ""))),
      surname: (defAg !== null && defAg.surnameHouse !== undefined ? defAg.surnameHouse : (defGrp !== null && defGrp.surnameHouse !== undefined ? defGrp.surnameHouse : (defOrg !== null && defOrg.surnameHouse !== undefined ? defOrg.surnameHouse : ""))),
      birthDate: "",
      gender: "M",
      fiscalCode: (defAg !== null && defAg.fiscalCodeHouse !== undefined ? defAg.fiscalCodeHouse : (defGrp !== null && defGrp.fiscalCodeHouse !== undefined ? defGrp.fiscalCodeHouse : (defOrg !== null && defOrg.fiscalCodeHouse !== undefined ? defOrg.fiscalCodeHouse : ""))),
      companyName: (defAg !== null && defAg.companyNameHouse !== undefined ? defAg.companyNameHouse : (defGrp !== null && defGrp.companyNameHouse !== undefined ? defGrp.companyNameHouse : (defOrg !== null && defOrg.companyNameHouse !== undefined ? defOrg.companyNameHouse : ""))),
      vatNumber: (defAg !== null && defAg.vatNumberHouse !== undefined ? defAg.vatNumberHouse : (defGrp !== null && defGrp.vatNumberHouse !== undefined ? defGrp.vatNumberHouse : (defOrg !== null && defOrg.vatNumberHouse !== undefined ? defOrg.vatNumberHouse : ""))),
      birthCity: "",
      birthProvince: "",
      birthRegion: "",
      birthNationAbbr: "",
      birthNation: "",
      // email: (defAg !== null && defAg.emailhouse !== undefined ? defAg.emailhouse : (defGrp !== null && defGrp.emailhouse !== undefined ? defGrp.emailhouse : (defOrg !== null && defOrg.emailhouse !== undefined ? defOrg.emailhouse : ""))),
      // phoneNumber: (defAg !== null && defAg.phoneNumberHouse !== undefined ? defAg.phoneNumberHouse : (defGrp !== null && defGrp.phoneNumberHouse !== undefined ? defGrp.phoneNumberHouse : (defOrg !== null && defOrg.phoneNumberHouse !== undefined ? defOrg.phoneNumberHouse : ""))),
      email: userData !== null && userData !== undefined && userData.email !== undefined && userData.email !== null ? userData.email : "",
      phoneNumber: userData !== null && userData !== undefined && userData.phone !== undefined && userData.phone !== null ? userData.phone : "",
      residenceInfo: {
        city: "",
        cap: "",
        provinceAbbr: "",
        toponym: "",
        address: "",
        streetNumber: "",
        istat: "",
      }
    },
    ownerInfo: {
      subjectType: "1",
      name: "",
      surname: "",
      birthDate: "",
      gender: "M",
      fiscalCode: "",
      birthCity: "",
      birthProvince: "",
      birthRegion: "",
      birthNationAbbr: "",
      birthNation: "",
      email: "",
      phoneNumber: "",
      residenceInfo: {
        city: "",
        cap: "",
        provinceAbbr: "",
        toponym: "",
        address: "",
        streetNumber: "",
        istat: "",
      }
    },
  });
}

const getInfoBirth = (token, url, cf, nome, cognome) => {
  try {
    const result = axios.get(url + API_URL_GET_INFOBIRTH, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      params: {
        cf: cf,
        nome: nome,
        cognome: cognome
      }
    });
    return result;
  } catch (e) {
    return Promise.reject(e.response);
  }
};

export default function QuotationHouseFacile(props) {
  const userData = Auth.getUser();
  const [loading, setLoading] = React.useState(false);
  const [NumberQuotation, setNumberQuotation] = React.useState(false);
  const [, setPosActive] = React.useContext(PosDataContext);
  const [pos, setPos] = React.useState('');
  const [orgid, setOrgId] = React.useState(null);
  const [sameOwner, setSameOwner] = React.useState(true);
  const [QuotationData, setQuotationData] = React.useState(AssignDefParameter(null, null, null));
  const [agencieslists, setagencieslists] = React.useState([]);
  const [listCity, setlistCity] = React.useState([]);
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const [open, setOpen] = React.useState(false);
  const [alertseverity, setAlertSeverity] = React.useState("success");
  const [alertmsg, setAlertMsg] = React.useState(false);
  const [token, setToken] = React.useState(null);
  const [companyData, setcompanyData] = React.useState([]);
  const [urlservicequotiamo, seturlservicequotiamo] = React.useState("");
  const [datipreventivo, setdatipreventivo] = React.useState([]);

  const [posinfo, setPosInfo] = React.useState(null);

  const [prevRQ, setPrevRQ] = React.useState(0);
  const [prevRS, setPrevRS] = React.useState(0);

  const location = useLocation();
  const value = queryString.parse(location.search);
  //const [PreCompile,setPreCompile] = React.useState(false);
  const token810DN = value.checktoken;
  const [guarantees, setGuarantees] = React.useState([]);
  const [guaranteesSelect, setGuaranteesSelect] = React.useState([]);
  const [guaranteesValue, setGuaranteesValue] = React.useState([]);
  const [progress, setProgress] = React.useState(0);
  const [factories, setFactories] = React.useState([]);

  const idDN = value.id;
  const codiceDN = value.codice;
  let contaPreventiviDaFare = 0;
  let contaPreventiviFatti = 0;

  const [images, setImages] = React.useState([]);

  const [factoriesHub, setFactoriesHub] = React.useState([]);

  const handleChange = (event) => {
    setQuotationData({
      ...QuotationData,
      [event.target.name]: event.target.value
    });
  };

  const checkcompanyData = async (tokenApp, pos) => {
    try {
      const result = await axios.get(API_URL_LIST_SERVICES_AGENCY_USER + "/house/" + pos, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + tokenApp,
        }
      });
      return result;

    } catch (e) {
      return Promise.reject(e.response);
    }
  };

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 1000 ? 1 : prevProgress + 1));
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  React.useEffect(() => {
    if (orgid !== null) {
      const url = API_URL_ALLGUARANTEES + "/enabled/house/" + orgid;
      axios.get(url,
        {
          headers: {
            'Authorization': 'Bearer ' + Auth.getToken(),
            'Content-Type': 'application/json'
          }
        }
      ).then(payload => {
        let temp = [];
        let tempVal = [];
        payload.data.guarantees.forEach(element => {
          temp.push(false);
          if (element.input_type === 2) {
            tempVal.push(element.list_value.split(",")[0]);
          } else {
            tempVal.push("");
          }
        });
        setGuaranteesSelect(temp);
        setGuaranteesValue(tempVal);
        setGuarantees(payload.data.guarantees);
      });
    }

  }, [orgid]);

  React.useEffect(() => {
    const url = API_URL_FACTORIES_LIST;
    axios.get(url,
      {
        headers: {
          'Authorization': 'Bearer ' + Auth.getToken(),
          'Content-Type': 'application/json'
        }
      }
    ).then(payload => {
      setFactories(payload.data.factories);
    });

  }, []);

  /*React.useEffect(() => {
    if (idDN !== undefined) {
      const url = REGISTRY_URL;
      axios.post(url, {
        "id": idDN,
        "codice": codiceDN
      },
        {
          headers: {
            'Authorization': 'Bearer ' + token810DN,
            'Content-Type': 'application/json'
          }
        }
      ).then(payload => {
        //setPreCompile(payload.data.result);                                                                                                                                            
      });
    }
  }, [idDN, codiceDN, token810DN]);*/

  const handleClick = name => event => {

    let new_guaranteesSelect = guaranteesSelect;
    new_guaranteesSelect[name] = !guaranteesSelect[name];
    setGuaranteesSelect(new_guaranteesSelect);
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  React.useEffect(() => {
    const url = API_URL_LIST_AGENCIES_USER;
    axios.get(url,
      {
        headers: {
          'Authorization': 'Bearer ' + Auth.getToken(),
          'Content-Type': 'application/json'
        }
      }
    ).then(payload => {
      setagencieslists(payload.data);
      if (payload.data.length > 0) {
        setPos(payload.data[0].id);
        seturlservicequotiamo(payload.data[0].url);
        setPosInfo(payload.data[0]);
        setPosActive(payload.data[0]);
        setOrgId(payload.data[0].orgid);

        if (payload.data[0].defaultparameter !== null) {
          let defOrg = JSON.parse(payload.data[0].defOrg);
          let defGrp = JSON.parse(payload.data[0].defGrp);
          let defAg = JSON.parse(payload.data[0].defAg);
          let n = 0;
          for (const cvt of guarantees) {
            if (defAg !== null && defAg[cvt.code] !== undefined ? true : (defGrp !== null && defGrp[cvt.code] !== undefined ? true : (defOrg !== null && defOrg[cvt.code] !== undefined ? true : false))) {
              let flag = (defAg !== null && defAg[cvt.code] !== undefined ? defAg[cvt.code] : (defGrp !== null && defGrp[cvt.code] !== undefined ? defGrp[cvt.code] : (defOrg !== null && defOrg[cvt.code] !== undefined ? defOrg[cvt.code] : 0)));
              if (flag > 0) {
                guaranteesSelect[n] = true;
              }
            }
            n++;
          }
          setQuotationData(AssignDefParameter(defOrg, defGrp, defAg, userData));

          /*if(guarantees.length===0){
            const url=API_URL_ALLGUARANTEES + "/house/"+payload.data[0].orgid;
            axios.get(url,
            {
                headers:{
                'Authorization': 'Bearer '+Auth.getToken(),
                'Content-Type': 'application/json'
                }
            }
            ).then(payload=>{                       
                
                let temp=[];
                  payload.data.guarantees.forEach(element => {    
                      let status=false;
                      if(defpar[element.code]!==undefined){                              
                          if((defpar[element.code] & 1)===1){
                            status=true;
                          }
                      }
                      temp.push(status);
                  });                                         
                setGuaranteesSelect(temp);                 
                setGuarantees(payload.data.guarantees);                                   
            }) ;        
          }else{
            let temp=[];
            guarantees.forEach((element,index) => {
              let status=false;
              if(defpar[element.code]!==undefined){
                  console.log(defpar[element.code],element.code);
                  if((defpar[element.code] & 1)===1){
                    status=true;
                  }
              }
              temp.push(status);
            });
            setGuaranteesSelect(temp);                 
          } */

        }

        localStorage.setItem('pos', JSON.stringify(payload.data[0]));
        let urlservicequotiamo = payload.data[0].url;
        AuthService.signin(payload.data[0].username, payload.data[0].password, payload.data[0].url).then(
          payload => {
            setToken(payload);
            getListOfCity(payload, urlservicequotiamo, "").then(payload => {
              setlistCity(payload.data);
            });
          }).catch(error => {
          });

        const url = API_URL_INFOIMAGES_ALL + "/house/" + payload.data[0].id;
        axios.get(url,
          {
            headers: {
              'Authorization': 'Bearer ' + Auth.getToken(),
              'Content-Type': 'application/json'
            }
          }
        ).then(payload => {
          setImages(payload.data.images)
        });
      }

    });

  }, [setPosActive]);

  const HandleSaveQuotation = (companyListPrev) => {
    const userData = Auth.getUser();
    const json = JSON.stringify({
      clientinfo: QuotationData,
      quotationtype: "house"
    });

    const list = []
    companyListPrev.forEach((company) => {
      company.guarantees_select = guaranteesSelect;
      list.push(
        {
          company: company,
          datipre: datipreventivo[company.sfo_id + company.paramurl + company.type]
        }
      )
    })

    const url = API_URL_QUOTATION_LIST;
    axios.put(url, {
      product: window.location.pathname,
      id_user: userData.id,
      id_agency: pos,
      clientinfo: json,
      detail: JSON.stringify(list),
      select: "",
    },
      {
        headers: {
          'Authorization': 'Bearer ' + Auth.getToken(),
          'Content-Type': 'application/json'
        }
      }
    ).then(payload => {
      if (payload.status === 200) {
        setAlertMsg("Inserimento dati avvenuto con successo");
        setAlertSeverity("success");
        setNumberQuotation(payload.data.numberquotation);

      } else {
        setAlertMsg("Errore durante l'inserimento dati");
        setAlertSeverity("error");
      }
      setOpen(true);

    }).catch((error) => {
      setAlertMsg(error.response.data.error);
      setAlertSeverity("error");
      setOpen(true);
    });
  }

  const handleNext = () => {
    switch (activeStep) {
      case 0:
        if (pos === "") {
          setAlertMsg("Nessun punto vendita abilitato, impossibile effettuare il preventivo");
          setAlertSeverity("error");
          setOpen(true);
          break;
        }

        let dataMax = moment().add(30, "days");
        let dataEffetto = moment(QuotationData.dataEffetto);
        if (dataEffetto > dataMax) {
          setAlertMsg("Non è possibile effettuare un preventivo con una scadenza superiore ad un mese");
          setAlertSeverity("error");
          setOpen(true);
          break;
        }

        checkcompanyData(Auth.getToken(), pos).then(
          payload => {
            let companyListPrev = payload.data.services;
            if (companyListPrev.length < 1) {
              setAlertMsg("Prodotto non attivo per questo punto vendita");
              setAlertSeverity("error");
              setOpen(true);
              return;
            }

            if (QuotationData.durataAnni === 0) {
              setAlertMsg("Seleziona la durata");
              setAlertSeverity("error");
              setOpen(true);
              return;
            }
            if (QuotationData.frazionamento === 0) {
              setAlertMsg("Seleziona il frazionamento");
              setAlertSeverity("error");
              setOpen(true);
              return;
            }
            if (QuotationData.citta === "" || QuotationData.cap === "" || QuotationData.provinciaAbbr === "") {
              setAlertMsg("Seleziona comune di ubicazione dell'immobile");
              setAlertSeverity("error");
              setOpen(true);
              return;
            }
            if (QuotationData.classeCostruttiva === 0) {
              setAlertMsg("Seleziona la classe costruttiva");
              setAlertSeverity("error");
              setOpen(true);
              return;
            }
            if (QuotationData.tipologiaCostruzione === 0) {
              setAlertMsg("Seleziona la tipologia costruzione");
              setAlertSeverity("error");
              setOpen(true);
              return;
            }
            if (QuotationData.annoCostruzioneRistrutturazione === "") {
              setAlertMsg("Inserisci anno di costruzione ristrutturazione");
              setAlertSeverity("error");
              setOpen(true);
              return;
            }
            if (QuotationData.superficieM2 === "") {
              setAlertMsg("Inserisci la superficie");
              setAlertSeverity("error");
              setOpen(true);
              return;
            }
            if (QuotationData.tipologiaProp === 0) {
              setAlertMsg("Seleziona la tipologia di proprietà");
              setAlertSeverity("error");
              setOpen(true);
              return;
            }
            if (QuotationData.tipologiaImm === 0) {
              setAlertMsg("Seleziona la tipologia di immobile");
              setAlertSeverity("error");
              setOpen(true);
              return;
            }
            if (QuotationData.tipologiaImm === "A" && QuotationData.scalaInterno === "") {
              setAlertMsg("Per la tipologia Appartamento è necessario specificare la scala/interno");
              setAlertSeverity("error");
              setOpen(true);
              return;
            }
            if (QuotationData.dimora === 0) {
              setAlertMsg("Seleziona la tipologia di dimora");
              setAlertSeverity("error");
              setOpen(true);
              return;
            }
            if (QuotationData.posizione === 0) {
              setAlertMsg("Seleziona la posizione");
              setAlertSeverity("error");
              setOpen(true);
              return;
            }
            if (QuotationData.contractorInfo.residenceInfo.city === "") {
              let NewQuotationData = QuotationData;
              NewQuotationData.contractorInfo.residenceInfo.city = QuotationData.citta;
              NewQuotationData.contractorInfo.residenceInfo.cap = QuotationData.cap;
              NewQuotationData.contractorInfo.residenceInfo.provinceAbbr = QuotationData.provinciaAbbr;
              NewQuotationData.contractorInfo.residenceInfo.toponym = QuotationData.toponimo;
              NewQuotationData.contractorInfo.residenceInfo.address = QuotationData.indirizzo;
              NewQuotationData.contractorInfo.residenceInfo.streetNumber = QuotationData.numeroCivico;
              NewQuotationData.contractorInfo.residenceInfo.istat = QuotationData.istat;
              setQuotationData(NewQuotationData);
            }
            if ((parseInt(QuotationData.nCani) + parseInt(QuotationData.nGatti)) > 3) {
              setAlertMsg("Attenzione il numero complessivo di cani e gatti non può essere > 3");
              setAlertSeverity("error");
              setOpen(true);
              return;
            }
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }
        ).catch(error => {
          setAlertMsg("Prodotto non attivo per questo punto vendita");
          setAlertSeverity("error");
          setOpen(true);
          return;
        });
        break;
      case 1:
        if (QuotationData.contractorInfo.subjectType === "" || QuotationData.contractorInfo.subjectType === "1") {
          if (QuotationData.contractorInfo.name === "" ||
            QuotationData.contractorInfo.surname === "" ||
            QuotationData.contractorInfo.birthDate === "" ||
            QuotationData.contractorInfo.gender === "" ||
            QuotationData.contractorInfo.fiscalCode === "" ||
            QuotationData.contractorInfo.birthDate === undefined
          ) {
            if (QuotationData.contractorInfo.birthDate === undefined || QuotationData.contractorInfo.birthDate === "") {
              setAlertMsg("Codice fiscale intestatario errato");
            } else {
              setAlertMsg("Dati intestatario non completi");
            }
            setAlertSeverity("error");
            setOpen(true);
            break;
          }
          if (QuotationData.contractorInfo.residenceInfo.toponym === "" ||
            QuotationData.contractorInfo.residenceInfo.address === "" ||
            QuotationData.contractorInfo.residenceInfo.streetNumber === "" ||
            QuotationData.contractorInfo.residenceInfo.city === "" ||
            QuotationData.contractorInfo.residenceInfo.cap === "" ||
            QuotationData.contractorInfo.residenceInfo.provinceAbbr === ""
          ) {
            setAlertMsg("Dati indirizzo non completi");
            setAlertSeverity("error");
            setOpen(true);
            break;
          }
          if (QuotationData.contractorInfo.residenceInfo.provinceAbbr.length > 2) {
            setAlertMsg("Sigla Provincia massimo 2 lettere");
            setAlertSeverity("error");
            setOpen(true);
            break;
          }
        } else {
          if (QuotationData.contractorInfo.vatNumber === undefined) QuotationData.contractorInfo.vatNumber = "";
          if (QuotationData.contractorInfo.companyName === undefined) QuotationData.contractorInfo.companyName = "";

          if (QuotationData.contractorInfo.vatNumber === "" ||
            QuotationData.contractorInfo.companyName === ""
          ) {
            setAlertMsg("Dati intestatario non completi");
            setAlertSeverity("error");
            setOpen(true);
            break;
          }
        }
        if (QuotationData.contractorInfo.email === undefined) QuotationData.contractorInfo.email = "";
        if (!validator.isEmail(QuotationData.contractorInfo.email)) {
          setAlertMsg("Email non valida");
          setAlertSeverity("error");
          setOpen(true);
          break;
        }
        if (QuotationData.contractorInfo.phoneNumber === undefined) QuotationData.contractorInfo.phoneNumber = "";

        var pattern = new RegExp(/^[0-9\b]+$/);

        if (QuotationData.contractorInfo.phoneNumber === "" || QuotationData.contractorInfo.phoneNumber.length < 10 || !(validator.isMobilePhone(QuotationData.contractorInfo.phoneNumber, 'it-IT') /*|| pattern.test(QuotationData.contractorInfo.phoneNumber)*/)) {
          setAlertMsg("Numero cellulare non valido");
          setAlertSeverity("error");
          setOpen(true);
          break;
        }
        if (sameOwner) {
          setQuotationData({
            ...QuotationData,
            ownerInfo: {
              ...QuotationData.contractorInfo,
            },
          });
        }

        if (QuotationData.contractorInfo.subjectType === "" || QuotationData.contractorInfo.subjectType === "1") {
          getInfoBirth(token, urlservicequotiamo, QuotationData.contractorInfo.fiscalCode, QuotationData.contractorInfo.name, QuotationData.contractorInfo.surname).then(
            payload => {
              if (payload.data.status !== undefined) {
                setAlertMsg(payload.data.message);
                setAlertSeverity("error");
                setOpen(true);
                return;
              } else {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
              }
            }
          );
        } else {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }

        break;
      case 2:
        if (guarantees.length < 1) {
          setAlertMsg("Nessuna garanzia selezionabile, contattare l'admin di sistema");
          setAlertSeverity("error");
          setOpen(true);
          break;
        }

        if (isNaN(QuotationData.valoreFabbricato) || QuotationData.valoreFabbricato <= 0) {
          setAlertMsg("Inserire il valore del fabbricato");
          setAlertSeverity("error");
          setOpen(true);
          break;
        }
        if (isNaN(QuotationData.valoreContenuto) || QuotationData.valoreContenuto <= 0) {
          setAlertMsg("Inserire il valore del contenuto");
          setAlertSeverity("error");
          setOpen(true);
          break;
        }

        if (pos === 604 || pos === 615) {
          QuotationData.id_preventivo_fp = "";
        }

        let almeno_uno = false;
        let check_value = false;
        let error_msg = "";
        guaranteesValue.forEach((element, index) => {
          if (guaranteesSelect[index]) {
            almeno_uno = true;
            if (guarantees[index].input_type !== 0) {
              if (guaranteesValue[index] === "") {
                check_value = true;
                error_msg += guarantees[index].description + " ";
              }
            }
          }
        }
        );

        if (!almeno_uno) {
          setAlertMsg("Deve essere selezionata almeno una garanzia");
          setAlertSeverity("error");
          setOpen(true);
          break;
        }

        if (check_value !== false) {
          setAlertMsg("Mancano dei massimali: ( " + error_msg + " )");
          setAlertSeverity("error");
          setOpen(true);
          break;
        }

        /*
        guaranteesSelect.forEach((element,index) => {
          if(guaranteesSelect[index]){
            almeno_uno=true;            
          }              
        }
        );*/

        AuthService.signin(posinfo.username, posinfo.password, posinfo.url).then(
          payload => {
            setToken(payload);
            checkcompanyData(Auth.getToken(), pos).then(
              payload => {
                let companyListPrev = payload.data.services;
                //let discountslist = payload.data.discounts;
                let conventionslist = payload.data.conventions;
                setFactoriesHub(payload.data.factorieshub);

                /*const url=API_URL_SELECTGUARANTEES + "/"+props.product_id+ "/"+props.ser_fac_org_id;
                      axios.get(url,
                      {
                          headers:{
                          'Authorization': 'Bearer '+Auth.getToken(),
                          'Content-Type': 'application/json'
                          }
                      }
                  ).then(payload=>{                                                       
                      setGuarantees(payload.data.guarantees);                                                       
                  }) ;        
                  */

                /*QUI FARE IL CHECK SE CI SONO CONDIZIONI DI QUOTAZIONE
                O DA RIMUOVERE UNA FABBRICA
                */


                companyListPrev.forEach((company, indexcompany) => {
                  guarantees.forEach((element, index) => {
                    if (guaranteesSelect[index]) {
                      if (element["input_type"] !== 0) {
                        companyListPrev[indexcompany].guarantees_info[index].massimale = Number(guaranteesValue[index]);
                      }
                    }
                  });
                });


                setcompanyData(companyListPrev);
                let AddOnService = [];

                /*
                QUI SE CI SONO DEGLI ADDON
                TIPO NetAssistance
                */
                setPrevRS(0);
                setPrevRQ(companyListPrev.length);

                contaPreventiviDaFare = companyListPrev.length;
                contaPreventiviFatti = 0;
                companyListPrev.map((company) => (
                  checkPreventivo(QuotationData, company, token, guarantees, guaranteesSelect, guaranteesValue, conventionslist, Auth.getToken()).then(
                    payload => {
                      const newDatiPreventivo = datipreventivo;

                      if (payload.data.prices !== undefined) {
                        payload.data.prices.forEach(element => {

                          let convenzioneAttiva = CheckConvenzioni(QuotationData, company, conventionslist, 1);
                          element.convenzioneAttiva = convenzioneAttiva;
                          if (element.convenzioneAttiva.code === "") {
                            convenzioneAttiva = CheckConvenzioni(QuotationData, company, conventionslist, 0);
                            element.convenzioneAttiva = convenzioneAttiva;
                            if (convenzioneAttiva !== false && convenzioneAttiva.code !== "") {
                              //Al momento sovrascrire qualsiasi sconto commerciale
                              //Dopo vedere il flag
                              company.discount_description = convenzioneAttiva.description;
                              company.discounts_rules = convenzioneAttiva.discounts_rules;
                            }
                          }

                          let sconto_totale = 0;
                          let nettoTotalePartenza = 0;
                          let nettoTotaleScontato = 0;
                          let differenzaImposte = 0;
                          if (company.discount_description !== null && company.discounts_rules !== null) {
                            element.listResponseCVT.forEach(
                              cvt => {
                                if (cvt.code !== "CFE" && cvt.code !== "TAX") {
                                  let selectCVT = "";
                                  nettoTotalePartenza += cvt.price;
                                  cvt.originalprice = cvt.price;
                                  selectCVT = cvt.code;
                                  if (selectCVT !== "") {
                                    JSON.parse(company.discounts_rules).forEach(
                                      single_discount_rules => {
                                        if (single_discount_rules.hasOwnProperty(selectCVT)) {
                                          if (single_discount_rules[selectCVT].val !== null && single_discount_rules[selectCVT].val !== "0" &&
                                            cvt.price >= parseFloat(single_discount_rules[selectCVT].val)) {
                                            sconto_totale += parseFloat(single_discount_rules[selectCVT].val);
                                            cvt.price -= parseFloat(single_discount_rules[selectCVT].val);
                                          }
                                          if (single_discount_rules[selectCVT].perc !== null && single_discount_rules[selectCVT].perc !== "0") {
                                            sconto_totale += (cvt.price * (parseFloat(single_discount_rules[selectCVT].perc))) / 100;
                                            cvt.price -= (cvt.price * (parseFloat(single_discount_rules[selectCVT].perc))) / 100;
                                          }
                                          nettoTotaleScontato += cvt.price;
                                        }
                                      }
                                    )
                                  }
                                }
                              }
                            );

                            if (company.paramurl === "groupamanexus") {
                              element.listResponseCVT.forEach(
                                cvt => {
                                  if (cvt.code === "TAX") {
                                    let imposteTotale = cvt.price;
                                    let scontoPercTax = (nettoTotaleScontato * 100) / nettoTotalePartenza;
                                    cvt.price = (cvt.price * scontoPercTax) / 100;
                                    differenzaImposte = imposteTotale - cvt.price;
                                  }
                                }
                              );
                            }
                          }
                          element.TotalPrice -= parseFloat(sconto_totale);
                          element.price -= parseFloat(sconto_totale);
                          if (company.paramurl === "groupamanexus") {
                            element.TotalPrice -= parseFloat(differenzaImposte);
                            element.price -= parseFloat(differenzaImposte);
                          }

                          let addCalcoloCvt = 0;
                          guarantees.forEach((elementGuar, indexGuar) => {
                            if (guaranteesSelect[indexGuar]) {
                              let calcoloPercValue = 0;
                              let tipoCalcolo = company.guarantees_info[indexGuar].tipo_calcolo !== null ? (company.guarantees_info[indexGuar].tipo_calcolo !== "default" ? company.guarantees_info[indexGuar].tipo_calcolo : company.guarantees_info[indexGuar].tipo_calcolo_default) : company.guarantees_info[indexGuar].tipo_calcolo_default;
                              let serviceParameters = company.guarantees_info[indexGuar].tipo_calcolo !== null ? (company.guarantees_info[indexGuar].tipo_calcolo !== "default" ? company.guarantees_info[indexGuar].service_parameters : company.guarantees_info[indexGuar].service_parameters_default) : company.guarantees_info[indexGuar].service_parameters_default;
                              let tipoArrotondamento = company.guarantees_info[indexGuar].tipo_arrotondamento_calcolo;
                              if (tipoCalcolo === "perc") {
                                calcoloPercValue = parseFloat2Decimals((element.price * (Number(serviceParameters))) / 100);
                                if (tipoArrotondamento === "sup") {
                                  calcoloPercValue = Math.ceil(calcoloPercValue);
                                } else if (tipoArrotondamento === "inf") {
                                  calcoloPercValue = Math.floor(calcoloPercValue);
                                } else if (tipoArrotondamento === "mat") {
                                  calcoloPercValue = Math.round(calcoloPercValue);
                                }
                                addCalcoloCvt += calcoloPercValue;
                                let guarInfo =
                                {
                                  code: elementGuar["code"],
                                  nameCVT: elementGuar["description"],
                                  selected: true,
                                  price: calcoloPercValue
                                };
                                element.listResponseCVT.push(guarInfo);
                              } else if (tipoCalcolo === "value") {
                                calcoloPercValue = Number(serviceParameters);
                                addCalcoloCvt += parseFloat2Decimals(calcoloPercValue);
                                let guarInfo =
                                {
                                  code: elementGuar["code"],
                                  nameCVT: elementGuar["description"],
                                  selected: true,
                                  price: parseFloat2Decimals(calcoloPercValue)
                                };
                                element.listResponseCVT.push(guarInfo);
                              }
                            }
                          });

                          element.TotalPrice += addCalcoloCvt;
                          element.price += addCalcoloCvt;

                          let NumRate = 1;
                          let splitPrice = element.price;
                          if (element.splittingUp !== undefined && !isNaN(element.splittingUp)) {
                            NumRate = parseFloat(element.splittingUp);
                          }

                          if (element.splitPrice !== undefined && !isNaN(element.splitPrice)) {
                            splitPrice = parseFloat(element.splitPrice);
                          } else {
                            splitPrice = element.price;
                          }

                          splitPrice += (addCalcoloCvt / NumRate).toFixed(2);

                          // COMMISSIONI
                          if (company.FinalCommissionsType !== null && company.FinalCommissionsType === "euro") {
                            if (Number(company.FinalCommissionsDefault) >= 0) {
                              element.Commissions = company.FinalCommissionsDefault * NumRate;
                            } else {
                              if (Number(company.FinalCommissionsMax)) {
                                element.Commissions = company.FinalCommissionsMax * NumRate;
                              } else {
                                element.Commissions = 0;
                              }
                            }
                          } else {
                            if (Number(company.FinalCommissionsDefault) >= 0) {
                              element.Commissions = element.price * company.FinalCommissionsDefault / 100;
                              element.Commissions = element.Commissions.toFixed(2);
                            } else {
                              if (Number(company.FinalCommissionsMax)) {
                                element.Commissions = element.price * company.FinalCommissionsMax / 100;
                                element.Commissions = element.Commissions.toFixed(2);
                              } else {
                                element.Commissions = 0;
                              }
                            }
                          }

                          // DIRITTI
                          if (company.FinalfeeType !== null && company.FinalfeeType === "perc") {
                            if (Number(company.FinalfeeDefault) >= 0) {
                              element.Fees = element.price * company.FinalfeeDefault / 100;
                              element.Fees = element.Fees.toFixed(2);
                            } else {
                              if (Number(company.FinalfeeMax)) {
                                element.Fees = element.price * company.FinalfeeMax / 100;
                                element.Fees = element.Fees.toFixed(2);
                              } else {
                                element.Fees = 0;
                              }
                            }
                          } else {
                            if (Number(company.FinalfeeDefault) >= 0) {
                              element.Fees = company.FinalfeeDefault * NumRate;
                            } else {
                              if (Number(company.FinalfeeMax)) {
                                element.Fees = company.FinalfeeMax * NumRate;
                              } else {
                                element.Fees = 0;
                              }
                            }
                          }

                          element.TotalPrice = parseFloat(element.price) + parseFloat(element.Commissions) + parseFloat(element.Fees);

                          //let NewAddOnService = AddOnService;       

                          element.ListService = [];
                          AddOnService.forEach(service => {
                            let priceservice = 0;
                            if (service.type === "perc") {
                              priceservice = element.price * service.value / 100;
                              priceservice = parseFloat(priceservice);
                            } else {
                              priceservice = service.value;
                            }
                            //NewAddOnService[key].priceservice=service.priceservice;
                            element.TotalPrice += parseFloat(priceservice);

                            let ServiceInfo =
                            {
                              description: "NetAssistance",
                              priceservice: priceservice
                            }
                              ;

                            element.ListService.push(ServiceInfo);

                          });

                          element.TotalRate = parseFloat(element.TotalPrice / NumRate);
                          element.TotalRate = element.TotalRate.toFixed(2);
                          element.only_cvt = company.only_cvt;
                        });
                      }

                      newDatiPreventivo[company.sfo_id + company.paramurl + company.type] = payload.data;
                      setdatipreventivo(newDatiPreventivo);

                      console.log("RQ1:" + prevRQ);
                      console.log("RS1:" + prevRS);

                      setPrevRS(prevCount => prevCount + 1);
                      console.log("RQ2:" + prevRQ);
                      console.log("RS2:" + prevRS);

                      //contaPreventiviDaFare=0;
                      contaPreventiviFatti++;
                      console.log("FATTI1:" + contaPreventiviFatti + "/" + contaPreventiviDaFare);

                      if (contaPreventiviFatti === contaPreventiviDaFare && contaPreventiviDaFare > 0) {
                        HandleSaveQuotation(companyListPrev);
                        //setSaved(true);
                        //setAlreadySave(true);
                      }

                      if (prevRQ === prevRS) {
                        setLoading(false);
                      }
                    }).catch(error => {
                      setPrevRS(prevCount => prevCount + 1);
                      contaPreventiviFatti++;
                      console.log("FATTI2:" + contaPreventiviFatti + "/" + contaPreventiviDaFare);

                      if (contaPreventiviFatti === contaPreventiviDaFare && contaPreventiviDaFare > 0) {
                        HandleSaveQuotation(companyListPrev);
                        //setSaved(true);
                        //setAlreadySave(true);
                      }
                      if (prevRQ === prevRS)
                        setLoading(false);
                    })
                ));
              }).catch(error => {
                console.log(error);
                /*setPrevRS(prevCount=> prevCount+1);
                contaPreventiviFatti++;
                                  
                if(prevRQ===prevRS)
                    setLoading(false);                                  
                }*/

              });
          }).catch(error => {
            setAlertMsg("Errore durante la connessione al QUOTIAMO Service");
            setAlertSeverity("error");
            setOpen(true);
          });
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        break;
      case 3:
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        break;
      default:
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        break;
    }
  };

  const handleBack = () => {
    if (activeStep === 3) {
      window.stop();
      setdatipreventivo([]);
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChangePos = (event) => {

    agencieslists.forEach(element => {
      if (element.id === event.target.value) {
        setPos(event.target.value);
        seturlservicequotiamo(element.url)
        setPosInfo(element);
        setPosActive(element);
        setOrgId(element.orgid);

        //const url_guarantees=API_URL_ALLGUARANTEES + "/house/"+element.orgid;
        /*axios.get(API_URL_ALLGUARANTEES + "/house/"+element.orgid,
        {
            headers:{
            'Authorization': 'Bearer '+Auth.getToken(),
            'Content-Type': 'application/json'
            }
        }
        ).then(payload=>{                       
        
        
              let temp=[];
                payload.data.guarantees.forEach(element => {    
                    temp.push(false);
                });                                         
              setGuaranteesSelect(temp);                
              setGuarantees(payload.data.guarantees);                                   
              console.log("GAR",payload.data.guarantees);
                        
       }) ;        */




        if (element.defaultparameter !== null) {
          /*let defpar = JSON.parse(element.defaultparameter);
          setQuotationData(AssignDefParameter(defpar));*/
          let defOrg = JSON.parse(element.defOrg);
          let defGrp = JSON.parse(element.defGrp);
          let defAg = JSON.parse(element.defAg);
          setQuotationData(AssignDefParameter(defOrg, defGrp, defAg, userData));
          /*let temp=[];
          guarantees.forEach((element,index) => {
            let status=false;
            if(defpar[element.code]!==undefined){
                console.log(defpar[element.code],element.code);
                if((defpar[element.code] & 1)===1){
                  status=true;
                }
            }
            temp.push(status);
          });
          setGuaranteesSelect(temp); */
        }




        localStorage.setItem('pos', JSON.stringify(element));

        AuthService.signin(element.username, element.password, element.url).then(
          payload => {
            setToken(payload);
          }).catch(error => {

          });

        const url = API_URL_INFOIMAGES_ALL + "/house/" + event.target.value;
        axios.get(url,
          {
            headers: {
              'Authorization': 'Bearer ' + Auth.getToken(),
              'Content-Type': 'application/json'
            }
          }
        ).then(payload => {
          setImages(payload.data.images)
        });

      }
    });
  };

  const handleChangeFormatNumber = name => event => {
    setQuotationData({
      ...QuotationData,
      [name]: event.floatValue
    });
  };

  const handleChangeFreeInput = index => event => {
    let newVal = guaranteesValue;
    newVal[index] = event.floatValue;
    setGuaranteesValue(newVal);
  };

  const handleChangeList = index => event => {
    let newVal = guaranteesValue;
    newVal[index] = event.target.value;
    setGuaranteesValue(newVal);

  };

  const handleChangeDateEffective = event => {
    setQuotationData({
      ...QuotationData,
      dataEffetto: moment(event).format("YYYY-MM-DD")
    });
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="labelPOS">Punto vendita</InputLabel>
                <Select
                  labelId="labelPOS"
                  id="pos"
                  value={pos}
                  onChange={handleChangePos}
                  label="Punto vendita"
                >
                  {agencieslists.length > 0 && agencieslists.map(option => (
                    <MenuItem
                      key={"ag_" + option.id}
                      value={option.id}
                    >
                      {option.description}
                    </MenuItem>
                  ))}

                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <KeyboardDatePicker
                label="Data effetto"
                value={QuotationData.dataEffetto}
                onChange={handleChangeDateEffective}
                format='dd/MM/yyyy'
                clearLabel="Conferma"
                cancelLabel="Annulla"
                name="dataEffetto"
                fullWidth
                required
                invalidDateMessage="Data non valida"
                maxDateMessage="Non è possibile effettuare un preventivo con una scadenza superiore ad un mese"
                minDateMessage="Il preventivo non può essere retroattivo"
                minDate={moment()}
                // maxDate={moment().add(1, "months").endOf('month')}
                maxDate={moment().add(30, "days")}
                inputVariant="outlined"
              />

            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Durata anni"
                name="durataAnni"
                onChange={handleChange}
                select
                SelectProps={{ native: true }}
                value={QuotationData.durataAnni}
                variant="outlined"
                disabled
              >
                <option value={0}>
                  Seleziona la durata
                </option>
                {durationsList.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Frazionamento"
                name="frazionamento"
                onChange={handleChange}
                select
                SelectProps={{ native: true }}
                value={QuotationData.frazionamento}
                variant="outlined"
                disabled
              >
                <option
                  key="NOFRAZ"
                  value={0}
                >
                  Seleziona il frazionamento
                </option>
                {tipoFrazionamento.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid container spacing={3} >
            <Grid item xs={12} style={{ marginBottom: 0, paddingBottom: 0 }}>
              <Typography className={classes.SubTitle}>
                Ubicazione immobile
              </Typography>
            </Grid>
          </Grid>

          <Grid className={classes.FirstDataRow} container spacing={3}>
            <Grid item xs={3} md={2}>
              <TextValidator
                variant="outlined"
                fullWidth
                id="toponimo"
                label="Via, piazza,ecc"
                name="toponimo"
                autoFocus
                value={QuotationData.toponimo}
                onChange={handleChange}
                validators={[]}
                errorMessages={[]}
              />
            </Grid>
            <Grid item xs={6} md={8}>
              <TextValidator
                variant="outlined"
                fullWidth
                id="indirizzo"
                label="Indirizzo"
                name="indirizzo"
                autoFocus
                value={QuotationData.indirizzo}
                onChange={handleChange}
                validators={[]}
                errorMessages={[]}
              />
            </Grid>
            <Grid item xs={3} md={2}>
              <TextValidator
                variant="outlined"
                fullWidth
                id="numeroCivico"
                label="Numero civico"
                name="numeroCivico"
                autoFocus
                value={QuotationData.numeroCivico}
                onChange={handleChange}
                validators={[]}
                errorMessages={[]}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} md={2}>
              <TextValidator
                variant="outlined"
                margin="normal"
                required
                fullWidth
                disabled
                id="cap"
                label="Cap"
                name="cap"
                value={QuotationData.cap}
                onChange={handleChange}
                validators={['required']}
                errorMessages={['Campo obbligatorio']}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <Autocomplete
                //options= {listCity.map((option)=>option.city)}                  
                options={listCity}
                defaultValue={{
                  cap: QuotationData.cap,
                  city: QuotationData.citta,
                  provinceAbbr: QuotationData.provinciaAbbr
                }}
                getOptionSelected={(option, value) => option.city === value.city && option.cap === value.cap}
                getOptionLabel={(option) => {
                  //filter value
                  return (`${option.city}`)
                }}
                id="comune"
                label="Comune"
                name="citta"
                autoHighlight
                onChange={(event, newValue) => {
                  listCity.map((option) => {
                    if (newValue === option) {
                      setQuotationData({
                        ...QuotationData,
                        citta: option.city,
                        cap: option.cap,
                        istat: option.istat,
                        provinciaAbbr: option.provinceAbbr
                      }
                      );
                      return option
                    }
                    return false;
                  }
                  )
                }}
                renderInput={(params) => <TextField {...params} label="Comune" margin="normal" variant="outlined" />}
                renderOption={(option) => {
                  return (`${option.city} (${option.cap})`)
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextValidator
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="provinciaAbbr"
                label="Provincia (Sigla)"
                name="provinciaAbbr"
                disabled
                value={QuotationData.provinciaAbbr}
                onChange={handleChange}
                validators={['required', 'maxStringLength:2']}
                errorMessages={['Campo obbligatorio']}
                maxLength={2}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>

            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Classe costruttiva"
                name="classeCostruttiva"
                onChange={handleChange}

                select
                SelectProps={{ native: true }}
                value={QuotationData.classeCostruttiva}
                variant="outlined"
              >
                <option
                  value={0}
                >
                  Seleziona la classe
                </option>


                {classeCostruttivaList.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Tipologia costruzione"
                name="tipologiaCostruzione"
                onChange={handleChange}

                select
                SelectProps={{ native: true }}
                value={QuotationData.tipologiaCostruzione}
                variant="outlined"
              >
                <option
                  value={0}
                >
                  Seleziona la tipologia di costruzione
                </option>


                {tipologiaCostruzioneList.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={2}>
              <TextValidator
                variant="outlined"
                fullWidth
                id="scalaInterno"
                label="Scala Interno"
                name="scalaInterno"
                autoFocus
                value={QuotationData.scalaInterno}
                onChange={handleChange}
                validators={[]}
                errorMessages={[]}
              />


            </Grid>

            <Grid item xs={12} md={2}>
              <TextValidator
                variant="outlined"
                fullWidth
                id="annoCostruzioneRistrutturazione"
                label="Anno di costruzione/ristrutturazione"
                name="annoCostruzioneRistrutturazione"
                autoFocus
                value={QuotationData.annoCostruzioneRistrutturazione}
                onChange={handleChange}
                validators={[]}
                errorMessages={[]}
              />


            </Grid>

          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <TextValidator
                variant="outlined"
                fullWidth
                id="superficieM2"
                label="Superficie Metri quadri"
                name="superficieM2"
                autoFocus
                value={QuotationData.superficieM2}
                onChange={handleChange}
                validators={[]}
                errorMessages={[]}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                variant="outlined"
                fullWidth
                id="piano"
                label="Piano"
                name="piano"
                autoFocus
                select
                SelectProps={{ native: true }}
                value={QuotationData.piano}
                onChange={handleChange}

              >
                <option
                  value={0}
                >
                  Seleziona
                </option>


                {PianoList.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>


            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Relazione con l'assicurato"
                name="tipologiaProp"
                onChange={handleChange}
                select
                SelectProps={{ native: true }}
                value={QuotationData.tipologiaProp}
                variant="outlined"
              >
                <option
                  value={0}
                >
                  Seleziona
                </option>


                {PossessoList.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>






          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Tipologia di immobile"
                name="tipologiaImm"
                onChange={handleChange}
                select
                SelectProps={{ native: true }}
                value={QuotationData.tipologiaImm}
                variant="outlined"
              >
                <option
                  value={0}
                >
                  Seleziona la tipologia
                </option>
                {TipologiaList.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Tipo di dimora"


                name="dimora"
                onChange={handleChange}
                select
                SelectProps={{ native: true }}
                value={QuotationData.dimora}
                variant="outlined"
              >
                <option
                  value={0}
                >
                  Seleziona il tipo di dimora
                </option>
                {TipoDimoraList.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Posizione immobile"
                name="posizione"
                onChange={handleChange}
                select
                SelectProps={{ native: true }}
                value={QuotationData.posizione}
                variant="outlined"
              >
                <option
                  value={0}
                >
                  Seleziona
                </option>
                {PosizioneList.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid container spacing={3}>

            <Grid item xs={12} md={4}>
              <TextValidator
                variant="outlined"
                fullWidth
                id="nPersNucleoFamiliare"
                label="Numero persone nel nucleo familiare"
                name="nPersNucleoFamiliare"
                autoFocus
                value={QuotationData.nPersNucleoFamiliare}
                onChange={handleChange}
                validators={[]}
                errorMessages={[]}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                fullWidth
                label="Numero di cani"
                name="nCani"
                onChange={handleChange}
                select
                SelectProps={{ native: true }}
                value={QuotationData.nCani}
                variant="outlined"
              >
                <option value={0}>0</option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
              </TextField>
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                fullWidth
                label="Numero di gatti"
                name="nGatti"
                onChange={handleChange}
                select
                SelectProps={{ native: true }}
                value={QuotationData.nGatti}
                variant="outlined"
              >
                <option value={0}>0</option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
              </TextField>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControlLabel
                aria-label="Acknowledge"
                onClick={(event) => {
                  setQuotationData({
                    ...QuotationData,
                    [event.target.name]: !QuotationData.antifurto
                  }
                  )
                    ;

                  event.stopPropagation();

                }}
                onFocus={(event) => event.stopPropagation()}
                control={<Checkbox name="antifurto" checked={QuotationData.antifurto} />}
                label="Antifurto presente"
              />
            </Grid>
          </Grid>
        </>;
      case 1:
        return <>
          <SubjectDetails
            datatype={"house"}
            type={"contractorInfo"}
            token={token}
            urlservicequotiamo={urlservicequotiamo}
            datiquotazione={QuotationData}
            setdatiquotazione={setQuotationData}
            listCity={listCity}
            posinfo={posinfo}
          />
          {false &&
            <Accordion defaultExpanded={sameOwner === false}>
              <AccordionSummary
                expandIcon={sameOwner === false && <ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id="additional-actions1-header"


              >
                <FormControlLabel
                  aria-label="Acknowledge"
                  onClick={(event) => {

                    setSameOwner(!sameOwner);
                    event.stopPropagation();

                  }
                  }
                  onFocus={(event) => event.stopPropagation()}
                  control={<Checkbox checked={sameOwner} />}
                  label="L'intestatario coincide con il contraente"
                />
              </AccordionSummary>
              {sameOwner === false &&
                <AccordionDetails>

                  <SubjectDetails
                    datatype={"house"}
                    type={"ownerInfo"}
                    token={token}
                    urlservicequotiamo={urlservicequotiamo}
                    datiquotazione={QuotationData}
                    setdatiquotazione={setQuotationData}
                    listCity={listCity}
                    posinfo={posinfo}
                  />
                </AccordionDetails>
              }
            </Accordion>
          }

        </>
          ;
      case 2:
        return <>
          <div className={classes.paper}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <NumberFormat style={{ width: "100%", paddingBottom: "10px" }} onValueChange={handleChangeFormatNumber("valoreFabbricato")} customInput={TextFieldCustomFabbricato} fixedDecimalScale="true" decimalScale="0" value={QuotationData.valoreFabbricato} displayType={'input'} decimalSeparator={" "} thousandSeparator={"."} prefix={'€ '} />
              </Grid>
              <Grid item xs={12} md={4}>
                <NumberFormat style={{ width: "100%", paddingBottom: "10px" }} onValueChange={handleChangeFormatNumber("valoreContenuto")} customInput={TextFieldCustomContenuto} fixedDecimalScale="true" decimalScale="0" value={QuotationData.valoreContenuto} displayType={'input'} decimalSeparator={" "} thousandSeparator={"."} prefix={'€ '} />
              </Grid>
            </Grid>

            <Grid container spacing={0}>
              {guarantees.map((warranty, index) => (
                <>
                  {(index === 0 || guarantees[index - 1].categoriesdescription !== warranty.categoriesdescription) &&
                    <Grid key={"cat_" + index} item xs={12}>
                      <Typography className={classes.TitleCategory}>
                        {warranty.categoriesdescription}
                      </Typography>
                    </Grid>
                  }
                  <Grid key={index} item xs={2}>
                    <CheckSvgButton disabled={posinfo.defAg !== null && JSON.parse(posinfo.defAg)[warranty.code] !== undefined ? JSON.parse(posinfo.defAg)[warranty.code] >= 2 : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp)[warranty.code] !== undefined ? true : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg)[warranty.code] !== undefined ? true : false)) &&
                      (((posinfo.defAg !== null && JSON.parse(posinfo.defAg)[warranty.code] !== undefined ? JSON.parse(posinfo.defAg)[warranty.code] : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp)[warranty.code] !== undefined ? JSON.parse(posinfo.defGrp)[warranty.code] : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg)[warranty.code] !== undefined ? JSON.parse(posinfo.defOrg)[warranty.code] : 0))) & 2) >= 2)} label={warranty.description} svg={warranty.image} status={guaranteesSelect[index]} click={handleClick(index)} />

                    <Box className={classes.BoxContainer}>

                      {warranty.input_type === 1 && <>
                        <NumberFormat disabled={!guaranteesSelect[index]} onValueChange={handleChangeFreeInput(index)} customInput={TextFieldCustomMassimale} fixedDecimalScale="true" decimalScale="0" value={guaranteesValue[index]} displayType={'input'} decimalSeparator={" "} thousandSeparator={"."} prefix={'€ '} />
                      </>}

                      {warranty.input_type === 2 && <>

                        <TextField
                          disabled={!guaranteesSelect[index]}
                          fullWidth
                          label="Massimale"
                          onChange={handleChangeList(index)}
                          select
                          SelectProps={{ native: true }}
                          value={guaranteesValue[index]}
                          variant="outlined"
                        >

                          {warranty.list_value.split(",").map(option => (
                            <option
                              key={option}
                              value={option}
                            >
                              {option.indexOf("%") >= 0 && "" + option}

                              {option.indexOf("%") === -1 && "€ " + number_format(option, 0, ",", ".")}
                            </option>
                          ))}
                        </TextField>


                      </>}
                    </Box>
                  </Grid>
                </>
              ))}
            </Grid>
          </div>
        </>;
      /*
      
      if(payload.data[0].defaultparameter!==null){
              let defpar=JSON.parse(payload.data[0].defaultparameter);
              if((defpar[element.code] & 1)===1){
      
      */
      case 3: return <Paper className={classes.paper}>
        <div className={classes.progress}>{progress}</div>
        {prevRS < prevRQ &&
          <div className={classes.linearProgressDiv}>
            <LinearProgressWithLabel value={100 * prevRS / prevRQ} />
            {images.length > 0 &&
              <Carousel
                className={classes.Carousel}
                autoPlay={true}
                //animation={"fade"}
                indicators={true}
                timeout={500}
                cycleNavigation={true}
                navButtonsAlwaysVisible={true}
                navButtonsAlwaysInvisible={false}
                fullHeightHover={true}
              >
                {
                  images.map((item, index) => {
                    return <CardMedia
                      className={classes.Media}
                      image={item.image}
                    >
                    </CardMedia>
                  })
                }
              </Carousel>}
          </div>
        }

        {companyData.map((company, index) =>
          <Box className={classes.root} m={2} >
            <QuotationDetails key={index} factories={factories} factoriesHub={factoriesHub} company={company} datipre={datipreventivo[company.sfo_id + company.paramurl + company.type]} saveActive={false} />
          </Box>
        )}

        {prevRS === prevRQ && images.length > 0 &&
          <Carousel
            className={classes.Carousel}
            autoPlay={true}
            animation={"fade"}
            indicators={true}
            timeout={500}
            cycleNavigation={true}
            navButtonsAlwaysVisible={true}
            navButtonsAlwaysInvisible={false}
            fullHeightHover={true}
          >
            {
              images.map((item, index) => {
                return <CardMedia
                  key={index}
                  className={classes.Media}
                  image={item.image}
                >
                </CardMedia>
              })
            }
          </Carousel>
        }
      </Paper>;
      case 4:
        return <div className={classes.paper}>
          {NumberQuotation &&
            <QuotationSingleDetailFacile numberquotation={NumberQuotation} visual={true} />
          }
        </div>;
      default:
        return 'Unknown step';
    }
  };

  /*
  <CheckSvgButton disabled={false} label={warranty.description}  svg={warranty.image} status={1}  />
  */

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <main className={classes.content}>
      <CssBaseline />
      <BackGround open={loading} />
      <ValidatorForm
        onSubmit={() => {
        }}
        onError={errors => console.log(errors)}
      >

        <Container maxWidth="lg" className={classes.container}>

          <Grid container spacing={3}>
            <Grid className={classes.containerButton} item md={8}>
              {/*<Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" href="/" >
                  {NAME_APP}
                </Link>
                <Link color="inherit" href="/house" >
                  Casa
                </Link>
                <Typography color="textPrimary">Richiesta preventivo</Typography>
      </Breadcrumbs>*/}
            </Grid>
            <Grid className={classes.containerButton} item md={4}>
              <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                Indietro
              </Button>
              {activeStep < steps.length &&
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  className={classes.button}
                  disabled={activeStep === steps.length - 1 || (activeStep === 3 && prevRS < prevRQ && prevRQ > 0)}
                >
                  Avanti
                </Button>}

            </Grid>
          </Grid>




          <Card>

            <CardContent>
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};

                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>


              <div className={classes.wrapper}>

                {activeStep === steps.length ? (
                  <div>
                    <Typography className={classes.instructions}>
                      Richiesta Preventivo completata
                    </Typography>
                    <Button onClick={handleReset} className={classes.button}>
                      Nuovo preventivo
                    </Button>
                  </div>
                ) : (
                  <div className={classes.fulldiv}>
                    {getStepContent(activeStep)}
                  </div>
                )}


              </div>

            </CardContent>


          </Card>
          <Grid container spacing={3}>
            <Grid className={classes.containerButton} item md={12}>

              <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                Indietro
              </Button>
              {activeStep < steps.length &&
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  className={classes.button}


                  disabled={activeStep === steps.length - 1 || (activeStep === 3 && prevRS < prevRQ && prevRQ > 0)}
                >
                  Avanti
                </Button>}

            </Grid>
          </Grid>
        </Container>

        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={handleClose} severity={alertseverity}>
            {alertmsg}
          </Alert>
        </Snackbar>
      </ValidatorForm>
    </main>
  );
}