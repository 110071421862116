import React,{useState} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Auth from '../auth/auth';
import {Helmet} from "react-helmet";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import {id_cliente,ReturnInfoClient} from '../containers/logincontext';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const useStyles = makeStyles((theme) => ({
  buttonProgress: {    
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalpaper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function RecoverPassword(pars) {
  const classes = useStyles();
  const [email,setEmail]=useState('');  
  const [open, setOpen] = useState(false);
  const [msgmodal,setMsgModal] = useState('');
  const [msgTitle,setMsgTitle] = useState('');
  const [loading, setLoading] = React.useState(false);

  const handleOpen = (title,msg) => {
    setMsgTitle(title);
    setMsgModal(msg);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  


  const recoverPasswordSubmit= (e) =>{
      
      e.preventDefault();
      setLoading(true);           
      Auth.recoverPassword(email).then(          
          payload =>{              
            setLoading(false);           
            handleOpen("success","E' stata inviata una email all'indirizzo "+payload.data["email"]+ " seguire le istruzioni per resettare la password");
          }).catch(error=>{
            setLoading(false);
            handleOpen("error",error);            
        });
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Helmet>
        <title>Recupero Password|{ReturnInfoClient(id_cliente,"nome_cliente")}</title>        
      </Helmet>
      <div className={classes.paper}>
        <div>                            
            <img src={ReturnInfoClient(id_cliente,"logo")} alt={ReturnInfoClient(id_cliente,"nome_cliente")}  className="siteLogo"/>                            
        </div>
        
        <form className={classes.form} noValidate onSubmit ={recoverPasswordSubmit}>        
        
          <Grid container spacing={2}>
            
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Indirizzo Email"
                name="email"
                autoComplete="email"
                value={email}                            
                onChange ={(e)=> setEmail(e.target.value)}
              />
            </Grid>                        
          </Grid>
          
          
                    
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={loading}
          >
            Reset password
          </Button>
          {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          <Grid container justify="flex-end">
            <Grid item>
              <Link href="/login" variant="body2">
                Hai già un utente, collegati
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={msgTitle}>
          {msgmodal}
          </Alert>
      </Snackbar>
      
      
    </Container>
  );
}


