import React from 'react';
import SvgColor from 'react-svg-color';
import { makeStyles  } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';



const useStyles = makeStyles((theme) => ({
    root:{
        
        margin: 10,
        padding:20,
        
        
    },
    label:{
        marginTop:10,
        fontSize: 10,
        fontWeight: "bold",
        minHeight:"50px",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    
    imgContainer:{
        margin:'auto',
        
        textAlign:'center'
    },
    

}));





export default function CheckSvgButton(props) {
    
    const classes = useStyles();

    const [status,setStatus] = React.useState(props.status);
    //name="assistenzaStradale"
    const handleChange = event => {
        
        if(props.disabled!==true){
            let newStatus=!status;

            
            setStatus(newStatus);                                        
            props.click(newStatus);
        }
        
        
    };

    return(
        <Box borderRadius={16} className={classes.root} m={1} border={status ? 3:3} borderColor={status ? "#f9b406":"#DDDDDD"} onClick={handleChange}>          
          <Box className={classes.imgContainer}>
          <SvgColor               
              svg={props.svg} 
              width={60} 
              colors={status ? ["#f9b406","#f9b406","#f9b406","#f9b406","#f9b406","#f9b406","#f9b406","#f9b406","#f9b406","#f9b406","#f9b406","#f9b406","#f9b406","#f9b406","#f9b406","#f9b406","#f9b406","#f9b406","#f9b406","#f9b406"]:
                               ["#DDDDDD","#DDDDDD","#DDDDDD","#DDDDDD","#DDDDDD","#DDDDDD","#DDDDDD","#DDDDDD","#DDDDDD","#DDDDDD","#DDDDDD","#DDDDDD","#DDDDDD","#DDDDDD","#DDDDDD","#DDDDDD","#DDDDDD","#DDDDDD","#DDDDDD","#DDDDDD"]}   
          />
          </Box>
          
          <Typography className={classes.label} variant="button" display="block" gutterBottom align="center">
          {props.label}  
          </Typography>
          
          </Box>
        
    )
}