import React from 'react';
import {makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import {API_URL_QUOTATION_LIST,GridLocaleText} from '../config/config';
//import {API_URL_ORGANIZATIONS_LIST} from '../config/config';
//import axios   from 'axios';

import { green, red } from '@material-ui/core/colors';


//import { useHistory } from "react-router-dom";
import Auth from '../auth/auth';


import Pagination from '@mui/material/Pagination';
import {
    DataGrid,
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
    GridToolbar,
    getGridNumericOperators 
    //getGridStringOperators 
  } from '@mui/x-data-grid';



  
//import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
//import QRCodeGenerator from 'qrcode'
function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  
    return (
      <Pagination
        color="primary"
        count={pageCount}
        page={page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  }

  

  const columns = [
    { 
        field: 'id', 
        headerName: 'Numero', 
        width: 90 ,
        filterOperators: getGridNumericOperators().filter(
          (operator) => operator.value === '=' ,
        ),
    },
    {
        field: 'orgdescr',
        headerName: 'Organizzazione',
        width: 250,
          

        
      },
    {
      field: 'grpdescr',
      headerName: 'Rete',
      width: 250,
      
    },
    {
      field: 'agdescr',
      headerName: 'Agenzia',
      width: 250,
      editable: true,
    },
    {
      field: 'surname',
      headerName: 'Agente',      
      width: 110,
      editable: true,
      filterable: false,
      
    },
    {
      field: 'licencePlate',
      headerName: 'Targa',      
      sortable: false,
      width: 160,
    }, 
  ];
const loadServerRows = (page, pageSize,sortModel,filterField,filterValue) =>
  new Promise((resolve) => {
    const tokenApp= Auth.getToken();  
    let url = API_URL_QUOTATION_LIST;
    url += '?per_page=' + pageSize
    url += '&page=' + (page + 1)
                
    
                if(filterField!==undefined){
                    url += '&field=' + filterField
                    console.log(filterField);
                }
                
                if(filterValue!==undefined){
                    url += '&search=' + filterValue
                    console.log(filterValue);
                }     
                
                
                if(sortModel!==undefined){                                    
                    url += '&orderby=' + sortModel[0].field;
                    url += '&orderdirection=' + sortModel[0].sort;                                    
                }
            

                const requestOptions = {                                    
                    headers: { 
                        'Authorization': 'Bearer '+tokenApp,
                        'Content-Type': 'application/json',                                
                    },              
                };


                fetch(url,requestOptions)            
                .then(                  
                    response => response.json()
                )
                .then(                
                    result => {                                      
                    resolve({
                    data: result.data,                  
                    page: result.current_page-1,
                    totalCount: result.total,
                    })
                })
    /*
    setTimeout(() => {
      resolve(allRows.slice(page * pageSize, (page + 1) * pageSize));
    }, Math.random() * 200 + 100); // simulate network latency
    */



  });

const useQuery = (page, pageSize,sortModel,filterField,filterValue) => {
  const [rowCount, setRowCount] = React.useState(undefined);
  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    let active = true;

    setIsLoading(true);
    setRowCount(undefined);
    loadServerRows(page, pageSize,sortModel,filterField,filterValue).then((newRows) => {
      if (!active) {
        return;
      }
      setData(newRows.data);
      setIsLoading(false);
      setRowCount(newRows.totalCount);
    });

    return () => {
      active = false;
    };
  }, [page, pageSize,sortModel,filterField,filterValue]);

  return { isLoading, data, rowCount };
};

const useStyles = makeStyles((theme) => ({

    BtnSearch:{
        marginBottom:20,
        marginTop:10,
    },
    alignFab: {
        marginLeft: 'auto',
      },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),      
    },
    img: {            
        maxWidth: 'auto',
        maxHeight: '40px',
        justifyContent: 'center'
    },
    dialog:{
        minWidth:500
    },
    red: {
        color: red[500],        
    },
    green: {
        color: green[500],
        
    },
}));

export default function QuotationList2() {    
    const classes = useStyles();    
    

    /*const [organizations,setOrganizations]= React.useState([]);
    

    React.useEffect(() => {                
      const url=API_URL_ORGANIZATIONS_LIST;
          axios.get(url,
          {
              headers:{
              'Authorization': 'Bearer '+Auth.getToken(),
              'Content-Type': 'application/json'
              }
          }
      ).then(payload=>{                       


        let temp=[];
        payload.data.organizations.forEach(element => {
            temp.push({[element.id]:element.description});
        });                     
        
        var obj = payload.data.organizations.reduce(function(acc, cur, i) {
          acc[cur.id] = cur.description;
          return acc;
          }, {});

          setOrganizations(obj);         
          
      }) ;        
              
    },[]);    */  
    
    
    
    //let history = useHistory();

    

    const [filterValue, setFilterValue] = React.useState();
    const [filterField, setFilterField] = React.useState();

    const [sortModel, setSortModel] = React.useState([
        { field: 'id', sort: 'desc' },
    ]);
    
      const [rowsState, setRowsState] = React.useState({
        page: 0,
        pageSize: 10,
      });
    
      const { isLoading, data, rowCount } = useQuery(
        rowsState.page,
        rowsState.pageSize,
        sortModel,
        filterField,
        filterValue
      );
    

      const handleSortModelChange = (newModel) => {
        setSortModel(newModel);
      };

      const onFilterChange = React.useCallback((filterModel) => {
        console.log(filterModel);

        if(filterModel.items!==undefined){
          setFilterField(filterModel.items[0].columnField);
          setFilterValue(filterModel.items[0].value);
        }else{
          setFilterField();
          setFilterValue();
        }
        
      }, []);

      // Some api client return undefine while loading
      // Following lines are here to prevent `rowCountState` from being undefined during the loading
      const [rowCountState, setRowCountState] = React.useState(rowCount || 0);
      React.useEffect(() => {
        setRowCountState((prevRowCountState) =>
          rowCount !== undefined ? rowCount : prevRowCountState,
        );
      }, [rowCount, setRowCountState]);  
    
    
    

    return (
        <main className={classes.content}>            
        <div className={classes.appBarSpacer} />            
            <Container maxWidth="lg" className={classes.container}>
            <div style={{ height: 800, width: '100%' }}>  
                    <DataGrid
        columns={columns}
        rows={data}
        rowCount={rowCountState}        
        loading={isLoading}
        rowsPerPageOptions={[10]}
        pagination
        autoHeight
        localeText={GridLocaleText}

        filterMode="server"
        onFilterModelChange={onFilterChange}

        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}

        {...rowsState}
        paginationMode="server"
        onPageChange={(page) => setRowsState((prev) => ({ ...prev, page }))}
        onPageSizeChange={(pageSize) =>
          setRowsState((prev) => ({ ...prev, pageSize }))
        }
        components={{
            Pagination: CustomPagination,
            Toolbar: GridToolbar
          }}
      />
                    </div>

        </Container>   
        </main>
      );
};

