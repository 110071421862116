import React from 'react';
import {makeStyles } from '@material-ui/core/styles';
import BackGround from './background';
import Container from '@material-ui/core/Container';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';
import MaterialTable from 'material-table';
import {id_cliente,ReturnInfoClient} from '../containers/logincontext';
import {API_URL_SICANIA,API_URL_SICANIA_CHECK,APP_TOKEN_QUOTIAMO} from '../config/config';
import BlockIcon from '@material-ui/icons/Block';
import Avatar from '@material-ui/core/Avatar';
import { green, red,pink } from '@material-ui/core/colors';
import Tooltip from '@material-ui/core/Tooltip';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import axios   from 'axios';
import Box from '@material-ui/core/Box';
import Auth from '../auth/auth';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import LinkIcon from '@material-ui/icons/Link';
import WebIcon from '@material-ui/icons/Web';
import HttpsIcon from '@material-ui/icons/Https';
import Chip from '@material-ui/core/Chip';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import moment from 'moment';
import CachedIcon from '@material-ui/icons/Cached';
import ShareIcon from '@material-ui/icons/Share';
import {NAME_APP} from '../config/config';
import {Grid} from '@material-ui/core';
import SharedAnia from './sharedAnia';



const useStyles = makeStyles((theme) => ({
    alignFab: {
        marginLeft: 'auto',
      },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),      
    },
    img: {            
        maxWidth: '80px',
        maxHeight: '40px',
        justifyContent: 'center'
    },
    red_fill: {
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
    },
    green_fill: {
        color: '#fff',
        backgroundColor: green[500],
    },
    red: {
      color: red[500]
      
    },
    green: {
        color: green[500]        
    },
    customBadgeOk: {
      backgroundColor: green[500],
      color: "white"
    },
    customBadgeKo: {
      backgroundColor: red[500],
      color: "white"
    },
    pink: {
        color: theme.palette.getContrastText(pink[500]),
        backgroundColor: pink[500],
      },
}));

/*const listOrg = async (tokenApp) => {         
  
    
    try {        
    const result = await axios.get( API_URL_ORGANIZATIONS_LIST ,{
      headers:{            
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+tokenApp,                            
      }});            
    return result;
  
  } catch( e ){                
      return Promise.reject(e.response) ;
  }
  };*/

export default function SicAniaLists() {
    const [loading,setLoading] = React.useState(false);
    const orglists=Auth.getOrganizations();
    
    const classes = useStyles();    
    const myTextIcon = React.useRef(null);


    const handleRefresh = (id) => {  
      new Promise((resolve, reject) => {
        
        const url=API_URL_SICANIA_CHECK+"/"+id+"?applicationToken="+APP_TOKEN_QUOTIAMO
        setLoading(true);
        axios.get(url,
          {
              headers:{                
                  'Content-Type': 'application/json'
              }
          }
        ).then(payload=>{
          if(payload.status===200){
            
          }else{
            
          }
          setLoading(false);
          
        }).catch((error)=>{
                                                
          setLoading(false);
        }) ;                                                                        
        resolve();        
      })                
    }

    const initcolums=[                                              
        { title: 'Organizzazione', 
        field: 'id_organization' ,
        align: 'center',                                  
        
        editComponent: props => {
            return <select value={props.value}
                    defaultValue={{ label: orglists[0].description, value: orglists[0].id }}
                    onChange={e => props.onChange(e.target.value)}>
                    {!Number.isInteger(props.value)    &&
                    <option value="">Selezionare l'organizzazione</option>}
                            {orglists.map((org)=>
                    <option value={org.id}>{org.description}</option>)}
            </select>            
          },                                                                               
        render: rowData =><Box><img className={classes.img} alt={rowData.orgdescription} src={rowData.orgimage} /></Box>                                  
    },
    { title: 'Descrizione', field: 'description' },                                                                
    { title: 'Tipo', 
        field: 'type', 
        lookup: { 'ws': 'Web Service', 'scrape': 'Web Scraping','share':"Credenziali condivise" },
        render: rowData =>
        <Tooltip placement="top" title={rowData.status===1 ? "Connessione Ok":"Connessione Ko"}>
        <Badge badgeContent={rowData.status===1 ? <LinkIcon/>:<LinkOffIcon/>}  classes={rowData.status===1 ? { badge: classes.customBadgeOk }:{badge: classes.customBadgeKo}} >
        <Chip icon={rowData.type==="ws" ? <HttpsIcon/>:
                    rowData.type==="scrape" ? <WebIcon/>: 
                    <ShareIcon/>} 
                    label={rowData.type==="ws" ? "Web Service":
                    rowData.type==="scrape" ? "Web Scraping":
                    "Credenziali condivise"}/>
        </Badge>
        </Tooltip>
        
    },
    
    { title: 'Stato', 
      field: 'enabled',
      lookup: { 1: 'Abilitato', 0: 'Disabilitato' },
      render: rowData =><Tooltip title={rowData.enabled===1 ? "Abilitato":"Disabilitato"}>                                                                                                        
            {rowData.enabled===1 ? <CheckCircleOutlineIcon className={classes.green} />:<BlockIcon className={classes.red}/>}                                                    
        </Tooltip>

                               

        
    },
    { title: 'Utente', field: 'username' },
    { 
      title: 'Password', 
      field: 'password',
      render: rowData => <div>{rowData.password.replace(/./g,"*")}</div>
    },
    { title: 'Codice società', field: 'code' },
    { title: 'Share token', field: 'sharetoken' }        
    ];

    const [columns, ] = React.useState(initcolums);

    

    
    
    
      
    

    return (
        <main className={classes.content}>            
            <BackGround open={loading}/>     
            <div className={classes.appBarSpacer} />            
            <Container maxWidth="lg" className={classes.container}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" href="/" >
                        {NAME_APP}
                    </Link>                                        
                    <Typography color="textPrimary">Sic Ania</Typography>                                            
                </Breadcrumbs>

                <Card>
                    <CardContent>
                    <MaterialTable 
                       icons={{
                        Add: props => (
                          <div ref={myTextIcon} className={classes.addButton}>
                            <Avatar className={classes.pink}>
                            <AddToPhotosIcon/>
                            </Avatar>
                            
                            
                          </div>
                        ),
                      }}
                        title="Credenziali Sic Ania"
                        columns={columns}

                        detailPanel={[
                            {
                              tooltip: 'Verifica parametri connessione',
                              render: rowData => {
                                return (
                                  <Grid container spacing={2}>
                                      {rowData.type!=="share" &&
                                      <Grid item xs={6}>
                                            <Card >                                            
                                              <CardContent>
                                                <SharedAnia id={rowData.id}/>
                                                </CardContent>
                                            </Card>
                                      </Grid>}
                                      <Grid item xs={6}>
                                          <Card >                                            
                                            <CardContent>
                                              <Typography className={classes.title} color="textSecondary" gutterBottom>
                                                Stato connessione
                                              </Typography>
                                              <Typography variant="h5" component="h2" className={rowData.status===1 ? classes.green:classes.red}>
                                              {rowData.status===1 ? "Attiva":"Errore"}
                                              </Typography>
                                              <Typography className={classes.pos} color="textSecondary">
                                                Ultimo aggiornamento
                                              </Typography>
                                              <Typography variant="body2" component="p">
                                                {moment(rowData.lastcheck,"YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")} {moment(rowData.lastcheck,"YYYY-MM-DD HH:mm:ss").format("HH:mm:ss")}
                                                <br />
                                                {rowData.status!==1 && "Tentativi: "+rowData.attempts}
                                                
                                                
                                              </Typography>
                                            </CardContent>
                                            <CardActions>
                                              <Button onClick={() => handleRefresh(rowData.id)} startIcon={<CachedIcon/>} size="small">Aggiorna</Button>
                                            </CardActions>
                                          </Card>
                                    </Grid>
                                </Grid>
                                                                                                                                                                              
                                )
                              },
                            },
                          ]
                          }

                        localization={{
                            pagination: {
                                labelDisplayedRows: '{from}-{to} di {count}',
                                labelRowsSelect: 'per pagina',
                                labelRowsPerPage : 'per pagina',
                                firstAriaLabel: 'prima pagina',
                                firstTooltip : 'prima pagina',
                                previousAriaLabel: 'pagina precedente',
                                previousTooltip: 'pagina precedente',
                                nextAriaLabel: 'pagina successiva',
                                nextTooltip: 'pagina successiva',
                                lastAriaLabel: 'ultima pagina',
                                lastTooltip: 'ultima pagina',
                            },
                            toolbar: {  
                                nRowsSelected: '{0} elementi selezionati',
                                searchTooltip: 'Ricerca',
                                searchPlaceholder : 'Ricerca'
                            },                            
                            header: {
                                actions: ''
                            },
                            body: {
                                emptyDataSourceMessage: 'Nessun record da visualizzare',
                                filterRow: {
                                    filterTooltip: 'Filtri'
                                },
                                editRow:{
                                    saveTooltip: "Conferma",
                                    cancelTooltip: "Annulla",
                                    deleteText: "Sei sicuro di voler cancellare queste credenziali?"
                                },
                                addTooltip: "Aggiungi nuove credenziali",
                                deleteTooltip: "Cancella",
                                editTooltip: "Modifica",
                            
                            }                          
                            }}
                            options={{
                            pageSize: 10,
                            pageSizeOptions: [5,10],
                            paginationType: "stepped",
                            search: true,
                            headerStyle: {
                            backgroundColor: ReturnInfoClient(id_cliente,"primary_color"),
                            color:  '#ffffff',    
                                    '&:hover': {
                                        color: '#ffffff',
                                    },
                                    '&:active': {
                                        color: '#ffffff',
                                    }          
                            }
                            
                            }}
                        data={query =>
                                new Promise((resolve, reject) => {            
                                    let url = API_URL_SICANIA;
                                        url += '?per_page=' + query.pageSize
                                        url += '&page=' + (query.page + 1)

                                    if(query.search.length>3){
                                        url += '&search=' + query.search
                                    }            
                                    if(query.orderBy!==undefined){                                    
                                        url += '&orderby=' + query.orderBy.field
                                        url += '&orderdirection=' + query.orderDirection                                    
                                    }
            
                                    const requestOptions = {                                    
                                        headers: { 
                                            'Content-Type': 'application/json',
                                            'Authorization': 'Bearer '+Auth.getToken(),                
                                        },              
                                    };


                                    fetch(url,requestOptions)            
                                    .then(                  
                                        response => response.json()
                                    )
                                    .then(                
                                        result => {                                      
                                        resolve({
                                        data: result.data,                  
                                        page: result.current_page-1,
                                        totalCount: result.total,
                                        })
                                    })
                                })
                            }
                            editable={{
                                onRowAdd: newData =>
                                  new Promise((resolve, reject) => {
                                      const url=API_URL_SICANIA;
                                      axios.put(url,{
                                          description:newData.description,
                                          id_organization:newData.id_organization,
                                          enabled:newData.enabled,
                                          username:newData.username,
                                          password:newData.password, 
                                          code:newData.code,
                                          type:newData.type,
                                          sharetoken:newData.sharetoken
                                      },
                                      {
                                          headers:{
                                            'Authorization': 'Bearer '+Auth.getToken(),
                                            'Content-Type': 'application/json'
                                          }
                                      }
                                    ).then(payload=>{
                                        
                                    }) ;        
                                    resolve();                                                                
                                  }),
                                onRowUpdate: (newData, oldData) =>
                                  new Promise((resolve, reject) => {
                                    const index = oldData.id;                                    
                                    const url=API_URL_SICANIA + "/" + index;                                    
                                    axios.patch(url,{
                                        description:newData.description,
                                        id_organization:newData.id_organization,
                                        enabled:newData.enabled,
                                        username:newData.username,
                                        password:newData.password ,
                                        code:newData.code,
                                        type:newData.type,
                                        sharetoken:newData.sharetoken
                                      },
                                      {
                                          headers:{
                                            'Authorization': 'Bearer '+Auth.getToken(),
                                              'Content-Type': 'application/json'
                                          }
                                      }
                                    ).then(payload=>{
                                        
                                    }) ;                                                                        
                                    resolve();
                                    
                                  }),
                                onRowDelete: 
                                
                                oldData =>
                                new Promise((resolve, reject) => {
                                    
                                    const index = oldData.id;                                    
                                    const url=API_URL_SICANIA + "/" + index;
                                    
                                    axios.delete(url,
                                    {
                                        headers:{
                                          'Authorization': 'Bearer '+Auth.getToken(),
                                            'Content-Type': 'application/json'
                                        }
                                    }
                                    ).then(payload=>{
                                        
                                    }) ;                                                                        
                                    resolve();
                                  })}
                                }                                                                                                                                                                        
                        />

                    </CardContent>        
                    
                    
                </Card>          
          </Container>                      
        </main>
      );
};