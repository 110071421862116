import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BackGround from './background';
import Container from '@material-ui/core/Container';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import MaterialTable from 'material-table';
import { id_cliente, ReturnInfoClient } from '../containers/logincontext';
import { API_URL_ORGANIZATIONS, API_URL_CHECK, API_URL_ALLGOODS } from '../config/config';
import InfoImages from './InfoImages';
import BlockIcon from '@material-ui/icons/Block';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import LinkIcon from '@material-ui/icons/Link';
import { green, red } from '@material-ui/core/colors';
import Tooltip from '@material-ui/core/Tooltip';
import axios from 'axios';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import moment from 'moment';
import CachedIcon from '@material-ui/icons/Cached';
import Badge from '@material-ui/core/Badge';
//import OrganizationDetailPanel from './organizationdetailpanel';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { NAME_APP } from '../config/config';
import Auth from '../auth/auth';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditCompanyProfileData from './EditCompanyProfileData';
import EditDefaultParameterData from './EditDefaultParameterData';
import EditDiscountsData from './EditDiscountsData';
import EditPaymentsData from './EditPaymentsData';
import EditConventionsData from './EditConventionsData';
import EditFactoriesProperties from './EditFactoriesProperties';
import EditGuarantiesProperties from './EditGuaranteesProperties';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  alignFab: {
    marginLeft: 'auto',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  img: {
    display: 'block',
    maxWidth: '100%',
    maxHeight: '40px',
  },
  red_fill: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
  },
  green_fill: {
    color: '#fff',
    backgroundColor: green[500],
  },
  red: {
    color: red[500]

  },
  green: {
    color: green[500]
  },
  customBadgeOk: {
    backgroundColor: green[500],
    color: "white"
  },
  customBadgeKo: {
    backgroundColor: red[500],
    color: "white"
  }
}));

export default function Organizations() {
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [alertseverity, setAlertSeverity] = React.useState("success");
  const [alertmsg, setAlertMsg] = React.useState(false);
  const [goods, setGoods] = React.useState([]);


  React.useEffect(() => {
    const url = API_URL_ALLGOODS;
    axios.get(url,
      {
        headers: {
          'Authorization': 'Bearer ' + Auth.getToken(),
          'Content-Type': 'application/json'
        }
      }
    ).then(payload => {
      setGoods(payload.data.goods);
    });

  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleRefresh = () => {
    setLoading(true);
    try {
      const result = axios.get(API_URL_CHECK, {
        headers: {
          'Content-Type': 'application/json',
        }
      });
      setLoading(false);
      return result;

    } catch (e) {
      setLoading(false);
      return Promise.reject(e.response);
    }
  }

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.readAsDataURL(file);
      fileReader.onload = (() => {
        resolve(fileReader.result)
      });

      fileReader.onerror = ((error) => {
        reject(error);
      })

    }

    )
  }

  const uploadImage = async (e, props) => {
    const file = e.target.files[0];

    const base64 = await convertBase64(file);
    props.onChange(base64);
  };

  return (
    <main className={classes.content}>
      <BackGround open={loading} />
      <div className={classes.appBarSpacer} />
      <Container maxWidth="lg" className={classes.container}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" href="/" >
            {NAME_APP}
          </Link>
          <Link
            color="textPrimary"
            href="/organizations"
            aria-current="page"
          >
            Organizzazioni
          </Link>
        </Breadcrumbs>

        <Card>
          <CardContent>
            <MaterialTable
              title="Organizzazioni"
              columns={[
                {
                  title: 'Logo',
                  field: 'image',
                  align: 'center',
                  render: rowData => <Tooltip placement="top" title={rowData.status === 1 ? "Connessione Ok" : "Connessione Ko"}>
                    <Badge badgeContent={rowData.status === 1 ? <LinkIcon /> : <LinkOffIcon />} classes={rowData.status === 1 ? { badge: classes.customBadgeOk } : { badge: classes.customBadgeKo }} >
                      <img className={classes.img} alt={rowData.description} src={rowData.image} />
                    </Badge>
                  </Tooltip>,
                  editComponent: props => {
                    return <div>
                      <input type="file" onChange={(e) => {
                        const base64 = uploadImage(e, props);
                        props.onChange(base64);
                      }} />

                      <img alt="Immagine da caricare" className={classes.img} src={props.value} />

                    </div>
                  }

                },

                { title: 'Descrizione', field: 'description' },
                /*{ title: 'Ragione sociale', field: 'businessname' },
                { title: 'RUI', field: 'rui' },
                { title: 'Data di registrazione', field: 'registrationdate' },
                { title: 'P.IVA', field: 'vatnumber' },
                { title: 'Codice fiscale', field: 'fiscalcode' },
                { title: 'Via,Piazza...', field: 'toponym' },
                { title: 'Indirizzo', field: 'address' },
                { title: 'Numero', field: 'civicnumber' },
                { title: 'CAP', field: 'cap' },
                { title: 'Città', field: 'city' },
                { title: 'Provincia', field: 'province' },*/



                { title: 'Codice 810', field: 'code' },
                {
                  title: 'Stato',
                  field: 'enabled',
                  lookup: { 1: 'Abilitato', 0: 'Disabilitato' },
                  render: rowData => <Tooltip title={rowData.enabled === 1 ? "Abilitato" : "Disabilitato"}>
                    {rowData.enabled === 1 ? <CheckCircleOutlineIcon className={classes.green} /> : <BlockIcon className={classes.red} />}
                  </Tooltip>
                },
                { title: 'Utente', field: 'username' },
                {
                  title: 'Password',
                  field: 'password',
                  render: rowData => <div>{rowData.password.replace(/./g, "*")}</div>
                },
                { title: 'QUOTIAMO Service', field: 'url' }

              ]}
              detailPanel={[
                {
                  tooltip: 'Dettagli organizzazione',
                  render: rowData => {
                    return (
                      <div>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1-header"
                          >
                            <Typography className={classes.heading}>Dati organizzazione</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <EditCompanyProfileData type="org" data={rowData} />
                          </AccordionDetails>
                        </Accordion>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel2-header"
                          >
                            <Typography className={classes.heading}>Parametri default</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <EditDefaultParameterData type="org" data={rowData} orgid={rowData.id} />
                          </AccordionDetails>

                        </Accordion>

                        {goods.map((good, index) => (
                          <Accordion key={index} disabled={!good.enabled}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel2-header"
                            >
                              <Typography className={classes.heading}>Garanzie: {good.description}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <EditGuarantiesProperties type="org" datagroups={rowData} good={good} />
                            </AccordionDetails>

                          </Accordion>
                        ))
                        }

                        {goods.map((good, index) => (
                          <Accordion key={index} disabled={!good.enabled}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel2-header"
                            >
                              <Typography className={classes.heading}>Fabbriche abilitate: {good.description}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <EditFactoriesProperties type="org" datagroups={rowData} good={good} />
                            </AccordionDetails>

                          </Accordion>
                        ))
                        }

                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel-discounts-content"
                            id="panel-discounts-header"
                          >
                            <Typography className={classes.heading}>Sconti</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <EditDiscountsData type="org" orgid={rowData.id} id={rowData.id} />
                          </AccordionDetails>

                        </Accordion>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel-discounts-content"
                            id="panel-discounts-header"
                          >
                            <Typography className={classes.heading}>Convenzioni</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <EditConventionsData type="org" orgid={rowData.id} id={rowData.id} />
                          </AccordionDetails>

                        </Accordion>

                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel2-header"
                          >
                            <Typography className={classes.heading}>Sistemi di pagamento</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <EditPaymentsData type="org" id={rowData.id} orgid={rowData.id} />
                          </AccordionDetails>
                        </Accordion>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            id="panel-immagini"
                          >
                            <Typography className={classes.heading}>Immagini informative</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <InfoImages orgid={rowData.id} />
                          </AccordionDetails>
                        </Accordion>


                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography className={classes.heading}>Stato connessione</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Card >
                              <CardContent>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                  Stato connessione
                                </Typography>
                                <Typography variant="h5" component="h2" className={rowData.status === 1 ? classes.green : classes.red}>
                                  {rowData.status === 1 ? "Attiva" : "Errore"}
                                </Typography>
                                <Typography className={classes.pos} color="textSecondary">
                                  Ultimo aggiornamento
                                </Typography>
                                <Typography variant="body2" component="p">
                                  {moment(rowData.lastcheck, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")} {moment(rowData.lastcheck, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss")}
                                  <br />
                                  {rowData.status !== 1 && "Tentativi: " + rowData.attempts}


                                </Typography>
                              </CardContent>
                              <CardActions>
                                <Button onClick={handleRefresh} startIcon={<CachedIcon />} size="small">Aggiorna</Button>
                              </CardActions>
                            </Card>
                          </AccordionDetails>

                        </Accordion>

                      </div>
                    )
                  },
                },
              ]
              }
              localization={{
                pagination: {
                  labelDisplayedRows: '{from}-{to} di {count}',
                  labelRowsSelect: 'per pagina',
                  labelRowsPerPage: 'per pagina',
                  firstAriaLabel: 'prima pagina',
                  firstTooltip: 'prima pagina',
                  previousAriaLabel: 'pagina precedente',
                  previousTooltip: 'pagina precedente',
                  nextAriaLabel: 'pagina successiva',
                  nextTooltip: 'pagina successiva',
                  lastAriaLabel: 'ultima pagina',
                  lastTooltip: 'ultima pagina',
                },
                toolbar: {
                  nRowsSelected: '{0} elementi selezionati',
                  searchTooltip: 'Ricerca',
                  searchPlaceholder: 'Ricerca'
                },
                header: {
                  actions: ''
                },
                body: {
                  emptyDataSourceMessage: 'Nessun record da visualizzare',
                  filterRow: {
                    filterTooltip: 'Filtri'
                  },
                  editRow: {
                    saveTooltip: "Conferma",
                    cancelTooltip: "Annulla",
                    deleteText: "Sei sicuro di voler cancellare questa organizzazione?"
                  },
                  addTooltip: "Aggiungi",
                  deleteTooltip: "Cancella",
                  editTooltip: "Modifica",
                }
              }}
              options={{
                pageSize: 10,
                pageSizeOptions: [5, 10],
                paginationType: "stepped",
                search: true,
                headerStyle: {
                  backgroundColor: ReturnInfoClient(id_cliente, "primary_color"),
                  color: '#ffffff',
                  '&:hover': {
                    color: '#ffffff',
                  },
                  '&:active': {
                    color: '#ffffff',
                  }
                }

              }}
              data={query =>
                new Promise((resolve, reject) => {
                  let url = API_URL_ORGANIZATIONS;
                  url += '?per_page=' + query.pageSize
                  url += '&page=' + (query.page + 1)

                  if (query.search.length > 3) {
                    url += '&search=' + query.search
                  }
                  if (query.orderBy !== undefined) {
                    url += '&orderby=' + query.orderBy.field
                    url += '&orderdirection=' + query.orderDirection
                  }

                  const requestOptions = {
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer ' + Auth.getToken(),
                    },
                  };
                  fetch(url, requestOptions)
                    .then(
                      response => response.json()
                    )
                    .then(
                      result => {
                        resolve({
                          data: result.data,
                          page: result.current_page - 1,
                          totalCount: result.total,
                        })
                      })
                })
              }
              editable={{
                onRowAdd: newData =>
                  new Promise((resolve, reject) => {
                    const url = API_URL_ORGANIZATIONS;
                    axios.put(url, {
                      description: newData.description,
                      enabled: newData.enabled,
                      username: newData.username,
                      password: newData.password,
                      code: newData.code,
                      logo: newData.logo,
                      image: newData.image,
                      url: newData.url,
                      businessname: newData.businessname,
                      rui: newData.rui,
                      registrationdate: newData.registrationdate,
                      vatnumber: newData.vatnumber,
                      fiscalcode: newData.fiscalcode,
                      toponym: newData.toponym,
                      address: newData.address,
                      civicnumber: newData.civicnumber,
                      cap: newData.cap,
                      city: newData.city,
                      province: newData.province,
                      useeffectivedate: newData.useeffectivedate
                    },
                      {
                        headers: {
                          'Authorization': 'Bearer ' + Auth.getToken(),
                          'Content-Type': 'application/json'
                        }
                      }
                    ).then(payload => {
                      if (payload.status === 200) {
                        setAlertMsg("Inserimento dati avvenuto con successo");
                        setAlertSeverity("success");
                      } else {
                        setAlertMsg("Errore durante l'inserimento dati");
                        setAlertSeverity("error");
                      }
                      setOpen(true);
                    }).catch((error) => {
                      setAlertMsg(error.response.data.error);
                      setAlertSeverity("error");
                      setOpen(true);
                    });
                    resolve();
                  }),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    const index = oldData.id;
                    const url = API_URL_ORGANIZATIONS + "/" + index;
                    axios.patch(url, {
                      description: newData.description,
                      enabled: newData.enabled,
                      username: newData.username,
                      password: newData.password,
                      code: newData.code,
                      logo: newData.logo,
                      image: newData.image,
                      url: newData.url,
                      businessname: newData.businessname,
                      rui: newData.rui,
                      registrationdate: newData.registrationdate,
                      vatnumber: newData.vatnumber,
                      fiscalcode: newData.fiscalcode,
                      toponym: newData.toponym,
                      address: newData.address,
                      civicnumber: newData.civicnumber,
                      cap: newData.cap,
                      city: newData.city,
                      province: newData.province,
                      useeffectivedate: newData.useeffectivedate
                    },
                      {
                        headers: {
                          'Authorization': 'Bearer ' + Auth.getToken(),
                          'Content-Type': 'application/json'
                        }
                      }
                    ).then(payload => {
                      if (payload.status === 200) {
                        setAlertMsg("Aggiornamento avvenuto con successo");
                        setAlertSeverity("success");
                      } else {
                        setAlertMsg("Errore durante l'aggiornamento");
                        setAlertSeverity("error");
                      }
                      setOpen(true);
                    }).catch((error) => {
                      setAlertMsg(error.response.data.error);
                      setAlertSeverity("error");
                      setOpen(true);
                    });
                    resolve();
                  }),
                onRowDelete:
                  oldData =>
                    new Promise((resolve, reject) => {

                      const index = oldData.id;
                      const url = API_URL_ORGANIZATIONS + "/" + index;

                      axios.delete(url,
                        {
                          headers: {
                            'Authorization': 'Bearer ' + Auth.getToken(),
                            'Content-Type': 'application/json'
                          }
                        }
                      ).then(payload => {
                        if (payload.status === 200) {
                          setAlertMsg("Cancellazione avvenuta con successo");
                          setAlertSeverity("success");
                        } else {
                          setAlertMsg("Errore durante la cancellazione");
                          setAlertSeverity("error");
                        }
                        setOpen(true);
                      }).catch((error) => {

                        setAlertMsg(error.response.data.error);
                        setAlertSeverity("error");
                        setOpen(true);
                      });
                      resolve();
                    })
              }
              }
            />

          </CardContent>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={alertseverity}>
              {alertmsg}
            </Alert>
          </Snackbar>


        </Card>
      </Container>
    </main>
  );
};