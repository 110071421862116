import React from 'react';
import {makeStyles } from '@material-ui/core/styles';
import BackGround from './background';
import Container from '@material-ui/core/Container';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';
import MaterialTable from 'material-table';
import {id_cliente,ReturnInfoClient} from '../containers/logincontext';
import {API_URL_PRODUCTS,API_URL_ALLGOODS} from '../config/config';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import BlockIcon from '@material-ui/icons/Block';
import { green, red } from '@material-ui/core/colors';
import Tooltip from '@material-ui/core/Tooltip';
import axios   from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {NAME_APP} from '../config/config';
import Typography from '@material-ui/core/Typography';
import Auth from '../auth/auth';


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

const useStyles = makeStyles((theme) => ({
    alignFab: {
        marginLeft: 'auto',
      },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),      
    },
    img: {            
        maxWidth: 'auto',
        maxHeight: '40px',
        justifyContent: 'center'
    },
    red: {
        color: red[500]
    },
    green: {
        color: green[500]
        
    },
}));

export default function Factories() {
    const [loading] = React.useState(false);
    const classes = useStyles();    
    const [open, setOpen] = React.useState(false);
    const [alertseverity, setAlertSeverity] = React.useState("success");
    const [alertmsg, setAlertMsg] = React.useState(false);
    

    const [goods,setGoods]= React.useState(null);
    
    

    React.useEffect(() => {                
      const url=API_URL_ALLGOODS;
          axios.get(url,
          {
              headers:{
              'Authorization': 'Bearer '+Auth.getToken(),
              'Content-Type': 'application/json'
              }
          }
      ).then(payload=>{
            
          let temp=[];
          payload.data.goods.forEach(element => {
              temp.push({[element.id]:element.description});
          });                     
          
          var obj = payload.data.goods.reduce(function(acc, cur, i) {
            acc[cur.id] = cur.description;
            return acc;
            }, {});

          setGoods(obj);         
          
          
      }) ;        
              
    },[]);      


    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };

    const convertBase64 = (file) =>{
        return new Promise((resolve,reject)=>{
            const fileReader = new FileReader();

            fileReader.readAsDataURL(file);
            fileReader.onload = (()=>{
                  resolve(fileReader.result)
            });

            fileReader.onerror = ((error)=>{
                reject(error);
            })

        }
        
        )
    }

    const uploadImage = async (e,props)=>{
      const file=e.target.files[0];

      const base64 = await convertBase64(file);      
      props.onChange(base64);            
    };

    return (
        <main className={classes.content}>            
            <BackGround open={loading}/>     
            <div className={classes.appBarSpacer} />            
            <Container maxWidth="lg" className={classes.container}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" href="/" >
                        {NAME_APP}
                    </Link>                                        
                    <Typography color="textPrimary">Settori</Typography>    
                </Breadcrumbs>

                <Card>
                    <CardContent>
                    {goods!==null &&
                    <MaterialTable 
                        title="Settori"
                        columns={[                                              
                                
                                { title: 'Descrizione', field: 'description' },
                                { 
                                    title: 'Bene', 
                                    field: 'good_id',
                                    lookup: goods,
                                    render: rowData => goods[rowData.good_id]                                                       
                            
                                },                                
                                { title: 'Parametro url', field: 'paramurl'},                                
                                
                                { title: 'Stato', 
                                  field: 'enabled',
                                  lookup: { 1: 'Abilitato', 0: 'Disabilitato' },
                                  render: rowData =><Tooltip title={rowData.enabled===1 ? "Abilitato":"Disabilitato"}>                                                                                                        
                                                        {rowData.enabled===1 ? <CheckCircleOutlineIcon className={classes.green} />:<BlockIcon className={classes.red}/>}                                                    
                                                    </Tooltip>
                                                                                            
                                },
                                { title: 'Ordine', field: 'orderhits' },
                                { title: 'Immagine', 
                                  field: 'image' ,
                                  align: 'center',                                  
                                  render: rowData =><img alt={rowData.description} className={classes.img} src={rowData.image} />,                           
                                  editComponent: props => {
                                      return <div>
                                              <input type="file" onChange={(e)=>{
                                                  const base64= uploadImage(e,props);                                                  
                                                  props.onChange(base64);
                                              }} />
  
                                              <img alt="Immagine da caricare" className={classes.img} src={props.value}/>
                                              
                                          </div>
                                    }     
                                  
                                }
                                
                                ]}
                        localization={{
                            pagination: {
                                labelDisplayedRows: '{from}-{to} di {count}',
                                labelRowsSelect: 'per pagina',
                                labelRowsPerPage : 'per pagina',
                                firstAriaLabel: 'prima pagina',
                                firstTooltip : 'prima pagina',
                                previousAriaLabel: 'pagina precedente',
                                previousTooltip: 'pagina precedente',
                                nextAriaLabel: 'pagina successiva',
                                nextTooltip: 'pagina successiva',
                                lastAriaLabel: 'ultima pagina',
                                lastTooltip: 'ultima pagina',
                            },
                            toolbar: {  
                                nRowsSelected: '{0} elementi selezionati',
                                searchTooltip: 'Ricerca',
                                searchPlaceholder : 'Ricerca'
                            },                            
                            header: {
                                actions: ''
                            },
                            body: {
                                emptyDataSourceMessage: 'Nessun record da visualizzare',
                                filterRow: {
                                    filterTooltip: 'Filtri'
                                },
                                editRow:{
                                    saveTooltip: "Conferma",
                                    cancelTooltip: "Annulla",
                                    deleteText: "Sei sicuro di voler cancellare questo prodotto?"
                                },
                                addTooltip: "Aggiungi",
                                deleteTooltip: "Cancella",
                                editTooltip: "Modifica",
                            }                          
                        }}
                        options={{
                            pageSize: 10,
                            pageSizeOptions: [5,10],
                            paginationType: "stepped",
                            search: true,
                            headerStyle: {
                            backgroundColor: ReturnInfoClient(id_cliente,"primary_color"),
                            color:  '#ffffff',    
                                    '&:hover': {
                                        color: '#ffffff',
                                    },
                                    '&:active': {
                                        color: '#ffffff',
                                    }          
                            }
            
                        }}
                        data={query =>
                                new Promise((resolve, reject) => {            
                                    let url = API_URL_PRODUCTS;
                                        url += '?per_page=' + query.pageSize
                                        url += '&page=' + (query.page + 1)

                                    if(query.search.length>3){
                                        url += '&search=' + query.search
                                    }            
                                    if(query.orderBy!==undefined){                                    
                                        url += '&orderby=' + query.orderBy.field
                                        url += '&orderdirection=' + query.orderDirection                                    
                                    }
            
                                    const requestOptions = {                                    
                                        headers: { 
                                            'Content-Type': 'application/json',
                                            'Authorization': 'Bearer '+Auth.getToken(),                
                                        },              
                                    };


                                    fetch(url,requestOptions)            
                                    .then(                  
                                        response => response.json()
                                    )
                                    .then(                
                                        result => {                                      
                                        resolve({
                                        data: result.data,                  
                                        page: result.current_page-1,
                                        totalCount: result.total,
                                        })
                                    })
                                })
                            }
                            editable={{
                                onRowAdd: newData =>
                                  new Promise((resolve, reject) => {
                                    const url=API_URL_PRODUCTS;
                                      axios.put(url,{                                          
                                        description:newData.description,
                                        enabled:newData.enabled,
                                        paramurl:newData.paramurl,
                                        image:newData.image,
                                        good_id:newData.good_id,
                                        orderhits:newData.orderhits
                                          
                                      },
                                      {
                                          headers:{
                                            'Authorization': 'Bearer '+Auth.getToken(),
                                            'Content-Type': 'application/json'
                                          }
                                      }
                                    ).then(payload=>{
                                        
                                        if(payload.status===200){
                                            setAlertMsg("Inserimento dati avvenuto con successo");
                                            setAlertSeverity("success");
                                            
                                            
                                        }else{
                                            setAlertMsg("Errore durante l'inserimento dati");
                                            setAlertSeverity("error");                                            
                                        }
                                        setOpen(true);
                                        
                                    }).catch((error)=>{
                                        
                                        setAlertMsg(error.response.data.error);
                                        setAlertSeverity("error");                                            
                                        setOpen(true);
                                    }) ;        
                                    resolve();      
                                  }),
                                onRowUpdate: (newData, oldData) =>
                                  new Promise((resolve, reject) => {
                                    const index = oldData.id;                                    
                                    const url=API_URL_PRODUCTS + "/" + index;                                    
                                    axios.patch(url,{                                      
                                        description:newData.description,
                                        enabled:newData.enabled,
                                        paramurl:newData.paramurl,
                                        image:newData.image,
                                        good_id:newData.good_id    ,
                                        orderhits:newData.orderhits                                    
                                      },
                                      {
                                          headers:{
                                            'Authorization': 'Bearer '+Auth.getToken(),
                                              'Content-Type': 'application/json'
                                          }
                                      }
                                    ).then(payload=>{
                                        if(payload.status===200){
                                            setAlertMsg("Aggiornamento avvenuto con successo");
                                            setAlertSeverity("success");                                                                                        
                                        }else{
                                            setAlertMsg("Errore durante l'aggiornamento");
                                            setAlertSeverity("error");                                            
                                        }
                                        setOpen(true);
                                    }).catch((error)=>{
                                        
                                        setAlertMsg(error.response.data.error);
                                        setAlertSeverity("error");                                            
                                        setOpen(true);
                                    }) ;                                                                                
                                    resolve();
                                  }),
                                onRowDelete: oldData =>
                                  new Promise((resolve, reject) => {
                                    const index = oldData.id;                                    
                                    const url=API_URL_PRODUCTS + "/" + index;
                                    
                                    axios.delete(url,
                                    {
                                        headers:{
                                          'Authorization': 'Bearer '+Auth.getToken(),
                                            'Content-Type': 'application/json'
                                        }
                                    }
                                    ).then(payload=>{
                                        
                                        if(payload.status===200){
                                            setAlertMsg("Cancellazione avvenuta con successo");
                                            setAlertSeverity("success");
                                            
                                            
                                        }else{
                                            setAlertMsg("Errore durante la cancellazione");
                                            setAlertSeverity("error");                                            
                                        }
                                        setOpen(true);
                                    }).catch((error)=>{
                                        
                                        setAlertMsg(error.response.data.error);
                                        setAlertSeverity("error");                                            
                                        setOpen(true);
                                    }) ;                                                                                                                                                        
                                    resolve();
                                  }),
                              }}
                        />
                        }
                    </CardContent>        
                    
                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity={alertseverity}>
                            {alertmsg}
                        </Alert>
                    </Snackbar>      
                </Card>          
          </Container>                      
        </main>
      );
};