import React from 'react';
//import {withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MaterialTable from 'material-table';
import { id_cliente, ReturnInfoClient } from '../containers/logincontext';
import { API_URL_SERVICES_LIST, API_URL_FACTORIES_LIST } from '../config/config';
import BlockIcon from '@material-ui/icons/Block';
import { green, red } from '@material-ui/core/colors';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import WebIcon from '@material-ui/icons/Web';
import HttpsIcon from '@material-ui/icons/Https';
import ShareIcon from '@material-ui/icons/Share';
import RateReviewIcon from '@material-ui/icons/RateReview';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import LinkIcon from '@material-ui/icons/Link';
import Badge from '@material-ui/core/Badge';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CompaniesParametersDetailPanel from './companiesParametersdetailpanel';
/*import LockIcon from '@material-ui/icons/Lock';
import Typography from '@material-ui/core/Typography';*/
import Snackbar from '@material-ui/core/Snackbar';
import Auth from '../auth/auth';
import axios from 'axios';
import MuiAlert from '@material-ui/lab/Alert';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/*const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);
*/


const useStyles = makeStyles((theme) => ({

  cardMaxSize: {
    width: '100%'
  },
  alignFab: {
    marginLeft: 'auto',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  img: {
    maxWidth: '80px',
    maxHeight: '40px',
    justifyContent: 'center'
  },
  red_fill: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
  },
  green_fill: {
    color: '#fff',
    backgroundColor: green[500],
  },
  red: {
    color: red[500]

  },
  green: {
    color: green[500]
  },
  customBadgeOk: {
    backgroundColor: green[500],
    color: "white"
  },
  customBadgeKo: {
    backgroundColor: red[500],
    color: "white"
  }
}));

export default function CompaniesParametersTable(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [alertseverity, setAlertSeverity] = React.useState("success");
  const [alertmsg, setAlertMsg] = React.useState(false);
  const [factories, setFactories] = React.useState(null);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  React.useEffect(() => {
    const url = API_URL_FACTORIES_LIST;
    axios.get(url,
      {
        headers: {
          'Authorization': 'Bearer ' + Auth.getToken(),
          'Content-Type': 'application/json'
        }
      }
    ).then(payload => {

      let temp = [];
      payload.data.factories.forEach(element => {
        temp.push({ [element.id]: element.description });
      });

      var obj = payload.data.factories.reduce(function (acc, cur, i) {
        acc[cur.id] = cur.description;
        return acc;
      }, {});

      setFactories(obj);


    });

  }, []);




  return (<Card className={classes.cardMaxSize}>
    <CardContent  >
      {factories !== null &&
        <MaterialTable
          title={"Connessioni " + props.dataPanel.description}
          columns={[
            {
              title: 'Descrizione',
              field: 'description',
            },
            {
              title: 'Fabbrica',
              field: 'id_factory',
              lookup: factories,
              //render: rowData => factories[rowData.id_factory]                                                       
              align: 'center',
              render: rowData => <img className={classes.img} alt={rowData.companydescription} src={rowData.companyimage} />
            },
            {
              title: 'Connessione',
              field: 'type',
              align: 'center',
              lookup: { 'ws': 'Web Service', 'scrape': 'Web Scraping', 'share': 'Credenziali condivise', 'rate': 'Tariffa' },
              render: rowData =>
                <Box>
                  <Tooltip placement="top" title={rowData.status === 1 ? "Connessione Ok" : "Connessione Ko"}>
                    <Badge badgeContent={rowData.status === 1 ? <LinkIcon /> : <LinkOffIcon />} classes={rowData.status === 1 ? { badge: classes.customBadgeOk } : { badge: classes.customBadgeKo }} >
                      <Chip icon={rowData.type === "ws" ?
                        <HttpsIcon /> :
                        rowData.type === "scrape" ?
                          <WebIcon /> :
                          rowData.type === "share" ?
                            <ShareIcon /> :
                            <RateReviewIcon />
                      }

                        label={rowData.type === "ws" ?
                          "Web Service" :
                          rowData.type === "scrape" ?
                            "Web Scraping" :
                            rowData.type === "share" ?
                              "Share" : "Tariffa"} />
                    </Badge>
                  </Tooltip>
                </Box>
            },

            {
              title: 'Stato',
              field: 'enabled',
              lookup: { 1: 'Abilitato', 0: 'Disabilitato' },
              render: rowData => <Tooltip title={rowData.enabled === 1 ? "Abilitato" : "Disabilitato"}>
                {rowData.enabled === 1 ? <CheckCircleOutlineIcon className={classes.green} /> : <BlockIcon className={classes.red} />}
              </Tooltip>

            },





          ]}

          detailPanel={[
            {
              tooltip: 'Verifica parametri connessione',
              render: rowData => {
                return (<CompaniesParametersDetailPanel dataPanel={rowData} type={props.type} org_id={props.dataPanel.id} />)
              },
            },
          ]
          }




          localization={{
            pagination: {
              labelDisplayedRows: '{from}-{to} di {count}',
              labelRowsSelect: 'per pagina',
              labelRowsPerPage: 'per pagina',
              firstAriaLabel: 'prima pagina',
              firstTooltip: 'prima pagina',
              previousAriaLabel: 'pagina precedente',
              previousTooltip: 'pagina precedente',
              nextAriaLabel: 'pagina successiva',
              nextTooltip: 'pagina successiva',
              lastAriaLabel: 'ultima pagina',
              lastTooltip: 'ultima pagina',
            },
            toolbar: {
              nRowsSelected: '{0} elementi selezionati',
              searchTooltip: 'Ricerca',
              searchPlaceholder: 'Ricerca'
            },
            header: {
              actions: ''
            },
            body: {
              emptyDataSourceMessage: 'Nessun record da visualizzare',
              filterRow: {
                filterTooltip: 'Filtri'
              },
              editRow: {
                saveTooltip: "Conferma",
                cancelTooltip: "Annulla",
                deleteText: "Confermi la cancellazione ?"
              },
              addTooltip: "Aggiungi",
              deleteTooltip: "Cancella",
              editTooltip: "Modifica",
            }
          }}
          options={{
            pageSize: 10,
            pageSizeOptions: [5, 10],
            paginationType: "stepped",
            search: true,
            headerStyle: {
              backgroundColor: ReturnInfoClient(id_cliente, "primary_color"),
              color: '#ffffff',
              '&:hover': {
                color: '#ffffff',
              },
              '&:active': {
                color: '#ffffff',
              }
            }

          }}
          data={query =>
            new Promise((resolve, reject) => {
              let url = API_URL_SERVICES_LIST + "/" + props.dataPanel.id;
              url += '?per_page=' + query.pageSize
              url += '&page=' + (query.page + 1)

              if (query.search.length > 3) {
                url += '&search=' + query.search
              }
              if (query.orderBy !== undefined) {
                url += '&orderby=' + query.orderBy.field
                url += '&orderdirection=' + query.orderDirection
              }

              const requestOptions = {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + Auth.getToken(),
                },
              };


              fetch(url, requestOptions)
                .then(
                  response => response.json()
                )
                .then(
                  result => {
                    resolve({
                      data: result.data,
                      page: result.current_page - 1,
                      totalCount: result.total,
                    })
                  })
            })
          }



          editable={{
            onRowAdd: newData =>
              new Promise((resolve, reject) => {
                const url = API_URL_SERVICES_LIST;
                axios.put(url, {
                  id_factory: newData.id_factory,
                  enabled: newData.enabled,
                  type: newData.type,
                  id_organization: props.dataPanel.id,
                  description: newData.description
                },
                  {
                    headers: {
                      'Authorization': 'Bearer ' + Auth.getToken(),
                      'Content-Type': 'application/json'
                    }
                  }
                ).then(payload => {
                  if (payload.status === 200) {
                    setAlertMsg("Inserimento dati avvenuto con successo");
                    setAlertSeverity("success");
                  } else {
                    setAlertMsg("Errore durante l'inserimento dati");
                    setAlertSeverity("error");
                  }
                  setOpen(true);
                }).catch((error) => {
                  setAlertMsg(error.response.data.error);
                  setAlertSeverity("error");
                  setOpen(true);
                });
                resolve();
              }),

            onRowDelete:

              oldData =>
                new Promise((resolve, reject) => {

                  const index = oldData.id;
                  const url = API_URL_SERVICES_LIST + "/" + index;

                  axios.delete(url,
                    {
                      headers: {
                        'Authorization': 'Bearer ' + Auth.getToken(),
                        'Content-Type': 'application/json'
                      }
                    }
                  ).then(payload => {
                    if (payload.status === 200) {
                      setAlertMsg("Cancellazione avvenuta con successo");
                      setAlertSeverity("success");
                    } else {
                      setAlertMsg("Errore durante la cancellazione");
                      setAlertSeverity("error");
                    }
                    setOpen(true);
                  }).catch((error) => {

                    setAlertMsg(error.response.data.error);
                    setAlertSeverity("error");
                    setOpen(true);
                  });
                  resolve();
                })
          }
          }
        />
      }

    </CardContent>
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={alertseverity} closeText="Chiudi">
        {alertmsg}
      </Alert>
    </Snackbar>
  </Card>
  );
};

/*
{ title: 'Stato servizio', 
                                    field: 'factory_available' ,
                                    align: 'center',                                                                                                          
                                    render: rowData =><Box>
                                      {(rowData.factory_available!==1 ) &&               
                                  <HtmlTooltip title={<React.Fragment>
                                                          <Typography color="inherit">Servizio disabilitato</Typography>
                                                          <React.Fragment>
                                                            <em>Il servizio è disabilitato a livello generale</em><br/>
                                                            Per abilitarlo andare nel menù <u>Fabbriche</u>
                                                            </React.Fragment>
                                                          
                                                      </React.Fragment>}>                  
                                          <LockIcon className={classes.red}/>                                     
                                    </HtmlTooltip> 
                                  }                                      
                                      </Box>                                                                                                  
                                },

*/