import React,{useState} from 'react';
import {useCallback } from "react";
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Auth from '../auth/auth';
import {id_cliente,ReturnInfoClient} from '../containers/logincontext';
import {Helmet} from "react-helmet";
import { useHistory } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {NAME_APP} from '../config/config';
import validator from 'validator';
import Tooltip from '@material-ui/core/Tooltip';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import { KeyboardDatePicker} from "@material-ui/pickers";
import moment from 'moment';
import {Grid} from '@material-ui/core';

//import Hidden from '@material-ui/core/Hidden';

import "../css/dropzone.css";
import Dropzone from "./Dropzone";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}






const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  MonitorImage:{
      width:"100%",
      /*paddingTop:"100px",*/
      paddingLeft:"30px",
      paddingRight:"30px",
      maxWidth:"500px",
      [theme.breakpoints.down('md')]: {
        width:"60%",
      },
      [theme.breakpoints.down('sm')]: {
          display:"none"
      }
  },
  TitleForm:{
    paddingTop:"80px",
    paddingBottom:"10px",
    fontFamily:"Poppins",
    fontWeight:"700",
    fontSize:"69px",
    color:"white",
    paddingLeft:"30px",
    [theme.breakpoints.down('sm')]: {
      fontSize:"39px",
      paddingTop:"70px",
      paddingBottom:"10px",
    },
  },
  MonitorGrid:{
      paddingBottom:0,
      alignSelf: "end",
      marginBottom: "-40px",
      marginTop: "-100px" ,
      [theme.breakpoints.down('md')]: {
        marginTop: "0px" ,
      },
  },
  SiteLogo:{
    height:'48px',
    width: 'auto',
    paddingRight:'10px',
    paddingTop:'5px',
    [theme.breakpoints.down('sm')]: {
      height: '36px'
    },
  },
  
  InfoForm:{    
    fontFamily:"Poppins",
    fontWeight:"300",
    fontSize:"20px",
    color:"black",
    paddingLeft:"30px",
    paddingBottom:"10px",
    
    /*[theme.breakpoints.between('sm','lg')]: {
      fontSize:"15px",
    },
    [theme.breakpoints.down('sm')]: {
      fontSize:"14px",
    },*/
  },
  InfoFormBtn:{    
    fontFamily:"Poppins",
    fontWeight:"700",
    fontSize:"22px",
    color:"white",
    //paddingLeft:"30px",
    paddingTop:"10px",
    paddingBottom:"10px",
    backgroundColor:"black",
    margin:"30px",
    width:"300px",
    textAlign:"center",
    borderRadius:"10px",
    textDecoration: "none",
    [theme.breakpoints.down('sm')]: {
      width:"200px",
    },
  },


  infopaper:{
    
    //backgroundImage: 'url("/images/sfondo_quotiamo.png")' ,
    backgroundRepeat: "no-repeat",    
    //minHeight: "400px",
    //backgroundSize: "100% 100%",
    backgroundColor:"#f9b406",
    width:"100%"
  },
  ErrorCheck:{
      color:"red"
  },
  ContainerLogo:{
    margin:"auto",
    textAlign:"center"
  },
  ImageLogoBroker:{
    maxHeight:"60px",    
  },
  buttonProgress: {    
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalpaper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },

  
  paper: {
    //marginTop: theme.spacing(8),
    marginTop: "12px",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor:"#f9b406",
    padding:"0px"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
    backgroundColor:"white",    
    margin:"20px",
    padding:"20px",
    borderRadius:"30px"
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width:"300px",
  },
  GridAlignCenter:{
      textAlign:"center"
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    backgroundColor:"#f9b406",
  },
  container: {
    paddingTop:"0px",
    //paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    maxWidth:'1600',
    backgroundColor:"#f9b406",
  },
}));

const pattern = new RegExp(/^[0-9\b]+$/);

export default function SignUpWithAgencyInfo(pars) {
  const classes = useStyles();

  const history = useHistory(); 
  const [data, setData] = React.useState({
      title: pars.title,
      profile:pars.profile,
      name:"",
      surname:"",
      businessname:"",
      vatnumber:"",
      fiscalcode:"",
      toponym:"",
      address:"",
      civicnumber:"",
      cap:"",
      province:"",
      city:"",
      email:"",
      phone:"",
      logo: null,
      rui:"",
      role:"",
      //registrationdate: moment().format("YYYY-MM-DD"),
      registrationdate: null,
      contactconsent:0,
      privacyconsent:0
  });       


  

  
  
  const MAX_SIZE = 128000;  

  const [errorVisible, setErrorVisible] = useState(false);
  const [errorVisibleRUI, setErrorVisibleRUI] = useState(false);

  const [open, setOpen] = useState(false);
  const [msgmodal,setMsgModal] = useState('');
  const [msgTitle,setMsgTitle] = useState('');
  const [loading, setLoading] = React.useState(false);


  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.map(file => {
      const reader = new FileReader();
      reader.onload = function(e) {
                        
        

        if(e.loaded<=MAX_SIZE){
            setData({
                ...data,
                logo:e.target.result
              });
        }
        
        
      };
      reader.readAsDataURL(file);
      return file;
    });
  }, [data]);

  const handleChangeRegistrationDate = event => {              
    setData({
      ...data,
      registrationdate: moment(event).format("YYYY-MM-DD")
    });
  };


  const handleChangeChecked = (event) => {
    
    setData({
      ...data,
      [event.target.name]: event.target.checked ? 1:0
    });                      
  };


  const handleChangeValue = (event) => {                                       
        setData({
          ...data,
          [event.target.name]: event.target.value
        });
  };


  const handleChangeRUIValue = (event) => {                                       
    setData({
      ...data,
      [event.target.name]: event.target.value.toUpperCase()
    });
};

  const handleOpen = (title,msg) => {
    setMsgTitle(title);
    setMsgModal(msg);
    setOpen(true);
  };

  const handleClose = () => {

    setOpen(false);
    if(msgTitle==='success'){
        history.push('/');                              
    }
    
  };
  

  const signUpUser= (e) =>{
      
        e.preventDefault();     
      

        
      setErrorVisibleRUI(false);    
        
      

      if(data.name.length===0 ||
        data.surname.length===0 ||
        data.businessname.length===0 ||      
        data.email.length===0 ||      
        data.phone.length===0 ||      
        data.rui.length===0){
            handleOpen("error","Dati incompleti, impossibile procedere con la registrazione");
            setErrorVisible(true);
            return;
    }
    setErrorVisible(false);
    if (!validator.isEmail(data.email)){
        handleOpen("error","Email non valida");
        setErrorVisible(true);        
        return;
    }
    
    if (!(validator.isMobilePhone(data.phone,'it-IT') || pattern.test(data.phone))){
        handleOpen("error","Numero di telefono non valido");
        setErrorVisible(true);        
        return;
    }

    if(data.rui.length!==10){
          
      handleOpen("error","Codice RUI errato");
      setErrorVisibleRUI(true);
      return;
  }
  if(!(data.rui.charAt(0)==='A' || data.rui.charAt(0)==='B' || data.rui.charAt(0)==='C' || data.rui.charAt(0)==='D' || data.rui.charAt(0)==='E')){
    handleOpen("error","Codice RUI errato");
    setErrorVisibleRUI(true);
    return;
  }
  for (var i=1; i < data.rui.length; i++) {
      if(isNaN(data.rui.charAt(i))){
        handleOpen("error","Codice RUI errato");
        setErrorVisibleRUI(true);
        return;
      }
  } 

  

    if(data.privacyconsent===0){
        handleOpen("error","Per proseguire con la registrazione è necessario dare il proprio consenso per il trattamento dei dati personali");
        return;
    }
    if(data.contactconsent===0){
        handleOpen("error","Per proseguire con la registrazione è necessario dare il proprio consenso per essere contattati dopo aver attivato l'utenza di test");
        return;
    }
      


      
      
      setLoading(true); 
      Auth.signupWithAgency(data).then(          
          payload =>{                            
              setLoading(false);
              handleOpen("success","Registrazione avvenuta con successo. Vi verranno inviate le credenziali di accesso all'indirizzo "+data.email);
          }).catch(error=>{
            setLoading(false);
            handleOpen("error",error);
        });
  }
//maxWidth="xs"
  return (    
  
      <main className={classes.content}>      
      


      
      <CssBaseline />
      <Helmet>
        <title>{pars.title} {NAME_APP}</title>        
      </Helmet>
      
      <AppBar style={{backgroundColor: ReturnInfoClient(id_cliente,"appbar_color")}} position="absolute" className={clsx(classes.appBar)}>
      <Toolbar className={classes.toolbar}>            
        
        <img src={ReturnInfoClient(id_cliente,"logo")} alt="logo"  className={classes.SiteLogo}/>                            
        
        <Typography component="h1" align="center" color="textPrimary" variant="h4" noWrap className={classes.title}>      
          
        </Typography>
        

        
        <img src={pars.logo} alt="QUOTIAMO"  className={classes.SiteLogo}/>                                                    
      </Toolbar>
      </AppBar>
      {pars.title!=="Prova" &&
        <div className={classes.infopaper} style={{paddingTop:80}}>

        </div>
      }

      {pars.title==="Prova" &&
      <div className={classes.infopaper}>
      
        <Grid container spacing={3}>                            
            <Grid item xs={12}>
                 <Typography className={classes.TitleForm} >
                  Il futuro, <span style={{color:"black"}}>adesso.</span>
                  </Typography>
              </Grid>
        </Grid>
      
        <Grid container spacing={3}>                            
            <Grid item md={6} xs={12}>
                  
                  <Typography className={classes.InfoForm} >
                    QUOTIAMO è il primo e unico comparatore che
                    unisce le potenzialità della tecnologia
                    all'esperienza sul campo di Finital S.p.A.
                    </Typography>
                    <Typography className={classes.InfoForm} >
                    La soluzione che aspettavi per far decollare il tuo business!
                    </Typography>
                    <Typography className={classes.InfoForm} >
                        Come? Attiva la versione di prova e vedi tu stesso!          
                    </Typography>
                    
                    
              </Grid>
              
              <Grid className={classes.MonitorGrid} item xs={6} >
              <img  src="/images/quotiamo_monitor.png" alt="QUOTIAMO"  className={classes.MonitorImage}/>                                            
              </Grid>
        </Grid>
      </div>
      }
      
      <Container className={classes.container}>
      <div className={classes.paper}>
      
        
        <form className={classes.form} noValidate onSubmit ={signUpUser}>     

        <Grid container spacing={3}>                            
            <Grid item xs={12} md={6}>
                <Typography>
                  Compila il form e accedi gratis alla prova:
                </Typography>
            </Grid>
        </Grid>
        <Grid container spacing={3}>                            
            
            <Grid item xs={12} md={6}>
                            <Grid container spacing={3}>                            
                            <Grid item xs={12}>
                                <TextField id="idname" required className={classes.tableData}  fullWidth label="Nome" variant="outlined" onChange={handleChangeValue} name={"name"}  
                                value= {data.name} 
                                error={data.name.length===0 && errorVisible} 
                                helperText={errorVisible && "Dato obbligatorio"}
                                />
                            </Grid>
                            
                            
                        </Grid>        
                        <Grid container spacing={3}>                            
                          <Grid item xs={12}>
                            <TextField required className={classes.tableData}  fullWidth label="Cognome" variant="outlined" onChange={handleChangeValue} name={"surname"}  
                            value= {data.surname} 
                            error={data.surname.length===0 && errorVisible} 
                            helperText={data.surname.length===0 && errorVisible && "Dato obbligatorio"}
                            />
                            </Grid>
                        </Grid>        

                        <Grid container spacing={3}>                            
                            <Grid item xs={12}>
                                <TextField required className={classes.tableData}  fullWidth label="Indirizzo email" variant="outlined" onChange={handleChangeValue} name={"email"}  
                                value= {data.email} 
                                error={(data.email.length===0 || !validator.isEmail(data.email)) && errorVisible} 
                                helperText={(data.email.length===0 || !validator.isEmail(data.email)) && errorVisible && "Indirizzo email non valido"}
                                />
                            </Grid>            
                        </Grid>            
                        <Grid container spacing={3}>                            
                            <Grid item xs={12}>
                                <TextField required className={classes.tableData}  
                                fullWidth label="Telefono" variant="outlined" onChange={handleChangeValue} name={"phone"}  
                                value= {data.phone} 

                                
                                error={(data.phone.length===0 || !(validator.isMobilePhone(data.phone,'it-IT') || pattern.test(data.phone))) && errorVisible} 
                                helperText={(data.phone.length===0 || !(validator.isMobilePhone(data.phone,'it-IT') || pattern.test(data.phone))) && errorVisible && "Dato obbligatorio"}
                                />
                            </Grid>            
                            
                        </Grid>        

                        <Grid container spacing={3}>                            
                            <Grid item xs={12}>
                                <TextField className={classes.tableData}  fullWidth label="Ruolo" variant="outlined" onChange={handleChangeValue} name={"role"}  
                                value= {data.role}             
                                />
                            </Grid>                        
                        </Grid>        

                        <Grid container spacing={3}> 
                            <Grid item xs={12}>
                                <TextField                  
                                variant="outlined"                 
                                fullWidth 
                                required
                                label="Denominazione Broker"         
                                onChange={handleChangeValue} 
                                name="businessname"  
                                id="businessname"                                  
                                value= {data.businessname}                 
                                error={data.businessname.length===0 && errorVisible} 
                                helperText={data.businessname.length===0 && errorVisible && "Dato obbligatorio"}
                                />
                            </Grid>
                        </Grid>
            </Grid>

            <Grid item xs={12} md={6}>


                      <Grid container spacing={3}> 
                      <Grid item xs={12}>
                          <TextField                 
                          required
                          className={classes.tableData}  
                          fullWidth label="RUI" variant="outlined" 
                          onChange={handleChangeRUIValue} name={"rui"}  
                          value= {data.rui} 
                          error={errorVisibleRUI} 
                          helperText={errorVisibleRUI && "Codice RUI errato"}
                          />
                      </Grid>
                  </Grid>
                  <Grid container spacing={3}> 
                      <Grid item xs={12}>
                        <KeyboardDatePicker
                          helperText={''}
                          style={{marginTop:"0px",marginBottom:"0px"}}
                                          className={classes.tableData} 
                                          label="Data registrazione RUI"                        
                                          value={data.registrationdate}
                                          onChange={handleChangeRegistrationDate}
                                          format='dd/MM/yyyy'
                                          clearLabel="Conferma"
                                          cancelLabel="Annulla"
                                          name="registrationdate"      
                                          fullWidth                                                                                  
                                          inputVariant="outlined"    
                                          margin="normal"                                                                
                                      />
                      </Grid> 
                </Grid>        
                        
                  
                <Grid container spacing={3}> 
                      <Grid item xs={12}>
                          <TextField className={classes.tableData}  fullWidth label="P.IVA" variant="outlined" onChange={handleChangeValue} name={"vatnumber"}  value= {data.vatnumber} />
                      </Grid>
                </Grid>
                  <Grid container spacing={3}>                            
                      <Grid item xs={12}>
                      <TextField className={classes.tableData}  fullWidth label="Codice Fiscale" variant="outlined" onChange={handleChangeValue} name={"fiscalcode"}  value= {data.fiscalcode} />             
                      </Grid>
                  </Grid>
                <Grid container spacing={3}>                            
                      <Grid item xs={12} md={3}>
                      <Tooltip title="Via, piazza, ecc">                                                    
                        <TextField className={classes.tableData}  fullWidth label="Via" variant="outlined" onChange={handleChangeValue} name={"toponym"}  value= {data.toponym} />                                                
                      </Tooltip>
                        
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField className={classes.tableData}  fullWidth label="Indirizzo" variant="outlined" onChange={handleChangeValue} name={"address"}  value= {data.address} />              
                      </Grid>
                      <Grid item xs={12} md={3}>
                      <TextField className={classes.tableData}  fullWidth label="Numero" variant="outlined" onChange={handleChangeValue} name={"civicnumber"}  value= {data.civicnumber} />                              
                      </Grid>
                  </Grid>        
                  
                  <Grid container spacing={3}>                            
                      <Grid item xs={12} md={3}>
                        <TextField className={classes.tableData}  fullWidth label="CAP" variant="outlined" onChange={handleChangeValue} name={"cap"}  value= {data.cap} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                      <TextField className={classes.tableData}  fullWidth label="Comune" variant="outlined" onChange={handleChangeValue} name={"city"}  value= {data.city} />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <TextField className={classes.tableData}  fullWidth label="Provincia" variant="outlined" onChange={handleChangeValue} name={"province"}  value= {data.province} />
                      </Grid>
                  </Grid>        
            </Grid>
        </Grid>



        


        

        
        <Grid container spacing={3}> 
            <Grid className={classes.ContainerLogo} item xs={12} md={3}>

            </Grid>
            <Grid className={classes.ContainerLogo} item xs={12} md={6}>
                <Dropzone onDrop={onDrop} accept={"image/*"} maxSize={MAX_SIZE} />
            </Grid>
            <Grid className={classes.ContainerLogo} item xs={12} md={3}>

            </Grid>
        </Grid>
        {data.logo!==null &&
          <Grid container spacing={3}> 
            <Grid className={classes.ContainerLogo} item xs={12}>            
            <img className={classes.ImageLogoBroker} src={data.logo} alt={"Logo"}  />                                                            
            </Grid>
        </Grid>
        }
        
        
        

        <Grid container spacing={3}>                            
         
         
                         
            <Grid item xs={12}>

            {pars.profile===1 &&
            <FormControlLabel
                
                control={<Checkbox checked={data.privacyconsent===1} onChange={handleChangeChecked} name="privacyconsent" />}
                label={<div className={data.privacyconsent===0 && errorVisible && classes.ErrorCheck}>Acconsento al <a className={data.privacyconsent===0 && errorVisible && classes.ErrorCheck} rel="noreferrer" target='_blank' href='https://www.finital.it/privacy-policy/'>Trattamento dei miei dati personali*</a></div>}
            />   
            }
            {pars.profile===2 &&
            <FormControlLabel                
                control={<Checkbox checked={data.privacyconsent===1} onChange={handleChangeChecked} name="privacyconsent" />}
                label={<div className={data.privacyconsent===0 && errorVisible && classes.ErrorCheck}>Acconsento al <a className={data.privacyconsent===0 && errorVisible && classes.ErrorCheck} rel="noreferrer" target='_blank' href='https://www.recrea.srl/privacy-policy/'>Trattamento dei miei dati personali*</a></div>}                                
            />   
            }
            </Grid>                                       
                            
        </Grid>

        <Grid container spacing={3}>                            
         
         
                         
            <Grid item xs={12}>
            {pars.profile===1 &&
            <FormControlLabel
                className={data.contactconsent===0 && errorVisible && classes.ErrorCheck}
                required
                control={<Checkbox checked={data.contactconsent===1} onChange={handleChangeChecked} name="contactconsent" />}
                label="Acconsento ad essere contattato dopo l'attivazione del servizio di test di QUOTIAMO*"
            />   
            }
            {pars.profile===2 &&
            <FormControlLabel
                className={data.contactconsent===0 && errorVisible && classes.ErrorCheck}
                required
                control={<Checkbox checked={data.contactconsent===1} onChange={handleChangeChecked} name="contactconsent" />}
                label="Acconsento ad essere contattato dopo l'attivazione del servizio di PROVA di QUOTIAMO*"
            />   
            }
            </Grid>                                       
                            
        </Grid>                    
        <Grid container spacing={3}>                            
          <Grid className={classes.GridAlignCenter}  item xs={12}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
            className={classes.InfoFormBtn}
          >
            PROVA QUOTIAMO
          </Button>
          </Grid>
          </Grid>
          {loading && <CircularProgress size={24} className={classes.buttonProgress} />}          
        </form>
        
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={msgTitle} closeText="Chiudi">
          {msgmodal}
          </Alert>
      </Snackbar>
      
      
    </Container>
    </main>

  );
}


/*

<Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="Nome"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Cognome"
                name="lastName"
                autoComplete="lname"
              />
            </Grid>

*/
