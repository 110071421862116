import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import SaveIcon from '@material-ui/icons/Save';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { green, red } from '@material-ui/core/colors';
import { API_URL_FACTORIES_ORGANIZATIONS, API_URL_GROUPS_SERVICES, API_URL_AGENCIES_SERVICES, API_URL_USERS_SERVICES } from '../config/config';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Auth from '../auth/auth';
import { CheckFeeCommissions } from './utils';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Grid } from '@material-ui/core';
import GuaranteeFactoryOrgDetailpanel from './guaranteeFactoryOrgDetailpanel';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  alignFab: {
    marginLeft: 'auto',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  img: {
    display: 'block',
    maxWidth: '100%',
    maxHeight: '40px',
    minWidth: '50px',
  },
  img_service: {
    display: 'block',
    maxWidth: '40px',
    maxHeight: '20px',
  },
  red: {
    color: red[500],
  },
  green: {
    color: green[500],
  },
  hiddenDiv: {
    display: 'none'
  }
}));

export default function OrganizationDetailPanel(props) {
  const classes = useStyles();
  const [savestate, setsavestate] = React.useState(true);
  const [savenum, setsavenum] = React.useState(0);
  const [serviceslist, setserviceslist] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [alertseverity, setAlertSeverity] = React.useState("success");
  const [alertmsg, setAlertMsg] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = (() => {
        resolve(fileReader.result)
      });

      fileReader.onerror = ((error) => {
        reject(error);
      })
    })
  }

  const uploadImage = async (e, index) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    serviceslist[index].infoimage = base64;
    setserviceslist(serviceslist);
    setsavestate(false);
    setsavenum(savenum + 1);
    //props.onChange(base64);
    //props.onChange(base64);            
  };

  const handleChange = (event) => {
    //const newserviceslist=serviceslist;
    const selectcheck = event.target.name.split("_");
    serviceslist.map((single, index) => {
      if ((Number(selectcheck[2]) === single.ser_fac_org_id)) {
        if (event.target.checked) {
          serviceslist[index].chk_enable = 1;
        } else {
          serviceslist[index].chk_enable = 0;
        }
      }
      return false;
    }
    );
    setserviceslist(serviceslist);
    setsavestate(false);
    setsavenum(savenum + 1);
  };

  const handleChangeValue = (event) => {
    const selectcheck = event.target.name.split("_");

    serviceslist.map((single, index) => {
      if (Number(selectcheck[2]) === single.ser_fac_org_id) {
        switch (selectcheck[0]) {
          case "commin":
            serviceslist[index].commissionsMin = event.target.value;
            break;
          case "commax":
            serviceslist[index].commissionsMax = event.target.value;
            break;
          case "comdef":
            serviceslist[index].commissionsDefault = event.target.value;
            break;
          case "comtype":
            serviceslist[index].commissionsType = event.target.value;
            break;
          case "feemin":
            serviceslist[index].feeMin = event.target.value;
            break;
          case "feemax":
            serviceslist[index].feeMax = event.target.value;
            break;
          case "feedef":
            serviceslist[index].feeDefault = event.target.value;
            break;
          case "feetype":
            serviceslist[index].feeType = event.target.value;
            break;
          case "email":
            serviceslist[index].email = event.target.value;
            break;
          case "emailenabled":
            if (event.target.checked) {
              serviceslist[index].usedefaultemail = 1;
            } else {
              serviceslist[index].usedefaultemail = 0;
            }
            break;
          case "phonenumber":
            serviceslist[index].phonenumber = event.target.value;
            break;
          case "agZona":
            serviceslist[index].agZona = event.target.value;
            break;
          case "commerciale":
            serviceslist[index].commerciale = event.target.value;
            break;
          case "phonenumberenabled":
            if (event.target.checked) {
              serviceslist[index].usedefaultphonenumber = 1;
            } else {
              serviceslist[index].usedefaultphonenumber = 0;
            }
            break;
          default:
            break;
        }
      }
      return false;
    }
    );
    setserviceslist(serviceslist);
    setsavestate(false);
    setsavenum(savenum + 1);
  };

  React.useEffect(() => {
    let url;
    if (props.type === "org") {
      url = API_URL_FACTORIES_ORGANIZATIONS + "/" + props.datagroups.id + "/" + props.product.id;
    }
    if (props.type === "grp") {
      url = API_URL_GROUPS_SERVICES + "/" + props.datagroups.id + "/" + props.product.id;
    }
    if (props.type === "ag") {
      url = API_URL_AGENCIES_SERVICES + "/" + props.datagroups.id + "/" + props.product.id;
    }
    if (props.type === "usr") {
      url = API_URL_USERS_SERVICES + "/" + props.user + "/" + props.datagroups.id + "/" + props.product.id;
    }

    if (props.type !== "") {
      axios.get(url,
        {
          headers: {
            'Authorization': 'Bearer ' + Auth.getToken(),
            'Content-Type': 'application/json'
          }
        }
      ).then(payload => {
        setserviceslist(payload.data);
      });
    }
  }, [props]);

  const handleSave = () => {
    let checkError = CheckFeeCommissions(serviceslist);
    if (checkError !== "") {
      setAlertMsg(checkError);
      setAlertSeverity("warning");
      setOpen(true);
      return;
    }

    new Promise((resolve, reject) => {
      const myObjStr = JSON.stringify(serviceslist);
      let url;
      if (props.type === "org") {
        url = API_URL_FACTORIES_ORGANIZATIONS + "/" + props.datagroups.id + "/" + props.product.id;
      }
      if (props.type === "grp") {
        url = API_URL_GROUPS_SERVICES + "/" + props.datagroups.id + "/" + props.product.id;
      }
      if (props.type === "ag") {
        url = API_URL_AGENCIES_SERVICES + "/" + props.datagroups.id + "/" + props.product.id;
      }
      if (props.type === "usr") {
        url = API_URL_USERS_SERVICES + "/" + props.user + "/" + props.datagroups.id + "/" + props.product.id;
      }

      if (props.type !== "") {
        axios.patch(url, {
          list: myObjStr,
        },
          {
            headers: {
              'Authorization': 'Bearer ' + Auth.getToken(),
              'Content-Type': 'application/json'
            }
          }
        ).then(payload => {
          if (payload.status === 200) {
            setAlertMsg("Salvataggio dati avvenuto con successo");
            setAlertSeverity("success");
          } else {
            setAlertMsg("Errore durante il salvataggio dei dati");
            setAlertSeverity("error");
          }
          setOpen(true);
        }).catch((error) => {
          setAlertMsg(error.response.data.error);
          setAlertSeverity("error");
          setOpen(true);
        });
      }
      resolve();
    })
  }

  return (
    <>
      <Card >
        <CardContent>
          <div className={classes.root}>
            {serviceslist.map((single, index) => (
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-label="Expand"
                  aria-controls="additional-actions1-content"
                  id="additional-actions1-header"
                >
                  <FormControlLabel
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                    control={<Checkbox disabled={single.factory_available !== 1} name={"ch_" + props.datagroups.id + "_" + single.ser_fac_org_id} onChange={handleChange} checked={single.chk_enable === 1} />}
                    label={<Box>
                      <img className={classes.img} alt={single.factorydescription} src={single.factoryimage} />
                      <Typography>
                        {single.description_connection}
                      </Typography>
                    </Box>} />
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="Tabella impostazioni">
                          <TableHead>
                            <TableRow>
                              <TableCell align="center" colSpan={4}>Commissioni</TableCell>
                              <TableCell align="center" colSpan={4}>Diritti</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>minimo</TableCell>
                              <TableCell>massimo</TableCell>
                              <TableCell>default</TableCell>
                              <TableCell>tipo</TableCell>
                              <TableCell>minimo</TableCell>
                              <TableCell>massimo</TableCell>
                              <TableCell>default</TableCell>
                              <TableCell>tipo</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell ><TextField disabled={single.factory_available !== 1 || single.chk_enable === 0} onChange={handleChangeValue} name={"commin_" + props.datagroups.id + "_" + single.ser_fac_org_id} label={single.commissionsType === "euro" ? "€" : "%"} value={single.commissionsMin} /></TableCell>
                              <TableCell ><TextField disabled={single.factory_available !== 1 || single.chk_enable === 0} onChange={handleChangeValue} name={"commax_" + props.datagroups.id + "_" + single.ser_fac_org_id} label={single.commissionsType === "euro" ? "€" : "%"} value={single.commissionsMax} /></TableCell>
                              <TableCell ><TextField disabled={single.factory_available !== 1 || single.chk_enable === 0} onChange={handleChangeValue} name={"comdef_" + props.datagroups.id + "_" + single.ser_fac_org_id} label={single.commissionsType === "euro" ? "€" : "%"} value={single.commissionsDefault} /></TableCell>
                              <TableCell >
                                <TextField
                                  name={"comtype_" + props.datagroups.id + "_" + single.ser_fac_org_id}
                                  onChange={handleChangeValue}
                                  disabled={single.factory_available !== 1 || single.chk_enable === 0}
                                  select
                                  SelectProps={{ native: true }}
                                  value={single.commissionsType === "euro" ? "euro" : "perc"}
                                >
                                  <option key={"perc"} value={"perc"}>
                                    %
                                  </option>
                                  <option key={"euro"} value={"euro"}>
                                    €
                                  </option>
                                </TextField>
                              </TableCell>

                              <TableCell ><TextField disabled={single.factory_available !== 1 || single.chk_enable === 0} onChange={handleChangeValue} name={"feemin_" + props.datagroups.id + "_" + single.ser_fac_org_id} label={single.feeType === "perc" ? "%" : "€"} value={single.feeMin} /></TableCell>
                              <TableCell ><TextField disabled={single.factory_available !== 1 || single.chk_enable === 0} onChange={handleChangeValue} name={"feemax_" + props.datagroups.id + "_" + single.ser_fac_org_id} label={single.feeType === "perc" ? "%" : "€"} value={single.feeMax} /></TableCell>
                              <TableCell ><TextField disabled={single.factory_available !== 1 || single.chk_enable === 0} onChange={handleChangeValue} name={"feedef_" + props.datagroups.id + "_" + single.ser_fac_org_id} label={single.feeType === "perc" ? "%" : "€"} value={single.feeDefault} /></TableCell>
                              <TableCell >
                                <TextField
                                  name={"feetype_" + props.datagroups.id + "_" + single.ser_fac_org_id}
                                  onChange={handleChangeValue}
                                  disabled={single.factory_available !== 1 || single.chk_enable === 0}
                                  select
                                  SelectProps={{ native: true }}
                                  value={single.feeType === "perc" ? "perc" : "euro"}
                                >
                                  <option key={"euro"} value={"euro"}>
                                    €
                                  </option>
                                  <option key={"perc"} value={"perc"}>
                                    %
                                  </option>
                                </TextField>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                    {props.type === "org" &&
                      <>
                        <Grid item xs={3}>
                          <FormControlLabel
                            control={<Checkbox disabled={single.factory_available !== 1 || single.chk_enable === 0} name={"emailenabled_" + props.datagroups.id + "_" + single.ser_fac_org_id} onChange={handleChangeValue} checked={single.usedefaultemail === 1}
                            />}
                            label="Usa Email di default"
                          />
                        </Grid>
                        <Grid item xs={9}>
                          <TextField fullWidth disabled={single.factory_available !== 1 || single.chk_enable === 0 || single.usedefaultemail === 0} onChange={handleChangeValue} name={"email_" + props.datagroups.id + "_" + single.ser_fac_org_id} label="Email" value={single.email} />
                        </Grid>
                        <Grid item xs={3}>
                          <FormControlLabel
                            control={<Checkbox disabled={single.factory_available !== 1 || single.chk_enable === 0} name={"phonenumberenabled_" + props.datagroups.id + "_" + single.ser_fac_org_id} onChange={handleChangeValue} checked={single.usedefaultphonenumber === 1} />}
                            label="Usa telefono di default"
                          />
                        </Grid>
                        <Grid item xs={9}>
                          <TextField fullWidth disabled={single.factory_available !== 1 || single.chk_enable === 0 || single.usedefaultphonenumber === 0} onChange={handleChangeValue} name={"phonenumber_" + props.datagroups.id + "_" + single.ser_fac_org_id} label="Telefono" value={single.phonenumber} />
                        </Grid>
                        <Grid item xs={12}>
                          Immagine informativa da allegare al preventivo
                          {single.infoimage !== null &&
                            <img className={classes.img} alt="Immagine informatica" src={single.infoimage} />
                          }
                          <div>
                            <input type="file" onChange={(e) => {
                              const base64 = uploadImage(e, index);
                            }} />
                            <img alt="Immagine da caricare" className={classes.img} src={props.value} />
                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <GuaranteeFactoryOrgDetailpanel product_id={props.product.id} ser_fac_org_id={single.ser_fac_org_id} org_id={props.datagroups.id} />
                        </Grid>
                      </>
                    }

                    {props.type === "usr" &&
                      <>
                        <Grid item xs={12}>
                          <TextField fullWidth disabled={single.factory_available !== 1 || single.chk_enable === 0} onChange={handleChangeValue} name={"commerciale_" + props.datagroups.id + "_" + single.ser_fac_org_id} label="Codice commerciale" value={single.commerciale} />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField fullWidth disabled={single.factory_available !== 1 || single.chk_enable === 0} onChange={handleChangeValue} name={"agZona_" + props.datagroups.id + "_" + single.ser_fac_org_id} label="Codice agenzia" value={single.agZona} />
                        </Grid>
                      </>
                    }
                  </Grid>
                </AccordionDetails>
              </Accordion>))
            }
          </div>

          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={alertseverity}>
              {alertmsg}
            </Alert>
          </Snackbar>
        </CardContent>
        <CardActions>
          <div className={classes.hiddenDiv}>({savenum})</div>
          <Button disabled={savestate} onClick={handleSave} startIcon={<SaveIcon />} size="small">Salva</Button>
        </CardActions>
      </Card>
    </>
  );
};