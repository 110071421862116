import React from 'react';
import SaveIcon from '@material-ui/icons/Save';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import axios   from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Auth from '../auth/auth';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import {API_URL_PAYMENTS_RULES} from '../config/config';
import Checkbox from '@material-ui/core/Checkbox';
import { Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}







export default function EditPaymentsData(props) {        
    const [open, setOpen] = React.useState(false);
    const [alertseverity, setAlertSeverity] = React.useState("success");
    const [alertmsg, setAlertMsg] = React.useState(false);    
    const [openDialog, setOpenDialog] = React.useState(false);
    const [payments, setPayments] = React.useState([]);
    

    
  
    const handleCloseDialog = () => {
      setOpenDialog(false);
    };

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }  
      setOpen(false);
    };
    

    

    const handleChange = (numparam,id)  =>(event) => {
      let newArray=[];        
      payments.forEach((item,index)=>{                                
            if(item.id===id  ){                                                    
                
                if(numparam===1){
                    item.checked = event.target.checked;            
                }
                if(numparam===2){
                    item.removefee = event.target.checked;                                
                }
                if(numparam===3){
                    item.removecommissions = event.target.checked;            
                }
                    
            }         
            newArray.push(item);               
          }               
      );            
      setPayments(newArray);              
    }    
      
    

    const handleChangeValue = (numparam,id) =>(event) => {

        
        let newArray=[];
        payments.forEach((item,index)=>{                                
            if(item.id===id  ){                                                    
                
                if(numparam===1){
                    item.paymentcommissions   = event.target.value;                                                                
                }
                if(numparam===2){
                    item.paymentfee = event.target.value;            
                    
                }
                
                    
            }         
            newArray.push(item);               
          }               
        );            
        setPayments(newArray);              
        
    };

    
    


      
          
    const handleSave = () => {  

      

      
      let list_payments=[];
      let list_removecommissions=[];
      let list_removefee=[];
      let list_paymentcommissions=[];
      let list_paymentfee=[];
        payments.forEach((item)=>{          
            if(item.checked){                            
                list_payments.push(item.id);                            
                list_removecommissions.push(item.removecommissions===true ? 1:0);   
                list_removefee.push(item.removefee===true ? 1:0);   
                list_paymentcommissions.push(item.paymentcommissions);   
                list_paymentfee.push(item.paymentfee);   

            }          
      });
      

      
      
        new Promise((resolve, reject) => {                                    
        const url=API_URL_PAYMENTS_RULES+ "/" + props.orgid + "/"+ props.type +"/" + props.id ;                                          
        
            axios.patch(url,{              
                list_payments:list_payments.join(),          
                list_removecommissions:list_removecommissions.join(),          
                list_removefee:list_removefee.join(),          
                list_paymentcommissions:list_paymentcommissions.join(),          
                list_paymentfee:list_paymentfee.join(),          
            },
            {
                headers:{
                'Authorization': 'Bearer '+Auth.getToken(),
                    'Content-Type': 'application/json'
                }
            }
        ).then(payload=>{
            
            if(payload.status===200){
            setAlertMsg("Aggiornamento avvenuto con successo");
            setAlertSeverity("success");                                                                                        
            }else{
            setAlertMsg("Errore durante l'aggiornamento");
            setAlertSeverity("error");                                            
            }
            setOpen(true);
        }).catch((error)=>{                                        
            setAlertMsg(error.response.data.error);
            setAlertSeverity("error");                                            
            setOpen(true);
        }) ;                  
            resolve();            
        })
      
    }


    React.useEffect(() => {
          const url=API_URL_PAYMENTS_RULES+ "/" + props.orgid + "/"+ props.type +"/" + props.id ;
          axios.get(url,
          {
              headers:{
              'Authorization': 'Bearer '+Auth.getToken(),
              'Content-Type': 'application/json'
              }
          }
      ).then(payload=>{             

          payload.data.payments.forEach((item)=>{

              let active_list=payload.data.paymentsActive.filter(active => active.id_payment_systems===item.id)
              if(active_list.length>0){
                    item.checked=true;
                    item.removecommissions=active_list[0].removecommissions===1;
                    item.removefee=active_list[0].removefee===1
                    item.paymentcommissions=active_list[0].paymentcommissions;
                    item.paymentfee=active_list[0].paymentfee;
                                        
              }else{
                    item.checked=false;
                    item.removecommissions=false;
                    item.removefee=false;
                    item.paymentcommissions=0;
                    item.paymentfee=0;
              } 
          })

          setPayments(payload.data.payments);
          
          
      }) ;        
              
    },[props]);      
      
    //
    return (      
    <Card style={{width:"100%"}}>
        <CardContent>           
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Impossibile salvare</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Attenzione ci sono degli sconti che si sovrappongono come date.
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                
                <Button onClick={handleCloseDialog} color="primary" autoFocus>
                    OK
                </Button>
                </DialogActions>
            </Dialog>
            {payments.length===0 &&            
              <Typography>Nessun sistema di pagamento disponibile per questa organizzazione</Typography>
            }
            {payments.length>0 &&
            
            <TableContainer>
                <Table >        
                    
                
    
                    <TableHead>
                        <TableRow>
                            <TableCell>Attivo</TableCell>            
                            <TableCell>Descrizione</TableCell>            
                            <TableCell>Escludi diritti</TableCell>            
                            <TableCell>Escludi commissioni</TableCell>            
                            <TableCell>Diritti % incasso</TableCell>                                                                                                
                            <TableCell>Diritti incasso</TableCell>                                                                                                
                            </TableRow>
                        </TableHead>
                <TableBody>
                    {payments.map((item,key)=>(
                        <TableRow key={"ROW_"+item.id}>          

                            <TableCell>                  
                            <Checkbox onChange={handleChange(1,item.id)} checked={item.checked}  />
                            </TableCell>
                            

                            <TableCell>
                            {item.description}                                                            
                            </TableCell>                                    
                            
                            <TableCell>                                
                            <Checkbox disabled={item.checked===false} onChange={handleChange(2,item.id)} checked={item.removefee}  />
                            </TableCell>

                            <TableCell>                  
                            <Checkbox disabled={item.checked===false} onChange={handleChange(3,item.id)} checked={item.removecommissions}  />
                            </TableCell>

                            <TableCell>                  
                            <TextField
                                disabled={item.checked===false}
                                variant="outlined"
                                margin="normal"                  
                                fullWidth
                                id="paymentcommissions"
                                label="Diritti % incasso"
                                name="paymentcommissions"      
                                autoFocus      
                                value={item.paymentcommissions}
                                onChange={handleChangeValue(1,item.id)}            
                                />                 
                                
                            </TableCell>                                    
                            <TableCell>                  
                            <TextField
                                disabled={item.checked===false}
                                variant="outlined"
                                margin="normal"                  
                                fullWidth
                                id="paymentfee"
                                label="Diritti incasso"
                                name="paymentfee"      
                                autoFocus      
                                value={item.paymentfee}
                                onChange={handleChangeValue(2,item.id)}            
                                />                      
                            </TableCell>                                    
                        </TableRow>
                    ))}                                                
                    </TableBody>
                </Table>
            </TableContainer>
            }
            


            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={alertseverity}>
                    {alertmsg}
                </Alert>
            </Snackbar>      
        </CardContent>
        {payments.length>0 &&
        <CardActions>          
          <Button onClick={handleSave} startIcon={<SaveIcon/>} size="small">Salva</Button>
        </CardActions>
        }
      </Card> 
      
    );
};

