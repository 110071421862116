import React from 'react';
import {API_URL_ALLPRODUCTS} from '../config/config';
import axios   from 'axios';
import Auth from '../auth/auth';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import GuaranteeOrgDetailpanel from './guaranteeOrgDetailpanel';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }
  
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      width:"200"
      
    },
    tabs: {
      borderRight: `1px solid ${theme.palette.divider}`,
      width:"30%"
    },
  }));

export default function EditGuarantiesProperties(props) {
    const classes = useStyles();
    const [productsList, setProducts] = React.useState([]);
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    React.useEffect(() => {           
        
        
            const url=API_URL_ALLPRODUCTS + "/"+props.good.id;
            axios.get(url,
            {
                headers:{
                'Authorization': 'Bearer '+Auth.getToken(),
                'Content-Type': 'application/json'
                }
            }
            ).then(payload=>{                       
                setProducts(payload.data.products);   
            }) ;        
                
      },[props.good.id]);      

    return(
        <div className={classes.root}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Prodotti"
          className={classes.tabs}
        >
            {productsList.map((product,index) => (
                <Tab key={index} label={product.description} {...a11yProps(index)} disabled={!product.enabled} />            
            ))
            }                    
        </Tabs>
            {productsList.map((product,index) => (
              <>
              {product.enabled===1 &&
                <TabPanel key={index} value={value} index={index} >
                    <GuaranteeOrgDetailpanel user={props.user} type={props.type} datagroups={props.datagroups} product={product}/>
                </TabPanel>}
                </>
              ))
            }
        
          
        
      </div>
    
    );
}