import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { green, red } from '@material-ui/core/colors';
import { API_URL_AGENCIES, API_URL_ORGANIZATIONS, API_URL_GROUPS } from '../config/config';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Auth from '../auth/auth';
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Grid } from '@material-ui/core';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}



const useStyles = makeStyles((theme) => ({
  alignFab: {
    marginLeft: 'auto',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  img: {
    display: 'block',
    maxWidth: '100%',
    maxHeight: '40px',
    minWidth: '50px',

  },

  img_service: {
    display: 'block',
    maxWidth: '40px',
    maxHeight: '20px',
  },
  red: {
    color: red[500],
  },
  green: {
    color: green[500],
  },
  hiddenDiv: {
    display: 'none'
  },
  table: {
    minWidth: "500px"
  },
  tableData: {
    width: "100%"
  }
}));

export default function EditCompanyProfileData(props) {
  const classes = useStyles();
  const [data, setData] = React.useState(props.data);
  const [open, setOpen] = React.useState(false);
  const [alertseverity, setAlertSeverity] = React.useState("success");
  const [alertmsg, setAlertMsg] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleChangeRegistrationDate = event => {
    setData({
      ...data,
      registrationdate: moment(event).format("YYYY-MM-DD")
    });
  };

  const handleChangeChecked = (event) => {

    setData({
      ...data,
      [event.target.name]: event.target.checked ? 1 : 0
    });
  };

  const handleChangeValue = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value
    });
  };

  const handleSave = () => {
    new Promise((resolve, reject) => {
      const index = props.data.id;

      let url;
      if (props.type === "org") {
        url = API_URL_ORGANIZATIONS + "/" + index;
      } else if (props.type === "grp") {
        url = API_URL_GROUPS + "/" + index;
      } else {
        url = API_URL_AGENCIES + "/" + index;
      }

      axios.patch(url, {
        ...data,
        businessname: data.businessname,
        rui: data.rui,
        registrationdate: data.registrationdate,
        vatnumber: data.vatnumber,
        fiscalcode: data.fiscalcode,
        toponym: data.toponym,
        address: data.address,
        civicnumber: data.civicnumber,
        cap: data.cap,
        city: data.city,
        province: data.province,
        useeffectivedate: props.type === "org" ? data.useeffectivedate : 0,
        expirationdays: props.type === "org" ? data.expirationdays : 7,
        printfooter: data.printfooter,
        linkuserguide: props.type === "org" ? data.linkuserguide : null,
        linksetupguide: props.type === "org" ? data.linksetupguide : null,
        emission_block_message: props.type === "org" ? data.emission_block_message : null
      },
        {
          headers: {
            'Authorization': 'Bearer ' + Auth.getToken(),
            'Content-Type': 'application/json'
          }
        }
      ).then(payload => {
        if (payload.status === 200) {
          setAlertMsg("Aggiornamento avvenuto con successo");
          setAlertSeverity("success");
        } else {
          setAlertMsg("Errore durante l'aggiornamento");
          setAlertSeverity("error");
        }
        setOpen(true);
      }).catch((error) => {
        setAlertMsg(error.response.data.error);
        setAlertSeverity("error");
        setOpen(true);
      });

      resolve();

    })
  }

  //
  return (
    <Card style={{ width: "100%" }}>
      <CardContent style={{ width: "100%" }}>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField className={classes.tableData} fullwidth label="Ragione Sociale" variant="outlined" onChange={handleChangeValue} name={"businessname"} value={data.businessname} />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <TextField className={classes.tableData} fullwidth label="RUI" variant="outlined" onChange={handleChangeValue} name={"rui"} value={data.rui} />
          </Grid>
          <Grid item xs={3}>
            <KeyboardDatePicker
              style={{ marginTop: "0px" }}
              className={classes.tableData}
              label="Data registrazione RUI"
              value={data.registrationdate}
              onChange={handleChangeRegistrationDate}
              format='dd/MM/yyyy'
              clearLabel="Conferma"
              cancelLabel="Annulla"
              name="registrationdate"
              fullWidth
              inputVariant="outlined"
              margin="normal"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField className={classes.tableData} fullwidth label="P.IVA" variant="outlined" onChange={handleChangeValue} name={"vatnumber"} value={data.vatnumber} />
          </Grid>
          <Grid item xs={3}>
            <TextField className={classes.tableData} fullwidth label="Codice Fiscale" variant="outlined" onChange={handleChangeValue} name={"fiscalcode"} value={data.fiscalcode} />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <TextField className={classes.tableData} fullwidth label="Via, piazza,ecc" variant="outlined" onChange={handleChangeValue} name={"toponym"} value={data.toponym} />
          </Grid>
          <Grid item xs={6}>
            <TextField className={classes.tableData} fullwidth label="Indirizzo" variant="outlined" onChange={handleChangeValue} name={"address"} value={data.address} />
          </Grid>
          <Grid item xs={3}>
            <TextField className={classes.tableData} fullwidth label="Numero civico" variant="outlined" onChange={handleChangeValue} name={"civicnumber"} value={data.civicnumber} />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <TextField className={classes.tableData} fullwidth label="CAP" variant="outlined" onChange={handleChangeValue} name={"cap"} value={data.cap} />
          </Grid>
          <Grid item xs={6}>
            <TextField className={classes.tableData} fullwidth label="Comune" variant="outlined" onChange={handleChangeValue} name={"city"} value={data.city} />
          </Grid>
          <Grid item xs={3}>
            <TextField className={classes.tableData} fullwidth label="Provincia (Sigla)" variant="outlined" onChange={handleChangeValue} name={"province"} value={data.province} />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField className={classes.tableData} fullwidth label="Piè di pagina stampa preventivo" variant="outlined" onChange={handleChangeValue} name={"printfooter"} value={data.printfooter} />
          </Grid>
        </Grid>
        {props.type === "org" &&
          <>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField className={classes.tableData} fullwidth label="Link Guida all'uso" variant="outlined" onChange={handleChangeValue} name={"linkuserguide"} value={data.linkuserguide} />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField className={classes.tableData} fullwidth label="Link Manuale di configurazione" variant="outlined" onChange={handleChangeValue} name={"linksetupguide"} value={data.linksetupguide} />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField className={classes.tableData} fullwidth label="Messaggio di blocco emissione all'utente" variant="outlined" onChange={handleChangeValue} name={"emission_block_message"} value={data.emission_block_message} />
              </Grid>
            </Grid>
            <Grid container spacing={3}>

              <Grid item xs={3}>
                <TextField className={classes.tableData} fullwidth label="Giorni scadenza preventivo" variant="outlined" onChange={handleChangeValue} name={"expirationdays"} value={data.expirationdays} />
              </Grid>

              <Grid item xs={9}>
                <FormControlLabel
                  control={<Checkbox checked={data.useeffectivedate === 1} onChange={handleChangeChecked} name="useeffectivedate" />}
                  label="Usa data effetto per scadenza preventivo"
                />
              </Grid>

            </Grid>

          </>
        }
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={alertseverity} closeText="Chiudi">
            {alertmsg}
          </Alert>
        </Snackbar>
      </CardContent>
      <CardActions>
        <Button onClick={handleSave} startIcon={<SaveIcon />} size="small">Salva</Button>
      </CardActions>
    </Card>

  );
};

