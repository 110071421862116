import React from 'react';
import Auth from '../auth/auth';
import Header from './header';
import QuotationSingle from './quotationsingle';

export default function QuotationSingleRedirect(props) {
    const userData = Auth.getUser();

    return (
        <>
            {userData.id_template === null &&
                <>
                    <Header />
                    <QuotationSingle />
                </>
            }
            {userData.id_template !== null && userData.id_template === 1 && userData.id_collaboratore_sso !== null && userData.id_user_sso !== null &&
                <QuotationSingle />
            }
        </>
    );
}