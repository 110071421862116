import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import SaveIcon from '@material-ui/icons/Save';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { green, red } from '@material-ui/core/colors';
import { API_URL_ALLGUARANTEES, inputTypeLists } from '../config/config';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Auth from '../auth/auth';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Select } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  alignFab: {
    marginLeft: 'auto',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  img: {
    display: 'block',
    maxWidth: '100%',
    maxHeight: '40px',
    minWidth: '50px',

  },

  img_service: {
    display: 'block',
    maxWidth: '40px',
    maxHeight: '20px',
  },
  red: {
    color: red[500],
  },
  green: {
    color: green[500],
  },
  hiddenDiv: {
    display: 'none'
  }
}));

export default function GuaranteeOrgDetailpanel(props) {
  const classes = useStyles();
  const [savestate, setsavestate] = React.useState(true);
  const [savenum, setsavenum] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [alertseverity, setAlertSeverity] = React.useState("success");
  const [alertmsg, setAlertMsg] = React.useState(false);
  const [guarantees, setGuarantees] = React.useState([]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleChangeValue = (id) => (event) => {
    let newguarantees = guarantees;

    //event.target.value
    newguarantees.map((single, index) => {
      if (id === single.id) {
        newguarantees[index].list_value = event.target.value;
      }
      return false;
    }
    );
    setGuarantees(newguarantees);
    setsavestate(false);
    setsavenum(savenum + 1);
  }

  const handleChangeType = (id) => (event) => {
    let newguarantees = guarantees;

    //event.target.value
    newguarantees.map((single, index) => {
      if (id === single.id) {
        newguarantees[index].input_type = event.target.value;
      }
      return false;
    }
    );
    setGuarantees(newguarantees);
    setsavestate(false);
    setsavenum(savenum + 1);
  }

  const handleChange = (id) => (event) => {
    let newguarantees = guarantees;

    newguarantees.map((single, index) => {
      if (id === single.id) {
        if (event.target.checked) {
          newguarantees[index].enabled = 1;
        } else {
          newguarantees[index].enabled = 0;
        }
      }
      return false;
    }
    );
    setGuarantees(newguarantees);
    setsavestate(false);
    setsavenum(savenum + 1);
  }

  const handleSave = () => {
    new Promise((resolve, reject) => {
      const myObjStr = JSON.stringify(guarantees);
      const url = API_URL_ALLGUARANTEES + "/" + props.product.paramurl + "/" + props.datagroups.id;

      axios.patch(url, {
        list: myObjStr,
      },
        {
          headers: {
            'Authorization': 'Bearer ' + Auth.getToken(),
            'Content-Type': 'application/json'
          }
        }
      ).then(payload => {
        if (payload.status === 200) {
          setAlertMsg("Salvataggio dati avvenuto con successo");
          setAlertSeverity("success");
        } else {
          setAlertMsg("Errore durante il salvataggio dei dati");
          setAlertSeverity("error");
        }
        setOpen(true);



      }).catch((error) => {

        setAlertMsg(error.response.data.error);
        setAlertSeverity("error");
        setOpen(true);
      });
      resolve();
    })

  };

  React.useEffect(() => {
    const url = API_URL_ALLGUARANTEES + "/" + props.product.paramurl + "/" + props.datagroups.id;
    axios.get(url,
      {
        headers: {
          'Authorization': 'Bearer ' + Auth.getToken(),
          'Content-Type': 'application/json'
        }
      }
    ).then(payload => {
      setGuarantees(payload.data.guarantees);
    });


  }, [props.product.paramurl, props.datagroups.id]);

  return (
    <>
      <Card className={classes.root} >
        <CardContent >
          {guarantees.map((single, index) => (
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id="additional-actions1-header"
              >
                <FormControlLabel
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}
                  control={<Checkbox name="guarantees" onChange={handleChange(single.id)} checked={single.enabled === 1} />}
                  label={<Box>
                    <Typography>
                      {single.description}
                    </Typography>
                  </Box>} />
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer className={classes.table}>
                  <Table aria-label="Parametri garanzie">
                    <TableHead>
                      <TableRow>
                        <TableCell>Nome parametro</TableCell>
                        <TableCell>Valore</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          Tipo di dato da inserire
                        </TableCell>
                        <TableCell>
                          <Select
                            value={single.input_type}
                            onChange={handleChangeType(single.id)}
                            label="Tipo di valore da inserire"
                          >
                            {inputTypeLists.map(option => (
                              <MenuItem
                                key={option.value}
                                value={option.value}
                              >
                                {option.description}
                              </MenuItem>
                            ))}

                          </Select>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Valori
                        </TableCell>
                        <TableCell>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="list_value"
                            autoFocus
                            value={single.list_value}
                            onChange={handleChangeValue(single.id)}

                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>))}
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={alertseverity}>
              {alertmsg}
            </Alert>
          </Snackbar>
        </CardContent>
        <CardActions>
          <div className={classes.hiddenDiv}>({savenum})</div>
          <Button disabled={savestate} onClick={handleSave} startIcon={<SaveIcon />} size="small">Salva</Button>
        </CardActions>
      </Card>
    </>
  );
};