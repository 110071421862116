import axios   from 'axios';
import {API_URL_SERVICE_AUTH} from '../config/config';


function AuthService() {
    const isTokenExpired = () =>{
        
        const expires = +localStorage.getItem('auth-expires');
        const res =  (new Date()) > expires;
        if(res){
            localStorage.removeItem('auth-expires');
            localStorage.removeItem('authservice')
        }
        return res;
    };


    const handleError = (resp)=>{
        let message = '';        
        switch (+resp.status) {
            case 401:
                message = resp.data.error;
                break;
            case 500:
                message = resp.data.message;
                break;
            default:
                message = 'Error contacting server';
        }
        return message;
    };
    


    const signin = async (username, password,url) => {         
        const json = JSON.stringify({ 
                username: username,
                password: password            
            }        
            );    
        try {        

            
            
        const result = await axios.post( url+API_URL_SERVICE_AUTH ,json,{
            headers:{            
                'Content-Type': 'application/json',
                'Authorization':''
                
            }});          
        
        return manageResult(result);

        } catch( e ){             
            
            return Promise.reject(handleError(e.response)) ;
        }
    };


    
 

 
    const getToken = () =>{
        if(isTokenExpired()){
            return null;
        }
       const authservice = localStorage.getItem('authservice');
       if(authservice){                      
           return authservice;
       }
       return null;
    }

     


    function manageResult(result) {
        const data = result['data'];
        
        if (!data || !data['token']) {
            return Promise.reject('Invalid server response');
        }
                                
        localStorage.setItem('authservice', data['token']);        
        const expireDate = (new Date()).getTime() + 20 * 60  * 1000;
        localStorage.setItem('auth-expires', expireDate);
        
        return data['token'];
        
        
        
        /*
        

        return result.data;*/
    }

     
    const refresh = () => {};

    return {
      
      signin ,      
      refresh,
      isTokenExpired,      
      getToken,      
    }

}
const  authMethodsService = AuthService();
export default authMethodsService;