import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import BackGround from './background';
import Container from '@material-ui/core/Container';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';
import MaterialTable from 'material-table';
import { id_cliente, ReturnInfoClient } from '../containers/logincontext';
import { API_URL_ORGANIZATIONS } from '../config/config';
import { green, red } from '@material-ui/core/colors';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import GroupsTable from './groupstable';
import LockIcon from '@material-ui/icons/Lock';
import Typography from '@material-ui/core/Typography';
import { NAME_APP } from '../config/config';
import Auth from '../auth/auth';

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);



const useStyles = makeStyles((theme) => ({
    alignFab: {
        marginLeft: 'auto',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    img: {
        display: 'block',
        maxWidth: '100%',
        maxHeight: '40px',
    },

    img_service: {
        display: 'block',
        maxWidth: '40px',
        maxHeight: '20px',
    },
    red: {
        color: red[500],
    },
    green: {
        color: green[500],
    },
}));

export default function Groups() {
    const [loading] = React.useState(false);
    const classes = useStyles();



    return (
        <main className={classes.content}>
            <BackGround open={loading} />
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" href="/" >
                        {NAME_APP}
                    </Link>
                    <Link
                        color="textPrimary"
                        href="/groups"
                        aria-current="page"
                    >
                        Gruppi
                    </Link>
                </Breadcrumbs>

                <Card>
                    <CardContent>
                        <MaterialTable
                            title="Gruppi"
                            columns={[

                                {
                                    title: 'Organizzazione',
                                    field: 'id_organizations',
                                    render: rowData => <Box><img className={classes.img} alt={rowData.description} src={rowData.image} /></Box>
                                },
                                { title: 'Descrizione', field: 'description' },
                                {
                                    title: '',
                                    field: 'enabled',
                                    lookup: { 1: 'Abilitato', 0: 'Disabilitato' },
                                    render: rowData => <div>{rowData.enabled === 0 &&
                                        <HtmlTooltip title={<React.Fragment>
                                            <Typography color="inherit">Organizzazione disabilitata</Typography>
                                            <em>L'organizzazione è disabilitata</em><br />
                                            Per abilitarla andare nel menù <u>Organizzazioni</u>
                                        </React.Fragment>}>
                                            <LockIcon className={classes.red} />
                                        </HtmlTooltip>
                                    }
                                    </div>
                                }



                            ]}

                            detailPanel={[
                                {
                                    tooltip: 'Impostazioni gruppi',
                                    render: rowData => {
                                        return (<GroupsTable datagroups={rowData} />)
                                    },
                                },
                            ]
                            }


                            localization={{
                                pagination: {
                                    labelDisplayedRows: '{from}-{to} di {count}',
                                    labelRowsSelect: 'per pagina',
                                    labelRowsPerPage: 'per pagina',
                                    firstAriaLabel: 'prima pagina',
                                    firstTooltip: 'prima pagina',
                                    previousAriaLabel: 'pagina precedente',
                                    previousTooltip: 'pagina precedente',
                                    nextAriaLabel: 'pagina successiva',
                                    nextTooltip: 'pagina successiva',
                                    lastAriaLabel: 'ultima pagina',
                                    lastTooltip: 'ultima pagina',
                                },
                                toolbar: {
                                    nRowsSelected: '{0} elementi selezionati',
                                    searchTooltip: 'Ricerca',
                                    searchPlaceholder: 'Ricerca'
                                },
                                header: {
                                    actions: ''
                                },
                                body: {
                                    emptyDataSourceMessage: 'Nessun record da visualizzare',
                                    filterRow: {
                                        filterTooltip: 'Filtri'
                                    },
                                    editRow: {
                                        saveTooltip: "Conferma",
                                        cancelTooltip: "Annulla",
                                        deleteText: "Sei sicuro di voler cancellare questo gruppo?"
                                    },
                                    addTooltip: "Aggiungi",
                                    deleteTooltip: "Cancella",
                                    editTooltip: "Modifica",
                                }
                            }}
                            options={{
                                pageSize: 10,
                                pageSizeOptions: [5, 10],
                                paginationType: "stepped",
                                search: true,
                                headerStyle: {
                                    backgroundColor: ReturnInfoClient(id_cliente, "primary_color"),
                                    color: '#ffffff',
                                    '&:hover': {
                                        color: '#ffffff',
                                    },
                                    '&:active': {
                                        color: '#ffffff',
                                    }
                                }

                            }}
                            data={query =>
                                new Promise((resolve, reject) => {
                                    let url = API_URL_ORGANIZATIONS;
                                    url += '?per_page=' + query.pageSize
                                    url += '&page=' + (query.page + 1)

                                    if (query.search.length > 3) {
                                        url += '&search=' + query.search
                                    }
                                    if (query.orderBy !== undefined) {
                                        url += '&orderby=' + query.orderBy.field
                                        url += '&orderdirection=' + query.orderDirection
                                    }

                                    const requestOptions = {
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'Authorization': 'Bearer ' + Auth.getToken(),
                                        },
                                    };


                                    fetch(url, requestOptions)
                                        .then(
                                            response => response.json()
                                        )
                                        .then(
                                            result => {
                                                resolve({
                                                    data: result.data,
                                                    page: result.current_page - 1,
                                                    totalCount: result.total,
                                                })
                                            })
                                })
                            }
                        />
                    </CardContent>
                </Card>
            </Container>
        </main>
    );
};


