import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import BackGround from './background';
import axios from 'axios';
import Auth from '../auth/auth';
import AuthService from '../auth/authservice';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Stepper from '@material-ui/core/Stepper';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MuiAlert from '@material-ui/lab/Alert';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Link from '@material-ui/core/Link';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { NAME_APP } from '../config/config';
import Container from '@material-ui/core/Container';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import { KeyboardDatePicker } from "@material-ui/pickers";
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { PosDataContext } from '../containers/logincontext';
import {
    API_URL_INFOIMAGES_ALL,
    API_URL_GET_INFOCITYBYCITY,
    API_URL_ALLGUARANTEES,
    API_URL_FACTORIES_LIST,
    API_URL_LIST_AGENCIES_USER,
    API_URL_LIST_SERVICES_AGENCY_USER,
    API_URL_QUOTATION_LIST,
    API_URL_MULTISESSION,
    API_URL_QUOTATION_RE,
    APP_TOKEN_QUOTIAMO,
    API_URL_GET_INFOBIRTH
} from '../config/config';
import {
    TipologiaList,
    tipologiaCostruzioneListGf,
    franchigiaFrontaleGf,
    tettoGf,
    pannelliGf,
    cappottoGf,
    domandeQuestionarioGf,
    impElettricoGf,
    impIdraulicoGf,
    opzioniGfDanTerrScoperto
} from '../config/config';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { number_format, parseFloat2Decimals } from './utils';
import SubjectDetails from './subjectDetails';
import validator from 'validator';
import CheckSvgButton from './checkboxsvg';
import Box from '@material-ui/core/Box';
import NumberFormat from 'react-number-format';
import QuotationSingleDetail from './quotationsingledetail';
import { CardMedia } from '@material-ui/core';
import QuotationDetails from './quotationdetails';
import Carousel from 'react-material-ui-carousel';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const drawerWidth = 240;

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};

function LinearProgressWithLabel(props) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
};

const useStyles = makeStyles((theme) => ({
    BoxContainer: {
        padding: "10px"
    },
    fulldiv: {
        width: "-webkit-fill-available"
    },
    FirstDataRow: {
        marginTop: "20px",
        marginBottom: "0px",
    },
    TitleCategory: {
        marginTop: "20px",
        fontSize: "16px",
        fontWeight: "600"
    },
    DetailWarranty: {
        marginTop: "5px",
        fontSize: "12px",
        fontWeight: "400"
    },
    SubTitle: {
        marginTop: "20px",
        fontSize: "16px",
        fontWeight: "600"
    },
    button: {
        height: 30
    },
    Carousel: {
        width: "100%"
    },
    Media: {
        height: 800
    },
    Carousel2: {
        width: "50%"
    },
    Media2: {
        height: 400
    },
    PaddingTop: {
        paddingTop: 30,
    },
    cellroot: {
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    prezzo: {
        align: 'right'
    },
    titleTable: {
        flex: '1 1 100%',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    linear_class: {
        width: '20%',
        '& > * + *': {
            marginTop: theme.spacing(2),
            zIndex: theme.zIndex.drawer + 2,
        },
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    root: {
        width: '90%'
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        //maxWidth:'100%',
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        alignItems: 'center',
        flexDirection: 'column',
    },
    preventivo: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 800,
    },
    image: {
        width: 180,
        height: 60,
    },
    imgLogo: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '50px',
        marginBottom: '40px'
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    fixedHeight: {
        height: 240,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    pos: {
        marginBottom: 12,
    },
    containerButton: {
        alignItems: 'right',
        textAlign: 'right',
        marginBottom: '10px',
        marginTop: '10px'
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
        alignItems: 'center'
    },
    formControl: {
        width: '100%'
    },
    progress: {
        display: "none"
    },
    iconRoot: {
        color: red
    },
    linearProgressDiv: {
        width: '90%'
    }
}));

function AssignDefParameter(defOrg, defGrp, defAg) {
    return ({
        dataEffetto: moment().format("YYYY-MM-DD"), // YYYY-MM-DD
        questionarioGf: false,
        tipologiaCostruzione: (defAg !== null && defAg.tipologiaCostruzioneGf !== undefined ? defAg.tipologiaCostruzioneGf : (defGrp !== null && defGrp.tipologiaCostruzioneGf !== undefined ? defGrp.tipologiaCostruzioneGf : (defOrg !== null && defOrg.tipologiaCostruzioneGf !== undefined ? defOrg.tipologiaCostruzioneGf : 0))),
        percentualeAbUff: (defAg !== null && defAg.percentualeAbUffGf !== undefined ? defAg.percentualeAbUffGf : (defGrp !== null && defGrp.percentualeAbUffGf !== undefined ? defGrp.percentualeAbUffGf : (defOrg !== null && defOrg.percentualeAbUffGf !== undefined ? defOrg.percentualeAbUffGf : ""))),
        tipologiaImm: (defAg !== null && defAg.tipologiaImmGf !== undefined ? defAg.tipologiaImmGf : (defGrp !== null && defGrp.tipologiaImmGf !== undefined ? defGrp.tipologiaImmGf : (defOrg !== null && defOrg.tipologiaImmGf !== undefined ? defOrg.tipologiaImmGf : 0))), // A = Appartamento, V1 = Villa a schiera, V2 = Villa singola
        annoCostruzioneRistrutturazione: (defAg !== null && defAg.annoCostruzioneRistrutturazioneGf !== undefined ? defAg.annoCostruzioneRistrutturazioneGf : (defGrp !== null && defGrp.annoCostruzioneRistrutturazioneGf !== undefined ? defGrp.annoCostruzioneRistrutturazioneGf : (defOrg !== null && defOrg.annoCostruzioneRistrutturazioneGf !== undefined ? defOrg.annoCostruzioneRistrutturazioneGf : ""))),
        valoreFabbricato: (defAg !== null && defAg.valoreFabbricatoGf !== undefined ? defAg.valoreFabbricatoGf : (defGrp !== null && defGrp.valoreFabbricatoGf !== undefined ? defGrp.valoreFabbricatoGf : (defOrg !== null && defOrg.valoreFabbricatoGf !== undefined ? defOrg.valoreFabbricatoGf : ""))),
        inCostruzione: (defAg !== null && defAg.inCostruzioneGf !== undefined ? defAg.inCostruzioneGf : (defGrp !== null && defGrp.inCostruzioneGf !== undefined ? defGrp.inCostruzioneGf : (defOrg !== null && defOrg.inCostruzioneGf !== undefined ? defOrg.inCostruzioneGf : 0))),
        nPiani: (defAg !== null && defAg.nPianiGf !== undefined ? defAg.nPianiGf : (defGrp !== null && defGrp.nPianiGf !== undefined ? defGrp.nPianiGf : (defOrg !== null && defOrg.nPianiGf !== undefined ? defOrg.nPianiGf : ""))),
        cappotto: (defAg !== null && defAg.cappottoGf !== undefined ? defAg.cappottoGf : (defGrp !== null && defGrp.cappottoGf !== undefined ? defGrp.cappottoGf : (defOrg !== null && defOrg.cappottoGf !== undefined ? defOrg.cappottoGf : 0))),
        impElettrico: (defAg !== null && defAg.impElettricoGf !== undefined ? defAg.impElettricoGf : (defGrp !== null && defGrp.impElettricoGf !== undefined ? defGrp.impElettricoGf : (defOrg !== null && defOrg.impElettricoGf !== undefined ? defOrg.impElettricoGf : 0))),
        impIdraulico: (defAg !== null && defAg.impIdraulicoGf !== undefined ? defAg.impIdraulicoGf : (defGrp !== null && defGrp.impIdraulicoGf !== undefined ? defGrp.impIdraulicoGf : (defOrg !== null && defOrg.impIdraulicoGf !== undefined ? defOrg.impIdraulicoGf : 0))),
        pannelli: (defAg !== null && defAg.pannelliGf !== undefined ? defAg.pannelliGf : (defGrp !== null && defGrp.pannelliGf !== undefined ? defGrp.pannelliGf : (defOrg !== null && defOrg.pannelliGf !== undefined ? defOrg.pannelliGf : 0))),
        franchigiaFrontale: (defAg !== null && defAg.franchigiaFrontaleGf !== undefined ? defAg.franchigiaFrontaleGf : (defGrp !== null && defGrp.franchigiaFrontaleGf !== undefined ? defGrp.franchigiaFrontaleGf : (defOrg !== null && defOrg.franchigiaFrontaleGf !== undefined ? defOrg.franchigiaFrontaleGf : 0))),
        nFabbricati: (defAg !== null && defAg.nFabbricatiGf !== undefined ? defAg.nFabbricatiGf : (defGrp !== null && defGrp.nFabbricatiGf !== undefined ? defGrp.nFabbricatiGf : (defOrg !== null && defOrg.nFabbricatiGf !== undefined ? defOrg.nFabbricatiGf : ""))),
        nUnitaImmobiliari: (defAg !== null && defAg.nUnitaImmobiliariGf !== undefined ? defAg.nUnitaImmobiliariGf : (defGrp !== null && defGrp.nUnitaImmobiliariGf !== undefined ? defGrp.nUnitaImmobiliariGf : (defOrg !== null && defOrg.nUnitaImmobiliariGf !== undefined ? defOrg.nUnitaImmobiliariGf : ""))),
        tetto: (defAg !== null && defAg.tettoGf !== undefined ? defAg.tettoGf : (defGrp !== null && defGrp.tettoGf !== undefined ? defGrp.tettoGf : (defOrg !== null && defOrg.tettoGf !== undefined ? defOrg.tettoGf : "0"))),
        provinciaAbbr: "",
        citta: "",
        cap: "",
        istat: "",
        numeroCivico: (defAg !== null && defAg.numeroCivicoGf !== undefined ? defAg.numeroCivicoGf : (defGrp !== null && defGrp.numeroCivicoGf !== undefined ? defGrp.numeroCivicoGf : (defOrg !== null && defOrg.numeroCivicoGf !== undefined ? defOrg.numeroCivicoGf : ""))),
        toponimo: (defAg !== null && defAg.toponimoGf !== undefined ? defAg.toponimoGf : (defGrp !== null && defGrp.toponimoGf !== undefined ? defGrp.toponimoGf : (defOrg !== null && defOrg.toponimoGf !== undefined ? defOrg.toponimoGf : ""))),
        indirizzo: (defAg !== null && defAg.indirizzoGf !== undefined ? defAg.indirizzoGf : (defGrp !== null && defGrp.indirizzoGf !== undefined ? defGrp.indirizzoGf : (defOrg !== null && defOrg.indirizzoGf !== undefined ? defOrg.indirizzoGf : ""))),
        ownerInfo: {
            subjectType: (defAg !== null && defAg.subjectTypeGf !== undefined ? defAg.subjectTypeGf : (defGrp !== null && defGrp.subjectTypeGf !== undefined ? defGrp.subjectTypeGf : (defOrg !== null && defOrg.subjectTypeGf !== undefined ? defOrg.subjectTypeGf : "1"))),
            name: (defAg !== null && defAg.nameGf !== undefined ? defAg.nameGf : (defGrp !== null && defGrp.nameGf !== undefined ? defGrp.nameGf : (defOrg !== null && defOrg.nameGf !== undefined ? defOrg.nameGf : ""))),
            surname: (defAg !== null && defAg.surnameGf !== undefined ? defAg.surnameGf : (defGrp !== null && defGrp.surnameGf !== undefined ? defGrp.surnameGf : (defOrg !== null && defOrg.surnameGf !== undefined ? defOrg.surnameGf : ""))),
            birthDate: "",
            gender: "M",
            fiscalCode: (defAg !== null && defAg.fiscalCodeGf !== undefined ? defAg.fiscalCodeGf : (defGrp !== null && defGrp.fiscalCodeGf !== undefined ? defGrp.fiscalCodeGf : (defOrg !== null && defOrg.fiscalCodeGf !== undefined ? defOrg.fiscalCodeGf : ""))),
            companyName: (defAg !== null && defAg.companyNameGf !== undefined ? defAg.companyNameGf : (defGrp !== null && defGrp.companyNameGf !== undefined ? defGrp.companyNameGf : (defOrg !== null && defOrg.companyNameGf !== undefined ? defOrg.companyNameGf : ""))),
            vatNumber: (defAg !== null && defAg.vatNumberGf !== undefined ? defAg.vatNumberGf : (defGrp !== null && defGrp.vatNumberGf !== undefined ? defGrp.vatNumberGf : (defOrg !== null && defOrg.vatNumberGf !== undefined ? defOrg.vatNumberGf : ""))),
            birthCity: "",
            birthProvince: "",
            birthRegion: "",
            birthNationAbbr: "",
            birthNation: "",
            email: (defAg !== null && defAg.emailGf !== undefined ? defAg.emailGf : (defGrp !== null && defGrp.emailGf !== undefined ? defGrp.emailGf : (defOrg !== null && defOrg.emailGf !== undefined ? defOrg.emailGf : ""))),
            phoneNumber: (defAg !== null && defAg.phoneNumberGf !== undefined ? defAg.phoneNumberGf : (defGrp !== null && defGrp.phoneNumberGf !== undefined ? defGrp.phoneNumberGf : (defOrg !== null && defOrg.phoneNumberGf !== undefined ? defOrg.phoneNumberGf : ""))),
            residenceInfo: {
                city: "",
                cap: "",
                provinceAbbr: "",
                istat: "",
                toponym: (defAg !== null && defAg.toponimoGf !== undefined ? defAg.toponimoGf : (defGrp !== null && defGrp.toponimoGf !== undefined ? defGrp.toponimoGf : (defOrg !== null && defOrg.toponimoGf !== undefined ? defOrg.toponimoGf : ""))),
                address: (defAg !== null && defAg.indirizzoGf !== undefined ? defAg.indirizzoGf : (defGrp !== null && defGrp.indirizzoGf !== undefined ? defGrp.indirizzoGf : (defOrg !== null && defOrg.indirizzoGf !== undefined ? defOrg.indirizzoGf : ""))),
                streetNumber: (defAg !== null && defAg.numeroCivicoGf !== undefined ? defAg.numeroCivicoGf : (defGrp !== null && defGrp.numeroCivicoGf !== undefined ? defGrp.numeroCivicoGf : (defOrg !== null && defOrg.numeroCivicoGf !== undefined ? defOrg.numeroCivicoGf : ""))),
            }
        },
        amministratoreInfo: {
            subjectType: (defAg !== null && defAg.subjectTypeGf !== undefined ? defAg.subjectTypeGf : (defGrp !== null && defGrp.subjectTypeGf !== undefined ? defGrp.subjectTypeGf : (defOrg !== null && defOrg.subjectTypeGf !== undefined ? defOrg.subjectTypeGf : "1"))),
            name: (defAg !== null && defAg.nameGf !== undefined ? defAg.nameGf : (defGrp !== null && defGrp.nameGf !== undefined ? defGrp.nameGf : (defOrg !== null && defOrg.nameGf !== undefined ? defOrg.nameGf : ""))),
            surname: (defAg !== null && defAg.surnameGf !== undefined ? defAg.surnameGf : (defGrp !== null && defGrp.surnameGf !== undefined ? defGrp.surnameGf : (defOrg !== null && defOrg.surnameGf !== undefined ? defOrg.surnameGf : ""))),
            birthDate: "",
            gender: "M",
            fiscalCode: (defAg !== null && defAg.fiscalCodeGf !== undefined ? defAg.fiscalCodeGf : (defGrp !== null && defGrp.fiscalCodeGf !== undefined ? defGrp.fiscalCodeGf : (defOrg !== null && defOrg.fiscalCodeGf !== undefined ? defOrg.fiscalCodeGf : ""))),
            companyName: (defAg !== null && defAg.companyNameGf !== undefined ? defAg.companyNameGf : (defGrp !== null && defGrp.companyNameGf !== undefined ? defGrp.companyNameGf : (defOrg !== null && defOrg.companyNameGf !== undefined ? defOrg.companyNameGf : ""))),
            vatNumber: (defAg !== null && defAg.vatNumberGf !== undefined ? defAg.vatNumberGf : (defGrp !== null && defGrp.vatNumberGf !== undefined ? defGrp.vatNumberGf : (defOrg !== null && defOrg.vatNumberGf !== undefined ? defOrg.vatNumberGf : ""))),
            birthCity: "",
            birthProvince: "",
            birthRegion: "",
            birthNationAbbr: "",
            birthNation: "",
            email: (defAg !== null && defAg.emailGf !== undefined ? defAg.emailGf : (defGrp !== null && defGrp.emailGf !== undefined ? defGrp.emailGf : (defOrg !== null && defOrg.emailGf !== undefined ? defOrg.emailGf : ""))),
            phoneNumber: (defAg !== null && defAg.phoneNumberGf !== undefined ? defAg.phoneNumberGf : (defGrp !== null && defGrp.phoneNumberGf !== undefined ? defGrp.phoneNumberGf : (defOrg !== null && defOrg.phoneNumberGf !== undefined ? defOrg.phoneNumberGf : ""))),
            residenceInfo: {
                city: "",
                cap: "",
                provinceAbbr: "",
                istat: "",
                toponym: (defAg !== null && defAg.toponimoGf !== undefined ? defAg.toponimoGf : (defGrp !== null && defGrp.toponimoGf !== undefined ? defGrp.toponimoGf : (defOrg !== null && defOrg.toponimoGf !== undefined ? defOrg.toponimoGf : ""))),
                address: (defAg !== null && defAg.indirizzoGf !== undefined ? defAg.indirizzoGf : (defGrp !== null && defGrp.indirizzoGf !== undefined ? defGrp.indirizzoGf : (defOrg !== null && defOrg.indirizzoGf !== undefined ? defOrg.indirizzoGf : ""))),
                streetNumber: (defAg !== null && defAg.numeroCivicoGf !== undefined ? defAg.numeroCivicoGf : (defGrp !== null && defGrp.numeroCivicoGf !== undefined ? defGrp.numeroCivicoGf : (defOrg !== null && defOrg.numeroCivicoGf !== undefined ? defOrg.numeroCivicoGf : ""))),
            }
        }
    });
};

function TextFieldCustomMassimale(props) {
    return (
        <TextField label="Massimale" variant="outlined" {...props} />
    );
};

const getListOfCity = async (token, url, search) => {
    try {
        const result = await axios.get(url + API_URL_GET_INFOCITYBYCITY, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            params: {
                city: search
            }
        });
        return result;
    } catch (e) {
        return Promise.reject(e.response);
    }
};

function CheckConvenzioni(QuotationData, company, conventionslist, flagapply) {
    let convention_active = {
        code: "",
        description: "",
        flex: 0
    };

    if (flagapply === 1) {
        let convComp = false;
        conventionslist.forEach(conventions => {
            if (company.facid === conventions.id_factory) {
                let convention_accept = CheckActiveConvention(conventions, QuotationData);
                if (convention_accept) {
                    convention_active = conventions;
                    if (conventions.companyapply === 1) {
                        convComp = true;
                    } else {
                        convComp = false;
                    }
                }
            }
        }
        );
        if (convComp === false) {
            let convention = {
                code: "",
                description: "",
                flex: 0
            };
            return convention;
        }
    } else {
        conventionslist.forEach(conventions => {
            if (company.facid === conventions.id_factory && conventions.companyapply === flagapply) {
                let convention_accept = CheckActiveConvention(conventions, QuotationData);
                if (convention_accept) {
                    convention_active = conventions;
                }
            }
        });
    }

    return convention_active;
};

function CheckActiveConvention(conventions, QuotationData) {
    let convention_accept = true;
    if (conventions.convention_rules !== null) {
        JSON.parse(conventions.convention_rules).forEach(
            (single_rules) => {
                if (single_rules.ProvinceList !== undefined) {
                    if (single_rules.ProvinceList.value !== null && single_rules.ProvinceList.value !== "") {
                        //non trovate le province
                        let elencoProvince = single_rules.ProvinceList.value.split(",");
                        if (elencoProvince.indexOf(QuotationData.provinciaAbbr) === -1) {
                            convention_accept = false;
                        }
                    }
                }
                if (single_rules.BirthNation !== undefined) {
                    if (single_rules.BirthNation.value !== null && single_rules.BirthNation.value !== "") {
                        //Verifico se nato in paese straniero
                        if (!isNaN(single_rules.BirthNation.value)) {
                            var res = QuotationData.contractorInfo.fiscalCode.substring(11, 12).toUpperCase();
                            if (parseInt(single_rules.BirthNation.value) === 1) {
                                if (res === "Z") {
                                    convention_accept = false;
                                }
                            } else if (parseInt(single_rules.BirthNation.value) === 2) {
                                if (res !== "Z") {
                                    convention_accept = false;
                                }
                            }
                        }
                    }
                }
                if (single_rules.PaymentMode !== undefined) {
                    if (single_rules.PaymentMode.value !== null && single_rules.PaymentMode.value !== "") {
                        if (Number(QuotationData.frazionamento) !== Number(single_rules.PaymentMode.value)) {
                            convention_accept = false;
                        }
                    }
                }
            }
        );
    }

    return convention_accept;
};

const checkPreventivo = async (QuotationData, company, token, guarantees, guaranteesSelect, guaranteesValue, conventionslist, authToken, scopertoDanTerr) => {
    if (company.multisession === 1 && company.username === "" && company.password === "" && company.companyCode === "" && company.companyParams === "" && company.nodeCode === "") {
        if (company.paramurl === "hdi") {
            const result = {
                data: {
                    error: "Servizio non disponibile",
                    message: "Limite utenze in uso raggiunto, riprovare tra qualche minuto",
                    path: "/getquotation",
                    status: 503,
                    errorCode: 1003,
                    timestamp: moment().format("YYYY-MM-DD HH:mm:ss")
                }
            };
            return result;
        } else {
            const result = {
                data: {
                    error: "Servizio non disponibile",
                    message: "Utenze non disponibili, riprovare tra qualche minuto",
                    path: "/getquotation",
                    status: 503,
                    errorCode: 1003,
                    timestamp: moment().format("YYYY-MM-DD HH:mm:ss")
                }
            };
            return result;
        }
    }
    /*Verifica se proviene dalla stessa compagnia */

    let listCVT = [];
    let convenzioneAttiva = CheckConvenzioni(QuotationData, company, conventionslist, 1);
    if (convenzioneAttiva !== false && convenzioneAttiva.code !== "") {
        console.log("TROVATA CONVENZIONE DA INVIARE ALLA COMPAGNIA");
        //Al momento non usato
    }

    guarantees.forEach((element, index) => {
        if (guaranteesSelect[index]) {
            let massimale = 0;
            let massimalePerc = 0;
            let franchigia = 0;
            let franchigiaPerc = 0;
            let limite = 0;
            let limitePerc = 0;
            let scopertoPerc = 0;
            let scoperto = 0;

            if (element["input_type"] !== 0) {
                if (isNaN(guaranteesValue[index]) && guaranteesValue[index].indexOf('%') !== -1) {
                    massimalePerc = Number(guaranteesValue[index].replace('%', ''));
                } else {
                    massimale = Number(guaranteesValue[index]);
                }
            } else {
                if (company.guarantees_info[index].massimale !== null) {
                    if (isNaN(company.guarantees_info[index].massimale) && company.guarantees_info[index].massimale.indexOf('%') !== -1) {
                        massimalePerc = Number(company.guarantees_info[index].massimale.replace('%', ''));
                    } else {
                        massimale = Number(company.guarantees_info[index].massimale);
                    }
                }
            }
            if (company.guarantees_info[index].franchigia !== null) {
                if (isNaN(company.guarantees_info[index].franchigia) && company.guarantees_info[index].franchigia.indexOf('%') !== -1) {
                    franchigiaPerc = Number(company.guarantees_info[index].franchigia.replace('%', ''));
                } else {
                    franchigia = Number(company.guarantees_info[index].franchigia);
                }
            }

            if (company.guarantees_info[index].limite_risarcimento_perc !== null) {
                limitePerc = Number(company.guarantees_info[index].limite_risarcimento_perc);
            }
            if (company.guarantees_info[index].limite_risarcimento_val !== null) {
                limite = Number(company.guarantees_info[index].limite_risarcimento_val);
            }

            if (company.guarantees_info[index].scoperto_perc !== null) {
                scopertoPerc = Number(company.guarantees_info[index].scoperto_perc);
            }

            if (company.guarantees_info[index].scoperto_val !== null) {
                scoperto = Number(company.guarantees_info[index].scoperto_val);
            }
            if (company.paramurl === "tua") {
                let codiceGaranzia = element["code"];
                if (codiceGaranzia === "DAN_TERR") {
                    scoperto = scopertoDanTerr;
                }
            }

            let tipoCalcolo = company.guarantees_info[index].tipo_calcolo !== null ? (company.guarantees_info[index].tipo_calcolo !== "default" ? company.guarantees_info[index].tipo_calcolo : company.guarantees_info[index].tipo_calcolo_default) : company.guarantees_info[index].tipo_calcolo_default;
            if (tipoCalcolo === "comp") {
                if (company.guarantees_info[index].service_parameters_code !== null) {
                    company.guarantees_info[index].service_parameters_code.split(",").forEach(elementcode => {
                        //temp.push({[element.id]:element.description});
                        listCVT.push(
                            {
                                codice: elementcode,
                                massimale: massimale,
                                massimalePerc: massimalePerc,
                                franchigia: franchigia,
                                franchigiaPerc: franchigiaPerc,
                                limite: limite,
                                limitePerc: limitePerc,
                                scoperto: scoperto,
                                scopertoPerc: scopertoPerc
                            }
                        );
                    });
                } else {
                    listCVT.push(
                        {
                            codice: element["code"],
                            massimale: massimale,
                            massimalePerc: massimalePerc,
                            franchigia: franchigia,
                            franchigiaPerc: franchigiaPerc,
                            limite: limite,
                            limitePerc: limitePerc,
                            scoperto: scoperto,
                            scopertoPerc: scopertoPerc
                        }
                    );
                }
            }
        }
    });

    const json = JSON.stringify({
        applicationToken: APP_TOKEN_QUOTIAMO,
        username: company.username,
        password: company.password,
        companyCode: company.companyCode,
        companyParams: company.companyParams,
        nodeCode: company.nodeCode,
        commerciale: (company.commerciale === null || company.commerciale === "") ? "A046" : company.commerciale,
        agZona: (company.agZona === null || company.agZona === "") ? "A046" : company.agZona,
        reteVendita: "001",
        ownerInfo: {
            name: QuotationData.ownerInfo.name,
            surname: QuotationData.ownerInfo.surname,
            birthDate: QuotationData.ownerInfo.birthDate,
            gender: QuotationData.ownerInfo.gender,
            companyName: QuotationData.ownerInfo.companyName,
            phoneNumber: company.usedefaultphonenumber === 1 ? company.phonenumber : QuotationData.ownerInfo.phoneNumber,
            email: company.usedefaultemail === 1 ? company.email : QuotationData.ownerInfo.email,
            fiscalCode: QuotationData.ownerInfo.fiscalCode,
            vatNumber: QuotationData.ownerInfo.vatNumber,
            personType: (QuotationData.ownerInfo.subjectType === "1" || QuotationData.ownerInfo.subjectType === "") ? "FIS" : "GIU",
            subjectType: (QuotationData.ownerInfo.subjectType === "") ? "1" : QuotationData.ownerInfo.subjectType,
            residenceInfo: {
                city: QuotationData.ownerInfo.residenceInfo.city,
                cap: QuotationData.ownerInfo.residenceInfo.cap,
                provinceAbbr: QuotationData.ownerInfo.residenceInfo.provinceAbbr,
                istat: QuotationData.ownerInfo.residenceInfo.istat,
                toponym: QuotationData.ownerInfo.residenceInfo.toponym,
                address: QuotationData.ownerInfo.residenceInfo.address,
                streetNumber: QuotationData.ownerInfo.residenceInfo.streetNumber
            },
            locationInfo: {
                city: QuotationData.ownerInfo.residenceInfo.city,
                cap: QuotationData.ownerInfo.residenceInfo.cap,
                provinceAbbr: QuotationData.ownerInfo.residenceInfo.provinceAbbr,
                istat: QuotationData.ownerInfo.residenceInfo.istat,
                toponym: QuotationData.ownerInfo.residenceInfo.toponym,
                address: QuotationData.ownerInfo.residenceInfo.address,
                streetNumber: QuotationData.ownerInfo.residenceInfo.streetNumber
            }
        },
        quoteInfo: {
            amministratoreInfo: {
                name: QuotationData.amministratoreInfo.name,
                surname: QuotationData.amministratoreInfo.surname,
                birthDate: QuotationData.amministratoreInfo.birthDate,
                gender: QuotationData.amministratoreInfo.gender,
                companyName: QuotationData.amministratoreInfo.companyName,
                phoneNumber: company.usedefaultphonenumber === 1 ? company.phonenumber : QuotationData.amministratoreInfo.phoneNumber,
                email: company.usedefaultemail === 1 ? company.email : QuotationData.amministratoreInfo.email,
                fiscalCode: QuotationData.amministratoreInfo.fiscalCode,
                vatNumber: QuotationData.amministratoreInfo.vatNumber,
                personType: (QuotationData.amministratoreInfo.subjectType === "1" || QuotationData.amministratoreInfo.subjectType === "") ? "FIS" : "GIU",
                subjectType: (QuotationData.amministratoreInfo.subjectType === "") ? "1" : QuotationData.amministratoreInfo.subjectType,
                residenceInfo: {
                    city: QuotationData.amministratoreInfo.residenceInfo.city,
                    cap: QuotationData.amministratoreInfo.residenceInfo.cap,
                    provinceAbbr: QuotationData.amministratoreInfo.residenceInfo.provinceAbbr,
                    istat: QuotationData.amministratoreInfo.residenceInfo.istat,
                    toponym: QuotationData.amministratoreInfo.residenceInfo.toponym,
                    address: QuotationData.amministratoreInfo.residenceInfo.address,
                    streetNumber: QuotationData.amministratoreInfo.residenceInfo.streetNumber
                },
                locationInfo: {
                    city: QuotationData.amministratoreInfo.residenceInfo.city,
                    cap: QuotationData.amministratoreInfo.residenceInfo.cap,
                    provinceAbbr: QuotationData.amministratoreInfo.residenceInfo.provinceAbbr,
                    istat: QuotationData.amministratoreInfo.residenceInfo.istat,
                    toponym: QuotationData.amministratoreInfo.residenceInfo.toponym,
                    address: QuotationData.amministratoreInfo.residenceInfo.address,
                    streetNumber: QuotationData.amministratoreInfo.residenceInfo.streetNumber
                }
            },
            dataEffetto: QuotationData.dataEffetto,
            questionarioGf: QuotationData.questionarioGf !== undefined ? QuotationData.questionarioGf : false,
            tipologiaCostruzione: QuotationData.tipologiaCostruzione,
            percentualeAbUff: parseInt(QuotationData.percentualeAbUff),
            tipologiaImm: QuotationData.tipologiaImm,
            annoCostruzioneRistrutturazione: parseInt(QuotationData.annoCostruzioneRistrutturazione),
            valoreFabbricato: QuotationData.valoreFabbricato,
            inCostruzione: QuotationData.inCostruzione !== undefined && QuotationData.inCostruzione === "y" ? true : false,
            nPiani: parseInt(QuotationData.nPiani),
            cappotto: parseInt(QuotationData.cappotto),
            impElettrico: parseInt(QuotationData.impElettrico),
            impIdraulico: parseInt(QuotationData.impIdraulico),
            pannelli: parseInt(QuotationData.pannelli),
            franchigiaFrontale: parseInt(QuotationData.franchigiaFrontale),
            nFabbricati: parseInt(QuotationData.nFabbricati),
            nUnitaImmobiliari: parseInt(QuotationData.nUnitaImmobiliari),
            tetto: QuotationData.tetto,
            ubicazione: {
                provinciaAbbr: QuotationData.provinciaAbbr,
                citta: QuotationData.citta,
                cap: QuotationData.cap,
                istat: QuotationData.istat,
                numeroCivico: QuotationData.numeroCivico,
                nazioneAbbr: QuotationData.nazioneAbbr,
                toponimo: QuotationData.toponimo,
                indirizzo: QuotationData.indirizzo,
                provincia: QuotationData.provincia
            },
            listaCVT: listCVT
        },
    });
    const url_service = company.url + API_URL_QUOTATION_RE + "/gf/" + company.type + "/" + (company.type === "share" ? company.sharetoken : company.paramurl);
    try {
        const result = await axios.post(url_service, json, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        });
        // chiamata sblocco credenziali multisession
        if (company.multisession === 1) {
            const url_multisession = API_URL_MULTISESSION + "/checkuse/" + company.multisessionId + "/0";
            await axios.get(url_multisession, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + authToken,
                }
            });
        }
        return result;
    } catch (e) {
        // chiamata sblocco credenziali multisession
        if (company.multisession === 1) {
            const url_multisession = API_URL_MULTISESSION + "/checkuse/" + company.multisessionId + "/0";
            await axios.get(url_multisession, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + authToken,
                }
            });
        }
        return Promise.reject(e.response);
    }
};

const getInfoBirth = (token, url, cf, nome, cognome) => {
    try {
        const result = axios.get(url + API_URL_GET_INFOBIRTH, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            params: {
                cf: cf,
                nome: nome,
                cognome: cognome
            }
        });
        return result;
    } catch (e) {
        return Promise.reject(e.response);
    }
};

function TextFieldCustomFabbricato(props) {
    return (
        <TextField label="Valore fabbricato" variant="outlined" {...props} />
    );
};

function getSteps() {
    return ['Dati immobile', 'Anagrafiche', 'Garanzie', 'Questionario', 'Preventivo', 'Modifica/Conferma'];
};

export default function QuotationGf(props) {
    const classes = useStyles();
    const [alertseverity, setAlertSeverity] = React.useState("success");
    const [alertmsg, setAlertMsg] = React.useState(false);
    const [activeStep, setActiveStep] = React.useState(0);
    const [prevRQ, setPrevRQ] = React.useState(0);
    const [prevRS, setPrevRS] = React.useState(0);
    const steps = getSteps();
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [datipreventivo, setdatipreventivo] = React.useState([]);
    const [QuotationData, setQuotationData] = React.useState(AssignDefParameter(null, null, null));
    const [pos, setPos] = React.useState('');
    const [agencieslists, setagencieslists] = React.useState([]);
    const [images, setImages] = React.useState([]);
    const [token, setToken] = React.useState(null);
    const [orgid, setOrgId] = React.useState(null);
    const [posinfo, setPosInfo] = React.useState(null);
    const [, setPosActive] = React.useContext(PosDataContext);
    const [urlservicequotiamo, seturlservicequotiamo] = React.useState("");
    const [listCity, setlistCity] = React.useState([]);
    const [guarantees, setGuarantees] = React.useState([]);
    const [guaranteesSelect, setGuaranteesSelect] = React.useState([]);
    const [guaranteesValue, setGuaranteesValue] = React.useState([]);
    const [factories, setFactories] = React.useState([]);
    const [factoriesHub, setFactoriesHub] = React.useState([]);
    let contaPreventiviDaFare = 0;
    let contaPreventiviFatti = 0;
    const [companyData, setcompanyData] = React.useState([]);
    const [NumberQuotation, setNumberQuotation] = React.useState(false);
    const [progress, setProgress] = React.useState(0);
    const [questionarioGf, setQuestionarioGf] = React.useState({
        gf_domanda_1: "yes", gf_domanda_2: "no", gf_domanda_3: "no",
        gf_domanda_4: "no", gf_domanda_5: "no", gf_domanda_6: "no"
    });
    const [contatore, setContatore] = React.useState(0);
    const [noMod, setNoMod] = React.useState(1);
    const [scopertoDanTerr, setScopertoDanTerr] = React.useState(10000);

    const handleChangeValueGf = (event) => {
        setQuestionarioGf({
            ...questionarioGf,
            [event.target.name]: event.target.value
        });
    };

    React.useEffect(() => {
        const url = API_URL_LIST_AGENCIES_USER;
        axios.get(url,
            {
                headers: {
                    'Authorization': 'Bearer ' + Auth.getToken(),
                    'Content-Type': 'application/json'
                }
            }
        ).then(payload => {
            setagencieslists(payload.data);
            if (payload.data.length > 0) {
                setPos(payload.data[0].id);
                seturlservicequotiamo(payload.data[0].url);
                setPosInfo(payload.data[0]);
                setPosActive(payload.data[0]);
                setOrgId(payload.data[0].orgid);
                if (payload.data[0].defaultparameter !== null) {
                    let defOrg = JSON.parse(payload.data[0].defOrg);
                    let defGrp = JSON.parse(payload.data[0].defGrp);
                    let defAg = JSON.parse(payload.data[0].defAg);
                    setQuotationData(AssignDefParameter(defOrg, defGrp, defAg));
                }
                localStorage.setItem('pos', JSON.stringify(payload.data[0]));
                let urlservicequotiamo = payload.data[0].url;
                AuthService.signin(payload.data[0].username, payload.data[0].password, payload.data[0].url).then(
                    payload => {
                        setToken(payload);
                        getListOfCity(payload, urlservicequotiamo, "").then(payload => {
                            setlistCity(payload.data);
                        });
                    }
                ).catch(error => {
                });
                const url = API_URL_INFOIMAGES_ALL + "/gf/" + payload.data[0].id;
                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + Auth.getToken(),
                            'Content-Type': 'application/json'
                        }
                    }
                ).then(payload => {
                    setImages(payload.data.images)
                });
            }
        });
    }, [setPosActive]);

    React.useEffect(() => {
        if (orgid !== null) {
            const url = API_URL_ALLGUARANTEES + "/enabled/gf/" + orgid;
            axios.get(url,
                {
                    headers: {
                        'Authorization': 'Bearer ' + Auth.getToken(),
                        'Content-Type': 'application/json'
                    }
                }
            ).then(payload => {
                let temp = [];
                let tempVal = [];
                payload.data.guarantees.forEach(element => {
                    temp.push(false);
                    if (element.input_type === 2) {
                        tempVal.push(element.list_value.split(",")[0]);
                    } else {
                        tempVal.push("");
                    }
                });
                setGuaranteesSelect(temp);
                setGuaranteesValue(tempVal);
                setGuarantees(payload.data.guarantees);
            });
        }
    }, [orgid]);

    React.useEffect(() => {
        const url = API_URL_FACTORIES_LIST;
        axios.get(url,
            {
                headers: {
                    'Authorization': 'Bearer ' + Auth.getToken(),
                    'Content-Type': 'application/json'
                }
            }
        ).then(payload => {
            setFactories(payload.data.factories);
        });
    }, []);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleChange = (event) => {
        setQuotationData({
            ...QuotationData,
            [event.target.name]: event.target.value
        });
    };

    const handleBack = () => {
        if (activeStep === 4) {
            window.stop();
            setdatipreventivo([]);
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleChangePos = (event) => {
        agencieslists.forEach(element => {
            if (element.id === event.target.value) {
                setPos(event.target.value);
                seturlservicequotiamo(element.url)
                setPosInfo(element);
                setPosActive(element);
                setOrgId(element.orgid);
                if (element.defaultparameter !== null) {
                    let defOrg = JSON.parse(element.defOrg);
                    let defGrp = JSON.parse(element.defGrp);
                    let defAg = JSON.parse(element.defAg);
                    setQuotationData(AssignDefParameter(defOrg, defGrp, defAg));
                }
                localStorage.setItem('pos', JSON.stringify(element));
                AuthService.signin(element.username, element.password, element.url).then(
                    payload => {
                        setToken(payload);
                    }
                ).catch(error => {

                });
                const url = API_URL_INFOIMAGES_ALL + "/gf/" + event.target.value;
                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + Auth.getToken(),
                            'Content-Type': 'application/json'
                        }
                    }
                ).then(payload => {
                    setImages(payload.data.images)
                });
            }
        });
    };

    const handleChangeDataEffetto = event => {
        setQuotationData({
            ...QuotationData,
            dataEffetto: moment(event).format("YYYY-MM-DD")
        });
    };

    const checkcompanyData = async (tokenApp, pos) => {
        try {
            const result = await axios.get(API_URL_LIST_SERVICES_AGENCY_USER + "/gf/" + pos, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + tokenApp,
                }
            });
            return result;
        } catch (e) {
            return Promise.reject(e.response);
        }
    };

    const handleChangeList = index => event => {
        let newVal = guaranteesValue;
        newVal[index] = event.target.value;
        setGuaranteesValue(newVal);
        setContatore((prevState) => prevState + 1);
    };

    const handleClick = name => event => {
        let new_guaranteesSelect = guaranteesSelect;
        new_guaranteesSelect[name] = !guaranteesSelect[name];
        setGuaranteesSelect(new_guaranteesSelect);
        setContatore((prevState) => prevState + 1);
    };

    const handleChangeFreeInput = index => event => {
        let newVal = guaranteesValue;
        newVal[index] = event.floatValue;
        setGuaranteesValue(newVal);
        setContatore((prevState) => prevState + 1);
    };

    const HandleSaveQuotation = (companyListPrev) => {
        const userData = Auth.getUser();

        const json = JSON.stringify({
            clientinfo: QuotationData,
            questionario1: domandeQuestionarioGf,
            quotationtype: "gf"
        });

        const list = []
        companyListPrev.forEach((company) => {
            company.guarantees_select = guaranteesSelect;
            list.push(
                {
                    company: company,
                    datipre: datipreventivo[company.sfo_id + company.paramurl + company.type]
                }
            )
        });

        const url = API_URL_QUOTATION_LIST;
        axios.put(url, {
            product: window.location.pathname,
            id_user: userData.id,
            id_agency: pos,
            clientinfo: json,
            detail: JSON.stringify(list),
            select: "",
        },
            {
                headers: {
                    'Authorization': 'Bearer ' + Auth.getToken(),
                    'Content-Type': 'application/json'
                }
            }
        ).then(payload => {
            if (payload.status === 200) {
                setAlertMsg("Inserimento dati avvenuto con successo");
                setAlertSeverity("success");
                setNumberQuotation(payload.data.numberquotation);
            } else {
                setAlertMsg("Errore durante l'inserimento dati");
                setAlertSeverity("error");
            }
            setOpen(true);
        }).catch((error) => {
            setAlertMsg(error.response.data.error);
            setAlertSeverity("error");
            setOpen(true);
        });
    };

    const handleChangeFormatNumber = name => event => {
        setQuotationData({
            ...QuotationData,
            [name]: event.floatValue
        });
    };

    const handleNext = () => {
        switch (activeStep) {
            case 0:
                if (pos === "") {
                    setAlertMsg("Nessun punto vendita abilitato, impossibile effettuare il preventivo");
                    setAlertSeverity("error");
                    setOpen(true);
                    break;
                }
                let dataMax = moment().add(30, "days");
                let dataEffetto = moment(QuotationData.dataEffetto);
                if (dataEffetto > dataMax) {
                    setAlertMsg("Non è possibile effettuare un preventivo con una scadenza superiore ad un mese");
                    setAlertSeverity("error");
                    setOpen(true);
                    break;
                }
                checkcompanyData(Auth.getToken(), pos).then(
                    payload => {
                        let companyListPrev = payload.data.services;
                        if (companyListPrev.length < 1) {
                            setAlertMsg("Prodotto non attivo per questo punto vendita");
                            setAlertSeverity("error");
                            setOpen(true);
                            return;
                        }

                        if (QuotationData.inCostruzione === undefined || QuotationData.inCostruzione === 0 || QuotationData.inCostruzione === "0") {
                            setAlertMsg("Selezionare se l'immobile è in costruzione");
                            setAlertSeverity("error");
                            setOpen(true);
                            return;
                        }
                        if (QuotationData.citta === "" || QuotationData.cap === "" || QuotationData.provinciaAbbr === "") {
                            setAlertMsg("Seleziona comune di ubicazione dell'immobile");
                            setAlertSeverity("error");
                            setOpen(true);
                            return;
                        }
                        if (QuotationData.toponimo === "" || QuotationData.indirizzo === "" || QuotationData.numeroCivico === "") {
                            setAlertMsg("Inserire dati ubicazione dell'immobile");
                            setAlertSeverity("error");
                            setOpen(true);
                            return;
                        }
                        if (QuotationData.percentualeAbUff === "" || QuotationData.percentualeAbUff === undefined || QuotationData.percentualeAbUff === 0 || QuotationData.percentualeAbUff === "0") {
                            setAlertMsg("Inserire la percentuale abitazioni/uffici");
                            setAlertSeverity("error");
                            setOpen(true);
                            return;
                        }
                        if (parseInt(QuotationData.percentualeAbUff) > 100) {
                            setAlertMsg("La percentuale abitazioni/uffici non può essere maggiore del 100%");
                            setAlertSeverity("error");
                            setOpen(true);
                            return;
                        }
                        if (QuotationData.tipologiaCostruzione === undefined || QuotationData.tipologiaCostruzione === "0" || QuotationData.tipologiaCostruzione === 0) {
                            setAlertMsg("Seleziona la tipologia costruzione");
                            setAlertSeverity("error");
                            setOpen(true);
                            return;
                        }
                        let annoCorrente = moment().year();
                        if (QuotationData.annoCostruzioneRistrutturazione === "") {
                            setAlertMsg("Inserisci anno di costruzione/ristrutturazione");
                            setAlertSeverity("error");
                            setOpen(true);
                            return;
                        }
                        if (parseInt(QuotationData.annoCostruzioneRistrutturazione) > annoCorrente) {
                            setAlertMsg("L'anno di costruzione/ristrutturazione non può essere maggiore dell'anno corrente");
                            setAlertSeverity("error");
                            setOpen(true);
                            return;
                        }
                        if (QuotationData.tipologiaImm === undefined || QuotationData.tipologiaImm === "0" || QuotationData.tipologiaImm === 0) {
                            setAlertMsg("Seleziona la tipologia di immobile");
                            setAlertSeverity("error");
                            setOpen(true);
                            return;
                        }
                        if (QuotationData.franchigiaFrontale === undefined || QuotationData.franchigiaFrontale === "0" || QuotationData.franchigiaFrontale === 0) {
                            setAlertMsg("Seleziona la franchigia frontale");
                            setAlertSeverity("error");
                            setOpen(true);
                            return;
                        }
                        if (QuotationData.nPiani === "" || QuotationData.nPiani === 0 || QuotationData.nPiani === "0") {
                            setAlertMsg("Inserisci il numero di piani");
                            setAlertSeverity("error");
                            setOpen(true);
                            return;
                        }
                        if (QuotationData.nFabbricati === "" || QuotationData.nFabbricati === 0 || QuotationData.nFabbricati === "0") {
                            setAlertMsg("Inserisci il numero di fabbricati");
                            setAlertSeverity("error");
                            setOpen(true);
                            return;
                        }
                        if (QuotationData.nUnitaImmobiliari === "" || QuotationData.nUnitaImmobiliari === 0 || QuotationData.nUnitaImmobiliari === "0") {
                            setAlertMsg("Inserisci il numero di unità immobiliari");
                            setAlertSeverity("error");
                            setOpen(true);
                            return;
                        }
                        if (parseInt(QuotationData.nUnitaImmobiliari) < 2) {
                            setAlertMsg("Il numero di unità immobiliari non può essre inferiore a 2");
                            setAlertSeverity("error");
                            setOpen(true);
                            return;
                        }
                        if (QuotationData.tetto === undefined || QuotationData.tetto === "0") {
                            setAlertMsg("Seleziona il tipo di tetto");
                            setAlertSeverity("error");
                            setOpen(true);
                            return;
                        }
                        if (QuotationData.pannelli === undefined || QuotationData.pannelli === "0" || QuotationData.pannelli === 0) {
                            setAlertMsg("Seleziona se sono presenti i pannelli");
                            setAlertSeverity("error");
                            setOpen(true);
                            return;
                        }
                        if (QuotationData.impIdraulico === undefined || QuotationData.impIdraulico === "0" || QuotationData.impIdraulico === 0) {
                            setAlertMsg("Seleziona se l'impianto idraulico è dotato di conformità");
                            setAlertSeverity("error");
                            setOpen(true);
                            return;
                        }
                        if (QuotationData.impElettrico === undefined || QuotationData.impElettrico === "0" || QuotationData.impElettrico === 0) {
                            setAlertMsg("Seleziona se l'impianto elettrico è dotato di conformità");
                            setAlertSeverity("error");
                            setOpen(true);
                            return;
                        }
                        if (QuotationData.cappotto === undefined || QuotationData.cappotto === "0" || QuotationData.cappotto === 0) {
                            setAlertMsg("Seleziona se è presente il cappotto");
                            setAlertSeverity("error");
                            setOpen(true);
                            return;
                        }

                        if (noMod === 1) {
                            QuotationData.ownerInfo.residenceInfo.toponym = QuotationData.toponimo;
                            QuotationData.ownerInfo.residenceInfo.address = QuotationData.indirizzo;
                            QuotationData.ownerInfo.residenceInfo.streetNumber = QuotationData.numeroCivico;
                            QuotationData.ownerInfo.residenceInfo.city = QuotationData.citta;
                            QuotationData.ownerInfo.residenceInfo.cap = QuotationData.cap;
                            QuotationData.ownerInfo.residenceInfo.provinceAbbr = QuotationData.provinciaAbbr;
                            QuotationData.ownerInfo.residenceInfo.istat = QuotationData.istat;

                            QuotationData.amministratoreInfo.residenceInfo.toponym = QuotationData.toponimo;
                            QuotationData.amministratoreInfo.residenceInfo.address = QuotationData.indirizzo;
                            QuotationData.amministratoreInfo.residenceInfo.streetNumber = QuotationData.numeroCivico;
                            QuotationData.amministratoreInfo.residenceInfo.city = QuotationData.citta;
                            QuotationData.amministratoreInfo.residenceInfo.cap = QuotationData.cap;
                            QuotationData.amministratoreInfo.residenceInfo.provinceAbbr = QuotationData.provinciaAbbr;
                            QuotationData.amministratoreInfo.residenceInfo.istat = QuotationData.istat;
                        }

                        setNoMod(0);
                        setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    }
                ).catch(error => {
                    setAlertMsg("Prodotto non attivo per questo punto vendita");
                    setAlertSeverity("error");
                    setOpen(true);
                    return;
                });
                break;
            case 1:
                if (QuotationData.ownerInfo.subjectType === "" || QuotationData.ownerInfo.subjectType === "1") {
                    if (QuotationData.ownerInfo.name === "" ||
                        QuotationData.ownerInfo.surname === "" ||
                        QuotationData.ownerInfo.birthDate === "" ||
                        QuotationData.ownerInfo.gender === "" ||
                        QuotationData.ownerInfo.fiscalCode === "" ||
                        QuotationData.ownerInfo.birthDate === undefined
                    ) {
                        if (QuotationData.ownerInfo.birthDate === undefined || QuotationData.ownerInfo.birthDate === "") {
                            setAlertMsg("Codice fiscale intestatario errato");
                        } else {
                            setAlertMsg("Dati intestatario non completi");
                        }
                        setAlertSeverity("error");
                        setOpen(true);
                        break;
                    }
                    if (QuotationData.ownerInfo.residenceInfo.toponym === "" ||
                        QuotationData.ownerInfo.residenceInfo.address === "" ||
                        QuotationData.ownerInfo.residenceInfo.streetNumber === "" ||
                        QuotationData.ownerInfo.residenceInfo.city === "" ||
                        QuotationData.ownerInfo.residenceInfo.cap === "" ||
                        QuotationData.ownerInfo.residenceInfo.provinceAbbr === ""
                    ) {
                        setAlertMsg("Dati indirizzo proprietario non completi");
                        setAlertSeverity("error");
                        setOpen(true);
                        break;
                    }
                    if (QuotationData.ownerInfo.residenceInfo.provinceAbbr.length > 2) {
                        setAlertMsg("Sigla Provincia massimo 2 lettere");
                        setAlertSeverity("error");
                        setOpen(true);
                        break;
                    }
                    QuotationData.ownerInfo.vatNumber = "";
                    QuotationData.ownerInfo.companyName = "";
                } else {
                    if (QuotationData.ownerInfo.vatNumber === undefined) QuotationData.ownerInfo.vatNumber = "";
                    if (QuotationData.ownerInfo.companyName === undefined) QuotationData.ownerInfo.companyName = "";
                    if (QuotationData.ownerInfo.vatNumber === "" ||
                        QuotationData.ownerInfo.companyName === ""
                    ) {
                        setAlertMsg("Dati intestatario non completi");
                        setAlertSeverity("error");
                        setOpen(true);
                        break;
                    }
                    QuotationData.ownerInfo.birthDate = "";
                    QuotationData.ownerInfo.gender = "";
                    QuotationData.ownerInfo.name = "";
                    QuotationData.ownerInfo.surname = "";
                }
                if (QuotationData.ownerInfo.email === undefined) QuotationData.ownerInfo.email = "";
                if (!validator.isEmail(QuotationData.ownerInfo.email)) {
                    setAlertMsg("Email non valida");
                    setAlertSeverity("error");
                    setOpen(true);
                    break;
                }
                if (QuotationData.ownerInfo.phoneNumber === undefined) QuotationData.ownerInfo.phoneNumber = "";
                if (QuotationData.ownerInfo.phoneNumber === "" || QuotationData.ownerInfo.phoneNumber.length < 10 || !(validator.isMobilePhone(QuotationData.ownerInfo.phoneNumber, 'it-IT') /*|| pattern.test(QuotationData.contractorInfo.phoneNumber)*/)) {
                    setAlertMsg("Numero cellulare non valido");
                    setAlertSeverity("error");
                    setOpen(true);
                    break;
                }
                setQuotationData({
                    ...QuotationData,
                    contractorInfo: {
                        ...QuotationData.ownerInfo,
                    },
                });

                if (QuotationData.amministratoreInfo.subjectType === "" || QuotationData.amministratoreInfo.subjectType === "1") {
                    if (QuotationData.amministratoreInfo.name === "" ||
                        QuotationData.amministratoreInfo.surname === "" ||
                        QuotationData.amministratoreInfo.birthDate === "" ||
                        QuotationData.amministratoreInfo.gender === "" ||
                        QuotationData.amministratoreInfo.fiscalCode === "" ||
                        QuotationData.amministratoreInfo.birthDate === undefined
                    ) {
                        if (QuotationData.amministratoreInfo.birthDate === undefined || QuotationData.amministratoreInfo.birthDate === "") {
                            setAlertMsg("Codice fiscale amministratore errato");
                        } else {
                            setAlertMsg("Dati intestatario non completi");
                        }
                        setAlertSeverity("error");
                        setOpen(true);
                        break;
                    }
                    if (QuotationData.amministratoreInfo.residenceInfo.toponym === "" ||
                        QuotationData.amministratoreInfo.residenceInfo.address === "" ||
                        QuotationData.amministratoreInfo.residenceInfo.streetNumber === "" ||
                        QuotationData.amministratoreInfo.residenceInfo.city === "" ||
                        QuotationData.amministratoreInfo.residenceInfo.cap === "" ||
                        QuotationData.amministratoreInfo.residenceInfo.provinceAbbr === ""
                    ) {
                        setAlertMsg("Dati indirizzo amministratore non completi");
                        setAlertSeverity("error");
                        setOpen(true);
                        break;
                    }
                    if (QuotationData.amministratoreInfo.residenceInfo.provinceAbbr.length > 2) {
                        setAlertMsg("Sigla Provincia massimo 2 lettere");
                        setAlertSeverity("error");
                        setOpen(true);
                        break;
                    }
                } else {
                    setAlertMsg("Amministratore non specificato");
                    setAlertSeverity("error");
                    setOpen(true);
                    break;
                }

                let defOrg = JSON.parse(posinfo.defOrg);
                let defGrp = JSON.parse(posinfo.defGrp);
                let defAg = JSON.parse(posinfo.defAg);
                let n = 0;
                for (const cvt of guarantees) {
                    if (defAg !== null && defAg["GF_" + cvt.code] !== undefined ? true : (defGrp !== null && defGrp["GF_" + cvt.code] !== undefined ? true : (defOrg !== null && defOrg["GF_" + cvt.code] !== undefined ? true : false))) {
                        let flag = (defAg !== null && defAg["GF_" + cvt.code] !== undefined ? defAg["GF_" + cvt.code] : (defGrp !== null && defGrp["GF_" + cvt.code] !== undefined ? defGrp["GF_" + cvt.code] : (defOrg !== null && defOrg["GF_" + cvt.code] !== undefined ? defOrg["GF_" + cvt.code] : 0)));
                        if (flag > 0) {
                            guaranteesSelect[n] = true;
                        }
                    }
                    n++;
                }

                if (QuotationData.ownerInfo.subjectType === "" || QuotationData.ownerInfo.subjectType === "1") {
                    getInfoBirth(token, urlservicequotiamo, QuotationData.ownerInfo.fiscalCode, QuotationData.ownerInfo.name, QuotationData.ownerInfo.surname).then(
                        payload => {
                            if (payload.data.status !== undefined) {
                                setAlertMsg(payload.data.message);
                                setAlertSeverity("error");
                                setOpen(true);
                                return;
                            } else {
                                getInfoBirth(token, urlservicequotiamo, QuotationData.amministratoreInfo.fiscalCode, QuotationData.amministratoreInfo.name, QuotationData.amministratoreInfo.surname).then(
                                    payloadAmm => {
                                        if (payloadAmm.data.status !== undefined) {
                                            setAlertMsg(payloadAmm.data.message);
                                            setAlertSeverity("error");
                                            setOpen(true);
                                            return;
                                        } else {
                                            setActiveStep((prevActiveStep) => prevActiveStep + 1);
                                        }
                                    }
                                );
                            }
                        }
                    );
                } else {
                    getInfoBirth(token, urlservicequotiamo, QuotationData.amministratoreInfo.fiscalCode, QuotationData.amministratoreInfo.name, QuotationData.amministratoreInfo.surname).then(
                        payloadAmm => {
                            if (payloadAmm.data.status !== undefined) {
                                setAlertMsg(payloadAmm.data.message);
                                setAlertSeverity("error");
                                setOpen(true);
                                return;
                            } else {
                                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                            }
                        }
                    );
                }
                break;
            case 2:
                if (QuotationData.valoreFabbricato === undefined || QuotationData.valoreFabbricato === "" || QuotationData.valoreFabbricato === 0 || QuotationData.valoreFabbricato === "0") {
                    setAlertMsg("Valore fabbricato non corretto");
                    setAlertSeverity("error");
                    setOpen(true);
                    break;
                }

                if (guaranteesSelect.includes(true) === false) {
                    setAlertMsg("Non sono state selezionate garanzie");
                    setAlertSeverity("error");
                    setOpen(true);
                    return;
                }
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                break;
            case 3:
                QuotationData.questionarioGf = false;
                QuotationData.questionario = {};
                if (questionarioGf.gf_domanda_1 === "" || questionarioGf.gf_domanda_2 === "" || questionarioGf.gf_domanda_3 === "" ||
                    questionarioGf.gf_domanda_4 === "" || questionarioGf.gf_domanda_5 === "" || questionarioGf.gf_domanda_6 === ""
                ) {
                    setAlertMsg("Il Questionario non è stato compilato completamente");
                    setAlertSeverity("error");
                    setOpen(true);
                    return;
                }
                if (questionarioGf.gf_domanda_1 === "empty" || questionarioGf.gf_domanda_2 === "empty" || questionarioGf.gf_domanda_3 === "empty" ||
                    questionarioGf.gf_domanda_4 === "empty" || questionarioGf.gf_domanda_5 === "empty" || questionarioGf.gf_domanda_6 === "empty"
                ) {
                    setAlertMsg("Il Questionario non è stato compilato completamente");
                    setAlertSeverity("error");
                    setOpen(true);
                    return;
                }
                if (questionarioGf.gf_domanda_4 === "yes") {
                    if (questionarioGf.gf_domanda_4_1 === undefined || questionarioGf.gf_domanda_4_1.trim() === "") {
                        setAlertMsg("Questionario - Compilare la domanda 4.1");
                        setAlertSeverity("error");
                        setOpen(true);
                        return;
                    }
                }
                if (questionarioGf.gf_domanda_5 === "yes") {
                    if (questionarioGf.gf_domanda_5_1 === undefined || questionarioGf.gf_domanda_5_1.trim() === "") {
                        setAlertMsg("Questionario - Compilare la domanda 5.1");
                        setAlertSeverity("error");
                        setOpen(true);
                        return;
                    }
                }
                if (questionarioGf.gf_domanda_1 === "no") {
                    QuotationData.questionarioGf = true;
                }
                if (questionarioGf.gf_domanda_2 === "yes" || questionarioGf.gf_domanda_3 === "yes" || questionarioGf.gf_domanda_4 === "yes"
                    || questionarioGf.gf_domanda_5 === "yes" || questionarioGf.gf_domanda_6 === "yes"
                ) {
                    QuotationData.questionarioGf = true;
                }

                QuotationData.questionario.questionarioGf = questionarioGf;

                AuthService.signin(posinfo.username, posinfo.password, posinfo.url).then(
                    payload => {
                        setToken(payload);
                        checkcompanyData(Auth.getToken(), pos).then(
                            payload => {
                                let companyListPrev = payload.data.services;
                                let conventionslist = payload.data.conventions;
                                setFactoriesHub(payload.data.factorieshub);

                                companyListPrev.forEach((company, indexcompany) => {
                                    guarantees.forEach((element, index) => {
                                        if (guaranteesSelect[index]) {
                                            if (element["input_type"] !== 0) {
                                                companyListPrev[indexcompany].guarantees_info[index].massimale = Number(guaranteesValue[index]);
                                            }
                                        }
                                    });
                                });

                                setcompanyData(companyListPrev);
                                let AddOnService = [];

                                setPrevRS(0);
                                setPrevRQ(companyListPrev.length);
                                contaPreventiviDaFare = companyListPrev.length;
                                contaPreventiviFatti = 0;

                                companyListPrev.map((company) => (
                                    checkPreventivo(QuotationData, company, token, guarantees, guaranteesSelect, guaranteesValue, conventionslist, Auth.getToken(), scopertoDanTerr).then(
                                        payload => {
                                            const newDatiPreventivo = datipreventivo;
                                            if (payload.data.prices !== undefined) {
                                                payload.data.prices.forEach(element => {
                                                    let convenzioneAttiva = CheckConvenzioni(QuotationData, company, conventionslist, 1);
                                                    element.convenzioneAttiva = convenzioneAttiva;
                                                    if (element.convenzioneAttiva.code === "") {
                                                        convenzioneAttiva = CheckConvenzioni(QuotationData, company, conventionslist, 0);
                                                        element.convenzioneAttiva = convenzioneAttiva;
                                                        if (convenzioneAttiva !== false && convenzioneAttiva.code !== "") {
                                                            //Al momento sovrascrire qualsiasi sconto commerciale
                                                            //Dopo vedere il flag
                                                            company.discount_description = convenzioneAttiva.description;
                                                            company.discounts_rules = convenzioneAttiva.discounts_rules;
                                                        }
                                                    }

                                                    let sconto_totale = 0;
                                                    let nettoTotalePartenza = 0;
                                                    let nettoTotaleScontato = 0;
                                                    let differenzaImposte = 0;
                                                    if (company.discount_description !== null && company.discounts_rules !== null) {
                                                        element.listResponseCVT.forEach(
                                                            cvt => {
                                                                let selectCVT = "";
                                                                nettoTotalePartenza += cvt.price;
                                                                cvt.originalprice = cvt.price;
                                                                selectCVT = cvt.code;
                                                                if (selectCVT !== "") {
                                                                    JSON.parse(company.discounts_rules).forEach(
                                                                        single_discount_rules => {
                                                                            if (single_discount_rules.hasOwnProperty(selectCVT)) {
                                                                                if (single_discount_rules[selectCVT].val !== null && cvt.price >= Number(single_discount_rules[selectCVT].val)) {
                                                                                    sconto_totale += Number(single_discount_rules[selectCVT].val);
                                                                                    cvt.price -= Number(single_discount_rules[selectCVT].val);
                                                                                }
                                                                                if (single_discount_rules[selectCVT].perc !== null) {
                                                                                    sconto_totale += (cvt.price * (Number(single_discount_rules[selectCVT].perc))) / 100;
                                                                                    cvt.price -= (cvt.price * (Number(single_discount_rules[selectCVT].perc))) / 100;
                                                                                }
                                                                                nettoTotaleScontato += cvt.price;
                                                                            }
                                                                        }
                                                                    )
                                                                }
                                                            }
                                                        );

                                                        if (company.paramurl === "groupamanexus") {
                                                            element.listResponseCVT.forEach(
                                                                cvt => {
                                                                    if (cvt.code === "TAX") {
                                                                        let imposteTotale = cvt.price;
                                                                        let scontoPercTax = (nettoTotaleScontato * 100) / nettoTotalePartenza;
                                                                        cvt.price = (cvt.price * scontoPercTax) / 100;
                                                                        differenzaImposte = imposteTotale - cvt.price;
                                                                    }
                                                                }
                                                            );
                                                        }
                                                    }
                                                    element.TotalPrice -= parseFloat(sconto_totale);
                                                    element.price -= parseFloat(sconto_totale);
                                                    if (company.paramurl === "groupamanexus") {
                                                        element.TotalPrice -= parseFloat(differenzaImposte);
                                                        element.price -= parseFloat(differenzaImposte);
                                                    }

                                                    let addCalcoloCvt = 0;
                                                    guarantees.forEach((elementGuar, indexGuar) => {
                                                        if (guaranteesSelect[indexGuar]) {
                                                            let calcoloPercValue = 0;
                                                            let tipoCalcolo = company.guarantees_info[indexGuar].tipo_calcolo !== null ? (company.guarantees_info[indexGuar].tipo_calcolo !== "default" ? company.guarantees_info[indexGuar].tipo_calcolo : company.guarantees_info[indexGuar].tipo_calcolo_default) : company.guarantees_info[indexGuar].tipo_calcolo_default;
                                                            let serviceParameters = company.guarantees_info[indexGuar].tipo_calcolo !== null ? (company.guarantees_info[indexGuar].tipo_calcolo !== "default" ? company.guarantees_info[indexGuar].service_parameters : company.guarantees_info[indexGuar].service_parameters_default) : company.guarantees_info[indexGuar].service_parameters_default;
                                                            let tipoArrotondamento = company.guarantees_info[indexGuar].tipo_arrotondamento_calcolo;
                                                            if (tipoCalcolo === "perc") {
                                                                calcoloPercValue = parseFloat2Decimals((element.price * (Number(serviceParameters))) / 100);
                                                                if (tipoArrotondamento === "sup") {
                                                                    calcoloPercValue = Math.ceil(calcoloPercValue);
                                                                } else if (tipoArrotondamento === "inf") {
                                                                    calcoloPercValue = Math.floor(calcoloPercValue);
                                                                } else if (tipoArrotondamento === "mat") {
                                                                    calcoloPercValue = Math.round(calcoloPercValue);
                                                                }
                                                                addCalcoloCvt += calcoloPercValue;
                                                                let guarInfo =
                                                                {
                                                                    code: elementGuar["code"],
                                                                    nameCVT: elementGuar["description"],
                                                                    selected: true,
                                                                    price: calcoloPercValue
                                                                };
                                                                element.listResponseCVT.push(guarInfo);
                                                            } else if (tipoCalcolo === "value") {
                                                                calcoloPercValue = Number(serviceParameters);
                                                                addCalcoloCvt += parseFloat2Decimals(calcoloPercValue);
                                                                let guarInfo =
                                                                {
                                                                    code: elementGuar["code"],
                                                                    nameCVT: elementGuar["description"],
                                                                    selected: true,
                                                                    price: parseFloat2Decimals(calcoloPercValue)
                                                                };
                                                                element.listResponseCVT.push(guarInfo);
                                                            }
                                                        }
                                                    });

                                                    element.TotalPrice += addCalcoloCvt;
                                                    element.price += addCalcoloCvt;

                                                    let NumRate = 1;
                                                    let splitPrice = element.price;

                                                    if (element.splittingUp !== undefined && !isNaN(element.splittingUp)) {
                                                        NumRate = parseFloat(element.splittingUp);
                                                    }
                                                    if (element.splitPrice !== undefined && !isNaN(element.splitPrice)) {
                                                        splitPrice = parseFloat(element.splitPrice);
                                                    } else {
                                                        splitPrice = element.price;
                                                    }

                                                    splitPrice += (addCalcoloCvt / NumRate).toFixed(2);

                                                    // COMMISSIONI
                                                    if (company.FinalCommissionsType !== null && company.FinalCommissionsType === "euro") {
                                                        if (Number(company.FinalCommissionsDefault) >= 0) {
                                                            element.Commissions = company.FinalCommissionsDefault * NumRate;
                                                        } else {
                                                            if (Number(company.FinalCommissionsMax)) {
                                                                element.Commissions = company.FinalCommissionsMax * NumRate;
                                                            } else {
                                                                element.Commissions = 0;
                                                            }
                                                        }
                                                    } else {
                                                        if (Number(company.FinalCommissionsDefault) >= 0) {
                                                            element.Commissions = element.price * company.FinalCommissionsDefault / 100;
                                                            element.Commissions = element.Commissions.toFixed(2);
                                                        } else {
                                                            if (Number(company.FinalCommissionsMax)) {
                                                                element.Commissions = element.price * company.FinalCommissionsMax / 100;
                                                                element.Commissions = element.Commissions.toFixed(2);
                                                            } else {
                                                                element.Commissions = 0;
                                                            }
                                                        }
                                                    }

                                                    // DIRITTI
                                                    if (company.FinalfeeType !== null && company.FinalfeeType === "perc") {
                                                        if (Number(company.FinalfeeDefault) >= 0) {
                                                            element.Fees = element.price * company.FinalfeeDefault / 100;
                                                            element.Fees = element.Fees.toFixed(2);
                                                        } else {
                                                            if (Number(company.FinalfeeMax)) {
                                                                element.Fees = element.price * company.FinalfeeMax / 100;
                                                                element.Fees = element.Fees.toFixed(2);
                                                            } else {
                                                                element.Fees = 0;
                                                            }
                                                        }
                                                    } else {
                                                        if (Number(company.FinalfeeDefault) >= 0) {
                                                            element.Fees = company.FinalfeeDefault * NumRate;
                                                        } else {
                                                            if (Number(company.FinalfeeMax)) {
                                                                element.Fees = company.FinalfeeMax * NumRate;
                                                            } else {
                                                                element.Fees = 0;
                                                            }
                                                        }
                                                    }

                                                    element.TotalPrice = parseFloat(element.price) + parseFloat(element.Commissions) + parseFloat(element.Fees);

                                                    element.ListService = [];
                                                    AddOnService.forEach(service => {
                                                        let priceservice = 0;
                                                        if (service.type === "perc") {
                                                            priceservice = element.price * service.value / 100;
                                                            priceservice = parseFloat(priceservice);
                                                        } else {
                                                            priceservice = service.value;
                                                        }
                                                        element.TotalPrice += parseFloat(priceservice);

                                                        let ServiceInfo =
                                                        {
                                                            description: "NetAssistance",
                                                            priceservice: priceservice
                                                        };
                                                        element.ListService.push(ServiceInfo);
                                                    });

                                                    element.TotalRate = parseFloat(element.TotalPrice / NumRate);
                                                    element.TotalRate = element.TotalRate.toFixed(2);
                                                    element.only_cvt = company.only_cvt;
                                                });
                                            }

                                            newDatiPreventivo[company.sfo_id + company.paramurl + company.type] = payload.data;
                                            setdatipreventivo(newDatiPreventivo);
                                            setPrevRS(prevCount => prevCount + 1);
                                            contaPreventiviFatti++;

                                            if (contaPreventiviFatti === contaPreventiviDaFare && contaPreventiviDaFare > 0) {
                                                HandleSaveQuotation(companyListPrev);
                                            }

                                            if (prevRQ === prevRS) {
                                                setLoading(false);
                                            }
                                        }
                                    ).catch(error => {
                                        setPrevRS(prevCount => prevCount + 1);
                                        contaPreventiviFatti++;
                                        if (contaPreventiviFatti === contaPreventiviDaFare && contaPreventiviDaFare > 0) {
                                            HandleSaveQuotation(companyListPrev);
                                        }
                                        if (prevRQ === prevRS)
                                            setLoading(false);
                                    })
                                ));
                            }
                        ).catch(error => {
                            console.log(error);
                        });
                    }
                ).catch(error => {
                    setAlertMsg("Errore durante la connessione a QUOTIAMO Service");
                    setAlertSeverity("error");
                    setOpen(true);
                });

                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                break;
            case 4:
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                break;
            default:
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                break;
        }
    }

    const handleChangeFranchigiaDannTerr = index => event => {
        setScopertoDanTerr(event.target.value);
    };

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return <>
                    <div className={classes.paper}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={4}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel id="labelPOS">Punto vendita</InputLabel>
                                    <Select
                                        labelId="labelPOS"
                                        id="pos"
                                        value={pos}
                                        onChange={handleChangePos}
                                        label="Punto vendita"
                                    >
                                        {agencieslists.length > 0 && agencieslists.map(option => (
                                            <MenuItem
                                                key={"ag_" + option.id}
                                                value={option.id}
                                            >
                                                {option.description}
                                            </MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <KeyboardDatePicker
                                    label="Data effetto"
                                    value={QuotationData.dataEffetto}
                                    onChange={handleChangeDataEffetto}
                                    format='dd/MM/yyyy'
                                    clearLabel="Conferma"
                                    cancelLabel="Annulla"
                                    name="dataEffetto"
                                    fullWidth
                                    required
                                    invalidDateMessage="Data non valida"
                                    maxDateMessage="Non è possibile effettuare un preventivo con una scadenza superiore alla fine del mese successivo"
                                    minDateMessage="Il preventivo non può essere retroattivo"
                                    minDate={moment()}
                                    maxDate={moment().add(30, "days")}
                                    //maxDate={moment().add(1, "months").endOf('month')}
                                    inputVariant="outlined"
                                />
                            </Grid>
                        </Grid>
                    </div>
                    <div className={classes.paper}>
                        <Typography variant="h6">
                            Ubicazione immobile
                        </Typography>

                        <Grid className={classes.FirstDataRow} container spacing={3}>
                            <Grid item xs={3} md={2}>
                                <TextValidator
                                    variant="outlined"
                                    fullWidth
                                    id="toponimo"
                                    label="Via, piazza,ecc"
                                    name="toponimo"
                                    value={QuotationData.toponimo}
                                    onChange={handleChange}
                                    validators={[]}
                                    errorMessages={[]}
                                />
                            </Grid>
                            <Grid item xs={6} md={8}>
                                <TextValidator
                                    variant="outlined"
                                    fullWidth
                                    id="indirizzo"
                                    label="Indirizzo"
                                    name="indirizzo"
                                    value={QuotationData.indirizzo}
                                    onChange={handleChange}
                                    validators={[]}
                                    errorMessages={[]}
                                />
                            </Grid>
                            <Grid item xs={3} md={2}>
                                <TextValidator
                                    variant="outlined"
                                    fullWidth
                                    id="numeroCivico"
                                    label="Numero civico"
                                    name="numeroCivico"
                                    value={QuotationData.numeroCivico}
                                    onChange={handleChange}
                                    validators={[]}
                                    errorMessages={[]}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item xs={12} md={2}>
                                <TextValidator
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    disabled
                                    id="cap"
                                    label="Cap"
                                    name="cap"
                                    autoFocus
                                    value={QuotationData.cap}
                                    onChange={handleChange}
                                    validators={['required']}
                                    errorMessages={['Campo obbligatorio']}
                                />
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Autocomplete
                                    //options= {listCity.map((option)=>option.city)}                  
                                    options={listCity}
                                    defaultValue={{
                                        cap: QuotationData.cap,
                                        city: QuotationData.citta,
                                        provinceAbbr: QuotationData.provinciaAbbr
                                    }}
                                    getOptionSelected={(option, value) => option.city === value.city && option.cap === value.cap}
                                    getOptionLabel={(option) => {
                                        //filter value
                                        return (`${option.city}`)
                                    }}
                                    id="comune"
                                    label="Comune"
                                    name="citta"
                                    autoHighlight
                                    onChange={(event, newValue) => {
                                        listCity.map((option) => {
                                            if (newValue === option) {
                                                setQuotationData({
                                                    ...QuotationData,
                                                    citta: option.city,
                                                    cap: option.cap,
                                                    istat: option.istat,
                                                    provinciaAbbr: option.provinceAbbr
                                                }
                                                );
                                                return option
                                            }
                                            return false;
                                        })
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Comune" margin="normal" variant="outlined" />}
                                    renderOption={(option) => {
                                        return (`${option.city} (${option.cap})`)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <TextValidator
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="provinciaAbbr"
                                    label="Provincia (Sigla)"
                                    name="provinciaAbbr"
                                    autoFocus
                                    disabled
                                    value={QuotationData.provinciaAbbr}
                                    onChange={handleChange}
                                    validators={['required', 'maxStringLength:2']}
                                    errorMessages={['Campo obbligatorio']}
                                    maxLength={2}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="inCostruzione"
                                    label="In costruzione?"
                                    name="inCostruzione"
                                    select
                                    SelectProps={{ native: true }}
                                    value={QuotationData.inCostruzione}
                                    onChange={handleChange}
                                >
                                    <option
                                        value={0}
                                    >
                                        Seleziona
                                    </option>
                                    <option
                                        value={"y"}
                                    >
                                        Sì
                                    </option>
                                    <option
                                        value={"n"}
                                    >
                                        No
                                    </option>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    label="Tipologia di immobile"
                                    name="tipologiaImm"
                                    onChange={handleChange}
                                    select
                                    SelectProps={{ native: true }}
                                    value={QuotationData.tipologiaImm}
                                    variant="outlined"
                                >
                                    <option
                                        value={0}
                                    >
                                        Seleziona la tipologia
                                    </option>
                                    {TipologiaList.map(option => (
                                        <option
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    label="Tipologia costruzione"
                                    name="tipologiaCostruzione"
                                    onChange={handleChange}
                                    margin="normal"
                                    select
                                    SelectProps={{ native: true }}
                                    value={QuotationData.tipologiaCostruzione}
                                    variant="outlined"
                                >
                                    <option
                                        value={0}
                                    >
                                        Seleziona la tipologia di costruzione
                                    </option>
                                    {tipologiaCostruzioneListGf.map(option => (
                                        <option
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item xs={12} md={4}>
                                <TextValidator
                                    variant="outlined"
                                    fullWidth
                                    id="percentualeAbUff"
                                    label="Percentuale abitazioni/uffici"
                                    name="percentualeAbUff"
                                    margin="normal"
                                    type="number"
                                    value={QuotationData.percentualeAbUff}
                                    onChange={handleChange}
                                    validators={[]}
                                    errorMessages={[]}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    label="Franchigia frontale"
                                    name="franchigiaFrontale"
                                    onChange={handleChange}
                                    margin="normal"
                                    select
                                    SelectProps={{ native: true }}
                                    value={QuotationData.franchigiaFrontale}
                                    variant="outlined"
                                >
                                    <option
                                        value={0}
                                    >
                                        Seleziona la franchigia frontale
                                    </option>
                                    {franchigiaFrontaleGf.map(option => (
                                        <option
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextValidator
                                    variant="outlined"
                                    fullWidth
                                    id="nPiani"
                                    label="Numero piani"
                                    name="nPiani"
                                    margin="normal"
                                    type="number"
                                    value={QuotationData.nPiani}
                                    onChange={handleChange}
                                    validators={[]}
                                    errorMessages={[]}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item xs={12} md={4}>
                                <TextValidator
                                    variant="outlined"
                                    fullWidth
                                    id="nFabbricati"
                                    label="Numero fabbricati"
                                    name="nFabbricati"
                                    margin="normal"
                                    type="number"
                                    value={QuotationData.nFabbricati}
                                    onChange={handleChange}
                                    validators={[]}
                                    errorMessages={[]}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextValidator
                                    variant="outlined"
                                    fullWidth
                                    id="nUnitaImmobiliari"
                                    label="Numero unità immobiliari"
                                    name="nUnitaImmobiliari"
                                    margin="normal"
                                    type="number"
                                    value={QuotationData.nUnitaImmobiliari}
                                    onChange={handleChange}
                                    validators={[]}
                                    errorMessages={[]}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextValidator
                                    variant="outlined"
                                    fullWidth
                                    id="annoCostruzioneRistrutturazione"
                                    label="Anno costruzione/ristrutturazione"
                                    name="annoCostruzioneRistrutturazione"
                                    margin="normal"
                                    type="number"
                                    value={QuotationData.annoCostruzioneRistrutturazione}
                                    onChange={handleChange}
                                    validators={[]}
                                    errorMessages={[]}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    label="Tetto"
                                    name="tetto"
                                    onChange={handleChange}
                                    select
                                    SelectProps={{ native: true }}
                                    value={QuotationData.tetto}
                                    variant="outlined"
                                >
                                    <option
                                        value={"0"}
                                    >
                                        Seleziona il tipo di tetto
                                    </option>
                                    {tettoGf.map(option => (
                                        <option
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    label="Presenza pannelli solari e/o fotovoltaici"
                                    name="pannelli"
                                    onChange={handleChange}
                                    select
                                    SelectProps={{ native: true }}
                                    value={QuotationData.pannelli}
                                    variant="outlined"
                                >
                                    <option
                                        value={0}
                                    >
                                        Seleziona se presenti
                                    </option>
                                    {pannelliGf.map(option => (
                                        <option
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    label="Presenza cappotto termico"
                                    name="cappotto"
                                    onChange={handleChange}
                                    select
                                    SelectProps={{ native: true }}
                                    value={QuotationData.cappotto}
                                    variant="outlined"
                                >
                                    <option
                                        value={0}
                                    >
                                        Seleziona se presente
                                    </option>
                                    {cappottoGf.map(option => (
                                        <option
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    label="Impianto elettrico dotato di dichiarazione conformità L. 46/90 o D.M. 37/2008"
                                    name="impElettrico"
                                    onChange={handleChange}
                                    select
                                    SelectProps={{ native: true }}
                                    value={QuotationData.impElettrico}
                                    variant="outlined"
                                >
                                    <option
                                        value={"0"}
                                    >
                                        Seleziona
                                    </option>
                                    {impElettricoGf.map(option => (
                                        <option
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    label="Impianto idraulico dotato di dichiarazione conformità L. 46/90 o D.M. 37/2008"
                                    name="impIdraulico"
                                    onChange={handleChange}
                                    select
                                    SelectProps={{ native: true }}
                                    value={QuotationData.impIdraulico}
                                    variant="outlined"
                                >
                                    <option
                                        value={0}
                                    >
                                        Seleziona
                                    </option>
                                    {impIdraulicoGf.map(option => (
                                        <option
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                    </div>
                </>;
            case 1:
                return <>
                    <SubjectDetails
                        datatype={"gf"}
                        type={"ownerInfo"}
                        token={token}
                        urlservicequotiamo={urlservicequotiamo}
                        datiquotazione={QuotationData}
                        setdatiquotazione={setQuotationData}
                        listCity={listCity}
                        posinfo={posinfo}
                    />
                    <SubjectDetails
                        datatype={"gf"}
                        type={"amministratoreInfo"}
                        token={token}
                        urlservicequotiamo={urlservicequotiamo}
                        datiquotazione={QuotationData}
                        setdatiquotazione={setQuotationData}
                        listCity={listCity}
                        posinfo={posinfo}
                    />
                </>;
            case 2:
                return <>
                    <div className={classes.paper}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={4}>
                                <NumberFormat style={{ width: "100%", paddingBottom: "10px" }} onValueChange={handleChangeFormatNumber("valoreFabbricato")} customInput={TextFieldCustomFabbricato} fixedDecimalScale="true" decimalScale="0" value={QuotationData.valoreFabbricato} displayType={'input'} decimalSeparator={" "} thousandSeparator={"."} prefix={'€ '} />
                            </Grid>
                        </Grid>

                        <Grid container spacing={0}>
                            {guarantees.map((warranty, index) => (
                                <>
                                    {(index === 0 || guarantees[index - 1].categoriesdescription !== warranty.categoriesdescription) &&
                                        <Grid key={"cat_" + index} item xs={12}>
                                            <Typography className={classes.TitleCategory}>
                                                {warranty.categoriesdescription}
                                            </Typography>
                                        </Grid>
                                    }
                                    <Grid key={index} item xs={2}>
                                        <CheckSvgButton disabled={posinfo.defAg !== null && JSON.parse(posinfo.defAg)["GF_" + warranty.code] !== undefined ? JSON.parse(posinfo.defAg)["GF_" + warranty.code] >= 2 : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp)["GF_" + warranty.code] !== undefined ? true : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg)["GF_" + warranty.code] !== undefined ? true : false)) &&
                                            (((posinfo.defAg !== null && JSON.parse(posinfo.defAg)["GF_" + warranty.code] !== undefined ? JSON.parse(posinfo.defAg)["GF_" + warranty.code] : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp)["GF_" + warranty.code] !== undefined ? JSON.parse(posinfo.defGrp)["GF_" + warranty.code] : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg)["GF_" + warranty.code] !== undefined ? JSON.parse(posinfo.defOrg)["GF_" + warranty.code] : 0))) & 2) >= 2)} label={warranty.description} svg={warranty.image} status={guaranteesSelect[index]} click={handleClick(index)} />

                                        <Box className={classes.BoxContainer}>

                                            {warranty.input_type === 1 &&
                                                <>
                                                    <NumberFormat disabled={!guaranteesSelect[index]} onValueChange={handleChangeFreeInput(index)} customInput={TextFieldCustomMassimale} fixedDecimalScale="true" decimalScale="0" value={guaranteesValue[index]} displayType={'input'} decimalSeparator={" "} thousandSeparator={"."} prefix={'€ '} />
                                                </>
                                            }

                                            {warranty.input_type === 2 &&
                                                <>
                                                    <TextField
                                                        disabled={!guaranteesSelect[index]}
                                                        fullWidth
                                                        label="Massimale"
                                                        onChange={handleChangeList(index)}
                                                        select
                                                        SelectProps={{ native: true }}
                                                        value={guaranteesValue[index]}
                                                        variant="outlined"
                                                    >

                                                        {warranty.list_value.split(",").map(option => (
                                                            <option
                                                                key={option}
                                                                value={option}
                                                            >
                                                                {option.indexOf("%") >= 0 && "" + option}

                                                                {option.indexOf("%") === -1 && "€ " + number_format(option, 0, ",", ".")}
                                                            </option>
                                                        ))}
                                                    </TextField>
                                                </>
                                            }
                                        </Box>
                                        {warranty.code === "DAN_TERR" &&
                                            <Box className={classes.BoxContainer}>
                                                <TextField
                                                    disabled={!guaranteesSelect[index]}
                                                    fullWidth
                                                    label="Franchigia/Scoperto"
                                                    onChange={handleChangeFranchigiaDannTerr(index)}
                                                    select
                                                    SelectProps={{ native: true }}
                                                    value={scopertoDanTerr}
                                                    variant="outlined"
                                                >
                                                    {opzioniGfDanTerrScoperto.map(option => (
                                                        <option
                                                            key={option.value}
                                                            value={option.value}
                                                        >
                                                            {option.label}
                                                        </option>
                                                    ))}
                                                </TextField>
                                            </Box>
                                        }
                                    </Grid>
                                </>
                            ))}
                        </Grid>
                    </div>
                </>;
            case 3:
                return <>
                    <div className={classes.paper}>
                        <Accordion defaultExpanded={true} style={{ width: "100%" }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className={classes.heading}>Questionario dichiarativo</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TableContainer className={classes.table}>
                                    <Table aria-label="Questionario dichiarativo">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ width: "60%" }}>Domanda</TableCell>
                                                <TableCell>Risposta</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {domandeQuestionarioGf.map((domanda, index) => (
                                                <>
                                                    {domanda.value !== "gf_domanda_4_1" && domanda.value !== "gf_domanda_5_1" &&
                                                        <TableRow>
                                                            <TableCell style={{ width: "40%" }}>
                                                                {domanda.description}
                                                            </TableCell>
                                                            <TableCell>
                                                                {domanda.type === "select" &&
                                                                    <TextField
                                                                        margin="normal"
                                                                        fullWidth
                                                                        label={"Domanda"}
                                                                        name={domanda.value}
                                                                        onChange={handleChangeValueGf}
                                                                        select
                                                                        SelectProps={{ native: true }}
                                                                        value={questionarioGf === null ? "" : questionarioGf[domanda.value] === undefined ? "" : questionarioGf[domanda.value]}
                                                                        variant="outlined"
                                                                    >
                                                                        <option key={domanda.value + "_vuoto"} value={"empty"}>
                                                                            Seleziona
                                                                        </option>
                                                                        <option key={domanda.value + "_no"} value={"no"}>
                                                                            NO
                                                                        </option>
                                                                        <option key={domanda.value + "_si"} value={"yes"}>
                                                                            SI
                                                                        </option>
                                                                    </TextField>
                                                                }
                                                                {domanda.type === "text" &&
                                                                    <TextField
                                                                        variant="outlined"
                                                                        margin="normal"
                                                                        fullWidth
                                                                        id={domanda.value}
                                                                        label={"Domanda"}
                                                                        name={domanda.value}
                                                                        size="medium"
                                                                        multiline
                                                                        rows={4}
                                                                        value={questionarioGf === null ? "no" : questionarioGf[domanda.value] === undefined ? "" : questionarioGf[domanda.value]}
                                                                        onChange={handleChangeValueGf}
                                                                    />
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                    {domanda.value === "gf_domanda_4_1" && questionarioGf !== null && questionarioGf.gf_domanda_4 !== undefined && questionarioGf.gf_domanda_4 === "yes" &&
                                                        <TableRow>
                                                            <TableCell style={{ width: "40%" }}>
                                                                {domanda.description}
                                                            </TableCell>
                                                            <TableCell>
                                                                {domanda.type === "text" &&
                                                                    <TextField
                                                                        variant="outlined"
                                                                        margin="normal"
                                                                        fullWidth
                                                                        id={domanda.value}
                                                                        label={"Domanda"}
                                                                        name={domanda.value}
                                                                        size="medium"
                                                                        multiline
                                                                        rows={4}
                                                                        value={questionarioGf === null ? "no" : questionarioGf[domanda.value] === undefined ? "" : questionarioGf[domanda.value]}
                                                                        onChange={handleChangeValueGf}
                                                                    />
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                    {domanda.value === "gf_domanda_5_1" && questionarioGf !== null && questionarioGf.gf_domanda_5 !== undefined && questionarioGf.gf_domanda_5 === "yes" &&
                                                        <TableRow>
                                                            <TableCell style={{ width: "40%" }}>
                                                                {domanda.description}
                                                            </TableCell>
                                                            <TableCell>
                                                                {domanda.type === "text" &&
                                                                    <TextField
                                                                        variant="outlined"
                                                                        margin="normal"
                                                                        fullWidth
                                                                        id={domanda.value}
                                                                        label={"Domanda"}
                                                                        name={domanda.value}
                                                                        size="medium"
                                                                        multiline
                                                                        rows={4}
                                                                        value={questionarioGf === null ? "no" : questionarioGf[domanda.value] === undefined ? "" : questionarioGf[domanda.value]}
                                                                        onChange={handleChangeValueGf}
                                                                    />
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                </>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </>;
            case 4:
                return <Paper className={classes.paper}>
                    <div className={classes.progress}>{progress}</div>
                    {prevRS < prevRQ &&
                        <div className={classes.linearProgressDiv}>
                            <LinearProgressWithLabel value={100 * prevRS / prevRQ} />
                            {images.length > 0 &&
                                <Carousel
                                    className={classes.Carousel}
                                    autoPlay={true}
                                    indicators={true}
                                    timeout={500}
                                    cycleNavigation={true}
                                    navButtonsAlwaysVisible={true}
                                    navButtonsAlwaysInvisible={false}
                                    fullHeightHover={true}
                                >
                                    {
                                        images.map((item, index) => {
                                            return <CardMedia
                                                className={classes.Media}
                                                image={item.image}
                                            >
                                            </CardMedia>
                                        })
                                    }
                                </Carousel>
                            }
                        </div>
                    }
                    {companyData.map((company, index) =>
                        <Box className={classes.root} m={2} >
                            <QuotationDetails key={index} factories={factories} factoriesHub={factoriesHub} company={company} datipre={datipreventivo[company.sfo_id + company.paramurl + company.type]} saveActive={false} />
                        </Box>
                    )}
                    {prevRS === prevRQ && images.length > 0 &&
                        <Carousel
                            className={classes.Carousel}
                            autoPlay={true}
                            animation={"fade"}
                            indicators={true}
                            timeout={500}
                            cycleNavigation={true}
                            navButtonsAlwaysVisible={true}
                            navButtonsAlwaysInvisible={false}
                            fullHeightHover={true}
                        >
                            {
                                images.map((item, index) => {
                                    return <CardMedia
                                        key={index}
                                        className={classes.Media}
                                        image={item.image}
                                    >
                                    </CardMedia>
                                })
                            }
                        </Carousel>
                    }
                </Paper>;
            case 5:
                return <div className={classes.paper}>
                    {NumberQuotation &&
                        <QuotationSingleDetail numberquotation={NumberQuotation} visual={true} />
                    }
                </div>;
            default:
                return 'Unknown step';
        }
    }

    return (
        <main className={classes.content}>
            <BackGround open={loading} />
            <ValidatorForm
                onSubmit={() => {
                }}
                onError={errors => console.log(errors)}
            >
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid className={classes.containerButton} item md={8}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link color="inherit" href="/" >
                                    {NAME_APP}
                                </Link>
                                <Link color="inherit" href="/gf" >
                                    Globale Fabbricati
                                </Link>
                                <Typography color="textPrimary">Richiesta preventivo</Typography>
                            </Breadcrumbs>
                        </Grid>
                        <Grid className={classes.containerButton} item md={4}>
                            <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                                Indietro
                            </Button>
                            {activeStep < steps.length &&
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNext}
                                    className={classes.button}
                                    disabled={activeStep === steps.length - 1 || (activeStep === 3 && prevRS < prevRQ && prevRQ > 0)}
                                >
                                    Avanti
                                </Button>
                            }
                        </Grid>
                    </Grid>
                    <Card>
                        <CardContent>
                            <Stepper activeStep={activeStep}>
                                {steps.map((label, index) => {
                                    const stepProps = {};
                                    const labelProps = {};
                                    return (
                                        <Step key={label} {...stepProps}>
                                            <StepLabel {...labelProps}>{label}</StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                            <div className={classes.wrapper}>
                                {activeStep === steps.length ? (
                                    <div>
                                        <Typography className={classes.instructions}>
                                            Richiesta Preventivo completata
                                        </Typography>
                                        <Button onClick={handleReset} className={classes.button}>
                                            Nuovo preventivo
                                        </Button>
                                    </div>
                                ) : (
                                    <div className={classes.fulldiv}>
                                        {getStepContent(activeStep)}
                                    </div>
                                )}
                            </div>
                        </CardContent>
                    </Card>
                    <Grid container spacing={3}>
                        <Grid className={classes.containerButton} item md={12}>
                            <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                                Indietro
                            </Button>
                            {activeStep < steps.length &&
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNext}
                                    className={classes.button}
                                    disabled={activeStep === steps.length - 1 || (activeStep === 3 && prevRS < prevRQ && prevRQ > 0)}
                                >
                                    Avanti
                                </Button>
                            }
                        </Grid>
                    </Grid>
                </Container>

                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                    <Alert onClose={handleClose} severity={alertseverity}>
                        {alertmsg}
                    </Alert>
                </Snackbar>
            </ValidatorForm>
        </main>
    );
}