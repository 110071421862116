export const NAME_APP = 'QUOTIAMO';

export const APP_TOKEN_QUOTIAMO = 'c2691db61637eebad87b8129116834af';
export const APP_URL = "https://app.quotiamo.it/";
//export const API_URL = "http://localhost/api/";
//export const API_URL= "http://server1011.lab4it.net:1080/public/api/";
//export const API_URL= "https://quotiamoapi.finital.it/public/api/";
//export const API_URL= "https://apinew.quotiamo.it/api/";
export const API_URL= "https://api.quotiamo.it/api/";

export const URL_SSO_INSURSOFT = "https://crm-quotiamo.insursoft.it/sso-auth/";
export const API_URL_REGISTER = API_URL + 'register';
export const API_URL_REGISTER_WITH_AGENCY = API_URL + 'registerwithagency';
export const API_URL_LOGIN = API_URL + 'login';
export const API_URL_LOGIN_SSO = API_URL + 'users/login/sso/verifytoken';
export const API_URL_RECOVER = API_URL + 'recover';
export const API_URL_REFRESH = API_URL + 'refresh';
export const API_URL_CHANGE_PASSWORD = API_URL + 'changepassword';

export const API_URL_MULTISESSION = API_URL + 'multisession';

//export const API_URL_SERVICES = API_URL + 'services';
//export const API_URL_ALL_SERVICES = API_URL + 'allservices';
export const API_URL_ORGANIZATION = API_URL + 'organization';
export const API_URL_ORGANIZATIONS = API_URL + 'organizations';
export const API_URL_ORGANIZATIONS_LIST = API_URL + 'organizationslist';
export const API_URL_SERVICES_LIST = API_URL + 'servicesfactoriesorganizations';
export const API_URL_LIST_AGENCIES_USER = API_URL + 'listagenciesuser';
export const API_URL_LIST_SERVICES_AGENCY_USER = API_URL + 'servicesagencyuser';

export const API_URL_GROUPS = API_URL + 'groups';
export const API_URL_GROUPS_ALL = API_URL + 'groupslist';
export const API_URL_GROUPS_SERVICES = API_URL + 'servicesgroups';

export const API_URL_AGENCY = API_URL + 'agency';
export const API_URL_AGENCIES = API_URL + 'agencies';
export const API_URL_AGENCIES_SERVICES = API_URL + 'servicesagencies';

export const API_URL_FACTORIES = API_URL + 'factories';
export const API_URL_INFOIMAGES = API_URL + 'infoimages';

export const API_URL_INFOIMAGES_ALL = API_URL + 'allinfoimages';

export const API_URL_FACTORIES_LIST = API_URL + 'factorieslist';

export const API_URL_PRODUCTS = API_URL + 'products';

export const API_URL_DISCOUNTS = API_URL + 'discounts';
export const API_URL_DISCOUNTS_RULES = API_URL + 'discountsrules';

export const API_URL_CONVENTIONS = API_URL + 'conventions';
export const API_URL_CONVENTIONS_RULES = API_URL + 'conventionsrules';

export const API_URL_PAYMENTS = API_URL + 'paymentsystems';
export const API_URL_PAYMENTS_RULES = API_URL + 'paymentsystemsrules';

export const API_URL_GOODS = API_URL + 'goods';
export const API_URL_ALLGOODS = API_URL + 'allgoods';

export const API_URL_ALLPRODUCTS = API_URL + 'allproducts';

export const API_URL_CATEGORIES = API_URL + 'categories';
export const API_URL_ALLCATEGORIES = API_URL + 'allcategories';

export const API_URL_ALLLINKS = API_URL + 'alllinks';

export const API_URL_SERVICESPRODCONN = API_URL + 'servicesprodconn';

export const API_URL_GUARANTEES = API_URL + 'guarantees';
export const API_URL_ALLGUARANTEES = API_URL + 'allguarantees';
export const API_URL_SELECTGUARANTEES = API_URL + 'selectguarantees';

export const API_URL_FACTORIES_ORGANIZATIONS = API_URL + 'factoriesorganizations';
export const API_URL_FACTORIES_PRODUCTS = API_URL + 'factoriesproducts';
export const API_URL_FACTORIES_GUARANTEES = API_URL + 'factoriesguarantees';

export const API_URL_QUOTATION_LIST = API_URL + 'quotation';
export const API_URL_QUOTATION_UPDATE = API_URL + 'quotationupdate';

export const API_URL_SAVE_NOTIFICATION = API_URL + 'savenotification';

export const API_URL_QUOTATION_PAYMENTS = API_URL + 'quotationpayments';

export const API_URL_QUOTATION_LIST_BY_DATE = API_URL + 'quotationbydate';

export const API_URL_USERS = API_URL + 'users';
export const API_URL_AGENCY_USERS = API_URL + 'agencyusers';

export const API_URL_USERS_PERMISSIONS = API_URL + 'userspermissions';
export const API_URL_USERS_SERVICES = API_URL + 'usersservice';

export const API_URL_SERVICES_SHARE = API_URL + 'servicesshare';
export const API_URL_SICANIA_SHARE = API_URL + 'sicaniashare';

export const API_URL_CONNECTORS = API_URL + 'connectors';

export const API_URL_SICANIA = API_URL + 'sicania';
export const API_URL_SICANIA_LIST = API_URL + 'sicanialist';

export const API_URL_CHECK = API_URL + 'organizationscheck?applicationToken=' + APP_TOKEN_QUOTIAMO;
export const API_URL_SICANIA_CHECK = API_URL + 'sicaniacheck';

// FACILE
export const API_URL_INSERT_RE = API_URL + 'insert/elephant';
export const API_URL_INSERT_MOTOR = API_URL + 'insert/shark';

//export const SSO_URL= "https://810-dev.finital.it:4443/web/apiboot/public/api/";
export const SSO_URL = "https://api.recrea.srl/web/apiboot/public/api/";
//export const SSO_URL = "https://810.finital.it:4443/web/apiboot/public/api/";
export const APP_TOKEN_810 = 'MWJlNmJlMGY4NzgxODA1Yzg5NDEwNDVjODdlZjg1MGFhNDgxMWEyNQ';
export const AUTH_URL = SSO_URL + 'user/checklogin';
export const AUTH_URL_TOKEN = SSO_URL + 'user/createtoken';
export const AUTH_URL_CHECK_TOKEN = SSO_URL + 'user/checktoken';
export const AUTH_URL_DESTROY_TOKEN = SSO_URL + 'user/destroytoken';

export const REGISTRY_URL = "https://api.recrea.srl/web/apianagrafica/public/api/beni/ricerca/codiceCircolazione";
//https://api-dev.recrea.srl/web/apianagrafica/public/api/beni/ricerca/codiceCircolazione

//export const API_URL_SERVICE = 'http://192.168.55.140:8080';
//export const API_URL_SERVICE = 'http://server1011.lab4it.net:8080/Quotiamo/';
//export const API_URL_SERVICE = 'http://localhost:8080/';
export const API_URL_SERVICE_AUTH = '/authenticate';
//export const API_URL_SERVICE_SICANIA=  '/getdata/scrape/sicania';
export const API_URL_SERVICE_SICANIA = '/getdata';
export const API_URL_SERVICE_DATA_SICANIA = '/getdatasicania';
export const API_URL_QUOTATION = '/getquotation';
export const API_URL_QUOTATION_HOUSE = '/re/casa';
export const API_URL_QUOTATION_RE = '/re';

export const API_URL_SAVE_POLICY = '/savepolicy';
export const API_URL_GET_PROFESSIONS = '/utils/getprofessions';

export const API_URL_GET_BRAND = '/utils/getbrand';
export const API_URL_GET_MODEL = '/utils/getmodel';
export const API_URL_GET_OUTFITTING = '/utils/getoutfitting';

export const API_URL_GET_CVT = '/utils/getcvt';
export const API_URL_GET_INFOBIRTH = '/utils/getinfobirth';
export const API_URL_GET_INFOCITYBYCITY = '/utils/getinfocitybycity';

export const ADMIN = 8;
export const ADMIN_TEST = 9;
export const SUPER_ADMIN = 10;
export const IGM = 6; //INSURANCE GENERAL MANAGER

export const DAYS_BEFORE_QUOTATION = 60;
export const DAYS_EXPIRE_QUOTATION = 7;

export const opzioniGfDanTerrScoperto = [
    {
        value: 10000,
        label: "€ 10.000"
    },
    {
        value: 25000,
        label: "€ 25.000"
    }
];

export const tipologiaCostruzioneListGf = [

    {
        value: 'A',
        label: 'Antisismica'
    },
    {
        value: 'C',
        label: 'Cemento armato'
    },
    {
        value: 'B',
        label: 'Bioedilizia'
    },
    {
        value: 'M',
        label: 'Muratura'
    },
    {
        value: 'L',
        label: 'Chalet in legno'
    }
];

export const domandeQuestionarioGf = [
    {
        type: 'select',
        value: 'gf_domanda_1',
        description: "1) Il rischio e' conforme alle dichiarazioni di polizza in base alle quali la Società ha determinato i relativi premi?"
    },
    {
        type: 'select',
        value: 'gf_domanda_2',
        description: "2) Il fabbricato contiene industrie, cinematografi, teatri, discoteche, sale da ballo, sale giochi, depositi agricoli o di infiammabili, sedi di partiti o associazioni politiche?"
    },
    {
        type: 'select',
        value: 'gf_domanda_3',
        description: "3) Il fabbricato presenta problemi di statica e manutenzione?"
    },
    {
        type: 'select',
        value: 'gf_domanda_4',
        description: "4) Negli ultimi tre anni ha subito danni o è stato coinvolto in più di un sinistro relativamente ai rischi assicurati dalla presente polizza?"
    },
    {
        type: 'text',
        value: 'gf_domanda_4_1',
        description: "Specificare quali"
    },
    {
        type: 'select',
        value: 'gf_domanda_5',
        description: "5) Ha altre assicurazioni per gli stessi rischi?"
    },
    {
        type: 'text',
        value: 'gf_domanda_5_1',
        description: "Specificare quali"
    },
    {
        type: 'select',
        value: 'gf_domanda_6',
        description: "6) Negli ultimi 3 anni sono state annullate o rifiutate alla contraente coperture assicurative relative ai medesimi rischi assicurati con la presente polizza?"
    }
];

export const tettoGf = [
    {
        value: "T",
        label: "Tegole"
    },
    {
        value: "C",
        label: "Cemento"
    },
    {
        value: "A",
        label: "Altro"
    }
];

export const pannelliGf = [
    {
        value: 1,
        label: "Si"
    },
    {
        value: 3,
        label: "No"
    }
];

export const impElettricoGf = [
    {
        value: 1,
        label: "Si"
    },
    {
        value: 2,
        label: "No"
    }
];

export const impIdraulicoGf = [
    {
        value: 1,
        label: "Si"
    },
    {
        value: 2,
        label: "No"
    }
];

export const cappottoGf = [
    {
        value: 1,
        label: "Si"
    },
    {
        value: 7,
        label: "No"
    }
];

export const franchigiaFrontaleGf = [
    {
        value: 1,
        label: "€ 150"
    },
    {
        value: 2,
        label: "€ 300"
    },
    {
        value: 3,
        label: "€ 500"
    },
    {
        value: 4,
        label: "€ 750"
    },
    {
        value: 5,
        label: "€ 1.000"
    },
    {
        value: 6,
        label: "€ 1.500"
    },
    {
        value: 7,
        label: "€ 2.000"
    },
    {
        value: 8,
        label: "€ 2.500"
    },
    {
        value: 9,
        label: "€ 3.000"
    }
];

export const professioniRcp = [
    {
        value: "1",
        label: "Ingegneri"
    },
    {
        value: "2",
        label: "Architetti"
    },
    {
        value: "3",
        label: "Geometri"
    },
    {
        value: "4",
        label: "Commercialisti"
    }
];

export const fasciaFatturatoRcp = [
    {
        value: "1",
        label: "Fino a € 50.000"
    },
    {
        value: "2",
        label: "Superiore a € 50.000 e fino a € 100.000"
    },
    {
        value: "3",
        label: "Superiore a € 100.000 e fino a € 150.000"
    },
    {
        value: "4",
        label: "Superiore a € 150.000 e fino a € 200.000"
    },
    /*{
        value: "5",
        label: "Superiore a € 200.000"
    }*/
];

export const massimaliRcp = [
    {
        value: "1",
        label: "€ 250.000"
    },
    {
        value: "2",
        label: "€ 500.000"
    },
    {
        value: "3",
        label: "€ 1.000.000"
    },
    {
        value: "4",
        label: "€ 1.500.000"
    },
    {
        value: "5",
        label: "€ 2.000.000"
    },
    {
        value: "6",
        label: "€ 2.500.000"
    },
    {
        value: "7",
        label: "€ 3.000.000"
    }
];

export const professioniInjuries = [
    {
        value: "014713",
        label: "999A - Iscritti al sindacato SINALP"
    },
    {
        value: "000469",
        label: "Abbattitori di piante, boscaioli"
    },
    {
        value: "000470",
        label: "Addetti a centri elaborazione dati"
    },
    {
        value: "000471",
        label: "Addetti agli zoo"
    },
    {
        value: "000472",
        label: "Addetti in imprese di pulizia con uso di impalcature esterne"
    },
    {
        value: "000473",
        label: "Addetti in imprese di pulizia senza uso di impalcature esterne"
    },
    {
        value: "000474",
        label: "Addetti in lavanderia, pulisecco, tintorie e stirerie"
    },
    {
        value: "000475",
        label: "Agenti di cambio o di borsa, agenti immobiliari, promotori finanziari"
    },
    {
        value: "000476",
        label: "Agenti di commercio"
    },
    {
        value: "000477",
        label: "Agenti/ispettori di assicurazione"
    },
    {
        value: "000478",
        label: "Agenzia di recapito (fattorini)"
    },
    {
        value: "000479",
        label: "Agenzia di recapito (impiegati)"
    },
    {
        value: "000480",
        label: "Agricoltori (proprietari, affittuari, ecc.) che non prendono parte ai lavori manuali"
    },
    {
        value: "000481",
        label: "Agricoltori che lavorano manualmente, braccianti agricoli"
    },
    {
        value: "000482",
        label: "Agronomi"
    },
    {
        value: "000483",
        label: "Albergatori con prestazioni manuali"
    },
    {
        value: "000484",
        label: "Albergatori senza prestazioni manuali"
    },
    {
        value: "000485",
        label: "Allenatori sportivi"
    },
    {
        value: "000486",
        label: "Allevatori di altri animali, pastori"
    },
    {
        value: "000487",
        label: "Allevatori di equini, bovini, suini che non prestano opera manuale"
    },
    {
        value: "000488",
        label: "Allevatori di equini, bovini, suini che prestano opera manuale"
    },
    {
        value: "000489",
        label: "Altri artigiani con uso di macchine"
    },
    {
        value: "000490",
        label: "Altri artigiani senza uso di macchine"
    },
    {
        value: "014245",
        label: "Altro A"
    },
    {
        value: "014246",
        label: "Altro B"
    },
    {
        value: "014247",
        label: "Altro C"
    },
    {
        value: "014248",
        label: "Altro D"
    },
    {
        value: "000491",
        label: "Amministratori di beni propri o altrui"
    },
    {
        value: "000492",
        label: "Analisti chimici"
    },
    {
        value: "000493",
        label: "Antennisti (installatori di antenne radio-TV)"
    },
    {
        value: "000494",
        label: "Antiquari senza restauro"
    },
    {
        value: "000495",
        label: "Architetti che accedono anche ai cantieri"
    },
    {
        value: "000496",
        label: "Architetti occupati prevalentemente all'esterno ma senza accesso ai cantieri"
    },
    {
        value: "000497",
        label: "Architetti occupati prevalentemente in ufficio"
    },
    {
        value: "000498",
        label: "Argentieri/doratori"
    },
    {
        value: "000499",
        label: "Artisti di varieta', attori, musicisti, cantanti, agenti teatrali, registi, sceneggiatori"
    },
    {
        value: "000500",
        label: "Assistenti sociali"
    },
    {
        value: "000501",
        label: "Attrezzisti, macchinisti (settore spettacoli)"
    },
    {
        value: "000502",
        label: "Autisti di autobus, autocarri, motocarri"
    },
    {
        value: "000503",
        label: "Autisti di autovetture in servizio pubblico o privato, taxi, autoambulanza, autofunebri"
    },
    {
        value: "000504",
        label: "Autorimesse: addetti a lavori di riparazione, manutenzione, lavaggio ecc."
    },
    {
        value: "000505",
        label: "Autorimesse: esercenti che non lavorano manualmente"
    },
    {
        value: "000506",
        label: "Autoriparatore (carrozzieri o meccanici)"
    },
    {
        value: "000507",
        label: "Avvocati e procuratori legali"
    },
    {
        value: "000508",
        label: "Ballerini"
    },
    {
        value: "000509",
        label: "Bambinaie, baby-sitter, collaboratori familiari"
    },
    {
        value: "000510",
        label: "Bambini (0-13 anni)"
    },
    {
        value: "000511",
        label: "Bar, caffe', bottiglierie, proprietari/addetti a birrerie, enoteche, paninoteche"
    },
    {
        value: "000512",
        label: "Barbieri, parrucchieri da donna"
    },
    {
        value: "000513",
        label: "Benestanti senza particolari occupazioni"
    },
    {
        value: "000514",
        label: "Bidelli, custodi di musei"
    },
    {
        value: "000515",
        label: "Biologi/addetti a laboratori di analisi mediche"
    },
    {
        value: "000516",
        label: "Calciatori di altre serie o divisioni"
    },
    {
        value: "000517",
        label: "Calciatori di serie A-B-C"
    },
    {
        value: "000518",
        label: "Callisti, manicure, pedicure, estetisti"
    },
    {
        value: "000519",
        label: "Calzolai"
    },
    {
        value: "000520",
        label: "Camerieri"
    },
    {
        value: "000521",
        label: "Carabinieri, guardie di finanza, guardie doganali, guardie portuali, pubblica sicurezza (pers amm)"
    },
    {
        value: "000522",
        label: "Carabinieri, guardie di finanza, guardie doganali, guardie portuali, pubblica sicurezza (pers opera)"
    },
    {
        value: "000523",
        label: "Carpentieri in legno o ferro"
    },
    {
        value: "000524",
        label: "Casalinghe"
    },
    {
        value: "000525",
        label: "Casari"
    },
    {
        value: "000526",
        label: "Cave a giorno con o senza uso di mine: proprietari o addetti che non lavorano manualmente"
    },
    {
        value: "000527",
        label: "Cave a giorno:proprietari o addetti che lavorano manualmente"
    },
    {
        value: "000528",
        label: "Certificatori di bilancio e revisori dei conti"
    },
    {
        value: "000529",
        label: "Ciclisti: corridori dilettanti"
    },
    {
        value: "000530",
        label: "Ciclisti: corridori professionisti"
    },
    {
        value: "000531",
        label: "Clero (appartenenti al)"
    },
    {
        value: "000532",
        label: "Collaudatori di veicoli e natanti a motore"
    },
    {
        value: "000533",
        label: "Commercialisti, consulenti del lavoro"
    },
    {
        value: "000534",
        label: "Concessionari auto-motoveicoli"
    },
    {
        value: "000535",
        label: "Conciai, pellettieri, pellicciai"
    },
    {
        value: "000536",
        label: "Corniciai"
    },
    {
        value: "000537",
        label: "Cuochi"
    },
    {
        value: "000538",
        label: "Custodi/portieri di stabili, alberghi, musei e simili, uscieri"
    },
    {
        value: "000539",
        label: "Demolitori di autoveicoli"
    },
    {
        value: "000540",
        label: "Diplomatici, politici, sindacalisti"
    },
    {
        value: "000541",
        label: "Dirigenti occupati anche all'esterno con accesso a cantieri, ponti, impalcature ecc."
    },
    {
        value: "000542",
        label: "Dirigenti occupati anche all'esterno escluso accesso a cantieri, ponti, impalcature ecc."
    },
    {
        value: "000543",
        label: "Dirigenti occupati solo in ufficio"
    },
    {
        value: "000544",
        label: "Disegnatori che frequentano anche ambienti di lavoro"
    },
    {
        value: "000545",
        label: "Disegnatori occupati solo in ufficio"
    },
    {
        value: "000546",
        label: "Distributori automatici di carburante (gestori di) con operazioni di manutenzione, lavaggio e simili"
    },
    {
        value: "000547",
        label: "Distributori automatici di carburante (gestori di)senz operazioni di manutenzione, lavaggio e simili"
    },
    {
        value: "000548",
        label: "Domestici"
    },
    {
        value: "000550",
        label: "Elettrauto"
    },
    {
        value: "000552",
        label: "Elettricisti che lavorano anche all'esterno di edifici ed a contatto anche correnti a alta tensione"
    },
    {
        value: "014317",
        label: "Elettricisti che lavorano solo all'interno di edifici ed a contatto con correnti a bassa tensione"
    },
    {
        value: "000553",
        label: "Enologi ed enotecnici"
    },
    {
        value: "000554",
        label: "Esercito, marina militare e aviazione"
    },
    {
        value: "000560",
        label: "Esercizi commeciali Casalinghi, armi"
    },
    {
        value: "000556",
        label: "Esercizi commerciali Abiti, confezioni, mercerie, pellicce e tessuti"
    },
    {
        value: "000557",
        label: "Esercizi commerciali Articoli e apparecchi fotografici e di ottica, dischi, musica, strumenti musica"
    },
    {
        value: "000558",
        label: "Esercizi commerciali Articoli in pelle, calzature"
    },
    {
        value: "000559",
        label: "Esercizi commerciali Cartolerie, librerie, edicole"
    },
    {
        value: "000563",
        label: "Esercizi commerciali Ferramenta, colori e vernici, accessori per autoveicoli"
    },
    {
        value: "000564",
        label: "Esercizi commerciali Frutta e verdura, fiori e piante"
    },
    {
        value: "000565",
        label: "Esercizi commerciali Giocattoli, articoli sportivi"
    },
    {
        value: "000555",
        label: "Esercizi commerciali Rivendita pane, latterie, pasticcerie e gelaterie senza produzione propria"
    },
    {
        value: "000561",
        label: "Esercizi commerciali diversi da quelli elencati con installazione esclusa la posa di antenne"
    },
    {
        value: "000562",
        label: "Esercizi commerciali diversi da quelli elencati esclusa installazione esclusa la posa di antenne"
    },
    {
        value: "000566",
        label: "Fabbri anche su impalcature e ponti"
    },
    {
        value: "000567",
        label: "Fabbri solo a terra"
    },
    {
        value: "000568",
        label: "Facchini (portabagagli di piccoli colli)"
    },
    {
        value: "000569",
        label: "Facchini addetti al carico e scarico di merci in genere (portuali, doganali, ecc)"
    },
    {
        value: "000570",
        label: "Falegnami"
    },
    {
        value: "000571",
        label: "Fantini"
    },
    {
        value: "000572",
        label: "Farmacie e Farmacisti"
    },
    {
        value: "000573",
        label: "Floricultori"
    },
    {
        value: "000574",
        label: "Fonditori"
    },
    {
        value: "000575",
        label: "Fotografi anche all'esterno"
    },
    {
        value: "000576",
        label: "Fotografi solo in studio"
    },
    {
        value: "000577",
        label: "Geologi"
    },
    {
        value: "000578",
        label: "Geometri che accedono anche ai cantieri"
    },
    {
        value: "000579",
        label: "Geometri occupati anche all'esterno ma senza accesso ai cantieri"
    },
    {
        value: "000580",
        label: "Geometri occupati solo in ufficio"
    },
    {
        value: "000581",
        label: "Giardinieri, vivaisti, floricoltori con potature e messa a dimora di piante ad alto fusto"
    },
    {
        value: "000582",
        label: "Giornalisti (cronisti, corrispondenti)"
    },
    {
        value: "000583",
        label: "Gommisti/vulcanizzatori"
    },
    {
        value: "000584",
        label: "Grafici"
    },
    {
        value: "000585",
        label: "Guardiacaccia, guardiapesca, guardie campestri, guardie forestali"
    },
    {
        value: "000586",
        label: "Guardie notturne, giurate, guardie armate, portavalori, investigatori privati, guardie del corpo"
    },
    {
        value: "000587",
        label: "Guide turistiche"
    },
    {
        value: "000588",
        label: "Idraulici solo all'interno di edifici o a terra"
    },
    {
        value: "000589",
        label: "Imbianchini anche all'esterno di edifici e con uso di scale, impalcature e ponti"
    },
    {
        value: "000590",
        label: "Imbianchini solo all'interno di edifici e senza l'uso di lunghe scale, impalcature e ponti"
    },
    {
        value: "000591",
        label: "Impiegati amministrativi in genere"
    },
    {
        value: "000592",
        label: "Impiegati in agenzie d'affari, di pubblicita' e di viaggi"
    },
    {
        value: "000593",
        label: "Impiegati tecnici (anche Architetti, Ingegneri, Geometri, Farmacisti, Commessi)"
    },
    {
        value: "000594",
        label: "Imprenditori edili che possono prendere parte ai lavori"
    },
    {
        value: "000595",
        label: "Imprenditori in genere che non lavorano manualmente"
    },
    {
        value: "000596",
        label: "Imprenditori in genere che possono prendere parte ai lavori"
    },
    {
        value: "000597",
        label: "Incisori/coniatori"
    },
    {
        value: "000598",
        label: "Indossatori e modelli"
    },
    {
        value: "000314",
        label: "Infermiere diplomato"
    },
    {
        value: "000600",
        label: "Ingegneri che accedono anche ai cantieri"
    },
    {
        value: "000601",
        label: "Ingegneri occupati anche all'esterno ma senza accesso ai cantieri"
    },
    {
        value: "000602",
        label: "Ingegneri occupati solo in ufficio"
    },
    {
        value: "000603",
        label: "Insegnanti di alpinismo, guide alpine"
    },
    {
        value: "000604",
        label: "Insegnanti di educazione fisica, sci, basket, tennis, scherma, ballo, atletica leggera"
    },
    {
        value: "000605",
        label: "Insegnanti di equitazione, insegnanti hockey e rugby"
    },
    {
        value: "000606",
        label: "Insegnanti di judo, karate' od altri similari, insegnanti di atletica pesanti ed arti marziali"
    },
    {
        value: "000607",
        label: "Insegnanti di materie non sperimentali e non di pratica professionale"
    },
    {
        value: "000608",
        label: "Insegnanti di materie sperimentali anche in laboratorio o di pratica professionale"
    },
    {
        value: "000609",
        label: "Insegnanti di nuoto e bagnini"
    },
    {
        value: "000610",
        label: "Istruttori di pratica (guida) di scuola guida"
    },
    {
        value: "000611",
        label: "Istruttori di teoria di scuola guida"
    },
    {
        value: "000612",
        label: "Lattonieri anche su impalcature e ponti, Idraulici anche all'esterno"
    },
    {
        value: "000613",
        label: "Liberi professionisti (non elencati)"
    },
    {
        value: "000614",
        label: "Litografi e tipografi"
    },
    {
        value: "000615",
        label: "Magistrati"
    },
    {
        value: "000616",
        label: "Magliaie"
    },
    {
        value: "000617",
        label: "Marinai - con uso di macchine"
    },
    {
        value: "000618",
        label: "Marinai - senza uso di macchine"
    },
    {
        value: "000619",
        label: "Marmisti (posatore) solo a terra"
    },
    {
        value: "000620",
        label: "Marmisti anche su impalcature e ponti"
    },
    {
        value: "000621",
        label: "Massaggiatori, fisioterapisti"
    },
    {
        value: "000622",
        label: "Materassai"
    },
    {
        value: "000623",
        label: "Mediatori di bestiame"
    },
    {
        value: "000624",
        label: "Mediatori in genere (escluso bestiame)"
    },
    {
        value: "000625",
        label: "Medici(per i radiologi sono esclusi gli effetti sia interni che esterni delle emanazioni radiattive)"
    },
    {
        value: "000626",
        label: "Mobilieri: fabbricazione mobili in ferro"
    },
    {
        value: "000627",
        label: "Mobilieri: fabbricazione mobili in legno"
    },
    {
        value: "000628",
        label: "Muratori, piastrellisti, pavimentatori, palchettisti"
    },
    {
        value: "000629",
        label: "Notai"
    },
    {
        value: "000630",
        label: "Odontotecnici"
    },
    {
        value: "000631",
        label: "Operai (dipendenti) e lavoratori dipendenti diversi da impiegati, quadri e dirigenti"
    },
    {
        value: "000669",
        label: "Operai (dipendenti) e lavoratori diversi da impiegati senza uso di macchine"
    },
    {
        value: "000632",
        label: "Oreficerie, orologerie, gioiellerie"
    },
    {
        value: "000633",
        label: "Ortopedici (fabbricanti di apparecchi)"
    },
    {
        value: "000634",
        label: "Ostetriche"
    },
    {
        value: "000635",
        label: "Palombari/sommozzatori"
    },
    {
        value: "000636",
        label: "Panetterie, pasticcerie gelaterie: per tutti compresa produzione propria"
    },
    {
        value: "000637",
        label: "Pensionati"
    },
    {
        value: "000638",
        label: "Periti liberi professionisti oaccupati anche all'esterno"
    },
    {
        value: "000639",
        label: "Personale del circo, giostre e simili"
    },
    {
        value: "000640",
        label: "Personale viaggiante dell'aviazione civile"
    },
    {
        value: "000641",
        label: "Pescatori (pesca marittima costiera)"
    },
    {
        value: "000642",
        label: "Piazzisti, Rappresentanti, Commessi viaggiatori con trasporto e consegna merce, Venditori ambulanti"
    },
    {
        value: "000643",
        label: "Piazzisti, Rappresentanti, Commessi viaggiatori senza trasporto e consegna merce"
    },
    {
        value: "000644",
        label: "Restauratori ed antiquari con restauro (esclusi mobili e senza uso di impalcature)"
    },
    {
        value: "000645",
        label: "Restauratori ed antiquari con restauro con uso di impalcature"
    },
    {
        value: "000646",
        label: "Restauratori ed antiquari con restauro di mobili"
    },
    {
        value: "000647",
        label: "Riparatori elettrodomestici e computer"
    },
    {
        value: "000648",
        label: "Ristoranti, trattorie, pizzerie"
    },
    {
        value: "000649",
        label: "Saldatori"
    },
    {
        value: "000650",
        label: "Salumerie, rosticcerie, macellerie, pescherie"
    },
    {
        value: "000651",
        label: "Sarti"
    },
    {
        value: "000652",
        label: "Scrittori"
    },
    {
        value: "000653",
        label: "Scultori/Intagliatori"
    },
    {
        value: "000654",
        label: "Speleologi"
    },
    {
        value: "000655",
        label: "Sportivi dilettanti (no calciatori ne ciclisti)"
    },
    {
        value: "000656",
        label: "Sportivi professionisti (no calciatori ne ciclisti)"
    },
    {
        value: "000657",
        label: "Studenti (&gt;13 anni) di scuola professionale"
    },
    {
        value: "000658",
        label: "Studenti (&gt;13 anni) non di scuola professionale"
    },
    {
        value: "000659",
        label: "Tabaccherie, profumerie, drogherie, vini e liquori"
    },
    {
        value: "000660",
        label: "Tappezzieri"
    },
    {
        value: "000661",
        label: "Topografi"
    },
    {
        value: "000662",
        label: "Tornitori"
    },
    {
        value: "000663",
        label: "Veterinari"
    },
    {
        value: "000664",
        label: "Vetrai"
    },
    {
        value: "000665",
        label: "Vetrinisti"
    },
    {
        value: "000666",
        label: "Vigili del fuoco"
    },
    {
        value: "000667",
        label: "Vigili urbani"
    }
];

export const opzioniMesiDiGuidaMotorBike = [
    {
        value: "1",
        label: "Meno di 3 mesi"
    },
    {
        value: "2",
        label: "4 - 6 mesi"
    },
    {
        value: "3",
        label: "7 - 9 mesi"
    },
    {
        value: "4",
        label: "10 - 12 mesi"
    },
    {
        value: "5",
        label: "13 - 18 mesi"
    },
    {
        value: "6",
        label: "19 - 24 mesi"
    },
    {
        value: "7",
        label: "Più di 24 mesi"
    }
];

export const opzioniCCDrivedMotorBike = [
    {
        value: "50",
        label: "50cc"
    },
    {
        value: "100",
        label: "100cc"
    },
    {
        value: "125",
        label: "125cc"
    },
    {
        value: "150",
        label: "150cc"
    },
    {
        value: "200",
        label: "200cc"
    },
    {
        value: "250",
        label: "250cc"
    },
    {
        value: "300",
        label: "300cc"
    },
    {
        value: "400",
        label: "400cc"
    },
    {
        value: "450",
        label: "450cc"
    },
    {
        value: "500",
        label: "500cc"
    },
    {
        value: "550",
        label: "550cc"
    },
    {
        value: "600",
        label: "600cc"
    },
    {
        value: "650",
        label: "650cc"
    },
    {
        value: "750",
        label: "750cc"
    },
    {
        value: "900",
        label: "900cc"
    },
    {
        value: "1000",
        label: "1000cc"
    },
    {
        value: "1200",
        label: "1200cc"
    },
    {
        value: "1500",
        label: "1500cc"
    }
];

export const opzioniInabilitaTempInjuries = [
    {
        value: 0,
        label: "Nessuna"
    },
    {
        value: 7,
        label: "7 giorni"
    },
    {
        value: 10,
        label: "10 giorni"
    },
    {
        value: 15,
        label: "15 giorni"
    },
    {
        value: 30,
        label: "30 giorni"
    }
];

export const opzioniInvaliditaPermInjuries = [
    {
        value: 0,
        label: "BASE - 3% fino a € 150k"
    },
    {
        value: 1,
        label: "I - ridotta o azzerata fino a € 150k in funzione del capitale"
    },
    {
        value: 2,
        label: "II - ridotta o azzerata fino a € 150k e ridotta sui successivi scaglioni in funzione del capitale"
    },
    {
        value: 3,
        label: "III - 5% fino a € 150k e supervalutazione oltre il 50%"
    },
    {
        value: 4,
        label: "IV - 10% fino a € 400k e supervalutazione oltre il 50%"
    },
    {
        value: 5,
        label: "V - 20% sul capitale e supervalutazione oltre il 50%"
    }
];

export const clausoleInjuries = [
    {
        value: "A",
        label: "A - Tabella INAIL"
    },
    {
        value: "B",
        label: "B - Assorbimento franchigia su I.P."
    },
    {
        value: "C",
        label: "C - Sopravvalutazione valori di liquidazione"
    },
    {
        value: "D",
        label: "D - Tabella I.P. con supervalutazione oltre 65%"
    },
    {
        value: "E",
        label: "E - Tabella I.P. modulare"
    },
    {
        value: "F",
        label: "F - Supervalutazione arti superiori"
    },
    {
        value: "G",
        label: "G - Supervalutazione vista udito voce"
    },
    {
        value: "H",
        label: "H - Scalata di roccia e ghiaccio oltre il 3rd grado della scala monaco"
    },
    {
        value: "I",
        label: "I - Immersione con autorespiratore"
    },
    {
        value: "L",
        label: "L - Speleologia"
    },
    {
        value: "M",
        label: "M - Pugilato, atletica pesante, arti marziali, salto del trampolino con idrosci, rugby e football americano"
    },
    {
        value: "N",
        label: "N - Inabilità temporanea integrale"
    },
    {
        value: "O",
        label: "O - Supervalutazione inabilità temporanea"
    },
    {
        value: "P",
        label: "P - Estensione alle malattie"
    },
    {
        value: "Q",
        label: "Q - Assistenza domiciliare"
    },
    {
        value: "S",
        label: "S - Franchigia e supervalutazione di IP da Ictus o Infarto"
    },
];

export const kmAnnuiOption = [
    {
        id: '0',
        description: 'Seleziona i Km annui percorsi'
    },
    {
        id: '1',
        description: '0 - 10.000 Km'
    },

    {
        id: '2',
        description: '10.000 - 20.000 Km'
    },
    {
        id: '3',
        description: '20.000 - 30.000 Km'
    },
    {
        id: '4',
        description: 'Oltre 30.000 Km'
    }

];

export const drivingLicenseTypes = [
    {
        id: 'PIB',
        description: 'Patente B'
    },

    {
        id: 'PIAB',
        description: 'Patente A+B'
    },
    {
        id: 'PIT',
        description: 'Altro tipo di patente'
    }

];

export const attesaPrevTypes = [
    {
        id: 'waiting',
        description: 'Tradizionale - attesa live dei preventivi'
    },
    {
        id: 'background',
        description: 'Esecuzione in background dei preventivi'
    }
];

export const genders = [
    {
        value: '',
        label: ''
    },
    {
        value: 'M',
        label: 'Maschio'
    },
    {
        value: 'F',
        label: 'Femmina'
    }

];

export const tipoFrazionamento = [
    {
        value: 1,
        label: 'Annuale'
    },
    {
        value: 2,
        label: 'Semestrale'
    }
];

export const durationsList = [
    {
        value: 1,
        label: '1'
    },
    {
        value: 2,
        label: '2'
    },
    {
        value: 3,
        label: '3'
    }
    ,
    {
        value: 4,
        label: '4'
    }
    ,
    {
        value: 5,
        label: '5'
    }
    ,
    {
        value: 6,
        label: '6'
    }
    ,
    {
        value: 7,
        label: '7'
    }
    ,
    {
        value: 8,
        label: '8'
    },
    {
        value: 9,
        label: '9'
    },
    {
        value: 10,
        label: '10'
    }
];

export const durationsListTcm = [
    {
        value: 2,
        label: '2'
    },
    {
        value: 3,
        label: '3'
    },
    {
        value: 4,
        label: '4'
    },
    {
        value: 5,
        label: '5'
    },
    {
        value: 6,
        label: '6'
    },
    {
        value: 7,
        label: '7'
    },
    {
        value: 8,
        label: '8'
    },
    {
        value: 9,
        label: '9'
    },
    {
        value: 10,
        label: '10'
    },
    {
        value: 11,
        label: '11'
    },
    {
        value: 12,
        label: '12'
    },
    {
        value: 13,
        label: '13'
    },
    {
        value: 14,
        label: '14'
    },
    {
        value: 15,
        label: '15'
    },
    {
        value: 16,
        label: '16'
    },
    {
        value: 17,
        label: '17'
    },
    {
        value: 18,
        label: '18'
    },
    {
        value: 19,
        label: '19'
    },
    {
        value: 20,
        label: '20'
    },
    {
        value: 21,
        label: '21'
    },
    {
        value: 22,
        label: '22'
    },
    {
        value: 23,
        label: '23'
    },
    {
        value: 24,
        label: '24'
    },
    {
        value: 25,
        label: '25'
    },
    {
        value: 26,
        label: '26'
    },
    {
        value: 27,
        label: '27'
    },
    {
        value: 28,
        label: '28'
    },
    {
        value: 29,
        label: '29'
    },
    {
        value: 30,
        label: '30'
    },
];

export const beneficiariTcm = [
    {
        value: 11,
        label: 'Eredi legittimi dell\'assicurato'
    },
    {
        value: 1,
        label: 'Beneficiario individuato'
    },
    {
        value: 2,
        label: 'Eredi legittimi salvo contraria disposizione'
    },
    {
        value: 3,
        label: 'Coniuge dell\'ass. In sua mancanza i figli'
    },
    {
        value: 4,
        label: 'Coniuge dell\'assicurato'
    },
    {
        value: 5,
        label: 'Figli nati e nascituri dell\'assicurato'
    },
    {
        value: 6,
        label: 'Genitori dell\'ass. In loro mancanza i figli'
    },
    {
        value: 7,
        label: 'Fratelli dell\'assicurato'
    },
    {
        value: 10,
        label: 'Gli eredi testamentari o, in mancanza, gli eredi legittimi'
    },
    {
        value: 13,
        label: 'Il contraente'
    }
];

export const premioTcmEasy = [
    {
        value: 80,
        label: '80'
    },
    {
        value: 140,
        label: '140'
    },
    {
        value: 200,
        label: '200'
    }
];

export const livelliIstruzione = [
    {
        id: 1,
        description: 'Nessuno'
    },
    {
        id: 2,
        description: 'Licenza elementare'
    },
    {
        id: 3,
        description: 'Licenza media'
    },
    {
        id: 4,
        description: 'Diploma'
    },

    {
        id: 5,
        description: 'Laurea'
    },
    {
        id: 6,
        description: 'Master/dottorato'
    }

];

export const statoCivile = [

    {
        id: 4,
        description: 'Non dichiarato'
    },
    {
        id: 1,
        description: 'Coniugato/a'
    },
    {
        id: 2,
        description: 'Convivente'
    },
    {
        id: 3,
        description: 'Celibe/Nubile'
    }



];

export const ElencoMassimali = [
    {
        value: '0',
        label: 'Selezionare il massimale'
    },
    {
        value: '1',
        label: 'Minimo'
    },
    {
        value: '2',
        label: '€ 10.000.000'
    }
];

export const ElencoMassimaliAutocarri = [
    {
        value: '0',
        label: 'Selezionare il massimale'
    },
    {
        value: '3',
        label: '€ 26.000.000'
    },
];

export const tipoGuida = [
    {
        value: '0',
        label: 'Selezionare il tipo di guida'
    },
    {
        value: 'LIB',
        label: 'Libera'
    },
    {
        value: 'ESP',
        label: 'Esperta'
    }
];

export const etaGuidatorePiuGiovane = [
    {
        value: '0',
        label: 'Età del guidatore più giovane'
    },
    {
        value: '18',
        label: '18'
    },
    {
        value: '19',
        label: '19'
    },
    {
        value: '20',
        label: '20'
    },
    {
        value: '21',
        label: '21'
    },
    {
        value: '22',
        label: '22'
    },
    {
        value: '23',
        label: '23'
    },
    {
        value: '24',
        label: '24'
    },
    {
        value: '25',
        label: '25'
    },
    {
        value: '26',
        label: '26'
    },
    {
        value: '27',
        label: '27'
    },
    {
        value: '28',
        label: '28'
    },
    {
        value: '29',
        label: '29'
    },
    {
        value: '30',
        label: '30 o più'
    },
];

export const tipoAlimentazione = [
    {
        value: 'B',
        label: 'Benzina'
    },
    {
        value: 'D',
        label: 'Diesel'
    },
    {
        value: 'I',
        label: 'Ibrida'
    },
    {
        value: 'E',
        label: 'Elettrica'
    },
    {
        value: 'G',
        label: 'GPL'
    },
    {
        value: 'M',
        label: 'Metano'
    },
    {
        value: 'N',
        label: 'Non specificato'
    }
];

export const namemonths = [
    {
        value: '',
        label: ''
    },
    {
        value: 1,
        label: 'Gennaio'
    },
    {
        value: 2,
        label: 'Febbraio'
    },
    {
        value: 3,
        label: 'Marzo'
    },
    {
        value: 4,
        label: 'Aprile'
    }
    ,
    {
        value: 5,
        label: 'Maggio'
    }
    ,
    {
        value: 6,
        label: 'Giugno'
    }
    ,
    {
        value: 7,
        label: 'Luglio'
    }
    ,
    {
        value: 8,
        label: 'Agosto'
    }
    ,
    {
        value: 9,
        label: 'Settembre'
    }
    ,
    {
        value: 10,
        label: 'Ottobre'
    }
    ,
    {
        value: 11,
        label: 'Novembre'
    }
    ,
    {
        value: 12,
        label: 'Dicembre'
    }

];

export const subjectTypes = [
    {
        value: 1,
        label: 'Privato'
    },
    {
        value: 2,
        label: 'Lavoratore autonomo'
    },
    {
        value: 3,
        label: 'Società di persone'
    },
    {
        value: 4,
        label: 'Società'
    },
    {
        value: 5,
        label: 'Ente'
    },
    {
        value: 6,
        label: 'ATI'
    },
    {
        value: 7,
        label: 'Impresa individuale'
    },
    {
        value: 8,
        label: 'Condominio'
    }
];

export const useTypes = [
    {
        value: "",
        label: ''
    },
    {
        value: "P",
        label: 'PROPRIO'
    },
    {
        value: "T",
        label: 'CONTO TERZI'
    }
];

export const elencoCVTCar = [
    {
        value: 'rca',
        label: 'Responsabilità civile'
    },
    {
        value: 'assistenzaStradale',
        label: 'Assistenza Stradale'
    },
    {
        value: 'infortunioConducente',
        label: 'Infortuni Conducente'
    },
    {
        value: 'tutelaLegale',
        label: 'Tutela legale'
    },
    {
        value: 'furto',
        label: 'Furto e incendio'
    },
    {
        value: 'attiVandalici',
        label: 'Atti vandalici'
    },
    {
        value: 'eventiNaturali',
        label: 'Eventi naturali'
    },
    {
        value: 'cristalli',
        label: 'Cristalli'
    },
    {
        value: 'collisione',
        label: 'Collisione'
    },
    {
        value: 'kasko',
        label: 'Kasko'
    },
    {
        value: 'revengeWaiver',
        label: 'Rinuncia alla rivalsa'
    },

];

export const elencoCondizioniCar = [
    {
        value: 'AtrCheck',
        label: 'Numero anni di Verifica numero massimo sinistri (vedi parametro successivo)'
    },
    {
        value: 'AtrCheckNumber',
        label: 'Numero massimo sinistri (vedi parametro precedente)'
    },
    {
        value: 'AtrCheckNumberMin',
        label: 'Numero minimo sinistri (vedi parametro precedente)'
    },
    {
        value: 'AtrValue',
        label: 'Verifica da quanti anni l\'ATR è valorizzato'
    },
    {
        value: 'ProvinceList',
        label: 'Province abilitate (Elenco province separate da virgole Es: VR,AR,ROMA,RM...)'
    },
    {
        value: 'BirthNation',
        label: 'Nazione di nascita (2=Solo nazioni estere, 1=Solo Italia, 0=Tutti)'
    },
    {
        value: 'PaymentMode',
        label: 'Valido solo per pagamento (1=Annuale,2=Semestrale)'
    },
    {
        value: 'PrevType',
        label: 'Tipo preventivo (RIN=Rinnovo,PRI=Prima immatricolazione, VOL=Voltura)'
    },
    {
        value: 'anniVeicolo',
        label: 'Limite anni vetustà veicolo (> valore inserito espresso in anni)'
    },
    {
        value: 'pesoVeicolo',
        label: 'Intervallo peso (in KG, >= min e < max), valori divisi da "-" (solo AUTOCARRI)'
    },
];

export const elencoCondizioniHouse = [
    {
        value: 'ProvinceList',
        label: 'Province abilitate (Elenco province separate da virgole Es: VR,AR,ROMA,RM...)'
    },
    {
        value: 'BirthNation',
        label: 'Nazione di nascita (2=Solo nazioni estere, 1=Solo Italia, 0=Tutti)'
    },
    {
        value: 'PaymentMode',
        label: 'Valido solo per pagamento (1=Annuale,2=Semestrale)'
    }

];

export const elencoProvinceConvenzione = [
    "BO",
    "FE",
    "FC",
    "MO",
    "PR",
    "PC",
    "RA",
    "RE",
    "RN",
    "GO",
    "PN",
    "TS",
    "UD",
    "RI",
    "ROMA",
    "RM",
    "VT",
    "GE",
    "IM",
    "SP",
    "SV",
    "BG",
    "BS",
    "CO",
    "CR",
    "LC",
    "LO",
    "MN",
    "MI",
    "MB",
    "PV",
    "SO",
    "VA",
    "AN",
    "AP",
    "FM",
    "MC",
    "PU",
    "CB",
    "IS",
    "AL",
    "AT",
    "BI",
    "CN",
    "NO",
    "TO",
    "VB",
    "VC",
    "AR",
    "FI",
    "GR",
    "LI",
    "LU",
    "MS",
    "PI",
    "PT",
    "PO",
    "SI",
    "BZ",
    "TN",
    "PG",
    "TR",
    "AO",
    "BL",
    "PD",
    "RO",
    "TV",
    "VE",
    "VR",
    "VI"
];
/*
Bologna[8]	BO	 Emilia-Romagna
 Ferrara	FE	 Emilia-Romagna
 Forlì-Cesena	FC	 Emilia-Romagna
 Modena	MO	 Emilia-Romagna
 Parma	PR	 Emilia-Romagna
 Piacenza	PC	 Emilia-Romagna
 Ravenna	RA	 Emilia-Romagna
 Reggio Emilia	RE	 Emilia-Romagna
 Rimini	RN	 Emilia-Romagna
 Gorizia[15]	GO	 Friuli-Venezia Giulia
 Pordenone[15]	PN	 Friuli-Venezia Giulia
 Trieste[15]	TS	 Friuli-Venezia Giulia
 Udine[15]	UD	 Friuli-Venezia Giulia
 Rieti	RI	 Lazio
 Roma[8]	ROMA	 Lazio
 Viterbo	VT	 Lazio
 Genova[8]	GE	 Liguria
 Imperia	IM	 Liguria
 La Spezia	SP	 Liguria
 Savona	SV	 Liguria
 Bergamo	BG	 Lombardia
 Brescia	BS	 Lombardia
 Como	CO	 Lombardia
 Cremona	CR	 Lombardia
 Lecco	LC	 Lombardia
 Lodi	LO	 Lombardia
 Mantova	MN	 Lombardia
 Milano[8]	MI	 Lombardia
 Monza e Brianza	MB	 Lombardia
 Pavia	PV	 Lombardia
 Sondrio[9]	SO	 Lombardia
 Varese	VA	 Lombardia
 Ancona	AN	 Marche
 Ascoli Piceno	AP	 Marche
 Fermo	FM	 Marche
 Macerata	MC	 Marche
 Pesaro e Urbino	PU	 Marche
 Campobasso	CB	 Molise
 Isernia	IS	 Molise
 Alessandria	AL	 Piemonte
 Asti	AT	 Piemonte
 Biella	BI	 Piemonte
 Cuneo	CN	 Piemonte
 Novara	NO	 Piemonte
 Torino[8]	TO	 Piemonte
 Verbano-Cusio-Ossola[9]	VB	 Piemonte
 Vercelli	VC	 Piemonte
 Arezzo	AR	 Toscana
 Firenze[8]	FI	 Toscana
 Grosseto	GR	 Toscana
 Livorno	LI	 Toscana
 Lucca	LU	 Toscana
 Massa-Carrara	MS	 Toscana
 Pisa	PI	 Toscana
 Pistoia	PT	 Toscana
 Prato	PO	 Toscana
 Siena	SI	 Toscana
 Bolzano[10]	BZ	 Trentino-Alto Adige
 Trento[10]	TN	 Trentino-Alto Adige
 Perugia	PG	 Umbria
 Terni	TR	 Umbria
 Aosta[7]	AO	 Valle d'Aosta
 Belluno[9]	BL	 Veneto
 Padova	PD	 Veneto
 Rovigo	RO	 Veneto
 Treviso	TV	 Veneto
 Venezia[8]	VE	 Veneto
 Verona	VR	 Veneto
Vicenza	VI	 Veneto
*/

export const tipologiaCostruzioneList = [

    {
        value: 'A',
        label: 'Antisismica'
    },
    {
        value: 'C',
        label: 'Cemento armato'
    },
    {
        value: 'S',
        label: 'Strutture portanti diverse'
    }
];

export const classeCostruttivaList = [

    {
        value: "T",
        label: 'Tradizionale'
    },
    {
        value: "B",
        label: 'Bioedilizia'
    },
    {
        value: "C",
        label: 'Chalet'
    }
];

export const TipoDimoraList = [

    {
        value: "A",
        label: 'Abituale'
    },
    {
        value: "S",
        label: 'Saltuaria'
    }
];

export const PossessoList = [

    {
        value: "P",
        label: 'Proprietà'
    },
    {
        value: "A",
        label: 'In affitto'
    },
    {
        value: "LT",
        label: 'Locata a terzi'
    }
];

export const TipologiaList = [

    {
        value: "A",
        label: 'Appartamento'
    },
    {
        value: "V1",
        label: 'Villa a schiera'
    },
    {
        value: "V2",
        label: 'Villa singola'
    }
];

export const PianoList = [
    {
        value: 1,
        label: "Piano Terra/Interrato"
    },
    {
        value: 2,
        label: "Rialzato/Primo Piano"
    },
    {
        value: 3,
        label: "Piano Secondo e Superiori"
    }
];

export const PosizioneList = [
    {
        value: "NI",
        label: 'Non isolata'
    },
    {
        value: "I",
        label: 'Isolata'
    },

];

export const inputTypeLists = [
    {
        value: 0,
        description: 'Nessun valore richiesto'
    },
    {
        value: 1,
        description: 'Importo libero'
    },
    {
        value: 2,
        description: 'Elenco di valori'
    },
];

export const inputTipoCalcoloLists = [
    {
        value: 'perc',
        description: 'Calcolo percentuale'
    },
    {
        value: 'value',
        description: 'Calcolo con valore fisso'
    },
    {
        value: 'comp',
        description: 'Invio codice alla compagnia'
    },
    {
        value: 'default',
        description: 'Configurazione default delle garanzie'
    },
];

export const inputTipoCalcoloListsDefault = [
    {
        value: 'perc',
        description: 'Calcolo percentuale'
    },
    {
        value: 'value',
        description: 'Calcolo con valore fisso'
    },
    {
        value: 'comp',
        description: 'Invio codice alla compagnia'
    },
];

export const inputTipoArrotondamentoListsDefault = [
    {
        value: 'sup',
        description: 'Superiore'
    },
    {
        value: 'inf',
        description: 'Inferiore'
    },
    {
        value: 'mat',
        description: 'Matematico'
    },
    {
        value: 'no',
        description: 'No'
    },
];

export const domandeQuestionarioInfortunio = [
    {
        type: 'select',
        value: 'inf_domanda_1',
        description: "1) L'assicurato ha in corso analoghe coperture assicurative con altre compagnie? (Non e' necessario dichiarare le coperture assicurative discendenti da CCNL/contratti integrativi/accordi aziendali e quelle accessorie a servizi bancari/di finanziamento)"
    },
    {
        type: 'text',
        value: 'inf_domanda_1_1',
        description: "Quante analoghe assicurazioni? Indicare: Compagnia altre assicurazioni, Capitale morte altre assicurazioni, Capitale IP altre assicurazioni"
    },
    {
        type: 'select',
        value: 'inf_domanda_2',
        description: "2) L'assicurato ha avuto polizze analoghe annullate da altre compagnie nel corso degli ultimi 5 anni?"
    },
    {
        type: 'text',
        value: 'inf_domanda_2_1',
        description: "Quante polizze analoghe annullate da altre compagnie? Indicare: Compagnia analoghe assicurazioni annullate, Anno annullamento analoghe assicurazioni"
    },
    {
        type: 'select',
        value: 'inf_domanda_3',
        description: "3) L'assicurato ha avuto infortuni da cui siano residuati minorazioni funzionali o postumi invalidanti?"
    },
    {
        type: 'text',
        value: 'inf_domanda_3_1',
        description: "Quanti infortuni con minorazioni funzionali o postumi invalidanti? Indicare: Anno dell'infortunio, Percentuale di invalidita' residuata minorazioni funzionali (1): (da compilare per l'infortunio oggetto di indennizzo assicurativo e con percentuale di invalidita' residua di almeno 10 punti), Descrizione infortunio"
    },
];

export const domandeQuestionarioMalattia = [
    {
        type: 'select',
        value: 'mal_domanda_1',
        description: "1) Le è mai stata riconosciuta da una Società di assicurazione una invalidità permanente?"
    },
    {
        type: 'text',
        value: 'mal_domanda_1_1',
        description: "Specificare: percentuale di invalidità, anno, infermità, Società"
    },
    {
        type: 'select',
        value: 'mal_domanda_2',
        description: "2) Fruisce di pensione di Invalidità o ha presentato domanda per ottenerla?"
    },
    {
        type: 'text',
        value: 'mal_domanda_2_1',
        description: "Specificare: causa, percentuale di invalidità, anno, infermità, ente erogatore"
    },
    {
        type: 'select',
        value: 'mal_domanda_3',
        description: "3) E' stato giudicato invalido civile?"
    },
    {
        type: 'text',
        value: 'mal_domanda_3_1',
        description: "Specificare: percentuale di invalidità, anno, infermità, ente erogatore"
    },
    {
        type: 'select',
        value: 'mal_domanda_4',
        description: "4) Ha minorazioni funzionali od imperfezioni fisiche diverse da: miopia, astigmatismo, presbiopia, ipermetropia?"
    },
    {
        type: 'text',
        value: 'mal_domanda_4_1',
        description: "Specificare: cosa (udito, arti, altro da specificare...), da quando e di che grado"
    },
    {
        type: 'select',
        value: 'mal_domanda_5',
        description: "5) Ha subito in passato traumi o infortuni che abbiano residuato postumi?"
    },
    {
        type: 'text',
        value: 'mal_domanda_5_1',
        description: "Specificare: cosa (cranio, organi di senso, atri, altro da specificare...), da quando e di che grado"
    },
    {
        type: 'select',
        value: 'mal_domanda_6',
        description: "6) E' stato mai dichiarato non idoneo al lavoro?"
    },
    {
        type: 'text',
        value: 'mal_domanda_6_1',
        description: "Specificare: quando, motivo"
    },
    {
        type: 'select',
        value: 'mal_domanda_7',
        description: "7) Ha alterazioni del ciclo e/o disturbi genitali o malattie delle mammelle?"
    },
    {
        type: 'text',
        value: 'mal_domanda_7_1',
        description: "Specificare: quali"
    },
    {
        type: 'select',
        value: 'mal_domanda_8',
        description: "8) E' incinta?"
    },
    {
        type: 'text',
        value: 'mal_domanda_8_1',
        description: "Specificare: mese di gravidanza"
    },
    {
        type: 'select',
        value: 'mal_domanda_9',
        description: "9) Precedenti gravidanze che abbiano comportato aborti o parti con taglio cesareo?"
    },
    {
        type: 'text',
        value: 'mal_domanda_9_1',
        description: "Specificare: quante, quanti aborti, quanti parti cesarei"
    },
    {
        type: 'select',
        value: 'mal_domanda_10',
        description: "10) E' attualmente in terapia farmacologica continuativa da più di un mese?"
    },
    {
        type: 'text',
        value: 'mal_domanda_10_1',
        description: "Specificare: quale, da quando e per quale motivo"
    },
    {
        type: 'select',
        value: 'mal_domanda_11',
        description: "11) Soffre e/o è in trattamento per ipertensione arteriosa?"
    },
    {
        type: 'text',
        value: 'mal_domanda_11_1',
        description: "Specificare: da quando"
    },
    {
        type: 'select',
        value: 'mal_domanda_12',
        description: "12) Ha mai subito interventi chirurgici diversi da: appendicectomia, adenoidectomia, tonsillectomia?"
    },
    {
        type: 'text',
        value: 'mal_domanda_12_1',
        description: "Specificare: quale, da quando e per quale motivo"
    },
    {
        type: 'select',
        value: 'mal_domanda_13',
        description: "13) E' mai stato sottoposto a terapie radianti, ormonali, trattamenti con cellule staminali, emotrasfusioni e/o terapie a base di emoderivati?"
    },
    {
        type: 'text',
        value: 'mal_domanda_13_1',
        description: "Specificare: anno, tipo, durata, motivo"
    },
    {
        type: 'select',
        value: 'mal_domanda_14',
        description: "14) Ha praticato negli ultimi 5 anni esami e/o accertamenti diagnostici dai quali sia emersa qualche alterazione?"
    },
    {
        type: 'text',
        value: 'mal_domanda_14_1',
        description: "Specificare: che esame (elettrocardiogramma, radiografie, elettroencefalogramma, esami del sangue, anti-HIV, HBsAg (epatite), esami urine, biopsie, T.A.C, altri specificare...) e quale alterazione"
    },
    {
        type: 'select',
        value: 'mal_domanda_15',
        description: "15) Ha in programma ricoveri, day-hospital, interventi chirurgici, accertamenti diagnostici, cicli di terapia farmacologia o fisico riabilitativa?"
    },
    {
        type: 'text',
        value: 'mal_domanda_15_1',
        description: "Specificare: quando, tipo, motivi"
    },
    {
        type: 'select',
        value: 'mal_domanda_16',
        description: "16) Ha sofferto o soffre di malattie che abbiano comportato cure mediche o chirurgiche, o terapie riabilitative?"
    },
    {
        type: 'text',
        value: 'mal_domanda_16_1',
        description: "Specificare: anno di insorgenza, tipo, durata e terapie pratiche"
    },
    {
        type: 'select',
        value: 'mal_domanda_17',
        description: "17) Nello specifico, ha sofferto o soffre attualmente di una delle malattie di seguito elencate?"
    },
    {
        type: 'text',
        value: 'mal_domanda_17_1',
        description: "Specificare: quale, anno di insorgenza, durata e terapie pratiche"
    },
    {
        type: 'select',
        value: 'mal_domanda_18',
        description: "18) Ulteriori informazioni di carattere sanitario?"
    },
    {
        type: 'text',
        value: 'mal_domanda_18_1',
        description: "Specificare"
    },
];

export const domandeQuestionarioTcm1 = [
    {
        type: 'select',
        value: 'tcm_domanda_1',
        description: "1) Dichiara di non aver sofferto di malattie acute o croniche da cui siano residuati postumi invalidanti, di non essere a conoscenza di doversi sottoporre ad un intervento chirurgico, ad un ricovero ospedaliero, ad una cura farmacologica o ad accertamenti sanitari nei prossimi sei mesi, fatta eccezione, per i soli accertamenti sanitari, ai semplici controlli di routine?"
    },
    {
        type: 'select',
        value: 'tcm_domanda_2',
        description: "2) Dichiara di non essere titolare di pensione di Invalidità Civile o di altra Prestazione di Previdenza Pubblica e di non aver presentato domanda per ottenerla?"
    },
    {
        type: 'select',
        value: 'tcm_domanda_3',
        description: "3) Dichiara di non avere malattie in atto, escluse l'influenza, il morbillo, la rosolia, la parotite, la rinite cronica, la bronchite cronica, la gastroduodenite, la colite spastica, la dermatite, la sordità di grado medio, i difetti della vista correggibili con lenti, il glaucoma, gli esiti di fratture post-traumatiche non patologiche, l'artrosi di grado non elevato, i calcoli della colecisti?"
    },
    {
        type: 'select',
        value: 'tcm_domanda_4',
        description: "4) Dichiara di non aver fatto e di non fare abuso di sostanze alcoliche?"
    },
    {
        type: 'select',
        value: 'tcm_domanda_5',
        description: "5) Dichiara di non fare uso di sostanze stupefacenti o allucinogene?"
    },
    {
        type: 'select',
        value: 'tcm_domanda_6',
        description: "6) Dichiara di essere esente da difetti fisici e/o costituzionali o da disturbi psichici che impediscano il normale svolgimento delle attività quotidiane?"
    },
    {
        type: 'select',
        value: 'tcm_domanda_7',
        description: "7) Dichiara di non essere stato ricoverato, negli ultimi 5 anni, in ospedali o case di cura salvo che in conseguenza di appendicectomia, tonsillectomia, adenoidectomia, erniectomia, safenectomia, varici, emorroidectomia, meniscectomia, fratture ossee semplici, deviazione del setto nasale, parto, colicistectomia non dovuta a neoplasia, splenectomia post-traumatica, varicocele, fimosi, alluce valgo, interventi per ascessi acuti, chirurgia estetica?"
    },
    {
        type: 'select',
        value: 'tcm_domanda_8',
        description: "8) Dichiara di non essere sottoposto a terapie continuative (per un periodo di almeno 30 giorni)?"
    },
    {
        type: 'select',
        value: 'tcm_domanda_9',
        description: "9) Dichiara di non essere mai risultato positivo ad un test HIV?"
    },
];

export const domandeQuestionarioTcm2 = [
    {
        type: 'text',
        value: 'tcm_domanda_12',
        description: "12) Indicare valore altezza: cm"
    },
    {
        type: 'text',
        value: 'tcm_domanda_13',
        description: "13) Indicare valore peso: Kg"
    },
    {
        type: 'select',
        value: 'tcm_domanda_19',
        description: "19) Fuma o ha mai fumato? (sigari, sigarette, pipa, sigaretta elettronica o altro)"
    },
    {
        type: 'select',
        value: 'tcm_domanda_19_1',
        description: "19.1) In caso affermativo, di media fuma più di 20 sigarette al giorno?"
    },
    {
        type: 'select',
        value: 'tcm_domanda_19_2',
        description: "19.2) Ha smesso da più di 24 mesi?"
    },
    {
        type: 'select',
        value: 'tcm_domanda_1',
        description: "1) Ha in corso polizze vita con copertura caso morte presso altre Compagnie di Assicurazione?"
    },
    {
        type: 'select',
        value: 'tcm_domanda_2',
        description: "2) Nella sua attività professionale, accede saltuariamente a cantieri o scavi, oppure su impalcature, tetti; appartiene alle forze armate, forze di polizia o in servizi di Sicurezza Pubblica o privata in cui è necessario l'utilizzo di armi (escluse forze speciali e missioni all'estero) e non svolge esclusivamente attività amministrativa?"
    },
    {
        type: 'select',
        value: 'tcm_domanda_3',
        description: "3) Nella sua attività professionale, opera in cantieri o scavi, oppure su impalcature, tetti, tralicci, scale telescopiche, gru, macchina movimento terra, linee o circuiti ad alta tensione?"
    },
    {
        type: 'select',
        value: 'tcm_domanda_4',
        description: "4) Nella sua attività professionale, utilizza, trasporta o viene a contatto con materiale esplosivo, sostanze tossiche, infiammabili, radioattive, esplosive o corrosive; opera in cisterne, pozzi, cave, miniere, carpenterie, teleferiche/telecabina?"
    },
    {
        type: 'select',
        value: 'tcm_domanda_5',
        description: "5) Nella sua attività professionale, opera nell'ambito di reattori nucleari, su piattaforme petrolifere (anche come gruista), fonderie, appartiene a: vigili del fuoco, forze speciali, forze armate o forze di polizia con missioni all'estero; è addetto agli organi di informazioni in attività all'estero? (in caso di risposta affermativa compilare il questionario relativo all'attività professionale)"
    },
    {
        type: 'select',
        value: 'tcm_domanda_6',
        description: "6) Pratica a livello professionale attività aeronautiche, alpinistiche, acrobatiche, subacquee, motoristiche competitive nautiche o terrestri? (in caso di risposta affermativa compilare il questionario relativo all'attività professionale)"
    },
    {
        type: 'select',
        value: 'tcm_domanda_7',
        description: "7) Pratica attività professionali pericolose non rientranti tra quelle descritte ai punti precedenti? (in caso di risposta affermativa compilare il questionario relativo all'attività professionale)"
    },
    {
        type: 'select',
        value: 'tcm_domanda_8',
        description: "8) Svolge attività professionali o socialmente utili in aree geografiche pericolose nelle quali sono in corso insurrezioni, atti di terrorismo, conflitti sociali gravi, guerre, sommosse? (in caso di risposta affermativa compilare il questionario relativo all'attività professionale)"
    },
    {
        type: 'select',
        value: 'tcm_domanda_9',
        description: "9) Pratica attività sportive che rientrano anche per analogia, tra quelle sotto elencate? Alpinismo fino al 3 grado della scala UIAA, attività subacquee fino a 20 metri, vela di altura, equitazione agonistica senza salto di ostacoli"
    },
    {
        type: 'select',
        value: 'tcm_domanda_10',
        description: "10) Pratica attività sportive che rientrano anche per analogia, tra quelle sotto elencate? (in caso di risposta affermativa compilare il questionario relativo all'attività sportiva) Speleologia, free climbing, kayak, rafting, skeleton, salto del trampolino con sci o idrosci, sci alpinismo fuori pista, alpinismo oltre il 3 grado della scala UIAA, attività subacquee oltre 20m, motorismo nautico o terrestre, equitazione agonistica con salto di ostacoli, paracadutismo, deltaplano, ultraleggero, aliante, parapendio e attività affini"
    },
    {
        type: 'select',
        value: 'tcm_domanda_11',
        description: "11) Pratica attività sportive pericolose non rientranti, anche per analogia, tra quelle descritte nei punti precedenti? (in caso di risposta affermativa compilare il questionario relativo all'attività sportiva)"
    },
    {
        type: 'select',
        value: 'tcm_domanda_14',
        description: "14) Soffre o ha sofferto di malattie? (in caso di risposta affermativa compilare il questionario per la/e patologia/e dichiarata/e) (non indicare le seguenti malattie: influenza, morbillo, rosolia, parotite, rinite cronica, bronchite cronica, gastroduodenite cronica, colite spastica, dermatite, difetti della vista correggibili con lenti, esiti di fratture post-traumatiche non patologiche, artrosi di grado non elevato, calcoli della colecisti)"
    },
    {
        type: 'select',
        value: 'tcm_domanda_15',
        description: "15) Negli ultimi 5 anni si è mai sottoposto ad esami clinici (analisi di laboratorio, visite specialistiche o altro) che abbiano presentato segni di alterazione?"
    },
    {
        type: 'select',
        value: 'tcm_domanda_16',
        description: "16) E' mai stato ricoverato o prevede un prossimo ricovero in ospedali, case di cura, sanatori o simili per interventi chirurgici o procedure invasive per semplici accertamenti o si è mai sottoposto a emotrasfusioni o terapia a base di emoderivati? (non indicare i ricoveri avvenuti per: appendicectomia, tonsillectomia, adenoidectomia, erniectomia, safenectomia, varici, emorroidectomia, meniscectomia, fratture ossee semplici, deviazione del setto nasale, parto, colicistectomia non dovuta a neoplasia, splenectomia post-traumatica, varicocele, fimosi, alluce valgo, interventi per ascessi acuti)"
    },
    {
        type: 'select',
        value: 'tcm_domanda_17',
        description: "17) Assume o ha mai assunto farmaci per la regolazione della pressione arteriosa?"
    },
    {
        type: 'select',
        value: 'tcm_domanda_18',
        description: "18) Assume o ha mai assunto per un periodo di almeno 2 mesi farmaci per malattie diverse dall'ipertensione oppure è sottoposto a terapie continuative? (in caso di risposta affermativa compilare il questionario per la/e patologia/e dichiarata/e)"
    },
    {
        type: 'text',
        value: 'tcm_domanda_18_1',
        description: "18.1) Quali e quando?"
    },
    {
        type: 'select',
        value: 'tcm_domanda_20',
        description: "20) Ha fatto o fa abuso di sostanze alcoliche?"
    },
    {
        type: 'select',
        value: 'tcm_domanda_21',
        description: "21) Ha fatto o fa uso di sostanze stupefacenti?"
    },
    {
        type: 'select',
        value: 'tcm_domanda_22',
        description: "22) E' stato riconosciuto invalido Civile, Inps, Inail, o per Causa di servizio; beneficia di esenzioni ticket per patologie?"
    },
];

export const GridLocaleText = {
    // Root
    noRowsLabel: 'Nessun record',
    noResultsOverlayLabel: 'Nessun risultato trovato.',
    errorOverlayDefaultLabel: 'Attenzione errore.',

    // Density selector toolbar button text
    toolbarDensity: 'Densità',
    toolbarDensityLabel: 'Densità',
    toolbarDensityCompact: 'Compatta',
    toolbarDensityStandard: 'Standard',
    toolbarDensityComfortable: 'Comfort',

    // Columns selector toolbar button text
    toolbarColumns: 'Colonne',
    toolbarColumnsLabel: 'Seleziona colonne',

    // Filters toolbar button text
    toolbarFilters: 'Filtri',
    toolbarFiltersLabel: 'Visualizza filtri',
    toolbarFiltersTooltipHide: 'Nascondi filtri',
    toolbarFiltersTooltipShow: 'Visualizza filtri',
    toolbarFiltersTooltipActive: (count) =>
        count !== 1 ? `${count} filtri attivi` : `${count} filtro attivo`,

    // Quick filter toolbar field
    toolbarQuickFilterPlaceholder: 'Ricerca…',
    toolbarQuickFilterLabel: 'Ricerca',
    toolbarQuickFilterDeleteIconLabel: 'Cancella',

    // Export selector toolbar button text
    toolbarExport: 'Export',
    toolbarExportLabel: 'Export',
    toolbarExportCSV: 'Download come CSV',
    toolbarExportPrint: 'Stampa',
    toolbarExportExcel: 'Download come Excel',

    // Columns panel text
    columnsPanelTextFieldLabel: 'Trova colonna',
    columnsPanelTextFieldPlaceholder: 'Titolo',
    columnsPanelDragIconLabel: 'Riordina colonna',
    columnsPanelShowAllButton: 'Visualizza tutto',
    columnsPanelHideAllButton: 'Nascondi tutto',

    // Filter panel text
    filterPanelAddFilter: 'Aggiungi filtro',
    filterPanelDeleteIconLabel: 'Cancella',
    filterPanelLinkOperator: 'Operatori logici',
    filterPanelOperators: 'Operatori', // TODO v6: rename to filterPanelOperator
    filterPanelOperatorAnd: 'And',
    filterPanelOperatorOr: 'Or',
    filterPanelColumns: 'Colonne',
    filterPanelInputLabel: 'Valore',
    filterPanelInputPlaceholder: 'Valore filtro',

    // Filter operators text
    filterOperatorContains: 'contiene',
    filterOperatorEquals: 'uguale',
    filterOperatorStartsWith: 'inizia con',
    filterOperatorEndsWith: 'finisce con',
    filterOperatorIs: 'è',
    filterOperatorNot: 'non è',
    filterOperatorAfter: 'è dopo',
    filterOperatorOnOrAfter: 'non è dopo',
    filterOperatorBefore: 'è prima',
    filterOperatorOnOrBefore: 'non è prima',
    filterOperatorIsEmpty: 'è vuoto',
    filterOperatorIsNotEmpty: 'non è vuoto',
    filterOperatorIsAnyOf: 'è uno di',

    // Filter values text
    filterValueAny: 'qualsiasi',
    filterValueTrue: 'vero',
    filterValueFalse: 'falso',

    // Column menu text
    columnMenuLabel: 'Menu',
    columnMenuShowColumns: 'Visualizza colonne',
    columnMenuFilter: 'Filtro',
    columnMenuHideColumn: 'Nascondi',
    columnMenuUnsort: 'Rimuovi ordinamento',
    columnMenuSortAsc: 'Ordinamento crescente',
    columnMenuSortDesc: 'Ordinamento decrescente',

    // Column header text
    columnHeaderFiltersTooltipActive: (count) =>
        count !== 1 ? `${count} filtri attivi` : `${count} filtro attivo`,
    columnHeaderFiltersLabel: 'Visualizza filtri',
    columnHeaderSortIconLabel: 'Ordina',

    // Rows selected footer text
    footerRowSelected: (count) =>
        count !== 1
            ? `${count.toLocaleString()} righe selezionate`
            : `${count.toLocaleString()} riga selezionata`,

    // Total row amount footer text
    footerTotalRows: 'Righe totali:',

    // Total visible row amount footer text
    footerTotalVisibleRows: (visibleCount, totalCount) =>
        `${visibleCount.toLocaleString()} of ${totalCount.toLocaleString()}`,

    // Checkbox selection text
    checkboxSelectionHeaderName: 'Selezione',
    checkboxSelectionSelectAllRows: 'Seleziona tutto',
    checkboxSelectionUnselectAllRows: 'Deseleziona tutto',
    checkboxSelectionSelectRow: 'Seleziona riga',
    checkboxSelectionUnselectRow: 'Deseleziona riga',

    // Boolean cell text
    booleanCellTrueLabel: 'si',
    booleanCellFalseLabel: 'no',

    // Actions cell more text
    actionsCellMore: 'di più',

    // Column pinning text
    pinToLeft: 'Pin a sinistra',
    pinToRight: 'Pin a destra',
    unpin: 'Rimuovi pin',

    // Tree Data
    treeDataGroupingHeaderName: 'Gruppo',
    treeDataExpand: 'vedi figli',
    treeDataCollapse: 'nascondi figli',

    // Grouping columns
    groupingColumnHeaderName: 'Gruppo',
    groupColumn: (name) => `Raggruppa per ${name}`,
    unGroupColumn: (name) => `Stop raggruppamento per  ${name}`,

    // Master/detail
    detailPanelToggle: 'Pannello di dettaglio',
    expandDetailPanel: 'Espandi',
    collapseDetailPanel: 'Comprimi',

    // Used core components translation keys
    MuiTablePagination: {},

    // Row reordering text
    rowReorderingHeaderName: 'Ordinamento righe',

    // Aggregation
    aggregationMenuItemHeader: 'Aggregazione',
    aggregationFunctionLabelSum: 'somma',
    aggregationFunctionLabelAvg: 'media',
    aggregationFunctionLabelMin: 'min',
    aggregationFunctionLabelMax: 'max',
    aggregationFunctionLabelSize: 'size',
};