import React from 'react';
import {makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { green, red } from '@material-ui/core/colors';
import {API_URL_PAYMENTS} from '../config/config';
import axios   from 'axios';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Auth from '../auth/auth';
import TableHead from '@material-ui/core/TableHead';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';




function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}



const useStyles = makeStyles((theme) => ({
    alignFab: {
        marginLeft: 'auto',
      },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),      
    },
    img: {    
        display: 'block',
        maxWidth: '100%',
        maxHeight: '40px',
        minWidth: '50px',
        
    },

    img_service: {    
        display: 'block',
        maxWidth: '40px',
        maxHeight: '20px',
    },
    red: {
        color: red[500],        
    },
    green: {
        color: green[500],        
    },
    hiddenDiv:{
        display:'none'
    },
    
    
}));





function returnDefaultConfig(props){

    let arrayParameter=[];
    

    
    switch (props.data.type) {
        
        case 'bccvpos':
                            
            arrayParameter.push({gatewayuserserver:{
                    label: "Url server",
                    value: ""
                    }});

            arrayParameter.push({tid:{
                    label: "TID",
                    value: ""
            }});
            arrayParameter.push({ksig:{
                label: "KSIG",
                value: ""
            }});                                
            break;
        case 'nexivpos':
                            
            arrayParameter.push({gatewayuserserver:{
                    label: "Gateway url server",
                    value: ""
                    }});

            arrayParameter.push({merchantcode:{
                    label: "Alias",
                    value: ""
            }});
            arrayParameter.push({serverkey:{
                label: "Chiave segreta",
                value: ""
            }});                
            arrayParameter.push({paybylink:{
                label: "Pay By Link",
                value: "0",
                listElement: ["Si","No"],
                listValue: ["1","0"]
            }});                
            arrayParameter.push({timeoutpaybylink:{
                label: "Scadenza link giorni",
                value: "",
                listElement: ["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25"],
                listValue: ["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25"]
            }});                
            break;
        case 'siavpos':
                            
            arrayParameter.push({gatewayuserserver:{
                    label: "Gateway url server",
                    value: ""
                    }});

            arrayParameter.push({merchantcode:{
                    label: "Codice negozio server",
                    value: ""
            }});
            arrayParameter.push({serverkey:{
                label: "Chiave avvio server",
                value: ""
            }});
            arrayParameter.push({serverkeyresponceapi:{
                label: "Chiave esito API server",
                value: ""
            }});
            break;
                                
        case 'wiretransfer':
            arrayParameter.push({bankname:{
                label: "Nome banca",
                value: ""
            }});
            arrayParameter.push({iban:{
                label: "Coordinate bancarie IBAN",
                value: ""
            }});
            //return arrayParameter;
            break;
        case 'credit':
            break;
        default:
            break;
    
    } 

    
    if (props.data.config===null){
        return arrayParameter;
    }else{

        
        arrayParameter.forEach((item,key)=>{
            JSON.parse(props.data.config).forEach((itemDB,keyDB)=>{

                
                if(Object.keys(item)[0]===Object.keys(itemDB)[0]){
                    arrayParameter[key][Object.keys(item)[0]].value=JSON.parse(props.data.config)[key][Object.keys(item)[0]].value;
                }
            });                        
        })
        return arrayParameter;
        //return JSON.parse(props.data.config);
    }

    
    
    
}


export default function EditPaymentsParameter(props) {
    const classes = useStyles();    
    
    const [data, setData] = React.useState(returnDefaultConfig(props));       
    const [open, setOpen] = React.useState(false);
    const [alertseverity, setAlertSeverity] = React.useState("success");
    const [alertmsg, setAlertMsg] = React.useState(false);
    
    



    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }  
      setOpen(false);
    };
    
      
    
    

    

    
    


      
          
    const handleSave = () => {  
      new Promise((resolve, reject) => {
        const index = props.data.id;                                    

        let url=API_URL_PAYMENTS + "/" + index;                                    
            
        
        
        axios.patch(url,{

          ...props.data,          
          config:JSON.stringify(data),          
          },
          {
              headers:{
                'Authorization': 'Bearer '+Auth.getToken(),
                  'Content-Type': 'application/json'
              }
          }
        ).then(payload=>{
          if(payload.status===200){
            setAlertMsg("Aggiornamento avvenuto con successo");
            setAlertSeverity("success");                                                                                        
          }else{
            setAlertMsg("Errore durante l'aggiornamento");
            setAlertSeverity("error");                                            
          }
          setOpen(true);
        }).catch((error)=>{                                        
          setAlertMsg(error.response.data.error);
          setAlertSeverity("error");                                            
          setOpen(true);
        }) ;          
        
            resolve();
            
          })
    }

    

    const handleChangeValue = (checkName)=>(event) => {
        let NewData=data;
        let keysearch=Object.keys(checkName)[0];
        let index=-1;
        let ObjInsert;

        
        
        
        NewData.forEach((item,key)=>{

            if(Object.keys(item)[0]===keysearch){
                
                index=key;
                
                

                    
                    ObjInsert={[keysearch]:{
                        "label":Object.values(item)[0].label,
                        "value": event.target.value,
                        "listElement":Object.values(item)[0].listElement,
                        "listValue":Object.values(item)[0].listValue
                    }};                                                
            }
            

        })

        
        
        setData([
              ...NewData.slice(0, index),
              ObjInsert,
              ...NewData.slice(index + 1)
            ]
          );
        
    };
      
    //
    return (      
    <Card >
        <CardContent>           
            
            
            

            <TableContainer className={classes.table}>
                <Table aria-label="CVT">        
                    

                    <TableHead>
                        <TableRow>
                            <TableCell>Nome parametro</TableCell>            
                            <TableCell>Valore</TableCell>                                        
                            </TableRow>
                        </TableHead>
                <TableBody>
                    {data.map((item,key)=>(
                            <TableRow>          

                            <TableCell>                  
                            
                            {Object.values(item)[0].label}
                            </TableCell>
                            

                            <TableCell>
                                
                            {Object.values(item)[0].listElement!==undefined ?
                            
                            <Select                              
                              value={Object.values(item)[0].value}
                              onChange={handleChangeValue(item)}
                            >
                            {Object.values(item)[0].listElement.map((element,index)=>
                                    <MenuItem key={index} value={Object.values(item)[0].listValue[index]}>{element}</MenuItem>
                                )
                            }                                                            
                            </Select>                          
                          :
                            
                                <TextField
                                    variant="outlined"
                                    margin="normal"                  
                                    fullWidth
                                    id="parameter"
                                    label="Parametro"
                                    name="parameter"      
                                    autoFocus      
                                    value={Object.values(item)[0].value}
                                    onChange={handleChangeValue(item)}            
                                    />
                            }                      
                            </TableCell>                                    
                            
                            
                        </TableRow>
                    ))}                                                
                    </TableBody>
                </Table>
            </TableContainer>




            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={alertseverity}>
                    {alertmsg}
                </Alert>
            </Snackbar>      
        </CardContent>
        <CardActions>          
          <Button onClick={handleSave} startIcon={<SaveIcon/>} size="small">Salva</Button>
        </CardActions>
      </Card> 
      
    );
};

