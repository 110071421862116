import React from 'react';
import {makeStyles } from '@material-ui/core/styles';
import BackGround from './background';
import Container from '@material-ui/core/Container';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';
import MaterialTable from 'material-table';
import {id_cliente,ReturnInfoClient} from '../containers/logincontext';
import {API_URL_PAYMENTS,API_URL_ORGANIZATIONS_LIST} from '../config/config';
import axios   from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {NAME_APP} from '../config/config';
import Typography from '@material-ui/core/Typography';
import Auth from '../auth/auth';
import EditPaymentsParameter from './EditPaymentsParameters';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import BlockIcon from '@material-ui/icons/Block';
import { green, red } from '@material-ui/core/colors';
import Tooltip from '@material-ui/core/Tooltip';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

const useStyles = makeStyles((theme) => ({
    alignFab: {
        marginLeft: 'auto',
      },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),      
    },
    img: {            
        maxWidth: 'auto',
        maxHeight: '40px',
        justifyContent: 'center'
    },
    red: {
        color: red[500]
    },
    green: {
        color: green[500]
        
    },
}));

export default function Payments() {
    const [loading] = React.useState(false);
    const classes = useStyles();    
    const [open, setOpen] = React.useState(false);
    const [alertseverity, setAlertSeverity] = React.useState("success");
    const [alertmsg, setAlertMsg] = React.useState(false);
      
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };

    

    const [organizationts,setOrganizations]= React.useState([]);
    

    React.useEffect(() => {                
      const url=API_URL_ORGANIZATIONS_LIST;
          axios.get(url,
          {
              headers:{
              'Authorization': 'Bearer '+Auth.getToken(),
              'Content-Type': 'application/json'
              }
          }
      ).then(payload=>{                       
        
        let temp=[];
        payload.data.organizations.forEach(element => {
            temp.push({[element.id]:element.description});
        });                     
        
        var obj = payload.data.organizations.reduce(function(acc, cur, i) {
          acc[cur.id] = cur.description;
          return acc;
          }, {});

          setOrganizations(obj);         
          
      }) ;        
              
    },[]);      
    

    

    return (
        <main className={classes.content}>            
            <BackGround open={loading}/>     
            <div className={classes.appBarSpacer} />            
            <Container maxWidth="lg" className={classes.container}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" href="/" >
                        {NAME_APP}
                    </Link>                    
                    <Typography color="textPrimary">Gestione pagamenti</Typography>                                            
                </Breadcrumbs>

                <Card>
                    <CardContent>
                    {organizationts!==null &&
                    <MaterialTable 
                        title="Gestione pagamenti"
                        columns={[                                              
                                
                                { 
                                    title: 'Descrizione', 
                                    field: 'description' 
                                },                                                                                                                                
                                { 
                                    title: 'Organizzazione', 
                                    field: 'id_organization',
                                    lookup: organizationts,
                                    render: rowData => organizationts[rowData.id_organization]                                                       
                                                    
                                },
                                { 
                                    title: 'Stato', 
                                    field: 'enabled',
                                    lookup: { 1: 'Abilitato', 0: 'Disabilitato' },
                                    render: rowData =><Tooltip title={rowData.enabled===1 ? "Abilitato":"Disabilitato"}>                                                                                                        
                                                          {rowData.enabled===1 ? <CheckCircleOutlineIcon className={classes.green} />:<BlockIcon className={classes.red}/>}                                                    
                                                      </Tooltip>
                                                                                              
                                  },            
                                { 
                                    title: 'Tipo', 
                                    field: 'type' ,
                                    align: 'center',  
                                    lookup: { 
                                              'nexivpos': 'XPay NEXI', 
                                              'siavpos': 'VPOS SIA', 
                                              'bccvpos': 'PayWay' ,
                                              'wiretransfer': 'Bonifico Bancario',
                                              'credit':"Affidamento" 
                                              
                                            }, 
                                    render: rowData =>                                    
                                        rowData.type==="siavpos" ? 
                                        "VPOS SIA":
                                        rowData.type==="nexivpos" ? 
                                        "XPay NEXI":
                                        rowData.type==="bccvpos" ? 
                                        "PayWay"
                                        :
                                        rowData.type==="wiretransfer"?                                      
                                        "Bonifico Bancario": 
                                        "Affidamento"
                                      
                                },          
                                
                                
                                
                            ]}

                            detailPanel={[
                                {
                                  tooltip: 'Dettaglio pagamento',
                                  render: rowData => {
                                    return (<EditPaymentsParameter data={rowData} />)
                                  },
                                },
                              ]
                              }

                        localization={{
                            pagination: {
                                labelDisplayedRows: '{from}-{to} di {count}',
                                labelRowsSelect: 'per pagina',
                                labelRowsPerPage : 'per pagina',
                                firstAriaLabel: 'prima pagina',
                                firstTooltip : 'prima pagina',
                                previousAriaLabel: 'pagina precedente',
                                previousTooltip: 'pagina precedente',
                                nextAriaLabel: 'pagina successiva',
                                nextTooltip: 'pagina successiva',
                                lastAriaLabel: 'ultima pagina',
                                lastTooltip: 'ultima pagina',
                            },
                            toolbar: {  
                                nRowsSelected: '{0} elementi selezionati',
                                searchTooltip: 'Ricerca',
                                searchPlaceholder : 'Ricerca'
                            },                            
                            header: {
                                actions: ''
                            },
                            body: {
                                emptyDataSourceMessage: 'Nessun record da visualizzare',
                                filterRow: {
                                    filterTooltip: 'Filtri'
                                },
                                editRow:{
                                    saveTooltip: "Conferma",
                                    cancelTooltip: "Annulla",
                                    deleteText: "Confermi cancellazione?"
                                },
                                addTooltip: "Aggiungi",
                                deleteTooltip: "Cancella",
                                editTooltip: "Modifica",
                            }                          
                        }}
                        options={{
                            pageSize: 10,
                            pageSizeOptions: [5,10],
                            paginationType: "stepped",
                            search: true,
                            headerStyle: {
                            backgroundColor: ReturnInfoClient(id_cliente,"primary_color"),
                            color:  '#ffffff',    
                                    '&:hover': {
                                        color: '#ffffff',
                                    },
                                    '&:active': {
                                        color: '#ffffff',
                                    }          
                            }
            
                        }}
                        data={query =>
                                new Promise((resolve, reject) => {            
                                    let url = API_URL_PAYMENTS;
                                        url += '?per_page=' + query.pageSize
                                        url += '&page=' + (query.page + 1)

                                    if(query.search.length>3){
                                        url += '&search=' + query.search
                                    }            
                                    if(query.orderBy!==undefined){                                    
                                        url += '&orderby=' + query.orderBy.field
                                        url += '&orderdirection=' + query.orderDirection                                    
                                    }
            
                                    const requestOptions = {                                    
                                        headers: { 
                                            'Content-Type': 'application/json',
                                            'Authorization': 'Bearer '+Auth.getToken(),                
                                        },              
                                    };


                                    fetch(url,requestOptions)            
                                    .then(                  
                                        response => response.json()
                                    )
                                    .then(                
                                        result => {                                      
                                        resolve({
                                        data: result.data,                  
                                        page: result.current_page-1,
                                        totalCount: result.total,
                                        })
                                    })
                                })
                            }
                            editable={{
                                onRowAdd: newData =>
                                  new Promise((resolve, reject) => {
                                    const url=API_URL_PAYMENTS;
                                      axios.put(url,{                                          
                                        description:newData.description,                                                                                
                                        id_organization:newData.id_organization,                                                    
                                        enabled:newData.enabled,
                                        type:newData.type,                                        
                                        config:newData.config,                                        
                                      },
                                      {
                                          headers:{
                                            'Authorization': 'Bearer '+Auth.getToken(),
                                            'Content-Type': 'application/json'
                                          }
                                      }
                                    ).then(payload=>{
                                        
                                        if(payload.status===200){
                                            setAlertMsg("Inserimento dati avvenuto con successo");
                                            setAlertSeverity("success");
                                            
                                            
                                        }else{
                                            setAlertMsg("Errore durante l'inserimento dati");
                                            setAlertSeverity("error");                                            
                                        }
                                        setOpen(true);
                                        
                                    }).catch((error)=>{
                                        
                                        setAlertMsg(error.response.data.error);
                                        setAlertSeverity("error");                                            
                                        setOpen(true);
                                    }) ;        
                                    resolve();      
                                  }),
                                onRowUpdate: (newData, oldData) =>
                                  new Promise((resolve, reject) => {
                                    const index = oldData.id;                                    
                                    const url=API_URL_PAYMENTS + "/" + index;                                    
                                    axios.patch(url,{                                      
                                        description:newData.description,                                                                                
                                        id_organization:newData.id_organization,                                                    
                                        enabled:newData.enabled,
                                        type:newData.type,                                        
                                        config:newData.config,                                        
                                      },
                                      {
                                          headers:{
                                            'Authorization': 'Bearer '+Auth.getToken(),
                                              'Content-Type': 'application/json'
                                          }
                                      }
                                    ).then(payload=>{
                                        if(payload.status===200){
                                            setAlertMsg("Aggiornamento avvenuto con successo");
                                            setAlertSeverity("success");                                                                                        
                                        }else{
                                            setAlertMsg("Errore durante l'aggiornamento");
                                            setAlertSeverity("error");                                            
                                        }
                                        setOpen(true);
                                    }).catch((error)=>{
                                        
                                        setAlertMsg(error.response.data.error);
                                        setAlertSeverity("error");                                            
                                        setOpen(true);
                                    }) ;                                                                                
                                    resolve();
                                  }),
                                onRowDelete: oldData =>
                                  new Promise((resolve, reject) => {
                                    const index = oldData.id;                                    
                                    const url=API_URL_PAYMENTS + "/" + index;
                                    
                                    axios.delete(url,
                                    {
                                        headers:{
                                          'Authorization': 'Bearer '+Auth.getToken(),
                                            'Content-Type': 'application/json'
                                        }
                                    }
                                    ).then(payload=>{
                                        
                                        if(payload.status===200){
                                            setAlertMsg("Cancellazione avvenuta con successo");
                                            setAlertSeverity("success");
                                            
                                            
                                        }else{
                                            setAlertMsg("Errore durante la cancellazione");
                                            setAlertSeverity("error");                                            
                                        }
                                        setOpen(true);
                                    }).catch((error)=>{
                                        
                                        setAlertMsg(error.response.data.error);
                                        setAlertSeverity("error");                                            
                                        setOpen(true);
                                    }) ;                                                                                                                                                        
                                    resolve();
                                  }),
                              }}
                        />
                    }

                    </CardContent>        
                    
                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity={alertseverity}>
                            {alertmsg}
                        </Alert>
                    </Snackbar>      
                </Card>          
          </Container>                      
        </main>
      );
};