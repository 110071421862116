import React,{useState} from 'react';
import Container from '@material-ui/core/Container';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Card from '@material-ui/core/Card';
import Link from '@material-ui/core/Link';
import {makeStyles } from '@material-ui/core/styles';
import {NAME_APP} from '../config/config';
import Typography from '@material-ui/core/Typography';
import Auth from '../auth/auth';
import TextField from '@material-ui/core/TextField';
import {Grid} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import validator from 'validator';
import Button from '@material-ui/core/Button';
import axios   from 'axios';
import {API_URL_USERS} from '../config/config';
import ChangePswDialog from "./DialogChangePassword";
import {
    Avatar,
    Box    
} from '@material-ui/core';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

const useStyles = makeStyles((theme) => ({
    alignFab: {
        marginLeft: 'auto',
      },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),      
    },
    img: {            
        maxWidth: 'auto',
        maxHeight: '40px',
        justifyContent: 'center'
    },
    dialog:{
        minWidth:500
    },
    paper: {
        padding: theme.spacing(2),        
    },
    avatar:{
        height:80,
        width:80
    }
}));

const pattern = new RegExp(/^[0-9\b]+$/);


export default function Account() {
    const classes = useStyles();    
    const userData= Auth.getUser();
    const [data, setData] = React.useState({        
        name:userData.name,
        surname:userData.surname,                
        email:userData.email,
        phone:userData.phone,        
        role:userData.role,                
        user:userData.user,        
    });       
    const [open, setOpen] = useState(false);
    const [msgmodal,setMsgModal] = useState('');
    const [msgTitle,setMsgTitle] = useState('');
    const [loading, setLoading] = React.useState(false);
    const [openChangePswDialog, setChangePswDialog] = React.useState(false);
            
    
    
      const handleChangeValue = (event) => {                                       
            setData({
              ...data,
              [event.target.name]: event.target.value
            });
      };

      const handleOpen = (title,msg) => {
        setMsgTitle(title);
        setMsgModal(msg);
        setOpen(true);
      };
    
      const handleClose = () => {

        setOpen(false);                
      };
      
    
      const signUpUser= (e) =>{
          
        e.preventDefault();                                                                     
          if(data.name.length===0 ||
            data.surname.length===0
            ){
                handleOpen("error","Dati incompleti, impossibile procedere con il salvataggio");
                
                return;
        }
        
        if(data.phone!==""){
            if (!(validator.isMobilePhone(data.phone,'it-IT') || pattern.test(data.phone))){
                handleOpen("error","Numero di telefono non valido");
                
                return;
            }
        }
        
                                                
          setLoading(true);           
          axios.patch(API_URL_USERS+"/"+  userData.id,
            {
                ...data
            },
            {
                headers:{
                  'Authorization': 'Bearer '+Auth.getToken(),
                  'Content-Type': 'application/json'
                }
            }
          ).then(payload=>{
            setLoading(false);
            handleOpen("success","Salvataggio avvenuto con successo");
            Auth.refresh();
          }).catch(error=>{
                setLoading(false);
                handleOpen("error",error);
          });
      }
    

    
    
    return (
        <main className={classes.content}>            
            
            <div className={classes.appBarSpacer} />            
            <Container maxWidth="lg" className={classes.container}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" href="/" >
                        {NAME_APP}
                    </Link>                    
                    
                    <Typography color="textPrimary">Il mio account</Typography>    
                    
                </Breadcrumbs>

                <Card className={classes.paper}>
                <form className={classes.form} noValidate onSubmit ={signUpUser}>     
                <Grid container spacing={3}>                            
                    <Grid item xs={12} md={6}>
                    <Box
                            alignItems="center"
                            display="flex"
                            flexDirection="column"
                            >
                            <Avatar
                                className={classes.avatar}                                
                            >                                
                                    {data.name &&  data.name.charAt(0)}
                                    {data.surname &&  data.surname.charAt(0)}                             
                            </Avatar>
                            <Typography
                                color="textPrimary"
                                gutterBottom
                                variant="h5"
                                className={classes.fullname}
                            
                            >
                                {data.name + " "+ data.surname}
                            </Typography>
                            
                                
                            
                            
                            
                            </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={3}>                            
                            <Grid item xs={12}>
                                <TextField id="idname"  className={classes.tableData}  fullWidth label="Nome" variant="outlined" onChange={handleChangeValue} name={"name"}  
                                value= {data.name}                                 
                                />
                            </Grid>
                            
                            
                        </Grid>        
                        <Grid container spacing={3}>                            
                          <Grid item xs={12}>
                            <TextField  className={classes.tableData}  fullWidth label="Cognome" variant="outlined" onChange={handleChangeValue} name={"surname"}  
                            value= {data.surname}                             
                            />
                            </Grid>
                        </Grid>        

                        <Grid container spacing={3}>                            
                            <Grid item xs={12}>
                                <TextField disabled  className={classes.tableData}  fullWidth label="Indirizzo email" variant="outlined" onChange={handleChangeValue} name={"email"} 
                                value= {data.email}                             
                                />
                            </Grid>            
                        </Grid>            
                        <Grid container spacing={3}>                            
                            <Grid item xs={12}>
                                <TextField  className={classes.tableData}  
                                fullWidth label="Telefono" variant="outlined" onChange={handleChangeValue} name={"phone"}  
                                value= {data.phone} 
                                />
                            </Grid>            
                            
                        </Grid>        

                        <Grid container spacing={3}>                            
                            <Grid item xs={12}>
                                <TextField className={classes.tableData}  fullWidth label="Ruolo" variant="outlined" onChange={handleChangeValue} name={"role"}  
                                value= {data.role}             
                                />
                            </Grid>                        
                        </Grid>        
                        {userData.loginwith810===1 &&
                        <Grid container spacing={3}>                            
                            <Grid item xs={12}>
                                <TextField disabled className={classes.tableData}  fullWidth label="Nome utente per 810" variant="outlined" onChange={handleChangeValue} name={"user"}  
                                value= {data.user}             
                                />
                            </Grid>                        
                        </Grid>}                                            
                        <Grid container spacing={3}>                            
                            <Grid className={classes.GridAlignCenter}  item xs={6}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={loading}
                                className={classes.InfoFormBtn}
                            >
                                Salva
                            </Button>
                            </Grid>

                            <Grid className={classes.GridAlignCenter}  item xs={6}>
                            <Button
                                
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={loading}
                                className={classes.InfoFormBtn}
                                onClick={(e) => setChangePswDialog(true)}
                            >
                                Modifica password
                            </Button>
                            </Grid>                        
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}          
                    </Grid>
                    </Grid>
                    </Grid>
                </form>
                </Card>          
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity={msgTitle} closeText="Chiudi">
                    {msgmodal}
                    </Alert>
                </Snackbar>
          </Container>     
          <ChangePswDialog
                openPopup={openChangePswDialog}
                setOpenPopup={setChangePswDialog}
            />
        </main>  
      );

}