import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { green, red } from '@material-ui/core/colors';
import { API_URL_SELECTGUARANTEES, inputTipoCalcoloLists } from '../config/config';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Auth from '../auth/auth';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Select } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  alignFab: {
    marginLeft: 'auto',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  img: {
    display: 'block',
    maxWidth: '100%',
    maxHeight: '40px',
    minWidth: '50px',

  },

  img_service: {
    display: 'block',
    maxWidth: '40px',
    maxHeight: '20px',
  },
  red: {
    color: red[500],
  },
  green: {
    color: green[500],
  },
  hiddenDiv: {
    display: 'none'
  }
}));

export default function GuaranteeFactoryOrgDetailpanel(props) {
  const classes = useStyles();
  const [savestate, setsavestate] = React.useState(true);
  const [savenum, setsavenum] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [alertseverity, setAlertSeverity] = React.useState("success");
  const [alertmsg, setAlertMsg] = React.useState(false);
  const [guarantees, setGuarantees] = React.useState([]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleChangeValue = (id) => (event) => {
    let newguarantees = guarantees;
    newguarantees.map((single, index) => {
      if (id === single.id) {
        newguarantees[index][event.target.name] = event.target.value;
      }
      return false;
    }
    );
    setGuarantees(newguarantees);
    setsavestate(false);
    setsavenum(savenum + 1);
  }

  const handleChangeTipoCalcolo = (id) => (event) => {
    let newguarantees = guarantees;
    newguarantees.map((single, index) => {
      if (id === single.id) {
        newguarantees[index].tipo_calcolo = event.target.value;
      }
      return false;
    }
    );
    setGuarantees(newguarantees);
    setsavestate(false);
    setsavenum(savenum + 1);
  }

  const handleSave = () => {
    new Promise((resolve, reject) => {
      const myObjStr = JSON.stringify(guarantees);
      const url = API_URL_SELECTGUARANTEES + "/" + props.product_id + "/" + props.ser_fac_org_id + "/" + props.org_id;
      axios.patch(url, {
        list: myObjStr,
      },
        {
          headers: {
            'Authorization': 'Bearer ' + Auth.getToken(),
            'Content-Type': 'application/json'
          }
        }
      ).then(payload => {
        if (payload.status === 200) {
          setAlertMsg("Salvataggio dati avvenuto con successo");
          setAlertSeverity("success");
        } else {
          setAlertMsg("Errore durante il salvataggio dei dati");
          setAlertSeverity("error");
        }
        setOpen(true);
      }).catch((error) => {
        setAlertMsg(error.response.data.error);
        setAlertSeverity("error");
        setOpen(true);
      });
      resolve();
    })
  };

  React.useEffect(() => {
    const url = API_URL_SELECTGUARANTEES + "/" + props.product_id + "/" + props.ser_fac_org_id + "/" + props.org_id;
    axios.get(url,
      {
        headers: {
          'Authorization': 'Bearer ' + Auth.getToken(),
          'Content-Type': 'application/json'
        }
      }
    ).then(payload => {
      setGuarantees(payload.data.guarantees);
    });
  }, [props.product_id, props.ser_fac_org_id]);

  return (
    <>
      <Card className={classes.root} >
        <CardContent >
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            Parametri garanzie
          </Typography>
          {guarantees.map((single, index) => (
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id="additional-actions1-header"
              >
                <Typography>
                  {single.description}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer className={classes.table}>
                  <Table aria-label="Parametri garanzie">
                    <TableHead>
                      <TableRow>
                        <TableCell>Nome parametro</TableCell>
                        <TableCell>Valore</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          Massimale
                        </TableCell>
                        <TableCell>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="massimale"
                            autoFocus
                            value={single.massimale}
                            onChange={handleChangeValue(single.id)}

                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Franchigia
                        </TableCell>
                        <TableCell>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="franchigia"
                            autoFocus
                            value={single.franchigia}
                            onChange={handleChangeValue(single.id)}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Limite risarcimento in percentuale
                        </TableCell>
                        <TableCell>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="limite_risarcimento_perc"
                            autoFocus
                            value={single.limite_risarcimento_perc}
                            onChange={handleChangeValue(single.id)}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Limite risarcimento in valore
                        </TableCell>
                        <TableCell>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="limite_risarcimento_val"
                            autoFocus
                            value={single.limite_risarcimento_val}
                            onChange={handleChangeValue(single.id)}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Scoperto in percentuale
                        </TableCell>
                        <TableCell>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="scoperto_perc"
                            autoFocus
                            value={single.scoperto_perc}
                            onChange={handleChangeValue(single.id)}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Scoperto valore
                        </TableCell>
                        <TableCell>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="scoperto_val"
                            autoFocus
                            value={single.scoperto_val}
                            onChange={handleChangeValue(single.id)}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Note preventivo
                        </TableCell>
                        <TableCell>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="note_preventivo"
                            autoFocus
                            value={single.note_preventivo}
                            onChange={handleChangeValue(single.id)}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Tipo di calcolo
                        </TableCell>
                        <TableCell>
                          <Select
                            value={single.tipo_calcolo}
                            onChange={handleChangeTipoCalcolo(single.id)}
                            label="Tipo di calcolo"
                          >
                            {inputTipoCalcoloLists.map(option => (
                              <MenuItem
                                key={option.value}
                                value={option.value}
                              >
                                {option.description}
                              </MenuItem>
                            ))}
                          </Select>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Parametri di servizio
                        </TableCell>
                        <TableCell>
                          <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="service_parameters"
                            autoFocus
                            value={single.service_parameters}
                            onChange={handleChangeValue(single.id)}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>

                </TableContainer>
              </AccordionDetails>
            </Accordion>))}
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={alertseverity}>
              {alertmsg}
            </Alert>
          </Snackbar>
        </CardContent>
        <CardActions>
          <div className={classes.hiddenDiv}>({savenum})</div>
          <Button disabled={savestate} onClick={handleSave} startIcon={<SaveIcon />} size="small">Salva</Button>
        </CardActions>
      </Card>
    </>
  );
};

