import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BackGround from './background';
import axios from 'axios';
import Auth from '../auth/auth';
import AuthService from '../auth/authservice';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';
import { NAME_APP } from '../config/config';
import Container from '@material-ui/core/Container';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { red } from '@material-ui/core/colors';
import MuiAlert from '@material-ui/lab/Alert';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import CheckSvgButton from './checkboxsvg';
import Box from '@material-ui/core/Box';
import {
    API_URL_INFOIMAGES_ALL,
    API_URL_GET_INFOCITYBYCITY,
    API_URL_ALLGUARANTEES,
    API_URL_FACTORIES_LIST,
    API_URL_LIST_AGENCIES_USER,
    API_URL_LIST_SERVICES_AGENCY_USER,
    API_URL_QUOTATION_LIST,
    API_URL_MULTISESSION,
    API_URL_QUOTATION_RE,
    APP_TOKEN_QUOTIAMO,
    API_URL_GET_INFOBIRTH
} from '../config/config';
import { PosDataContext } from '../containers/logincontext';
import moment from 'moment';
import {
    tipoFrazionamento,
    clausoleInjuries,
    professioniInjuries,
    opzioniInabilitaTempInjuries,
    opzioniInvaliditaPermInjuries,
    domandeQuestionarioInfortunio,
    domandeQuestionarioMalattia
} from '../config/config';
import TextField from '@material-ui/core/TextField';
import { KeyboardDatePicker } from "@material-ui/pickers";
import SubjectDetails from './subjectDetails';
import NumberFormat from 'react-number-format';
import {
    getProfessions,
    number_format,
    parseFloat2Decimals
} from './utils';
import validator from 'validator';
import QuotationSingleDetail from './quotationsingledetail';
import { CardMedia } from '@material-ui/core';
import QuotationDetails from './quotationdetails';
import Carousel from 'react-material-ui-carousel';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

function LinearProgressWithLabel(props) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    BoxContainer: {
        padding: "10px"
    },
    fulldiv: {
        width: "-webkit-fill-available"
    },
    FirstDataRow: {
        marginTop: "20px",
        marginBottom: "0px",
    },
    TitleCategory: {
        marginTop: "20px",
        fontSize: "16px",
        fontWeight: "600"
    },
    DetailWarranty: {
        marginTop: "5px",
        fontSize: "12px",
        fontWeight: "400"

    },
    SubTitle: {
        marginTop: "20px",
        fontSize: "16px",
        fontWeight: "600"
    },
    button: {
        height: 30
    },
    Carousel: {
        width: "100%"
    },
    Media: {
        height: 800
    },
    Carousel2: {
        width: "50%"
    },
    Media2: {
        height: 400
    },
    PaddingTop: {
        paddingTop: 30,
    },
    cellroot: {
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    prezzo: {
        align: 'right'
    },
    titleTable: {
        flex: '1 1 100%',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    linear_class: {
        width: '20%',
        '& > * + *': {
            marginTop: theme.spacing(2),
            zIndex: theme.zIndex.drawer + 2,
        },
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    root: {
        width: '90%'
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        //maxWidth:'100%',
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        alignItems: 'center',
        flexDirection: 'column',
    },
    preventivo: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 800,
    },
    image: {
        width: 180,
        height: 60,
    },
    imgLogo: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '50px',
        marginBottom: '40px'
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    fixedHeight: {
        height: 240,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    pos: {
        marginBottom: 12,
    },
    containerButton: {
        alignItems: 'right',
        textAlign: 'right',
        marginBottom: '10px',
        marginTop: '10px'
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
        alignItems: 'center'
    },
    formControl: {
        width: '100%'
    },
    progress: {
        display: "none"
    },
    iconRoot: {
        color: red
    },
    linearProgressDiv: {
        width: '90%'
    }
}));

function getSteps() {
    return ['Dati generali', 'Garanzie', 'Clausole', 'Questionari', 'Preventivo', 'Modifica/Conferma'];
}

function AssignDefParameter(defOrg, defGrp, defAg) {
    return ({
        lavoratoreDipendente: false,
        dataEffetto: moment().format("YYYY-MM-DD"), // YYYY-MM-DD
        frazionamento: (defAg !== null && defAg.frazionamentoInjuries !== undefined ? defAg.frazionamentoInjuries : (defGrp !== null && defGrp.frazionamentoInjuries !== undefined ? defGrp.frazionamentoInjuries : (defOrg !== null && defOrg.frazionamentoInjuries !== undefined ? defOrg.frazionamentoInjuries : 0))), // 1=Annuale, 2=Semestrale, 3=Quadrimestrale, 4=Trimestrale, 6=Bimestrale, 12=Mensile
        contractorInfo: {
            subjectType: (defAg !== null && defAg.subjectTypeInjuries !== undefined ? defAg.subjectTypeInjuries : (defGrp !== null && defGrp.subjectTypeInjuries !== undefined ? defGrp.subjectTypeInjuries : (defOrg !== null && defOrg.subjectTypeInjuries !== undefined ? defOrg.subjectTypeInjuries : "1"))),
            name: (defAg !== null && defAg.nameInjuries !== undefined ? defAg.nameInjuries : (defGrp !== null && defGrp.nameInjuries !== undefined ? defGrp.nameInjuries : (defOrg !== null && defOrg.nameInjuries !== undefined ? defOrg.nameInjuries : ""))),
            surname: (defAg !== null && defAg.surnameInjuries !== undefined ? defAg.surnameInjuries : (defGrp !== null && defGrp.surnameInjuries !== undefined ? defGrp.surnameInjuries : (defOrg !== null && defOrg.surnameInjuries !== undefined ? defOrg.surnameInjuries : ""))),
            birthDate: "",
            gender: "M",
            fiscalCode: (defAg !== null && defAg.fiscalCodeInjuries !== undefined ? defAg.fiscalCodeInjuries : (defGrp !== null && defGrp.fiscalCodeInjuries !== undefined ? defGrp.fiscalCodeInjuries : (defOrg !== null && defOrg.fiscalCodeInjuries !== undefined ? defOrg.fiscalCodeInjuries : ""))),
            companyName: (defAg !== null && defAg.companyNameInjuries !== undefined ? defAg.companyNameInjuries : (defGrp !== null && defGrp.companyNameInjuries !== undefined ? defGrp.companyNameInjuries : (defOrg !== null && defOrg.companyNameInjuries !== undefined ? defOrg.companyNameInjuries : ""))),
            vatNumber: (defAg !== null && defAg.vatNumberInjuries !== undefined ? defAg.vatNumberInjuries : (defGrp !== null && defGrp.vatNumberInjuries !== undefined ? defGrp.vatNumberInjuries : (defOrg !== null && defOrg.vatNumberInjuries !== undefined ? defOrg.vatNumberInjuries : ""))),
            birthCity: "",
            birthProvince: "",
            birthRegion: "",
            birthNationAbbr: "",
            birthNation: "",
            profession: (defAg !== null && defAg.professionInjuries !== undefined ? defAg.professionInjuries : (defGrp !== null && defGrp.professionInjuries !== undefined ? defGrp.professionInjuries : (defOrg !== null && defOrg.professionInjuries !== undefined ? defOrg.professionInjuries : ""))),
            email: (defAg !== null && defAg.emailInjuries !== undefined ? defAg.emailInjuries : (defGrp !== null && defGrp.emailInjuries !== undefined ? defGrp.emailInjuries : (defOrg !== null && defOrg.emailInjuries !== undefined ? defOrg.emailInjuries : ""))),
            phoneNumber: (defAg !== null && defAg.phoneNumberInjuries !== undefined ? defAg.phoneNumberInjuries : (defGrp !== null && defGrp.phoneNumberInjuries !== undefined ? defGrp.phoneNumberInjuries : (defOrg !== null && defOrg.phoneNumberInjuries !== undefined ? defOrg.phoneNumberInjuries : ""))),
            residenceInfo: {
                city: "",
                cap: "",
                provinceAbbr: "",
                istat: "",
                toponym: (defAg !== null && defAg.toponimoInjuries !== undefined ? defAg.toponimoInjuries : (defGrp !== null && defGrp.toponimoInjuries !== undefined ? defGrp.toponimoInjuries : (defOrg !== null && defOrg.toponimoInjuries !== undefined ? defOrg.toponimoInjuries : ""))),
                address: (defAg !== null && defAg.indirizzoInjuries !== undefined ? defAg.indirizzoInjuries : (defGrp !== null && defGrp.indirizzoInjuries !== undefined ? defGrp.indirizzoInjuries : (defOrg !== null && defOrg.indirizzoInjuries !== undefined ? defOrg.indirizzoInjuries : ""))),
                streetNumber: (defAg !== null && defAg.numeroCivicoInjuries !== undefined ? defAg.numeroCivicoInjuries : (defGrp !== null && defGrp.numeroCivicoInjuries !== undefined ? defGrp.numeroCivicoInjuries : (defOrg !== null && defOrg.numeroCivicoInjuries !== undefined ? defOrg.numeroCivicoInjuries : ""))),
            }
        }
    });
}

const getListOfCity = async (token, url, search) => {
    try {
        const result = await axios.get(url + API_URL_GET_INFOCITYBYCITY, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            params: {
                city: search
            }
        });
        return result;
    } catch (e) {
        return Promise.reject(e.response);
    }
};

function TextFieldCustomMassimale(props) {
    return (
        <TextField label="Massimale" variant="outlined" {...props} />
    );
}

const checkPreventivo = async (QuotationData, company, token, guarantees, guaranteesSelect, guaranteesValue, conventionslist, authToken, franchigiaInvPerm, franchigiaInabTemp) => {
    if (company.multisession === 1 && company.username === "" && company.password === "" && company.companyCode === "" && company.companyParams === "" && company.nodeCode === "") {
        if (company.paramurl === "hdi") {
            const result = {
                data: {
                    error: "Servizio non disponibile",
                    message: "Limite utenze in uso raggiunto, riprovare tra qualche minuto",
                    path: "/getquotation",
                    status: 503,
                    errorCode: 1003,
                    timestamp: moment().format("YYYY-MM-DD HH:mm:ss")
                }
            };
            return result;
        } else {
            const result = {
                data: {
                    error: "Servizio non disponibile",
                    message: "Utenze non disponibili, riprovare tra qualche minuto",
                    path: "/getquotation",
                    status: 503,
                    errorCode: 1003,
                    timestamp: moment().format("YYYY-MM-DD HH:mm:ss")
                }
            };
            return result;
        }
    }
    /*Verifica se proviene dalla stessa compagnia */

    let listCVT = [];
    let convenzioneAttiva = CheckConvenzioni(QuotationData, company, conventionslist, 1);
    if (convenzioneAttiva !== false && convenzioneAttiva.code !== "") {
        console.log("TROVATA CONVENZIONE DA INVIARE ALLA COMPAGNIA");
        //Al momento non usato
    }

    guarantees.forEach((element, index) => {
        if (guaranteesSelect[index]) {
            let massimale = 0;
            let massimalePerc = 0;
            let franchigia = 0;
            let franchigiaPerc = 0;
            let limite = 0;
            let limitePerc = 0;
            let scopertoPerc = 0;
            let scoperto = 0;

            if (element["input_type"] !== 0) {
                if (isNaN(guaranteesValue[index]) && guaranteesValue[index].indexOf('%') !== -1) {
                    massimalePerc = Number(guaranteesValue[index].replace('%', ''));
                } else {
                    massimale = Number(guaranteesValue[index]);
                }
            } else {
                if (company.guarantees_info[index].massimale !== null) {
                    if (isNaN(company.guarantees_info[index].massimale) && company.guarantees_info[index].massimale.indexOf('%') !== -1) {
                        massimalePerc = Number(company.guarantees_info[index].massimale.replace('%', ''));
                    } else {
                        massimale = Number(company.guarantees_info[index].massimale);
                    }
                }
            }

            if (company.guarantees_info[index].franchigia !== null) {
                if (isNaN(company.guarantees_info[index].franchigia) && company.guarantees_info[index].franchigia.indexOf('%') !== -1) {
                    franchigiaPerc = Number(company.guarantees_info[index].franchigia.replace('%', ''));
                } else {
                    franchigia = Number(company.guarantees_info[index].franchigia);
                }
            }
            if (company.paramurl === "groupamanexus") {
                let codiceGaranzia = element["code"];
                if (codiceGaranzia === "INF_INVPER") {
                    franchigia = franchigiaInvPerm;
                } else if (codiceGaranzia === "INF_INTEMP") {
                    franchigia = franchigiaInabTemp;
                }
            }

            if (company.guarantees_info[index].limite_risarcimento_perc !== null) {
                limitePerc = Number(company.guarantees_info[index].limite_risarcimento_perc);
            }
            if (company.guarantees_info[index].limite_risarcimento_val !== null) {
                limite = Number(company.guarantees_info[index].limite_risarcimento_val);
            }

            if (company.guarantees_info[index].scoperto_perc !== null) {
                scopertoPerc = Number(company.guarantees_info[index].scoperto_perc);
            }

            if (company.guarantees_info[index].scoperto_val !== null) {
                scoperto = Number(company.guarantees_info[index].scoperto_val);
            }

            let tipoCalcolo = company.guarantees_info[index].tipo_calcolo !== null ? (company.guarantees_info[index].tipo_calcolo !== "default" ? company.guarantees_info[index].tipo_calcolo : company.guarantees_info[index].tipo_calcolo_default) : company.guarantees_info[index].tipo_calcolo_default;
            if (tipoCalcolo === "comp") {
                if (company.guarantees_info[index].service_parameters_code !== null) {
                    company.guarantees_info[index].service_parameters_code.split(",").forEach(elementcode => {
                        //temp.push({[element.id]:element.description});
                        listCVT.push(
                            {
                                codice: elementcode,
                                massimale: massimale,
                                massimalePerc: massimalePerc,
                                franchigia: franchigia,
                                franchigiaPerc: franchigiaPerc,
                                limite: limite,
                                limitePerc: limitePerc,
                                scoperto: scoperto,
                                scopertoPerc: scopertoPerc
                            }
                        );
                    });
                } else {
                    listCVT.push(
                        {
                            codice: element["code"],
                            massimale: massimale,
                            massimalePerc: massimalePerc,
                            franchigia: franchigia,
                            franchigiaPerc: franchigiaPerc,
                            limite: limite,
                            limitePerc: limitePerc,
                            scoperto: scoperto,
                            scopertoPerc: scopertoPerc
                        }
                    );
                }
            }
        }
    });

    const json = JSON.stringify({
        applicationToken: APP_TOKEN_QUOTIAMO,
        username: company.username,
        password: company.password,
        companyCode: company.companyCode,
        companyParams: company.companyParams,
        nodeCode: company.nodeCode,
        commerciale: (company.commerciale === null || company.commerciale === "") ? "00493" : company.commerciale,
        agZona: (company.agZona === null || company.agZona === "") ? "00493" : company.agZona,
        reteVendita: "001",
        contractorInfo: {
            name: QuotationData.contractorInfo.name,
            surname: QuotationData.contractorInfo.surname,
            birthDate: QuotationData.contractorInfo.birthDate,
            gender: QuotationData.contractorInfo.gender,
            companyName: QuotationData.contractorInfo.companyName,
            phoneNumber: company.usedefaultphonenumber === 1 ? company.phonenumber : QuotationData.contractorInfo.phoneNumber,
            email: company.usedefaultemail === 1 ? company.email : QuotationData.contractorInfo.email,
            fiscalCode: QuotationData.contractorInfo.fiscalCode,
            vatNumber: QuotationData.contractorInfo.vatNumber,
            profession: QuotationData.contractorInfo.profession,
            personType: (QuotationData.contractorInfo.subjectType === "1" || QuotationData.contractorInfo.subjectType === "") ? "FIS" : "GIU",
            subjectType: (QuotationData.contractorInfo.subjectType === "") ? "1" : QuotationData.contractorInfo.subjectType,
            residenceInfo: {
                city: QuotationData.contractorInfo.residenceInfo.city,
                cap: QuotationData.contractorInfo.residenceInfo.cap,
                provinceAbbr: QuotationData.contractorInfo.residenceInfo.provinceAbbr,
                istat: QuotationData.contractorInfo.residenceInfo.istat,
            },
            locationInfo: {
                city: QuotationData.contractorInfo.residenceInfo.city,
                cap: QuotationData.contractorInfo.residenceInfo.cap,
                provinceAbbr: QuotationData.contractorInfo.residenceInfo.provinceAbbr,
                istat: QuotationData.contractorInfo.residenceInfo.istat,
            }
        },
        quoteInfo: {
            dataEffetto: QuotationData.dataEffetto,
            frazionamento: QuotationData.frazionamento,
            questionarioSanitario: QuotationData.questionarioSanitario,
            lavoratoreDipendente: QuotationData.lavoratoreDipendente,
            listaCVT: listCVT,
            clausole: QuotationData.clausole,
        },
    });
    const url_service = company.url + API_URL_QUOTATION_RE + "/injuries/" + company.type + "/" + (company.type === "share" ? company.sharetoken : company.paramurl);
    try {
        const result = await axios.post(url_service, json, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        });
        // chiamata sblocco credenziali multisession
        if (company.multisession === 1) {
            const url_multisession = API_URL_MULTISESSION + "/checkuse/" + company.multisessionId + "/0";
            await axios.get(url_multisession, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + authToken,
                }
            });
        }
        return result;
    } catch (e) {
        // chiamata sblocco credenziali multisession
        if (company.multisession === 1) {
            const url_multisession = API_URL_MULTISESSION + "/checkuse/" + company.multisessionId + "/0";
            await axios.get(url_multisession, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + authToken,
                }
            });
        }
        return Promise.reject(e.response);
    }
};

function CheckConvenzioni(QuotationData, company, conventionslist, flagapply) {
    let convention_active = {
        code: "",
        description: "",
        flex: 0
    };

    if (flagapply === 1) {
        let convComp = false;
        conventionslist.forEach(conventions => {
            if (company.facid === conventions.id_factory) {
                let convention_accept = CheckActiveConvention(conventions, QuotationData);
                if (convention_accept) {
                    convention_active = conventions;
                    if (conventions.companyapply === 1) {
                        convComp = true;
                    } else {
                        convComp = false;
                    }
                }
            }
        }
        );
        if (convComp === false) {
            let convention = {
                code: "",
                description: "",
                flex: 0
            };
            return convention;
        }
    } else {
        conventionslist.forEach(conventions => {
            if (company.facid === conventions.id_factory && conventions.companyapply === flagapply) {
                let convention_accept = CheckActiveConvention(conventions, QuotationData);
                if (convention_accept) {
                    convention_active = conventions;
                }
            }
        });
    }

    return convention_active;
}

function CheckActiveConvention(conventions, QuotationData) {
    let convention_accept = true;
    if (conventions.convention_rules !== null) {
        JSON.parse(conventions.convention_rules).forEach(
            (single_rules) => {
                if (single_rules.ProvinceList !== undefined) {
                    if (single_rules.ProvinceList.value !== null && single_rules.ProvinceList.value !== "") {
                        //non trovate le province
                        let elencoProvince = single_rules.ProvinceList.value.split(",");
                        if (elencoProvince.indexOf(QuotationData.provinciaAbbr) === -1) {
                            convention_accept = false;
                        }
                    }
                }
                if (single_rules.BirthNation !== undefined) {
                    if (single_rules.BirthNation.value !== null && single_rules.BirthNation.value !== "") {
                        //Verifico se nato in paese straniero
                        if (!isNaN(single_rules.BirthNation.value)) {
                            var res = QuotationData.contractorInfo.fiscalCode.substring(11, 12).toUpperCase();
                            if (parseInt(single_rules.BirthNation.value) === 1) {
                                if (res === "Z") {
                                    convention_accept = false;
                                }
                            } else if (parseInt(single_rules.BirthNation.value) === 2) {
                                if (res !== "Z") {
                                    convention_accept = false;
                                }
                            }
                        }
                    }
                }
                if (single_rules.PaymentMode !== undefined) {
                    if (single_rules.PaymentMode.value !== null && single_rules.PaymentMode.value !== "") {
                        if (Number(QuotationData.frazionamento) !== Number(single_rules.PaymentMode.value)) {
                            convention_accept = false;
                        }
                    }
                }
            }
        );
    }

    return convention_accept;
}

function checkQuestionarioInfortunio(guarantees, guaranteesSelect, classes) {
    let isDisabled = true;
    guarantees.forEach((element, index) => {
        if (guaranteesSelect[index]) {
            if (element["code"].includes("INF")) {
                isDisabled = false;
            }
        }
    });
    return isDisabled;
}

function checkQuestionarioMalattia(guarantees, guaranteesSelect, classes) {
    let isDisabled = true;
    guarantees.forEach((element, index) => {
        if (guaranteesSelect[index]) {
            if (element["code"].includes("MAL")) {
                isDisabled = false;
            }
        }
    });
    return isDisabled;
}

function checkClausolaAtt(value, clausole, guaranteesSelect, guarantees, franchigiaInvPerm) {
    let isDisabled = false;
    if (value === "B") {
        isDisabled = clausole.some(v => (v === "C" || v === "D" || v === "E"));
        if (franchigiaInvPerm == 1 || franchigiaInvPerm == 2 || franchigiaInvPerm == 5) {
            isDisabled = true;
        }
    } else if (value === "C") {
        isDisabled = clausole.some(v => (v === "B" || v === "D" || v === "E" || v === "F" || v === "G"));
    } else if (value === "D") {
        isDisabled = clausole.some(v => (v === "B" || v === "C" || v === "E" || v === "F" || v === "G"));
    } else if (value === "E") {
        isDisabled = clausole.some(v => (v === "B" || v === "D" || v === "C" || v === "F" || v === "G"));
        if (franchigiaInvPerm > 0) {
            isDisabled = true;
        }
    } else if (value === "F") {
        isDisabled = clausole.some(v => (v === "C" || v === "D" || v === "E"));
    } else if (value === "G") {
        isDisabled = clausole.some(v => (v === "C" || v === "D" || v === "E"));
    }
    if (isDisabled === true) {
        return isDisabled;
    }
    if (value === "A" || value === "B" || value === "C" || value === "D" || value === "E" || value === "F" || value === "G") {
        let trovato = false;
        guarantees.forEach((element, index) => {
            if (guaranteesSelect[index]) {
                if (element["code"] === "INF_INVPER") {
                    trovato = true;
                }
            }
        });
        isDisabled = !trovato;
    } else if (value === "H" || value === "I" || value === "L" || value === "M") {
        let trovato = false;
        guarantees.forEach((element, index) => {
            if (guaranteesSelect[index]) {
                if (element["code"] === "INF_MOR") {
                    trovato = true;
                }
            }
        });
        isDisabled = !trovato;
    } else if (value === "N" || value === "O") {
        let trovato = false;
        guarantees.forEach((element, index) => {
            if (guaranteesSelect[index]) {
                if (element["code"] === "INF_INTEMP") {
                    trovato = true;
                }
            }
        });
        isDisabled = !trovato;
    } else if (value === "P") {
        let trovato = false;
        guarantees.forEach((element, index) => {
            if (guaranteesSelect[index]) {
                if (element["code"] === "INF_DIAR" || element["code"] === "INF_DIAI" || element["code"] === "INF_DIAC") {
                    trovato = true;
                }
            }
        });
        isDisabled = !trovato;
    } else if (value === "Q") {
        let trovato = false;
        guarantees.forEach((element, index) => {
            if (guaranteesSelect[index]) {
                if (element["code"] === "ASS") {
                    trovato = true;
                }
            }
        });
        isDisabled = !trovato;
    } else if (value === "S") {
        let trovato = false;
        guarantees.forEach((element, index) => {
            if (guaranteesSelect[index]) {
                if (element["code"] === "MAL_INVPERI") {
                    trovato = true;
                }
            }
        });
        isDisabled = !trovato;
    }
    return isDisabled;
}

function checkClausolaAttP(value, clausole, guaranteesSelect, guarantees) {
    let isDisabled = false;
    if (value === "P") {
        let trovato = false;
        let trovatoDiar = false;
        let trovatoDiai = false;
        let trovatoDiac = false;
        guarantees.forEach((element, index) => {
            if (guaranteesSelect[index]) {
                if (element["code"] === "INF_DIAR") {
                    trovatoDiar = true;
                }
                if (element["code"] === "INF_DIAI") {
                    trovatoDiai = true;
                }
                if (element["code"] === "INF_DIAC") {
                    trovatoDiac = true;
                }
            }
        });
        guarantees.forEach((element, index) => {
            if (guaranteesSelect[index]) {
                if (element["code"] === "MAL_DIAR" && trovatoDiar === true) {
                    trovato = true;
                }
                if (element["code"] === "MAL_DIAI" && trovatoDiai === true) {
                    trovato = true;
                }
                if (element["code"] === "MAL_DIAC" && trovatoDiac === true) {
                    trovato = true;
                }
            }
        });
        isDisabled = !trovato;
    }
    return isDisabled;
}

const getInfoBirth = (token, url, cf, nome, cognome) => {
    try {
        const result = axios.get(url + API_URL_GET_INFOBIRTH, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            params: {
                cf: cf,
                nome: nome,
                cognome: cognome
            }
        });
        return result;
    } catch (e) {
        return Promise.reject(e.response);
    }
};

export default function QuotationInjuries(props) {
    const classes = useStyles();
    const [alertseverity, setAlertSeverity] = React.useState("success");
    const [alertmsg, setAlertMsg] = React.useState(false);
    const [activeStep, setActiveStep] = React.useState(0);
    const [prevRQ, setPrevRQ] = React.useState(0);
    const [prevRS, setPrevRS] = React.useState(0);
    const steps = getSteps();
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [datipreventivo, setdatipreventivo] = React.useState([]);
    const [pos, setPos] = React.useState('');
    const [agencieslists, setagencieslists] = React.useState([]);
    const [images, setImages] = React.useState([]);
    const [token, setToken] = React.useState(null);
    const [orgid, setOrgId] = React.useState(null);
    const [posinfo, setPosInfo] = React.useState(null);
    const [, setPosActive] = React.useContext(PosDataContext);
    const [QuotationData, setQuotationData] = React.useState(AssignDefParameter(null, null, null));
    const [urlservicequotiamo, seturlservicequotiamo] = React.useState("");
    const [listCity, setlistCity] = React.useState([]);
    const [listProfessions, setlistProfessions] = React.useState([]);
    const [progress, setProgress] = React.useState(0);
    const [guarantees, setGuarantees] = React.useState([]);
    const [guaranteesSelect, setGuaranteesSelect] = React.useState([]);
    const [guaranteesValue, setGuaranteesValue] = React.useState([]);
    const [factories, setFactories] = React.useState([]);
    const [factoriesHub, setFactoriesHub] = React.useState([]);
    let contaPreventiviDaFare = 0;
    let contaPreventiviFatti = 0;
    const [companyData, setcompanyData] = React.useState([]);
    const [NumberQuotation, setNumberQuotation] = React.useState(false);
    const [clausole, setClausole] = React.useState([]);

    const [franchigiaInvPerm, setFranchigiaInvPerm] = React.useState(0);
    const [franchigiaInabTemp, setFranchigiaInabTemp] = React.useState(7);

    const [questionarioInf, setQuestionarioInf] = React.useState({ inf_domanda_1: "no", inf_domanda_2: "no", inf_domanda_3: "no" });
    const [questionarioMal, setQuestionarioMal] = React.useState({
        mal_domanda_1: "no", mal_domanda_2: "no", mal_domanda_3: "no", mal_domanda_4: "no", mal_domanda_5: "no", mal_domanda_6: "no", mal_domanda_7: "no", mal_domanda_8: "no",
        mal_domanda_9: "no", mal_domanda_10: "no", mal_domanda_11: "no", mal_domanda_12: "no", mal_domanda_13: "no", mal_domanda_14: "no", mal_domanda_15: "no", mal_domanda_16: "no",
        mal_domanda_17: "no", mal_domanda_18: "no"
    });

    const handleChangeValueInf = (event) => {
        setQuestionarioInf({
            ...questionarioInf,
            [event.target.name]: event.target.value
        });
    };

    const handleChangeValueMal = (event) => {
        setQuestionarioMal({
            ...questionarioMal,
            [event.target.name]: event.target.value
        });
    };

    React.useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 1000 ? 1 : prevProgress + 1));
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, []);

    React.useEffect(() => {
        if (orgid !== null) {
            const url = API_URL_ALLGUARANTEES + "/enabled/injuries/" + orgid;
            axios.get(url,
                {
                    headers: {
                        'Authorization': 'Bearer ' + Auth.getToken(),
                        'Content-Type': 'application/json'
                    }
                }
            ).then(payload => {
                let temp = [];
                let tempVal = [];
                payload.data.guarantees.forEach(element => {
                    temp.push(false);
                    if (element.input_type === 2) {
                        tempVal.push(element.list_value.split(",")[0]);
                    } else {
                        tempVal.push("");
                    }
                });
                setGuaranteesSelect(temp);
                setGuaranteesValue(tempVal);
                setGuarantees(payload.data.guarantees);
            });
        }
    }, [orgid]);

    React.useEffect(() => {
        const url = API_URL_FACTORIES_LIST;
        axios.get(url,
            {
                headers: {
                    'Authorization': 'Bearer ' + Auth.getToken(),
                    'Content-Type': 'application/json'
                }
            }
        ).then(payload => {
            setFactories(payload.data.factories);
        });
    }, []);

    React.useEffect(() => {
        const url = API_URL_LIST_AGENCIES_USER;
        axios.get(url,
            {
                headers: {
                    'Authorization': 'Bearer ' + Auth.getToken(),
                    'Content-Type': 'application/json'
                }
            }
        ).then(payload => {
            setagencieslists(payload.data);
            if (payload.data.length > 0) {
                setPos(payload.data[0].id);
                seturlservicequotiamo(payload.data[0].url);
                setPosInfo(payload.data[0]);
                setPosActive(payload.data[0]);
                setOrgId(payload.data[0].orgid);
                if (payload.data[0].defaultparameter !== null) {
                    let defOrg = JSON.parse(payload.data[0].defOrg);
                    let defGrp = JSON.parse(payload.data[0].defGrp);
                    let defAg = JSON.parse(payload.data[0].defAg);
                    setQuotationData(AssignDefParameter(defOrg, defGrp, defAg));
                }
                localStorage.setItem('pos', JSON.stringify(payload.data[0]));
                let urlservicequotiamo = payload.data[0].url;
                AuthService.signin(payload.data[0].username, payload.data[0].password, payload.data[0].url).then(
                    payload => {
                        setToken(payload);
                        getListOfCity(payload, urlservicequotiamo, "").then(payload => {
                            setlistCity(payload.data);
                        });
                        /*getProfessions(payload, urlservicequotiamo).then(payload => {
                            setlistProfessions(payload.data);
                        });*/
                    }
                ).catch(error => {
                });
                const url = API_URL_INFOIMAGES_ALL + "/injuries/" + payload.data[0].id;
                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + Auth.getToken(),
                            'Content-Type': 'application/json'
                        }
                    }
                ).then(payload => {
                    setImages(payload.data.images)
                });
            }
        });
    }, [setPosActive]);

    const checkcompanyData = async (tokenApp, pos) => {
        try {
            const result = await axios.get(API_URL_LIST_SERVICES_AGENCY_USER + "/injuries/" + pos, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + tokenApp,
                }
            });
            return result;
        } catch (e) {
            return Promise.reject(e.response);
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleNext = () => {
        switch (activeStep) {
            case 0:
                if (pos === "") {
                    setAlertMsg("Nessun punto vendita abilitato, impossibile effettuare il preventivo");
                    setAlertSeverity("error");
                    setOpen(true);
                    break;
                }
                let dataMax = moment().add(30, "days");
                let dataEffetto = moment(QuotationData.dataEffetto);
                if (dataEffetto > dataMax) {
                    setAlertMsg("Non è possibile effettuare un preventivo con una scadenza superiore ad un mese");
                    setAlertSeverity("error");
                    setOpen(true);
                    break;
                }
                checkcompanyData(Auth.getToken(), pos).then(
                    payload => {
                        let companyListPrev = payload.data.services;
                        if (companyListPrev.length < 1) {
                            setAlertMsg("Prodotto non attivo per questo punto vendita");
                            setAlertSeverity("error");
                            setOpen(true);
                            return;
                        }
                        if (QuotationData.frazionamento === 0) {
                            setAlertMsg("Seleziona il frazionamento");
                            setAlertSeverity("error");
                            setOpen(true);
                            return;
                        }
                        if (QuotationData.contractorInfo.subjectType === "" || QuotationData.contractorInfo.subjectType === "1") {
                            if (QuotationData.contractorInfo.name === "" ||
                                QuotationData.contractorInfo.surname === "" ||
                                QuotationData.contractorInfo.birthDate === "" ||
                                QuotationData.contractorInfo.gender === "" ||
                                QuotationData.contractorInfo.fiscalCode === "" ||
                                QuotationData.contractorInfo.profession === "" ||
                                QuotationData.contractorInfo.profession === undefined ||
                                QuotationData.contractorInfo.birthDate === undefined
                            ) {
                                if (QuotationData.contractorInfo.birthDate === undefined || QuotationData.contractorInfo.birthDate === "") {
                                    setAlertMsg("Codice fiscale contraente errato");
                                } else {
                                    setAlertMsg("Dati contraente non completi");
                                }
                                setAlertSeverity("error");
                                setOpen(true);
                                return;
                            }
                        } else {
                            if (QuotationData.contractorInfo.vatNumber === undefined) QuotationData.contractorInfo.vatNumber = "";
                            if (QuotationData.contractorInfo.companyName === undefined) QuotationData.contractorInfo.companyName = "";
                            if (QuotationData.contractorInfo.vatNumber === "" ||
                                QuotationData.contractorInfo.companyName === ""
                            ) {
                                setAlertMsg("Dati contraente non completi");
                                setAlertSeverity("error");
                                setOpen(true);
                                return;
                            }
                        }
                        if (QuotationData.contractorInfo.residenceInfo.toponym === "" ||
                            QuotationData.contractorInfo.residenceInfo.address === "" ||
                            QuotationData.contractorInfo.residenceInfo.streetNumber === "" ||
                            QuotationData.contractorInfo.residenceInfo.city === "" ||
                            QuotationData.contractorInfo.residenceInfo.cap === "" ||
                            QuotationData.contractorInfo.residenceInfo.provinceAbbr === ""
                        ) {
                            setAlertMsg("Dati indirizzo non completi");
                            setAlertSeverity("error");
                            setOpen(true);
                            return;
                        }
                        if (QuotationData.contractorInfo.residenceInfo.provinceAbbr.length > 2) {
                            setAlertMsg("Sigla Provincia massimo 2 lettere");
                            setAlertSeverity("error");
                            setOpen(true);
                            return;
                        }
                        if (QuotationData.contractorInfo.email === undefined) QuotationData.contractorInfo.email = "";
                        if (!validator.isEmail(QuotationData.contractorInfo.email)) {
                            setAlertMsg("Email non valida");
                            setAlertSeverity("error");
                            setOpen(true);
                            return;
                        }
                        if (QuotationData.contractorInfo.phoneNumber === undefined) QuotationData.contractorInfo.phoneNumber = "";
                        if (QuotationData.contractorInfo.phoneNumber === "" || QuotationData.contractorInfo.phoneNumber.length < 10 || !(validator.isMobilePhone(QuotationData.contractorInfo.phoneNumber, 'it-IT'))) {
                            setAlertMsg("Numero cellulare non valido");
                            setAlertSeverity("error");
                            setOpen(true);
                            return;
                        }

                        let defOrg = JSON.parse(posinfo.defOrg);
                        let defGrp = JSON.parse(posinfo.defGrp);
                        let defAg = JSON.parse(posinfo.defAg);
                        let n = 0;
                        for (const cvt of guarantees) {
                            if (defAg !== null && defAg["INF_" + cvt.code] !== undefined ? true : (defGrp !== null && defGrp["INF_" + cvt.code] !== undefined ? true : (defOrg !== null && defOrg["INF_" + cvt.code] !== undefined ? true : false))) {
                                let flag = (defAg !== null && defAg["INF_" + cvt.code] !== undefined ? defAg["INF_" + cvt.code] : (defGrp !== null && defGrp["INF_" + cvt.code] !== undefined ? defGrp["INF_" + cvt.code] : (defOrg !== null && defOrg["INF_" + cvt.code] !== undefined ? defOrg["INF_" + cvt.code] : 0)));
                                if (flag > 0) {
                                    guaranteesSelect[n] = true;
                                }
                            }
                            n++;
                        }

                        if (QuotationData.contractorInfo.subjectType === "" || QuotationData.contractorInfo.subjectType === "1") {
                            getInfoBirth(token, urlservicequotiamo, QuotationData.contractorInfo.fiscalCode, QuotationData.contractorInfo.name, QuotationData.contractorInfo.surname).then(
                                payload => {
                                    if (payload.data.status !== undefined) {
                                        setAlertMsg(payload.data.message);
                                        setAlertSeverity("error");
                                        setOpen(true);
                                        return;
                                    } else {
                                        setActiveStep((prevActiveStep) => prevActiveStep + 1);
                                    }
                                }
                            );
                        } else {
                            setActiveStep((prevActiveStep) => prevActiveStep + 1);
                        }
                    }
                ).catch(error => {
                    setAlertMsg("Prodotto non attivo per questo punto vendita");
                    setAlertSeverity("error");
                    setOpen(true);
                    return;
                });
                break;
            case 1:
                if (guarantees.length < 1) {
                    setAlertMsg("Nessuna garanzia selezionabile, contattare l'admin di sistema");
                    setAlertSeverity("error");
                    setOpen(true);
                    break;
                }
                setClausole([])
                let almeno_uno = false;
                let check_value = false;
                let error_msg = "";
                guaranteesValue.forEach((element, index) => {
                    if (guaranteesSelect[index]) {
                        almeno_uno = true;
                        if (guarantees[index].input_type !== 0) {
                            if (guaranteesValue[index] === "") {
                                check_value = true;
                                error_msg += guarantees[index].description + " ";
                            }
                        }
                    }
                });
                if (!almeno_uno) {
                    setAlertMsg("Deve essere selezionata almeno una garanzia");
                    setAlertSeverity("error");
                    setOpen(true);
                    break;
                }
                if (check_value !== false) {
                    setAlertMsg("Mancano dei massimali: ( " + error_msg + " )");
                    setAlertSeverity("error");
                    setOpen(true);
                    break;
                }
                if (checkClausolaAttP("P", clausole, guaranteesSelect, guarantees) === false) {
                    setClausolaData("P");
                }
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                break;
            case 2:
                QuotationData.clausole = clausole;
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                break;
            case 3:
                QuotationData.questionarioSanitario = false;
                QuotationData.questionario = {};
                // check questionari
                //if (checkQuestionarioInfortunio(guarantees, guaranteesSelect, classes) === false) {
                if (questionarioInf.inf_domanda_1 === "" || questionarioInf.inf_domanda_2 === "" || questionarioInf.inf_domanda_3 === "") {
                    setAlertMsg("Il Questionario INFORTUNIO non è stato compilato completamente");
                    setAlertSeverity("error");
                    setOpen(true);
                    return;
                }
                if (questionarioInf.inf_domanda_1 === "empty" || questionarioInf.inf_domanda_2 === "empty" || questionarioInf.inf_domanda_3 === "empty") {
                    setAlertMsg("Il Questionario INFORTUNIO non è stato compilato completamente");
                    setAlertSeverity("error");
                    setOpen(true);
                    return;
                }

                if (questionarioInf.inf_domanda_1 === "yes") {
                    if (questionarioInf.inf_domanda_1_1 === undefined || questionarioInf.inf_domanda_1_1.trim() === "") {
                        setAlertMsg("Questionario INFORTUNIO - Compilare la domanda 1.1");
                        setAlertSeverity("error");
                        setOpen(true);
                        return;
                    }
                    QuotationData.questionarioSanitario = true;
                }
                if (questionarioInf.inf_domanda_2 === "yes") {
                    if (questionarioInf.inf_domanda_2_1 === undefined || questionarioInf.inf_domanda_2_1.trim() === "") {
                        setAlertMsg("Questionario INFORTUNIO - Compilare la domanda 2.1");
                        setAlertSeverity("error");
                        setOpen(true);
                        return;
                    }
                    QuotationData.questionarioSanitario = true;
                }
                if (questionarioInf.inf_domanda_3 === "yes") {
                    if (questionarioInf.inf_domanda_3_1 === undefined || questionarioInf.inf_domanda_3_1.trim() === "") {
                        setAlertMsg("Questionario INFORTUNIO - Compilare la domanda 3.1");
                        setAlertSeverity("error");
                        setOpen(true);
                        return;
                    }
                    QuotationData.questionarioSanitario = true;
                }

                QuotationData.questionario.questionarioInfortunio = questionarioInf;
                //}
                if (checkQuestionarioMalattia(guarantees, guaranteesSelect, classes) === false) {
                    if (questionarioMal.mal_domanda_1 === "empty" || questionarioMal.mal_domanda_2 === "empty" || questionarioMal.mal_domanda_3 === "empty" ||
                        questionarioMal.mal_domanda_4 === "empty" || questionarioMal.mal_domanda_5 === "empty" || questionarioMal.mal_domanda_6 === "empty" ||
                        questionarioMal.mal_domanda_10 === "empty" || questionarioMal.mal_domanda_11 === "empty" || questionarioMal.mal_domanda_12 === "empty" ||
                        questionarioMal.mal_domanda_13 === "empty" || questionarioMal.mal_domanda_14 === "empty" || questionarioMal.mal_domanda_15 === "empty" ||
                        questionarioMal.mal_domanda_16 === "empty" || questionarioMal.mal_domanda_17 === "empty" || questionarioMal.mal_domanda_18 === "empty") {
                        setAlertMsg("Il Questionario MALATTIA non è stato compilato completamente");
                        setAlertSeverity("error");
                        setOpen(true);
                        return;
                    }
                    if (QuotationData.contractorInfo.gender === "F") {
                        if (questionarioMal.mal_domanda_7 === "empty" || questionarioMal.mal_domanda_8 === "empty" || questionarioMal.mal_domanda_9 === "empty") {
                            setAlertMsg("Il Questionario MALATTIA non è stato compilato completamente");
                            setAlertSeverity("error");
                            setOpen(true);
                            return;
                        }
                    }
                    if (questionarioMal.mal_domanda_1 === "" || questionarioMal.mal_domanda_2 === "" || questionarioMal.mal_domanda_3 === "" ||
                        questionarioMal.mal_domanda_4 === "" || questionarioMal.mal_domanda_5 === "" || questionarioMal.mal_domanda_6 === "" ||
                        questionarioMal.mal_domanda_10 === "" || questionarioMal.mal_domanda_11 === "" || questionarioMal.mal_domanda_12 === "" ||
                        questionarioMal.mal_domanda_13 === "" || questionarioMal.mal_domanda_14 === "" || questionarioMal.mal_domanda_15 === "" ||
                        questionarioMal.mal_domanda_16 === "" || questionarioMal.mal_domanda_17 === "" || questionarioMal.mal_domanda_18 === "") {
                        setAlertMsg("Il Questionario MALATTIA non è stato compilato completamente");
                        setAlertSeverity("error");
                        setOpen(true);
                        return;
                    }
                    if (QuotationData.contractorInfo.gender === "F") {
                        if (questionarioMal.mal_domanda_7 === "" || questionarioMal.mal_domanda_8 === "" || questionarioMal.mal_domanda_9 === "") {
                            setAlertMsg("Il Questionario MALATTIA non è stato compilato completamente");
                            setAlertSeverity("error");
                            setOpen(true);
                            return;
                        }
                    }

                    if (questionarioMal.mal_domanda_1 === "yes") {
                        if (questionarioMal.mal_domanda_1_1 === undefined || questionarioMal.mal_domanda_1_1.trim() === "") {
                            setAlertMsg("Questionario MALATTIA - Compilare la domanda 1.1");
                            setAlertSeverity("error");
                            setOpen(true);
                            return;
                        }
                        QuotationData.questionarioSanitario = true;
                    }
                    if (questionarioMal.mal_domanda_2 === "yes") {
                        if (questionarioMal.mal_domanda_2_1 === undefined || questionarioMal.mal_domanda_2_1.trim() === "") {
                            setAlertMsg("Questionario MALATTIA - Compilare la domanda 2.1");
                            setAlertSeverity("error");
                            setOpen(true);
                            return;
                        }
                        QuotationData.questionarioSanitario = true;
                    }
                    if (questionarioMal.mal_domanda_3 === "yes") {
                        if (questionarioMal.mal_domanda_3_1 === undefined || questionarioMal.mal_domanda_3_1.trim() === "") {
                            setAlertMsg("Questionario MALATTIA - Compilare la domanda 3.1");
                            setAlertSeverity("error");
                            setOpen(true);
                            return;
                        }
                        QuotationData.questionarioSanitario = true;
                    }
                    if (questionarioMal.mal_domanda_4 === "yes") {
                        if (questionarioMal.mal_domanda_4_1 === undefined || questionarioMal.mal_domanda_4_1.trim() === "") {
                            setAlertMsg("Questionario MALATTIA - Compilare la domanda 4.1");
                            setAlertSeverity("error");
                            setOpen(true);
                            return;
                        }
                        QuotationData.questionarioSanitario = true;
                    }
                    if (questionarioMal.mal_domanda_5 === "yes") {
                        if (questionarioMal.mal_domanda_5_1 === undefined || questionarioMal.mal_domanda_5_1.trim() === "") {
                            setAlertMsg("Questionario MALATTIA - Compilare la domanda 5.1");
                            setAlertSeverity("error");
                            setOpen(true);
                            return;
                        }
                        QuotationData.questionarioSanitario = true;
                    }
                    if (questionarioMal.mal_domanda_6 === "yes") {
                        if (questionarioMal.mal_domanda_6_1 === undefined || questionarioMal.mal_domanda_6_1.trim() === "") {
                            setAlertMsg("Questionario MALATTIA - Compilare la domanda 6.1");
                            setAlertSeverity("error");
                            setOpen(true);
                            return;
                        }
                        QuotationData.questionarioSanitario = true;
                    }
                    if (QuotationData.contractorInfo.gender === "F") {
                        if (questionarioMal.mal_domanda_7 === "yes") {
                            if (questionarioMal.mal_domanda_7_1 === undefined || questionarioMal.mal_domanda_7_1.trim() === "") {
                                setAlertMsg("Questionario MALATTIA - Compilare la domanda 7.1");
                                setAlertSeverity("error");
                                setOpen(true);
                                return;
                            }
                            QuotationData.questionarioSanitario = true;
                        }
                        if (questionarioMal.mal_domanda_8 === "yes") {
                            if (questionarioMal.mal_domanda_8_1 === undefined || questionarioMal.mal_domanda_8_1.trim() === "") {
                                setAlertMsg("Questionario MALATTIA - Compilare la domanda 8.1");
                                setAlertSeverity("error");
                                setOpen(true);
                                return;
                            }
                            QuotationData.questionarioSanitario = true;
                        }
                        if (questionarioMal.mal_domanda_9 === "yes") {
                            if (questionarioMal.mal_domanda_9_1 === undefined || questionarioMal.mal_domanda_9_1.trim() === "") {
                                setAlertMsg("Questionario MALATTIA - Compilare la domanda 9.1");
                                setAlertSeverity("error");
                                setOpen(true);
                                return;
                            }
                            QuotationData.questionarioSanitario = true;
                        }
                    }
                    if (questionarioMal.mal_domanda_10 === "yes") {
                        if (questionarioMal.mal_domanda_10_1 === undefined || questionarioMal.mal_domanda_10_1.trim() === "") {
                            setAlertMsg("Questionario MALATTIA - Compilare la domanda 10.1");
                            setAlertSeverity("error");
                            setOpen(true);
                            return;
                        }
                        QuotationData.questionarioSanitario = true;
                    }
                    if (questionarioMal.mal_domanda_11 === "yes") {
                        if (questionarioMal.mal_domanda_11_1 === undefined || questionarioMal.mal_domanda_11_1.trim() === "") {
                            setAlertMsg("Questionario MALATTIA - Compilare la domanda 11.1");
                            setAlertSeverity("error");
                            setOpen(true);
                            return;
                        }
                        QuotationData.questionarioSanitario = true;
                    }
                    if (questionarioMal.mal_domanda_12 === "yes") {
                        if (questionarioMal.mal_domanda_12_1 === undefined || questionarioMal.mal_domanda_12_1.trim() === "") {
                            setAlertMsg("Questionario MALATTIA - Compilare la domanda 12.1");
                            setAlertSeverity("error");
                            setOpen(true);
                            return;
                        }
                        QuotationData.questionarioSanitario = true;
                    }
                    if (questionarioMal.mal_domanda_13 === "yes") {
                        if (questionarioMal.mal_domanda_13_1 === undefined || questionarioMal.mal_domanda_13_1.trim() === "") {
                            setAlertMsg("Questionario MALATTIA - Compilare la domanda 13.1");
                            setAlertSeverity("error");
                            setOpen(true);
                            return;
                        }
                        QuotationData.questionarioSanitario = true;
                    }
                    if (questionarioMal.mal_domanda_14 === "yes") {
                        if (questionarioMal.mal_domanda_14_1 === undefined || questionarioMal.mal_domanda_14_1.trim() === "") {
                            setAlertMsg("Questionario MALATTIA - Compilare la domanda 14.1");
                            setAlertSeverity("error");
                            setOpen(true);
                            return;
                        }
                        QuotationData.questionarioSanitario = true;
                    }
                    if (questionarioMal.mal_domanda_15 === "yes") {
                        if (questionarioMal.mal_domanda_15_1 === undefined || questionarioMal.mal_domanda_15_1.trim() === "") {
                            setAlertMsg("Questionario MALATTIA - Compilare la domanda 15.1");
                            setAlertSeverity("error");
                            setOpen(true);
                            return;
                        }
                        QuotationData.questionarioSanitario = true;
                    }
                    if (questionarioMal.mal_domanda_16 === "yes") {
                        if (questionarioMal.mal_domanda_16_1 === undefined || questionarioMal.mal_domanda_16_1.trim() === "") {
                            setAlertMsg("Questionario MALATTIA - Compilare la domanda 16.1");
                            setAlertSeverity("error");
                            setOpen(true);
                            return;
                        }
                        QuotationData.questionarioSanitario = true;
                    }
                    if (questionarioMal.mal_domanda_17 === "yes") {
                        if (questionarioMal.mal_domanda_17_1 === undefined || questionarioMal.mal_domanda_17_1.trim() === "") {
                            setAlertMsg("Questionario MALATTIA - Compilare la domanda 17.1");
                            setAlertSeverity("error");
                            setOpen(true);
                            return;
                        }
                        QuotationData.questionarioSanitario = true;
                    }
                    if (questionarioMal.mal_domanda_18 === "yes") {
                        if (questionarioMal.mal_domanda_18_1 === undefined || questionarioMal.mal_domanda_18_1.trim() === "") {
                            setAlertMsg("Questionario MALATTIA - Compilare la domanda 18.1");
                            setAlertSeverity("error");
                            setOpen(true);
                            return;
                        }
                        QuotationData.questionarioSanitario = true;
                    }

                    QuotationData.questionario.questionarioMalattia = questionarioMal;
                }

                AuthService.signin(posinfo.username, posinfo.password, posinfo.url).then(
                    payload => {
                        setToken(payload);
                        checkcompanyData(Auth.getToken(), pos).then(
                            payload => {
                                let companyListPrev = payload.data.services;
                                let conventionslist = payload.data.conventions;
                                setFactoriesHub(payload.data.factorieshub);

                                companyListPrev.forEach((company, indexcompany) => {
                                    guarantees.forEach((element, index) => {
                                        if (guaranteesSelect[index]) {
                                            if (element["input_type"] !== 0) {
                                                companyListPrev[indexcompany].guarantees_info[index].massimale = Number(guaranteesValue[index]);
                                            }
                                        }
                                    });
                                });

                                setcompanyData(companyListPrev);
                                let AddOnService = [];

                                setPrevRS(0);
                                setPrevRQ(companyListPrev.length);
                                contaPreventiviDaFare = companyListPrev.length;
                                contaPreventiviFatti = 0;

                                companyListPrev.map((company) => (
                                    checkPreventivo(QuotationData, company, token, guarantees, guaranteesSelect, guaranteesValue, conventionslist, Auth.getToken(), franchigiaInvPerm, franchigiaInabTemp).then(
                                        payload => {
                                            const newDatiPreventivo = datipreventivo;
                                            if (payload.data.prices !== undefined) {
                                                payload.data.prices.forEach(element => {
                                                    let convenzioneAttiva = CheckConvenzioni(QuotationData, company, conventionslist, 1);
                                                    element.convenzioneAttiva = convenzioneAttiva;
                                                    if (element.convenzioneAttiva.code === "") {
                                                        convenzioneAttiva = CheckConvenzioni(QuotationData, company, conventionslist, 0);
                                                        element.convenzioneAttiva = convenzioneAttiva;
                                                        if (convenzioneAttiva !== false && convenzioneAttiva.code !== "") {
                                                            //Al momento sovrascrire qualsiasi sconto commerciale
                                                            //Dopo vedere il flag
                                                            company.discount_description = convenzioneAttiva.description;
                                                            company.discounts_rules = convenzioneAttiva.discounts_rules;
                                                        }
                                                    }

                                                    let sconto_totale = 0;
                                                    let nettoTotalePartenza = 0;
                                                    let nettoTotaleScontato = 0;
                                                    let differenzaImposte = 0;
                                                    if (company.discount_description !== null && company.discounts_rules !== null) {
                                                        element.listResponseCVT.forEach(
                                                            cvt => {
                                                                if (cvt.code !== "CFE" && cvt.code !== "TAX") {
                                                                    let selectCVT = "";
                                                                    nettoTotalePartenza += cvt.price;
                                                                    cvt.originalprice = cvt.price;
                                                                    selectCVT = cvt.code;
                                                                    if (selectCVT !== "") {
                                                                        JSON.parse(company.discounts_rules).forEach(
                                                                            single_discount_rules => {
                                                                                if (single_discount_rules.hasOwnProperty(selectCVT)) {
                                                                                    if (single_discount_rules[selectCVT].val !== null && single_discount_rules[selectCVT].val !== "0" &&
                                                                                        cvt.price >= parseFloat(single_discount_rules[selectCVT].val)) {
                                                                                        sconto_totale += parseFloat(single_discount_rules[selectCVT].val);
                                                                                        cvt.price -= parseFloat(single_discount_rules[selectCVT].val);
                                                                                    }
                                                                                    if (single_discount_rules[selectCVT].perc !== null && single_discount_rules[selectCVT].perc !== "0") {
                                                                                        sconto_totale += (cvt.price * (parseFloat(single_discount_rules[selectCVT].perc))) / 100;
                                                                                        cvt.price -= (cvt.price * (parseFloat(single_discount_rules[selectCVT].perc))) / 100;
                                                                                    }
                                                                                    nettoTotaleScontato += cvt.price;
                                                                                }
                                                                            }
                                                                        )
                                                                    }
                                                                }
                                                            }
                                                        );

                                                        if (company.paramurl === "groupamanexus") {
                                                            element.listResponseCVT.forEach(
                                                                cvt => {
                                                                    if (cvt.code === "TAX") {
                                                                        let imposteTotale = cvt.price;
                                                                        let scontoPercTax = (nettoTotaleScontato * 100) / nettoTotalePartenza;
                                                                        cvt.price = (cvt.price * scontoPercTax) / 100;
                                                                        differenzaImposte = imposteTotale - cvt.price;
                                                                    }
                                                                }
                                                            );
                                                        }
                                                    }
                                                    element.TotalPrice -= parseFloat(sconto_totale);
                                                    element.price -= parseFloat(sconto_totale);
                                                    if (company.paramurl === "groupamanexus") {
                                                        element.TotalPrice -= parseFloat(differenzaImposte);
                                                        element.price -= parseFloat(differenzaImposte);
                                                    }

                                                    let addCalcoloCvt = 0;
                                                    guarantees.forEach((elementGuar, indexGuar) => {
                                                        if (guaranteesSelect[indexGuar]) {
                                                            let calcoloPercValue = 0;
                                                            let tipoCalcolo = company.guarantees_info[indexGuar].tipo_calcolo !== null ? (company.guarantees_info[indexGuar].tipo_calcolo !== "default" ? company.guarantees_info[indexGuar].tipo_calcolo : company.guarantees_info[indexGuar].tipo_calcolo_default) : company.guarantees_info[indexGuar].tipo_calcolo_default;
                                                            let serviceParameters = company.guarantees_info[indexGuar].tipo_calcolo !== null ? (company.guarantees_info[indexGuar].tipo_calcolo !== "default" ? company.guarantees_info[indexGuar].service_parameters : company.guarantees_info[indexGuar].service_parameters_default) : company.guarantees_info[indexGuar].service_parameters_default;
                                                            let tipoArrotondamento = company.guarantees_info[indexGuar].tipo_arrotondamento_calcolo;
                                                            if (tipoCalcolo === "perc") {
                                                                calcoloPercValue = parseFloat2Decimals((element.price * (Number(serviceParameters))) / 100);
                                                                if (tipoArrotondamento === "sup") {
                                                                    calcoloPercValue = Math.ceil(calcoloPercValue);
                                                                } else if (tipoArrotondamento === "inf") {
                                                                    calcoloPercValue = Math.floor(calcoloPercValue);
                                                                } else if (tipoArrotondamento === "mat") {
                                                                    calcoloPercValue = Math.round(calcoloPercValue);
                                                                }
                                                                addCalcoloCvt += calcoloPercValue;
                                                                let guarInfo =
                                                                {
                                                                    code: elementGuar["code"],
                                                                    nameCVT: elementGuar["description"],
                                                                    selected: true,
                                                                    price: calcoloPercValue
                                                                };
                                                                element.listResponseCVT.push(guarInfo);
                                                            } else if (tipoCalcolo === "value") {
                                                                calcoloPercValue = Number(serviceParameters);
                                                                addCalcoloCvt += parseFloat2Decimals(calcoloPercValue);
                                                                let guarInfo =
                                                                {
                                                                    code: elementGuar["code"],
                                                                    nameCVT: elementGuar["description"],
                                                                    selected: true,
                                                                    price: parseFloat2Decimals(calcoloPercValue)
                                                                };
                                                                element.listResponseCVT.push(guarInfo);
                                                            }
                                                        }
                                                    });

                                                    element.TotalPrice += addCalcoloCvt;
                                                    element.price += addCalcoloCvt;

                                                    let NumRate = 1;
                                                    let splitPrice = element.price;

                                                    if (element.splittingUp !== undefined && !isNaN(element.splittingUp)) {
                                                        NumRate = parseFloat(element.splittingUp);
                                                    }
                                                    if (element.splitPrice !== undefined && !isNaN(element.splitPrice)) {
                                                        splitPrice = parseFloat(element.splitPrice);
                                                    } else {
                                                        splitPrice = element.price;
                                                    }

                                                    splitPrice += (addCalcoloCvt / NumRate).toFixed(2);

                                                    // COMMISSIONI
                                                    if (company.FinalCommissionsType !== null && company.FinalCommissionsType === "euro") {
                                                        if (Number(company.FinalCommissionsDefault) >= 0) {
                                                            element.Commissions = company.FinalCommissionsDefault * NumRate;
                                                        } else {
                                                            if (Number(company.FinalCommissionsMax)) {
                                                                element.Commissions = company.FinalCommissionsMax * NumRate;
                                                            } else {
                                                                element.Commissions = 0;
                                                            }
                                                        }
                                                    } else {
                                                        if (Number(company.FinalCommissionsDefault) >= 0) {
                                                            element.Commissions = element.price * company.FinalCommissionsDefault / 100;
                                                            element.Commissions = element.Commissions.toFixed(2);
                                                        } else {
                                                            if (Number(company.FinalCommissionsMax)) {
                                                                element.Commissions = element.price * company.FinalCommissionsMax / 100;
                                                                element.Commissions = element.Commissions.toFixed(2);
                                                            } else {
                                                                element.Commissions = 0;
                                                            }
                                                        }
                                                    }

                                                    // DIRITTI
                                                    if (company.FinalfeeType !== null && company.FinalfeeType === "perc") {
                                                        if (Number(company.FinalfeeDefault) >= 0) {
                                                            element.Fees = element.price * company.FinalfeeDefault / 100;
                                                            element.Fees = element.Fees.toFixed(2);
                                                        } else {
                                                            if (Number(company.FinalfeeMax)) {
                                                                element.Fees = element.price * company.FinalfeeMax / 100;
                                                                element.Fees = element.Fees.toFixed(2);
                                                            } else {
                                                                element.Fees = 0;
                                                            }
                                                        }
                                                    } else {
                                                        if (Number(company.FinalfeeDefault) >= 0) {
                                                            element.Fees = company.FinalfeeDefault * NumRate;
                                                        } else {
                                                            if (Number(company.FinalfeeMax)) {
                                                                element.Fees = company.FinalfeeMax * NumRate;
                                                            } else {
                                                                element.Fees = 0;
                                                            }
                                                        }
                                                    }

                                                    element.TotalPrice = parseFloat(element.price) + parseFloat(element.Commissions) + parseFloat(element.Fees);

                                                    element.ListService = [];
                                                    AddOnService.forEach(service => {
                                                        let priceservice = 0;
                                                        if (service.type === "perc") {
                                                            priceservice = element.price * service.value / 100;
                                                            priceservice = parseFloat(priceservice);
                                                        } else {
                                                            priceservice = service.value;
                                                        }
                                                        element.TotalPrice += parseFloat(priceservice);

                                                        let ServiceInfo =
                                                        {
                                                            description: "NetAssistance",
                                                            priceservice: priceservice
                                                        };
                                                        element.ListService.push(ServiceInfo);
                                                    });

                                                    element.TotalRate = parseFloat(element.TotalPrice / NumRate);
                                                    element.TotalRate = element.TotalRate.toFixed(2);
                                                    element.only_cvt = company.only_cvt;
                                                });
                                            }

                                            newDatiPreventivo[company.sfo_id + company.paramurl + company.type] = payload.data;
                                            setdatipreventivo(newDatiPreventivo);
                                            setPrevRS(prevCount => prevCount + 1);
                                            contaPreventiviFatti++;

                                            if (contaPreventiviFatti === contaPreventiviDaFare && contaPreventiviDaFare > 0) {
                                                HandleSaveQuotation(companyListPrev);
                                            }

                                            if (prevRQ === prevRS) {
                                                setLoading(false);
                                            }
                                        }
                                    ).catch(error => {
                                        setPrevRS(prevCount => prevCount + 1);
                                        contaPreventiviFatti++;
                                        if (contaPreventiviFatti === contaPreventiviDaFare && contaPreventiviDaFare > 0) {
                                            HandleSaveQuotation(companyListPrev);
                                        }
                                        if (prevRQ === prevRS)
                                            setLoading(false);
                                    })
                                ));
                            }
                        ).catch(error => {
                            console.log(error);
                        });
                    }
                ).catch(error => {
                    setAlertMsg("Errore durante la connessione a QUOTIAMO Service");
                    setAlertSeverity("error");
                    setOpen(true);
                });

                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                break;
            case 4:
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                break;
            default:
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                break;
        }
    }

    const handleBack = () => {
        if (activeStep === 4) {
            window.stop();
            setdatipreventivo([]);
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const setClausolaData = (value) => {
        const exist = clausole.some(v => (v === value));
        if (exist === false) {
            const newList = clausole.concat(value);
            setClausole(newList);
        } else {
            const newList = clausole.filter((item) => item !== value);
            setClausole(newList);
        }
    };

    const checkChecked = (value) => {
        return clausole.some(v => (v === value));
    };

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return <>
                    <div className={classes.paper}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={4}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel id="labelPOS">Punto vendita</InputLabel>
                                    <Select
                                        labelId="labelPOS"
                                        id="pos"
                                        value={pos}
                                        onChange={handleChangePos}
                                        label="Punto vendita"
                                    >
                                        {agencieslists.length > 0 && agencieslists.map(option => (
                                            <MenuItem
                                                key={"ag_" + option.id}
                                                value={option.id}
                                            >
                                                {option.description}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <KeyboardDatePicker
                                    label="Data effetto"
                                    value={QuotationData.dataEffetto}
                                    onChange={handleChangeDataEffetto}
                                    format='dd/MM/yyyy'
                                    clearLabel="Conferma"
                                    cancelLabel="Annulla"
                                    name="dataEffetto"
                                    fullWidth
                                    required
                                    invalidDateMessage="Data non valida"
                                    // maxDateMessage="Non è possibile effettuare un preventivo con una scadenza superiore alla fine del mese successivo"
                                    minDateMessage="Il preventivo non può essere retroattivo"
                                    minDate={moment()}
                                    // maxDate={moment().add(1, "months").endOf('month')}
                                    maxDate={moment().add(30, "days")}
                                    inputVariant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    label="Frazionamento"
                                    name="frazionamento"
                                    onChange={handleChange}
                                    select
                                    SelectProps={{ native: true }}
                                    value={QuotationData.frazionamento}
                                    variant="outlined"
                                >
                                    <option
                                        key="NOFRAZ"
                                        value={0}
                                    >
                                        Seleziona il frazionamento
                                    </option>
                                    {tipoFrazionamento.map(option => (
                                        <option
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                    </div>
                    <SubjectDetails
                        datatype={"injuries"}
                        type={"contractorInfo"}
                        token={token}
                        urlservicequotiamo={urlservicequotiamo}
                        datiquotazione={QuotationData}
                        setdatiquotazione={setQuotationData}
                        listCity={listCity}
                        listProfessions={professioniInjuries}
                        posinfo={posinfo}
                    />
                </>;
            case 1:
                return <>
                    <div className={classes.paper}>
                        <Grid container spacing={0}>
                            {guarantees.map((warranty, index) => (
                                <>
                                    {(index === 0 || guarantees[index - 1].categoriesdescription !== warranty.categoriesdescription) &&
                                        <Grid key={"cat_" + index} item xs={12}>
                                            <Typography className={classes.TitleCategory}>
                                                {warranty.categoriesdescription}
                                            </Typography>
                                        </Grid>
                                    }
                                    <Grid key={index} item xs={2}>
                                        <CheckSvgButton disabled={posinfo.defAg !== null && JSON.parse(posinfo.defAg)["INF_" + warranty.code] !== undefined ? JSON.parse(posinfo.defAg)["INF_" + warranty.code] >= 2 : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp)["INF_" + warranty.code] !== undefined ? true : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg)["INF_" + warranty.code] !== undefined ? true : false)) &&
                                            (((posinfo.defAg !== null && JSON.parse(posinfo.defAg)["INF_" + warranty.code] !== undefined ? JSON.parse(posinfo.defAg)["INF_" + warranty.code] : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp)["INF_" + warranty.code] !== undefined ? JSON.parse(posinfo.defGrp)["INF_" + warranty.code] : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg)["INF_" + warranty.code] !== undefined ? JSON.parse(posinfo.defOrg)["INF_" + warranty.code] : 0))) & 2) >= 2)} label={warranty.description} svg={warranty.image} status={guaranteesSelect[index]} click={handleClick(index)} />
                                        <Box className={classes.BoxContainer}>
                                            {warranty.input_type === 1 &&
                                                <>
                                                    <NumberFormat disabled={!guaranteesSelect[index]} onValueChange={handleChangeFreeInput(index)} customInput={TextFieldCustomMassimale} fixedDecimalScale="true" decimalScale="0" value={guaranteesValue[index]} displayType={'input'} decimalSeparator={" "} thousandSeparator={"."} prefix={'€ '} />
                                                </>
                                            }
                                            {warranty.input_type === 2 &&
                                                <>
                                                    <TextField
                                                        disabled={!guaranteesSelect[index]}
                                                        fullWidth
                                                        label="Massimale"
                                                        onChange={handleChangeList(index)}
                                                        select
                                                        SelectProps={{ native: true }}
                                                        value={guaranteesValue[index]}
                                                        variant="outlined"
                                                    >
                                                        {warranty.list_value.split(",").map(option => (
                                                            <option
                                                                key={option}
                                                                value={option}
                                                            >
                                                                {option.indexOf("%") >= 0 && "" + option}
                                                                {option.indexOf("%") === -1 && "€ " + number_format(option, 0, ",", ".")}
                                                            </option>
                                                        ))}
                                                    </TextField>
                                                </>
                                            }
                                        </Box>
                                        {warranty.code === "INF_INVPER" &&
                                            <Box className={classes.BoxContainer}>
                                                <TextField
                                                    disabled={!guaranteesSelect[index]}
                                                    fullWidth
                                                    label="Franchigia"
                                                    onChange={handleChangeFranchigiaInvPerm(index)}
                                                    select
                                                    SelectProps={{ native: true }}
                                                    value={franchigiaInvPerm}
                                                    variant="outlined"
                                                >
                                                    {opzioniInvaliditaPermInjuries.map(option => (
                                                        <option
                                                            key={option.value}
                                                            value={option.value}
                                                        >
                                                            {option.label}
                                                        </option>
                                                    ))}
                                                </TextField>
                                            </Box>
                                        }
                                        {warranty.code === "INF_INTEMP" &&
                                            <Box className={classes.BoxContainer}>
                                                <TextField
                                                    disabled={!guaranteesSelect[index]}
                                                    fullWidth
                                                    label="Franchigia"
                                                    onChange={handleChangeFranchigiaInabTemp(index)}
                                                    select
                                                    SelectProps={{ native: true }}
                                                    value={franchigiaInabTemp}
                                                    variant="outlined"
                                                >
                                                    {opzioniInabilitaTempInjuries.map(option => (
                                                        <option
                                                            key={option.value}
                                                            value={option.value}
                                                        >
                                                            {option.label}
                                                        </option>
                                                    ))}
                                                </TextField>
                                            </Box>
                                        }
                                    </Grid>
                                </>
                            ))}
                        </Grid>
                    </div>
                </>;
            case 2:
                return <>
                    <div className={classes.paper}>
                        <Grid container spacing={0}>
                            {clausoleInjuries.map((clausola) => (
                                <>
                                    <Grid item xs={4}>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControlLabel
                                            aria-label="Clausola"
                                            onClick={(event) => {
                                                if (checkClausolaAtt(clausola.value, clausole, guaranteesSelect, guarantees, franchigiaInvPerm) === false) {
                                                    setClausolaData(clausola.value);
                                                    event.stopPropagation();
                                                }
                                            }}
                                            onFocus={(event) => event.stopPropagation()}
                                            control={<Checkbox name="clausola" checked={checkChecked(clausola.value)} />}
                                            label={clausola.label}
                                            disabled={checkClausolaAtt(clausola.value, clausole, guaranteesSelect, guarantees, franchigiaInvPerm)}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                    </Grid>
                                </>
                            ))}
                        </Grid>
                    </div>
                </>;
            case 3:
                return <>
                    <div className={classes.paper}>
                        <Accordion defaultExpanded={checkQuestionarioInfortunio(guarantees, guaranteesSelect, classes) === false} style={{ width: "100%" }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            //disabled={checkQuestionarioInfortunio(guarantees, guaranteesSelect, classes)}
                            >
                                <Typography className={classes.heading}>Questionario Infortunio</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TableContainer className={classes.table}>
                                    <Table aria-label="Questionario INFORTUNIO">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ width: "60%" }}>Domanda</TableCell>
                                                <TableCell>Risposta</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell style={{ width: "40%" }}>
                                                    1) L'assicurato ha in corso analoghe coperture assicurative con altre compagnie? (Non e' necessario dichiarare le coperture assicurative discendenti da CCNL/contratti integrativi/accordi aziendali e quelle accessorie a servizi bancari/di finanziamento)
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        margin="normal"
                                                        fullWidth
                                                        label="Domanda 1"
                                                        name="inf_domanda_1"
                                                        onChange={handleChangeValueInf}
                                                        select
                                                        SelectProps={{ native: true }}
                                                        value={questionarioInf === null ? "" : questionarioInf.inf_domanda_1 === undefined ? "" : questionarioInf.inf_domanda_1}
                                                        variant="outlined"
                                                    >
                                                        <option key="inf_domanda_1_vuoto" value={"empty"}>
                                                            Seleziona
                                                        </option>
                                                        <option key="inf_domanda_1_no" value={"no"}>
                                                            NO
                                                        </option>
                                                        <option key="inf_domanda_1_si" value={"yes"}>
                                                            SI
                                                        </option>
                                                    </TextField>
                                                </TableCell>
                                            </TableRow>
                                            {questionarioInf !== null && questionarioInf.inf_domanda_1 !== undefined && questionarioInf.inf_domanda_1 === "yes" &&
                                                <TableRow>
                                                    <TableCell style={{ width: "40%" }}>
                                                        Quante analoghe assicurazioni? Indicare: Compagnia altre assicurazioni, Capitale morte altre assicurazioni, Capitale IP altre assicurazioni
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            variant="outlined"
                                                            margin="normal"
                                                            fullWidth
                                                            id="inf_domanda_1_1"
                                                            label="Domanda 1.1"
                                                            name="inf_domanda_1_1"
                                                            size="medium"
                                                            multiline
                                                            rows={4}
                                                            value={questionarioInf === null ? "no" : questionarioInf.inf_domanda_1_1 === undefined ? "" : questionarioInf.inf_domanda_1_1}
                                                            onChange={handleChangeValueInf}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            }

                                            <TableRow>
                                                <TableCell style={{ width: "40%" }}>
                                                    2) L'assicurato ha avuto polizze analoghe annullate da altre compagnie nel corso degli ultimi 5 anni?
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        margin="normal"
                                                        fullWidth
                                                        label="Domanda 2"
                                                        name="inf_domanda_2"
                                                        onChange={handleChangeValueInf}
                                                        select
                                                        SelectProps={{ native: true }}
                                                        value={questionarioInf === null ? "" : questionarioInf.inf_domanda_2 === undefined ? "" : questionarioInf.inf_domanda_2}
                                                        variant="outlined"
                                                    >
                                                        <option key="inf_domanda_2_vuoto" value={"empty"}>
                                                            Seleziona
                                                        </option>
                                                        <option key="inf_domanda_2_no" value={"no"}>
                                                            NO
                                                        </option>
                                                        <option key="inf_domanda_2_si" value={"yes"}>
                                                            SI
                                                        </option>
                                                    </TextField>
                                                </TableCell>
                                            </TableRow>
                                            {questionarioInf !== null && questionarioInf.inf_domanda_2 !== undefined && questionarioInf.inf_domanda_2 === "yes" &&
                                                <TableRow>
                                                    <TableCell style={{ width: "40%" }}>
                                                        Quante polizze analoghe annullate da altre compagnie? Indicare: Compagnia analoghe assicurazioni annullate, Anno annullamento analoghe assicurazioni
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            variant="outlined"
                                                            margin="normal"
                                                            fullWidth
                                                            id="inf_domanda_2_1"
                                                            label="Domanda 2.1"
                                                            name="inf_domanda_2_1"
                                                            size="medium"
                                                            multiline
                                                            rows={4}
                                                            value={questionarioInf === null ? "no" : questionarioInf.inf_domanda_2_1 === undefined ? "" : questionarioInf.inf_domanda_2_1}
                                                            onChange={handleChangeValueInf}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            }

                                            <TableRow>
                                                <TableCell style={{ width: "40%" }}>
                                                    3) L'assicurato ha avuto infortuni da cui siano residuati minorazioni funzionali o postumi invalidanti?
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        margin="normal"
                                                        fullWidth
                                                        label="Domanda 3"
                                                        name="inf_domanda_3"
                                                        onChange={handleChangeValueInf}
                                                        select
                                                        SelectProps={{ native: true }}
                                                        value={questionarioInf === null ? "" : questionarioInf.inf_domanda_3 === undefined ? "" : questionarioInf.inf_domanda_3}
                                                        variant="outlined"
                                                    >
                                                        <option key="inf_domanda_3_vuoto" value={"empty"}>
                                                            Seleziona
                                                        </option>
                                                        <option key="inf_domanda_3_no" value={"no"}>
                                                            NO
                                                        </option>
                                                        <option key="inf_domanda_3_si" value={"yes"}>
                                                            SI
                                                        </option>
                                                    </TextField>
                                                </TableCell>
                                            </TableRow>
                                            {questionarioInf !== null && questionarioInf.inf_domanda_3 !== undefined && questionarioInf.inf_domanda_3 === "yes" &&
                                                <TableRow>
                                                    <TableCell style={{ width: "40%" }}>
                                                        Quanti infortuni con minorazioni funzionali o postumi invalidanti? Indicare: Anno dell'infortunio, Percentuale di invalidita' residuata minorazioni funzionali (1): (da compilare per l'infortunio oggetto di indennizzo assicurativo e con percentuale di invalidita' residua di almeno 10 punti), Descrizione infortunio
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            variant="outlined"
                                                            margin="normal"
                                                            fullWidth
                                                            id="inf_domanda_3_1"
                                                            label="Domanda 3.1"
                                                            name="inf_domanda_3_1"
                                                            size="medium"
                                                            multiline
                                                            rows={4}
                                                            value={questionarioInf === null ? "no" : questionarioInf.inf_domanda_3_1 === undefined ? "" : questionarioInf.inf_domanda_3_1}
                                                            onChange={handleChangeValueInf}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion defaultExpanded={checkQuestionarioMalattia(guarantees, guaranteesSelect, classes) === false} style={{ width: "100%" }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                disabled={checkQuestionarioMalattia(guarantees, guaranteesSelect, classes)}
                            >
                                <Typography className={classes.heading}>Questionario Malattia</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TableContainer className={classes.table}>
                                    <Table aria-label="Questionario MALATTIA">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ width: "60%" }}>Domanda</TableCell>
                                                <TableCell>Risposta</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell style={{ width: "40%" }}>
                                                    1) Le è mai stata riconosciuta da una Società di assicurazione una invalidità permanente?
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        margin="normal"
                                                        fullWidth
                                                        label="Domanda 1"
                                                        name="mal_domanda_1"
                                                        onChange={handleChangeValueMal}
                                                        select
                                                        SelectProps={{ native: true }}
                                                        value={questionarioMal === null ? "" : questionarioMal.mal_domanda_1 === undefined ? "" : questionarioMal.mal_domanda_1}
                                                        variant="outlined"
                                                    >
                                                        <option key="mal_domanda_1_vuoto" value={"empty"}>
                                                            Seleziona
                                                        </option>
                                                        <option key="mal_domanda_1_no" value={"no"}>
                                                            NO
                                                        </option>
                                                        <option key="mal_domanda_1_si" value={"yes"}>
                                                            SI
                                                        </option>
                                                    </TextField>
                                                </TableCell>
                                            </TableRow>
                                            {questionarioMal !== null && questionarioMal.mal_domanda_1 !== undefined && questionarioMal.mal_domanda_1 === "yes" &&
                                                <TableRow>
                                                    <TableCell style={{ width: "40%" }}>
                                                        Specificare: percentuale di invalidità, anno, infermità, Società
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            variant="outlined"
                                                            margin="normal"
                                                            fullWidth
                                                            id="mal_domanda_1_1"
                                                            label="Domanda 1.1"
                                                            name="mal_domanda_1_1"
                                                            size="medium"
                                                            multiline
                                                            rows={4}
                                                            value={questionarioMal === null ? "no" : questionarioMal.mal_domanda_1_1 === undefined ? "" : questionarioMal.mal_domanda_1_1}
                                                            onChange={handleChangeValueMal}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            }

                                            <TableRow>
                                                <TableCell style={{ width: "40%" }}>
                                                    2) Fruisce di pensione di Invalidità o ha presentato domanda per ottenerla?
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        margin="normal"
                                                        fullWidth
                                                        label="Domanda 2"
                                                        name="mal_domanda_2"
                                                        onChange={handleChangeValueMal}
                                                        select
                                                        SelectProps={{ native: true }}
                                                        value={questionarioMal === null ? "" : questionarioMal.mal_domanda_2 === undefined ? "" : questionarioMal.mal_domanda_2}
                                                        variant="outlined"
                                                    >
                                                        <option key="mal_domanda_2_vuoto" value={"empty"}>
                                                            Seleziona
                                                        </option>
                                                        <option key="mal_domanda_2_no" value={"no"}>
                                                            NO
                                                        </option>
                                                        <option key="mal_domanda_2_si" value={"yes"}>
                                                            SI
                                                        </option>
                                                    </TextField>
                                                </TableCell>
                                            </TableRow>
                                            {questionarioMal !== null && questionarioMal.mal_domanda_2 !== undefined && questionarioMal.mal_domanda_2 === "yes" &&
                                                <TableRow>
                                                    <TableCell style={{ width: "40%" }}>
                                                        Specificare: causa, percentuale di invalidità, anno, infermità, ente erogatore
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            variant="outlined"
                                                            margin="normal"
                                                            fullWidth
                                                            id="mal_domanda_2_1"
                                                            label="Domanda 2.1"
                                                            name="mal_domanda_2_1"
                                                            size="medium"
                                                            multiline
                                                            rows={4}
                                                            value={questionarioMal === null ? "no" : questionarioMal.mal_domanda_2_1 === undefined ? "" : questionarioMal.mal_domanda_2_1}
                                                            onChange={handleChangeValueMal}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            }

                                            <TableRow>
                                                <TableCell style={{ width: "40%" }}>
                                                    3) E' stato giudicato invalido civile?
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        margin="normal"
                                                        fullWidth
                                                        label="Domanda 3"
                                                        name="mal_domanda_3"
                                                        onChange={handleChangeValueMal}
                                                        select
                                                        SelectProps={{ native: true }}
                                                        value={questionarioMal === null ? "" : questionarioMal.mal_domanda_3 === undefined ? "" : questionarioMal.mal_domanda_3}
                                                        variant="outlined"
                                                    >
                                                        <option key="mal_domanda_3_vuoto" value={"empty"}>
                                                            Seleziona
                                                        </option>
                                                        <option key="mal_domanda_3_no" value={"no"}>
                                                            NO
                                                        </option>
                                                        <option key="mal_domanda_3_si" value={"yes"}>
                                                            SI
                                                        </option>
                                                    </TextField>
                                                </TableCell>
                                            </TableRow>
                                            {questionarioMal !== null && questionarioMal.mal_domanda_3 !== undefined && questionarioMal.mal_domanda_3 === "yes" &&
                                                <TableRow>
                                                    <TableCell style={{ width: "40%" }}>
                                                        Specificare: percentuale di invalidità, anno, infermità, ente erogatore
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            variant="outlined"
                                                            margin="normal"
                                                            fullWidth
                                                            id="mal_domanda_3_1"
                                                            label="Domanda 3.1"
                                                            name="mal_domanda_3_1"
                                                            size="medium"
                                                            multiline
                                                            rows={4}
                                                            value={questionarioMal === null ? "no" : questionarioMal.mal_domanda_3_1 === undefined ? "" : questionarioMal.mal_domanda_3_1}
                                                            onChange={handleChangeValueMal}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            }

                                            <TableRow>
                                                <TableCell style={{ width: "40%" }}>
                                                    4) Ha minorazioni funzionali od imperfezioni fisiche diverse da: miopia, astigmatismo, presbiopia, ipermetropia?
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        margin="normal"
                                                        fullWidth
                                                        label="Domanda 4"
                                                        name="mal_domanda_4"
                                                        onChange={handleChangeValueMal}
                                                        select
                                                        SelectProps={{ native: true }}
                                                        value={questionarioMal === null ? "" : questionarioMal.mal_domanda_4 === undefined ? "" : questionarioMal.mal_domanda_4}
                                                        variant="outlined"
                                                    >
                                                        <option key="mal_domanda_4_vuoto" value={"empty"}>
                                                            Seleziona
                                                        </option>
                                                        <option key="mal_domanda_4_no" value={"no"}>
                                                            NO
                                                        </option>
                                                        <option key="mal_domanda_4_si" value={"yes"}>
                                                            SI
                                                        </option>
                                                    </TextField>
                                                </TableCell>
                                            </TableRow>
                                            {questionarioMal !== null && questionarioMal.mal_domanda_4 !== undefined && questionarioMal.mal_domanda_4 === "yes" &&
                                                <TableRow>
                                                    <TableCell style={{ width: "40%" }}>
                                                        Specificare: cosa (udito, arti, altro da specificare...), da quando e di che grado
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            variant="outlined"
                                                            margin="normal"
                                                            fullWidth
                                                            id="mal_domanda_4_1"
                                                            label="Domanda 4.1"
                                                            name="mal_domanda_4_1"
                                                            size="medium"
                                                            multiline
                                                            rows={4}
                                                            value={questionarioMal === null ? "no" : questionarioMal.mal_domanda_4_1 === undefined ? "" : questionarioMal.mal_domanda_4_1}
                                                            onChange={handleChangeValueMal}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            }

                                            <TableRow>
                                                <TableCell style={{ width: "40%" }}>
                                                    5) Ha subito in passato traumi o infortuni che abbiano residuato postumi?
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        margin="normal"
                                                        fullWidth
                                                        label="Domanda 5"
                                                        name="mal_domanda_5"
                                                        onChange={handleChangeValueMal}
                                                        select
                                                        SelectProps={{ native: true }}
                                                        value={questionarioMal === null ? "" : questionarioMal.mal_domanda_5 === undefined ? "" : questionarioMal.mal_domanda_5}
                                                        variant="outlined"
                                                    >
                                                        <option key="mal_domanda_5_vuoto" value={"empty"}>
                                                            Seleziona
                                                        </option>
                                                        <option key="mal_domanda_5_no" value={"no"}>
                                                            NO
                                                        </option>
                                                        <option key="mal_domanda_5_si" value={"yes"}>
                                                            SI
                                                        </option>
                                                    </TextField>
                                                </TableCell>
                                            </TableRow>
                                            {questionarioMal !== null && questionarioMal.mal_domanda_5 !== undefined && questionarioMal.mal_domanda_5 === "yes" &&
                                                <TableRow>
                                                    <TableCell style={{ width: "40%" }}>
                                                        Specificare: cosa (cranio, organi di senso, atri, altro da specificare...), da quando e di che grado
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            variant="outlined"
                                                            margin="normal"
                                                            fullWidth
                                                            id="mal_domanda_5_1"
                                                            label="Domanda 5.1"
                                                            name="mal_domanda_5_1"
                                                            size="medium"
                                                            multiline
                                                            rows={4}
                                                            value={questionarioMal === null ? "no" : questionarioMal.mal_domanda_5_1 === undefined ? "" : questionarioMal.mal_domanda_5_1}
                                                            onChange={handleChangeValueMal}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            }

                                            <TableRow>
                                                <TableCell style={{ width: "40%" }}>
                                                    6) E' stato mai dichiarato non idoneo al lavoro?
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        margin="normal"
                                                        fullWidth
                                                        label="Domanda 6"
                                                        name="mal_domanda_6"
                                                        onChange={handleChangeValueMal}
                                                        select
                                                        SelectProps={{ native: true }}
                                                        value={questionarioMal === null ? "" : questionarioMal.mal_domanda_6 === undefined ? "" : questionarioMal.mal_domanda_6}
                                                        variant="outlined"
                                                    >
                                                        <option key="mal_domanda_6_vuoto" value={"empty"}>
                                                            Seleziona
                                                        </option>
                                                        <option key="mal_domanda_6_no" value={"no"}>
                                                            NO
                                                        </option>
                                                        <option key="mal_domanda_6_si" value={"yes"}>
                                                            SI
                                                        </option>
                                                    </TextField>
                                                </TableCell>
                                            </TableRow>
                                            {questionarioMal !== null && questionarioMal.mal_domanda_6 !== undefined && questionarioMal.mal_domanda_6 === "yes" &&
                                                <TableRow>
                                                    <TableCell style={{ width: "40%" }}>
                                                        Specificare: quando, motivo
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            variant="outlined"
                                                            margin="normal"
                                                            fullWidth
                                                            id="mal_domanda_6_1"
                                                            label="Domanda 6.1"
                                                            name="mal_domanda_6_1"
                                                            size="medium"
                                                            multiline
                                                            rows={4}
                                                            value={questionarioMal === null ? "no" : questionarioMal.mal_domanda_6_1 === undefined ? "" : questionarioMal.mal_domanda_6_1}
                                                            onChange={handleChangeValueMal}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            }

                                            {QuotationData.contractorInfo.gender === "F" &&
                                                <>
                                                    <TableRow>
                                                        <TableCell style={{ width: "40%" }}>
                                                            7) Ha alterazioni del ciclo e/o disturbi genitali o malattie delle mammelle?
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                margin="normal"
                                                                fullWidth
                                                                label="Domanda 7"
                                                                name="mal_domanda_7"
                                                                onChange={handleChangeValueMal}
                                                                select
                                                                SelectProps={{ native: true }}
                                                                value={questionarioMal === null ? "" : questionarioMal.mal_domanda_7 === undefined ? "" : questionarioMal.mal_domanda_7}
                                                                variant="outlined"
                                                            >
                                                                <option key="mal_domanda_7_vuoto" value={"empty"}>
                                                                    Seleziona
                                                                </option>
                                                                <option key="mal_domanda_7_no" value={"no"}>
                                                                    NO
                                                                </option>
                                                                <option key="mal_domanda_7_si" value={"yes"}>
                                                                    SI
                                                                </option>
                                                            </TextField>
                                                        </TableCell>
                                                    </TableRow>
                                                    {questionarioMal !== null && questionarioMal.mal_domanda_7 !== undefined && questionarioMal.mal_domanda_7 === "yes" &&
                                                        <TableRow>
                                                            <TableCell style={{ width: "40%" }}>
                                                                Specificare: quali
                                                            </TableCell>
                                                            <TableCell>
                                                                <TextField
                                                                    variant="outlined"
                                                                    margin="normal"
                                                                    fullWidth
                                                                    id="mal_domanda_7_1"
                                                                    label="Domanda 7.1"
                                                                    name="mal_domanda_7_1"
                                                                    size="medium"
                                                                    multiline
                                                                    rows={4}
                                                                    value={questionarioMal === null ? "no" : questionarioMal.mal_domanda_7_1 === undefined ? "" : questionarioMal.mal_domanda_7_1}
                                                                    onChange={handleChangeValueMal}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    }

                                                    <TableRow>
                                                        <TableCell style={{ width: "40%" }}>
                                                            8) E' incinta?
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                margin="normal"
                                                                fullWidth
                                                                label="Domanda 8"
                                                                name="mal_domanda_8"
                                                                onChange={handleChangeValueMal}
                                                                select
                                                                SelectProps={{ native: true }}
                                                                value={questionarioMal === null ? "" : questionarioMal.mal_domanda_8 === undefined ? "" : questionarioMal.mal_domanda_8}
                                                                variant="outlined"
                                                            >
                                                                <option key="mal_domanda_8_vuoto" value={"empty"}>
                                                                    Seleziona
                                                                </option>
                                                                <option key="mal_domanda_8_no" value={"no"}>
                                                                    NO
                                                                </option>
                                                                <option key="mal_domanda_8_si" value={"yes"}>
                                                                    SI
                                                                </option>
                                                            </TextField>
                                                        </TableCell>
                                                    </TableRow>
                                                    {questionarioMal !== null && questionarioMal.mal_domanda_8 !== undefined && questionarioMal.mal_domanda_8 === "yes" &&
                                                        <TableRow>
                                                            <TableCell style={{ width: "40%" }}>
                                                                Specificare: mese di gravidanza
                                                            </TableCell>
                                                            <TableCell>
                                                                <TextField
                                                                    variant="outlined"
                                                                    margin="normal"
                                                                    fullWidth
                                                                    id="mal_domanda_8_1"
                                                                    label="Domanda 8.1"
                                                                    name="mal_domanda_8_1"
                                                                    size="medium"
                                                                    multiline
                                                                    rows={4}
                                                                    value={questionarioMal === null ? "no" : questionarioMal.mal_domanda_8_1 === undefined ? "" : questionarioMal.mal_domanda_8_1}
                                                                    onChange={handleChangeValueMal}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    }

                                                    <TableRow>
                                                        <TableCell style={{ width: "40%" }}>
                                                            9) Precedenti gravidanze che abbiano comportato aborti o parti con taglio cesareo?
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                margin="normal"
                                                                fullWidth
                                                                label="Domanda 9"
                                                                name="mal_domanda_9"
                                                                onChange={handleChangeValueMal}
                                                                select
                                                                SelectProps={{ native: true }}
                                                                value={questionarioMal === null ? "" : questionarioMal.mal_domanda_9 === undefined ? "" : questionarioMal.mal_domanda_9}
                                                                variant="outlined"
                                                            >
                                                                <option key="mal_domanda_9_vuoto" value={"empty"}>
                                                                    Seleziona
                                                                </option>
                                                                <option key="mal_domanda_9_no" value={"no"}>
                                                                    NO
                                                                </option>
                                                                <option key="mal_domanda_9_si" value={"yes"}>
                                                                    SI
                                                                </option>
                                                            </TextField>
                                                        </TableCell>
                                                    </TableRow>
                                                    {questionarioMal !== null && questionarioMal.mal_domanda_9 !== undefined && questionarioMal.mal_domanda_9 === "yes" &&
                                                        <TableRow>
                                                            <TableCell style={{ width: "40%" }}>
                                                                Specificare: quante, quanti aborti, quanti parti cesarei
                                                            </TableCell>
                                                            <TableCell>
                                                                <TextField
                                                                    variant="outlined"
                                                                    margin="normal"
                                                                    fullWidth
                                                                    id="mal_domanda_9_1"
                                                                    label="Domanda 9.1"
                                                                    name="mal_domanda_9_1"
                                                                    size="medium"
                                                                    multiline
                                                                    rows={4}
                                                                    value={questionarioMal === null ? "no" : questionarioMal.mal_domanda_9_1 === undefined ? "" : questionarioMal.mal_domanda_9_1}
                                                                    onChange={handleChangeValueMal}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                </>
                                            }

                                            <TableRow>
                                                <TableCell style={{ width: "40%" }}>
                                                    10) E' attualmente in terapia farmacologica continuativa da più di un mese?
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        margin="normal"
                                                        fullWidth
                                                        label="Domanda 10"
                                                        name="mal_domanda_10"
                                                        onChange={handleChangeValueMal}
                                                        select
                                                        SelectProps={{ native: true }}
                                                        value={questionarioMal === null ? "" : questionarioMal.mal_domanda_10 === undefined ? "" : questionarioMal.mal_domanda_10}
                                                        variant="outlined"
                                                    >
                                                        <option key="mal_domanda_10_vuoto" value={"empty"}>
                                                            Seleziona
                                                        </option>
                                                        <option key="mal_domanda_10_no" value={"no"}>
                                                            NO
                                                        </option>
                                                        <option key="mal_domanda_10_si" value={"yes"}>
                                                            SI
                                                        </option>
                                                    </TextField>
                                                </TableCell>
                                            </TableRow>
                                            {questionarioMal !== null && questionarioMal.mal_domanda_10 !== undefined && questionarioMal.mal_domanda_10 === "yes" &&
                                                <TableRow>
                                                    <TableCell style={{ width: "40%" }}>
                                                        Specificare: quale, da quando e per quale motivo
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            variant="outlined"
                                                            margin="normal"
                                                            fullWidth
                                                            id="mal_domanda_10_1"
                                                            label="Domanda 10.1"
                                                            name="mal_domanda_10_1"
                                                            size="medium"
                                                            multiline
                                                            rows={4}
                                                            value={questionarioMal === null ? "no" : questionarioMal.mal_domanda_10_1 === undefined ? "" : questionarioMal.mal_domanda_10_1}
                                                            onChange={handleChangeValueMal}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            }

                                            <TableRow>
                                                <TableCell style={{ width: "40%" }}>
                                                    11) Soffre e/o è in trattamento per ipertensione arteriosa?
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        margin="normal"
                                                        fullWidth
                                                        label="Domanda 11"
                                                        name="mal_domanda_11"
                                                        onChange={handleChangeValueMal}
                                                        select
                                                        SelectProps={{ native: true }}
                                                        value={questionarioMal === null ? "" : questionarioMal.mal_domanda_11 === undefined ? "" : questionarioMal.mal_domanda_11}
                                                        variant="outlined"
                                                    >
                                                        <option key="mal_domanda_11_vuoto" value={"empty"}>
                                                            Seleziona
                                                        </option>
                                                        <option key="mal_domanda_11_no" value={"no"}>
                                                            NO
                                                        </option>
                                                        <option key="mal_domanda_11_si" value={"yes"}>
                                                            SI
                                                        </option>
                                                    </TextField>
                                                </TableCell>
                                            </TableRow>
                                            {questionarioMal !== null && questionarioMal.mal_domanda_11 !== undefined && questionarioMal.mal_domanda_11 === "yes" &&
                                                <TableRow>
                                                    <TableCell style={{ width: "40%" }}>
                                                        Specificare: da quando
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            variant="outlined"
                                                            margin="normal"
                                                            fullWidth
                                                            id="mal_domanda_11_1"
                                                            label="Domanda 11.1"
                                                            name="mal_domanda_11_1"
                                                            size="medium"
                                                            multiline
                                                            rows={4}
                                                            value={questionarioMal === null ? "no" : questionarioMal.mal_domanda_11_1 === undefined ? "" : questionarioMal.mal_domanda_11_1}
                                                            onChange={handleChangeValueMal}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            }

                                            <TableRow>
                                                <TableCell style={{ width: "40%" }}>
                                                    12) Ha mai subito interventi chirurgici diversi da: appendicectomia, adenoidectomia, tonsillectomia?
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        margin="normal"
                                                        fullWidth
                                                        label="Domanda 12"
                                                        name="mal_domanda_12"
                                                        onChange={handleChangeValueMal}
                                                        select
                                                        SelectProps={{ native: true }}
                                                        value={questionarioMal === null ? "" : questionarioMal.mal_domanda_12 === undefined ? "" : questionarioMal.mal_domanda_12}
                                                        variant="outlined"
                                                    >
                                                        <option key="mal_domanda_12_vuoto" value={"empty"}>
                                                            Seleziona
                                                        </option>
                                                        <option key="mal_domanda_12_no" value={"no"}>
                                                            NO
                                                        </option>
                                                        <option key="mal_domanda_12_si" value={"yes"}>
                                                            SI
                                                        </option>
                                                    </TextField>
                                                </TableCell>
                                            </TableRow>
                                            {questionarioMal !== null && questionarioMal.mal_domanda_12 !== undefined && questionarioMal.mal_domanda_12 === "yes" &&
                                                <TableRow>
                                                    <TableCell style={{ width: "40%" }}>
                                                        Specificare: quale, da quando e per quale motivo
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            variant="outlined"
                                                            margin="normal"
                                                            fullWidth
                                                            id="mal_domanda_12_1"
                                                            label="Domanda 12.1"
                                                            name="mal_domanda_12_1"
                                                            size="medium"
                                                            multiline
                                                            rows={4}
                                                            value={questionarioMal === null ? "no" : questionarioMal.mal_domanda_12_1 === undefined ? "" : questionarioMal.mal_domanda_12_1}
                                                            onChange={handleChangeValueMal}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            }

                                            <TableRow>
                                                <TableCell style={{ width: "40%" }}>
                                                    13) E' mai stato sottoposto a terapie radianti, ormonali, trattamenti con cellule staminali, emotrasfusioni e/o terapie a base di emoderivati?
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        margin="normal"
                                                        fullWidth
                                                        label="Domanda 13"
                                                        name="mal_domanda_13"
                                                        onChange={handleChangeValueMal}
                                                        select
                                                        SelectProps={{ native: true }}
                                                        value={questionarioMal === null ? "" : questionarioMal.mal_domanda_13 === undefined ? "" : questionarioMal.mal_domanda_13}
                                                        variant="outlined"
                                                    >
                                                        <option key="mal_domanda_13_vuoto" value={"empty"}>
                                                            Seleziona
                                                        </option>
                                                        <option key="mal_domanda_13_no" value={"no"}>
                                                            NO
                                                        </option>
                                                        <option key="mal_domanda_13_si" value={"yes"}>
                                                            SI
                                                        </option>
                                                    </TextField>
                                                </TableCell>
                                            </TableRow>
                                            {questionarioMal !== null && questionarioMal.mal_domanda_13 !== undefined && questionarioMal.mal_domanda_13 === "yes" &&
                                                <TableRow>
                                                    <TableCell style={{ width: "40%" }}>
                                                        Specificare: anno, tipo, durata, motivo
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            variant="outlined"
                                                            margin="normal"
                                                            fullWidth
                                                            id="mal_domanda_13_1"
                                                            label="Domanda 13.1"
                                                            name="mal_domanda_13_1"
                                                            size="medium"
                                                            multiline
                                                            rows={4}
                                                            value={questionarioMal === null ? "no" : questionarioMal.mal_domanda_13_1 === undefined ? "" : questionarioMal.mal_domanda_13_1}
                                                            onChange={handleChangeValueMal}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            }

                                            <TableRow>
                                                <TableCell style={{ width: "40%" }}>
                                                    14) Ha praticato negli ultimi 5 anni esami e/o accertamenti diagnostici dai quali sia emersa qualche alterazione?
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        margin="normal"
                                                        fullWidth
                                                        label="Domanda 14"
                                                        name="mal_domanda_14"
                                                        onChange={handleChangeValueMal}
                                                        select
                                                        SelectProps={{ native: true }}
                                                        value={questionarioMal === null ? "" : questionarioMal.mal_domanda_14 === undefined ? "" : questionarioMal.mal_domanda_14}
                                                        variant="outlined"
                                                    >
                                                        <option key="mal_domanda_14_vuoto" value={"empty"}>
                                                            Seleziona
                                                        </option>
                                                        <option key="mal_domanda_14_no" value={"no"}>
                                                            NO
                                                        </option>
                                                        <option key="mal_domanda_14_si" value={"yes"}>
                                                            SI
                                                        </option>
                                                    </TextField>
                                                </TableCell>
                                            </TableRow>
                                            {questionarioMal !== null && questionarioMal.mal_domanda_14 !== undefined && questionarioMal.mal_domanda_14 === "yes" &&
                                                <TableRow>
                                                    <TableCell style={{ width: "40%" }}>
                                                        Specificare: che esame (elettrocardiogramma, radiografie, elettroencefalogramma, esami del sangue, anti-HIV, HBsAg (epatite), esami urine, biopsie, T.A.C, altri specificare...) e quale alterazione
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            variant="outlined"
                                                            margin="normal"
                                                            fullWidth
                                                            id="mal_domanda_14_1"
                                                            label="Domanda 14.1"
                                                            name="mal_domanda_14_1"
                                                            size="medium"
                                                            multiline
                                                            rows={4}
                                                            value={questionarioMal === null ? "no" : questionarioMal.mal_domanda_14_1 === undefined ? "" : questionarioMal.mal_domanda_14_1}
                                                            onChange={handleChangeValueMal}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            }

                                            <TableRow>
                                                <TableCell style={{ width: "40%" }}>
                                                    15) Ha in programma ricoveri, day-hospital, interventi chirurgici, accertamenti diagnostici, cicli di terapia farmacologia o fisico riabilitativa?
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        margin="normal"
                                                        fullWidth
                                                        label="Domanda 15"
                                                        name="mal_domanda_15"
                                                        onChange={handleChangeValueMal}
                                                        select
                                                        SelectProps={{ native: true }}
                                                        value={questionarioMal === null ? "" : questionarioMal.mal_domanda_15 === undefined ? "" : questionarioMal.mal_domanda_15}
                                                        variant="outlined"
                                                    >
                                                        <option key="mal_domanda_15_vuoto" value={"empty"}>
                                                            Seleziona
                                                        </option>
                                                        <option key="mal_domanda_15_no" value={"no"}>
                                                            NO
                                                        </option>
                                                        <option key="mal_domanda_15_si" value={"yes"}>
                                                            SI
                                                        </option>
                                                    </TextField>
                                                </TableCell>
                                            </TableRow>
                                            {questionarioMal !== null && questionarioMal.mal_domanda_15 !== undefined && questionarioMal.mal_domanda_15 === "yes" &&
                                                <TableRow>
                                                    <TableCell style={{ width: "40%" }}>
                                                        Specificare: quando, tipo, motivi
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            variant="outlined"
                                                            margin="normal"
                                                            fullWidth
                                                            id="mal_domanda_15_1"
                                                            label="Domanda 15.1"
                                                            name="mal_domanda_15_1"
                                                            size="medium"
                                                            multiline
                                                            rows={4}
                                                            value={questionarioMal === null ? "no" : questionarioMal.mal_domanda_15_1 === undefined ? "" : questionarioMal.mal_domanda_15_1}
                                                            onChange={handleChangeValueMal}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            }

                                            <TableRow>
                                                <TableCell style={{ width: "40%" }}>
                                                    16) Ha sofferto o soffre di malattie che abbiano comportato cure mediche o chirurgiche, o terapie riabilitative?
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        margin="normal"
                                                        fullWidth
                                                        label="Domanda 16"
                                                        name="mal_domanda_16"
                                                        onChange={handleChangeValueMal}
                                                        select
                                                        SelectProps={{ native: true }}
                                                        value={questionarioMal === null ? "" : questionarioMal.mal_domanda_16 === undefined ? "" : questionarioMal.mal_domanda_16}
                                                        variant="outlined"
                                                    >
                                                        <option key="mal_domanda_16_vuoto" value={"empty"}>
                                                            Seleziona
                                                        </option>
                                                        <option key="mal_domanda_16_no" value={"no"}>
                                                            NO
                                                        </option>
                                                        <option key="mal_domanda_16_si" value={"yes"}>
                                                            SI
                                                        </option>
                                                    </TextField>
                                                </TableCell>
                                            </TableRow>
                                            {questionarioMal !== null && questionarioMal.mal_domanda_16 !== undefined && questionarioMal.mal_domanda_16 === "yes" &&
                                                <TableRow>
                                                    <TableCell style={{ width: "40%" }}>
                                                        Specificare: anno di insorgenza, tipo, durata e terapie pratiche
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            variant="outlined"
                                                            margin="normal"
                                                            fullWidth
                                                            id="mal_domanda_16_1"
                                                            label="Domanda 16.1"
                                                            name="mal_domanda_16_1"
                                                            size="medium"
                                                            multiline
                                                            rows={4}
                                                            value={questionarioMal === null ? "no" : questionarioMal.mal_domanda_16_1 === undefined ? "" : questionarioMal.mal_domanda_16_1}
                                                            onChange={handleChangeValueMal}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            }

                                            <TableRow>
                                                <TableCell style={{ width: "40%" }}>
                                                    17) Nello specifico, ha sofferto o soffre attualmente di una delle malattie di seguito elencate?
                                                    <br />
                                                    <b>APPARATO RESPIRATORIO</b>: asma, enfisema, infezioni bronco polmonari, pleurite, TBC, polipi delle corde vocali
                                                    <br />
                                                    <b>APPARATO CARDIOCIRCOLATORIO</b>: infarto miocardio, angina pectoris, ipertensione arteriosa, valvulopatie, arteriopatie, varici degli arti
                                                    inferiori, tromboflebiti
                                                    <br />
                                                    <b>APPARATO DIGERENTE</b>: ulcera gastroduodenale, rettocolite ulcerosa, epatite virale B-C, cirrosi, calcolosi biliare
                                                    <br />
                                                    <b>APPARATO UROGENITALE</b>: malattie infettive e infiammatorie del nefrone, calcolosi, insufficenza renale, ipertrofia della prostata, cisti
                                                    dell'ovaio, fibroma uterino, varicocele
                                                    <br />
                                                    <b>APPARATO OSTEOARTICOLARE</b>: artrite, artrosi, ernia del disco, lesioni del menisco o dei legamenti del ginocchio, alluce valgo,
                                                    patologie non trattate con protesi
                                                    <br />
                                                    <b>SISTEMA NERVOSO</b>: morbo di Parkinson, epilessia, sclerosi multipla, demenza (m. di Alzheimer), SLA
                                                    <br />
                                                    <b>ENDOCRINO METABOLICHE</b>: diabete mellito tipo 1-2, malattie della tiroide o delle ghiandole surrena
                                                    <br />
                                                    <b>DEL SANGUE</b>: anemia, leucemia
                                                    <br />
                                                    <b>DEL CONNETTIVO</b>: connettivite mista, lupus eritematoso sistemico, sclerodermia, poliartrite nodosa, reumatismo articolare
                                                    <br />
                                                    <b>DELL'OCCHIO</b>: cataratta, glaucoma, distacco di retina, malattie vitrali
                                                    <br />
                                                    <b>TUMORI MALIGNI E BENIGNI</b>
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        margin="normal"
                                                        fullWidth
                                                        label="Domanda 17"
                                                        name="mal_domanda_17"
                                                        onChange={handleChangeValueMal}
                                                        select
                                                        SelectProps={{ native: true }}
                                                        value={questionarioMal === null ? "" : questionarioMal.mal_domanda_17 === undefined ? "" : questionarioMal.mal_domanda_17}
                                                        variant="outlined"
                                                    >
                                                        <option key="mal_domanda_17_vuoto" value={"empty"}>
                                                            Seleziona
                                                        </option>
                                                        <option key="mal_domanda_17_no" value={"no"}>
                                                            NO
                                                        </option>
                                                        <option key="mal_domanda_17_si" value={"yes"}>
                                                            SI
                                                        </option>
                                                    </TextField>
                                                </TableCell>
                                            </TableRow>
                                            {questionarioMal !== null && questionarioMal.mal_domanda_17 !== undefined && questionarioMal.mal_domanda_17 === "yes" &&
                                                <TableRow>
                                                    <TableCell style={{ width: "40%" }}>
                                                        Specificare: quale, anno di insorgenza, durata e terapie pratiche
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            variant="outlined"
                                                            margin="normal"
                                                            fullWidth
                                                            id="mal_domanda_17_1"
                                                            label="Domanda 17.1"
                                                            name="mal_domanda_17_1"
                                                            size="medium"
                                                            multiline
                                                            rows={4}
                                                            value={questionarioMal === null ? "no" : questionarioMal.mal_domanda_17_1 === undefined ? "" : questionarioMal.mal_domanda_17_1}
                                                            onChange={handleChangeValueMal}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            }

                                            <TableRow>
                                                <TableCell style={{ width: "40%" }}>
                                                    18) Ulteriori informazioni di carattere sanitario?
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        margin="normal"
                                                        fullWidth
                                                        label="Domanda 18"
                                                        name="mal_domanda_18"
                                                        onChange={handleChangeValueMal}
                                                        select
                                                        SelectProps={{ native: true }}
                                                        value={questionarioMal === null ? "" : questionarioMal.mal_domanda_18 === undefined ? "" : questionarioMal.mal_domanda_18}
                                                        variant="outlined"
                                                    >
                                                        <option key="mal_domanda_18_vuoto" value={"empty"}>
                                                            Seleziona
                                                        </option>
                                                        <option key="mal_domanda_18_no" value={"no"}>
                                                            NO
                                                        </option>
                                                        <option key="mal_domanda_18_si" value={"yes"}>
                                                            SI
                                                        </option>
                                                    </TextField>
                                                </TableCell>
                                            </TableRow>
                                            {questionarioMal !== null && questionarioMal.mal_domanda_18 !== undefined && questionarioMal.mal_domanda_18 === "yes" &&
                                                <TableRow>
                                                    <TableCell style={{ width: "40%" }}>
                                                        Specificare
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            variant="outlined"
                                                            margin="normal"
                                                            fullWidth
                                                            id="mal_domanda_18_1"
                                                            label="Domanda 18.1"
                                                            name="mal_domanda_18_1"
                                                            size="medium"
                                                            multiline
                                                            rows={4}
                                                            value={questionarioMal === null ? "no" : questionarioMal.mal_domanda_18_1 === undefined ? "" : questionarioMal.mal_domanda_18_1}
                                                            onChange={handleChangeValueMal}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </>;
            case 4:
                return <Paper className={classes.paper}>
                    <div className={classes.progress}>{progress}</div>
                    {prevRS < prevRQ &&
                        <div className={classes.linearProgressDiv}>
                            <LinearProgressWithLabel value={100 * prevRS / prevRQ} />
                            {images.length > 0 &&
                                <Carousel
                                    className={classes.Carousel}
                                    autoPlay={true}
                                    indicators={true}
                                    timeout={500}
                                    cycleNavigation={true}
                                    navButtonsAlwaysVisible={true}
                                    navButtonsAlwaysInvisible={false}
                                    fullHeightHover={true}
                                >
                                    {
                                        images.map((item, index) => {
                                            return <CardMedia
                                                className={classes.Media}
                                                image={item.image}
                                            >
                                            </CardMedia>
                                        })
                                    }
                                </Carousel>
                            }
                        </div>
                    }
                    {companyData.map((company, index) =>
                        <Box className={classes.root} m={2} >
                            <QuotationDetails key={index} factories={factories} factoriesHub={factoriesHub} company={company} datipre={datipreventivo[company.sfo_id + company.paramurl + company.type]} saveActive={false} />
                        </Box>
                    )}
                    {prevRS === prevRQ && images.length > 0 &&
                        <Carousel
                            className={classes.Carousel}
                            autoPlay={true}
                            animation={"fade"}
                            indicators={true}
                            timeout={500}
                            cycleNavigation={true}
                            navButtonsAlwaysVisible={true}
                            navButtonsAlwaysInvisible={false}
                            fullHeightHover={true}
                        >
                            {
                                images.map((item, index) => {
                                    return <CardMedia
                                        key={index}
                                        className={classes.Media}
                                        image={item.image}
                                    >
                                    </CardMedia>
                                })
                            }
                        </Carousel>
                    }
                </Paper>;
            case 5:
                return <div className={classes.paper}>
                    {NumberQuotation &&
                        <QuotationSingleDetail numberquotation={NumberQuotation} visual={true} />
                    }
                </div>;
            default:
                return 'Unknown step';
        }
    }

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleChangePos = (event) => {
        agencieslists.forEach(element => {
            if (element.id === event.target.value) {
                setPos(event.target.value);
                seturlservicequotiamo(element.url)
                setPosInfo(element);
                setPosActive(element);
                setOrgId(element.orgid);
                if (element.defaultparameter !== null) {
                    let defOrg = JSON.parse(element.defOrg);
                    let defGrp = JSON.parse(element.defGrp);
                    let defAg = JSON.parse(element.defAg);
                    setQuotationData(AssignDefParameter(defOrg, defGrp, defAg));
                }
                localStorage.setItem('pos', JSON.stringify(element));
                AuthService.signin(element.username, element.password, element.url).then(
                    payload => {
                        setToken(payload);
                    }
                ).catch(error => {

                });
                const url = API_URL_INFOIMAGES_ALL + "/injuries/" + event.target.value;
                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + Auth.getToken(),
                            'Content-Type': 'application/json'
                        }
                    }
                ).then(payload => {
                    setImages(payload.data.images)
                });
            }
        });
    };

    const handleChange = (event) => {
        setQuotationData({
            ...QuotationData,
            [event.target.name]: event.target.value
        });
    };

    const handleChangeDataEffetto = event => {
        setQuotationData({
            ...QuotationData,
            dataEffetto: moment(event).format("YYYY-MM-DD")
        });
    };

    const handleChangeList = index => event => {
        let newVal = guaranteesValue;
        newVal[index] = event.target.value;
        setGuaranteesValue(newVal);
    };

    const handleChangeFranchigiaInvPerm = index => event => {
        setFranchigiaInvPerm(event.target.value);
    };

    const handleChangeFranchigiaInabTemp = index => event => {
        setFranchigiaInabTemp(event.target.value);
    };

    const handleClick = name => event => {
        let new_guaranteesSelect = guaranteesSelect;
        new_guaranteesSelect[name] = !guaranteesSelect[name];
        setGuaranteesSelect(new_guaranteesSelect);
    }

    const handleChangeFreeInput = index => event => {
        let newVal = guaranteesValue;
        newVal[index] = event.floatValue;
        setGuaranteesValue(newVal);
    };

    const HandleSaveQuotation = (companyListPrev) => {
        const userData = Auth.getUser();

        let proflabel = "";
        professioniInjuries.forEach(prof => {
            if (prof.value === QuotationData.contractorInfo.profession) {
                proflabel = prof.label;
            }
        });

        const json = JSON.stringify({
            clientinfo: QuotationData,
            clientlabelinfo: {
                profession: proflabel,
                clausole: clausoleInjuries
            },
            questionario1: domandeQuestionarioInfortunio,
            questionario2: domandeQuestionarioMalattia,
            quotationtype: "injuries"
        });
        const list = []
        companyListPrev.forEach((company) => {
            company.guarantees_select = guaranteesSelect;
            list.push(
                {
                    company: company,
                    datipre: datipreventivo[company.sfo_id + company.paramurl + company.type]
                }
            )
        });
        const url = API_URL_QUOTATION_LIST;
        axios.put(url, {
            product: window.location.pathname,
            id_user: userData.id,
            id_agency: pos,
            clientinfo: json,
            detail: JSON.stringify(list),
            select: "",
        },
            {
                headers: {
                    'Authorization': 'Bearer ' + Auth.getToken(),
                    'Content-Type': 'application/json'
                }
            }
        ).then(payload => {
            if (payload.status === 200) {
                setAlertMsg("Inserimento dati avvenuto con successo");
                setAlertSeverity("success");
                setNumberQuotation(payload.data.numberquotation);
            } else {
                setAlertMsg("Errore durante l'inserimento dati");
                setAlertSeverity("error");
            }
            setOpen(true);
        }).catch((error) => {
            setAlertMsg(error.response.data.error);
            setAlertSeverity("error");
            setOpen(true);
        });
    }

    return (
        <main className={classes.content}>
            <BackGround open={loading} />
            <ValidatorForm
                onSubmit={() => {
                }}
                onError={errors => console.log(errors)}
            >
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid className={classes.containerButton} item md={8}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link color="inherit" href="/" >
                                    {NAME_APP}
                                </Link>
                                <Link color="inherit" href="/injuries" >
                                    Infortuni
                                </Link>
                                <Typography color="textPrimary">Richiesta preventivo</Typography>
                            </Breadcrumbs>
                        </Grid>
                        <Grid className={classes.containerButton} item md={4}>
                            <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                                Indietro
                            </Button>
                            {activeStep < steps.length &&
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNext}
                                    className={classes.button}
                                    disabled={activeStep === steps.length - 1 || (activeStep === 4 && prevRS < prevRQ && prevRQ > 0)}
                                >
                                    Avanti
                                </Button>
                            }
                        </Grid>
                    </Grid>
                    <Card>
                        <CardContent>
                            <Stepper activeStep={activeStep}>
                                {steps.map((label, index) => {
                                    const stepProps = {};
                                    const labelProps = {};
                                    return (
                                        <Step key={label} {...stepProps}>
                                            <StepLabel {...labelProps}>{label}</StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                            <div className={classes.wrapper}>
                                {activeStep === steps.length ? (
                                    <div>
                                        <Typography className={classes.instructions}>
                                            Richiesta Preventivo completata
                                        </Typography>
                                        <Button onClick={handleReset} className={classes.button}>
                                            Nuovo preventivo
                                        </Button>
                                    </div>
                                ) : (
                                    <div className={classes.fulldiv}>
                                        {getStepContent(activeStep)}
                                    </div>
                                )}
                            </div>
                        </CardContent>
                    </Card>
                    <Grid container spacing={3}>
                        <Grid className={classes.containerButton} item md={12}>
                            <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                                Indietro
                            </Button>
                            {activeStep < steps.length &&
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNext}
                                    className={classes.button}
                                    disabled={activeStep === steps.length - 1 || (activeStep === 4 && prevRS < prevRQ && prevRQ > 0)}
                                >
                                    Avanti
                                </Button>
                            }
                        </Grid>
                    </Grid>
                </Container>

                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                    <Alert onClose={handleClose} severity={alertseverity}>
                        {alertmsg}
                    </Alert>
                </Snackbar>
            </ValidatorForm>
        </main>
    );
}