import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BackGround from './background';
import Container from '@material-ui/core/Container';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';
import MaterialTable from 'material-table';
import { id_cliente, ReturnInfoClient } from '../containers/logincontext';
import { API_URL_USERS } from '../config/config';
/*import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import BlockIcon from '@material-ui/icons/Block';
import Tooltip from '@material-ui/core/Tooltip';*/
import Avatar from '@material-ui/core/Avatar';
import { green, red, pink } from '@material-ui/core/colors';

import PersonAddIcon from '@material-ui/icons/PersonAdd';
import axios from 'axios';
import UserDetailPanel from './userdetailpanel';
import { NAME_APP } from '../config/config';
import Typography from '@material-ui/core/Typography';
import Auth from '../auth/auth';



const useStyles = makeStyles((theme) => ({
    alignFab: {
        marginLeft: 'auto',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    img: {
        maxWidth: 'auto',
        maxHeight: '40px',
        justifyContent: 'center'
    },
    red: {
        color: red[500]

    },
    green: {
        color: green[500]

    },
    pink: {
        color: theme.palette.getContrastText(pink[500]),
        backgroundColor: pink[500],
    },
}));

//props.value.toString().split(',').index(org.id)>-1



export default function UsersLists() {
    const [loading] = React.useState(false);
    const classes = useStyles();
    const myTextIcon = React.useRef(null);
    const tableRef = React.createRef();



    return (
        <main className={classes.content}>
            <BackGround open={loading} />
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" href="/" >
                        {NAME_APP}
                    </Link>
                    <Typography color="textPrimary">Utenti</Typography>
                </Breadcrumbs>

                <Card>
                    <CardContent>
                        <MaterialTable
                            tableRef={tableRef}
                            icons={{
                                Add: props => (
                                    <div ref={myTextIcon} className={classes.addButton}>
                                        <Avatar className={classes.pink}>
                                            <PersonAddIcon />
                                        </Avatar>
                                    </div>
                                ),
                            }}
                            title="Utenti"
                            columns={[

                                { title: 'Cognome', field: 'surname' },
                                { title: 'Nome', field: 'name' },
                                { title: 'Email', field: 'email' },
                                { title: 'Telefono', field: 'phone' },
                                { title: 'Utente', field: 'user' },
                                /*{ title: 'Stato', 
                                  field: 'level',
                                  editable: 'never',
                                  render: rowData =><Tooltip title={rowData.level>0 ? "Abilitato":"Disabilitato"}>                                                    
                                                        {rowData.level>0 ? <CheckCircleOutlineIcon className={classes.green} />:<BlockIcon className={classes.red}/>}                                                    
                                                    </Tooltip>
                                                                                            
                                },*/
                                {
                                    title: 'Livello',
                                    field: 'level',
                                    lookup: {
                                        0: 'Disabilitato',
                                        1: 'Livello 1: Può vedere solo i propri preventivi',
                                        2: 'Livello 2: Può vedere tutti i preventivi fatti nelle filiali dove è abilitato',
                                        3: 'Livello 3: Può vedere tutti i preventivi del gruppo di appartenenza',
                                        4: 'Livello 4: Può vedere tutti i preventivi delle proprie organizzazioni',
                                        5: 'Livello 5',
                                        6: 'Livello 6',
                                        7: 'Livello 7',
                                        8: 'Admin: Può vedere tutti i preventivi e modificare tutti i parametri delle proprie organizzazioni',
                                        9: 'Admin Test: Può vedere tutti i preventivi e modificare tutti i parametri delle proprie organizzazioni, inoltre può effettuare test su nuovi prodotti',
                                        10: 'Super Admin: Può vedere tutti i preventivi e modificare tutti i parametri',
                                    },
                                    render: rowData => <div>{rowData.level === 10 ? "Super Admin" :
                                        rowData.level === 9 ? "Admin Test" :
                                            rowData.level === 8 ? "Admin" :
                                                (rowData.level === 0 || rowData.level === null) ? "Disabilitato" :
                                                    "Livello " + rowData.level}
                                    </div>
                                },
                                {
                                    title: 'Ruolo', field: 'role'
                                },
                                {
                                    title: 'Login',
                                    field: 'loginwith810',
                                    //editable: 'never',
                                    lookup: {
                                        0: 'Email',
                                        1: '810'
                                    },

                                    render: rowData => <div>{rowData.loginwith810 === 1 ? "810" : "Email"}</div>
                                },
                                {
                                    title: 'Privacy',
                                    field: 'privacyconsent',
                                    //editable: 'never',
                                    lookup: {
                                        0: 'No',
                                        1: 'Si'
                                    },

                                    render: rowData => <div>{rowData.privacyconsent === 1 ? "Si" : "No"}</div>
                                },
                                {
                                    title: 'Consenso per test',
                                    field: 'contactconsent',
                                    lookup: {
                                        0: 'No',
                                        1: 'Si'
                                    },

                                    render: rowData => <div>{rowData.contactconsent === 1 ? "Si" : "No"}</div>
                                },

                            ]}

                            detailPanel={[
                                {
                                    tooltip: 'Permessi',
                                    render: rowData => {
                                        return (<UserDetailPanel datauser={rowData} />)
                                    },
                                },
                            ]
                            }


                            localization={{
                                pagination: {
                                    labelDisplayedRows: '{from}-{to} di {count}',
                                    labelRowsSelect: 'per pagina',
                                    labelRowsPerPage: 'per pagina',
                                    firstAriaLabel: 'prima pagina',
                                    firstTooltip: 'prima pagina',
                                    previousAriaLabel: 'pagina precedente',
                                    previousTooltip: 'pagina precedente',
                                    nextAriaLabel: 'pagina successiva',
                                    nextTooltip: 'pagina successiva',
                                    lastAriaLabel: 'ultima pagina',
                                    lastTooltip: 'ultima pagina',
                                },
                                toolbar: {
                                    nRowsSelected: '{0} elementi selezionati',
                                    searchTooltip: 'Ricerca',
                                    searchPlaceholder: 'Ricerca'
                                },
                                header: {
                                    actions: ''
                                },
                                body: {
                                    emptyDataSourceMessage: 'Nessun record da visualizzare',
                                    filterRow: {
                                        filterTooltip: 'Filtri'
                                    },
                                    editRow: {
                                        saveTooltip: "Conferma",
                                        cancelTooltip: "Annulla",
                                        deleteText: "Sei sicuro di voler cancellare questo utente?"
                                    },
                                    addTooltip: "Aggiungi nuovo utente",
                                    deleteTooltip: "Cancella",
                                    editTooltip: "Modifica",

                                }
                            }}
                            options={{
                                pageSize: 10,
                                pageSizeOptions: [5, 10],
                                search: true,
                                paginationType: "stepped",
                                headerStyle: {
                                    backgroundColor: ReturnInfoClient(id_cliente, "primary_color"),
                                    color: '#ffffff',
                                    '&:hover': {
                                        color: '#ffffff',
                                    },
                                    '&:active': {
                                        color: '#ffffff',
                                    }
                                }

                            }}
                            data={query =>
                                new Promise((resolve, reject) => {
                                    let url = API_URL_USERS;
                                    url += '?per_page=' + query.pageSize
                                    url += '&page=' + (query.page + 1)

                                    if (query.search.length > 3) {
                                        url += '&search=' + query.search
                                    }
                                    if (query.orderBy !== undefined) {
                                        url += '&orderby=' + query.orderBy.field
                                        url += '&orderdirection=' + query.orderDirection
                                    }

                                    const requestOptions = {
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'Authorization': 'Bearer ' + Auth.getToken(),
                                        },
                                    };


                                    fetch(url, requestOptions)
                                        .then(
                                            response => response.json()
                                        )
                                        .then(
                                            result => {
                                                resolve({
                                                    data: result.data,
                                                    page: result.current_page - 1,
                                                    totalCount: result.total,
                                                })
                                            })
                                })
                            }
                            editable={{
                                onRowAdd: newData =>
                                    new Promise((resolve, reject) => {
                                        const url = API_URL_USERS;
                                        axios.put(url, {
                                            surname: newData.surname,
                                            name: newData.name,
                                            email: newData.email,
                                            level: newData.level,
                                            user: newData.user,
                                            loginwith810: newData.loginwith810,
                                            contactconsent: newData.contactconsent,
                                            privacyconsent: newData.privacyconsent,
                                            phone: newData.phone,
                                            role: newData.role
                                        },
                                            {
                                                headers: {
                                                    'Authorization': 'Bearer ' + Auth.getToken(),
                                                    'Content-Type': 'application/json'
                                                }
                                            }
                                        ).then(payload => {

                                            tableRef.current && tableRef.current.onQueryChange();
                                        });
                                        resolve();
                                    }),
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve, reject) => {
                                        const index = oldData.id;
                                        const url = API_URL_USERS + "/" + index;
                                        axios.patch(url, {
                                            surname: newData.surname,
                                            name: newData.name,
                                            email: newData.email,
                                            level: newData.level,
                                            user: newData.user,
                                            loginwith810: newData.loginwith810,
                                            contactconsent: newData.contactconsent,
                                            privacyconsent: newData.privacyconsent,
                                            phone: newData.phone,
                                            role: newData.role

                                        },
                                            {
                                                headers: {
                                                    'Authorization': 'Bearer ' + Auth.getToken(),
                                                    'Content-Type': 'application/json'
                                                }
                                            }
                                        ).then(payload => {

                                            tableRef.current && tableRef.current.onQueryChange();
                                        });
                                        resolve();

                                    }),
                                onRowDelete:

                                    oldData =>
                                        new Promise((resolve, reject) => {

                                            const index = oldData.id;
                                            const url = API_URL_USERS + "/" + index;

                                            axios.delete(url,
                                                {
                                                    headers: {
                                                        'Authorization': 'Bearer ' + Auth.getToken(),
                                                        'Content-Type': 'application/json'
                                                    }
                                                }
                                            ).then(payload => {

                                                tableRef.current && tableRef.current.onQueryChange();
                                            });
                                            resolve();
                                        })
                            }
                            }
                            actions={[
                                {
                                    icon: 'refresh',
                                    tooltip: 'Aggiorna dati',
                                    isFreeAction: true,
                                    onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                                }
                            ]}
                        />

                    </CardContent>


                </Card>
            </Container>
        </main>
    );
};

