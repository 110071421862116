import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BackGround from './background';
import axios from 'axios';
import Auth from '../auth/auth';
import AuthService from '../auth/authservice';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';
import { NAME_APP } from '../config/config';
import Container from '@material-ui/core/Container';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { red } from '@material-ui/core/colors';
import MuiAlert from '@material-ui/lab/Alert';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import CheckSvgButton from './checkboxsvg';
import Box from '@material-ui/core/Box';
import {
    API_URL_INFOIMAGES_ALL,
    API_URL_GET_INFOCITYBYCITY,
    API_URL_ALLGUARANTEES,
    API_URL_FACTORIES_LIST,
    API_URL_LIST_AGENCIES_USER,
    API_URL_LIST_SERVICES_AGENCY_USER,
    API_URL_QUOTATION_LIST,
    API_URL_MULTISESSION,
    API_URL_QUOTATION_RE,
    APP_TOKEN_QUOTIAMO,
    API_URL_GET_INFOBIRTH
} from '../config/config';
import { PosDataContext } from '../containers/logincontext';
import moment from 'moment';
import {
    tipoFrazionamento,
    domandeQuestionarioTcm1,
    domandeQuestionarioTcm2,
    durationsListTcm,
    beneficiariTcm,
    premioTcmEasy
} from '../config/config';
import TextField from '@material-ui/core/TextField';
import { KeyboardDatePicker } from "@material-ui/pickers";
import SubjectDetails from './subjectDetails';
import NumberFormat from 'react-number-format';
import {
    getProfessions,
    number_format,
    parseFloat2Decimals
} from './utils';
import validator from 'validator';
import QuotationSingleDetail from './quotationsingledetail';
import { CardMedia } from '@material-ui/core';
import QuotationDetails from './quotationdetails';
import Carousel from 'react-material-ui-carousel';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';

const drawerWidth = 240;

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const useStyles = makeStyles((theme) => ({
    BoxContainer: {
        padding: "10px"
    },
    fulldiv: {
        width: "-webkit-fill-available"
    },
    FirstDataRow: {
        marginTop: "20px",
        marginBottom: "0px",
    },
    TitleCategory: {
        marginTop: "20px",
        fontSize: "16px",
        fontWeight: "600"
    },
    DetailWarranty: {
        marginTop: "5px",
        fontSize: "12px",
        fontWeight: "400"

    },
    SubTitle: {
        marginTop: "20px",
        fontSize: "16px",
        fontWeight: "600"
    },
    button: {
        height: 30
    },
    Carousel: {
        width: "100%"
    },
    Media: {
        height: 800
    },
    Carousel2: {
        width: "50%"
    },
    Media2: {
        height: 400
    },
    PaddingTop: {
        paddingTop: 30,
    },
    cellroot: {
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    prezzo: {
        align: 'right'
    },
    titleTable: {
        flex: '1 1 100%',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    linear_class: {
        width: '20%',
        '& > * + *': {
            marginTop: theme.spacing(2),
            zIndex: theme.zIndex.drawer + 2,
        },
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    root: {
        width: '90%'
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        //maxWidth:'100%',
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        alignItems: 'center',
        flexDirection: 'column',
    },
    preventivo: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 800,
    },
    image: {
        width: 180,
        height: 60,
    },
    imgLogo: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '50px',
        marginBottom: '40px'
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    fixedHeight: {
        height: 240,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    pos: {
        marginBottom: 12,
    },
    containerButton: {
        alignItems: 'right',
        textAlign: 'right',
        marginBottom: '10px',
        marginTop: '10px'
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
        alignItems: 'center'
    },
    formControl: {
        width: '100%'
    },
    progress: {
        display: "none"
    },
    iconRoot: {
        color: red
    },
    linearProgressDiv: {
        width: '90%'
    }
}));

function getSteps() {
    return ['Dati generali', 'Garanzie', 'Questionari', 'Preventivo', 'Modifica/Conferma'];
};

function AssignDefParameter(defOrg, defGrp, defAg) {
    return ({
        dataEffetto: moment().format("YYYY-MM-DD"), // YYYY-MM-DD
        frazionamento: (defAg !== null && defAg.frazionamentoTcm !== undefined ? defAg.frazionamentoTcm : (defGrp !== null && defGrp.frazionamentoTcm !== undefined ? defGrp.frazionamentoTcm : (defOrg !== null && defOrg.frazionamentoTcm !== undefined ? defOrg.frazionamentoTcm : 0))), // 1=Annuale, 2=Semestrale, 3=Quadrimestrale, 4=Trimestrale, 6=Bimestrale, 12=Mensile
        durataAnni: (defAg !== null && defAg.durataAnniTcm !== undefined ? defAg.durataAnniTcm : (defGrp !== null && defGrp.durataAnniTcm !== undefined ? defGrp.durataAnniTcm : (defOrg !== null && defOrg.durataAnniTcm !== undefined ? defOrg.durataAnniTcm : 0))),
        beneficiario: (defAg !== null && defAg.beneficiarioTcm !== undefined ? defAg.beneficiarioTcm : (defGrp !== null && defGrp.beneficiarioTcm !== undefined ? defGrp.beneficiarioTcm : (defOrg !== null && defOrg.beneficiarioTcm !== undefined ? defOrg.beneficiarioTcm : 11))),
        premioAnnuoLordo: (defAg !== null && defAg.premioAnnuoLordoTcm !== undefined ? defAg.premioAnnuoLordoTcm : (defGrp !== null && defGrp.premioAnnuoLordoTcm !== undefined ? defGrp.premioAnnuoLordoTcm : (defOrg !== null && defOrg.premioAnnuoLordoTcm !== undefined ? defOrg.premioAnnuoLordoTcm : 0))),
        contractorInfo: {
            subjectType: (defAg !== null && defAg.subjectTypeTcm !== undefined ? defAg.subjectTypeTcm : (defGrp !== null && defGrp.subjectTypeTcm !== undefined ? defGrp.subjectTypeTcm : (defOrg !== null && defOrg.subjectTypeTcm !== undefined ? defOrg.subjectTypeTcm : "1"))),
            name: (defAg !== null && defAg.nameTcm !== undefined ? defAg.nameTcm : (defGrp !== null && defGrp.nameTcm !== undefined ? defGrp.nameTcm : (defOrg !== null && defOrg.nameTcm !== undefined ? defOrg.nameTcm : ""))),
            surname: (defAg !== null && defAg.surnameTcm !== undefined ? defAg.surnameTcm : (defGrp !== null && defGrp.surnameTcm !== undefined ? defGrp.surnameTcm : (defOrg !== null && defOrg.surnameTcm !== undefined ? defOrg.surnameTcm : ""))),
            birthDate: "",
            gender: "M",
            fiscalCode: (defAg !== null && defAg.fiscalCodeTcm !== undefined ? defAg.fiscalCodeTcm : (defGrp !== null && defGrp.fiscalCodeTcm !== undefined ? defGrp.fiscalCodeTcm : (defOrg !== null && defOrg.fiscalCodeTcm !== undefined ? defOrg.fiscalCodeTcm : ""))),
            companyName: (defAg !== null && defAg.companyNameTcm !== undefined ? defAg.companyNameTcm : (defGrp !== null && defGrp.companyNameTcm !== undefined ? defGrp.companyNameTcm : (defOrg !== null && defOrg.companyNameTcm !== undefined ? defOrg.companyNameTcm : ""))),
            vatNumber: (defAg !== null && defAg.vatNumberTcm !== undefined ? defAg.vatNumberTcm : (defGrp !== null && defGrp.vatNumberTcm !== undefined ? defGrp.vatNumberTcm : (defOrg !== null && defOrg.vatNumberTcm !== undefined ? defOrg.vatNumberTcm : ""))),
            birthCity: "",
            birthProvince: "",
            birthRegion: "",
            birthNationAbbr: "",
            birthNation: "",
            profession: (defAg !== null && defAg.professionTcm !== undefined ? defAg.professionTcm : (defGrp !== null && defGrp.professionTcm !== undefined ? defGrp.professionTcm : (defOrg !== null && defOrg.professionTcm !== undefined ? defOrg.professionTcm : ""))),
            email: (defAg !== null && defAg.emailTcm !== undefined ? defAg.emailTcm : (defGrp !== null && defGrp.emailTcm !== undefined ? defGrp.emailTcm : (defOrg !== null && defOrg.emailTcm !== undefined ? defOrg.emailTcm : ""))),
            phoneNumber: (defAg !== null && defAg.phoneNumberTcm !== undefined ? defAg.phoneNumberTcm : (defGrp !== null && defGrp.phoneNumberTcm !== undefined ? defGrp.phoneNumberTcm : (defOrg !== null && defOrg.phoneNumberTcm !== undefined ? defOrg.phoneNumberTcm : ""))),
            residenceInfo: {
                city: "",
                cap: "",
                provinceAbbr: "",
                istat: "",
                toponym: (defAg !== null && defAg.toponimoTcm !== undefined ? defAg.toponimoTcm : (defGrp !== null && defGrp.toponimoTcm !== undefined ? defGrp.toponimoTcm : (defOrg !== null && defOrg.toponimoTcm !== undefined ? defOrg.toponimoTcm : ""))),
                address: (defAg !== null && defAg.indirizzoTcm !== undefined ? defAg.indirizzoTcm : (defGrp !== null && defGrp.indirizzoTcm !== undefined ? defGrp.indirizzoTcm : (defOrg !== null && defOrg.indirizzoTcm !== undefined ? defOrg.indirizzoTcm : ""))),
                streetNumber: (defAg !== null && defAg.numeroCivicoTcm !== undefined ? defAg.numeroCivicoTcm : (defGrp !== null && defGrp.numeroCivicoTcm !== undefined ? defGrp.numeroCivicoTcm : (defOrg !== null && defOrg.numeroCivicoTcm !== undefined ? defOrg.numeroCivicoTcm : ""))),
            }
        }
    });
};

const getListOfCity = async (token, url, search) => {
    try {
        const result = await axios.get(url + API_URL_GET_INFOCITYBYCITY, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            params: {
                city: search
            }
        });
        return result;
    } catch (e) {
        return Promise.reject(e.response);
    }
};

function TextFieldCustomMassimale(props) {
    return (
        <TextField label="Massimale" variant="outlined" {...props} />
    );
};

function LinearProgressWithLabel(props) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
};

function CheckConvenzioni(QuotationData, company, conventionslist, flagapply) {
    let convention_active = {
        code: "",
        description: "",
        flex: 0
    };

    if (flagapply === 1) {
        let convComp = false;
        conventionslist.forEach(conventions => {
            if (company.facid === conventions.id_factory) {
                let convention_accept = CheckActiveConvention(conventions, QuotationData);
                if (convention_accept) {
                    convention_active = conventions;
                    if (conventions.companyapply === 1) {
                        convComp = true;
                    } else {
                        convComp = false;
                    }
                }
            }
        }
        );
        if (convComp === false) {
            let convention = {
                code: "",
                description: "",
                flex: 0
            };
            return convention;
        }
    } else {
        conventionslist.forEach(conventions => {
            if (company.facid === conventions.id_factory && conventions.companyapply === flagapply) {
                let convention_accept = CheckActiveConvention(conventions, QuotationData);
                if (convention_accept) {
                    convention_active = conventions;
                }
            }
        });
    }

    return convention_active;
};

function CheckActiveConvention(conventions, QuotationData) {
    let convention_accept = true;
    if (conventions.convention_rules !== null) {
        JSON.parse(conventions.convention_rules).forEach(
            (single_rules) => {
                if (single_rules.ProvinceList !== undefined) {
                    if (single_rules.ProvinceList.value !== null && single_rules.ProvinceList.value !== "") {
                        //non trovate le province
                        let elencoProvince = single_rules.ProvinceList.value.split(",");
                        if (elencoProvince.indexOf(QuotationData.provinciaAbbr) === -1) {
                            convention_accept = false;
                        }
                    }
                }
                if (single_rules.BirthNation !== undefined) {
                    if (single_rules.BirthNation.value !== null && single_rules.BirthNation.value !== "") {
                        //Verifico se nato in paese straniero
                        if (!isNaN(single_rules.BirthNation.value)) {
                            var res = QuotationData.contractorInfo.fiscalCode.substring(11, 12).toUpperCase();
                            if (parseInt(single_rules.BirthNation.value) === 1) {
                                if (res === "Z") {
                                    convention_accept = false;
                                }
                            } else if (parseInt(single_rules.BirthNation.value) === 2) {
                                if (res !== "Z") {
                                    convention_accept = false;
                                }
                            }
                        }
                    }
                }
                if (single_rules.PaymentMode !== undefined) {
                    if (single_rules.PaymentMode.value !== null && single_rules.PaymentMode.value !== "") {
                        if (Number(QuotationData.frazionamento) !== Number(single_rules.PaymentMode.value)) {
                            convention_accept = false;
                        }
                    }
                }
            }
        );
    }

    return convention_accept;
};

const checkPreventivo = async (QuotationData, company, token, guarantees, guaranteesSelect, guaranteesValue, conventionslist, authToken) => {
    if (company.multisession === 1 && company.username === "" && company.password === "" && company.companyCode === "" && company.companyParams === "" && company.nodeCode === "") {
        if (company.paramurl === "hdi") {
            const result = {
                data: {
                    error: "Servizio non disponibile",
                    message: "Limite utenze in uso raggiunto, riprovare tra qualche minuto",
                    path: "/getquotation",
                    status: 503,
                    errorCode: 1003,
                    timestamp: moment().format("YYYY-MM-DD HH:mm:ss")
                }
            };
            return result;
        } else {
            const result = {
                data: {
                    error: "Servizio non disponibile",
                    message: "Utenze non disponibili, riprovare tra qualche minuto",
                    path: "/getquotation",
                    status: 503,
                    errorCode: 1003,
                    timestamp: moment().format("YYYY-MM-DD HH:mm:ss")
                }
            };
            return result;
        }
    }
    /*Verifica se proviene dalla stessa compagnia */

    let listCVT = [];
    let convenzioneAttiva = CheckConvenzioni(QuotationData, company, conventionslist, 1);
    if (convenzioneAttiva !== false && convenzioneAttiva.code !== "") {
        console.log("TROVATA CONVENZIONE DA INVIARE ALLA COMPAGNIA");
        //Al momento non usato
    }

    guarantees.forEach((element, index) => {
        if (guaranteesSelect[index]) {
            let massimale = 0;
            let massimalePerc = 0;
            let franchigia = 0;
            let franchigiaPerc = 0;
            let limite = 0;
            let limitePerc = 0;
            let scopertoPerc = 0;
            let scoperto = 0;

            if (element["input_type"] !== 0) {
                if (isNaN(guaranteesValue[index]) && guaranteesValue[index].indexOf('%') !== -1) {
                    massimalePerc = Number(guaranteesValue[index].replace('%', ''));
                } else {
                    massimale = Number(guaranteesValue[index]);
                }
            } else {
                if (company.guarantees_info[index].massimale !== null) {
                    if (isNaN(company.guarantees_info[index].massimale) && company.guarantees_info[index].massimale.indexOf('%') !== -1) {
                        massimalePerc = Number(company.guarantees_info[index].massimale.replace('%', ''));
                    } else {
                        massimale = Number(company.guarantees_info[index].massimale);
                    }
                }
            }
            if (company.guarantees_info[index].franchigia !== null) {
                if (isNaN(company.guarantees_info[index].franchigia) && company.guarantees_info[index].franchigia.indexOf('%') !== -1) {
                    franchigiaPerc = Number(company.guarantees_info[index].franchigia.replace('%', ''));
                } else {
                    franchigia = Number(company.guarantees_info[index].franchigia);
                }
            }

            if (company.guarantees_info[index].limite_risarcimento_perc !== null) {
                limitePerc = Number(company.guarantees_info[index].limite_risarcimento_perc);
            }
            if (company.guarantees_info[index].limite_risarcimento_val !== null) {
                limite = Number(company.guarantees_info[index].limite_risarcimento_val);
            }

            if (company.guarantees_info[index].scoperto_perc !== null) {
                scopertoPerc = Number(company.guarantees_info[index].scoperto_perc);
            }

            if (company.guarantees_info[index].scoperto_val !== null) {
                scoperto = Number(company.guarantees_info[index].scoperto_val);
            }

            let tipoCalcolo = company.guarantees_info[index].tipo_calcolo !== null ? (company.guarantees_info[index].tipo_calcolo !== "default" ? company.guarantees_info[index].tipo_calcolo : company.guarantees_info[index].tipo_calcolo_default) : company.guarantees_info[index].tipo_calcolo_default;
            if (tipoCalcolo === "comp") {
                if (company.guarantees_info[index].service_parameters_code !== null) {
                    company.guarantees_info[index].service_parameters_code.split(",").forEach(elementcode => {
                        //temp.push({[element.id]:element.description});
                        listCVT.push(
                            {
                                codice: elementcode,
                                massimale: massimale,
                                massimalePerc: massimalePerc,
                                franchigia: franchigia,
                                franchigiaPerc: franchigiaPerc,
                                limite: limite,
                                limitePerc: limitePerc,
                                scoperto: scoperto,
                                scopertoPerc: scopertoPerc
                            }
                        );
                    });
                } else {
                    listCVT.push(
                        {
                            codice: element["code"],
                            massimale: massimale,
                            massimalePerc: massimalePerc,
                            franchigia: franchigia,
                            franchigiaPerc: franchigiaPerc,
                            limite: limite,
                            limitePerc: limitePerc,
                            scoperto: scoperto,
                            scopertoPerc: scopertoPerc
                        }
                    );
                }
            }
        }
    });

    const json = JSON.stringify({
        applicationToken: APP_TOKEN_QUOTIAMO,
        username: company.username,
        password: company.password,
        companyCode: company.companyCode,
        companyParams: company.companyParams,
        nodeCode: company.nodeCode,
        commerciale: (company.commerciale === null || company.commerciale === "") ? "00493" : company.commerciale,
        agZona: (company.agZona === null || company.agZona === "") ? "00493" : company.agZona,
        reteVendita: "001",
        contractorInfo: {
            name: QuotationData.contractorInfo.name,
            surname: QuotationData.contractorInfo.surname,
            birthDate: QuotationData.contractorInfo.birthDate,
            gender: QuotationData.contractorInfo.gender,
            companyName: QuotationData.contractorInfo.companyName,
            phoneNumber: company.usedefaultphonenumber === 1 ? company.phonenumber : QuotationData.contractorInfo.phoneNumber,
            email: company.usedefaultemail === 1 ? company.email : QuotationData.contractorInfo.email,
            fiscalCode: QuotationData.contractorInfo.fiscalCode,
            vatNumber: QuotationData.contractorInfo.vatNumber,
            personType: (QuotationData.contractorInfo.subjectType === "1" || QuotationData.contractorInfo.subjectType === "") ? "FIS" : "GIU",
            subjectType: (QuotationData.contractorInfo.subjectType === "") ? "1" : QuotationData.contractorInfo.subjectType,
            residenceInfo: {
                city: QuotationData.contractorInfo.residenceInfo.city,
                cap: QuotationData.contractorInfo.residenceInfo.cap,
                provinceAbbr: QuotationData.contractorInfo.residenceInfo.provinceAbbr,
                istat: QuotationData.contractorInfo.residenceInfo.istat,
            },
            locationInfo: {
                city: QuotationData.contractorInfo.residenceInfo.city,
                cap: QuotationData.contractorInfo.residenceInfo.cap,
                provinceAbbr: QuotationData.contractorInfo.residenceInfo.provinceAbbr,
                istat: QuotationData.contractorInfo.residenceInfo.istat,
            }
        },
        quoteInfo: {
            dataEffetto: QuotationData.dataEffetto,
            frazionamento: QuotationData.frazionamento,
            durataAnni: QuotationData.durataAnni,
            beneficiario: QuotationData.beneficiario,
            premioAnnuoLordo: QuotationData.premioAnnuoLordo,
            questionarioFumatore: QuotationData.questionarioFumatore,
            questionarioSanitario: QuotationData.questionarioSanitario,
            listaCVT: listCVT
        },
    });
    const url_service = company.url + API_URL_QUOTATION_RE + "/tcm/" + company.type + "/" + (company.type === "share" ? company.sharetoken : company.paramurl);
    try {
        const result = await axios.post(url_service, json, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        });
        // chiamata sblocco credenziali multisession
        if (company.multisession === 1) {
            const url_multisession = API_URL_MULTISESSION + "/checkuse/" + company.multisessionId + "/0";
            await axios.get(url_multisession, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + authToken,
                }
            });
        }
        return result;
    } catch (e) {
        // chiamata sblocco credenziali multisession
        if (company.multisession === 1) {
            const url_multisession = API_URL_MULTISESSION + "/checkuse/" + company.multisessionId + "/0";
            await axios.get(url_multisession, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + authToken,
                }
            });
        }
        return Promise.reject(e.response);
    }
};

const getInfoBirth = (token, url, cf, nome, cognome) => {
    try {
        const result = axios.get(url + API_URL_GET_INFOBIRTH, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            params: {
                cf: cf,
                nome: nome,
                cognome: cognome
            }
        });
        return result;
    } catch (e) {
        return Promise.reject(e.response);
    }
};

export default function QuotationTcm(props) {
    const classes = useStyles();
    const [alertseverity, setAlertSeverity] = React.useState("success");
    const [alertmsg, setAlertMsg] = React.useState(false);
    const [activeStep, setActiveStep] = React.useState(0);
    const [prevRQ, setPrevRQ] = React.useState(0);
    const [prevRS, setPrevRS] = React.useState(0);
    const steps = getSteps();
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [datipreventivo, setdatipreventivo] = React.useState([]);
    const [pos, setPos] = React.useState('');
    const [agencieslists, setagencieslists] = React.useState([]);
    const [images, setImages] = React.useState([]);
    const [token, setToken] = React.useState(null);
    const [orgid, setOrgId] = React.useState(null);
    const [posinfo, setPosInfo] = React.useState(null);
    const [, setPosActive] = React.useContext(PosDataContext);
    const [QuotationData, setQuotationData] = React.useState(AssignDefParameter(null, null, null));
    const [urlservicequotiamo, seturlservicequotiamo] = React.useState("");
    const [listCity, setlistCity] = React.useState([]);
    const [listProfessions, setlistProfessions] = React.useState([]);
    const [progress, setProgress] = React.useState(0);
    const [guarantees, setGuarantees] = React.useState([]);
    const [guaranteesSelect, setGuaranteesSelect] = React.useState([]);
    const [guaranteesValue, setGuaranteesValue] = React.useState([]);
    const [factories, setFactories] = React.useState([]);
    const [factoriesHub, setFactoriesHub] = React.useState([]);
    let contaPreventiviDaFare = 0;
    let contaPreventiviFatti = 0;
    const [companyData, setcompanyData] = React.useState([]);
    const [NumberQuotation, setNumberQuotation] = React.useState(false);

    const [questionarioTcm1, setQuestionarioTcm1] = React.useState({
        tcm_domanda_1: "yes", tcm_domanda_2: "yes", tcm_domanda_3: "yes", tcm_domanda_4: "yes", tcm_domanda_5: "yes", tcm_domanda_6: "yes", tcm_domanda_7: "yes",
        tcm_domanda_8: "yes", tcm_domanda_9: "yes"
    });
    const [questionarioTcm2, setQuestionarioTcm2] = React.useState({
        tcm_domanda_1: "no", tcm_domanda_2: "no", tcm_domanda_3: "no", tcm_domanda_4: "no", tcm_domanda_5: "no", tcm_domanda_6: "no", tcm_domanda_7: "no",
        tcm_domanda_8: "no", tcm_domanda_9: "no", tcm_domanda_10: "no", tcm_domanda_11: "no", tcm_domanda_12: "1.70", tcm_domanda_13: "75", tcm_domanda_14: "no",
        tcm_domanda_15: "no", tcm_domanda_16: "no", tcm_domanda_17: "no", tcm_domanda_18: "no", tcm_domanda_18_1: "", tcm_domanda_19: "no", tcm_domanda_19_1: "no", tcm_domanda_19_2: "no",
        tcm_domanda_20: "no", tcm_domanda_21: "no", tcm_domanda_22: "no"
    });

    const handleChangeValueTcm1 = (event) => {
        setQuestionarioTcm1({
            ...questionarioTcm1,
            [event.target.name]: event.target.value
        });
    };

    const handleChangeValueTcm2 = (event) => {
        setQuestionarioTcm2({
            ...questionarioTcm2,
            [event.target.name]: event.target.value
        });
    };

    React.useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 1000 ? 1 : prevProgress + 1));
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, []);

    React.useEffect(() => {
        if (orgid !== null) {
            const url = API_URL_ALLGUARANTEES + "/enabled/tcm/" + orgid;
            axios.get(url,
                {
                    headers: {
                        'Authorization': 'Bearer ' + Auth.getToken(),
                        'Content-Type': 'application/json'
                    }
                }
            ).then(payload => {
                let temp = [];
                let tempVal = [];
                payload.data.guarantees.forEach(element => {
                    temp.push(false);
                    if (element.input_type === 2) {
                        tempVal.push(element.list_value.split(",")[0]);
                    } else {
                        tempVal.push("");
                    }
                });
                setGuaranteesSelect(temp);
                setGuaranteesValue(tempVal);
                setGuarantees(payload.data.guarantees);
            });
        }
    }, [orgid]);

    React.useEffect(() => {
        const url = API_URL_FACTORIES_LIST;
        axios.get(url,
            {
                headers: {
                    'Authorization': 'Bearer ' + Auth.getToken(),
                    'Content-Type': 'application/json'
                }
            }
        ).then(payload => {
            setFactories(payload.data.factories);
        });
    }, []);

    React.useEffect(() => {
        const url = API_URL_LIST_AGENCIES_USER;
        axios.get(url,
            {
                headers: {
                    'Authorization': 'Bearer ' + Auth.getToken(),
                    'Content-Type': 'application/json'
                }
            }
        ).then(payload => {
            setagencieslists(payload.data);
            if (payload.data.length > 0) {
                setPos(payload.data[0].id);
                seturlservicequotiamo(payload.data[0].url);
                setPosInfo(payload.data[0]);
                setPosActive(payload.data[0]);
                setOrgId(payload.data[0].orgid);
                if (payload.data[0].defaultparameter !== null) {
                    let defOrg = JSON.parse(payload.data[0].defOrg);
                    let defGrp = JSON.parse(payload.data[0].defGrp);
                    let defAg = JSON.parse(payload.data[0].defAg);
                    setQuotationData(AssignDefParameter(defOrg, defGrp, defAg));
                }
                localStorage.setItem('pos', JSON.stringify(payload.data[0]));
                let urlservicequotiamo = payload.data[0].url;
                AuthService.signin(payload.data[0].username, payload.data[0].password, payload.data[0].url).then(
                    payload => {
                        setToken(payload);
                        getListOfCity(payload, urlservicequotiamo, "").then(payload => {
                            setlistCity(payload.data);
                        });
                        getProfessions(payload, urlservicequotiamo).then(payload => {
                            setlistProfessions(payload.data);
                        });
                    }
                ).catch(error => {
                });
                const url = API_URL_INFOIMAGES_ALL + "/tcm/" + payload.data[0].id;
                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + Auth.getToken(),
                            'Content-Type': 'application/json'
                        }
                    }
                ).then(payload => {
                    setImages(payload.data.images)
                });
            }
        });
    }, [setPosActive]);

    const checkcompanyData = async (tokenApp, pos) => {
        try {
            const result = await axios.get(API_URL_LIST_SERVICES_AGENCY_USER + "/tcm/" + pos, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + tokenApp,
                }
            });
            return result;
        } catch (e) {
            return Promise.reject(e.response);
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleBack = () => {
        if (activeStep === 3) {
            window.stop();
            setdatipreventivo([]);
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleChangePos = (event) => {
        agencieslists.forEach(element => {
            if (element.id === event.target.value) {
                setPos(event.target.value);
                seturlservicequotiamo(element.url)
                setPosInfo(element);
                setPosActive(element);
                setOrgId(element.orgid);
                if (element.defaultparameter !== null) {
                    let defOrg = JSON.parse(element.defOrg);
                    let defGrp = JSON.parse(element.defGrp);
                    let defAg = JSON.parse(element.defAg);
                    setQuotationData(AssignDefParameter(defOrg, defGrp, defAg));
                }
                localStorage.setItem('pos', JSON.stringify(element));
                AuthService.signin(element.username, element.password, element.url).then(
                    payload => {
                        setToken(payload);
                    }
                ).catch(error => {

                });
                const url = API_URL_INFOIMAGES_ALL + "/tcm/" + event.target.value;
                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + Auth.getToken(),
                            'Content-Type': 'application/json'
                        }
                    }
                ).then(payload => {
                    setImages(payload.data.images)
                });
            }
        });
    };

    const handleChange = (event) => {
        setQuotationData({
            ...QuotationData,
            [event.target.name]: event.target.value
        });
    };

    const handleChangeDataEffetto = event => {
        setQuotationData({
            ...QuotationData,
            dataEffetto: moment(event).format("YYYY-MM-DD")
        });
    };

    const handleChangeList = index => event => {
        let newVal = guaranteesValue;
        newVal[index] = event.target.value;
        setGuaranteesValue(newVal);
    };

    const handleClick = name => event => {
        let new_guaranteesSelect = guaranteesSelect;
        new_guaranteesSelect[name] = !guaranteesSelect[name];
        setGuaranteesSelect(new_guaranteesSelect);
    };

    const handleChangeFreeInput = index => event => {
        let newVal = guaranteesValue;
        newVal[index] = event.floatValue;
        setGuaranteesValue(newVal);
    };

    const HandleSaveQuotation = (companyListPrev) => {
        const userData = Auth.getUser();

        let proflabel = "";
        listProfessions.forEach(prof => {
            if (prof.id === QuotationData.contractorInfo.profession) {
                proflabel = prof.description;
            }
        });

        const json = JSON.stringify({
            clientinfo: QuotationData,
            clientlabelinfo: {
                profession: proflabel
            },
            questionario1: domandeQuestionarioTcm1,
            questionario2: domandeQuestionarioTcm2,
            quotationtype: "tcm"
        });
        const list = []
        companyListPrev.forEach((company) => {
            company.guarantees_select = guaranteesSelect;
            list.push(
                {
                    company: company,
                    datipre: datipreventivo[company.sfo_id + company.paramurl + company.type]
                }
            )
        });
        const url = API_URL_QUOTATION_LIST;
        axios.put(url, {
            product: window.location.pathname,
            id_user: userData.id,
            id_agency: pos,
            clientinfo: json,
            detail: JSON.stringify(list),
            select: "",
        },
            {
                headers: {
                    'Authorization': 'Bearer ' + Auth.getToken(),
                    'Content-Type': 'application/json'
                }
            }
        ).then(payload => {
            if (payload.status === 200) {
                setAlertMsg("Inserimento dati avvenuto con successo");
                setAlertSeverity("success");
                setNumberQuotation(payload.data.numberquotation);
            } else {
                setAlertMsg("Errore durante l'inserimento dati");
                setAlertSeverity("error");
            }
            setOpen(true);
        }).catch((error) => {
            setAlertMsg(error.response.data.error);
            setAlertSeverity("error");
            setOpen(true);
        });
    };

    const handleNext = () => {
        switch (activeStep) {
            case 0:
                if (pos === "") {
                    setAlertMsg("Nessun punto vendita abilitato, impossibile effettuare il preventivo");
                    setAlertSeverity("error");
                    setOpen(true);
                    break;
                }
                let dataMax = moment().add(30, "days");
                let dataEffetto = moment(QuotationData.dataEffetto);
                if (dataEffetto > dataMax) {
                    setAlertMsg("Non è possibile effettuare un preventivo con una scadenza superiore ad un mese");
                    setAlertSeverity("error");
                    setOpen(true);
                    break;
                }
                checkcompanyData(Auth.getToken(), pos).then(
                    payload => {
                        let companyListPrev = payload.data.services;
                        if (companyListPrev.length < 1) {
                            setAlertMsg("Prodotto non attivo per questo punto vendita");
                            setAlertSeverity("error");
                            setOpen(true);
                            return;
                        }
                        if (QuotationData.frazionamento === 0) {
                            setAlertMsg("Seleziona il frazionamento");
                            setAlertSeverity("error");
                            setOpen(true);
                            return;
                        }
                        if (QuotationData.contractorInfo.subjectType === "" || QuotationData.contractorInfo.subjectType === "1") {
                            if (QuotationData.contractorInfo.name === "" ||
                                QuotationData.contractorInfo.surname === "" ||
                                QuotationData.contractorInfo.birthDate === "" ||
                                QuotationData.contractorInfo.gender === "" ||
                                QuotationData.contractorInfo.fiscalCode === "" ||
                                QuotationData.contractorInfo.profession === "" ||
                                QuotationData.contractorInfo.profession === undefined ||
                                QuotationData.contractorInfo.birthDate === undefined
                            ) {
                                if (QuotationData.contractorInfo.birthDate === undefined || QuotationData.contractorInfo.birthDate === "") {
                                    setAlertMsg("Codice fiscale contraente errato");
                                } else {
                                    setAlertMsg("Dati contraente non completi");
                                }
                                setAlertSeverity("error");
                                setOpen(true);
                                return;
                            }
                        } else {
                            if (QuotationData.contractorInfo.vatNumber === undefined) QuotationData.contractorInfo.vatNumber = "";
                            if (QuotationData.contractorInfo.companyName === undefined) QuotationData.contractorInfo.companyName = "";
                            if (QuotationData.contractorInfo.vatNumber === "" ||
                                QuotationData.contractorInfo.companyName === ""
                            ) {
                                setAlertMsg("Dati contraente non completi");
                                setAlertSeverity("error");
                                setOpen(true);
                                return;
                            }
                        }
                        if (QuotationData.contractorInfo.residenceInfo.toponym === "" ||
                            QuotationData.contractorInfo.residenceInfo.address === "" ||
                            QuotationData.contractorInfo.residenceInfo.streetNumber === "" ||
                            QuotationData.contractorInfo.residenceInfo.city === "" ||
                            QuotationData.contractorInfo.residenceInfo.cap === "" ||
                            QuotationData.contractorInfo.residenceInfo.provinceAbbr === ""
                        ) {
                            setAlertMsg("Dati indirizzo non completi");
                            setAlertSeverity("error");
                            setOpen(true);
                            return;
                        }
                        if (QuotationData.contractorInfo.residenceInfo.provinceAbbr.length > 2) {
                            setAlertMsg("Sigla Provincia massimo 2 lettere");
                            setAlertSeverity("error");
                            setOpen(true);
                            return;
                        }
                        if (QuotationData.contractorInfo.email === undefined) QuotationData.contractorInfo.email = "";
                        if (!validator.isEmail(QuotationData.contractorInfo.email)) {
                            setAlertMsg("Email non valida");
                            setAlertSeverity("error");
                            setOpen(true);
                            return;
                        }
                        if (QuotationData.contractorInfo.phoneNumber === undefined) QuotationData.contractorInfo.phoneNumber = "";
                        if (QuotationData.contractorInfo.phoneNumber === "" || QuotationData.contractorInfo.phoneNumber.length < 10 || !(validator.isMobilePhone(QuotationData.contractorInfo.phoneNumber, 'it-IT'))) {
                            setAlertMsg("Numero cellulare non valido");
                            setAlertSeverity("error");
                            setOpen(true);
                            return;
                        }
                        let defOrg = JSON.parse(posinfo.defOrg);
                        let defGrp = JSON.parse(posinfo.defGrp);
                        let defAg = JSON.parse(posinfo.defAg);
                        let n = 0;
                        for (const cvt of guarantees) {
                            if (defAg !== null && defAg["TCM_" + cvt.code] !== undefined ? true : (defGrp !== null && defGrp["TCM_" + cvt.code] !== undefined ? true : (defOrg !== null && defOrg["TCM_" + cvt.code] !== undefined ? true : false))) {
                                let flag = (defAg !== null && defAg["TCM_" + cvt.code] !== undefined ? defAg["TCM_" + cvt.code] : (defGrp !== null && defGrp["TCM_" + cvt.code] !== undefined ? defGrp["TCM_" + cvt.code] : (defOrg !== null && defOrg["TCM_" + cvt.code] !== undefined ? defOrg["TCM_" + cvt.code] : 0)));
                                if (flag > 0) {
                                    guaranteesSelect[n] = true;
                                }
                            }
                            n++;
                        }
                        if (QuotationData.contractorInfo.subjectType === "" || QuotationData.contractorInfo.subjectType === "1") {
                            getInfoBirth(token, urlservicequotiamo, QuotationData.contractorInfo.fiscalCode, QuotationData.contractorInfo.name, QuotationData.contractorInfo.surname).then(
                                payload => {
                                    if (payload.data.status !== undefined) {
                                        setAlertMsg(payload.data.message);
                                        setAlertSeverity("error");
                                        setOpen(true);
                                        return;
                                    } else {
                                        setActiveStep((prevActiveStep) => prevActiveStep + 1);
                                    }
                                }
                            );
                        } else {
                            setActiveStep((prevActiveStep) => prevActiveStep + 1);
                        }
                    }
                ).catch(error => {
                    setAlertMsg("Prodotto non attivo per questo punto vendita");
                    setAlertSeverity("error");
                    setOpen(true);
                    return;
                });
                break;
            case 1:
                if (QuotationData.durataAnni === 0) {
                    setAlertMsg("Seleziona la durata");
                    setAlertSeverity("error");
                    setOpen(true);
                    return;
                }

                if (QuotationData.premioAnnuoLordo === 0 || QuotationData.premioAnnuoLordo === "0") {
                    setAlertMsg("Seleziona il premio TCM EASY");
                    setAlertSeverity("error");
                    setOpen(true);
                    return;
                }

                if (guarantees.length < 1) {
                    setAlertMsg("Nessuna garanzia selezionabile, contattare l'admin di sistema");
                    setAlertSeverity("error");
                    setOpen(true);
                    break;
                }
                let almeno_uno = false;
                let check_value = false;
                let error_msg = "";
                guaranteesValue.forEach((element, index) => {
                    if (guaranteesSelect[index]) {
                        almeno_uno = true;
                        if (guarantees[index].input_type !== 0) {
                            if (guaranteesValue[index] === "") {
                                check_value = true;
                                error_msg += guarantees[index].description + " ";
                            }
                        }
                    }
                });
                if (!almeno_uno) {
                    setAlertMsg("Deve essere selezionata almeno una garanzia");
                    setAlertSeverity("error");
                    setOpen(true);
                    break;
                }
                if (check_value !== false) {
                    setAlertMsg("Mancano dei massimali: ( " + error_msg + " )");
                    setAlertSeverity("error");
                    setOpen(true);
                    break;
                }

                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                break;
            case 2:
                QuotationData.questionarioFumatore = false;
                QuotationData.questionarioSanitario = false;
                // check questionari
                let checkTcm1 = false;
                domandeQuestionarioTcm1.forEach((domanda, index) => {
                    if (questionarioTcm1[domanda.value] === null || questionarioTcm1[domanda.value] === undefined) {
                        checkTcm1 = true;
                        return;
                    }
                    if (questionarioTcm1[domanda.value].trim() === "" || questionarioTcm1[domanda.value].trim() === "empty") {
                        checkTcm1 = true;
                        return;
                    }
                });
                if (checkTcm1 === true) {
                    setAlertMsg("La dichiarazione dell'assicurato non è stata compilata completamente");
                    setAlertSeverity("error");
                    setOpen(true);
                    return;
                }

                let checkTcm2 = false;
                domandeQuestionarioTcm2.forEach((domanda, index) => {
                    if (domanda.value !== "tcm_domanda_18_1" && domanda.value !== "tcm_domanda_19_1" && domanda.value !== "tcm_domanda_19_2") {
                        if (questionarioTcm2[domanda.value] === null || questionarioTcm2[domanda.value] === undefined) {
                            checkTcm2 = true;
                            return;
                        }
                        if (questionarioTcm2[domanda.value].trim() === "" || questionarioTcm2[domanda.value].trim() === "empty") {
                            checkTcm2 = true;
                            return;
                        }
                    } else {
                        if (domanda.value === "tcm_domanda_18_1") {
                            if (questionarioTcm2["tcm_domanda_18"] !== null && questionarioTcm2["tcm_domanda_18"] !== undefined && questionarioTcm2["tcm_domanda_18"] === "yes") {
                                if (questionarioTcm2[domanda.value] === null || questionarioTcm2[domanda.value] === undefined || questionarioTcm2[domanda.value].trim() === "") {
                                    checkTcm2 = true;
                                    return;
                                }
                            }
                        } else if (domanda.value === "tcm_domanda_19_1" || domanda.value === "tcm_domanda_19_2") {
                            if (questionarioTcm2["tcm_domanda_19"] !== null && questionarioTcm2["tcm_domanda_19"] !== undefined && questionarioTcm2["tcm_domanda_19"] === "yes") {
                                if (questionarioTcm2[domanda.value] === null || questionarioTcm2[domanda.value] === undefined || questionarioTcm2[domanda.value].trim() === "" || questionarioTcm2[domanda.value].trim() === "empty") {
                                    checkTcm2 = true;
                                    return;
                                }
                            }
                        }
                    }
                    if (domanda.value === "tcm_domanda_19") {
                        if (questionarioTcm2[domanda.value].trim() === "yes") {
                            QuotationData.questionarioFumatore = true;
                        }
                    }
                    if (domanda.value === "tcm_domanda_19_2") {
                        if (QuotationData.questionarioFumatore === true && questionarioTcm2[domanda.value].trim() === "yes") {
                            QuotationData.questionarioFumatore = false;
                        }
                    }
                });
                if (checkTcm2 === true) {
                    setAlertMsg("La dichiarazione professionale, sportiva e sanitaria dell'assicurato non è stata compilata completamente");
                    setAlertSeverity("error");
                    setOpen(true);
                    return;
                }

                domandeQuestionarioTcm1.forEach((domanda, index) => {
                    if (questionarioTcm1[domanda.value].trim() === "no") {
                        QuotationData.questionarioSanitario = true;
                        return;
                    }
                });

                domandeQuestionarioTcm2.forEach((domanda, index) => {
                    if (domanda.value !== "tcm_domanda_18_1" && domanda.value !== "tcm_domanda_12" && domanda.value !== "tcm_domanda_13") {
                        if (questionarioTcm2[domanda.value].trim() === "yes") {
                            QuotationData.questionarioSanitario = true;
                            return;
                        }
                    }
                });

                QuotationData.questionario = {};
                QuotationData.questionario.questionarioTcm1 = questionarioTcm1;
                QuotationData.questionario.questionarioTcm2 = questionarioTcm2;

                AuthService.signin(posinfo.username, posinfo.password, posinfo.url).then(
                    payload => {
                        setToken(payload);
                        checkcompanyData(Auth.getToken(), pos).then(
                            payload => {
                                let companyListPrev = payload.data.services;
                                let conventionslist = payload.data.conventions;
                                setFactoriesHub(payload.data.factorieshub);

                                companyListPrev.forEach((company, indexcompany) => {
                                    guarantees.forEach((element, index) => {
                                        if (guaranteesSelect[index]) {
                                            if (element["input_type"] !== 0) {
                                                companyListPrev[indexcompany].guarantees_info[index].massimale = Number(guaranteesValue[index]);
                                            }
                                        }
                                    });
                                });

                                setcompanyData(companyListPrev);
                                let AddOnService = [];

                                setPrevRS(0);
                                setPrevRQ(companyListPrev.length);
                                contaPreventiviDaFare = companyListPrev.length;
                                contaPreventiviFatti = 0;

                                companyListPrev.map((company) => (
                                    checkPreventivo(QuotationData, company, token, guarantees, guaranteesSelect, guaranteesValue, conventionslist, Auth.getToken()).then(
                                        payload => {
                                            const newDatiPreventivo = datipreventivo;
                                            if (payload.data.prices !== undefined) {
                                                payload.data.prices.forEach(element => {
                                                    let convenzioneAttiva = CheckConvenzioni(QuotationData, company, conventionslist, 1);
                                                    element.convenzioneAttiva = convenzioneAttiva;
                                                    if (element.convenzioneAttiva.code === "") {
                                                        convenzioneAttiva = CheckConvenzioni(QuotationData, company, conventionslist, 0);
                                                        element.convenzioneAttiva = convenzioneAttiva;
                                                        if (convenzioneAttiva !== false && convenzioneAttiva.code !== "") {
                                                            //Al momento sovrascrire qualsiasi sconto commerciale
                                                            //Dopo vedere il flag
                                                            company.discount_description = convenzioneAttiva.description;
                                                            company.discounts_rules = convenzioneAttiva.discounts_rules;
                                                        }
                                                    }

                                                    let sconto_totale = 0;
                                                    let nettoTotalePartenza = 0;
                                                    let nettoTotaleScontato = 0;
                                                    let differenzaImposte = 0;
                                                    if (company.discount_description !== null && company.discounts_rules !== null) {
                                                        element.listResponseCVT.forEach(
                                                            cvt => {
                                                                let selectCVT = "";
                                                                nettoTotalePartenza += cvt.price;
                                                                cvt.originalprice = cvt.price;
                                                                selectCVT = cvt.code;
                                                                if (selectCVT !== "") {
                                                                    JSON.parse(company.discounts_rules).forEach(
                                                                        single_discount_rules => {
                                                                            if (single_discount_rules.hasOwnProperty(selectCVT)) {
                                                                                if (single_discount_rules[selectCVT].val !== null && cvt.price >= Number(single_discount_rules[selectCVT].val)) {
                                                                                    sconto_totale += Number(single_discount_rules[selectCVT].val);
                                                                                    cvt.price -= Number(single_discount_rules[selectCVT].val);
                                                                                }
                                                                                if (single_discount_rules[selectCVT].perc !== null) {
                                                                                    sconto_totale += (cvt.price * (Number(single_discount_rules[selectCVT].perc))) / 100;
                                                                                    cvt.price -= (cvt.price * (Number(single_discount_rules[selectCVT].perc))) / 100;
                                                                                }
                                                                                nettoTotaleScontato += cvt.price;
                                                                            }
                                                                        }
                                                                    )
                                                                }
                                                            }
                                                        );

                                                        if (company.paramurl === "groupamanexus") {
                                                            element.listResponseCVT.forEach(
                                                                cvt => {
                                                                    if (cvt.code === "TAX") {
                                                                        let imposteTotale = cvt.price;
                                                                        let scontoPercTax = (nettoTotaleScontato * 100) / nettoTotalePartenza;
                                                                        cvt.price = (cvt.price * scontoPercTax) / 100;
                                                                        differenzaImposte = imposteTotale - cvt.price;
                                                                    }
                                                                }
                                                            );
                                                        }
                                                    }
                                                    element.TotalPrice -= parseFloat(sconto_totale);
                                                    element.price -= parseFloat(sconto_totale);
                                                    if (company.paramurl === "groupamanexus") {
                                                        element.TotalPrice -= parseFloat(differenzaImposte);
                                                        element.price -= parseFloat(differenzaImposte);
                                                    }

                                                    let addCalcoloCvt = 0;
                                                    guarantees.forEach((elementGuar, indexGuar) => {
                                                        if (guaranteesSelect[indexGuar]) {
                                                            let calcoloPercValue = 0;
                                                            let tipoCalcolo = company.guarantees_info[indexGuar].tipo_calcolo !== null ? (company.guarantees_info[indexGuar].tipo_calcolo !== "default" ? company.guarantees_info[indexGuar].tipo_calcolo : company.guarantees_info[indexGuar].tipo_calcolo_default) : company.guarantees_info[indexGuar].tipo_calcolo_default;
                                                            let serviceParameters = company.guarantees_info[indexGuar].tipo_calcolo !== null ? (company.guarantees_info[indexGuar].tipo_calcolo !== "default" ? company.guarantees_info[indexGuar].service_parameters : company.guarantees_info[indexGuar].service_parameters_default) : company.guarantees_info[indexGuar].service_parameters_default;
                                                            let tipoArrotondamento = company.guarantees_info[indexGuar].tipo_arrotondamento_calcolo;
                                                            if (tipoCalcolo === "perc") {
                                                                calcoloPercValue = parseFloat2Decimals((element.price * (Number(serviceParameters))) / 100);
                                                                if (tipoArrotondamento === "sup") {
                                                                    calcoloPercValue = Math.ceil(calcoloPercValue);
                                                                } else if (tipoArrotondamento === "inf") {
                                                                    calcoloPercValue = Math.floor(calcoloPercValue);
                                                                } else if (tipoArrotondamento === "mat") {
                                                                    calcoloPercValue = Math.round(calcoloPercValue);
                                                                }
                                                                addCalcoloCvt += calcoloPercValue;
                                                                let guarInfo =
                                                                {
                                                                    code: elementGuar["code"],
                                                                    nameCVT: elementGuar["description"],
                                                                    selected: true,
                                                                    price: calcoloPercValue
                                                                };
                                                                element.listResponseCVT.push(guarInfo);
                                                            } else if (tipoCalcolo === "value") {
                                                                calcoloPercValue = Number(serviceParameters);
                                                                addCalcoloCvt += parseFloat2Decimals(calcoloPercValue);
                                                                let guarInfo =
                                                                {
                                                                    code: elementGuar["code"],
                                                                    nameCVT: elementGuar["description"],
                                                                    selected: true,
                                                                    price: parseFloat2Decimals(calcoloPercValue)
                                                                };
                                                                element.listResponseCVT.push(guarInfo);
                                                            }
                                                        }
                                                    });

                                                    element.TotalPrice += addCalcoloCvt;
                                                    element.price += addCalcoloCvt;

                                                    let NumRate = 1;
                                                    let splitPrice = element.price;

                                                    if (element.splittingUp !== undefined && !isNaN(element.splittingUp)) {
                                                        NumRate = parseFloat(element.splittingUp);
                                                    }
                                                    if (element.splitPrice !== undefined && !isNaN(element.splitPrice)) {
                                                        splitPrice = parseFloat(element.splitPrice);
                                                    } else {
                                                        splitPrice = element.price;
                                                    }

                                                    splitPrice += (addCalcoloCvt / NumRate).toFixed(2);

                                                    // COMMISSIONI
                                                    if (company.FinalCommissionsType !== null && company.FinalCommissionsType === "euro") {
                                                        if (Number(company.FinalCommissionsDefault) >= 0) {
                                                            element.Commissions = company.FinalCommissionsDefault * NumRate;
                                                        } else {
                                                            if (Number(company.FinalCommissionsMax)) {
                                                                element.Commissions = company.FinalCommissionsMax * NumRate;
                                                            } else {
                                                                element.Commissions = 0;
                                                            }
                                                        }
                                                    } else {
                                                        if (Number(company.FinalCommissionsDefault) >= 0) {
                                                            element.Commissions = element.price * company.FinalCommissionsDefault / 100;
                                                            element.Commissions = element.Commissions.toFixed(2);
                                                        } else {
                                                            if (Number(company.FinalCommissionsMax)) {
                                                                element.Commissions = element.price * company.FinalCommissionsMax / 100;
                                                                element.Commissions = element.Commissions.toFixed(2);
                                                            } else {
                                                                element.Commissions = 0;
                                                            }
                                                        }
                                                    }

                                                    // DIRITTI
                                                    if (company.FinalfeeType !== null && company.FinalfeeType === "perc") {
                                                        if (Number(company.FinalfeeDefault) >= 0) {
                                                            element.Fees = element.price * company.FinalfeeDefault / 100;
                                                            element.Fees = element.Fees.toFixed(2);
                                                        } else {
                                                            if (Number(company.FinalfeeMax)) {
                                                                element.Fees = element.price * company.FinalfeeMax / 100;
                                                                element.Fees = element.Fees.toFixed(2);
                                                            } else {
                                                                element.Fees = 0;
                                                            }
                                                        }
                                                    } else {
                                                        if (Number(company.FinalfeeDefault) >= 0) {
                                                            element.Fees = company.FinalfeeDefault * NumRate;
                                                        } else {
                                                            if (Number(company.FinalfeeMax)) {
                                                                element.Fees = company.FinalfeeMax * NumRate;
                                                            } else {
                                                                element.Fees = 0;
                                                            }
                                                        }
                                                    }

                                                    element.TotalPrice = parseFloat(element.price) + parseFloat(element.Commissions) + parseFloat(element.Fees);

                                                    element.ListService = [];
                                                    AddOnService.forEach(service => {
                                                        let priceservice = 0;
                                                        if (service.type === "perc") {
                                                            priceservice = element.price * service.value / 100;
                                                            priceservice = parseFloat(priceservice);
                                                        } else {
                                                            priceservice = service.value;
                                                        }
                                                        element.TotalPrice += parseFloat(priceservice);

                                                        let ServiceInfo =
                                                        {
                                                            description: "NetAssistance",
                                                            priceservice: priceservice
                                                        };
                                                        element.ListService.push(ServiceInfo);
                                                    });

                                                    element.TotalRate = parseFloat(element.TotalPrice / NumRate);
                                                    element.TotalRate = element.TotalRate.toFixed(2);
                                                    element.only_cvt = company.only_cvt;
                                                });
                                            }

                                            newDatiPreventivo[company.sfo_id + company.paramurl + company.type] = payload.data;
                                            setdatipreventivo(newDatiPreventivo);
                                            setPrevRS(prevCount => prevCount + 1);
                                            contaPreventiviFatti++;

                                            if (contaPreventiviFatti === contaPreventiviDaFare && contaPreventiviDaFare > 0) {
                                                HandleSaveQuotation(companyListPrev);
                                            }

                                            if (prevRQ === prevRS) {
                                                setLoading(false);
                                            }
                                        }
                                    ).catch(error => {
                                        setPrevRS(prevCount => prevCount + 1);
                                        contaPreventiviFatti++;
                                        if (contaPreventiviFatti === contaPreventiviDaFare && contaPreventiviDaFare > 0) {
                                            HandleSaveQuotation(companyListPrev);
                                        }
                                        if (prevRQ === prevRS)
                                            setLoading(false);
                                    })
                                ));
                            }
                        ).catch(error => {
                            console.log(error);
                        });
                    }
                ).catch(error => {
                    setAlertMsg("Errore durante la connessione a QUOTIAMO Service");
                    setAlertSeverity("error");
                    setOpen(true);
                });

                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                break;
            case 3:
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                break;
            default:
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                break;
        }
    }

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return <>
                    <div className={classes.paper}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={4}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel id="labelPOS">Punto vendita</InputLabel>
                                    <Select
                                        labelId="labelPOS"
                                        id="pos"
                                        value={pos}
                                        onChange={handleChangePos}
                                        label="Punto vendita"
                                    >
                                        {agencieslists.length > 0 && agencieslists.map(option => (
                                            <MenuItem
                                                key={"ag_" + option.id}
                                                value={option.id}
                                            >
                                                {option.description}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <KeyboardDatePicker
                                    label="Data effetto"
                                    value={QuotationData.dataEffetto}
                                    onChange={handleChangeDataEffetto}
                                    format='dd/MM/yyyy'
                                    clearLabel="Conferma"
                                    cancelLabel="Annulla"
                                    name="dataEffetto"
                                    fullWidth
                                    required
                                    invalidDateMessage="Data non valida"
                                    // maxDateMessage="Non è possibile effettuare un preventivo con una scadenza superiore alla fine del mese successivo"
                                    minDateMessage="Il preventivo non può essere retroattivo"
                                    minDate={moment()}
                                    // maxDate={moment().add(1, "months").endOf('month')}
                                    maxDate={moment().add(30, "days")}
                                    inputVariant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    label="Frazionamento"
                                    name="frazionamento"
                                    onChange={handleChange}
                                    select
                                    SelectProps={{ native: true }}
                                    value={QuotationData.frazionamento}
                                    variant="outlined"
                                >
                                    <option
                                        key="NOFRAZ"
                                        value={0}
                                    >
                                        Seleziona il frazionamento
                                    </option>
                                    {tipoFrazionamento.map(option => (
                                        <option
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                    </div>
                    <SubjectDetails
                        datatype={"tcm"}
                        type={"contractorInfo"}
                        token={token}
                        urlservicequotiamo={urlservicequotiamo}
                        datiquotazione={QuotationData}
                        setdatiquotazione={setQuotationData}
                        listCity={listCity}
                        listProfessions={listProfessions}
                        posinfo={posinfo}
                    />
                </>;
            case 1:
                return <>
                    <div className={classes.paper}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    label="Durata anni"
                                    name="durataAnni"
                                    onChange={handleChange}
                                    select
                                    SelectProps={{ native: true }}
                                    value={QuotationData.durataAnni}
                                    variant="outlined"
                                >
                                    <option value={0}>
                                        Seleziona la durata
                                    </option>
                                    {durationsListTcm.map(option => (
                                        <option
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    label="Beneficiario"
                                    name="beneficiario"
                                    onChange={handleChange}
                                    select
                                    SelectProps={{ native: true }}
                                    value={QuotationData.beneficiario}
                                    variant="outlined"
                                >
                                    {beneficiariTcm.map(option => (
                                        <option
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    label="Premio TCM EASY"
                                    name="premioAnnuoLordo"
                                    onChange={handleChange}
                                    select
                                    SelectProps={{ native: true }}
                                    value={QuotationData.premioAnnuoLordo}
                                    variant="outlined"
                                >
                                    <option value={0}>
                                        Seleziona premio TCM EASY
                                    </option>
                                    {premioTcmEasy.map(option => (
                                        <option
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                        <Grid container spacing={0}>
                            {guarantees.map((warranty, index) => (
                                <>
                                    {(index === 0 || guarantees[index - 1].categoriesdescription !== warranty.categoriesdescription) &&
                                        <Grid key={"cat_" + index} item xs={12}>
                                            <Typography className={classes.TitleCategory}>
                                                {warranty.categoriesdescription}
                                            </Typography>
                                        </Grid>
                                    }
                                    <Grid key={index} item xs={2}>
                                        <CheckSvgButton disabled={posinfo.defAg !== null && JSON.parse(posinfo.defAg)["TCM_" + warranty.code] !== undefined ? JSON.parse(posinfo.defAg)["TCM_" + warranty.code] >= 2 : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp)["TCM_" + warranty.code] !== undefined ? true : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg)["TCM_" + warranty.code] !== undefined ? true : false)) &&
                                            (((posinfo.defAg !== null && JSON.parse(posinfo.defAg)["TCM_" + warranty.code] !== undefined ? JSON.parse(posinfo.defAg)["TCM_" + warranty.code] : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp)["TCM_" + warranty.code] !== undefined ? JSON.parse(posinfo.defGrp)["TCM_" + warranty.code] : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg)["TCM_" + warranty.code] !== undefined ? JSON.parse(posinfo.defOrg)["TCM_" + warranty.code] : 0))) & 2) >= 2)} label={warranty.description} svg={warranty.image} status={guaranteesSelect[index]} click={handleClick(index)} />
                                        <Box className={classes.BoxContainer}>
                                            {warranty.input_type === 1 &&
                                                <>
                                                    <NumberFormat disabled={!guaranteesSelect[index]} onValueChange={handleChangeFreeInput(index)} customInput={TextFieldCustomMassimale} fixedDecimalScale="true" decimalScale="0" value={guaranteesValue[index]} displayType={'input'} decimalSeparator={" "} thousandSeparator={"."} prefix={'€ '} />
                                                </>
                                            }
                                            {warranty.input_type === 2 && <>
                                                <TextField
                                                    disabled={!guaranteesSelect[index]}
                                                    fullWidth
                                                    label="Massimale"
                                                    onChange={handleChangeList(index)}
                                                    select
                                                    SelectProps={{ native: true }}
                                                    value={guaranteesValue[index]}
                                                    variant="outlined"
                                                >
                                                    {warranty.list_value.split(",").map(option => (
                                                        <option
                                                            key={option}
                                                            value={option}
                                                        >
                                                            {option.indexOf("%") >= 0 && "" + option}
                                                            {option.indexOf("%") === -1 && "€ " + number_format(option, 0, ",", ".")}
                                                        </option>
                                                    ))}
                                                </TextField>
                                            </>}
                                        </Box>
                                    </Grid>
                                </>
                            ))}
                        </Grid>
                    </div>
                </>;
            case 2:
                return <>
                    <div className={classes.paper}>
                        <Accordion defaultExpanded={true} style={{ width: "100%" }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className={classes.heading}>Dichiarazione dell'assicurato</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TableContainer className={classes.table}>
                                    <Table aria-label="Dichiarazione dell'assicurato">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ width: "60%" }}>Domanda</TableCell>
                                                <TableCell>Risposta</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {domandeQuestionarioTcm1.map((domanda, index) => (
                                                <TableRow>
                                                    <TableCell style={{ width: "40%" }}>
                                                        {domanda.description}
                                                    </TableCell>
                                                    <TableCell>
                                                        {domanda.type === "select" &&
                                                            <TextField
                                                                margin="normal"
                                                                fullWidth
                                                                label={"Domanda"}
                                                                name={domanda.value}
                                                                onChange={handleChangeValueTcm1}
                                                                select
                                                                SelectProps={{ native: true }}
                                                                value={questionarioTcm1 === null ? "" : questionarioTcm1[domanda.value] === undefined ? "" : questionarioTcm1[domanda.value]}
                                                                variant="outlined"
                                                            >
                                                                <option key={domanda.value + "_vuoto"} value={"empty"}>
                                                                    Seleziona
                                                                </option>
                                                                <option key={domanda.value + "_no"} value={"no"}>
                                                                    NO
                                                                </option>
                                                                <option key={domanda.value + "_si"} value={"yes"}>
                                                                    SI
                                                                </option>
                                                            </TextField>
                                                        }
                                                        {domanda.type === "text" &&
                                                            <TextField
                                                                variant="outlined"
                                                                margin="normal"
                                                                fullWidth
                                                                id={domanda.value}
                                                                label={"Domanda"}
                                                                name={domanda.value}
                                                                size="medium"
                                                                multiline
                                                                rows={4}
                                                                value={questionarioTcm1 === null ? "no" : questionarioTcm1[domanda.value] === undefined ? "" : questionarioTcm1[domanda.value]}
                                                                onChange={handleChangeValueTcm1}
                                                            />
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion defaultExpanded={true} style={{ width: "100%" }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className={classes.heading}>Dichiarazione professionale, sportiva e sanitaria dell'assicurato</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TableContainer className={classes.table}>
                                    <Table aria-label="Dichiarazione professionale, sportiva e sanitaria dell'assicurato">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ width: "60%" }}>Domanda</TableCell>
                                                <TableCell>Risposta</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {domandeQuestionarioTcm2.map((domanda, index) => (
                                                <>
                                                    {domanda.value !== "tcm_domanda_18_1" && domanda.value !== "tcm_domanda_19_1" && domanda.value !== "tcm_domanda_19_2" &&
                                                        <TableRow>
                                                            <TableCell style={{ width: "40%" }}>
                                                                {domanda.description}
                                                            </TableCell>
                                                            <TableCell>
                                                                {domanda.type === "select" &&
                                                                    <TextField
                                                                        margin="normal"
                                                                        fullWidth
                                                                        label={"Domanda"}
                                                                        name={domanda.value}
                                                                        onChange={handleChangeValueTcm2}
                                                                        select
                                                                        SelectProps={{ native: true }}
                                                                        value={questionarioTcm2 === null ? "" : questionarioTcm2[domanda.value] === undefined ? "" : questionarioTcm2[domanda.value]}
                                                                        variant="outlined"
                                                                    >
                                                                        <option key={domanda.value + "_vuoto"} value={"empty"}>
                                                                            Seleziona
                                                                        </option>
                                                                        <option key={domanda.value + "_no"} value={"no"}>
                                                                            NO
                                                                        </option>
                                                                        <option key={domanda.value + "_si"} value={"yes"}>
                                                                            SI
                                                                        </option>
                                                                    </TextField>
                                                                }
                                                                {domanda.type === "text" &&
                                                                    <TextField
                                                                        variant="outlined"
                                                                        margin="normal"
                                                                        fullWidth
                                                                        id={domanda.value}
                                                                        label={"Domanda"}
                                                                        name={domanda.value}
                                                                        size="medium"
                                                                        multiline
                                                                        rows={4}
                                                                        value={questionarioTcm2 === null ? "no" : questionarioTcm2[domanda.value] === undefined ? "" : questionarioTcm2[domanda.value]}
                                                                        onChange={handleChangeValueTcm2}
                                                                    />
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                    {domanda.value === "tcm_domanda_18_1" && questionarioTcm2 !== null && questionarioTcm2.tcm_domanda_18 !== undefined && questionarioTcm2.tcm_domanda_18 === "yes" &&
                                                        <TableRow>
                                                            <TableCell style={{ width: "40%" }}>
                                                                {domanda.description}
                                                            </TableCell>
                                                            <TableCell>
                                                                {domanda.type === "text" &&
                                                                    <TextField
                                                                        variant="outlined"
                                                                        margin="normal"
                                                                        fullWidth
                                                                        id={domanda.value}
                                                                        label={"Domanda"}
                                                                        name={domanda.value}
                                                                        size="medium"
                                                                        multiline
                                                                        rows={4}
                                                                        value={questionarioTcm2 === null ? "no" : questionarioTcm2[domanda.value] === undefined ? "" : questionarioTcm2[domanda.value]}
                                                                        onChange={handleChangeValueTcm2}
                                                                    />
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                    {(domanda.value === "tcm_domanda_19_1" || domanda.value === "tcm_domanda_19_2") && questionarioTcm2 !== null && questionarioTcm2.tcm_domanda_19 !== undefined && questionarioTcm2.tcm_domanda_19 === "yes" &&
                                                        <TableRow>
                                                            <TableCell style={{ width: "40%" }}>
                                                                {domanda.description}
                                                            </TableCell>
                                                            <TableCell>
                                                                {domanda.type === "select" &&
                                                                    <TextField
                                                                        margin="normal"
                                                                        fullWidth
                                                                        label={"Domanda"}
                                                                        name={domanda.value}
                                                                        onChange={handleChangeValueTcm2}
                                                                        select
                                                                        SelectProps={{ native: true }}
                                                                        value={questionarioTcm2 === null ? "" : questionarioTcm2[domanda.value] === undefined ? "" : questionarioTcm2[domanda.value]}
                                                                        variant="outlined"
                                                                    >
                                                                        <option key={domanda.value + "_vuoto"} value={"empty"}>
                                                                            Seleziona
                                                                        </option>
                                                                        <option key={domanda.value + "_no"} value={"no"}>
                                                                            NO
                                                                        </option>
                                                                        <option key={domanda.value + "_si"} value={"yes"}>
                                                                            SI
                                                                        </option>
                                                                    </TextField>
                                                                }
                                                                {domanda.type === "text" &&
                                                                    <TextField
                                                                        variant="outlined"
                                                                        margin="normal"
                                                                        fullWidth
                                                                        id={domanda.value}
                                                                        label={"Domanda"}
                                                                        name={domanda.value}
                                                                        size="medium"
                                                                        multiline
                                                                        rows={4}
                                                                        value={questionarioTcm2 === null ? "no" : questionarioTcm2[domanda.value] === undefined ? "" : questionarioTcm2[domanda.value]}
                                                                        onChange={handleChangeValueTcm2}
                                                                    />
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                </>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </>;
            case 3:
                return <Paper className={classes.paper}>
                    <div className={classes.progress}>{progress}</div>
                    {prevRS < prevRQ &&
                        <div className={classes.linearProgressDiv}>
                            <LinearProgressWithLabel value={100 * prevRS / prevRQ} />
                            {images.length > 0 &&
                                <Carousel
                                    className={classes.Carousel}
                                    autoPlay={true}
                                    indicators={true}
                                    timeout={500}
                                    cycleNavigation={true}
                                    navButtonsAlwaysVisible={true}
                                    navButtonsAlwaysInvisible={false}
                                    fullHeightHover={true}
                                >
                                    {
                                        images.map((item, index) => {
                                            return <CardMedia
                                                className={classes.Media}
                                                image={item.image}
                                            >
                                            </CardMedia>
                                        })
                                    }
                                </Carousel>
                            }
                        </div>
                    }
                    {companyData.map((company, index) =>
                        <Box className={classes.root} m={2} >
                            <QuotationDetails key={index} factories={factories} factoriesHub={factoriesHub} company={company} datipre={datipreventivo[company.sfo_id + company.paramurl + company.type]} saveActive={false} />
                        </Box>
                    )}
                    {prevRS === prevRQ && images.length > 0 &&
                        <Carousel
                            className={classes.Carousel}
                            autoPlay={true}
                            animation={"fade"}
                            indicators={true}
                            timeout={500}
                            cycleNavigation={true}
                            navButtonsAlwaysVisible={true}
                            navButtonsAlwaysInvisible={false}
                            fullHeightHover={true}
                        >
                            {
                                images.map((item, index) => {
                                    return <CardMedia
                                        key={index}
                                        className={classes.Media}
                                        image={item.image}
                                    >
                                    </CardMedia>
                                })
                            }
                        </Carousel>
                    }
                </Paper>;
            case 4:
                return <div className={classes.paper}>
                    {NumberQuotation &&
                        <QuotationSingleDetail numberquotation={NumberQuotation} visual={true} />
                    }
                </div>;
            default:
                return 'Unknown step';
        }
    };

    return (
        <main className={classes.content}>
            <BackGround open={loading} />
            <ValidatorForm
                onSubmit={() => {
                }}
                onError={errors => console.log(errors)}
            >
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid className={classes.containerButton} item md={8}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link color="inherit" href="/" >
                                    {NAME_APP}
                                </Link>
                                <Link color="inherit" href="/tcm" >
                                    TCM
                                </Link>
                                <Typography color="textPrimary">Richiesta preventivo</Typography>
                            </Breadcrumbs>
                        </Grid>
                        <Grid className={classes.containerButton} item md={4}>
                            <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                                Indietro
                            </Button>
                            {activeStep < steps.length &&
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNext}
                                    className={classes.button}
                                    disabled={activeStep === steps.length - 1 || (activeStep === 3 && prevRS < prevRQ && prevRQ > 0)}
                                >
                                    Avanti
                                </Button>
                            }
                        </Grid>
                    </Grid>
                    <Card>
                        <CardContent>
                            <Stepper activeStep={activeStep}>
                                {steps.map((label, index) => {
                                    const stepProps = {};
                                    const labelProps = {};
                                    return (
                                        <Step key={label} {...stepProps}>
                                            <StepLabel {...labelProps}>{label}</StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                            <div className={classes.wrapper}>
                                {activeStep === steps.length ? (
                                    <div>
                                        <Typography className={classes.instructions}>
                                            Richiesta Preventivo completata
                                        </Typography>
                                        <Button onClick={handleReset} className={classes.button}>
                                            Nuovo preventivo
                                        </Button>
                                    </div>
                                ) : (
                                    <div className={classes.fulldiv}>
                                        {getStepContent(activeStep)}
                                    </div>
                                )}
                            </div>
                        </CardContent>
                    </Card>
                    <Grid container spacing={3}>
                        <Grid className={classes.containerButton} item md={12}>
                            <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                                Indietro
                            </Button>
                            {activeStep < steps.length &&
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNext}
                                    className={classes.button}
                                    disabled={activeStep === steps.length - 1 || (activeStep === 3 && prevRS < prevRQ && prevRQ > 0)}
                                >
                                    Avanti
                                </Button>
                            }
                        </Grid>
                    </Grid>
                </Container>

                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                    <Alert onClose={handleClose} severity={alertseverity}>
                        {alertmsg}
                    </Alert>
                </Snackbar>
            </ValidatorForm>
        </main>
    );
}