import React from 'react';
//import {withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MaterialTable from 'material-table';
import { id_cliente, ReturnInfoClient } from '../containers/logincontext';
import { API_URL_FACTORIES_PRODUCTS, API_URL_ALLPRODUCTS } from '../config/config';
import BlockIcon from '@material-ui/icons/Block';
import { green, red } from '@material-ui/core/colors';
import Tooltip from '@material-ui/core/Tooltip';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Snackbar from '@material-ui/core/Snackbar';
import Auth from '../auth/auth';
import axios from 'axios';
import MuiAlert from '@material-ui/lab/Alert';
import FactoriesGuaranteesTable from './FactoriesGuaranteesTable';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import FactoriesLinksTable from './FactoriesLinksTable';
import ServicesProdConnTable from './ServicesProdConnTable';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  cardMaxSize: {
    width: '100%'
  },
  alignFab: {
    marginLeft: 'auto',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  img: {
    maxWidth: '80px',
    maxHeight: '40px',
    justifyContent: 'center'
  },
  red_fill: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
  },
  green_fill: {
    color: '#fff',
    backgroundColor: green[500],
  },
  red: {
    color: red[500]

  },
  green: {
    color: green[500]
  },
  customBadgeOk: {
    backgroundColor: green[500],
    color: "white"
  },
  customBadgeKo: {
    backgroundColor: red[500],
    color: "white"
  }
}));

export default function FactoriesProductsTable(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [alertseverity, setAlertSeverity] = React.useState("success");
  const [alertmsg, setAlertMsg] = React.useState(false);
  const [productsList, setProductsList] = React.useState(null);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  React.useEffect(() => {
    const url = API_URL_ALLPRODUCTS;
    axios.get(url,
      {
        headers: {
          'Authorization': 'Bearer ' + Auth.getToken(),
          'Content-Type': 'application/json'
        }
      }
    ).then(payload => {
      let temp = [];
      payload.data.products.forEach(element => {
        temp.push({ [element.id]: element.description });
      });
      var obj = payload.data.products.reduce(function (acc, cur, i) {
        acc[cur.id] = cur.description;
        return acc;
      }, {});
      setProductsList(obj);
    });
  }, []);

  return (<Card className={classes.cardMaxSize}>
    <CardContent  >
      {productsList !== null &&
        <MaterialTable
          title={"Prodotti abilitati " + props.dataPanel.description}
          columns={[
            {
              title: 'Prodotto',
              field: 'id_product',
              lookup: productsList,
              render: rowData => productsList[rowData.id_product]

            },
            {
              title: 'Stato',
              field: 'enabled',
              lookup: { 1: 'Abilitato', 0: 'Disabilitato' },
              render: rowData => <Tooltip title={rowData.enabled === 1 ? "Abilitato" : "Disabilitato"}>
                {rowData.enabled === 1 ? <CheckCircleOutlineIcon className={classes.green} /> : <BlockIcon className={classes.red} />}
              </Tooltip>

            },
          ]}
          detailPanel={[
            {
              tooltip: 'Parametri garanzie prodotto',
              render: rowData => {
                /* return <FactoriesGuaranteesTable dataPanel={rowData} />; */
                return (
                  <div>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1-header"
                      >
                        <Typography className={classes.heading}>Garanzie</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <FactoriesGuaranteesTable dataPanel={rowData} />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1-header"
                      >
                        <Typography className={classes.heading}>Link informativi</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <FactoriesLinksTable dataPanel={rowData} />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1-header"
                      >
                        <Typography className={classes.heading}>Tipologie di connessione</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <ServicesProdConnTable dataPanel={rowData} />
                      </AccordionDetails>
                    </Accordion>
                  </div>
                );
              },
            },
          ]
          }
          localization={{
            pagination: {
              labelDisplayedRows: '{from}-{to} di {count}',
              labelRowsSelect: 'per pagina',
              labelRowsPerPage: 'per pagina',
              firstAriaLabel: 'prima pagina',
              firstTooltip: 'prima pagina',
              previousAriaLabel: 'pagina precedente',
              previousTooltip: 'pagina precedente',
              nextAriaLabel: 'pagina successiva',
              nextTooltip: 'pagina successiva',
              lastAriaLabel: 'ultima pagina',
              lastTooltip: 'ultima pagina',
            },
            toolbar: {
              nRowsSelected: '{0} elementi selezionati',
              searchTooltip: 'Ricerca',
              searchPlaceholder: 'Ricerca'
            },
            header: {
              actions: ''
            },
            body: {
              emptyDataSourceMessage: 'Nessun record da visualizzare',
              filterRow: {
                filterTooltip: 'Filtri'
              },
              editRow: {
                saveTooltip: "Conferma",
                cancelTooltip: "Annulla",
                deleteText: "Confermi la cancellazione ?"
              },
              addTooltip: "Aggiungi",
              deleteTooltip: "Cancella",
              editTooltip: "Modifica",
            }
          }}
          options={{
            pageSize: 10,
            pageSizeOptions: [5, 10],
            paginationType: "stepped",
            search: true,
            headerStyle: {
              backgroundColor: ReturnInfoClient(id_cliente, "primary_color"),
              color: '#ffffff',
              '&:hover': {
                color: '#ffffff',
              },
              '&:active': {
                color: '#ffffff',
              }
            }

          }}
          data={query =>
            new Promise((resolve, reject) => {
              let url = API_URL_FACTORIES_PRODUCTS + "/" + props.dataPanel.id;
              url += '?per_page=' + query.pageSize
              url += '&page=' + (query.page + 1)

              if (query.search.length > 3) {
                url += '&search=' + query.search
              }
              if (query.orderBy !== undefined) {
                url += '&orderby=' + query.orderBy.field
                url += '&orderdirection=' + query.orderDirection
              }

              const requestOptions = {
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + Auth.getToken(),
                },
              };
              fetch(url, requestOptions)
                .then(
                  response => response.json()
                )
                .then(
                  result => {
                    resolve({
                      data: result.data,
                      page: result.current_page - 1,
                      totalCount: result.total,
                    })
                  })
            })
          }
          editable={{
            onRowAdd: newData =>
              new Promise((resolve, reject) => {
                const url = API_URL_FACTORIES_PRODUCTS;
                axios.put(url, {
                  id_factory: props.dataPanel.id,
                  enabled: newData.enabled,
                  id_product: newData.id_product
                },
                  {
                    headers: {
                      'Authorization': 'Bearer ' + Auth.getToken(),
                      'Content-Type': 'application/json'
                    }
                  }
                ).then(payload => {
                  if (payload.status === 200) {
                    setAlertMsg("Inserimento dati avvenuto con successo");
                    setAlertSeverity("success");
                  } else {
                    setAlertMsg("Errore durante l'inserimento dati");
                    setAlertSeverity("error");
                  }
                  setOpen(true);
                }).catch((error) => {
                  setAlertMsg(error.response.data.error);
                  setAlertSeverity("error");
                  setOpen(true);
                });
                resolve();
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                const index = oldData.id;
                const url = API_URL_FACTORIES_PRODUCTS + "/" + index;
                axios.patch(url, {
                  id_factory: props.dataPanel.id,
                  enabled: newData.enabled,
                  id_product: newData.id_product
                },
                  {
                    headers: {
                      'Authorization': 'Bearer ' + Auth.getToken(),
                      'Content-Type': 'application/json'
                    }
                  }
                ).then(payload => {
                  if (payload.status === 200) {
                    setAlertMsg("Aggiornamento avvenuto con successo");
                    setAlertSeverity("success");
                  } else {
                    setAlertMsg("Errore durante l'aggiornamento");
                    setAlertSeverity("error");
                  }
                  setOpen(true);
                }).catch((error) => {

                  setAlertMsg(error.response.data.error);
                  setAlertSeverity("error");
                  setOpen(true);
                });
                resolve();
              }),
            onRowDelete:
              oldData =>
                new Promise((resolve, reject) => {
                  const index = oldData.id;
                  const url = API_URL_FACTORIES_PRODUCTS + "/" + index;
                  axios.delete(url,
                    {
                      headers: {
                        'Authorization': 'Bearer ' + Auth.getToken(),
                        'Content-Type': 'application/json'
                      }
                    }
                  ).then(payload => {
                    if (payload.status === 200) {
                      setAlertMsg("Cancellazione avvenuta con successo");
                      setAlertSeverity("success");
                    } else {
                      setAlertMsg("Errore durante la cancellazione");
                      setAlertSeverity("error");
                    }
                    setOpen(true);
                  }).catch((error) => {
                    setAlertMsg(error.response.data.error);
                    setAlertSeverity("error");
                    setOpen(true);
                  });
                  resolve();
                })
          }}
        />
      }
    </CardContent>
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={alertseverity} closeText="Chiudi">
        {alertmsg}
      </Alert>
    </Snackbar>
  </Card>
  );
};

