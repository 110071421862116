import React from "react";
import { useDropzone } from "react-dropzone";


const getClassName = (className, isActive) => {
  if (!isActive) return className;
  return `${className} ${className}-active`;
};

const Dropzone = ({ onDrop, accept }) => {

  
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept
  });

  return (
    <div className={getClassName("dropzone", isDragActive)} {...getRootProps()}>
      <input className="dropzone-input" {...getInputProps()} />
      <div className="text-center">
        {isDragActive ? (
          <p className="dropzone-content">Logo azienda formato JPG o PNG, max 128 Kb <br/> Rilascia i file qui</p>
        ) : (
          <p className="dropzone-content">
            Logo azienda formato JPG o PNG, max 128 Kb <br/>
            Trascina e rilascia i file qui o fai clic per selezionare il file
          </p>
        )}
      </div>
    </div>
  );
};



export default Dropzone;
