







import axios from 'axios';
import {
  API_URL_GET_PROFESSIONS
} from '../config/config';



export const getProfessions = async (token, url) => {
  try {
    const result = await axios.get(url + API_URL_GET_PROFESSIONS, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      }
    });


    return result;

  } catch (e) {
    return Promise.reject(e.response);
  }
};


export function returnElement(list, id) {
  let element_active = null;

  list.forEach((element) => {
    if (element.id === id) {
      element_active = element;
    }
  });

  return (element_active);
}

export function returnElementValue(list, id) {
  let element_active = null;

  list.forEach((element) => {
    if (element.value === id) {
      element_active = element;
    }
  });

  return (element_active);
}

export function returnImageHub(factoriesHub, facid, slug) {
  let element_active = null;

  if (facid === undefined || factoriesHub === undefined) return "";
  factoriesHub.forEach((element) => {
    if (element.id_factory === facid && element.slug === slug) {
      element_active = element;
    }
  });
  if (element_active == null) return "";
  return (element_active.logo);
}

export function returnImage(list, fac) {
  let element_active = null;

  if (fac === undefined || list === undefined) return "";
  list.forEach((element) => {
    if (element.description === fac) {
      element_active = element;
    }
  });
  if (element_active == null) return "";

  return (element_active.image);

}

export function returnDescription(list, id) {
  let element_active = null;

  if (id === undefined) return "";
  list.forEach((element) => {
    if (element.id === id) {
      element_active = element;
    }
  });
  if (element_active == null) return "";

  return (element_active.description);

}

export function returnLabel(list, id) {
  let element_active = null;
  list.forEach((element) => {
    if (element.value === id) {
      element_active = element;
    }
  });
  if (element_active == null) return "";
  return (element_active.label);

}

export function number_format(number, decimals, dec_point, thousands_sep) {

  var n = !isFinite(+number) ? 0 : +number,
    prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
    dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
    toFixedFix = function (n, prec) {
      // Fix for IE parseFloat(0.55).toFixed(0) = 0;
      var k = Math.pow(10, prec);
      return Math.round(n * k) / k;
    },
    s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString().split('.');
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || '';
    s[1] += new Array(prec - s[1].length + 1).join('0');
  }
  return s.join(dec);
}


export function CheckFeeCommissions(serviceslist) {
  let checkError = "";


  serviceslist.forEach((item) => {
    let factory = "";
    if (item.factorydescription !== undefined) {
      factory = item.factorydescription;
    }
    if (item.servicedescription !== undefined) {
      factory = item.servicedescription;
    }

    if (
      (item.commissionsMin === "" || item.commissionsMin === null) &&
      (item.commissionsMax === "" || item.commissionsMax === null) &&
      (item.commissionsDefault === "" || item.commissionsDefault === null)) {
      /*OK*/
    } else {

      if (
        (item.commissionsMin === "" || item.commissionsMin === null) ||
        (item.commissionsMax === "" || item.commissionsMax === null) ||
        (item.commissionsDefault === "" || item.commissionsDefault === null)) {
        checkError = "Commissioni " + factory + " devono essere compilati tutti i campi, oppure devono essere lasciati tutti vuoti";
      } else if (Number(item.commissionsMin) > Number(item.commissionsMax)) {
        checkError = "Commissioni " + factory + " minimo deve essere minore o uguale del massimo";

      } else if (item.commissionsDefault !== null && item.commissionsDefault !== "" && (Number(item.commissionsDefault) < Number(item.commissionsMin) || Number(item.commissionsDefault) > Number(item.commissionsMax))) {
        checkError = "Commissioni " + factory + " il default deve essere compreso tra minimo e massimo";
      }

    }
    if (
      (item.feeMin === "" || item.feeMin === null) &&
      (item.feeMax === "" || item.feeMax === null) &&
      (item.feeDefault === "" || item.feeDefault === null)) {
      /*OK*/
    } else {
      if (
        (item.feeMin === "" || item.feeMin === null) ||
        (item.feeMax === "" || item.feeMax === null) ||
        (item.feeDefault === "" || item.feeDefault === null)) {
        checkError = "Diritti " + factory + " devono essere compilati tutti i campi, oppure devono essere lasciati tutti vuoti";
      } else if (Number(item.feeMin) > Number(item.feeMax)) {
        checkError = "Diritti " + factory + " minimo deve essere minore o uguale del massimo";

      } else if (item.feeDefault !== null && item.feeDefault !== "" && (Number(item.feeDefault) < Number(item.feeMin) || Number(item.feeDefault) > Number(item.feeMax))) {
        checkError = "Diritti " + factory + " il default deve essere compreso tra minimo e massimo";
      }

    }


  }

  );

  return checkError;
}


export function parseFloat2Decimals(value) {
  return parseFloat(parseFloat(value).toFixed(2));
}
