import React from 'react';
import { Fragment } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import NumberFormat from 'react-number-format';
//import Skeleton from '@material-ui/lab/Skeleton';
//import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';
import LinearProgress from '@material-ui/core/LinearProgress';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import { API_URL_QUOTATION_LIST, API_URL_QUOTATION_PAYMENTS, API_URL_AGENCY_USERS } from '../config/config';
import axios from 'axios';
import Auth from '../auth/auth';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Slider from '@material-ui/core/Slider';
import Badge from '@material-ui/core/Badge';
import MailIcon from '@material-ui/icons/Mail';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import { returnImage, returnImageHub } from './utils';
import { UserDataContext } from '../containers/logincontext';
import { SUPER_ADMIN } from '../config/config';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import { number_format } from './utils';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 820,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({

  /*InfoIconClass:{
      marginTop:"10px"
  },*/
  titleTable: {
    fontWeight: 'bold'
  },
  root: {
    width: '100%'
  },
  infoDialog: {
    width: '100%',
    textAlign: 'center',
    verticalAlign: 'center',
    padding: "5px"
  },

  progressdiv: {
    width: '100%',
    minHeight: '300px'
  },

  boxWait: {
    width: '100%',
    //minHeight: '250px',
    textAlign: 'center',
    verticalAlign: 'center'
  },

  typoWait: {
    width: '30%',
    //minHeight: '250px',
    padding: '20px',
    textAlign: 'center',
    verticalAlign: 'center'
  },

  paper: {
    width: '100%',
    padding: theme.spacing(2),
    textAlign: 'center',

  },
  detail: {
    textAlign: 'left',
    //minHeight: '200px'
  },
  img: {
    maxWidth: '100%',
    maxHeight: '30px'
  },
  prezzo: {
    align: 'right',
    color: 'green',
    fontWeight: 'bold'
  },
  subtitle: {
    fontWeight: 'bold',
    align: 'right',
  },
  infobox: {
    marginTop: '20px'
  },
  chipbox: {
    padding: '5px'
  },
  tableBold: {
    fontWeight: "bold"
  },

  numberCellStyle: {
    align: 'right',
  },
  boxprev: {
    paddingBottom: 20
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '20%',
    flexShrink: 0,
  },
  secondaryHeading: {
    flexBasis: '40%',
    flexShrink: 0,
    align: 'right',
    fontWeight: 'bold',

    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingRight: "20px",

  },
  secondaryHeading2: {
    flexBasis: '5%',
    flexShrink: 0,
    align: 'right',
    fontWeight: 'bold'
  },
  thirdHeading: {
    flexBasis: '20%',
    flexShrink: 0,
    align: 'right',
    fontWeight: 'bold',
    color: 'green',
  },
  splitHeading: {
    flexBasis: '15%',
    flexShrink: 0,
    align: 'right',
    textAlign: 'right',
    fontSize: '12px',
    paddingLeft: '4px'
  },
  thirdHeadingKo: {
    flexBasis: '30%',
    flexShrink: 0,
    align: 'right',
  },
  typographyMessage: {
    padding: theme.spacing(2),
  },
  customBadge: {
    backgroundColor: "#f9b406",
    //backgroundColor: props => props.color,
    color: "white"
  },
  buttonClass: {
    margin: 2
  }

}));

function valuetext(value) {
  return value.toFixed(2);
  //return `${value.toFixed(2)}%`;
}

function valueTextFee(value) {
  return value.toFixed(2);
  //return `${value.toFixed(2)}%`;
}

function truncateStr(str) {
  return str.length > 60 ? str.substring(0, 60) + "..." : str;
}

function CalculateToPay(QuoteSelect, OptionPayment, onlyCommissions = false) {
  let rate = Number(QuoteSelect.TotalRate);

  if (OptionPayment.removecommissions === 1) {
    rate -= Number(QuoteSelect.Commissions) / Number(QuoteSelect.splittingUp);
  }
  if (OptionPayment.removefee === 1) {
    rate -= Number(QuoteSelect.Fees) / Number(QuoteSelect.splittingUp);
  }
  if (onlyCommissions) {
    return (OptionPayment.paymentcommissions * rate) / 100;
  }
  rate = rate + (OptionPayment.paymentcommissions * rate) / 100;
  rate = rate + OptionPayment.paymentfee;
  return rate;
}

/*
ORDERID=QUOTIAMO_48299509_3884&
SHOPID=129288001710030
&AUTHNUMBER=477468
&AMOUNT=41573
&CURRENCY=978
&TRANSACTIONID=8032112928SL1rn7gx2xm4lq5
&ACCOUNTINGMODE=I&AUTHORMODE=I
&RESULT=00&TRANSACTIONTYPE=TT09
&NETWORK=02
&MAC=38D66830C41B2346991334307E371BC23B91F5F1
*/

function checkVisible(QuoteSelect, pricedetail, isQuoteAdd, quotationAdd) {
  if (isQuoteAdd == false && quotationAdd !== undefined && quotationAdd !== null && quotationAdd != "") {
    for (const quote of quotationAdd) {
      if (pricedetail.nrQuotation === quote.nrQuotation && pricedetail.company === quote.company) {
        return false;
      }
    }
  }
  if (QuoteSelect === undefined) return true;
  if (pricedetail.nrQuotation === QuoteSelect.nrQuotation && pricedetail.company === QuoteSelect.company) {
    return false;
  }
  return true;
}

function CustomMsgSnackBar(url, expirelink, classes) {
  return <>
    <Typography className={classes.infoDialog}>
      Il link per procedere al pagamento è il seguente:
    </Typography>
    <Typography className={classes.infoDialog}>
      {url}
    </Typography>
    <Typography className={classes.infoDialog}>
      Questo link scade il {moment(expirelink).format("DD/MM/YYYY")} alle {moment(expirelink).format("HH:MM")}
    </Typography>

  </>;
  /*  setAlertMsg("Il link è il seguente: "+payload.data.url);
                    setAlertSeverity("success");                                            */
}

function CustomMsgConfimPayment(type, QuoteSelect, paymentsOptions, PaymentOptionSelect, email2Send, props, classes) {
  return <>
    <Typography className={classes.infoDialog}>
      {/* props.company.hub !== undefined && props.company.hub !== null && props.company.hub === 1 &&
        <img className={classes.img} alt={props.company.fac} src={returnImageHub(props.factoriesHub, props.company.facid, pricedetail.slug) !== "" ? returnImageHub(props.factoriesHub, props.company.facid, pricedetail.slug) : returnImage(props.factories, props.company.fac)} />
      }
      {(props.company.hub === undefined || props.company.hub === null || props.company.hub === 0) &&
        <img className={classes.img} alt={props.company.fac} src={returnImage(props.factories, props.company.fac)} />
      */}
      <img className={classes.img} alt={props.company.fac} src={returnImage(props.factories, props.company.fac)} />
    </Typography>
    {type !== 2 &&
      <Typography className={classes.infoDialog}>
        Pagamento tramite {paymentsOptions.filter(active => active.id === PaymentOptionSelect)[0].description}
      </Typography>
    }
    {type === 2 &&
      <>
        <Typography className={classes.infoDialog}>
          Non è possibile procedere con il pagamento di questo preventivo
        </Typography>
        <Typography className={classes.infoDialog}>
          Il preventivo selezionato è soggetto di verifica, prima di essere confermato.
        </Typography>
      </>
    }
    {type !== 2 &&
      <Typography className={classes.infoDialog}>
        Importo:  <NumberFormat fixedDecimalScale="true" decimalScale="2" value={CalculateToPay(QuoteSelect, paymentsOptions.filter(active => active.id === PaymentOptionSelect)[0])} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} />
      </Typography>
    }


    <Typography className={classes.infoDialog}>
      L'ordine verrà salvato nello stato:
    </Typography>
    <Typography className={classes.infoDialog}>
      {type === 1 &&
        <Chip color='primary' style={{ backgroundColor: 'yellow', color: 'black' }} label="In attesa di pagamento" />
      }
      {type === 0 &&
        <Chip color='primary' style={{ backgroundColor: 'green' }} label="Confermato" />
      }
      {type === 2 &&
        <Chip color='primary' style={{ backgroundColor: 'yellow', color: 'black' }} label="Premio da confermare" />
      }
    </Typography>
    {type === 1 && <>
      <Typography className={classes.infoDialog}>
        Il link per il pagamento verrà inviato all'email:
      </Typography>
      <Typography className={classes.infoDialog}>
        {email2Send}
      </Typography>
    </>
    }
  </>;
}

/*
setMsgConfirmSave("Vuoi procedere con l'ordine del preventivo numero "+QuoteSelect.nrQuotation+ " di " + props.company.fac+ 
      " importo € "+CalculateToPay(QuoteSelect,paymentsOptions.filter(active => active.id===PaymentOptionSelect)[0])+
      " pagamento tramite "+ paymentsOptions.filter(active => active.id===PaymentOptionSelect)[0].description +       
      "?");   

      <img className={classes.img} alt={props.company.fac} src={returnImage(props.factories,props.company.fac)} />
                                    {props.company.sfo_description!==undefined &&                                    
                                      <Box>                  
                                      {props.company.sfo_description}
                                      </Box>                                    
                                    
      

"Vuoi procedere con l'ordine del preventivo numero "+QuoteSelect.nrQuotation+ " di " + props.company.fac+ 
      " importo € "+CalculateToPay(QuoteSelect,paymentsOptions.filter(active => active.id===PaymentOptionSelect)[0])+ "?" +
      " L'ordine verrà salvato come in Attesa di Pagamento. " + 
      " Il link per il pagamento tramite "+ paymentsOptions.filter(active => active.id===PaymentOptionSelect)[0].description +       
      " verrà inviato al seguente indirizzo email:".email2Send
*/

function CheckNote(cvt, guarantees_info, classes) {
  if (guarantees_info === undefined) return <></>;

  let message = [];
  let nameCVT = "";

  for (const single of guarantees_info) {
    if (single.service_parameters_code !== null) {
      let tipoCalcolo = single.tipo_calcolo !== null ? (single.tipo_calcolo !== "default" ? single.tipo_calcolo : single.tipo_calcolo_default) : single.tipo_calcolo_default;
      if (tipoCalcolo === "comp") {
        let doneDesc = false;
        single.service_parameters_code.split(",").forEach(singlecode => {
          if (cvt.code !== null && cvt.code.includes(singlecode)) {
            nameCVT = single.description;
            if (doneDesc === false) {
              if (cvt.nameCVT !== "TCM PREMIO FISSO") {
                let maximalFactor = false;
                if (cvt.factors !== null && cvt.factors !== undefined && cvt.factors.length > 0) {
                  for (const fattore of cvt.factors) {
                    if (fattore.code === "maximal") {
                      if (isNaN(fattore.value)) {
                        message.push("Massimale: " + fattore.value);
                      } else {
                        message.push("Massimale € " + number_format(parseInt(fattore.value), 2, ",", "."));
                      }
                      maximalFactor = true;
                      break;
                    }
                  }
                }
                if (maximalFactor === false && single.massimale !== undefined && single.massimale !== "" && single.massimale !== null) {
                  message.push("Massimale € " + number_format(single.massimale, 2, ",", "."));
                }

                let franchigiaFactor = false;
                if (cvt.factors !== null && cvt.factors !== undefined && cvt.factors.length > 0) {
                  for (const fattore of cvt.factors) {
                    if (fattore.code === "deductible") {
                      if (isNaN(fattore.value)) {
                        message.push("Franchigia: " + fattore.value);
                      } else {
                        message.push("Franchigia € " + number_format(parseInt(fattore.value), 2, ",", "."));
                      }
                      franchigiaFactor = true;
                      break;
                    }
                  }
                }
                if (franchigiaFactor === false && single.franchigia !== undefined && single.franchigia !== "" && single.franchigia !== null) {
                  message.push("Franchigia € " + number_format(single.franchigia, 2, ",", "."));
                }

                if (single.limite_risarcimento_perc !== undefined && single.limite_risarcimento_perc !== "" && single.limite_risarcimento_perc !== null) {
                  message.push("Limite risarcimento " + single.limite_risarcimento_perc + " %");
                }

                if (single.limite_risarcimento_val !== undefined && single.limite_risarcimento_val !== "" && single.limite_risarcimento_val !== null) {
                  message.push("Limite risarcimento € " + number_format(single.limite_risarcimento_val, 2, ",", "."));
                }

                if (single.scoperto_perc !== undefined && single.scoperto_perc !== "" && single.scoperto_perc !== null) {
                  message.push("Scoperto " + single.scoperto_perc + " %");
                }

                if (single.scoperto_val !== undefined && single.scoperto_val !== "" && single.scoperto_val !== null) {
                  message.push("Scoperto € " + number_format(single.scoperto_val, 2, ",", "."));
                }

                if (single.note_preventivo !== undefined && single.note_preventivo !== "" && single.note_preventivo !== null) {
                  message.push(single.note_preventivo);
                }
              } else {
                let maximalFactor = false;
                if (cvt.factors !== null && cvt.factors !== undefined && cvt.factors.length > 0) {
                  for (const fattore of cvt.factors) {
                    if (fattore.code === "maximal") {
                      if (isNaN(fattore.value)) {
                        message.push("Massimale: " + fattore.value);
                      } else {
                        message.push("Massimale € " + number_format(parseInt(fattore.value), 2, ",", "."));
                      }
                      maximalFactor = true;
                      break;
                    }
                  }
                }
              }
              doneDesc = true;
            }
          }
        });
        if (nameCVT !== "") {
          break;
        }
      } else {
        nameCVT = single.categoriesdescription;
        if (cvt.nameCVT !== "TCM PREMIO FISSO") {
          let maximalFactor = false;
          if (cvt.factors !== null && cvt.factors !== undefined && cvt.factors.length > 0) {
            for (const fattore of cvt.factors) {
              if (fattore.code === "maximal") {
                if (isNaN(fattore.value)) {
                  message.push("Massimale: " + fattore.value);
                } else {
                  message.push("Massimale € " + number_format(parseInt(fattore.value), 2, ",", "."));
                }
                maximalFactor = true;
                break;
              }
            }
          }
          if (maximalFactor === false && single.massimale !== undefined && single.massimale !== "" && single.massimale !== null) {
            message.push("Massimale € " + number_format(single.massimale, 2, ",", "."));
          }

          let franchigiaFactor = false;
          if (cvt.factors !== null && cvt.factors !== undefined && cvt.factors.length > 0) {
            for (const fattore of cvt.factors) {
              if (fattore.code === "deductible") {
                if (isNaN(fattore.value)) {
                  message.push("Franchigia: " + fattore.value);
                } else {
                  message.push("Franchigia € " + number_format(parseInt(fattore.value), 2, ",", "."));
                }
                franchigiaFactor = true;
                break;
              }
            }
          }
          if (franchigiaFactor === false && single.franchigia !== undefined && single.franchigia !== "" && single.franchigia !== null) {
            message.push("Franchigia 2 € " + number_format(single.franchigia, 2, ",", "."));
          }

          if (single.limite_risarcimento_perc !== undefined && single.limite_risarcimento_perc !== "" && single.limite_risarcimento_perc !== null) {
            message.push("Limite risarcimento " + single.limite_risarcimento_perc + " %");
          }

          if (single.limite_risarcimento_val !== undefined && single.limite_risarcimento_val !== "" && single.limite_risarcimento_val !== null) {
            message.push("Limite risarcimento € " + number_format(single.limite_risarcimento_val, 2, ",", "."));
          }

          if (single.scoperto_perc !== undefined && single.scoperto_perc !== "" && single.scoperto_perc !== null) {
            message.push("Scoperto " + single.scoperto_perc + " %");
          }

          if (single.scoperto_val !== undefined && single.scoperto_val !== "" && single.scoperto_val !== null) {
            message.push("Scoperto € " + number_format(single.scoperto_val, 2, ",", "."));
          }

          if (single.note_preventivo !== undefined && single.note_preventivo !== "" && single.note_preventivo !== null) {
            message.push(single.note_preventivo);
          }
        } else {
          let maximalFactor = false;
          if (cvt.factors !== null && cvt.factors !== undefined && cvt.factors.length > 0) {
            for (const fattore of cvt.factors) {
              if (fattore.code === "maximal") {
                if (isNaN(fattore.value)) {
                  message.push("Massimale: " + fattore.value);
                } else {
                  message.push("Massimale € " + number_format(parseInt(fattore.value), 2, ",", "."));
                }
                maximalFactor = true;
                break;
              }
            }
          }
        }
        if (nameCVT !== "") {
          break;
        }
      }
    }
  };

  if (message.length === 0 && nameCVT === "") return <></>;

  if (message.length === 0) return <>{nameCVT}</>;

  return <HtmlTooltip title={
    <>
      {message.map((message2print, index) => (
        <Typography key={index} className={classes.typographyMessage} color="initial">
          {message2print}
        </Typography>
      ))}
    </>
  }>
    <Badge color="secondary">
      {nameCVT}
      <InfoIcon fontSize="small" />
    </Badge>
  </HtmlTooltip>;
}

function checkAnotherRCA(quotationAdd) {
  if (quotationAdd === false) return false;
  if (quotationAdd === undefined) return false;
  if (quotationAdd.length < 1) return false;
  for (const quote of quotationAdd) {
    if (quote.description === "RCA") {
      return true;
    }
  }
  return false;
}

function checkAnotherNoOnlyCvt(quotationAdd, factories) {
  if (quotationAdd === false) return false;
  if (quotationAdd === undefined) return false;
  if (quotationAdd.length < 1) return false;
  for (const quote of quotationAdd) {
    for (const fact of factories) {
      if (quote.company === fact.factorycode && fact.only_cvt === 0 && quote.only_cvt === 0) {
        return true;
      }
    }
  }
  return false;
}

export default function QuotationDetails(props) {
  const [user] = React.useContext(UserDataContext);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [alertseverity, setAlertSeverity] = React.useState("success");
  const [alertmsg, setAlertMsg] = React.useState(false);
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(props.notConfirmed ? "panel0" : "");
  const [quotationInfo, setQuotationInfo] = React.useState(props.quotationInfo === undefined ? "" : props.quotationInfo);
  //const [quotationAdd, setQuotationAdd] = React.useState(props.quotationAdd === undefined ? "" : props.quotationAdd);
  const [isHouse, setIsHouse] = React.useState(props.quotationInfo === undefined ? "" : props.quotationInfo.quotationtype === "house");
  const [email2Send, setEmail2Send] = React.useState(props.email === undefined ? "" : props.email);

  const [valueCom, setValueCom] = React.useState(
    props.datipre !== undefined &&
      props.datipre.prices !== undefined && props.datipre.prices[0] !== undefined ?
      (props.company.FinalCommissionsType !== null && props.company.FinalCommissionsType === "euro" ? props.datipre.prices[0].Commissions : (props.datipre.prices[0].Commissions / props.datipre.prices[0].price * 100)) :
      props.company.FinalCommissionsDefault !== undefined ? props.company.FinalCommissionsDefault : props.company.FinalCommissionsMax
  );

  const [valueFee, setValueFee] = React.useState(
    props.datipre !== undefined &&
      props.datipre.prices !== undefined && props.datipre.prices[0] !== undefined ?
      (props.company.FinalCommissionsType !== null && props.company.FinalCommissionsType === "perc" ? (props.datipre.prices[0].Fees / props.datipre.prices[0].price * 100) : props.datipre.prices[0].Fees) :
      props.company.FinalfeeDefault !== undefined ? props.company.FinalfeeDefault : props.company.FinalfeeMax
  );

  const [datipre, setDatiPre] = React.useState(props.datipre);
  const [isQuoteAdd, setIsQuoteAdd] = React.useState(props.isQuoteAdd !== undefined ? props.isQuoteAdd : false);
  const [paymentsOptions, setPaymentsOptions] = React.useState([]);
  const [openPaymentOptions, setopenPaymentOptions] = React.useState(false);
  const [PaymentOptionSelect, setPaymentOptionSelect] = React.useState(false);
  const [PaymentPayBylinkActive, setPaymentPayBylinkActive] = React.useState(false);
  const [QuoteSelect, setQuoteSelect] = React.useState(false);
  const [openConfirmSave, setopenConfirmSave] = React.useState(false);
  const [msgConfirmSave, setMsgConfirmSave] = React.useState("");
  const [titleConfirmSave, setTitleConfirmSave] = React.useState("");
  const [operationConfirmSave, setOperationConfirmSave] = React.useState(0);
  const [emissionBlock, setEmissionBlock] = React.useState(undefined);
  const [messageEmissionBlock, setMessageEmissionBlock] = React.useState(undefined);
  const [numTab, setTab] = React.useState(0);

  React.useEffect(() => {
    if (user.level < 8) {
      const url = API_URL_AGENCY_USERS + "/" + props.company.agencyid + "/" + user.id;
      axios.get(url,
        {
          headers: {
            'Authorization': 'Bearer ' + Auth.getToken(),
            'Content-Type': 'application/json'
          }
        }
      ).then(payload => {
        setEmissionBlock(payload.data.user.emission_block);
        if (messageEmissionBlock === undefined) {
          setMessageEmissionBlock(payload.data.user.emission_block_message);
        }
      });
    } else {
      setEmissionBlock(0);
    }
  }, []);

  /*React.useEffect(() => {
    setValueCom(props.datipre !== undefined &&
      props.datipre.prices !== undefined && props.datipre.prices[0] !== undefined ?
      (props.datipre.prices[0].Commissions / props.datipre.prices[0].price * 100) :
      props.company.FinalCommissionsDefault !== undefined ? props.company.FinalCommissionsDefault : props.company.FinalCommissionsMax);

    setValueFee(props.datipre !== undefined &&
      props.datipre.prices !== undefined && props.datipre.prices[0] !== undefined ?
      props.datipre.prices[0].Fees :
      props.company.FinalfeeDefault !== undefined ? props.company.FinalfeeDefault : props.company.FinalfeeMax);
  }, [props.isChange]);*/

  const handleChangeTab = (event, newValue) => {
    if (newValue === 1) {
      if (paymentsOptions.length > 0) {
        paymentsOptions.forEach(option => {
          if (option.config !== null) {
            JSON.parse(option.config).forEach((item, key) => {
              if (Object.keys(item)[0] === "paybylink") {
                if (JSON.parse(option.config)[key][Object.keys(item)[0]].value === "1") {
                  setPaymentOptionSelect(option.id);
                }
              }
            });
          }
        });
      }
    }

    setTab(newValue);
  };

  const handleCancelConfirm = () => {
    setopenConfirmSave(false);
  };

  const handleCancel = () => {
    setopenPaymentOptions(false);
  };

  const handleOk = () => {
    setOperationConfirmSave(2);
    setTitleConfirmSave("Conferma ordine preventivo " + QuoteSelect.nrQuotation);
    setMsgConfirmSave(CustomMsgConfimPayment(numTab, QuoteSelect, paymentsOptions, PaymentOptionSelect, email2Send, props, classes));
    setopenConfirmSave(true);
  }

  const handleOkSaveConfirm = () => {
    const tokenApp = Auth.getToken();
    const url = API_URL_QUOTATION_LIST + '/' + props.numberquotation;

    if (operationConfirmSave === 1) {
      axios.patch(url, {
        select: JSON.stringify(QuoteSelect),
        paymentid: null,
        payment_import: 0,
        urldone: null,
        urlback: null,
        urlms: null,
      },
        {
          headers: {
            'Authorization': 'Bearer ' + tokenApp,
            'Content-Type': 'application/json'
          }
        }
      ).then(payload => {
        if (payload.status === 200) {
          window.location.href = "/quotationsingle/" + props.numberquotation;
        }
      }).catch((error) => {
        setAlertMsg(error.response.data.error);
        setAlertSeverity("error");
        setOpenAlert(true);
      });
    } else {
      const tokenApp = Auth.getToken();
      const url = API_URL_QUOTATION_LIST + '/' + props.numberquotation;
      let urldone;
      let urlms;
      let urlback;

      if (numTab === 0) {  //Qui pagamento
        urldone = window.location.origin + '/quotationsingle/' + props.numberquotation + '/paymentdone';
        urlms = API_URL_QUOTATION_LIST + '/' + props.numberquotation + "/paymentdone";
        urlback = window.location.origin + '/quotationsingle/' + props.numberquotation + '/paymentcancel';
      } else {    //Qui pay per link
        urldone = window.location.origin + '/resultpayment/' + props.numberquotation + '/paymentdone';
        urlms = API_URL_QUOTATION_LIST + '/' + props.numberquotation + "/paymentdone";
        urlback = window.location.origin + '/resultpayment/' + props.numberquotation + '/paymentcancel';
      }

      axios.patch(url, {
        select: JSON.stringify(QuoteSelect),
        paymentid: paymentsOptions.filter(active => active.id === PaymentOptionSelect)[0].id_payment_systems,
        payment_import: CalculateToPay(QuoteSelect, paymentsOptions.filter(active => active.id === PaymentOptionSelect)[0]),
        urldone: urldone,
        urlback: urlback,
        urlms: urlms,
        email: email2Send,
        paybylink: numTab
      },
        {
          headers: {
            'Authorization': 'Bearer ' + tokenApp,
            'Content-Type': 'application/json'
          }
        }
      ).then(payload => {
        if (payload.status === 200) {
          if (payload.data.paybylink === 1) {
            //alert("LINK PAGAMENTO:"+payload.data.url);
            setAlertMsg(CustomMsgSnackBar(payload.data.url, payload.data.expirelink, classes));
            setAlertSeverity("success");
            setOpenAlert(true);
            setopenConfirmSave(false);
            setopenPaymentOptions(false);
          } else {
            if (payload.data.postparam !== "") {
              window.location.href = payload.data.url + "?" + payload.data.postparam;
            } else {
              window.location.href = payload.data.url;
            }
          }
        }
      }).catch((error) => {
        setAlertMsg(error.response.data.error);
        setAlertSeverity("error");
        setOpenAlert(true);
      });
    }
  };

  const handleChange = (event) => {
    setPaymentOptionSelect(Number(event.target.value));
  };

  const handleChangeCom = (index, commissionsType) => (event, newValue) => {
    let datiprenew = datipre;
    let conta = 0;

    datiprenew.prices.forEach(element => {
      if (conta === index) {
        element.TotalPrice -= parseFloat(element.Commissions);
        if (commissionsType !== null && commissionsType === "euro") {
          element.Commissions = parseFloat(newValue * element.splittingUp).toFixed(2);
        } else {
          element.Commissions = ((newValue * element.price) / 100).toFixed(2);
        }

        element.TotalPrice += parseFloat(element.Commissions);
        if (element.addCalcoloCvtNoFraz !== null && element.addCalcoloCvtNoFraz !== undefined && element.addCalcoloCvtNoFraz > 0) {
          element.TotalPrice -= element.addCalcoloCvtNoFraz;
          element.TotalRate = parseFloat((element.TotalPrice / element.splittingUp) + element.addCalcoloCvtNoFraz).toFixed(2);
          element.TotalPrice += element.addCalcoloCvtNoFraz;
        } else {
          element.TotalRate = parseFloat(element.TotalPrice / element.splittingUp).toFixed(2);
        }
      }
      conta++;
    });
    setDatiPre(datiprenew);
    setValueCom(newValue);
  };

  const handleChangeFee = (index, feeType) => (event, newValue) => {
    let datiprenew = datipre;
    let conta = 0;

    datiprenew.prices.forEach(element => {
      if (conta === index) {
        element.TotalPrice -= parseFloat(element.Fees);
        if (feeType !== null && feeType === "perc") {
          element.Fees = ((newValue * element.price) / 100).toFixed(2);
        } else {
          element.Fees = parseFloat(newValue * element.splittingUp).toFixed(2);
        }

        element.TotalPrice += parseFloat(element.Fees);
        if (element.addCalcoloCvtNoFraz !== null && element.addCalcoloCvtNoFraz !== undefined && element.addCalcoloCvtNoFraz > 0) {
          element.TotalPrice -= element.addCalcoloCvtNoFraz;
          element.TotalRate = parseFloat((element.TotalPrice / element.splittingUp) + element.addCalcoloCvtNoFraz).toFixed(2);
          element.TotalPrice += element.addCalcoloCvtNoFraz;
        } else {
          element.TotalRate = parseFloat(element.TotalPrice / element.splittingUp).toFixed(2);
        }
      }
      conta++;
    });
    setDatiPre(datiprenew);
    setValueFee(newValue);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const RemoveQuotationSelect = (event) => {
    const tokenApp = Auth.getToken();
    const url = API_URL_QUOTATION_LIST + '/' + props.numberquotation;

    axios.patch(url, {
      select: "",
      paymentid: null,
      payment_import: 0,
      urldone: null,
      urlback: null,
      urlms: null,
    },
      {
        headers: {
          'Authorization': 'Bearer ' + tokenApp,
          'Content-Type': 'application/json'
        }
      }
    ).then(payload => {
      if (payload.status === 200) {
        window.location.href = "/quotationsingle/" + props.numberquotation;
      }
    }).catch((error) => {
      setAlertMsg(error.response.data.error);
      setAlertSeverity("error");
      setOpenAlert(true);
    });
  }

  const handleSave = (quoteInfo, type) => (event) => {
    setTab(type);
    const tokenApp = Auth.getToken();
    const url = API_URL_QUOTATION_PAYMENTS + '/' + props.numberquotation;
    if (quoteInfo.messages !== undefined && quoteInfo.messages.length > 0) {
      setOperationConfirmSave(1);
      setTitleConfirmSave("Conferma ordine preventivo " + quoteInfo.nrQuotation);
      setMsgConfirmSave(CustomMsgConfimPayment(2, quoteInfo, paymentsOptions, PaymentOptionSelect, email2Send, props, classes));
      setQuoteSelect(quoteInfo);
      setopenConfirmSave(true);
      return;
    }

    axios.get(url,
      {
        headers: {
          'Authorization': 'Bearer ' + tokenApp,
          'Content-Type': 'application/json'
        }
      }
    ).then(payload => {
      if (payload.status === 200) {
        setPaymentsOptions(payload.data.activePayment);
        if (payload.data.activePayment.length > 0) {
          if (type === 0) {
            setPaymentOptionSelect(payload.data.activePayment[0].id);
          }
          payload.data.activePayment.forEach(option => {
            if (option.config !== null) {
              JSON.parse(option.config).forEach((item, key) => {
                if (Object.keys(item)[0] === "paybylink") {
                  if (JSON.parse(option.config)[key][Object.keys(item)[0]].value === "1") {
                    if (type === 1) {
                      setPaymentOptionSelect(option.id);
                    }
                    setPaymentPayBylinkActive(true);
                  }
                }
              });
            }
          });
        }
        setQuoteSelect(quoteInfo);
        setopenPaymentOptions(true);
      }
    }).catch((error) => {
      setAlertMsg(error.response.data.error);
      setAlertSeverity("error");
      setOpenAlert(true);
    });
  };

  const handleAdd = (quoteInfo, type) => (event) => {
    const tokenApp = Auth.getToken();
    const url = API_URL_QUOTATION_LIST + '/addquotation/' + props.numberquotation;
    axios.patch(url, {
      quotation_add: quoteInfo,
    },
      {
        headers: {
          'Authorization': 'Bearer ' + tokenApp,
          'Content-Type': 'application/json'
        }
      }
    ).then(payload => {
      if (window.location.pathname.includes("quotationsingle/")) {
        window.location.reload();
      } else {
        window.location.href = "/quotationsingle/" + props.numberquotation;
      }
    }).catch((error) => {
      setAlertMsg(error.response.data.error);
      setAlertSeverity("error");
      setOpenAlert(true);
    });
  };

  const handleRemove = (quoteInfo, type) => (event) => {
    const tokenApp = Auth.getToken();
    const url = API_URL_QUOTATION_LIST + '/removequotation/' + props.numberquotation;
    axios.patch(url, {
      quotation_add: quoteInfo,
    },
      {
        headers: {
          'Authorization': 'Bearer ' + tokenApp,
          'Content-Type': 'application/json'
        }
      }
    ).then(payload => {
      if (window.location.pathname.includes("quotationsingle/")) {
        window.location.reload();
      } else {
        window.location.href = "/quotationsingle/" + props.numberquotation;
      }
    }).catch((error) => {
      setAlertMsg(error.response.data.error);
      setAlertSeverity("error");
      setOpenAlert(true);
    });
  };

  return (
    <Fragment>
      {props.datipre !== undefined ?
        <Fragment>
          {props.datipre.error !== undefined ?
            <Accordion expanded={expanded === 'panel1'} onChange={handleChangeAccordion('panel1')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography className={classes.heading}>
                  <img className={classes.img} alt={props.company.fac} src={returnImage(props.factories, props.company.fac)} />
                  {props.company.sfo_description !== undefined &&
                    <Box>
                      {props.company.sfo_description}
                    </Box>
                  }
                </Typography>
                <Typography align="left" className={classes.secondaryHeading} variant="h4" gutterBottom>
                </Typography>
                <Typography align="right" className={classes.thirdHeadingKo} variant="h6" gutterBottom>
                  Quotazione non riuscita
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography align="left" variant="body1" gutterBottom>
                  {props.datipre.message}
                </Typography>
              </AccordionDetails>
            </Accordion>
            :
            datipre !== undefined ?
              datipre.prices.map((pricedetail, index) => (
                <>
                  {checkVisible(props.QuotationSelect, pricedetail, isQuoteAdd, props.quotationAdd) && pricedetail.nrQuotation !== "" &&
                    <Grid key={index} container spacing={3}>
                      <Grid item xs={12}>
                        <Accordion /*expanded={expanded === ('panel' + index)}*/ onChange={handleChangeAccordion('panel' + index)}>
                          <AccordionSummary
                            style={{ backgroundColor: props.select === true ? '#ddf0de' : '#ffffff' }}
                            expandIcon={<ExpandMoreIcon />}
                          >
                            <Typography className={classes.heading}>
                              {props.company.hub !== undefined && props.company.hub !== null && props.company.hub === 1 &&
                                <img className={classes.img} alt={props.company.fac} src={returnImageHub(props.factoriesHub, props.company.facid, pricedetail.slug) !== "" ? returnImageHub(props.factoriesHub, props.company.facid, pricedetail.slug) : returnImage(props.factories, props.company.fac)} />
                              }
                              {(props.company.hub === undefined || props.company.hub === null || props.company.hub === 0) &&
                                <img className={classes.img} alt={props.company.fac} src={returnImage(props.factories, props.company.fac)} />
                              }
                              {props.company.sfo_description !== undefined && (pricedetail.description === undefined || pricedetail.description === "") &&
                                <Box>
                                  {props.company.sfo_description}
                                </Box>
                              }
                              {pricedetail.description !== undefined && pricedetail.description !== "" &&
                                <Box>
                                  {pricedetail.description}
                                </Box>
                              }
                            </Typography>
                            <Fragment>
                              <Typography className={classes.secondaryHeading} variant="subtitle1" gutterBottom>
                                {pricedetail.messages !== undefined && pricedetail.messages.length > 0 &&
                                  <Fragment>
                                    {truncateStr(pricedetail.messages.map(message2print => message2print.message).join(' - '))}
                                  </Fragment>
                                }
                              </Typography>
                              <Typography className={classes.secondaryHeading2} variant="subtitle1" gutterBottom>
                                {pricedetail.messages !== undefined && pricedetail.messages.length > 0 &&
                                  <Fragment>
                                    <HtmlTooltip title={
                                      <Fragment>
                                        {pricedetail.messages.map((message2print, index) => (
                                          <Fragment key={index}>
                                            <Typography className={classes.typographyMessage} color="initial">
                                              ({index + 1}) {message2print.message}
                                            </Typography>
                                          </Fragment>
                                        ))}
                                      </Fragment>
                                    }>
                                      <Badge color="secondary" badgeContent={pricedetail.messages.length} showZero>
                                        <MailIcon />
                                      </Badge>
                                    </HtmlTooltip>
                                  </Fragment>
                                }
                              </Typography>
                              {pricedetail.nrQuotation !== "" &&
                                <Fragment>
                                  <Typography align="right" className={classes.thirdHeading} variant="h5" gutterBottom>
                                    <NumberFormat fixedDecimalScale="true" decimalScale="2" value={pricedetail.TotalPrice} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} />
                                  </Typography>
                                  {pricedetail.splittingUp !== undefined && !isNaN(pricedetail.splittingUp) && Number(pricedetail.splittingUp) > 1 &&
                                    <Fragment>
                                      <Typography className={classes.splitHeading} variant="subtitle1" gutterBottom>
                                        <Box>
                                          Numero rate {pricedetail.splittingUp}
                                        </Box>
                                        <Box>
                                          Importo prima rata <NumberFormat fixedDecimalScale="true" decimalScale="2" value={parseFloat(pricedetail.TotalRate)} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} />
                                        </Box>
                                      </Typography>
                                    </Fragment>
                                  }
                                </Fragment>
                              }
                              {pricedetail.nrQuotation === "" && pricedetail.messages !== undefined &&
                                <Fragment>
                                  {pricedetail.messages.map((message2print, index) => (
                                    <Fragment key={index}>
                                      {message2print.code === "02" &&
                                        <Typography align="right" className={classes.thirdHeadingKo} variant="subtitle2" gutterBottom>
                                          {message2print.message}
                                        </Typography>
                                      }
                                    </Fragment>))
                                  }
                                </Fragment>
                              }
                            </Fragment>
                          </AccordionSummary>
                          <AccordionDetails>

                            {pricedetail.nrQuotation !== "" &&
                              <TableContainer>
                                <Table>
                                  <TableBody>
                                    {pricedetail.nrQuotation !== "" &&
                                      <TableRow>
                                        <TableCell>
                                          <Typography className={classes.subtitle} variant="subtitle1" gutterBottom>
                                            Numero preventivo
                                          </Typography>
                                        </TableCell>
                                        <TableCell></TableCell>
                                        <TableCell align='right'>
                                          <Chip className={classes.chipbox}
                                            style={{ backgroundColor: 'purple', color: 'white' }}
                                            label={pricedetail.nrQuotation}
                                          />
                                        </TableCell>
                                      </TableRow>
                                    }
                                    {pricedetail.convenzioneAttiva !== null && pricedetail.convenzioneAttiva.code !== undefined && pricedetail.convenzioneAttiva.code !== "" &&
                                      <TableRow>
                                        <TableCell>Convenzione</TableCell>
                                        <TableCell>
                                        </TableCell>
                                        <TableCell align='right'><Chip className={classes.chipbox}
                                          style={{ backgroundColor: 'green', color: 'white' }}
                                          label={pricedetail.convenzioneAttiva.description}
                                        /></TableCell>
                                      </TableRow>
                                    }
                                    {user.level === SUPER_ADMIN && (pricedetail.convenzioneAttiva !== null && pricedetail.convenzioneAttiva.code === undefined || pricedetail.convenzioneAttiva.code === "") && props.company.discount_description !== null && props.company.discount_description !== undefined && props.company.discount_description !== "" &&
                                      <TableRow>
                                        <TableCell>Convenzione</TableCell>
                                        <TableCell>

                                        </TableCell>
                                        <TableCell align='right'><Chip className={classes.chipbox}
                                          style={{ backgroundColor: 'green', color: 'white' }}
                                          label={props.company.discount_description}
                                        /></TableCell>
                                      </TableRow>
                                    }
                                    <TableRow>
                                      <TableCell className={classes.titleTable}>Garanzia fabbrica</TableCell>
                                      <TableCell className={classes.titleTable}>Garanzia QUOTIAMO</TableCell>
                                      <TableCell className={classes.titleTable} align='right'>Premio</TableCell>
                                    </TableRow>
                                    {pricedetail.listResponseCVT.map((cvt, index) => (
                                      <TableRow key={index}>
                                        {cvt.selected === false &&
                                          <TableCell style={{ color: '#bbbbbb' }} >{cvt.nameCVT}</TableCell>
                                        }

                                        {cvt.selected === true &&
                                          <TableCell>
                                            {cvt.nameCVT}
                                          </TableCell>
                                        }
                                        <TableCell>
                                          {CheckNote(cvt, props.company.guarantees_info, classes)}
                                        </TableCell>
                                        <TableCell align='right'>
                                          {user.level === SUPER_ADMIN && cvt.selected && cvt.price > 0 && cvt.originalprice !== undefined && cvt.price !== cvt.originalprice &&
                                            <div style={{ textDecoration: "line-through" }}>
                                              <NumberFormat fixedDecimalScale="true" decimalScale="2" value={cvt.originalprice} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} />
                                            </div>
                                          }
                                          {cvt.selected && (cvt.price > 0 || cvt.price < 0) &&
                                            <NumberFormat fixedDecimalScale="true" decimalScale="2" value={cvt.price} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} />
                                          }
                                          {cvt.selected === false &&
                                            <Chip className={classes.chipbox}
                                              style={{ backgroundColor: '#eeeeee', color: 'black' }}
                                              label={"Non disponibile"}
                                            />
                                          }
                                          {cvt.selected === true && cvt.price === 0 &&
                                            <Chip className={classes.chipbox}
                                              style={{ backgroundColor: '#eeeeee', color: 'black' }}
                                              label={"Compresa"}
                                            />
                                          }
                                        </TableCell>

                                      </TableRow>
                                    ))}

                                    {pricedetail.ListService.map((service, index) => (
                                      <TableRow key={index}>
                                        <TableCell>{service.description}</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell align='right'><NumberFormat fixedDecimalScale="true" decimalScale="2" value={parseFloat(service.priceservice)} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} /></TableCell>
                                      </TableRow>
                                    ))}

                                    {(pricedetail.Commissions > 0 || (props.saveActive && props.company.FinalCommissionsMax > 0)) &&
                                      <TableRow>
                                        <TableCell>Commissioni {props.company.FinalCommissionsType !== null && props.company.FinalCommissionsType === "euro" ? "€" : "%"}
                                        </TableCell>
                                        <TableCell>
                                          {props.saveActive && props.company.FinalCommissionsMin !== props.company.FinalCommissionsMax &&
                                            <Slider value={valueCom} onChange={handleChangeCom(index, props.company.FinalCommissionsType)}
                                              getAriaValueText={valuetext}
                                              valueLabelFormat={valuetext}
                                              min={props.company.FinalCommissionsMin}
                                              max={props.company.FinalCommissionsMax}
                                              step={(props.company.FinalCommissionsMax - props.company.FinalCommissionsMin) / 100}
                                              aria-labelledby="discrete-slider-custom"
                                              valueLabelDisplay="on"
                                            />
                                          }
                                        </TableCell>

                                        <TableCell align='right'><NumberFormat fixedDecimalScale="true" decimalScale="2" value={parseFloat(pricedetail.Commissions)} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} /></TableCell>
                                      </TableRow>
                                    }
                                    {(pricedetail.Fees > 0 || (props.saveActive && props.company.FinalfeeMax > 0)) &&
                                      <TableRow>
                                        <TableCell>Diritti {props.company.FinalfeeType !== null && props.company.FinalfeeType === "perc" ? "%" : "€"}</TableCell>
                                        <TableCell>
                                          {props.saveActive && props.company.FinalfeeMin !== props.company.FinalfeeMax &&
                                            <Slider value={valueFee} onChange={handleChangeFee(index, props.company.FinalfeeType)}
                                              getAriaValueText={valueTextFee}
                                              valueLabelFormat={valueTextFee}
                                              min={props.company.FinalfeeMin}
                                              max={props.company.FinalfeeMax}
                                              step={(props.company.FinalfeeMin - props.company.FinalfeeMax) / 100}
                                              aria-labelledby="discrete-slider-custom"
                                              valueLabelDisplay="on" />
                                          }
                                        </TableCell>
                                        <TableCell align='right'><NumberFormat fixedDecimalScale="true" decimalScale="2" value={parseFloat(pricedetail.Fees)} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} /></TableCell>
                                      </TableRow>
                                    }
                                    <TableRow>
                                      <TableCell className={classes.tableBold}>Totale preventivo</TableCell>
                                      <TableCell></TableCell>
                                      <TableCell className={classes.tableBold} align='right'><NumberFormat fixedDecimalScale="true" decimalScale="2" value={pricedetail.TotalPrice} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} /></TableCell>
                                    </TableRow>
                                    {pricedetail.splittingUp !== undefined && !isNaN(pricedetail.splittingUp) && Number(pricedetail.splittingUp) > 1 &&
                                      <Fragment>
                                        <TableRow>
                                          <TableCell className={classes.tableBold}>Numero rate</TableCell>
                                          <TableCell></TableCell>
                                          <TableCell className={classes.tableBold} align='right'>{pricedetail.splittingUp}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell className={classes.tableBold}>Importo prima rata</TableCell>
                                          <TableCell></TableCell>
                                          <TableCell className={classes.tableBold} align='right'><NumberFormat fixedDecimalScale="true" decimalScale="2" value={parseFloat(pricedetail.TotalRate)} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} /></TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell className={classes.tableBold}>Importo rate restanti</TableCell>
                                          <TableCell></TableCell>
                                          <TableCell className={classes.tableBold} align='right'><NumberFormat fixedDecimalScale="true" decimalScale="2" value={(pricedetail.TotalPrice - parseFloat(pricedetail.TotalRate)) / (Number(pricedetail.splittingUp) - 1)} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} /></TableCell>
                                        </TableRow>
                                      </Fragment>
                                    }

                                    {isQuoteAdd === false &&
                                      <>
                                        {props.notConfirmed &&
                                          <TableRow>
                                            <TableCell className={classes.tableBold}>
                                              <Button variant="outlined" onClick={RemoveQuotationSelect} size="small">Cambia quotazione scelta</Button>
                                            </TableCell>
                                            <TableCell></TableCell>
                                            <TableCell className={classes.tableBold} align='right'>
                                              {PaymentPayBylinkActive &&
                                                <Button className={classes.buttonClass} variant="outlined" onClick={handleSave(pricedetail, 1)} size="small">Crea link per pagamento</Button>
                                              }
                                              <Button className={classes.buttonClass} variant="outlined" onClick={handleSave(pricedetail, 0)} size="small">Riprova pagamento</Button>
                                            </TableCell>
                                          </TableRow>}

                                        {props.saveActive && emissionBlock !== undefined &&
                                          <TableRow>
                                            <TableCell className={classes.tableBold}><Button variant="outlined" onClick={props.onSave(pricedetail)} size="small">Salva</Button></TableCell>
                                            {emissionBlock === 0 &&
                                              <>
                                                <TableCell></TableCell>
                                                {(props.company.only_cvt === undefined || pricedetail.only_cvt === undefined) &&
                                                  <>
                                                    {(isHouse || (quotationInfo.vehicleInfo.vehicleType !== "C" && quotationInfo.vehicleInfo.vehicleType !== "R")) /* && props.company.only_cvt === undefined */ &&
                                                      <TableCell className={classes.tableBold} align='right'>
                                                        {PaymentPayBylinkActive &&
                                                          <Button className={classes.buttonClass} variant="outlined" onClick={handleSave(pricedetail, 1)} size="small">Crea link per pagamento</Button>
                                                        }
                                                        <Button className={classes.buttonClass} variant="outlined" onClick={handleSave(pricedetail, 0)} size="small">Salva per ordine</Button>
                                                      </TableCell>
                                                    }
                                                    {!isHouse && (quotationInfo.vehicleInfo.vehicleType === "C" || quotationInfo.vehicleInfo.vehicleType === "R") /* && props.company.only_cvt === undefined */ &&
                                                      <TableCell className={classes.tableBold} align='right'>
                                                        {pricedetail.description === "RCA" && checkAnotherRCA(props.quotationAdd, isQuoteAdd) === true &&
                                                          <Button className={classes.buttonClass} variant="outlined" onClick={handleAdd(pricedetail, 0)} size="small" disabled>Aggiungi all'ordine</Button>
                                                        }
                                                        {pricedetail.description === "RCA" && checkAnotherRCA(props.quotationAdd, isQuoteAdd) === false &&
                                                          <Button className={classes.buttonClass} variant="outlined" onClick={handleAdd(pricedetail, 0)} size="small" >Aggiungi all'ordine</Button>
                                                        }
                                                        {pricedetail.description !== "RCA" &&
                                                          <Button className={classes.buttonClass} variant="outlined" onClick={handleAdd(pricedetail, 0)} size="small">Aggiungi all'ordine</Button>
                                                        }
                                                      </TableCell>
                                                    }
                                                  </>
                                                }
                                                {props.company.only_cvt !== undefined && pricedetail.only_cvt !== undefined &&
                                                  <>
                                                    { /*props.orgid !== undefined && props.orgid !== 3 && */}
                                                    <TableCell className={classes.tableBold} align='right'>
                                                      {props.company.only_cvt === 1 &&
                                                        <Button className={classes.buttonClass} variant="outlined" onClick={handleAdd(pricedetail, 0)} size="small">Aggiungi all'ordine</Button>
                                                      }
                                                      {props.company.only_cvt === 0 && checkAnotherNoOnlyCvt(props.quotationAdd, props.factories) === true &&
                                                        <Button className={classes.buttonClass} variant="outlined" onClick={handleAdd(pricedetail, 0)} size="small" disabled>Aggiungi all'ordine</Button>
                                                      }
                                                      {props.company.only_cvt === 0 && checkAnotherNoOnlyCvt(props.quotationAdd, props.factories) === false &&
                                                        <Button className={classes.buttonClass} variant="outlined" onClick={handleAdd(pricedetail, 0)} size="small">Aggiungi all'ordine</Button>
                                                      }
                                                    </TableCell>

                                                  </>
                                                }
                                              </>
                                            }
                                            {emissionBlock === 1 &&
                                              <>
                                                <TableCell className={classes.titleTable}>
                                                  {messageEmissionBlock !== null && messageEmissionBlock !== undefined &&
                                                    <>{messageEmissionBlock}</>
                                                  }
                                                </TableCell>
                                                {(props.company.only_cvt === undefined || pricedetail.only_cvt === undefined) &&
                                                  <>
                                                    {(isHouse || (quotationInfo.vehicleInfo.vehicleType !== "C" && quotationInfo.vehicleInfo.vehicleType !== "R")) &&
                                                      <TableCell className={classes.tableBold} align='right'>
                                                        {PaymentPayBylinkActive &&
                                                          <Button className={classes.buttonClass} variant="outlined" onClick={handleSave(pricedetail, 1)} size="small">Crea link per pagamento</Button>
                                                        }
                                                        <Button className={classes.buttonClass} variant="outlined" onClick={handleSave(pricedetail, 0)} size="small">Salva per ordine</Button>
                                                      </TableCell>
                                                    }
                                                    {!isHouse && (quotationInfo.vehicleInfo.vehicleType === "C" || quotationInfo.vehicleInfo.vehicleType === "R") &&
                                                      <TableCell className={classes.tableBold} align='right'>
                                                        {pricedetail.description === "RCA" && checkAnotherRCA(props.quotationAdd, isQuoteAdd) === true &&
                                                          <Button className={classes.buttonClass} variant="outlined" onClick={handleAdd(pricedetail, 0)} size="small" disabled>Aggiungi all'ordine</Button>
                                                        }
                                                        {pricedetail.description === "RCA" && checkAnotherRCA(props.quotationAdd, isQuoteAdd) === false &&
                                                          <Button className={classes.buttonClass} variant="outlined" onClick={handleAdd(pricedetail, 0)} size="small" >Aggiungi all'ordine</Button>
                                                        }
                                                        {pricedetail.description !== "RCA" &&
                                                          <Button className={classes.buttonClass} variant="outlined" onClick={handleAdd(pricedetail, 0)} size="small">Aggiungi all'ordine</Button>
                                                        }
                                                      </TableCell>
                                                    }
                                                  </>
                                                }
                                                {props.company.only_cvt !== undefined && pricedetail.only_cvt !== undefined &&
                                                  <TableCell className={classes.tableBold} align='right'>
                                                    {props.company.only_cvt === 1 &&
                                                      <Button className={classes.buttonClass} variant="outlined" onClick={handleAdd(pricedetail, 0)} size="small">Aggiungi all'ordine</Button>
                                                    }
                                                    {props.company.only_cvt === 0 && checkAnotherNoOnlyCvt(props.quotationAdd, props.factories) === true &&
                                                      <Button className={classes.buttonClass} variant="outlined" onClick={handleAdd(pricedetail, 0)} size="small" disabled>Aggiungi all'ordine</Button>
                                                    }
                                                    {props.company.only_cvt === 0 && checkAnotherNoOnlyCvt(props.quotationAdd, props.factories) === false &&
                                                      <Button className={classes.buttonClass} variant="outlined" onClick={handleAdd(pricedetail, 0)} size="small">Aggiungi all'ordine</Button>
                                                    }
                                                  </TableCell>
                                                }
                                              </>
                                            }
                                          </TableRow>
                                        }
                                      </>
                                    }

                                    {isQuoteAdd === true && props.select === false &&
                                      <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell className={classes.tableBold} align='right'>
                                          <Button className={classes.buttonClass} variant="outlined" onClick={handleRemove(pricedetail, 0)} size="small">Rimuovi dall'ordine</Button>
                                        </TableCell>
                                      </TableRow>
                                    }
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            }

                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    </Grid>
                  }
                </>
              )) :
              props.datipre.prices.map((pricedetail, index) => (
                //datipre.prices.map((pricedetail, index) => (
                <>
                  {checkVisible(props.QuotationSelect, pricedetail, isQuoteAdd, props.quotationAdd) && pricedetail.nrQuotation !== "" &&
                    <Grid key={index} container spacing={3}>
                      <Grid item xs={12}>
                        <Accordion /*expanded={expanded === ('panel' + index)}*/ onChange={handleChangeAccordion('panel' + index)}>
                          <AccordionSummary
                            style={{ backgroundColor: props.select === true ? '#ddf0de' : '#ffffff' }}
                            expandIcon={<ExpandMoreIcon />}
                          >
                            <Typography className={classes.heading}>
                              {props.company.hub !== undefined && props.company.hub !== null && props.company.hub === 1 &&
                                <img className={classes.img} alt={props.company.fac} src={returnImageHub(props.factoriesHub, props.company.facid, pricedetail.slug) !== "" ? returnImageHub(props.factoriesHub, props.company.facid, pricedetail.slug) : returnImage(props.factories, props.company.fac)} />
                              }
                              {(props.company.hub === undefined || props.company.hub === null || props.company.hub === 0) &&
                                <img className={classes.img} alt={props.company.fac} src={returnImage(props.factories, props.company.fac)} />
                              }
                              {props.company.sfo_description !== undefined && (pricedetail.description === undefined || pricedetail.description === "") &&
                                <Box>
                                  {props.company.sfo_description}
                                </Box>
                              }
                              {pricedetail.description !== undefined && pricedetail.description !== "" &&
                                <Box>
                                  {pricedetail.description}
                                </Box>
                              }
                            </Typography>
                            <Fragment>
                              <Typography className={classes.secondaryHeading} variant="subtitle1" gutterBottom>
                                {pricedetail.messages !== undefined && pricedetail.messages.length > 0 &&
                                  <Fragment>
                                    {truncateStr(pricedetail.messages.map(message2print => message2print.message).join(' - '))}
                                  </Fragment>
                                }
                              </Typography>
                              <Typography className={classes.secondaryHeading2} variant="subtitle1" gutterBottom>
                                {pricedetail.messages !== undefined && pricedetail.messages.length > 0 &&
                                  <Fragment>
                                    <HtmlTooltip title={
                                      <Fragment>
                                        {pricedetail.messages.map((message2print, index) => (
                                          <Fragment key={index}>
                                            <Typography className={classes.typographyMessage} color="initial">
                                              ({index + 1}) {message2print.message}
                                            </Typography>
                                          </Fragment>
                                        ))}
                                      </Fragment>
                                    }>
                                      <Badge color="secondary" badgeContent={pricedetail.messages.length} showZero>
                                        <MailIcon />
                                      </Badge>
                                    </HtmlTooltip>
                                  </Fragment>
                                }
                              </Typography>
                              {pricedetail.nrQuotation !== "" &&
                                <Fragment>
                                  <Typography align="right" className={classes.thirdHeading} variant="h5" gutterBottom>
                                    <NumberFormat fixedDecimalScale="true" decimalScale="2" value={pricedetail.TotalPrice} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} />
                                  </Typography>
                                  {pricedetail.splittingUp !== undefined && !isNaN(pricedetail.splittingUp) && Number(pricedetail.splittingUp) > 1 &&
                                    <Fragment>
                                      <Typography className={classes.splitHeading} variant="subtitle1" gutterBottom>
                                        <Box>
                                          Numero rate {pricedetail.splittingUp}
                                        </Box>
                                        <Box>
                                          Importo prima rata <NumberFormat fixedDecimalScale="true" decimalScale="2" value={parseFloat(pricedetail.TotalRate)} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} />
                                        </Box>
                                      </Typography>
                                    </Fragment>
                                  }
                                </Fragment>
                              }
                              {pricedetail.nrQuotation === "" && pricedetail.messages !== undefined &&
                                <Fragment>
                                  {pricedetail.messages.map((message2print, index) => (
                                    <Fragment key={index}>
                                      {message2print.code === "02" &&
                                        <Typography align="right" className={classes.thirdHeadingKo} variant="subtitle2" gutterBottom>
                                          {message2print.message}
                                        </Typography>
                                      }
                                    </Fragment>))
                                  }
                                </Fragment>
                              }
                            </Fragment>
                          </AccordionSummary>
                          <AccordionDetails>

                            {pricedetail.nrQuotation !== "" &&
                              <TableContainer>
                                <Table>
                                  <TableBody>
                                    {pricedetail.nrQuotation !== "" &&
                                      <TableRow>
                                        <TableCell>
                                          <Typography className={classes.subtitle} variant="subtitle1" gutterBottom>
                                            Numero preventivo
                                          </Typography>
                                        </TableCell>
                                        <TableCell></TableCell>
                                        <TableCell align='right'>
                                          <Chip className={classes.chipbox}
                                            style={{ backgroundColor: 'purple', color: 'white' }}
                                            label={pricedetail.nrQuotation}
                                          />
                                        </TableCell>
                                      </TableRow>
                                    }
                                    {pricedetail.convenzioneAttiva !== null && pricedetail.convenzioneAttiva.code !== undefined && pricedetail.convenzioneAttiva.code !== "" &&
                                      <TableRow>
                                        <TableCell>Convenzione</TableCell>
                                        <TableCell>
                                        </TableCell>
                                        <TableCell align='right'><Chip className={classes.chipbox}
                                          style={{ backgroundColor: 'green', color: 'white' }}
                                          label={pricedetail.convenzioneAttiva.description}
                                        /></TableCell>
                                      </TableRow>
                                    }
                                    {user.level === SUPER_ADMIN && (pricedetail.convenzioneAttiva !== null && pricedetail.convenzioneAttiva.code === undefined || pricedetail.convenzioneAttiva.code === "") && props.company.discount_description !== null && props.company.discount_description !== undefined && props.company.discount_description !== "" &&
                                      <TableRow>
                                        <TableCell>Convenzione</TableCell>
                                        <TableCell>

                                        </TableCell>
                                        <TableCell align='right'><Chip className={classes.chipbox}
                                          style={{ backgroundColor: 'green', color: 'white' }}
                                          label={props.company.discount_description}
                                        /></TableCell>
                                      </TableRow>
                                    }
                                    <TableRow>
                                      <TableCell className={classes.titleTable}>Garanzia fabbrica</TableCell>
                                      <TableCell className={classes.titleTable}>Garanzia QUOTIAMO</TableCell>
                                      <TableCell className={classes.titleTable} align='right'>Premio</TableCell>
                                    </TableRow>
                                    {pricedetail.listResponseCVT.map((cvt, index) => (
                                      <TableRow key={index}>
                                        {cvt.selected === false &&
                                          <TableCell style={{ color: '#bbbbbb' }} >{cvt.nameCVT}</TableCell>
                                        }

                                        {cvt.selected === true &&
                                          <TableCell>
                                            {cvt.nameCVT}
                                          </TableCell>
                                        }
                                        <TableCell>
                                          {CheckNote(cvt, props.company.guarantees_info, classes)}
                                        </TableCell>
                                        <TableCell align='right'>
                                          {user.level === SUPER_ADMIN && cvt.selected && cvt.price > 0 && cvt.originalprice !== undefined && cvt.price !== cvt.originalprice &&
                                            <div style={{ textDecoration: "line-through" }}>
                                              <NumberFormat fixedDecimalScale="true" decimalScale="2" value={cvt.originalprice} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} />
                                            </div>
                                          }
                                          {cvt.selected && (cvt.price > 0 || cvt.price < 0) &&
                                            <NumberFormat fixedDecimalScale="true" decimalScale="2" value={cvt.price} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} />
                                          }
                                          {cvt.selected === false &&
                                            <Chip className={classes.chipbox}
                                              style={{ backgroundColor: '#eeeeee', color: 'black' }}
                                              label={"Non disponibile"}
                                            />
                                          }
                                          {cvt.selected === true && cvt.price === 0 &&
                                            <Chip className={classes.chipbox}
                                              style={{ backgroundColor: '#eeeeee', color: 'black' }}
                                              label={"Compresa"}
                                            />
                                          }
                                        </TableCell>

                                      </TableRow>
                                    ))}

                                    {pricedetail.ListService.map((service, index) => (
                                      <TableRow key={index}>
                                        <TableCell>{service.description}</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell align='right'><NumberFormat fixedDecimalScale="true" decimalScale="2" value={parseFloat(service.priceservice)} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} /></TableCell>
                                      </TableRow>
                                    ))}

                                    {(pricedetail.Commissions > 0 || (props.saveActive && props.company.FinalCommissionsMax > 0)) &&
                                      <TableRow>
                                        <TableCell>Commissioni {props.company.FinalCommissionsType !== null && props.company.FinalCommissionsType === "euro" ? "€" : "%"}
                                        </TableCell>
                                        <TableCell>
                                          {props.saveActive && props.company.FinalCommissionsMin !== props.company.FinalCommissionsMax &&
                                            <Slider value={valueCom} onChange={handleChangeCom(index, props.company.FinalCommissionsType)}
                                              getAriaValueText={valuetext}
                                              valueLabelFormat={valuetext}
                                              min={props.company.FinalCommissionsMin}
                                              max={props.company.FinalCommissionsMax}
                                              step={(props.company.FinalCommissionsMax - props.company.FinalCommissionsMin) / 100}
                                              aria-labelledby="discrete-slider-custom"
                                              valueLabelDisplay="on"
                                            />
                                          }
                                        </TableCell>

                                        <TableCell align='right'><NumberFormat fixedDecimalScale="true" decimalScale="2" value={parseFloat(pricedetail.Commissions)} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} /></TableCell>
                                      </TableRow>
                                    }
                                    {(pricedetail.Fees > 0 || (props.saveActive && props.company.FinalfeeMax > 0)) &&
                                      <TableRow>
                                        <TableCell>Diritti  {props.company.FinalfeeType !== null && props.company.FinalfeeType === "perc" ? "%" : "€"}</TableCell>
                                        <TableCell>
                                          {props.saveActive && props.company.FinalfeeMin !== props.company.FinalfeeMax &&
                                            <Slider value={valueFee} onChange={handleChangeFee(index, props.company.FinalfeeType)}
                                              getAriaValueText={valueTextFee}
                                              valueLabelFormat={valueTextFee}
                                              min={props.company.FinalfeeMin}
                                              max={props.company.FinalfeeMax}
                                              step={(props.company.FinalfeeMin - props.company.FinalfeeMax) / 100}
                                              aria-labelledby="discrete-slider-custom"
                                              valueLabelDisplay="on" />
                                          }
                                        </TableCell>
                                        <TableCell align='right'><NumberFormat fixedDecimalScale="true" decimalScale="2" value={parseFloat(pricedetail.Fees)} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} /></TableCell>
                                      </TableRow>
                                    }
                                    <TableRow>
                                      <TableCell className={classes.tableBold}>Totale preventivo</TableCell>
                                      <TableCell></TableCell>
                                      <TableCell className={classes.tableBold} align='right'><NumberFormat fixedDecimalScale="true" decimalScale="2" value={pricedetail.TotalPrice} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} /></TableCell>
                                    </TableRow>
                                    {pricedetail.splittingUp !== undefined && !isNaN(pricedetail.splittingUp) && Number(pricedetail.splittingUp) > 1 &&
                                      <Fragment>
                                        <TableRow>
                                          <TableCell className={classes.tableBold}>Numero rate</TableCell>
                                          <TableCell></TableCell>
                                          <TableCell className={classes.tableBold} align='right'>{pricedetail.splittingUp}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell className={classes.tableBold}>Importo prima rata</TableCell>
                                          <TableCell></TableCell>
                                          <TableCell className={classes.tableBold} align='right'><NumberFormat fixedDecimalScale="true" decimalScale="2" value={parseFloat(pricedetail.TotalRate)} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} /></TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell className={classes.tableBold}>Importo rate restanti</TableCell>
                                          <TableCell></TableCell>
                                          <TableCell className={classes.tableBold} align='right'><NumberFormat fixedDecimalScale="true" decimalScale="2" value={(pricedetail.TotalPrice - parseFloat(pricedetail.TotalRate)) / (Number(pricedetail.splittingUp) - 1)} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} /></TableCell>
                                        </TableRow>
                                      </Fragment>
                                    }

                                    {isQuoteAdd === false &&
                                      <>
                                        {props.notConfirmed &&
                                          <TableRow>
                                            <TableCell className={classes.tableBold}>
                                              <Button variant="outlined" onClick={RemoveQuotationSelect} size="small">Cambia quotazione scelta</Button>
                                            </TableCell>
                                            <TableCell></TableCell>
                                            <TableCell className={classes.tableBold} align='right'>
                                              {PaymentPayBylinkActive &&
                                                <Button className={classes.buttonClass} variant="outlined" onClick={handleSave(pricedetail, 1)} size="small">Crea link per pagamento</Button>
                                              }
                                              <Button className={classes.buttonClass} variant="outlined" onClick={handleSave(pricedetail, 0)} size="small">Riprova pagamento</Button>
                                            </TableCell>
                                          </TableRow>}

                                        {props.saveActive && emissionBlock !== undefined &&
                                          <TableRow>
                                            <TableCell className={classes.tableBold}><Button variant="outlined" onClick={props.onSave(pricedetail)} size="small">Salva</Button></TableCell>
                                            {emissionBlock === 0 &&
                                              <>
                                                <TableCell></TableCell>
                                                {(props.company.only_cvt === undefined || pricedetail.only_cvt === undefined) &&
                                                  <>
                                                    {(isHouse || (quotationInfo.vehicleInfo.vehicleType !== "C" && quotationInfo.vehicleInfo.vehicleType !== "R")) /* && props.company.only_cvt === undefined */ &&
                                                      <TableCell className={classes.tableBold} align='right'>
                                                        {PaymentPayBylinkActive &&
                                                          <Button className={classes.buttonClass} variant="outlined" onClick={handleSave(pricedetail, 1)} size="small">Crea link per pagamento</Button>
                                                        }
                                                        <Button className={classes.buttonClass} variant="outlined" onClick={handleSave(pricedetail, 0)} size="small">Salva per ordine</Button>
                                                      </TableCell>
                                                    }
                                                    {!isHouse && (quotationInfo.vehicleInfo.vehicleType === "C" || quotationInfo.vehicleInfo.vehicleType === "R") /* && props.company.only_cvt === undefined */ &&
                                                      <TableCell className={classes.tableBold} align='right'>
                                                        {pricedetail.description === "RCA" && checkAnotherRCA(props.quotationAdd, isQuoteAdd) === true &&
                                                          <Button className={classes.buttonClass} variant="outlined" onClick={handleAdd(pricedetail, 0)} size="small" disabled>Aggiungi all'ordine</Button>
                                                        }
                                                        {pricedetail.description === "RCA" && checkAnotherRCA(props.quotationAdd, isQuoteAdd) === false &&
                                                          <Button className={classes.buttonClass} variant="outlined" onClick={handleAdd(pricedetail, 0)} size="small" >Aggiungi all'ordine</Button>
                                                        }
                                                        {pricedetail.description !== "RCA" &&
                                                          <Button className={classes.buttonClass} variant="outlined" onClick={handleAdd(pricedetail, 0)} size="small">Aggiungi all'ordine</Button>
                                                        }
                                                      </TableCell>
                                                    }
                                                  </>
                                                }
                                                {props.company.only_cvt !== undefined && pricedetail.only_cvt !== undefined &&
                                                  <>
                                                    { /*props.orgid !== undefined && props.orgid !== 3 && */}
                                                    <TableCell className={classes.tableBold} align='right'>
                                                      {props.company.only_cvt === 1 &&
                                                        <Button className={classes.buttonClass} variant="outlined" onClick={handleAdd(pricedetail, 0)} size="small">Aggiungi all'ordine</Button>
                                                      }
                                                      {props.company.only_cvt === 0 && checkAnotherNoOnlyCvt(props.quotationAdd, props.factories) === true &&
                                                        <Button className={classes.buttonClass} variant="outlined" onClick={handleAdd(pricedetail, 0)} size="small" disabled>Aggiungi all'ordine</Button>
                                                      }
                                                      {props.company.only_cvt === 0 && checkAnotherNoOnlyCvt(props.quotationAdd, props.factories) === false &&
                                                        <Button className={classes.buttonClass} variant="outlined" onClick={handleAdd(pricedetail, 0)} size="small">Aggiungi all'ordine</Button>
                                                      }
                                                    </TableCell>

                                                  </>
                                                }
                                              </>
                                            }
                                            {emissionBlock === 1 &&
                                              <>
                                                <TableCell className={classes.titleTable}>
                                                  {messageEmissionBlock !== null && messageEmissionBlock !== undefined &&
                                                    <>{messageEmissionBlock}</>
                                                  }
                                                </TableCell>
                                                {(props.company.only_cvt === undefined || pricedetail.only_cvt === undefined) &&
                                                  <>
                                                    {(isHouse || (quotationInfo.vehicleInfo.vehicleType !== "C" && quotationInfo.vehicleInfo.vehicleType !== "R")) &&
                                                      <TableCell className={classes.tableBold} align='right'>
                                                        {PaymentPayBylinkActive &&
                                                          <Button className={classes.buttonClass} variant="outlined" onClick={handleSave(pricedetail, 1)} size="small">Crea link per pagamento</Button>
                                                        }
                                                        <Button className={classes.buttonClass} variant="outlined" onClick={handleSave(pricedetail, 0)} size="small">Salva per ordine</Button>
                                                      </TableCell>
                                                    }
                                                    {!isHouse && (quotationInfo.vehicleInfo.vehicleType === "C" || quotationInfo.vehicleInfo.vehicleType === "R") &&
                                                      <TableCell className={classes.tableBold} align='right'>
                                                        {pricedetail.description === "RCA" && checkAnotherRCA(props.quotationAdd, isQuoteAdd) === true &&
                                                          <Button className={classes.buttonClass} variant="outlined" onClick={handleAdd(pricedetail, 0)} size="small" disabled>Aggiungi all'ordine</Button>
                                                        }
                                                        {pricedetail.description === "RCA" && checkAnotherRCA(props.quotationAdd, isQuoteAdd) === false &&
                                                          <Button className={classes.buttonClass} variant="outlined" onClick={handleAdd(pricedetail, 0)} size="small" >Aggiungi all'ordine</Button>
                                                        }
                                                        {pricedetail.description !== "RCA" &&
                                                          <Button className={classes.buttonClass} variant="outlined" onClick={handleAdd(pricedetail, 0)} size="small">Aggiungi all'ordine</Button>
                                                        }
                                                      </TableCell>
                                                    }
                                                  </>
                                                }
                                                {props.company.only_cvt !== undefined && pricedetail.only_cvt !== undefined &&
                                                  <TableCell className={classes.tableBold} align='right'>
                                                    {props.company.only_cvt === 1 &&
                                                      <Button className={classes.buttonClass} variant="outlined" onClick={handleAdd(pricedetail, 0)} size="small">Aggiungi all'ordine</Button>
                                                    }
                                                    {props.company.only_cvt === 0 && checkAnotherNoOnlyCvt(props.quotationAdd, props.factories) === true &&
                                                      <Button className={classes.buttonClass} variant="outlined" onClick={handleAdd(pricedetail, 0)} size="small" disabled>Aggiungi all'ordine</Button>
                                                    }
                                                    {props.company.only_cvt === 0 && checkAnotherNoOnlyCvt(props.quotationAdd, props.factories) === false &&
                                                      <Button className={classes.buttonClass} variant="outlined" onClick={handleAdd(pricedetail, 0)} size="small">Aggiungi all'ordine</Button>
                                                    }
                                                  </TableCell>
                                                }
                                              </>
                                            }
                                          </TableRow>
                                        }
                                      </>
                                    }

                                    {isQuoteAdd === true && props.select === false &&
                                      <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell className={classes.tableBold} align='right'>
                                          <Button className={classes.buttonClass} variant="outlined" onClick={handleRemove(pricedetail, 0)} size="small">Rimuovi dall'ordine</Button>
                                        </TableCell>
                                      </TableRow>
                                    }
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            }

                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    </Grid>
                  }
                </>
              ))
          }
        </Fragment>
        :
        <Grid container spacing={3}>

          <Box className={classes.boxWait} >
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              <Typography className={classes.typoWait} component="div" variant="body1">
                <Box>
                  <img className={classes.img} alt={props.company.fac} src={returnImage(props.factories, props.company.fac)} />
                </Box>
                {props.company.sfo_description !== undefined &&
                  <Box>
                    {props.company.sfo_description}
                  </Box>
                }
                <Box>
                  <LinearProgress />
                </Box>
              </Typography>
            </div>
          </Box>
        </Grid>
      }
      <Snackbar open={openAlert} autoHideDuration={6000}
        bodyStyle={{ height: 'auto', lineHeight: '28px', padding: 24, whiteSpace: 'pre-line' }}
        onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity={alertseverity}>
          {alertmsg}
        </Alert>
      </Snackbar>

      <Dialog
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={openConfirmSave}
      >
        <DialogTitle id="confirmation-dialog-title">{titleConfirmSave}</DialogTitle>
        <DialogContent dividers>
          {msgConfirmSave}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancelConfirm} color="primary">
            Annulla
          </Button>
          <Button onClick={handleOkSaveConfirm} color="primary">
            Conferma
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        aria-labelledby="confirmation-dialog-title"
        open={openPaymentOptions}
      >
        <DialogTitle id="confirmation-dialog-title">
          <AppBar position="static">
            <Tabs value={numTab} onChange={handleChangeTab} centered>
              <Tab label="Paga con" {...a11yProps(0)} />
              <Tab label="Crea link pagamento" disabled={!PaymentPayBylinkActive} {...a11yProps(1)} />
            </Tabs>
          </AppBar>
        </DialogTitle>
        <DialogContent dividers>
          {numTab === 0 &&
            <>
              <RadioGroup
                aria-label="PaymentOptionSelect"
                name="PaymentOptionSelect"
                value={PaymentOptionSelect}
                onChange={handleChange}
              >
                {paymentsOptions.map((option) => (
                  <FormControlLabel value={option.id} key={"TAB1_" + option.id} control={<Radio />} label={option.description} />
                ))}
              </RadioGroup>
            </>
          }

          {numTab === 1 &&
            <>
              <RadioGroup
                aria-label="PaymentOptionSelect"
                name="PaymentOptionSelect"
                value={PaymentOptionSelect}
                onChange={handleChange}
              >
                {paymentsOptions.map((option) => (
                  <>
                    {option.config !== "" && option.config !== null &&
                      JSON.parse(option.config).map((item, key) => (
                        <>
                          {Object.keys(item)[0] === "paybylink" &&
                            <>
                              {JSON.parse(option.config)[key][Object.keys(item)[0]].value === "1" &&
                                <>
                                  <FormControlLabel value={option.id} key={"TAB2_" + option.id} control={<Radio />} label={option.description} />
                                </>
                              }
                            </>
                          }
                        </>
                      ))
                    }
                  </>
                ))}
              </RadioGroup>
            </>
          }

          {paymentsOptions.filter(active => active.id === PaymentOptionSelect).length > 0 &&
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1" gutterBottom>
                        Quotazione
                      </Typography>
                    </TableCell>
                    <TableCell align='right'>
                      <NumberFormat fixedDecimalScale="true" decimalScale="2" value={Number(QuoteSelect.TotalRate)} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} />
                    </TableCell>
                  </TableRow>
                  {paymentsOptions.filter(active => active.id === PaymentOptionSelect)[0].removecommissions === 1 &&
                    <TableRow>
                      <TableCell>
                        <Typography variant="subtitle1" gutterBottom>
                          Commissioni
                        </Typography>
                      </TableCell>
                      <TableCell align='right'>
                        <NumberFormat fixedDecimalScale="true" decimalScale="2" value={-Number(QuoteSelect.Commissions) / Number(QuoteSelect.splittingUp)} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} />
                      </TableCell>
                    </TableRow>
                  }
                  {paymentsOptions.filter(active => active.id === PaymentOptionSelect)[0].removefee === 1 &&
                    <TableRow>
                      <TableCell>
                        <Typography variant="subtitle1" gutterBottom>
                          Diritti
                        </Typography>
                      </TableCell>
                      <TableCell align='right'>
                        <NumberFormat fixedDecimalScale="true" decimalScale="2" value={-Number(QuoteSelect.Fees) / Number(QuoteSelect.splittingUp)} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} />
                      </TableCell>
                    </TableRow>
                  }
                  {paymentsOptions.filter(active => active.id === PaymentOptionSelect)[0].paymentcommissions > 0 &&
                    <TableRow>
                      <TableCell>
                        <Typography variant="subtitle1" gutterBottom>
                          Commissioni su incasso ({paymentsOptions.filter(active => active.id === PaymentOptionSelect)[0].paymentcommissions}%)
                        </Typography>
                      </TableCell>
                      <TableCell align='right'>
                        <NumberFormat fixedDecimalScale="true" decimalScale="2" value={CalculateToPay(QuoteSelect, paymentsOptions.filter(active => active.id === PaymentOptionSelect)[0], true)} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} />
                      </TableCell>
                    </TableRow>
                  }
                  {paymentsOptions.filter(active => active.id === PaymentOptionSelect)[0].paymentfee > 0 &&
                    <TableRow>
                      <TableCell>
                        <Typography variant="subtitle1" gutterBottom>
                          Diritti su incasso
                        </Typography>
                      </TableCell>
                      <TableCell align='right'>
                        <NumberFormat fixedDecimalScale="true" decimalScale="2" value={paymentsOptions.filter(active => active.id === PaymentOptionSelect)[0].paymentfee} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} />
                      </TableCell>
                    </TableRow>
                  }
                  <TableRow>
                    <TableCell>
                      <Typography className={classes.subtitle} variant="subtitle1" gutterBottom>
                        Totale
                      </Typography>
                    </TableCell>
                    <TableCell align='right'>
                      <Typography className={classes.subtitle} variant="subtitle1" gutterBottom>
                        <NumberFormat fixedDecimalScale="true" decimalScale="2" value={CalculateToPay(QuoteSelect, paymentsOptions.filter(active => active.id === PaymentOptionSelect)[0])} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} />
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          }
          <TabPanel value={numTab} index={0}>
          </TabPanel>
          <TabPanel value={numTab} index={1}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Indirizzo email per invio link pagamento"
              name="email"
              autoComplete="email"
              autoFocus
              value={email2Send}
              onChange={(e) => setEmail2Send(e.target.value)}
            />
          </TabPanel>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel} color="primary">
            Annulla
          </Button>
          <Button onClick={handleOk} color="primary">
            Conferma
          </Button>
        </DialogActions>
      </Dialog>


    </Fragment>
  );
}

//

/*


{pricedetail.nrQuotation===""  &&
                                                    <Fragment>                                                          
                                                        <Typography align="left" className={classes.secondaryHeading} variant="h4" gutterBottom>                                            
                                                        <Chip className={classes.chipbox}                                         
                                                        style={{backgroundColor:'red',color:'white'}}
                                                        label={pricedetail.message}                                    
                                                        />
                                                        </Typography>
                                                        <Typography className={classes.secondaryHeading} variant="subtitle1" gutterBottom>
                                                            {pricedetail.description}
                                                        </Typography>                                                      
                                                    </Fragment>}
                                        
*/