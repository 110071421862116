import React from 'react';
import Auth from '../auth/auth';
import { useHistory } from "react-router-dom";
import {UserDataContext} from '../containers/logincontext';



export default function Logout(props){

    const [,setUser] = React.useContext(UserDataContext);
    const history = useHistory(); 
     
     React.useEffect(()=>{              
      setUser(null);      
      Auth.logout().then(()=>{                    
        history.push('/login');
      });             
    },[history,setUser]);
  return null;
}