import React from 'react';
import {makeStyles } from '@material-ui/core/styles';
import {id_cliente,ReturnInfoClient} from '../containers/logincontext';
import Backdrop from '@material-ui/core/Backdrop';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
      }
  }));

export default function Background(props) {
    const classes = useStyles();

    return (                    
        <Backdrop className={classes.backdrop} open={props.open}>
            <Typography component="div" variant="body1">
            <Box>
            <img src={ReturnInfoClient(id_cliente,"logo")} alt={ReturnInfoClient(id_cliente,"nome_cliente")}  className="siteLogo"/>             
            </Box>
            <Box>
            <LinearProgress />
            </Box>
            </Typography>                                                  
        </Backdrop>                                
      );
};