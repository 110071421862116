import React from 'react';
import {makeStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { green, red } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import CheckboxTree from 'react-checkbox-tree'; 
import './react-checkbox-tree.css';
import {API_URL_USERS_PERMISSIONS} from '../config/config';
import axios   from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare  } from '@fortawesome/free-solid-svg-icons';
import { faSquare  } from '@fortawesome/free-solid-svg-icons';
import { faChevronRight  } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown  } from '@fortawesome/free-solid-svg-icons';
import { faPlusSquare  } from '@fortawesome/free-solid-svg-icons';
import { faMinusSquare  } from '@fortawesome/free-solid-svg-icons';
import { faFolder  } from '@fortawesome/free-solid-svg-icons';
import { faFolderOpen  } from '@fortawesome/free-solid-svg-icons';
import { faFile  } from '@fortawesome/free-solid-svg-icons';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import UserDetailPermissions from './userdetailpermissions';
import Auth from '../auth/auth';
    
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}



const useStyles = makeStyles((theme) => ({
    root:{
      width:"100%"
    },
    alignFab: {
        marginLeft: 'auto',
      },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),      
    },
    img: {    
        display: 'block',
        maxWidth: '100%',
        maxHeight: '40px',
    },

    img_service: {    
        display: 'block',
        maxWidth: '40px',
        maxHeight: '20px',
    },
    red: {
        color: red[500],        
    },
    green: {
        color: green[500],        
    },
    hiddenDiv:{
        display:'none'
    }
}));


  

/*function getNodeIds(nodes) {
  let ids = [];

  if(nodes!==undefined){
    nodes.forEach(({ value, children }) => {
      ids = [...ids, value, ...getNodeIds(children)];
    });
  }
  

  return ids;
} */ 

  



export default function UserDetailPanel(props) {
    const classes = useStyles();    
    const [datatree,setDataTree]=React.useState([]);
    const [ checked, setChecked ] = React.useState([]);
    const [ expanded, setExpanded ] = React.useState([]);

    const [open, setOpen] = React.useState(false);

    
  
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };
  
                
    React.useEffect(() => {
      const url=API_URL_USERS_PERMISSIONS+ "/" + props.datauser.id ;
          axios.get(url,
          {
              headers:{
              'Authorization': 'Bearer '+Auth.getToken(),
              'Content-Type': 'application/json'
              }
          }
      ).then(payload=>{           
        //console.log("PERMISSION");
        //console.log(payload.data);
        
        setDataTree(payload.data.detail);   
        setChecked(payload.data.checked);        
        //setExpanded(getNodeIds(payload.data.detail));
      }) ;        
              
    },[props]);      

    const handleSave = () => {  
      new Promise((resolve, reject) => {
          const myObjStr = JSON.stringify(checked);
          const url=API_URL_USERS_PERMISSIONS + "/" + props.datauser.id;                                    
          axios.patch(url,{
              list:myObjStr,
              
            },
            {
                headers:{
                  'Authorization': 'Bearer '+Auth.getToken(),
                    'Content-Type': 'application/json'
                }
            }
          ).then(payload=>{
              setOpen(true);              
          }) ;                                                                        
          resolve();
          
        })
  }


    return (<Box className={classes.root}>
      <Card >
        <CardContent>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            Permessi
            
            </Typography>                                              
            
            
              <CheckboxTree
                  nodes={datatree}
                  checked={checked}
                  expanded={expanded}
                  onCheck={checked => setChecked(checked)}
                  onExpand={expanded => setExpanded(expanded)}
                  showCheckbox={true}
                  noCascade={true}
                  showExpandAll={true}
                  
                  icons={{
                    check: <FontAwesomeIcon className="rct-icon rct-icon-check" icon={faCheckSquare }  />,
                    uncheck: <FontAwesomeIcon className="rct-icon rct-icon-uncheck" icon={faSquare} />,
                    halfCheck: <FontAwesomeIcon className="rct-icon rct-icon-half-check" icon={faCheckSquare} />,
                    expandClose: <FontAwesomeIcon className="rct-icon rct-icon-expand-close" icon={faChevronRight} />,
                    expandOpen: <FontAwesomeIcon className="rct-icon rct-icon-expand-open" icon={faChevronDown} />,
                    expandAll: <FontAwesomeIcon className="rct-icon rct-icon-expand-all" icon={faPlusSquare} />,
                    collapseAll: <FontAwesomeIcon className="rct-icon rct-icon-collapse-all" icon={faMinusSquare} />,
                    parentClose: <FontAwesomeIcon className="rct-icon rct-icon-parent-close" icon={faFolder} />,
                    parentOpen: <FontAwesomeIcon className="rct-icon rct-icon-parent-open" icon={faFolderOpen} />,
                    leaf: <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faFile} />
                }}
                />          


            
        </CardContent>
        
        <CardActions>          
            <Button onClick={handleSave} startIcon={<SaveIcon/>} size="small">Salva</Button>        
        </CardActions>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Permessi salvati
        </Alert>
      </Snackbar>
      </Card> 
          <UserDetailPermissions datauser={props.datauser} checked={checked} detail={datatree}/>
      </Box>      
    );
};

