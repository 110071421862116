import React from 'react';
import {makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { green, red } from '@material-ui/core/colors';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import UserAgencyDetailPanel from './useragencydetailpanel';




const useStyles = makeStyles((theme) => ({
    alignFab: {
        marginLeft: 'auto',
      },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),      
    },
    img: {    
        display: 'block',
        maxWidth: '100%',
        maxHeight: '40px',
    },

    img_service: {    
        display: 'block',
        maxWidth: '40px',
        maxHeight: '20px',
    },
    red: {
        color: red[500],        
    },
    green: {
        color: green[500],        
    },
    hiddenDiv:{
        display:'none'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
        fontWeight: 'bold'
      },
      secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
      },
}));


  



  



export default function UserDetailPermissions(props) {
    const classes = useStyles();    
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
    
    
    let listelement=[];
    
    props.detail.forEach(({ value,label, children }) => {
        let org=label;
        if(children!==undefined){
            children.forEach(({ value,label, children }) => {
                let grp=label;
                if(children!==undefined){
                    children.forEach(({ value,label, children }) => {

                        props.checked.forEach( element=>{
                            
                            if(element===value){
                                listelement = [...listelement,{value,label,org,grp} ];
                            }
                            
                            
                        });
                        

                    });
                }
            });
        }                    
    });
    
    
                
    /*React.useEffect(() => {
      
              
    },[props,token]);      
  */
    


    return (<Card >
        <CardContent>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            Abilitazione fabbriche filiale
          </Typography>              
          {listelement.map((agency)=>
          <Accordion expanded={expanded === agency.value} onChange={handleChange(agency.value)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
        >            
            <Typography className={classes.heading}>{agency.label}</Typography>
            <Typography className={classes.secondaryHeading}>{agency.org} - {agency.grp}</Typography>
          </AccordionSummary>
          <AccordionDetails>              
                <UserAgencyDetailPanel user={props.datauser.id} agency={agency.value} />
          </AccordionDetails>
        </Accordion>
                    )}
            
        </CardContent>              
      </Card> 
            
    );
};

