import React from 'react'
import QrReader from 'react-qr-scanner';
import Container from '@material-ui/core/Container';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Card from '@material-ui/core/Card';
import Link from '@material-ui/core/Link';
import {makeStyles } from '@material-ui/core/styles';
import {NAME_APP} from '../config/config';
import { useHistory } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Auth from '../auth/auth';


const useStyles = makeStyles((theme) => ({
    alignFab: {
        marginLeft: 'auto',
      },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),      
    },
    img: {            
        maxWidth: 'auto',
        maxHeight: '40px',
        justifyContent: 'center'
    },
    dialog:{
        minWidth:500
    }
}));



export default function QRCodeReader() {
    const classes = useStyles();
    let history = useHistory();

    const handleScan = event => {

        
        if(event!==null){
            history.push(event.text)
            
        }
        
    };

    Auth.refresh();
    

    
    
    return (
        <main className={classes.content}>            
            
            <div className={classes.appBarSpacer} />            
            <Container maxWidth="lg" className={classes.container}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" href="/" >
                        {NAME_APP}
                    </Link>                    
                    
                    <Typography color="textPrimary">Ricerca preventivo con QRCODE</Typography>    
                    
                </Breadcrumbs>

                <Card>
                <QrReader
                    delay={500}                    
                    onScan={handleScan}
                    />
                </Card>          
          </Container>           
        </main>  
      );

}