import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';
import { UserDataContext } from '../containers/logincontext';
import Container from '@material-ui/core/Container';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import moment from 'moment';
import Auth from '../auth/auth';
import Link from '@material-ui/core/Link';
import { NAME_APP, API_URL_ALLPRODUCTS, API_URL_USERS, SUPER_ADMIN, ADMIN } from '../config/config';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { KeyboardDatePicker } from "@material-ui/pickers";
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import BackGround from './background';

const useStyles = makeStyles((theme) => ({
    BoxContainer: {
        padding: "10px"
    },
    fulldiv: {
        width: "-webkit-fill-available"
    },
    FirstDataRow: {
        marginTop: "20px",
        marginBottom: "0px",
    },
    TitleCategory: {
        marginTop: "20px",
        fontSize: "16px",
        fontWeight: "600"
    },
    DetailWarranty: {
        marginTop: "5px",
        fontSize: "12px",
        fontWeight: "400"
    },
    SubTitle: {
        marginTop: "20px",
        fontSize: "16px",
        fontWeight: "600"
    },
    button: {
        height: 30
    },
    Carousel: {
        width: "100%"
    },
    Media: {
        height: 800
    },
    Media2: {
        height: 900
    },
    Carousel2: {
        width: "50%"
    },
    TitleCategory: {
        marginTop: "10px",
        fontSize: "16px",
        fontWeight: "600"
    },
    PaddingTop: {
        paddingTop: 30,
    },
    cellroot: {
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    prezzo: {
        align: 'right'
    },
    titleTable: {
        flex: '1 1 100%',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    linear_class: {
        width: '20%',
        '& > * + *': {
            marginTop: theme.spacing(2),
            zIndex: theme.zIndex.drawer + 2,
        },
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    root: {
        width: '90%'
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        alignItems: 'center',
        flexDirection: 'column',
    },
    preventivo: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 800,
    },
    image: {
        width: 180,
        height: 60,
    },
    imgLogo: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '50px',
        marginBottom: '40px'
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    fixedHeight: {
        height: 240,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    pos: {
        marginBottom: 12,
    },
    containerButton: {
        alignItems: 'right',
        textAlign: 'right',
        marginBottom: '10px',
        marginTop: '10px'
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
        alignItems: 'center'
    },
    formControl: {
        width: '100%'
    },
    progress: {
        display: "none"
    },
    iconRoot: {
        color: red
    },
    linearProgressDiv: {
        width: '90%'
    }
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const drawerWidth = 240;

export default function QuotationDataWareHouse() {
    const classes = useStyles();
    const [user] = React.useContext(UserDataContext);
    const [prod, setProd] = React.useState("");
    const [dateDal, setDateDal] = React.useState(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'));
    const [dateAl, setDateAl] = React.useState(moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'));
    const [products, setProducts] = React.useState([]);
    const [org, setOrg] = React.useState(0);
    const [grp, setGrp] = React.useState(0);
    const [ag, setAg] = React.useState(0);
    const [organizations, setOrganizations] = React.useState([]);
    const [groups, setGroups] = React.useState([]);
    const [agiencies, setAgiencies] = React.useState([]);
    const [alertmsg, setAlertMsg] = React.useState(false);
    const [alertseverity, setAlertSeverity] = React.useState("success");
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        const url = API_URL_ALLPRODUCTS + "/is/enabled";
        axios.get(url,
            {
                headers: {
                    'Authorization': 'Bearer ' + Auth.getToken(),
                    'Content-Type': 'application/json'
                }
            }
        ).then(payload => {
            setProducts(payload.data.products);
        });
    }, []);

    React.useEffect(() => {
        const url = user.level >= SUPER_ADMIN ? API_URL_USERS + "/agencies/enabled/1" : API_URL_USERS + "/agencies/enabled/0";
        axios.get(url,
            {
                headers: {
                    'Authorization': 'Bearer ' + Auth.getToken(),
                    'Content-Type': 'application/json'
                }
            }
        ).then(payload => {
            setOrganizations(payload.data.listOrg);
            setGroups(payload.data.listGrp);
            setAgiencies(payload.data.listAg);
        });
    }, []);

    const handleChangeProd = (event) => {
        setProd(event.target.value);
    }

    const handleChangeDateDal = event => {
        setDateDal(moment(event).format("YYYY-MM-DD"));
    };

    const handleChangeDateAl = event => {
        setDateAl(moment(event).format("YYYY-MM-DD"));
    };

    const handleChangeOrg = event => {
        setOrg(event.target.value);
        setGrp(0);
        setAg(0);
    };

    const handleChangeGrp = event => {
        setGrp(event.target.value);
        setAg(0);
    };

    const handleChangeAg = event => {
        setAg(event.target.value);
    };

    const handleGetReport = async () => {
        if (prod === "") {
            setAlertMsg("Selezionare un prodotto");
            setAlertSeverity("error");
            setOpen(true);
            return;
        }
        if (org === "") {
            setAlertMsg("Selezionare almeno l'organizzazione, gruppo e punto vendita facoltativi");
            setAlertSeverity("error");
            setOpen(true);
            return;
        }
        if (moment(dateAl).isBefore(moment(dateDal)) === true) {
            setAlertMsg("La data \"AL\" non può essere inferiore alla data \"DAL\"");
            setAlertSeverity("error");
            setOpen(true);
            return;
        }
        const url = API_URL_USERS + "/getreportprev";
        setLoading(true);
        try {
            const a = document.createElement("a");
            a.style.display = "none";
            document.body.appendChild(a);

            const response = await axios.post(
                url,
                {
                    "id_prod": prod,
                    "data_dal": dateDal,
                    "data_al": dateAl,
                    "id_org": org,
                    "id_grp": grp,
                    "id_ag": ag,
                },
                {
                    headers: {
                        'Authorization': 'Bearer ' + Auth.getToken(),
                        'Content-Type': 'application/json'
                    }
                }
            );

            setLoading(false);
            if (response.data.message !== undefined) {
                setAlertMsg(response.data.message);
                setAlertSeverity("error");
                setOpen(true);
                return;
            }
            const blobFile = new Blob(["\uFEFF" + response.data], { type: 'text/csv; charset=utf-8' });
            const urlDownload = window.URL.createObjectURL(blobFile);
            a.href = urlDownload;
            a.download = 'export_preventivi_' + products.find((product) => product.id === prod).description.toLowerCase().trim() + '_' + moment().format('YYYYMMDDhhmmss') + '.csv';
            a.click();
            window.URL.revokeObjectURL(urlDownload);

            setAlertMsg("Report generato, attendi il download");
            setAlertSeverity("success");
            setOpen(true);
            return;
        } catch (e) {
            setLoading(false);
            setAlertMsg("Errore nella generazione del report, riprovare");
            setAlertSeverity("error");
            setOpen(true);
            return;
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <main className={classes.content}>
            <BackGround open={loading} />
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" href="/" >
                        {NAME_APP}
                    </Link>
                    <Typography color="textPrimary">Estrazione report preventivi</Typography>
                </Breadcrumbs>
                <Grid className={classes.containerButton} item md={4} />
                <Card>
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={4}>
                                <Box pb={2}>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel id="labelProd">Prodotto *</InputLabel>
                                        <Select
                                            labelId="labelProd"
                                            id="prod"
                                            value={prod}
                                            onChange={handleChangeProd}
                                            label="Prodotto"
                                            required
                                        >
                                            {products.length > 0 && products.map(option => (
                                                <MenuItem
                                                    key={"p_" + option.id}
                                                    value={option.id}
                                                >
                                                    {option.description}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <KeyboardDatePicker
                                    label="Dal"
                                    value={dateDal}
                                    onChange={handleChangeDateDal}
                                    format='dd/MM/yyyy'
                                    clearLabel="Conferma"
                                    cancelLabel="Annulla"
                                    name="dateDal"
                                    fullWidth
                                    invalidDateMessage="Data non valida"
                                    inputVariant="outlined"
                                    required
                                    maxDate={moment()}
                                    maxDateMessage='Non è possibile selezionare una data superiore alla data "AL"'
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <KeyboardDatePicker
                                    label="Al"
                                    value={dateAl}
                                    onChange={handleChangeDateAl}
                                    format='dd/MM/yyyy'
                                    clearLabel="Conferma"
                                    cancelLabel="Annulla"
                                    name="dateDal"
                                    fullWidth
                                    invalidDateMessage="Data non valida"
                                    inputVariant="outlined"
                                    required
                                    maxDate={moment()}
                                    maxDateMessage='Non è possibile selezionare una data superiore ad oggi'
                                    minDate={dateDal}
                                    minDateMessage='Non è possibile selezionare una data inferiore alla data "DAL"'
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={4}>
                                <Box pb={2}>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel id="labelOrg">Organizzazione *</InputLabel>
                                        <Select
                                            labelId="labelOrg"
                                            id="org"
                                            value={org}
                                            onChange={handleChangeOrg}
                                            label="Organizzazione"
                                            required
                                        >
                                            <MenuItem
                                                key={"a_0"}
                                                value={0}
                                            >
                                                Tutte le organizzazioni
                                            </MenuItem>
                                            {organizations.length > 0 && organizations.map(option => (
                                                <MenuItem
                                                    key={"o_" + option.id_organization}
                                                    value={option.id_organization}
                                                >
                                                    {option.description}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box pb={2}>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel id="labelGrp">Gruppo</InputLabel>
                                        <Select
                                            labelId="labelGrp"
                                            id="grp"
                                            value={grp}
                                            onChange={handleChangeGrp}
                                            label="Gruppo"
                                            disabled={org === 0 ? true : false}
                                        >
                                            <MenuItem
                                                key={"g_0"}
                                                value={0}
                                            >
                                                Tutti i gruppi
                                            </MenuItem>
                                            {groups.length > 0 && groups.map((option) => {
                                                if (option.id_organizations === org) {
                                                    return (
                                                        <MenuItem
                                                            key={"g_" + option.id_group}
                                                            value={option.id_group}
                                                        >
                                                            {option.description}
                                                        </MenuItem>
                                                    );
                                                }
                                            })}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box pb={2}>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel id="labelAg">Punto vendita</InputLabel>
                                        <Select
                                            labelId="labelAg"
                                            id="ag"
                                            value={ag}
                                            onChange={handleChangeAg}
                                            label="Punto vendita"
                                            disabled={grp === 0 ? true : false}
                                        >
                                            <MenuItem
                                                key={"a_0"}
                                                value={0}
                                            >
                                                Tutti i punti vendita
                                            </MenuItem>
                                            {agiencies.length > 0 && agiencies.map((option) => {
                                                if (option.id_organizations === org && option.id_group === grp) {
                                                    return (
                                                        <MenuItem
                                                            key={"a_" + option.id_agency}
                                                            value={option.id_agency}
                                                        >
                                                            {option.description}
                                                        </MenuItem>
                                                    );
                                                }
                                            })}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={4}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleGetReport}
                                    className={classes.button}
                                    disabled={prod === "" || org === ""}
                                >
                                    CREA REPORT
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                    <Alert onClose={handleClose} severity={alertseverity}>
                        {alertmsg}
                    </Alert>
                </Snackbar>
            </Container>
        </main>
    );
}