import React from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import BackGround from './background';
import Link from '@material-ui/core/Link';
import { useParams } from "react-router-dom";
import { NAME_APP, API_URL_QUOTATION_LIST } from '../config/config';
import Typography from '@material-ui/core/Typography';
import { green, red } from '@material-ui/core/colors';
import QuotationSingleDetail from './quotationsingledetail';
import QuotationSingleDetailFacile from './QuotationSingleDetailFacile';
import Auth from '../auth/auth';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
    },
    Carousel: {
        width: "100%",
        paddingTop: "20px",
        margin: "auto"
    },
    Media: {
        height: 800
    },
    divButtonPDF: {
        width: '100%',
        textAlign: 'right',
        paddingBottom: '10px'
    },
    alignFab: {
        marginLeft: 'auto',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    img: {
        maxWidth: 'auto',
        maxHeight: '40px',
        justifyContent: 'center'
    },

    imgCompany: {
        maxWidth: 'auto',
        maxHeight: '30px',
        justifyContent: 'center'
    },
    cellTitle: {
        fontWeight: "bold"
    },
    cellTitleRight: {
        fontWeight: "bold",
        textAlign: "right"
    },
    cellPrice: {
        textAlign: "right"
    },
    cellSubtotalPrice: {

        fontWeight: "bold",
        textAlign: "right",
    },
    cellSubtotalTitle: {
        fontWeight: "bold",
    },
    cellFinalPrice: {
        fontWeight: "bold",
        textAlign: "right",
        fontSize: 18
    },
    cellFinalTitle: {

        fontWeight: "bold",
        fontSize: 18
    },
    tablePadding: {
        paddingTop: "100px"
    },
    titlePadding: {
        paddingTop: "10px",
        paddingBottom: "10px"

    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    divContainer: {
        width: "100%"
    },
    titlePadding2: {
        fontSize: "16px",
        fontWeight: "600",


    },
    red: {
        color: red[500],
        fontSize: "16px",
        fontWeight: "600",
    },
    green: {
        color: green[500],

    },

    listpaymentok: {
        //backgroundColor:'#f9b406'
        //backgroundColor:'#ddf0de'
        backgroundColor: '#e0e0e0'

    },

    listpayment: {
        backgroundColor: '#ffffff'
    },

    button: {
        minWidth: '200px',
        margin: '5px'
    }

}));

export default function QuotationSingle(props) {
    const classes = useStyles();
    let { numberquotation } = useParams();
    let { paymentresult } = useParams();
    const [loading] = React.useState(false);
    const userData = Auth.getUser();

    return (
        <main className={classes.content}>
            <BackGround open={loading} />
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
                {userData.id_template === null &&
                    <>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link color="inherit" href="/" >
                                {NAME_APP}
                            </Link>
                            <Link
                                color="inherit"
                                href="/quotationlist"
                            >
                                Preventivi
                            </Link>
                            <Typography color="textPrimary">Preventivo numero {numberquotation}</Typography>
                        </Breadcrumbs>
                        <QuotationSingleDetail numberquotation={numberquotation} paymentresult={paymentresult} />
                    </>
                }
                {userData.id_template !== null && userData.id_template === 1 && userData.id_collaboratore_sso !== null && userData.id_user_sso !== null &&
                    <>
                        <QuotationSingleDetailFacile numberquotation={numberquotation} paymentresult={paymentresult} />
                    </>
                }
            </Container>
        </main>
    );
}
