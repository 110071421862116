import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MaterialTable from 'material-table';
import { id_cliente, ReturnInfoClient } from '../containers/logincontext';
import { API_URL_SERVICESPRODCONN } from '../config/config';
import { green, red } from '@material-ui/core/colors';
import Snackbar from '@material-ui/core/Snackbar';
import Auth from '../auth/auth';
import axios from 'axios';
import MuiAlert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Badge from '@material-ui/core/Badge';
import Chip from '@material-ui/core/Chip';
import WebIcon from '@material-ui/icons/Web';
import HttpsIcon from '@material-ui/icons/Https';
import ShareIcon from '@material-ui/icons/Share';
import RateReviewIcon from '@material-ui/icons/RateReview';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import LinkIcon from '@material-ui/icons/Link';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  cardMaxSize: {
    width: '100%'
  },
  alignFab: {
    marginLeft: 'auto',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  img: {
    maxWidth: '80px',
    maxHeight: '40px',
    justifyContent: 'center'
  },
  red_fill: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
  },
  green_fill: {
    color: '#fff',
    backgroundColor: green[500],
  },
  red: {
    color: red[500]

  },
  green: {
    color: green[500]
  },
  customBadgeOk: {
    backgroundColor: green[500],
    color: "white"
  },
  customBadgeKo: {
    backgroundColor: red[500],
    color: "white"
  }
}));

export default function ServicesProdConnTable(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [alertseverity, setAlertSeverity] = React.useState("success");
  const [alertmsg, setAlertMsg] = React.useState(false);
  const [servicesProdConnList, setServicesProdConnList] = React.useState(null);
  const [isSave, setIsSave] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  React.useEffect(() => {
    const url = API_URL_SERVICESPRODCONN + "/" + props.dataPanel.id_factory + "/" + props.dataPanel.id_product;
    axios.get(url,
      {
        headers: {
          'Authorization': 'Bearer ' + Auth.getToken(),
          'Content-Type': 'application/json'
        }
      }
    ).then(payload => {
      setServicesProdConnList(payload.data.types);
    });
  }, [isSave]);

  return (<Card className={classes.cardMaxSize}>
    <CardContent  >
      {servicesProdConnList !== null &&
        <MaterialTable
          title={"Tipologie di connessione " + props.dataPanel.description}
          columns={[
            {
              title: 'Id',
              field: 'id',
              editable: false
            },
            {
              title: 'Tipologia di connessione',
              field: 'type',
              lookup: { 'ws': 'Web Service', 'scrape': 'Web Scraping', 'rate': 'Tariffa' },
              render: rowData =>
                <Box>
                  <Badge>
                    <Chip icon={rowData.type === "ws" ?
                      <HttpsIcon /> :
                      rowData.type === "scrape" ?
                        <WebIcon /> :
                        rowData.type === "share" ?
                          <ShareIcon /> :
                          <RateReviewIcon />
                    }
                      label={rowData.type === "ws" ?
                        "Web Service" :
                        rowData.type === "scrape" ?
                          "Web Scraping" :
                          rowData.type === "share" ?
                            "Share" : "Tariffa"} />
                  </Badge>
                </Box>
            },
          ]}
          localization={{
            pagination: {
              labelDisplayedRows: '{from}-{to} di {count}',
              labelRowsSelect: 'per pagina',
              labelRowsPerPage: 'per pagina',
              firstAriaLabel: 'prima pagina',
              firstTooltip: 'prima pagina',
              previousAriaLabel: 'pagina precedente',
              previousTooltip: 'pagina precedente',
              nextAriaLabel: 'pagina successiva',
              nextTooltip: 'pagina successiva',
              lastAriaLabel: 'ultima pagina',
              lastTooltip: 'ultima pagina',
            },
            toolbar: {
              nRowsSelected: '{0} elementi selezionati',
              searchTooltip: 'Ricerca',
              searchPlaceholder: 'Ricerca'
            },
            header: {
              actions: ''
            },
            body: {
              emptyDataSourceMessage: 'Nessun record da visualizzare',
              filterRow: {
                filterTooltip: 'Filtri'
              },
              editRow: {
                saveTooltip: "Conferma",
                cancelTooltip: "Annulla",
                deleteText: "Confermi la cancellazione ?"
              },
              addTooltip: "Aggiungi",
              deleteTooltip: "Cancella",
              editTooltip: "Modifica",
            }
          }}
          options={{
            pageSize: 10,
            pageSizeOptions: [5, 10],
            paginationType: "stepped",
            search: true,
            headerStyle: {
              backgroundColor: ReturnInfoClient(id_cliente, "primary_color"),
              color: '#ffffff',
              '&:hover': {
                color: '#ffffff',
              },
              '&:active': {
                color: '#ffffff',
              }
            }

          }}
          data={servicesProdConnList}
          editable={{
            onRowAdd: newData =>
              new Promise((resolve, reject) => {
                const url = API_URL_SERVICESPRODCONN + "/" + props.dataPanel.id_factory + "/" + props.dataPanel.id_product;
                axios.put(url, {
                  type: newData.type,
                },
                  {
                    headers: {
                      'Authorization': 'Bearer ' + Auth.getToken(),
                      'Content-Type': 'application/json'
                    }
                  }
                ).then(payload => {
                  if (payload.status === 200) {
                    setAlertMsg("Inserimento dati avvenuto con successo");
                    setAlertSeverity("success");
                    setIsSave(!isSave);
                  } else {
                    setAlertMsg("Errore durante l'inserimento dati");
                    setAlertSeverity("error");
                  }
                  setOpen(true);
                }).catch((error) => {
                  setAlertMsg(error.response.data.error);
                  setAlertSeverity("error");
                  setOpen(true);
                });
                resolve();
              }),
            /* onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                const index = oldData.id;
                const url = API_URL_SERVICESPRODCONN + "/" + index;
                axios.patch(url, {
                  type: newData.type,
                },
                  {
                    headers: {
                      'Authorization': 'Bearer ' + Auth.getToken(),
                      'Content-Type': 'application/json'
                    }
                  }
                ).then(payload => {
                  if (payload.status === 200) {
                    setAlertMsg("Aggiornamento avvenuto con successo");
                    setAlertSeverity("success");
                    setIsSave(!isSave);
                  } else {
                    setAlertMsg("Errore durante l'aggiornamento");
                    setAlertSeverity("error");
                  }
                  setOpen(true);
                }).catch((error) => {
                  setAlertMsg(error.response.data.error);
                  setAlertSeverity("error");
                  setOpen(true);
                });
                resolve();
              }),*/
            onRowDelete: oldData =>
              new Promise((resolve, reject) => {
                const index = oldData.id;
                const url = API_URL_SERVICESPRODCONN + "/" + index;
                axios.delete(url,
                  {
                    headers: {
                      'Authorization': 'Bearer ' + Auth.getToken(),
                      'Content-Type': 'application/json'
                    }
                  }
                ).then(payload => {
                  if (payload.status === 200) {
                    setAlertMsg("Cancellazione avvenuta con successo");
                    setAlertSeverity("success");
                    setIsSave(!isSave);
                  } else {
                    setAlertMsg("Errore durante la cancellazione");
                    setAlertSeverity("error");
                  }
                  setOpen(true);
                }).catch((error) => {
                  setAlertMsg(error.response.data.error);
                  setAlertSeverity("error");
                  setOpen(true);
                });
                resolve();
              }),
          }}
        />
      }
    </CardContent>
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={alertseverity} closeText="Chiudi">
        {alertmsg}
      </Alert>
    </Snackbar>
  </Card>
  );
};

