import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font, Link } from '@react-pdf/renderer';
import moment from 'moment';
import { Fragment } from 'react';
import { kmAnnuiOption, drivingLicenseTypes, genders, tipoGuida, statoCivile, livelliIstruzione, subjectTypes, tipoAlimentazione, etaGuidatorePiuGiovane, ElencoMassimali, ElencoMassimaliAutocarri, tipoFrazionamento } from '../config/config';
import {
    durationsList,
    PianoList,
    PosizioneList,
    TipologiaList,
    PossessoList,
    TipoDimoraList,
    classeCostruttivaList,
    tipologiaCostruzioneList,
    domandeQuestionarioInfortunio,
    domandeQuestionarioMalattia,
    domandeQuestionarioTcm1,
    domandeQuestionarioTcm2,
    clausoleInjuries,
    opzioniMesiDiGuidaMotorBike,
    opzioniCCDrivedMotorBike,
    beneficiariTcm,
    massimaliRcp,
    fasciaFatturatoRcp,
    professioniRcp,
    tettoGf,
    pannelliGf,
    cappottoGf,
    franchigiaFrontaleGf,
    domandeQuestionarioGf,
    tipologiaCostruzioneListGf,
    impElettricoGf,
    impIdraulicoGf
} from '../config/config';
import { returnImage, returnImageHub } from './utils';
import { returnDescription, number_format, returnLabel } from './utils';
import Auth from '../auth/auth';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

function CheckInfoImage(facid, factoriesInfoImage) {
    let image = null;
    factoriesInfoImage.forEach(infoImage => {
        if (infoImage.id_factory === facid) {
            image = infoImage.infoimage;
        }
    });
    return image;
}

function returnImageFromFac(list, fac) {
    let facnew = fac;
    switch (fac) {
        case "TUA":
            facnew = "Tua";
            break;
        case "CTE":
            facnew = "ConTe";
            break;
        case "AMI":
            facnew = "Amissima";
            break;
        case "NOB":
            facnew = "Nobis";
            break;
        case "UNI":
            facnew = "Unipol SAI";
            break;
        case "GA":
            facnew = "Groupama";
            break;
        case "PRI":
            facnew = "Prima";
            break;
        case "GEN":
            facnew = "Generali";
            break;
        case "QXA":
            facnew = "Quixa";
            break;
        default:
            break;
    }

    return returnImage(list, facnew);
}

const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    title: {
        fontSize: 24,
        textAlign: 'center'

    },
    DescriptionSfo: {
        margin: 4,
        fontSize: 6,
        textAlign: 'left'
    },
    CompanyName: {
        margin: 4,
        fontSize: 16,
        textAlign: 'left'
    },
    author: {
        fontSize: 12,
        textAlign: 'center',
        marginBottom: 40,
    },
    subtitle: {
        fontSize: 8,
        fontWeight: 'extrabold',
        fontFamily: 'Oswald',
        color: 'white',
        backgroundColor: '#393939',
        borderTopLeftRadius: 2,
        borderTopRightRadius: 2,
        borderBottomRightRadius: 2,
        borderBottomLeftRadius: 2,
        marginTop: 10,
        paddingTop: 1,
        paddingBottom: 1,
        paddingLeft: 10,
    },
    TitoloNoteGaranzia: {
        fontSize: 6,
        textAlign: 'justify',
        marginLeft: 4,
    },
    NoteGaranzia: {
        textAlign: 'justify',
        fontSize: 4,
        marginLeft: 4,
    },
    Fascicolo: {
        marginTop: 40,
    },
    LinkText: {
        margin: 2,
        fontSize: 8,
        textAlign: 'justify',
        fontFamily: 'Oswald'
    },
    Fascicolo2: {
        marginTop: 4,
    },
    LinkText2: {
        margin: 2,
        fontSize: 8,
        textAlign: 'justify',
        fontFamily: 'Oswald'
    },
    textHeader: {
        margin: 2,
        marginLeft: 10,
        fontSize: 12,
        textAlign: 'left',
        fontFamily: 'Oswald'
    },
    textHeaderSmall: {
        margin: 2,
        marginLeft: 10,
        fontSize: 10,
        textAlign: 'left',
        fontFamily: 'Oswald'
    },
    textHeaderOrg: {
        fontSize: 6,
        textAlign: 'left',
        fontFamily: 'Oswald'
    },
    text: {
        margin: 2,
        fontSize: 8,
        textAlign: 'justify',
        fontFamily: 'Oswald'
    },
    image: {
        maxHeight: 40,
        maxWidth: 100
    },
    imageqr: {
        height: 100,
        width: 100
    },
    imageCompany: {
        marginTop: 15,
        maxHeight: 40,
        maxWidth: 50
    },
    containerImageInfoLeft: {
        width: "25%"
    },
    containerImageInfo: {
        width: "50%"
    },
    imageInfo: {
        marginTop: 15,
        maxHeight: 200,
        maxWidth: 256
    },
    imageCompanySmall: {
        maxHeight: 28,
        marginTop: 5,
        marginBottom: 5,
        maxWidth: 35
    },
    imageInforma: {
        maxHeight: "100%",
        maxWidth: "100%"
    },
    header: {
        fontSize: 8,
        marginBottom: 5,
        marginTop: 5,
        textAlign: 'center',
        color: 'white',
        backgroundColor: '#393939',
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
        borderBottomLeftRadius: 5,
        width: "50%",
        paddingTop: 5,
        paddingBottom: 5,
        alignContent: "center"
    },
    tableHeader: { display: "table", width: "100%" },
    tableRowHeader: { margin: "auto", flexDirection: "row" },
    tableColHeader: {
        width: "25%",
        alignContent: "left"
    },
    tableColHeaderCompany: {
        width: "10%",
        alignContent: "left"
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 30,
        right: 30,
        textAlign: 'right',
        color: 'grey',
    },
    QuotiamoFooter: {
        position: 'absolute',
        fontSize: 8,
        bottom: 30,
        left: 30,
        right: 30,
        textAlign: 'left',
        color: 'grey',
    },
    table: { display: "table", width: "auto", borderStyle: "none", borderWidth: 0, borderRightWidth: 0, borderBottomWidth: 0, borderLeftWidth: 0 },
    tableRow: { margin: "auto", flexDirection: "row" },
    tableRowRiepilogo: {
        margin: "auto",
        maxHeight: 20,
        flexDirection: "row"
    },
    tableCol: { width: "25%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, borderRightWidth: 0 },
    tableColQuest: { width: "75%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, borderRightWidth: 0 },
    tableColQuest1: { width: "25%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, borderRightWidth: 0 },
    tableCol2: {
        marginTop: 1,
        marginBottom: 1,
        height: 32,
        width: "10%",
        borderStyle: "solid",
        borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, borderRightWidth: 0
    },
    tableCol3: {
        marginTop: 1,
        marginBottom: 1,
        height: 32,
        width: "10%",
        borderStyle: "solid",
        borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, borderRightWidth: 0
    },
    tableCol4: {
        marginTop: 1,
        marginBottom: 1,
        height: 32,
        width: "20%",
        borderStyle: "solid",
        borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, borderRightWidth: 0
    },
    tableCol5: {
        marginTop: 1,
        marginBottom: 1,
        height: 32,
        width: "60%",
        borderStyle: "solid",
        borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, borderRightWidth: 0
    },
    tableColPrev: { width: "33%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, borderRightWidth: 0 },
    tableColRiepilogo1: {
        width: "10%",
        height: 15,
        marginTop: 1,
        marginBottom: 1,
        borderStyle: "solid",
        borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, borderRightWidth: 0
    },
    tableColRiepilogo2: {
        width: "30%",
        height: 15,
        marginTop: 1,
        marginBottom: 1,
        borderStyle: "solid",
        borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, borderRightWidth: 0
    },
    tableColRiepilogo3: {
        width: "30%",
        height: 15,
        marginTop: 1,
        marginBottom: 1,
        borderStyle: "solid",
        borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, borderRightWidth: 0
    },
    tableColRiepilogo4: {
        width: "30%",
        height: 15,
        marginTop: 1,
        marginBottom: 1,
        borderStyle: "solid",
        borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, borderRightWidth: 0
    },
    tableColDescrAtrc: { width: "34%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, borderRightWidth: 0 },
    tableColAtrc: { width: "6%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, borderRightWidth: 0 },
    tableColCard: { width: "12%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, borderRightWidth: 0 },
    tableColCard1: { width: "16%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, borderRightWidth: 0 },
    tableCellCompany: {
        margin: 4,
        fontSize: 6,
        textAlign: 'left'
    },
    tableCell: {
        margin: 4,
        fontSize: 6,
        textAlign: 'justify'
    },
    tableCellNote: {
        marginLeft: 2,
        fontSize: 4,
        textAlign: 'justify'
    },
    tableCellRight: {
        margin: 2,
        fontSize: 6,
        textAlign: 'right',
        paddingHorizontal: 10
    },
    tableCellTitle: {
        margin: 4,
        fontSize: 6,
        fontWeight: 'extrabold',
        textAlign: 'justify',
        fontFamily: 'Oswald'

    },
    tableCellRightTitle: {
        margin: 2,
        fontSize: 6,
        fontFamily: 'Oswald',
        fontWeight: 'extrabold',
        textAlign: 'right',
        paddingHorizontal: 10
    },
    tableCellRight2: {
        //margin: 2,    
        marginTop: "auto",
        marginBottom: "auto",
        textAlign: 'right',
        paddingHorizontal: 10,
        fontSize: 8,
        fontWeight: 'extrabold',
        fontFamily: 'Oswald',
        //fontFamily: 'Oswald'     
    },
    tableCell1: {
        margin: 2,
        fontSize: 6,
        textAlign: 'justify',
        fontFamily: 'Oswald'
    }

});

function CheckNote(single, listcvt) {
    let message = [];
    let nameCVT = "";
    let found = false;
    listcvt.forEach(cvt => {
        if (single.service_parameters_code !== null) {
            let doneDesc = false;
            single.service_parameters_code.split(",").forEach(singlecode => {
                //if (cvt.code === singlecode && !found) {
                if (cvt.code !== null && cvt.code.includes(singlecode) && !found) {
                    //message.push(single.description);
                    nameCVT = single.description;
                    if (doneDesc === false) {
                        if (cvt.nameCVT !== "TCM PREMIO FISSO") {
                            let maximalFactor = false;
                            if (cvt.factors !== null && cvt.factors !== undefined && cvt.factors.length > 0) {
                                for (const fattore of cvt.factors) {
                                    if (fattore.code === "maximal") {
                                        if (isNaN(fattore.value)) {
                                            message.push("Massimale: " + fattore.value);
                                        } else {
                                            message.push("Massimale € " + number_format(parseInt(fattore.value), 2, ",", "."));
                                        }
                                        maximalFactor = true;
                                        break;
                                    }
                                }
                            }
                            if (maximalFactor === false && single.massimale !== undefined && single.massimale !== "" && single.massimale !== null) {
                                message.push("Massimale € " + number_format(single.massimale, 2, ",", "."));
                            }

                            let franchigiaFactor = false;
                            if (cvt.factors !== null && cvt.factors !== undefined && cvt.factors.length > 0) {
                                for (const fattore of cvt.factors) {
                                    if (fattore.code === "deductible") {
                                        if (isNaN(fattore.value)) {
                                            message.push("Franchigia: " + fattore.value);
                                        } else {
                                            message.push("Franchigia € " + number_format(parseInt(fattore.value), 2, ",", "."));
                                        }
                                        franchigiaFactor = true;
                                        break;
                                    }
                                }
                            }
                            if (franchigiaFactor === false && single.franchigia !== undefined && single.franchigia !== "" && single.franchigia !== null) {
                                message.push("Franchigia € " + number_format(single.franchigia, 2, ",", "."));
                            }

                            if (single.limite_risarcimento_perc !== undefined && single.limite_risarcimento_perc !== "" && single.limite_risarcimento_perc !== null) {
                                message.push("Limite risarcimento  " + single.limite_risarcimento_perc + " %");
                            }

                            if (single.limite_risarcimento_val !== undefined && single.limite_risarcimento_val !== "" && single.limite_risarcimento_val !== null) {
                                message.push("Limite risarcimento € " + number_format(single.limite_risarcimento_val, 2, ",", "."));
                            }

                            if (single.scoperto_perc !== undefined && single.scoperto_perc !== "" && single.scoperto_perc !== null) {
                                message.push("Scoperto " + single.scoperto_perc + " %");
                            }

                            if (single.scoperto_val !== undefined && single.scoperto_val !== "" && single.scoperto_val !== null) {
                                message.push("Scoperto € " + number_format(single.scoperto_val, 2, ",", "."));
                            }

                            if (single.note_preventivo !== undefined && single.note_preventivo !== "" && single.note_preventivo !== null) {
                                message.push(single.note_preventivo);
                            }
                        } else {
                            let maximalFactor = false;
                            if (cvt.factors !== null && cvt.factors !== undefined && cvt.factors.length > 0) {
                                for (const fattore of cvt.factors) {
                                    if (fattore.code === "maximal") {
                                        if (isNaN(fattore.value)) {
                                            message.push("Massimale: " + fattore.value);
                                        } else {
                                            message.push("Massimale € " + number_format(parseInt(fattore.value), 2, ",", "."));
                                        }
                                        maximalFactor = true;
                                        break;
                                    }
                                }
                            }
                        }
                        doneDesc = true;
                    }
                    found = true;
                }
            });
        }
    });

    if (message.length === 0) return <></>;
    return <View >
        <Text style={styles.TitoloNoteGaranzia}>
            {nameCVT}
        </Text >
        {message.map((message2print, index) => (
            <Text key={index} style={styles.NoteGaranzia}>
                {message2print}
            </Text>
        ))}
    </View>;
}

function guaranteeName(cvt, guarantees_info) {
    if (guarantees_info === undefined) return <></>;
    let nameCVT = "";
    let message = [];

    for (const single of guarantees_info) {
        //guarantees_info.forEach(single => {
        if (single.service_parameters_code !== null) {
            let tipoCalcolo = single.tipo_calcolo !== null ? (single.tipo_calcolo !== "default" ? single.tipo_calcolo : single.tipo_calcolo_default) : single.tipo_calcolo_default;
            if (tipoCalcolo === "comp") {
                let doneDesc = false;
                single.service_parameters_code.split(",").forEach(singlecode => {
                    if (cvt.code === singlecode) {
                        nameCVT = single.description;
                        if (doneDesc === false) {
                            if (cvt.nameCVT !== "TCM PREMIO FISSO") {
                                let maximalFactor = false;
                                if (cvt.factors !== null && cvt.factors !== undefined && cvt.factors.length > 0) {
                                    for (const fattore of cvt.factors) {
                                        if (fattore.code === "maximal") {
                                            if (isNaN(fattore.value)) {
                                                message.push("Massimale: " + fattore.value);
                                            } else {
                                                message.push("Massimale € " + number_format(parseInt(fattore.value), 2, ",", "."));
                                            }
                                            maximalFactor = true;
                                            break;
                                        }
                                    }
                                }
                                if (maximalFactor === false && single.massimale !== undefined && single.massimale !== "" && single.massimale !== null) {
                                    message.push("Massimale € " + number_format(single.massimale, 2, ",", "."));
                                }

                                let franchigiaFactor = false;
                                if (cvt.factors !== null && cvt.factors !== undefined && cvt.factors.length > 0) {
                                    for (const fattore of cvt.factors) {
                                        if (fattore.code === "deductible") {
                                            if (isNaN(fattore.value)) {
                                                message.push("Franchigia: " + fattore.value);
                                            } else {
                                                message.push("Franchigia € " + number_format(parseInt(fattore.value), 2, ",", "."));
                                            }
                                            franchigiaFactor = true;
                                            break;
                                        }
                                    }
                                }
                                if (franchigiaFactor === false && single.franchigia !== undefined && single.franchigia !== "" && single.franchigia !== null) {
                                    message.push("Franchigia € " + number_format(single.franchigia, 2, ",", "."));
                                }

                                if (single.limite_risarcimento_perc !== undefined && single.limite_risarcimento_perc !== "" && single.limite_risarcimento_perc !== null) {
                                    message.push("Limite risarcimento  " + single.limite_risarcimento_perc + " %");
                                }

                                if (single.limite_risarcimento_val !== undefined && single.limite_risarcimento_val !== "" && single.limite_risarcimento_val !== null) {
                                    message.push("Limite risarcimento € " + number_format(single.limite_risarcimento_val, 2, ",", "."));
                                }

                                if (single.scoperto_perc !== undefined && single.scoperto_perc !== "" && single.scoperto_perc !== null) {
                                    message.push("Scoperto " + single.scoperto_perc + " %");
                                }

                                if (single.scoperto_val !== undefined && single.scoperto_val !== "" && single.scoperto_val !== null) {
                                    message.push("Scoperto € " + number_format(single.scoperto_val, 2, ",", "."));
                                }

                                if (single.note_preventivo !== undefined && single.note_preventivo !== "" && single.note_preventivo !== null) {
                                    message.push(single.note_preventivo);
                                }
                            } else {
                                let maximalFactor = false;
                                if (cvt.factors !== null && cvt.factors !== undefined && cvt.factors.length > 0) {
                                    for (const fattore of cvt.factors) {
                                        if (fattore.code === "maximal") {
                                            if (isNaN(fattore.value)) {
                                                message.push("Massimale: " + fattore.value);
                                            } else {
                                                message.push("Massimale € " + number_format(parseInt(fattore.value), 2, ",", "."));
                                            }
                                            maximalFactor = true;
                                            break;
                                        }
                                    }
                                }
                            }
                            doneDesc = true;
                        }
                    }
                });
                if (nameCVT !== "") {
                    break;
                }
            } else {
                nameCVT = single.categoriesdescription;
                if (cvt.nameCVT !== "TCM PREMIO FISSO") {
                    let maximalFactor = false;
                    if (cvt.factors !== null && cvt.factors !== undefined && cvt.factors.length > 0) {
                        for (const fattore of cvt.factors) {
                            if (fattore.code === "maximal") {
                                if (isNaN(fattore.value)) {
                                    message.push("Massimale: " + fattore.value);
                                } else {
                                    message.push("Massimale € " + number_format(parseInt(fattore.value), 2, ",", "."));
                                }
                                maximalFactor = true;
                                break;
                            }
                        }
                    }
                    if (maximalFactor === false && single.massimale !== undefined && single.massimale !== "" && single.massimale !== null) {
                        message.push("Massimale € " + number_format(single.massimale, 2, ",", "."));
                    }

                    let franchigiaFactor = false;
                    if (cvt.factors !== null && cvt.factors !== undefined && cvt.factors.length > 0) {
                        for (const fattore of cvt.factors) {
                            if (fattore.code === "deductible") {
                                if (isNaN(fattore.value)) {
                                    message.push("Franchigia: " + fattore.value);
                                } else {
                                    message.push("Franchigia € " + number_format(parseInt(fattore.value), 2, ",", "."));
                                }
                                franchigiaFactor = true;
                                break;
                            }
                        }
                    }
                    if (franchigiaFactor === false && single.franchigia !== undefined && single.franchigia !== "" && single.franchigia !== null) {
                        message.push("Franchigia € " + number_format(single.franchigia, 2, ",", "."));
                    }

                    if (single.limite_risarcimento_perc !== undefined && single.limite_risarcimento_perc !== "" && single.limite_risarcimento_perc !== null) {
                        message.push("Limite risarcimento  " + single.limite_risarcimento_perc + " %");
                    }

                    if (single.limite_risarcimento_val !== undefined && single.limite_risarcimento_val !== "" && single.limite_risarcimento_val !== null) {
                        message.push("Limite risarcimento € " + number_format(single.limite_risarcimento_val, 2, ",", "."));
                    }

                    if (single.scoperto_perc !== undefined && single.scoperto_perc !== "" && single.scoperto_perc !== null) {
                        message.push("Scoperto " + single.scoperto_perc + " %");
                    }

                    if (single.scoperto_val !== undefined && single.scoperto_val !== "" && single.scoperto_val !== null) {
                        message.push("Scoperto € " + number_format(single.scoperto_val, 2, ",", "."));
                    }

                    if (single.note_preventivo !== undefined && single.note_preventivo !== "" && single.note_preventivo !== null) {
                        message.push(single.note_preventivo);
                    }
                } else {
                    let maximalFactor = false;
                    if (cvt.factors !== null && cvt.factors !== undefined && cvt.factors.length > 0) {
                        for (const fattore of cvt.factors) {
                            if (fattore.code === "maximal") {
                                if (isNaN(fattore.value)) {
                                    message.push("Massimale: " + fattore.value);
                                } else {
                                    message.push("Massimale € " + number_format(parseInt(fattore.value), 2, ",", "."));
                                }
                                maximalFactor = true;
                                break;
                            }
                        }
                    }
                }
                if (nameCVT !== "") {
                    break;
                }
            }
        }
    }

    //return <>{nameCVT}</>;    

    if (message.length === 0) return <Text style={styles.tableCell}>{nameCVT}</Text>;



    return <>
        <Text style={styles.TitoloNoteGaranzia}>
            {nameCVT}
        </Text>
        {message.map((message2print, index) => (
            <Text key={index} style={styles.NoteGaranzia}>
                {message2print}
            </Text>
        ))}
    </>;

}

function IsPrivateType(QuotationInfo) {
    if (QuotationInfo.clientinfo.contractorInfo !== undefined) {
        return (QuotationInfo.clientinfo.contractorInfo.subjectType === "1" ||
            QuotationInfo.clientinfo.contractorInfo.subjectType === undefined ||
            QuotationInfo.clientinfo.contractorInfo.subjectType === "")
    } else {
        return (QuotationInfo.clientinfo.subjectType === "1" ||
            QuotationInfo.clientinfo.subjectType === undefined ||
            QuotationInfo.clientinfo.subjectType === "")
    }
}

function HouseDetails(QuotationInfo, props, QuotationPrice) {
    return <>
        <Text style={styles.subtitle}>
            DATI IMMOBILE
        </Text>
        <View style={styles.table}>
            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Data effetto
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {moment(QuotationInfo.clientinfo.dataEffetto, "YYYY-MM-DD").format("DD/MM/YYYY")}
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                    </Text>
                </View>

            </View>

            <View style={styles.tableRow}>

                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Durata anni
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {returnLabel(durationsList, Number(QuotationInfo.clientinfo.durataAnni))}
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Frazionamento
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {returnLabel(tipoFrazionamento, Number(QuotationInfo.clientinfo.frazionamento))}
                    </Text>
                </View>

            </View>

            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Ubicazione immobile
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {QuotationInfo.clientinfo.toponimo} {QuotationInfo.clientinfo.indirizzo} {QuotationInfo.clientinfo.numeroCivico}
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Città
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {QuotationInfo.clientinfo.cap} {QuotationInfo.clientinfo.citta} ({QuotationInfo.clientinfo.provinciaAbbr})
                    </Text>
                </View>

            </View>

            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Classe costruttiva
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {returnLabel(classeCostruttivaList, QuotationInfo.clientinfo.classeCostruttiva)}
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Tipologia costruzione
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {returnLabel(tipologiaCostruzioneList, QuotationInfo.clientinfo.tipologiaCostruzione)}
                    </Text>
                </View>

            </View>

            <View style={styles.tableRow}>

                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Scala interno
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {QuotationInfo.clientinfo.scalaInterno}
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Anno di costruzione
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {QuotationInfo.clientinfo.annoCostruzioneRistrutturazione}
                    </Text>
                </View>

            </View>

            <View style={styles.tableRow}>

                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Superficie metri quadri
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {QuotationInfo.clientinfo.superficieM2}
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>

                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>

                    </Text>
                </View>


            </View>
            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Piano
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {returnLabel(PianoList, Number(QuotationInfo.clientinfo.piano))}
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Possesso
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {returnLabel(PossessoList, QuotationInfo.clientinfo.tipologiaProp)}
                    </Text>
                </View>
            </View>

            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Tipologia immobile
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {returnLabel(TipologiaList, QuotationInfo.clientinfo.tipologiaImm)}
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Tipo di dimora
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {returnLabel(TipoDimoraList, QuotationInfo.clientinfo.dimora)}
                    </Text>
                </View>

            </View>

            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Posizione immobile
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {returnLabel(PosizioneList, QuotationInfo.clientinfo.posizione)}
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Antifurto
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {QuotationInfo.clientinfo.antifurto ? "Si" : "No"}
                    </Text>
                </View>
            </View>


            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Valore fabbricato
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        € {number_format(QuotationInfo.clientinfo.valoreFabbricato, 2, ",", ".")}
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Valore contenuto
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        € {number_format(QuotationInfo.clientinfo.valoreContenuto, 2, ",", ".")}
                    </Text>
                </View>
            </View>

            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>Numero di persone del nucleo familiare</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {QuotationInfo.clientinfo.nPersNucleoFamiliare}
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}></Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>

                    </Text>
                </View>
            </View>

            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>Numero cani</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {QuotationInfo.clientinfo.nCani}
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>Numero gatti</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {QuotationInfo.clientinfo.nGatti}
                    </Text>
                </View>
            </View>


        </View>
        {!(props.orderModule && props.QuotationSelect) &&

            <>
                <Text style={styles.subtitle}>
                    RIEPILOGO QUOTAZIONI
                </Text>
                <View style={styles.table}>
                    {QuotationPrice.map((ListPrice) =>
                    (
                        <>
                            {ListPrice.datipre !== null && ListPrice.datipre !== undefined && ListPrice.datipre.prices !== undefined &&
                                <>
                                    {ListPrice.datipre.prices.map((singleprice, index) => (
                                        <>
                                            {singleprice.nrQuotation !== "" &&
                                                <View key={index} style={styles.tableRow}>
                                                    <View style={styles.tableCol2}>
                                                        {props.DisplayImg && returnImage(props.factories, ListPrice.company.fac) !== "" &&
                                                            <>
                                                                {ListPrice.company.hub !== undefined && ListPrice.company.hub !== null && ListPrice.company.hub === 1 &&
                                                                    <Image
                                                                        style={styles.imageCompanySmall}
                                                                        src={returnImageHub(props.factoriesHub, ListPrice.company.facid, singleprice.slug) !== "" ? returnImageHub(props.factoriesHub, ListPrice.company.facid, singleprice.slug) : returnImage(props.factories, ListPrice.company.fac)}
                                                                    />
                                                                }
                                                                {(ListPrice.company.hub === undefined || ListPrice.company.hub === null || ListPrice.company.hub === 0) &&
                                                                    <Image
                                                                        style={styles.imageCompanySmall}
                                                                        src={returnImage(props.factories, ListPrice.company.fac)}
                                                                    />
                                                                }
                                                            </>
                                                        }

                                                        {(props.DisplayImg === false || returnImage(props.factories, ListPrice.company.fac) === "") &&
                                                            <Text style={styles.CompanyName}>
                                                                {ListPrice.company.fac}
                                                            </Text>
                                                        }



                                                    </View>

                                                    <View style={styles.tableCol4}>
                                                        {ListPrice.company.sfo_description !== undefined &&

                                                            <Text style={styles.DescriptionSfo}>
                                                                {ListPrice.company.sfo_description}
                                                            </Text>
                                                        }
                                                    </View>
                                                    <View style={styles.tableCol3}>
                                                        <Text style={styles.tableCellCompany}>
                                                            {singleprice.description}
                                                        </Text>
                                                    </View>
                                                    <View style={styles.tableCol5}>
                                                        <Text style={styles.tableCellRight2}>
                                                            € {number_format(singleprice.TotalPrice, 2, ",", ".")}
                                                        </Text>
                                                    </View>
                                                </View>
                                            }
                                        </>
                                    ))}
                                </>
                            }
                        </>
                    ))}
                </View>
            </>

        }
    </>;
}

function GfDetails(QuotationInfo, props, QuotationPrice) {
    return <>
        <Text style={styles.subtitle}>
            DATI IMMOBILE
        </Text>
        <View style={styles.table}>
            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Data effetto
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {moment(QuotationInfo.clientinfo.dataEffetto, "YYYY-MM-DD").format("DD/MM/YYYY")}
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        In costruzione
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {QuotationInfo.clientinfo.inCostruzione === "y" ? "Si" : "No"}
                    </Text>
                </View>
            </View>
            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Ubicazione immobile
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {QuotationInfo.clientinfo.toponimo} {QuotationInfo.clientinfo.indirizzo} {QuotationInfo.clientinfo.numeroCivico}
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Città
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {QuotationInfo.clientinfo.cap} {QuotationInfo.clientinfo.citta} ({QuotationInfo.clientinfo.provinciaAbbr})
                    </Text>
                </View>
            </View>
            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Percentuale abitazioni/uffici
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {QuotationInfo.clientinfo.percentualeAbUff}
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Tipologia costruzione
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {returnLabel(tipologiaCostruzioneListGf, QuotationInfo.clientinfo.tipologiaCostruzione)}
                    </Text>
                </View>
            </View>
            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Tipologia immobile
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {returnLabel(TipologiaList, QuotationInfo.clientinfo.tipologiaImm)}
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Franchigia frontale
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {returnLabel(franchigiaFrontaleGf, parseInt(QuotationInfo.clientinfo.franchigiaFrontale))}
                    </Text>
                </View>
            </View>
            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Anno di costruzione/ristrutturazione
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {QuotationInfo.clientinfo.annoCostruzioneRistrutturazione}
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Numero piani
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {QuotationInfo.clientinfo.nPiani}
                    </Text>
                </View>
            </View>
            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Numero fabbricati
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {QuotationInfo.clientinfo.nFabbricati}
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Numero unità immobiliari
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {QuotationInfo.clientinfo.nUnitaImmobiliari}
                    </Text>
                </View>
            </View>
            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Tetto
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {returnLabel(tettoGf, QuotationInfo.clientinfo.tetto)}
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Presenza pannelli solari e/o fotovoltaici
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {returnLabel(pannelliGf, parseInt(QuotationInfo.clientinfo.pannelli))}
                    </Text>
                </View>
            </View>
            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Presenza cappotto termico
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {returnLabel(cappottoGf, parseInt(QuotationInfo.clientinfo.cappotto))}
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Valore fabbricato
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        € {number_format(QuotationInfo.clientinfo.valoreFabbricato, 2, ",", ".")}
                    </Text>
                </View>
            </View>
            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Impianto elettrico dotato di dichiarazione conformità L. 46/90 o D.M. 37/2008
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {returnLabel(impElettricoGf, parseInt(QuotationInfo.clientinfo.impElettrico))}
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Impianto idraulico dotato di dichiarazione conformità L. 46/90 o D.M. 37/2008
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {returnLabel(impIdraulicoGf, parseInt(QuotationInfo.clientinfo.impIdraulico))}
                    </Text>
                </View>
            </View>
        </View>
        {!(props.orderModule && props.QuotationSelect) &&
            <>
                <Text style={styles.subtitle}>
                    RIEPILOGO QUOTAZIONI
                </Text>
                <View style={styles.table}>
                    {QuotationPrice.map((ListPrice) =>
                    (
                        <>
                            {ListPrice.datipre !== null && ListPrice.datipre !== undefined && ListPrice.datipre.prices !== undefined &&
                                <>
                                    {ListPrice.datipre.prices.map((singleprice, index) => (
                                        <>
                                            {singleprice.nrQuotation !== "" &&
                                                <View key={index} style={styles.tableRow}>
                                                    <View style={styles.tableCol2}>
                                                        {props.DisplayImg && returnImage(props.factories, ListPrice.company.fac) !== "" &&
                                                            <>
                                                                {ListPrice.company.hub !== undefined && ListPrice.company.hub !== null && ListPrice.company.hub === 1 &&
                                                                    <Image
                                                                        style={styles.imageCompanySmall}
                                                                        src={returnImageHub(props.factoriesHub, ListPrice.company.facid, singleprice.slug) !== "" ? returnImageHub(props.factoriesHub, ListPrice.company.facid, singleprice.slug) : returnImage(props.factories, ListPrice.company.fac)}
                                                                    />
                                                                }
                                                                {(ListPrice.company.hub === undefined || ListPrice.company.hub === null || ListPrice.company.hub === 0) &&
                                                                    <Image
                                                                        style={styles.imageCompanySmall}
                                                                        src={returnImage(props.factories, ListPrice.company.fac)}
                                                                    />
                                                                }
                                                            </>
                                                        }

                                                        {(props.DisplayImg === false || returnImage(props.factories, ListPrice.company.fac) === "") &&
                                                            <Text style={styles.CompanyName}>
                                                                {ListPrice.company.fac}
                                                            </Text>
                                                        }



                                                    </View>

                                                    <View style={styles.tableCol4}>
                                                        {ListPrice.company.sfo_description !== undefined &&

                                                            <Text style={styles.DescriptionSfo}>
                                                                {ListPrice.company.sfo_description}
                                                            </Text>
                                                        }
                                                    </View>
                                                    <View style={styles.tableCol3}>
                                                        <Text style={styles.tableCellCompany}>
                                                            {singleprice.description}
                                                        </Text>
                                                    </View>
                                                    <View style={styles.tableCol5}>
                                                        <Text style={styles.tableCellRight2}>
                                                            € {number_format(singleprice.TotalPrice, 2, ",", ".")}
                                                        </Text>
                                                    </View>
                                                </View>
                                            }
                                        </>
                                    ))}
                                </>
                            }
                        </>
                    ))}
                </View>
            </>
        }
    </>;
}

function InjuriesDetails(QuotationInfo, props, QuotationPrice) {
    return <>
        <Text style={styles.subtitle}>
            DATI POLIZZA
        </Text>
        <View style={styles.table}>
            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Data effetto
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {moment(QuotationInfo.clientinfo.dataEffetto, "YYYY-MM-DD").format("DD/MM/YYYY")}
                    </Text>
                </View>
                {QuotationInfo.quotationtype !== "rcp" &&
                    <>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell1}>
                                Frazionamento
                            </Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>
                                {returnLabel(tipoFrazionamento, Number(QuotationInfo.clientinfo.frazionamento))}
                            </Text>
                        </View>
                    </>
                }
                {QuotationInfo.quotationtype === "rcp" &&
                    <>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell1}>
                                Massimale
                            </Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>
                                {returnLabel(massimaliRcp, QuotationInfo.clientinfo.massimale)}
                            </Text>
                        </View>
                    </>
                }
            </View>
        </View>

        {QuotationInfo.quotationtype === "tcm" &&
            <View style={styles.table}>
                <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell1}>
                            Durata anni
                        </Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                            {QuotationInfo.clientinfo.durataAnni}
                        </Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell1}>
                            Beneficiario
                        </Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                            {returnLabel(beneficiariTcm, Number(QuotationInfo.clientinfo.beneficiario))}
                        </Text>
                    </View>
                </View>
            </View>
        }

        {QuotationInfo.quotationtype === "rcp" &&
            <View style={styles.table}>
                <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell1}>
                            Professione
                        </Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                            {returnLabel(professioniRcp, QuotationInfo.clientinfo.tipologiaProfessione)}
                        </Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell1}>
                            Fascia fatturato
                        </Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                            {returnLabel(fasciaFatturatoRcp, QuotationInfo.clientinfo.fatturato)}
                        </Text>
                    </View>
                </View>
            </View>
        }

        {QuotationInfo.quotationtype === "rcp" &&
            <>
                <Text style={styles.subtitle}>
                    DATI STUDIO
                </Text>
                <View style={styles.table}>
                    {DatiStudioDetails(QuotationInfo.clientinfo)}
                </View>
                <Text style={styles.subtitle}>
                    DOMANDE
                </Text>
                <View style={styles.table}>
                    {DomandeDetails(QuotationInfo.clientinfo)}
                </View>
            </>
        }

        {QuotationInfo.clientinfo.clausole !== null && QuotationInfo.clientinfo.clausole !== undefined && QuotationInfo.clientinfo.clausole.length > 0 &&
            <>
                {QuotationInfo.quotationtype === "injuries" &&
                    <>
                        <Text style={styles.subtitle}>
                            CLAUSOLE
                        </Text>
                        <View style={styles.table}>
                            {ClausoleDetails(QuotationInfo.clientinfo.clausole, clausoleInjuries)}
                        </View>
                    </>
                }
            </>
        }

        {!(props.orderModule && props.QuotationSelect) &&
            <>
                <Text style={styles.subtitle}>
                    RIEPILOGO QUOTAZIONI
                </Text>
                <View style={styles.table}>
                    {QuotationPrice.map((ListPrice) =>
                    (
                        <>
                            {ListPrice.datipre !== null && ListPrice.datipre !== undefined && ListPrice.datipre.prices !== undefined &&
                                <>
                                    {ListPrice.datipre.prices.map((singleprice, index) => (
                                        <>
                                            {singleprice.nrQuotation !== "" &&
                                                <View key={index} style={styles.tableRow}>
                                                    <View style={styles.tableCol2}>
                                                        {props.DisplayImg && returnImage(props.factories, ListPrice.company.fac) !== "" &&
                                                            <>
                                                                {ListPrice.company.hub !== undefined && ListPrice.company.hub !== null && ListPrice.company.hub === 1 &&
                                                                    <Image
                                                                        style={styles.imageCompanySmall}
                                                                        src={returnImageHub(props.factoriesHub, ListPrice.company.facid, singleprice.slug) !== "" ? returnImageHub(props.factoriesHub, ListPrice.company.facid, singleprice.slug) : returnImage(props.factories, ListPrice.company.fac)}
                                                                    />
                                                                }
                                                                {(ListPrice.company.hub === undefined || ListPrice.company.hub === null || ListPrice.company.hub === 0) &&
                                                                    <Image
                                                                        style={styles.imageCompanySmall}
                                                                        src={returnImage(props.factories, ListPrice.company.fac)}
                                                                    />
                                                                }
                                                            </>
                                                        }

                                                        {(props.DisplayImg === false || returnImage(props.factories, ListPrice.company.fac) === "") &&
                                                            <Text style={styles.CompanyName}>
                                                                {ListPrice.company.fac}
                                                            </Text>
                                                        }
                                                    </View>

                                                    <View style={styles.tableCol4}>
                                                        {ListPrice.company.sfo_description !== undefined &&
                                                            <Text style={styles.DescriptionSfo}>
                                                                {ListPrice.company.sfo_description}
                                                            </Text>
                                                        }
                                                    </View>
                                                    <View style={styles.tableCol3}>
                                                        <Text style={styles.tableCellCompany}>
                                                            {singleprice.description}
                                                        </Text>
                                                    </View>

                                                    <View style={styles.tableCol5}>
                                                        <Text style={styles.tableCellRight2}>
                                                            € {number_format(singleprice.TotalPrice, 2, ",", ".")}
                                                        </Text>
                                                    </View>
                                                </View>
                                            }
                                        </>
                                    ))}
                                </>
                            }
                        </>
                    ))}
                </View>
            </>
        }
    </>;
}

function DatiStudioDetails(QuotationInfo) {
    return <>
        <View style={styles.tableRow}>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell1}>
                    Indirizzo
                </Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                    {QuotationInfo.toponimo + " " + QuotationInfo.indirizzo + " " + QuotationInfo.numeroCivico}
                </Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell1}>
                    Città
                </Text>
            </View>
            <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                    {QuotationInfo.cap + " " + QuotationInfo.citta + " " + QuotationInfo.provinciaAbbr}
                </Text>
            </View>
        </View>
        {QuotationInfo.altriProfessionisti !== undefined && QuotationInfo.altriProfessionisti !== "" &&
            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Eventuali altri professionisti
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {QuotationInfo.altriProfessionisti}
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                    </Text>
                </View>
            </View>
        }
    </>;
}

function DomandeDetails(QuotationInfo) {
    return <>
        <View style={styles.tableRow}>
            <View style={styles.tableColQuest}>
                <Text style={styles.tableCell}>
                    Negli ultimi 5 anni sono state avanzate richieste di risarcimento nei confronti del cliente o è a conoscenza di circostanze che possano portare a tali richieste?
                </Text>
            </View>
            <View style={styles.tableColQuest1}>
                <Text style={styles.tableCell}>
                    {QuotationInfo.questionarioRcp !== undefined && QuotationInfo.questionarioRcp === "si" ? "Si" : "No"}
                </Text>
            </View>
        </View>
        {QuotationInfo.questionarioTipologiaProfessione !== undefined && QuotationInfo.tipologiaProfessione === "4" &&
            <View style={styles.tableRow}>
                <View style={styles.tableColQuest}>
                    <Text style={styles.tableCell}>
                        Il cliente svolge attività di REVISORE/ SINDACO/ O.D.V./ ATTESTATORE/ AMMINISTRATORE?
                    </Text>
                </View>
                <View style={styles.tableColQuest1}>
                    <Text style={styles.tableCell}>
                        {QuotationInfo.questionarioTipologiaProfessione !== undefined && QuotationInfo.questionarioTipologiaProfessione === "si" ? "Si" : "No"}
                    </Text>
                </View>
            </View>
        }
    </>;
}

function CarDetails(QuotationInfo) {
    return <>
        <Text style={styles.subtitle}>
            DATI VEICOLO
        </Text>

        <View style={styles.table}>
            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>Targa</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {QuotationInfo.vehicleInfo.licensePlate}
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Uso
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {QuotationInfo.clientinfo.use === "P" &&
                            <>PROPRIO</>
                        }
                        {QuotationInfo.clientinfo.use === "T" &&
                            <>TERZI</>
                        }
                        {QuotationInfo.clientinfo.use !== "T" && QuotationInfo.clientinfo.use !== "P" &&
                            <>{QuotationInfo.clientinfo.use}</>
                        }
                    </Text>
                </View>
            </View>

            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>Data immatricolazione</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {moment(QuotationInfo.clientinfo.registrationDate, "YYYY-MM-DD").format("DD/MM/YYYY")}

                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Data acquisto
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {moment(QuotationInfo.clientinfo.purchaseDate, "YYYY-MM-DD").format("DD/MM/YYYY")}

                    </Text>
                </View>
            </View>

            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Marca
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {QuotationInfo.clientinfo.brand} - {QuotationInfo.clientinfo.sicDescription}
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Modello
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {QuotationInfo.clientinfo.model}
                    </Text>
                </View>
            </View>

            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Allestimento
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {QuotationInfo.clientinfo.outfitting}
                    </Text>
                </View>
                {QuotationInfo.vehicleInfo.vehicleType !== "C" && QuotationInfo.vehicleInfo.vehicleType !== "R" &&
                    <>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell1}>
                                Carrozzeria
                            </Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>
                                {QuotationInfo.clientinfo.carBody}
                            </Text>
                        </View>
                    </>
                }
                {(QuotationInfo.vehicleInfo.vehicleType === "C" || QuotationInfo.vehicleInfo.vehicleType === "R") &&
                    <>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell1}>
                                Peso (KG)
                            </Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>
                                {QuotationInfo.clientinfo.weight}
                            </Text>
                        </View>
                    </>
                }
            </View>

            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Alimentazione
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {returnLabel(tipoAlimentazione, QuotationInfo.clientinfo.supply)}
                    </Text>
                </View>
                {QuotationInfo.clientinfo.displacement !== "" &&
                    <>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell1}>
                                Cilindrata
                            </Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>
                                {QuotationInfo.clientinfo.displacement}
                            </Text>
                        </View>
                    </>
                }
                {QuotationInfo.clientinfo.displacement === "" &&
                    <>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell1}>
                            </Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>
                            </Text>
                        </View>
                    </>
                }
            </View>

            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Potenza
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {QuotationInfo.clientinfo.power}
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Cavalli fiscali
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {QuotationInfo.clientinfo.horsePower}
                    </Text>
                </View>
            </View>

            {QuotationInfo.clientinfo.frame !== undefined && QuotationInfo.clientinfo.frame !== "" &&
                <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell1}>
                            Numero telaio
                        </Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                            {QuotationInfo.clientinfo.frame}
                        </Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell1}>
                            Codice omologazione
                        </Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                            {QuotationInfo.clientinfo.homologationCode}
                        </Text>
                    </View>
                </View>
            }

            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Codice MotorNet
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {QuotationInfo.clientinfo.codiceMotornet}

                    </Text>
                </View>
                {QuotationInfo.vehicleInfo.vehicleType !== "C" && QuotationInfo.vehicleInfo.vehicleType !== "R" &&
                    <>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell1}>
                                Km Annui percorsi
                            </Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>
                                {returnDescription(kmAnnuiOption, QuotationInfo.clientinfo.kmAnnui)}
                            </Text>
                        </View>
                    </>
                }
                {(QuotationInfo.vehicleInfo.vehicleType === "C" || QuotationInfo.vehicleInfo.vehicleType === "R") &&
                    <>
                        <View style={styles.tableCol}>
                        </View>
                        <View style={styles.tableCol}>
                        </View>
                    </>
                }
            </View>

            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Antifurto
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {QuotationInfo.clientinfo.alarm === "1" && "Satellitare GPS"}
                        {(QuotationInfo.clientinfo.alarm === undefined || QuotationInfo.clientinfo.alarm === "2") && "Generico"}
                        {QuotationInfo.clientinfo.alarm === "3" && "Assente"}
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Gancio traino
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {QuotationInfo.clientinfo.towHook === "false" ? "No" : QuotationInfo.clientinfo.towHook === "true" ? "Si" : QuotationInfo.clientinfo.towHook}
                    </Text>
                </View>
            </View>

            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Prezzo di listino
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {QuotationInfo.clientinfo.newValue}

                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Valore
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {QuotationInfo.clientinfo.usedValue}
                    </Text>
                </View>
            </View>
        </View>

        <Text style={styles.subtitle}>
            DATI POLIZZA
        </Text>

        <View style={styles.table}>
            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Data decorrenza polizza
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {moment(QuotationInfo.clientinfo.effectiveDate, "YYYY-MM-DD").format("DD/MM/YYYY")}
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Tipologia preventivo
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {QuotationInfo.vehicleInfo.insuranceStatus === "RIN" && "Rinnovo polizza in scadenza o appena scaduta"}
                        {QuotationInfo.vehicleInfo.insuranceStatus === "PRI" && "Prima immatricolazione"}
                        {QuotationInfo.vehicleInfo.insuranceStatus === "VOL" && "Voltura al PRA"}
                        {QuotationInfo.vehicleInfo.insuranceStatus === "CVT" && "Solo CVT"}
                    </Text>
                </View>
            </View>

            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Veicolo
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {(QuotationInfo.vehicleInfo.vehicleType === "AUT" || QuotationInfo.vehicleInfo.vehicleType === "A") && "Automobile"}
                        {QuotationInfo.vehicleInfo.vehicleType === "C" && "Autocarro"}
                        {QuotationInfo.vehicleInfo.vehicleType === "R" && "Rimorchio"}
                        {QuotationInfo.vehicleInfo.vehicleType === "M" && "Motociclo"}
                        {QuotationInfo.vehicleInfo.vehicleType === "W" && "Ciclomotore"}
                    </Text>
                </View>
                {(QuotationInfo.vehicleInfo.vehicleType === "A" || QuotationInfo.vehicleInfo.vehicleType === "AUT") &&
                    <>
                        {QuotationInfo.vehicleInfo.insuranceStatus === "RIN" &&
                            <>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell1}>
                                        Scadenza contrattuale
                                    </Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {moment(QuotationInfo.clientinfo.policyExpirationDate, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                    </Text>
                                </View>
                            </>
                        }
                        {QuotationInfo.vehicleInfo.insuranceStatus !== "RIN" &&
                            <>
                                <View style={styles.tableCol}>
                                </View>
                                <View style={styles.tableCol}>
                                </View>
                            </>
                        }
                    </>
                }
                {QuotationInfo.vehicleInfo.vehicleType !== "A" && QuotationInfo.vehicleInfo.vehicleType !== "AUT" && (QuotationInfo.vehicleInfo.fascia === "" || QuotationInfo.vehicleInfo.fascia === undefined) &&
                    <>
                        {QuotationInfo.vehicleInfo.insuranceStatus === "RIN" &&
                            <>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell1}>
                                        Scadenza contrattuale
                                    </Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {moment(QuotationInfo.clientinfo.policyExpirationDate, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                    </Text>
                                </View>
                            </>
                        }
                        {QuotationInfo.vehicleInfo.insuranceStatus !== "RIN" &&
                            <>
                                <View style={styles.tableCol}>
                                </View>
                                <View style={styles.tableCol}>
                                </View>
                            </>
                        }
                    </>
                }
                {QuotationInfo.vehicleInfo.vehicleType !== "A" && QuotationInfo.vehicleInfo.vehicleType !== "AUT" && QuotationInfo.vehicleInfo.fascia !== "" && QuotationInfo.vehicleInfo.fascia !== undefined &&
                    <>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell1}>
                                Dichiarazione altro autocarro in
                            </Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>
                                {(QuotationInfo.vehicleInfo.fascia === "FASCIA1" || QuotationInfo.vehicleInfo.fascia === "FASCIA3") &&
                                    <>FASCIA 1 (4+1 anni senza sinistri)</>
                                }
                                {QuotationInfo.vehicleInfo.fascia === "FASCIA2" &&
                                    <>FASCIA 2 (3+1 anni senza sinistri)</>
                                }
                            </Text>
                        </View>
                    </>
                }
            </View>

            {(QuotationInfo.vehicleInfo.vehicleType !== "C" && QuotationInfo.vehicleInfo.vehicleType !== "R") && (QuotationInfo.quoteInfo.extraLicensePlate !== "" || QuotationInfo.quoteInfo.familyDecree || QuotationInfo.quoteInfo.bersaniDecree) &&
                <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell1}>
                            Opzioni
                        </Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                            {QuotationInfo.quoteInfo.familyDecree && "Decreto famliare"}
                            {QuotationInfo.quoteInfo.bersaniDecree && "Legge Bersani"}
                            {QuotationInfo.quoteInfo.familyDecree === false && QuotationInfo.quoteInfo.bersaniDecree === false && "Importa CU da altra targa"}
                        </Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell1}>
                            Targa associata
                        </Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                            {QuotationInfo.quoteInfo.extraLicensePlate}
                        </Text>
                    </View>
                </View>
            }

            {QuotationInfo.vehicleInfo.vehicleType !== "C" && QuotationInfo.vehicleInfo.vehicleType !== "R" &&
                <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell1}>
                            Tipo di guida
                        </Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                            {returnLabel(tipoGuida, QuotationInfo.clientinfo.guideType)}
                        </Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell1}>
                            Età del guidatore più giovane
                        </Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                            {returnLabel(etaGuidatorePiuGiovane, QuotationInfo.clientinfo.youngestLicensedAge)}
                        </Text>
                    </View>
                </View>
            }

            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Massimale
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {QuotationInfo.vehicleInfo.vehicleType !== "C" && QuotationInfo.vehicleInfo.vehicleType !== "R" &&
                            <>
                                {returnLabel(ElencoMassimali, QuotationInfo.clientinfo.maximal)}
                            </>
                        }
                        {(QuotationInfo.vehicleInfo.vehicleType === "C" || QuotationInfo.vehicleInfo.vehicleType === "R") &&
                            <>
                                {returnLabel(ElencoMassimaliAutocarri, QuotationInfo.clientinfo.maximal)}
                            </>
                        }
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Frazionamento
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {returnLabel(tipoFrazionamento, Number((QuotationInfo.clientinfo.splittingUp === undefined || QuotationInfo.clientinfo.splittingUp === "") ? 1 : QuotationInfo.clientinfo.splittingUp))}
                    </Text>
                </View>
            </View>

            {(QuotationInfo.vehicleInfo.vehicleType === "M" || QuotationInfo.vehicleInfo.vehicleType === "W") &&
                <>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell1}>
                                Cilindrata più alta guidata
                            </Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>
                                {returnLabel(opzioniCCDrivedMotorBike, QuotationInfo.clientinfo.highestCcDrived)}
                            </Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell1}>
                                Mesi di guida consecutivi
                            </Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>
                                {returnLabel(opzioniMesiDiGuidaMotorBike, QuotationInfo.clientinfo.drivingConsecutiveMonthCode)}
                            </Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell1}>
                                Copertura sospesa l'ultimo anno
                            </Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>
                                {QuotationInfo.clientinfo.suspendedLastYear === undefined || QuotationInfo.clientinfo.suspendedLastYear === "no" ? "No" : "Si"}
                            </Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell1}>
                            </Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>
                            </Text>
                        </View>
                    </View>
                </>
            }
        </View>
    </>;
}

function DetailPerson(PersonInfo, QuotationInfo, title, ContractorType, product) {
    return <>
        <Text style={styles.subtitle}>
            {title}
        </Text>
        <View style={styles.table}>
            {PersonInfo !== undefined ?
                <Fragment>
                    {IsPrivateType(QuotationInfo) ?
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>Cognome Nome</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{PersonInfo.surname + " " + PersonInfo.name}</Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>Data di nascita</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {moment(PersonInfo.birthDate, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                </Text>
                            </View>
                        </View> :
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>
                                    {returnLabel(subjectTypes, Number(PersonInfo.subjectType))}
                                </Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{PersonInfo.companyName}</Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>Partita IVA</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{PersonInfo.vatNumber}</Text>
                            </View>
                        </View>
                    }

                    {IsPrivateType(QuotationInfo) &&
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>Codice fiscale</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {PersonInfo.fiscalCode}
                                </Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>Luogo di nascita</Text>
                            </View>
                            <View style={styles.tableCol}>


                                {PersonInfo.birthCity !== "" &&
                                    <Text style={styles.tableCell}>
                                        {PersonInfo.birthCity + " (" + PersonInfo.birthProvince + ") " + PersonInfo.birthNation}
                                    </Text>
                                }
                                {PersonInfo.birthCity === "" &&
                                    <Text style={styles.tableCell}>
                                        {PersonInfo.birthNation}
                                    </Text>
                                }
                            </View>
                        </View>
                    }
                </Fragment> :
                <Fragment>
                    {IsPrivateType(QuotationInfo) ?
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>Cognome Nome</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{QuotationInfo.clientinfo.surname + " " + QuotationInfo.clientinfo.name}</Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>Data di nascita</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {moment(QuotationInfo.clientinfo.birthDate, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                </Text>
                            </View>
                        </View> :
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>
                                    {returnLabel(subjectTypes, Number(QuotationInfo.clientinfo.subjectType))}
                                </Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{QuotationInfo.clientinfo.companyName}</Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>Partita IVA</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{QuotationInfo.clientinfo.vatNumber}</Text>
                            </View>
                        </View>
                    }
                    {IsPrivateType(QuotationInfo) &&
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>Codice fiscale</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {QuotationInfo.clientinfo.fiscalCode}
                                </Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>Luogo di nascita</Text>
                            </View>
                            <View style={styles.tableCol}>


                                {QuotationInfo.clientinfo.birthCity !== "" &&
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.birthCity + " (" + QuotationInfo.clientinfo.birthProvince + ") " + QuotationInfo.clientinfo.birthNation}
                                    </Text>
                                }
                                {QuotationInfo.clientinfo.birthCity === "" &&
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.birthNation}
                                    </Text>
                                }
                            </View>
                        </View>
                    }
                </Fragment>
            }

            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>Telefono</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {QuotationInfo.clientinfo.phoneNumber !== undefined && QuotationInfo.clientinfo.phoneNumber}
                        {PersonInfo !== undefined && PersonInfo.phoneNumber}
                    </Text>
                </View>

                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>Email</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {QuotationInfo.clientinfo !== undefined && QuotationInfo.clientinfo.email}
                        {PersonInfo !== undefined && PersonInfo.email}
                    </Text>
                </View>
            </View>
            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>Indirizzo</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {QuotationInfo.clientinfo.toponym !== undefined ?
                            QuotationInfo.clientinfo.toponym + " " + QuotationInfo.clientinfo.address + " " + QuotationInfo.clientinfo.streetNumber :
                            PersonInfo.residenceInfo.toponym + " " + PersonInfo.residenceInfo.address + " " + PersonInfo.residenceInfo.streetNumber
                        }
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Città
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    {PersonInfo !== undefined &&
                        <Text style={styles.tableCell}>
                            {PersonInfo.residenceInfo.cap + " " + PersonInfo.residenceInfo.city + " " + PersonInfo.residenceInfo.provinceAbbr}
                        </Text>
                    }
                    {PersonInfo === undefined &&
                        <Text style={styles.tableCell}>

                            {QuotationInfo.clientinfo.cap + " " + QuotationInfo.clientinfo.city + " " + QuotationInfo.clientinfo.provinceAbbr}
                        </Text>
                    }
                </View>
            </View>

            {((product !== undefined && product !== "house" && product !== "rcp" && product !== "gf" && IsPrivateType(QuotationInfo)) || (product === undefined && QuotationInfo.vehicleInfo.vehicleType !== "C" && QuotationInfo.vehicleInfo.vehicleType !== "R" && IsPrivateType(QuotationInfo))) &&
                <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell1}>Professione</Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                            {QuotationInfo.clientlabelinfo.profession}
                        </Text>
                    </View>
                    {product !== "injuries" && product !== "tcm" &&
                        <>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>
                                    Istruzione
                                </Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {returnDescription(livelliIstruzione, QuotationInfo.clientinfo.qualification !== undefined ? QuotationInfo.clientinfo.qualification : PersonInfo.qualification)}
                                </Text>
                            </View>
                        </>
                    }
                    {product === "injuries" && QuotationInfo.clientinfo.lavoratoreDipendente !== null && QuotationInfo.clientinfo.lavoratoreDipendente !== undefined &&
                        <>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>
                                    Lavoratore dipendente
                                </Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {QuotationInfo.clientinfo.lavoratoreDipendente === true ? "Si" : "No"}
                                </Text>
                            </View>
                        </>
                    }
                    {product === "injuries" && (QuotationInfo.clientinfo.lavoratoreDipendente === null || QuotationInfo.clientinfo.lavoratoreDipendente === undefined) &&
                        <>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>
                                </Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                </Text>
                            </View>
                        </>
                    }
                    {product === "tcm" &&
                        <>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>
                                </Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                </Text>
                            </View>
                        </>
                    }
                </View>
            }

            {product !== "house" && IsPrivateType(QuotationInfo) &&
                <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell1}>Sesso</Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                            {returnLabel(genders, PersonInfo !== undefined ? PersonInfo.gender : QuotationInfo.clientinfo.gender)}
                        </Text>
                    </View>
                    {product !== undefined &&
                        <>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}></Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                </Text>
                            </View>
                        </>
                    }
                    {product === undefined && QuotationInfo.vehicleInfo.vehicleType !== "C" && QuotationInfo.vehicleInfo.vehicleType !== "R" &&
                        <>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>
                                    Stato civile
                                </Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {returnDescription(statoCivile, QuotationInfo.clientinfo.maritalStatus !== undefined ? QuotationInfo.clientinfo.maritalStatus : PersonInfo.maritalStatus)}
                                </Text>
                            </View>
                        </>
                    }
                    {product === undefined && (QuotationInfo.vehicleInfo.vehicleType === "C" || QuotationInfo.vehicleInfo.vehicleType === "R") &&
                        <>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>
                                </Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                </Text>
                            </View>
                        </>
                    }
                </View>}

            {product === undefined && QuotationInfo.vehicleInfo.vehicleType !== "C" && QuotationInfo.vehicleInfo.vehicleType !== "R" &&
                QuotationInfo.vehicleInfo.vehicleType !== "W" && QuotationInfo.vehicleInfo.vehicleType !== "M" &&
                <>
                    {product === undefined && ContractorType && IsPrivateType(QuotationInfo) &&
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>Figli</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {QuotationInfo.clientinfo.sons === "true" ? "SI" : "NO"}
                                </Text>
                            </View>


                            <View style={styles.tableCol}>

                            </View>


                            <View style={styles.tableCol}>

                            </View>

                        </View>
                    }

                    {product === undefined && ContractorType && IsPrivateType(QuotationInfo) && QuotationInfo.clientinfo.sons === "true" &&
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>Figli 0-3 anni</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{QuotationInfo.clientinfo.nSons1}</Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>Figli 4-16 anni</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{QuotationInfo.clientinfo.nSons2}</Text>
                            </View>
                        </View>
                    }

                    {product === undefined && ContractorType && IsPrivateType(QuotationInfo) && QuotationInfo.clientinfo.sons === "true" &&
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>Figli 17-25 anni</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{QuotationInfo.clientinfo.nSons3}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>Figli 26 anni o più</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{QuotationInfo.clientinfo.nSons4}</Text>
                            </View>
                        </View>
                    }

                    {product === undefined && ContractorType && IsPrivateType(QuotationInfo) &&
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>Tipo patente</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {returnDescription(drivingLicenseTypes, QuotationInfo.clientinfo.drivingLicenseType)}
                                </Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>Mese e Anno  conseguimento patente</Text>

                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {QuotationInfo.clientinfo.drivingLicenseMonth}/{QuotationInfo.clientinfo.drivingLicenseYear}
                                </Text>
                            </View>
                        </View>
                    }

                    {product === undefined && ContractorType && IsPrivateType(QuotationInfo) &&
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>Punti patente</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {QuotationInfo.clientinfo.drivingLicensePoints}
                                </Text>
                            </View>
                            <View style={styles.tableCol}>

                            </View>
                            <View style={styles.tableCol}>

                            </View>
                        </View>
                    }
                </>
            }

        </View>
    </>;
}

function DetailPersonAmministratore(PersonInfo, QuotationInfo, title, ContractorType, product) {
    return <>
        <Text style={styles.subtitle}>
            {title}
        </Text>
        <View style={styles.table}>
            {PersonInfo !== undefined ?
                <Fragment>
                    {PersonInfo.subjectType === "1" ?
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>Cognome Nome</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{PersonInfo.surname + " " + PersonInfo.name}</Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>Data di nascita</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {moment(PersonInfo.birthDate, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                </Text>
                            </View>
                        </View> :
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>
                                    {returnLabel(subjectTypes, Number(PersonInfo.subjectType))}
                                </Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{PersonInfo.companyName}</Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>Partita IVA</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{PersonInfo.vatNumber}</Text>
                            </View>
                        </View>
                    }

                    {PersonInfo.subjectType === "1" &&
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>Codice fiscale</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {PersonInfo.fiscalCode}
                                </Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>Luogo di nascita</Text>
                            </View>
                            <View style={styles.tableCol}>


                                {PersonInfo.birthCity !== "" &&
                                    <Text style={styles.tableCell}>
                                        {PersonInfo.birthCity + " (" + PersonInfo.birthProvince + ") " + PersonInfo.birthNation}
                                    </Text>
                                }
                                {PersonInfo.birthCity === "" &&
                                    <Text style={styles.tableCell}>
                                        {PersonInfo.birthNation}
                                    </Text>
                                }
                            </View>
                        </View>
                    }
                </Fragment> :
                <Fragment>
                    {PersonInfo.subjectType === "1" ?
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>Cognome Nome</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{PersonInfo.surname + " " + PersonInfo.name}</Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>Data di nascita</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {moment(PersonInfo.birthDate, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                </Text>
                            </View>
                        </View> :
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>
                                    {returnLabel(subjectTypes, Number(PersonInfo.subjectType))}
                                </Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{PersonInfo.companyName}</Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>Partita IVA</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{PersonInfo.vatNumber}</Text>
                            </View>
                        </View>
                    }
                    {PersonInfo.subjectType === "1" &&
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>Codice fiscale</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {PersonInfo.fiscalCode}
                                </Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>Luogo di nascita</Text>
                            </View>
                            <View style={styles.tableCol}>


                                {PersonInfo.birthCity !== "" &&
                                    <Text style={styles.tableCell}>
                                        {PersonInfo.birthCity + " (" + PersonInfo.birthProvince + ") " + PersonInfo.birthNation}
                                    </Text>
                                }
                                {PersonInfo.birthCity === "" &&
                                    <Text style={styles.tableCell}>
                                        {PersonInfo.birthNation}
                                    </Text>
                                }
                            </View>
                        </View>
                    }
                </Fragment>
            }

            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>Telefono</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {PersonInfo.phoneNumber}
                    </Text>
                </View>

                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>Email</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {PersonInfo.email}
                    </Text>
                </View>
            </View>
            <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>Indirizzo</Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {PersonInfo.residenceInfo.toponym + " " + PersonInfo.residenceInfo.address + " " + PersonInfo.residenceInfo.streetNumber}
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell1}>
                        Città
                    </Text>
                </View>
                <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                        {PersonInfo.residenceInfo.cap + " " + PersonInfo.residenceInfo.city + " " + PersonInfo.residenceInfo.provinceAbbr}
                    </Text>
                </View>
            </View>

            {((product !== undefined && product !== "house" && product !== "rcp" && product !== "gf" && IsPrivateType(QuotationInfo)) || (product === undefined && QuotationInfo.vehicleInfo.vehicleType !== "C" && QuotationInfo.vehicleInfo.vehicleType !== "R" && IsPrivateType(QuotationInfo))) &&
                <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell1}>Professione</Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                            {QuotationInfo.clientlabelinfo.profession}
                        </Text>
                    </View>
                    {product !== "injuries" && product !== "tcm" &&
                        <>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>
                                    Istruzione
                                </Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {returnDescription(livelliIstruzione, QuotationInfo.clientinfo.qualification !== undefined ? QuotationInfo.clientinfo.qualification : PersonInfo.qualification)}
                                </Text>
                            </View>
                        </>
                    }
                    {product === "injuries" && QuotationInfo.clientinfo.lavoratoreDipendente !== null && QuotationInfo.clientinfo.lavoratoreDipendente !== undefined &&
                        <>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>
                                    Lavoratore dipendente
                                </Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {QuotationInfo.clientinfo.lavoratoreDipendente === true ? "Si" : "No"}
                                </Text>
                            </View>
                        </>
                    }
                    {product === "injuries" && (QuotationInfo.clientinfo.lavoratoreDipendente === null || QuotationInfo.clientinfo.lavoratoreDipendente === undefined) &&
                        <>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>
                                </Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                </Text>
                            </View>
                        </>
                    }
                    {product === "tcm" &&
                        <>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>
                                </Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                </Text>
                            </View>
                        </>
                    }
                </View>
            }

            {product !== "house" && IsPrivateType(QuotationInfo) &&
                <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell1}>Sesso</Text>
                    </View>
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>
                            {returnLabel(genders, PersonInfo !== undefined ? PersonInfo.gender : QuotationInfo.clientinfo.gender)}
                        </Text>
                    </View>
                    {product !== undefined &&
                        <>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}></Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                </Text>
                            </View>
                        </>
                    }
                    {product === undefined && QuotationInfo.vehicleInfo.vehicleType !== "C" && QuotationInfo.vehicleInfo.vehicleType !== "R" &&
                        <>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>
                                    Stato civile
                                </Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {returnDescription(statoCivile, QuotationInfo.clientinfo.maritalStatus !== undefined ? QuotationInfo.clientinfo.maritalStatus : PersonInfo.maritalStatus)}
                                </Text>
                            </View>
                        </>
                    }
                    {product === undefined && (QuotationInfo.vehicleInfo.vehicleType === "C" || QuotationInfo.vehicleInfo.vehicleType === "R") &&
                        <>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>
                                </Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                </Text>
                            </View>
                        </>
                    }
                </View>}

            {product === undefined && QuotationInfo.vehicleInfo.vehicleType !== "C" && QuotationInfo.vehicleInfo.vehicleType !== "R" &&
                QuotationInfo.vehicleInfo.vehicleType !== "W" && QuotationInfo.vehicleInfo.vehicleType !== "M" &&
                <>
                    {product === undefined && ContractorType && IsPrivateType(QuotationInfo) &&
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>Figli</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {QuotationInfo.clientinfo.sons === "true" ? "SI" : "NO"}
                                </Text>
                            </View>


                            <View style={styles.tableCol}>

                            </View>


                            <View style={styles.tableCol}>

                            </View>

                        </View>
                    }

                    {product === undefined && ContractorType && IsPrivateType(QuotationInfo) && QuotationInfo.clientinfo.sons === "true" &&
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>Figli 0-3 anni</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{QuotationInfo.clientinfo.nSons1}</Text>
                            </View>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>Figli 4-16 anni</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{QuotationInfo.clientinfo.nSons2}</Text>
                            </View>
                        </View>
                    }

                    {product === undefined && ContractorType && IsPrivateType(QuotationInfo) && QuotationInfo.clientinfo.sons === "true" &&
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>Figli 17-25 anni</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{QuotationInfo.clientinfo.nSons3}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>Figli 26 anni o più</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{QuotationInfo.clientinfo.nSons4}</Text>
                            </View>
                        </View>
                    }

                    {product === undefined && ContractorType && IsPrivateType(QuotationInfo) &&
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>Tipo patente</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {returnDescription(drivingLicenseTypes, QuotationInfo.clientinfo.drivingLicenseType)}
                                </Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>Mese e Anno  conseguimento patente</Text>

                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {QuotationInfo.clientinfo.drivingLicenseMonth}/{QuotationInfo.clientinfo.drivingLicenseYear}
                                </Text>
                            </View>
                        </View>
                    }

                    {product === undefined && ContractorType && IsPrivateType(QuotationInfo) &&
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>Punti patente</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {QuotationInfo.clientinfo.drivingLicensePoints}
                                </Text>
                            </View>
                            <View style={styles.tableCol}>

                            </View>
                            <View style={styles.tableCol}>

                            </View>
                        </View>
                    }
                </>
            }

        </View>
    </>;
}

function ReturnCodiceCommerciale(Quotation) {
    const list = JSON.parse(Quotation.detail);
    let codice = "";

    list.forEach(element => {

        if (element.company.commerciale !== null) {
            codice = element.company.commerciale;
        }
    });
    return codice;
}

function QuestionarioDetails(questionario, domandeQuestionario) {
    return (
        domandeQuestionario.map((domanda) => (
            <>
                {questionario[domanda.value] !== null && questionario[domanda.value] !== undefined && questionario[domanda.value] !== "" &&
                    <View style={styles.tableRow}>
                        <View style={styles.tableColQuest}>
                            <Text style={styles.tableCell}>
                                {domanda.description}
                                {domanda.value === "mal_domanda_17" &&
                                    <> APPARATO RESPIRATORIO: asma, enfisema, infezioni bronco polmonari, pleurite, TBC, polipi delle corde vocali;
                                        APPARATO CARDIOCIRCOLATORIO: infarto miocardio, angina pectoris, ipertensione arteriosa, valvulopatie, arteriopatie, varici degli arti
                                        inferiori, tromboflebiti; APPARATO DIGERENTE: ulcera gastroduodenale, rettocolite ulcerosa, epatite virale B-C, cirrosi, calcolosi biliare;
                                        APPARATO UROGENITALE: malattie infettive e infiammatorie del nefrone, calcolosi, insufficenza renale, ipertrofia della prostata, cisti
                                        dell'ovaio, fibroma uterino, varicocele;
                                        APPARATO OSTEOARTICOLARE: artrite, artrosi, ernia del disco, lesioni del menisco o dei legamenti del ginocchio, alluce valgo,
                                        patologie non trattate con protesi;
                                        SISTEMA NERVOSO: morbo di Parkinson, epilessia, sclerosi multipla, demenza (m. di Alzheimer), SLA;
                                        ENDOCRINO METABOLICHE: diabete mellito tipo 1-2, malattie della tiroide o delle ghiandole surrena;
                                        DEL SANGUE: anemia, leucemia;
                                        DEL CONNETTIVO: connettivite mista, lupus eritematoso sistemico, sclerodermia, poliartrite nodosa, reumatismo articolare;
                                        DELL'OCCHIO: cataratta, glaucoma, distacco di retina, malattie vitrali;
                                        TUMORI MALIGNI E BENIGNI
                                    </>
                                }
                            </Text>
                        </View>
                        <View style={styles.tableColQuest1}>
                            <Text style={styles.tableCell}>
                                {questionario[domanda.value] === "no" ? "No" : questionario[domanda.value] === "yes" ? "Si" : questionario[domanda.value]}
                            </Text>
                        </View>
                    </View>
                }
            </>
        ))
    );
}

function ClausoleDetails(clausoleSelected, listaClausole) {
    return (
        listaClausole.map((clausola) => (
            <>
                {clausoleSelected.some(v => (v === clausola.value)) &&
                    <View style={styles.tableRow}>
                        <View style={styles.tableColQuest}>
                            <Text style={styles.tableCell}>
                                {clausola.label}
                            </Text>
                        </View>
                        <View style={styles.tableColQuest1}>
                            <Text style={styles.tableCell}>
                            </Text>
                        </View>
                    </View>
                }
            </>
        ))
    );
}

export default function QuotationPDF(props) {
    const userData = Auth.getUser();
    const QuotationInfo = JSON.parse(props.row.clientinfo);
    const QuotationPrice = JSON.parse(props.row.detail);
    Font.register({
        family: 'Oswald',
        src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
    });

    function DetailQuotation(serviceimage, prices, CompanyName, DescriptionService, guarantees_info, imageInfo, links) {
        return <Page size="A4" style={styles.body}>
            <HeaderDocument />
            <View>
                {serviceimage !== "" &&
                    <Image
                        style={styles.imageCompany}
                        src={serviceimage}
                    />
                }
                {(props.DisplayImg === false || serviceimage === "") &&
                    <Text style={styles.CompanyName}>
                        {CompanyName}
                    </Text>
                }
                {DescriptionService !== undefined &&
                    <Text style={styles.DescriptionSfo}>
                        {DescriptionService}
                    </Text>
                }
                {prices.map((singleprice, index) => (
                    <>
                        {singleprice.nrQuotation !== "" &&
                            <View key={index}>
                                <Text style={styles.subtitle}>
                                    Preventivo nr {singleprice.nrQuotation} {singleprice.description}
                                </Text>
                                <View style={styles.table}>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableColPrev}>
                                            <Text style={styles.tableCellTitle}>
                                                Garanzia Fabbrica
                                            </Text>
                                        </View>
                                        <View style={styles.tableColPrev}>
                                            <Text style={styles.tableCellTitle}>
                                                Garanzia QUOTIAMO
                                            </Text>
                                        </View>
                                        <View style={styles.tableColPrev}>
                                            <Text style={styles.tableCellRightTitle}>
                                                Premio
                                            </Text>
                                        </View>
                                    </View>
                                    {singleprice.listResponseCVT.map((cvt, index) => (
                                        <View key={index}>
                                            {cvt.selected && (cvt.price > 0 || cvt.price < 0) &&
                                                <View style={styles.tableRow}>
                                                    <View style={styles.tableColPrev}>
                                                        <Text style={styles.tableCell}>
                                                            {cvt.nameCVT}
                                                        </Text>
                                                    </View>
                                                    <View style={styles.tableColPrev}>
                                                        {guaranteeName(cvt, guarantees_info)}
                                                    </View>
                                                    <View style={styles.tableColPrev}>
                                                        <Text style={styles.tableCellRight}>
                                                            € {number_format(cvt.price, 2, ",", ".")}
                                                        </Text>
                                                    </View>
                                                </View>
                                            }
                                            {cvt.selected === true && cvt.price === 0 &&
                                                <View style={styles.tableRow}>
                                                    <View style={styles.tableColPrev}>
                                                        <Text style={styles.tableCell}>
                                                            {cvt.nameCVT}
                                                        </Text>
                                                    </View>
                                                    <View style={styles.tableColPrev}>
                                                        {guaranteeName(cvt, guarantees_info)}
                                                    </View>
                                                    <View style={styles.tableColPrev}>
                                                        <Text style={styles.tableCellRight}>
                                                            Compresa
                                                        </Text>
                                                    </View>
                                                </View>
                                            }
                                            {cvt.selected === false &&
                                                <View style={styles.tableRow}>
                                                    <View style={styles.tableColPrev}>
                                                        <Text style={styles.tableCell}>
                                                            {cvt.nameCVT}
                                                        </Text>
                                                    </View>
                                                    <View style={styles.tableColPrev}>
                                                        {guaranteeName(cvt, guarantees_info)}
                                                    </View>
                                                    <View style={styles.tableColPrev}>
                                                        <Text style={styles.tableCellRight}>
                                                            Non disponibile
                                                        </Text>
                                                    </View>
                                                </View>
                                            }
                                        </View>
                                    ))
                                    }
                                    {singleprice.ListService.map((service, index) => (
                                        <View key={index}>
                                            <View style={styles.tableRow}>
                                                <View style={styles.tableColPrev}>
                                                    <Text style={styles.tableCell}>
                                                        {service.description}
                                                    </Text>
                                                </View>
                                                <View style={styles.tableColPrev}>
                                                    <Text style={styles.tableCell}>
                                                    </Text>
                                                </View>
                                                <View style={styles.tableColPrev}>
                                                    <Text style={styles.tableCellRight}>
                                                        € {number_format(service.priceservice, 2, ",", ".")}
                                                    </Text>
                                                </View>
                                            </View>
                                        </View>
                                    ))
                                    }
                                    {singleprice.Commissions > 0 &&
                                        <View>
                                            <View style={styles.tableRow}>
                                                <View style={styles.tableColPrev}>
                                                    <Text style={styles.tableCell}>
                                                        Commissioni
                                                    </Text>
                                                </View>
                                                <View style={styles.tableColPrev}>
                                                    <Text style={styles.tableCell}>
                                                    </Text>
                                                </View>
                                                <View style={styles.tableColPrev}>
                                                    <Text style={styles.tableCellRight}>
                                                        € {number_format(singleprice.Commissions, 2, ",", ".")}
                                                    </Text>
                                                </View>
                                            </View>
                                        </View>
                                    }
                                    {singleprice.Fees > 0 &&
                                        <View>
                                            <View style={styles.tableRow}>
                                                <View style={styles.tableColPrev}>
                                                    <Text style={styles.tableCell}>
                                                        Diritti
                                                    </Text>
                                                </View>
                                                <View style={styles.tableColPrev}>
                                                    <Text style={styles.tableCell}>
                                                    </Text>
                                                </View>
                                                <View style={styles.tableColPrev}>
                                                    <Text style={styles.tableCellRight}>
                                                        € {number_format(singleprice.Fees, 2, ",", ".")}
                                                    </Text>
                                                </View>
                                            </View>
                                        </View>
                                    }
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableColPrev}>
                                            <Text style={styles.tableCell1}>
                                                Totale preventivo
                                            </Text>
                                        </View>
                                        <View style={styles.tableColPrev}>
                                            <Text style={styles.tableCell1}>
                                            </Text>
                                        </View>
                                        <View style={styles.tableColPrev}>
                                            <Text style={styles.tableCellRight}>
                                                € {number_format(singleprice.TotalPrice, 2, ",", ".")}
                                            </Text>
                                        </View>
                                    </View>
                                    {singleprice.splittingUp !== undefined && !isNaN(singleprice.splittingUp) && Number(singleprice.splittingUp) > 1 &&
                                        <View style={styles.tableRow}>
                                            <View style={styles.tableColPrev}>
                                                <Text style={styles.tableCell1}>
                                                    Numero rate
                                                </Text>
                                            </View>
                                            <View style={styles.tableColPrev}>
                                                <Text style={styles.tableCell1}>
                                                </Text>
                                            </View>
                                            <View style={styles.tableColPrev}>
                                                <Text style={styles.tableCellRight}>
                                                    {singleprice.splittingUp}
                                                </Text>
                                            </View>
                                        </View>
                                    }
                                    {singleprice.splittingUp !== undefined && !isNaN(singleprice.splittingUp) && Number(singleprice.splittingUp) > 1 &&
                                        <>
                                            <View style={styles.tableRow}>
                                                <View style={styles.tableColPrev}>
                                                    <Text style={styles.tableCell1}>
                                                        Importo prima rata
                                                    </Text>
                                                </View>
                                                <View style={styles.tableColPrev}>
                                                    <Text style={styles.tableCell1}>
                                                    </Text>
                                                </View>
                                                <View style={styles.tableColPrev}>
                                                    <Text style={styles.tableCellRight}>
                                                        € {number_format(singleprice.TotalRate, 2, ",", ".")}
                                                    </Text>
                                                </View>
                                            </View>
                                            <View style={styles.tableRow}>
                                                <View style={styles.tableColPrev}>
                                                    <Text style={styles.tableCell1}>
                                                        Importo rate restanti
                                                    </Text>
                                                </View>
                                                <View style={styles.tableColPrev}>
                                                    <Text style={styles.tableCell1}>
                                                    </Text>
                                                </View>
                                                <View style={styles.tableColPrev}>
                                                    <Text style={styles.tableCellRight}>
                                                        € {number_format((singleprice.TotalPrice - parseFloat(singleprice.TotalRate)) / (Number(singleprice.splittingUp) - 1), 2, ",", ".")}
                                                    </Text>
                                                </View>
                                            </View>
                                        </>
                                    }
                                    {false && guarantees_info !== undefined && guarantees_info.map((guarantee) => (
                                        <>
                                            {CheckNote(guarantee, singleprice.listResponseCVT)}
                                        </>
                                    ))
                                    }
                                    {singleprice.messages !== undefined && singleprice.messages.length > 0 &&
                                        <View style={styles.Fascicolo}>
                                            <Text style={styles.LinkText}>
                                                Messaggi
                                            </Text >
                                            {singleprice.messages !== undefined && singleprice.messages.length > 0 &&
                                                <Fragment>
                                                    {singleprice.messages.map((message2print, index) => (
                                                        <Text key={index} style={styles.LinkText}>
                                                            ({index + 1}) {message2print.message}
                                                        </Text>
                                                    ))}
                                                </Fragment>
                                            }
                                        </View>
                                    }
                                    {imageInfo !== null && imageInfo !== false &&
                                        <View style={styles.tableRow}>
                                            <View style={styles.containerImageInfoLeft}>
                                            </View>
                                            <View style={styles.containerImageInfo}>
                                                <Image
                                                    style={styles.imageInfo}
                                                    src={imageInfo}
                                                />
                                            </View>
                                            <View style={styles.containerImageInfoLeft}>
                                            </View>
                                        </View>
                                    }
                                    <View style={styles.Fascicolo}>
                                        <Text style={styles.LinkText}>
                                            Fascicolo informativo
                                        </Text >
                                        {links !== undefined && links !== null && links !== false && links.length > 0 &&
                                            <>
                                                {links.map((linkUrl, index) => (
                                                    <>
                                                        {singleprice.company === linkUrl.factorycode &&
                                                            <Link style={styles.LinkText} src={linkUrl.link}>
                                                                {linkUrl.description !== null && linkUrl.description !== "" &&
                                                                    <>{linkUrl.description}: </>
                                                                }
                                                                {linkUrl.link}
                                                            </Link>
                                                        }
                                                    </>
                                                ))}
                                            </>
                                        }
                                        {QuotationInfo.quotationtype === undefined &&
                                            <>
                                                {singleprice.listResponseCVT.map((cvt, index) => (
                                                    <>
                                                        {(cvt.code === "NETAA" || cvt.code === "NETAB") &&
                                                            <Link style={styles.LinkText} src="https://www.netassistance.it/wp-content/uploads/2023/07/Condizioni-di-abbonamento.pdf">
                                                                https://www.netassistance.it/wp-content/uploads/2023/07/Condizioni-di-abbonamento.pdf
                                                            </Link>
                                                        }
                                                        {cvt.code === "NETAASS" &&
                                                            <Link style={styles.LinkText} src="https://www.autoassist.it/wp-content/uploads/2023/10/Condizione-Autoassist.pdf">
                                                                https://www.autoassist.it/wp-content/uploads/2023/10/Condizione-Autoassist.pdf
                                                            </Link>
                                                        }
                                                    </>
                                                ))}
                                            </>
                                        }
                                        {(QuotationInfo.quotationtype === "house" || QuotationInfo.quotationtype === "tcm" || QuotationInfo.quotationtype === "injuries") &&
                                            <>
                                                {singleprice.listResponseCVT.map((cvt, index) => (
                                                    <>
                                                        {cvt.code === "NETA" &&
                                                            <Link style={styles.LinkText} src="https://www.netassistance.it/wp-content/uploads/2023/07/Condizioni-di-abbonamento.pdf">
                                                                https://www.netassistance.it/wp-content/uploads/2023/07/Condizioni-di-abbonamento.pdf
                                                            </Link>
                                                        }
                                                    </>
                                                ))}
                                            </>
                                        }
                                    </View>
                                    {QuotationInfo.quotationtype === undefined && (QuotationInfo.vehicleInfo.vehicleType === "R" || QuotationInfo.vehicleInfo.vehicleType === "C") &&
                                        <View style={styles.Fascicolo}>
                                            <Fragment>
                                                {singleprice.company === "GA" &&
                                                    <Fragment>
                                                        <Text key={index} style={styles.LinkText}>
                                                            - VEICOLI ASSICURABILI: autocarri/veicoli speciali/rimorchi
                                                        </Text>
                                                        <Text key={index} style={styles.LinkText}>
                                                            - TARIFFA RCA: autocarri b/m - rimorchi Fissa
                                                        </Text>
                                                        <Text key={index} style={styles.LinkText}>
                                                            - MASSIMALE: 13.000.000,00/25.000.000,00
                                                        </Text>
                                                        <Text key={index} style={styles.LinkText}>
                                                            - FRAZIONAMENTO: annuale - semestrale
                                                        </Text>
                                                        <Text key={index} style={styles.LinkText}>
                                                            - USO: conto proprio - conto terzi
                                                        </Text>
                                                        <Text key={index} style={styles.LinkText}>
                                                            - GARANZIE AGGIUNTIVE INCLUSE: rinuncia alla rivalsa - carico/scarico compreso mezzi meccanici - terzi trasportati non addetti - RC personale del trasportato
                                                        </Text>
                                                        <Text key={index} style={styles.LinkText}>
                                                            - ADR: trasporto merci pericolose/infiammabili + 25%
                                                        </Text>
                                                    </Fragment>
                                                }
                                                {singleprice.company === "GEN" &&
                                                    <Fragment>
                                                        <Text key={index} style={styles.LinkText}>
                                                            - VEICOLI ASSICURABILI: autocarri/veicoli speciali/rimorchi
                                                        </Text>
                                                        <Text key={index} style={styles.LinkText}>
                                                            - TARIFFA RCA: autocarri Pejus - rimorchi Fissa
                                                        </Text>
                                                        <Text key={index} style={styles.LinkText}>
                                                            - MASSIMALE: 26.000.000,00 (VENTISEI MILIONI)
                                                        </Text>
                                                        <Text key={index} style={styles.LinkText}>
                                                            - FRAZIONAMENTO: annuale - SCADENZA FISSA: 15 marzo
                                                        </Text>
                                                        <Text key={index} style={styles.LinkText}>
                                                            - USO: conto proprio - conto terzi
                                                        </Text>
                                                        <Text key={index} style={styles.LinkText}>
                                                            - GARANZIE AGGIUNTIVE INCLUSE: rinuncia alla rivalsa - carico/scarico compreso mezzi meccanici - terzi trasportati non addetti - RC personale del trasportato
                                                        </Text>
                                                        <Text key={index} style={styles.LinkText}>
                                                            - ADR: trasporto merci pericolose/infiammabili + 25% - trasporto acidi + 50% - trasporto esplosivi + 100% - trasporto radioattivi R.D.
                                                        </Text>
                                                    </Fragment>
                                                }
                                                {singleprice.company === "LYS" &&
                                                    <Fragment>
                                                        <Text key={index} style={styles.LinkText}>
                                                            - VEICOLI ASSICURABILI: autocarri/veicoli speciali/rimorchi
                                                        </Text>
                                                        <Text key={index} style={styles.LinkText}>
                                                            - MAX VALORE ASSICURABILE: SENZA SATELLITARE: 150.000,00 € - CON SATELLITARE: 250.000 € oltre R.D.
                                                        </Text>
                                                        <Text key={index} style={styles.LinkText}>
                                                            - FRAZIONAMENTO: annuale - semestrale - quadrimestrale - trimestrale
                                                        </Text>
                                                        <Text key={index} style={styles.LinkText}>
                                                            - GARANZIE AGGIUNTIVE INCLUSE: ricorso terzi da incendio - rapina - danni da scasso - danni da circolazione dei ladri
                                                        </Text>
                                                        <Text key={index} style={styles.LinkText}>
                                                            - ATTI VANDALICI compreso: tumulti, scioperi, sabotaggio e terrorismo
                                                        </Text>
                                                        <Text key={index} style={styles.LinkText}>
                                                            - EVENTI NATURALI compreso: alluvioni, frane, gelo e grandine
                                                        </Text>
                                                        <Text key={index} style={styles.LinkText}>
                                                            - INDENNIZZO DANNO PARZIALE: senza degrado per 60 mesi dalla data di prima immatricolazione ad eccezione delle parti meccaniche soggette ad usura
                                                        </Text>
                                                        <Text key={index} style={styles.LinkText}>
                                                            - INDENNIZZO DANNO TOTALE: valore a nuovo per 6 mesi dalla data di prima immatricolazione, successivamente valore commerciale
                                                        </Text>
                                                    </Fragment>
                                                }
                                                {singleprice.company === "MFE" &&
                                                    <Fragment>
                                                        <Text key={index} style={styles.LinkText}>
                                                            - AUTOCARRI fino a 35 q.li (Standard): 30 km per destinazione a scelta - officina mobile max 300,00 Euro - recupero veicolo fuori strada max 800,00 Euro
                                                        </Text>
                                                        <Text key={index} style={styles.LinkText}>
                                                            - AUTOCARRI fino a 35 q.li (Elite): 50 km per destinazione a scelta - officina mobile max 500,00 Euro - recupero veicolo fuori strada max 1.500,00 Euro
                                                        </Text>
                                                        <Text key={index} style={styles.LinkText}>
                                                            - AUTOCARRI tra 35 e 359 q.li (Classic): 50 km per destinazione a scelta - officina mobile max 600,00 Euro - recupero veicolo fuori strada max 2,000,00 Euro
                                                        </Text>
                                                        <Text key={index} style={styles.LinkText}>
                                                            - AUTOCARRI oltre 360 q.li (Estesa): 50 km per destinazione a scelta - officina mobile max 1.000,00 Euro - recupero veicolo fuori strada max 6.500,00 Euro
                                                        </Text>
                                                    </Fragment>
                                                }
                                            </Fragment>
                                        </View>
                                    }

                                    {props.row.status !== 3 && props.orderModule &&
                                        <>
                                            <View style={styles.Fascicolo}>
                                                <Text style={styles.LinkText}>
                                                    Adeguatezza della proposta:
                                                </Text >
                                            </View>
                                            <View style={styles.Fascicolo2}>
                                                <Text style={styles.LinkText2}>
                                                    L'intermediario proponente dichiara di aver acquisito in modalità' documentabile le informazioni necessarie a valutare le esigenze assicurative del cliente e di ritenere la proposta adeguata alle esigenze assicurative del contraente.
                                                </Text>
                                            </View>
                                        </>
                                    }
                                </View>
                            </View>
                        }
                    </>
                ))}
            </View>
            <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                `${pageNumber} / ${totalPages}`
            )} fixed />
            <Text style={styles.QuotiamoFooter} fixed >
                {props.row.active_printfooter}
            </Text>
        </Page>
    }

    const HeaderDocument = () => (
        <View style={styles.tableHeader}>
            <View style={styles.tableRowHeader}>
                <View style={styles.tableColHeader}>
                    {props.DisplayImg && props.row.active_image !== null && props.row.active_image !== "" &&
                        <Image
                            style={styles.image}
                            src={props.row.active_image}
                        />}
                </View>
                <View style={styles.tableColHeader}>
                    {props.row.active_businessname !== null && props.row.active_businessname !== "" &&
                        <Text style={styles.textHeaderOrg} fixed>
                            {props.row.active_businessname}
                        </Text>
                    }
                    {props.row.active_address !== null && props.row.active_address !== "" &&
                        <>
                            <Text style={styles.textHeaderOrg} fixed>
                                {props.row.active_toponym} {props.row.active_address}, {props.row.active_civicnumber}
                            </Text>
                            <Text style={styles.textHeaderOrg} fixed>
                                {props.row.active_cap} {props.row.active_city} {props.row.active_province}
                            </Text>
                        </>
                    }
                    {props.row.active_vatnumber !== null && props.row.active_vatnumber !== "" &&
                        <Text style={styles.textHeaderOrg} fixed>
                            P.IVA {props.row.active_vatnumber}
                        </Text>
                    }
                    {props.row.active_fiscalcode !== null && props.row.active_fiscalcode !== "" &&
                        <Text style={styles.textHeaderOrg} fixed>
                            CF {props.row.active_fiscalcode}
                        </Text>
                    }
                    {props.row.active_rui !== null && props.row.active_rui !== "" &&
                        <Text style={styles.textHeaderOrg} fixed>
                            RUI {props.row.active_rui}
                            {moment(props.row.active_registrationdate).isValid() && "del " + moment(props.row.active_registrationdate, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")}
                        </Text>
                    }
                    <Text style={styles.textHeaderOrg} fixed>
                        Punto vendita: {props.row.agdescr}
                    </Text>
                    <Text style={styles.textHeaderOrg} fixed>
                        Emesso da: {props.row.name} {props.row.surname}
                    </Text>
                    <Text style={styles.textHeaderOrg} fixed>
                        Email: {props.row.email}
                    </Text>
                    {ReturnCodiceCommerciale(props.row) !== "" &&
                        <Text style={styles.textHeaderOrg} fixed>
                            Codice Commerciale: {ReturnCodiceCommerciale(props.row)}
                        </Text>
                    }
                    {props.row.loginwith810 === 1 &&
                        <Text style={styles.textHeaderOrg} fixed>
                            Utente: {props.row.user}
                        </Text>
                    }
                </View>
                <View style={styles.header}>
                    <Text style={styles.textHeader} fixed>
                        {props.row.status === 3 && props.orderModule &&
                            "PROPOSTA "
                        }
                        {props.row.status !== 3 && props.orderModule && "ORDINE FERMO DI COPERTURA "}
                        PREVENTIVO NR. {props.row.id}
                    </Text>
                    {props.row.expired_date === null &&
                        <Text style={styles.textHeader} fixed>
                            Emesso il {moment(props.row.created_at, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")}
                            {props.orderModule === false &&
                                <>
                                    &nbsp;Scadenza {moment(props.row.created_at, "YYYY-MM-DD HH:mm:ss").add(7, "days").format("DD/MM/YYYY")}
                                </>
                            }
                        </Text>
                    }
                    {props.row.expired_date !== null &&
                        <Text style={styles.textHeader} fixed>
                            Emesso il {moment(props.row.created_at, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")}
                            {props.orderModule === false &&
                                <>
                                    &nbsp;Scadenza {moment(props.row.expired_date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")}
                                </>
                            }
                        </Text>
                    }
                    <Text style={styles.textHeader} fixed>
                        Decorrenza polizza {moment(QuotationInfo.clientinfo.effectiveDate !== undefined ? QuotationInfo.clientinfo.effectiveDate : QuotationInfo.clientinfo.dataEffetto, "YYYY-MM-DD").format("DD/MM/YYYY")}
                    </Text>
                    {props.row.status === 1 && props.orderModule &&
                        <>
                            <Text style={styles.textHeaderSmall} fixed>
                                {props.row.payment_detail}
                            </Text>
                        </>
                    }
                    {props.row.status === 3 && props.orderModule &&
                        <>
                            {props.row.id_salvataggio === null &&
                                <Text style={styles.textHeaderSmall} fixed>
                                    Premio da confermare
                                </Text>
                            }
                            {props.row.id_salvataggio !== null &&
                                <Text style={styles.textHeaderSmall} fixed>
                                    Premio da confermare - {props.row.payment_detail}
                                </Text>
                            }
                        </>
                    }
                </View>
            </View>
        </View>
    );

    const MyDocument = () => (
        <Document>
            <Page size="A4" style={styles.body}>
                <HeaderDocument />
                {DetailPerson(QuotationInfo.clientinfo.contractorInfo, QuotationInfo, 'DATI CONTRAENTE', true, QuotationInfo.quotationtype)}
                {QuotationInfo.clientinfo.ownerInfo !== undefined &&
                    (!(
                        (QuotationInfo.clientinfo.contractorInfo.fiscalCode !== '' && QuotationInfo.clientinfo.ownerInfo.fiscalCode === QuotationInfo.clientinfo.contractorInfo.fiscalCode) ||
                        (QuotationInfo.clientinfo.contractorInfo.vatNumber !== '' && QuotationInfo.clientinfo.ownerInfo.vatNumber === QuotationInfo.clientinfo.contractorInfo.vatNumber))
                    )
                    &&
                    DetailPerson(QuotationInfo.clientinfo.ownerInfo, QuotationInfo, 'DATI INTESTATARIO', false, QuotationInfo.quotationtype)
                }

                {QuotationInfo.quotationtype !== undefined && QuotationInfo.quotationtype === "gf" &&
                    DetailPersonAmministratore(QuotationInfo.clientinfo.amministratoreInfo, QuotationInfo, 'DATI AMMINISTRATORE', false, QuotationInfo.quotationtype)
                }

                {QuotationInfo.quotationtype !== undefined && QuotationInfo.quotationtype === "house" &&
                    <>
                        {HouseDetails(QuotationInfo, props, QuotationPrice)}
                    </>
                }

                {QuotationInfo.quotationtype !== undefined && QuotationInfo.quotationtype === "gf" &&
                    <>
                        {GfDetails(QuotationInfo, props, QuotationPrice)}
                    </>
                }

                {QuotationInfo.quotationtype !== undefined && (QuotationInfo.quotationtype === "injuries" || QuotationInfo.quotationtype === "tcm" || QuotationInfo.quotationtype === "rcp") &&
                    <>
                        {InjuriesDetails(QuotationInfo, props, QuotationPrice)}
                    </>
                }

                {QuotationInfo.vehicleInfo !== undefined &&
                    <>
                        {CarDetails(QuotationInfo)}
                    </>
                }

                {userData.id_template === null && props.row.status === 1 && props.orderModule &&
                    <>
                        <Text style={styles.subtitle}>
                            DETTAGLI PAGAMENTO
                        </Text>

                        <View style={styles.table}>
                            <View style={styles.tableRow}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell1}>
                                        Tipo
                                    </Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {props.row.payment_detail}
                                    </Text>
                                </View>
                                <View style={styles.tableCol}>

                                    <Text style={styles.tableCell1}>
                                        Importo
                                    </Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        € {number_format(props.row.payment_import, 2, ",", ".")}
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </>
                }

                <View>
                    {props.DisplayImg && props.qrcode !== "" &&
                        <Image
                            style={styles.imageqr}
                            src={props.qrcode}
                        />}
                </View>

                <Text style={styles.QuotiamoFooter} fixed >
                    {props.row.active_printfooter}
                </Text>
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed />
            </Page>

            {QuotationInfo.vehicleInfo !== undefined &&
                <Page size="A4" style={styles.body}>
                    <HeaderDocument />
                    <Text style={styles.subtitle}>
                        ATTESTAZIONE DELLO STATO DEL RISCHIO
                    </Text>
                    <View style={styles.table}>

                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>
                                    Compagnia di provenienza
                                </Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {QuotationInfo.clientinfo.insuranceCompany}
                                </Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>
                                    Tipologia di tariffa
                                </Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {QuotationInfo.clientinfo.declarationOrigin}
                                </Text>
                            </View>
                        </View>

                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>
                                    CU di provenienza
                                </Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {QuotationInfo.clientinfo.cuOrigin}
                                </Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>
                                    CU di assegnazione
                                </Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {QuotationInfo.clientinfo.cuAssignment}
                                </Text>
                            </View>
                        </View>

                        <View style={styles.tableRow}>

                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>
                                    Anni in classe 1
                                </Text>
                            </View>
                            <View style={styles.tableCol}>
                                {Number(QuotationInfo.clientinfo.cuOrigin) === 1 &&
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.insuranceYearsCU1}
                                    </Text>
                                }
                            </View>


                            <View style={styles.tableCol}>

                                <Text style={styles.tableCell1}>
                                    Premio attuale
                                </Text>

                            </View>
                            <View style={styles.tableCol}>
                                {QuotationInfo.clientinfo.currentAward !== undefined && QuotationInfo.clientinfo.currentAward !== "" && Number(QuotationInfo.clientinfo.currentAward) > 0 &&
                                    <Text style={styles.tableCell}>
                                        € {number_format(QuotationInfo.clientinfo.currentAward, 2, ",", ".")}
                                    </Text>
                                }
                            </View>

                        </View>


                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>
                                    Hai già usufruito della legge Bersani?
                                </Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {QuotationInfo.clientinfo.usedBersani ? "SI" : "NO"}
                                </Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell1}>
                                    Da quanti anni sei assicurato?
                                </Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                    {QuotationInfo.clientinfo.insuranceYears}
                                </Text>
                            </View>
                        </View>

                    </View>
                    <Text style={styles.subtitle}>
                        DETTAGLIO SINISTRI ATTESTATO
                    </Text>
                    {QuotationInfo.clientinfo.dataATRC !== null &&
                        <View style={styles.table}>
                            <View style={styles.tableRow}>
                                <View style={styles.tableColDescrAtrc}>
                                    <Text style={styles.tableCell}>
                                        Tipo sinistri
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {Number(QuotationInfo.clientinfo.dataATRC.anno0Atrc) - 10}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {Number(QuotationInfo.clientinfo.dataATRC.anno0Atrc) - 9}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {Number(QuotationInfo.clientinfo.dataATRC.anno0Atrc) - 8}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {Number(QuotationInfo.clientinfo.dataATRC.anno0Atrc) - 7}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {Number(QuotationInfo.clientinfo.dataATRC.anno0Atrc) - 6}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {Number(QuotationInfo.clientinfo.dataATRC.anno0Atrc) - 5}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {Number(QuotationInfo.clientinfo.dataATRC.anno0Atrc) - 4}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {Number(QuotationInfo.clientinfo.dataATRC.anno0Atrc) - 3}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {Number(QuotationInfo.clientinfo.dataATRC.anno0Atrc) - 2}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {Number(QuotationInfo.clientinfo.dataATRC.anno0Atrc) - 1}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.anno0Atrc}
                                    </Text>
                                </View>
                            </View>

                            <View style={styles.tableRow}>
                                <View style={styles.tableColDescrAtrc}>
                                    <Text style={styles.tableCell}>
                                        Sinistri con responsabilità principale
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPrincAnno10}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPrincAnno9}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPrincAnno8}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPrincAnno7}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPrincAnno6}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPrincAnno5}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPrincAnno4}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPrincAnno3}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPrincAnno2}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPrincAnno1}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPrincAnno0}
                                    </Text>
                                </View>
                            </View>

                            <View style={styles.tableRow}>
                                <View style={styles.tableColDescrAtrc}>
                                    <Text style={styles.tableCellRight}>
                                        di cui con danni a: COSE
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniCoseAnno10}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniCoseAnno9}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniCoseAnno8}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniCoseAnno7}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniCoseAnno6}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniCoseAnno5}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniCoseAnno4}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniCoseAnno3}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniCoseAnno2}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniCoseAnno1}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniCoseAnno0}
                                    </Text>
                                </View>
                            </View>

                            <View style={styles.tableRow}>
                                <View style={styles.tableColDescrAtrc}>
                                    <Text style={styles.tableCellRight}>
                                        di cui con danni a: PERSONE
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniPersAnno10}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniPersAnno9}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniPersAnno8}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniPersAnno7}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniPersAnno6}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniPersAnno5}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniPersAnno4}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniPersAnno3}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniPersAnno2}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniPersAnno1}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniPersAnno0}
                                    </Text>
                                </View>
                            </View>

                            <View style={styles.tableRow}>
                                <View style={styles.tableColDescrAtrc}>
                                    <Text style={styles.tableCellRight}>
                                        di cui con danni a: MISTI
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniMistiAnno10}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniMistiAnno9}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniMistiAnno8}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniMistiAnno7}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniMistiAnno6}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniMistiAnno5}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniMistiAnno4}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniMistiAnno3}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniMistiAnno2}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniMistiAnno1}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPrincDanniMistiAnno0}
                                    </Text>
                                </View>
                            </View>





                            <View style={styles.tableRow}>
                                <View style={styles.tableColDescrAtrc}>
                                    <Text style={styles.tableCell}>
                                        Sinistri con responsabilità paritaria
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPariAnno10}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPariAnno9}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPariAnno8}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPariAnno7}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPariAnno6}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPariAnno5}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPariAnno4}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPariAnno3}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPariAnno2}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPariAnno1}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPariAnno0}
                                    </Text>
                                </View>
                            </View>

                            <View style={styles.tableRow}>
                                <View style={styles.tableColDescrAtrc}>
                                    <Text style={styles.tableCellRight}>
                                        di cui con danni a: COSE
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniCoseAnno10}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniCoseAnno9}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniCoseAnno8}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniCoseAnno7}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniCoseAnno6}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniCoseAnno5}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniCoseAnno4}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniCoseAnno3}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniCoseAnno2}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniCoseAnno1}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniCoseAnno0}
                                    </Text>
                                </View>
                            </View>

                            <View style={styles.tableRow}>
                                <View style={styles.tableColDescrAtrc}>
                                    <Text style={styles.tableCellRight}>
                                        di cui con danni a: PERSONE
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniPersAnno10}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniPersAnno9}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniPersAnno8}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniPersAnno7}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniPersAnno6}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniPersAnno5}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniPersAnno4}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniPersAnno3}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniPersAnno2}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniPersAnno1}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniPersAnno0}
                                    </Text>
                                </View>
                            </View>

                            <View style={styles.tableRow}>
                                <View style={styles.tableColDescrAtrc}>
                                    <Text style={styles.tableCellRight}>
                                        di cui con danni a: MISTI
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniMistiAnno10}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniMistiAnno9}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniMistiAnno8}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniMistiAnno7}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniMistiAnno6}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniMistiAnno5}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniMistiAnno4}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniMistiAnno3}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniMistiAnno2}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniMistiAnno1}
                                    </Text>
                                </View>
                                <View style={styles.tableColAtrc}>
                                    <Text style={styles.tableCell}>
                                        {QuotationInfo.clientinfo.dataATRC.atrcRespPariDanniMistiAnno0}
                                    </Text>
                                </View>
                            </View>

                        </View>
                    }

                    {QuotationInfo.clientinfo.listDataCARD !== null && QuotationInfo.clientinfo.listDataCARD.length > 0 &&
                        <Fragment>
                            <Text style={styles.subtitle}>
                                Elenco CARD
                            </Text>
                            <View style={styles.table}>

                                <View style={styles.tableRow}>
                                    <View style={styles.tableColCard1}>
                                        <Text style={styles.tableCell}>CARD</Text>
                                    </View>

                                    <View style={styles.tableColCard}>
                                        <Text style={styles.tableCell}>Sinistro</Text>
                                    </View>

                                    <View style={styles.tableColCard}>
                                        <Text style={styles.tableCell}>Accadimento</Text>
                                    </View>

                                    <View style={styles.tableColCard}>
                                        <Text style={styles.tableCell}>Aggiornamento</Text>
                                    </View>

                                    <View style={styles.tableColCard}>
                                        <Text style={styles.tableCell}>Ultima stanza</Text>
                                    </View>

                                    <View style={styles.tableColCard}>
                                        <Text style={styles.tableCell}>Debitrice</Text>
                                    </View>

                                    <View style={styles.tableColCard}>
                                        <Text style={styles.tableCell}>Gestionaria</Text>
                                    </View>

                                    <View style={styles.tableColCard}>
                                        <Text style={styles.tableCell}>Stato</Text>
                                    </View>
                                </View>
                                {QuotationInfo.clientinfo.listDataCARD.map((card, index) => (
                                    <View key={index} style={styles.tableRow}>
                                        <View style={styles.tableColCard1}>
                                            <Text style={styles.tableCell}>{card.vehicleDebtorId === QuotationInfo.vehicleInfo.licensePlate ? "PASSIVO" : "ATTIVO"}</Text>
                                        </View>

                                        <View style={styles.tableColCard}>
                                            <Text style={styles.tableCell}>{card.sinisterNumber}</Text>
                                        </View>
                                        <View style={styles.tableColCard}>
                                            <Text style={styles.tableCell}>{moment(card.occuranceDate, "YYYY-MM-DD").format("DD/MM/YYYY")}</Text>
                                        </View>

                                        <View style={styles.tableColCard}>
                                            <Text style={styles.tableCell}>{moment(card.updateDate, "YYYY-MM-DD").format("DD/MM/YYYY")}</Text>
                                        </View>

                                        <View style={styles.tableColCard}>
                                            <Text style={styles.tableCell}>{moment(card.lastRoomDate, "YYYY-MM-DD").format("DD/MM/YYYY")}</Text>
                                        </View>
                                        <View style={styles.tableColCard}>
                                            <Text style={styles.tableCell}>{card.debtorCompany} </Text>
                                            <Text style={styles.tableCell}>{card.vehicleDebtorType} </Text>
                                            <Text style={styles.tableCell}>{card.vehicleManagementIdFormat} </Text>
                                            <Text style={styles.tableCell}>{card.vehicleDebtorId}</Text>
                                        </View>
                                        <View style={styles.tableColCard}>
                                            <Text style={styles.tableCell}>{card.managementCompany}</Text>
                                            <Text style={styles.tableCell}>{card.vehicleManagementType}</Text>
                                            <Text style={styles.tableCell}>{card.vehicleManagementIdFormat}</Text>
                                            <Text style={styles.tableCell}>{card.vehicleManagementId}</Text>
                                        </View>
                                        <View style={styles.tableColCard}>
                                            <Text style={styles.tableCell}>{card.sinisterStatus}</Text>
                                        </View>

                                    </View>
                                ))}
                            </View>
                        </Fragment>
                    }
                    {!(props.orderModule && props.QuotationSelect) &&

                        <>
                            <Text style={styles.subtitle}>
                                RIEPILOGO QUOTAZIONI
                            </Text>
                            <View style={styles.table}>
                                {QuotationPrice.map((ListPrice) =>
                                (
                                    <>
                                        {ListPrice.datipre !== null && ListPrice.datipre !== undefined && ListPrice.datipre.prices !== undefined &&
                                            <>
                                                {ListPrice.datipre.prices.map((singleprice, index) => (
                                                    <>
                                                        {singleprice.nrQuotation !== "" &&
                                                            <View key={index} style={styles.tableRow}>
                                                                <View style={styles.tableCol2}>
                                                                    {props.DisplayImg && returnImage(props.factories, ListPrice.company.fac) !== "" &&
                                                                        <>
                                                                            {ListPrice.company.hub !== undefined && ListPrice.company.hub !== null && ListPrice.company.hub === 1 &&
                                                                                <Image
                                                                                    style={styles.imageCompanySmall}
                                                                                    src={returnImageHub(props.factoriesHub, ListPrice.company.facid, singleprice.slug) !== "" ? returnImageHub(props.factoriesHub, ListPrice.company.facid, singleprice.slug) : returnImage(props.factories, ListPrice.company.fac)}
                                                                                />
                                                                            }
                                                                            {(ListPrice.company.hub === undefined || ListPrice.company.hub === null || ListPrice.company.hub === 0) &&
                                                                                <Image
                                                                                    style={styles.imageCompanySmall}
                                                                                    src={returnImage(props.factories, ListPrice.company.fac)}
                                                                                />
                                                                            }
                                                                        </>
                                                                    }
                                                                    {(props.DisplayImg === false || returnImage(props.factories, ListPrice.company.fac) === "") &&
                                                                        <Text style={styles.CompanyName}>
                                                                            {ListPrice.company.fac}
                                                                        </Text>
                                                                    }
                                                                </View>

                                                                <View style={styles.tableCol4}>
                                                                    {ListPrice.company.sfo_description !== undefined &&
                                                                        <Text style={styles.DescriptionSfo}>
                                                                            {ListPrice.company.sfo_description}
                                                                        </Text>
                                                                    }
                                                                </View>
                                                                <View style={styles.tableCol3}>
                                                                    <Text style={styles.tableCellCompany}>
                                                                        {singleprice.description}
                                                                    </Text>
                                                                </View>

                                                                <View style={styles.tableCol5}>
                                                                    <Text style={styles.tableCellRight2}>
                                                                        € {number_format(singleprice.TotalPrice, 2, ",", ".")}
                                                                    </Text>
                                                                </View>
                                                            </View>
                                                        }
                                                    </>
                                                ))}
                                            </>
                                        }
                                    </>
                                ))}
                            </View>
                        </>
                    }

                    <Text style={styles.QuotiamoFooter} fixed >
                        {props.row.active_printfooter}
                    </Text>
                    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} fixed />
                </Page>
            }

            {!(props.orderModule && props.QuotationSelect) && QuotationPrice.map((ListPrice, index) =>
            (
                <Fragment key={index}>
                    {ListPrice.datipre !== null && ListPrice.datipre !== undefined && ListPrice.datipre.prices !== undefined && DetailQuotation(returnImage(props.factories, ListPrice.company.fac), ListPrice.datipre.prices, ListPrice.company.fac, ListPrice.company.sfo_description, ListPrice.company.guarantees_info, CheckInfoImage(ListPrice.company.facid, props.factoriesInfoImage), props.links)}
                </Fragment>
            ))}

            {props.orderModule && props.QuotationSelect && (props.QuotationAdd === undefined || props.QuotationAdd === null || props.QuotationAdd === false) &&
                <Fragment>
                    {QuotationPrice.map((Quote, index) => (
                        <Fragment key={index}>
                            {Quote.datipre !== null && Quote.datipre !== undefined && Quote.datipre.prices !== undefined &&
                                <Fragment>
                                    {Quote.datipre.prices.map((singlePrice, index2) => (
                                        <>
                                            {singlePrice.nrQuotation !== "" &&
                                                <Fragment key={index2}>
                                                    {singlePrice.nrQuotation === props.QuotationSelect.nrQuotation && singlePrice.company === props.QuotationSelect.company &&
                                                        <Fragment>
                                                            {Quote.company.hub !== undefined && Quote.company.hub !== null && Quote.company.hub === 1 &&
                                                                <>
                                                                    {DetailQuotation(returnImageHub(props.factoriesHub, Quote.company.facid, props.QuotationSelect.slug) !== "" ? returnImageHub(props.factoriesHub, Quote.company.facid, props.QuotationSelect.slug) : returnImage(props.factories, Quote.company.fac),
                                                                        [props.QuotationSelect], props.QuotationSelect.company, Quote.company.sfo_description, Quote.company.guarantees_info,
                                                                        CheckInfoImage(Quote.company.facid, props.factoriesInfoImage), props.links)}
                                                                </>
                                                            }
                                                            {(Quote.company.hub === undefined || Quote.company.hub === null || Quote.company.hub === 0) &&
                                                                <>
                                                                    {DetailQuotation(returnImage(props.factories, Quote.company.fac), [props.QuotationSelect], props.QuotationSelect.company, Quote.company.sfo_description, Quote.company.guarantees_info, CheckInfoImage(Quote.company.facid, props.factoriesInfoImage), props.links)}
                                                                </>
                                                            }
                                                        </Fragment>
                                                    }
                                                </Fragment>
                                            }
                                        </>
                                    ))
                                    }
                                </Fragment>
                            }
                        </Fragment>
                    ))}
                </Fragment>
            }

            {props.orderModule && props.QuotationSelect && props.QuotationAdd !== undefined && props.QuotationAdd !== null && props.QuotationAdd !== false && props.QuotationAdd.length > 0 &&
                <>
                    {props.QuotationAdd.map((QuoteAdd, index) => (
                        <Fragment>
                            {QuotationPrice.map((Quote, index) => (
                                <Fragment key={index}>
                                    {Quote.datipre !== null && Quote.datipre !== undefined && Quote.datipre.prices !== undefined &&
                                        <Fragment>
                                            {Quote.datipre.prices.map((singlePrice, index2) => (
                                                <>
                                                    {singlePrice.nrQuotation !== "" &&
                                                        <Fragment key={index2}>
                                                            {singlePrice.nrQuotation === QuoteAdd.nrQuotation && singlePrice.company === QuoteAdd.company &&
                                                                <Fragment>
                                                                    {Quote.company.hub !== undefined && Quote.company.hub !== null && Quote.company.hub === 1 &&
                                                                        <>
                                                                            {DetailQuotation(returnImageHub(props.factoriesHub, Quote.company.facid, QuoteAdd.slug) !== "" ? returnImageHub(props.factoriesHub, Quote.company.facid, QuoteAdd.slug) : returnImage(props.factories, Quote.company.fac),
                                                                                [QuoteAdd], QuoteAdd.company, Quote.company.sfo_description, Quote.company.guarantees_info,
                                                                                CheckInfoImage(Quote.company.facid, props.factoriesInfoImage), props.links)}
                                                                        </>
                                                                    }
                                                                    {(Quote.company.hub === undefined || Quote.company.hub === null || Quote.company.hub === 0) &&
                                                                        <>
                                                                            {DetailQuotation(returnImage(props.factories, Quote.company.fac), [QuoteAdd], QuoteAdd.company, Quote.company.sfo_description, Quote.company.guarantees_info, CheckInfoImage(Quote.company.facid, props.factoriesInfoImage), props.links)}
                                                                        </>
                                                                    }
                                                                </Fragment>
                                                            }
                                                        </Fragment>
                                                    }
                                                </>
                                            ))
                                            }
                                        </Fragment>
                                    }
                                </Fragment>
                            ))}
                        </Fragment>
                    ))}
                </>
            }

            {props.DisplayImg && props.Images.map((single, index) => (
                <Page key={index} size="A4" style={styles.body}>
                    <HeaderDocument />
                    <View>
                        {props.DisplayImg && single.image !== "" && single.image !== undefined &&
                            <Image
                                style={styles.imageInforma}
                                src={single.image}
                            />
                        }
                        {single.link !== null && single.link !== undefined && single.link !== "" &&
                            <>
                                <Text style={styles.LinkText}>
                                    Dettaglio analisi Esperto Informa:
                                </Text >
                                <Link style={styles.LinkText} src={single.link}>
                                    {single.link}
                                </Link>
                            </>
                        }
                    </View>
                    <Text style={styles.QuotiamoFooter} fixed>
                        {props.row.active_printfooter}
                    </Text>
                    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} fixed />
                </Page>
            ))}

            {QuotationInfo.clientinfo.questionario !== null && QuotationInfo.clientinfo.questionario !== undefined &&
                <Page size="A4" style={styles.body}>
                    <HeaderDocument />
                    <View>
                        {QuotationInfo.quotationtype === "injuries" && QuotationInfo.clientinfo.questionario.questionarioInfortunio !== undefined && QuotationInfo.clientinfo.questionario.questionarioInfortunio.inf_domanda_1 !== "" &&
                            <>
                                <Text style={styles.subtitle}>
                                    QUESTIONARIO INFORTUNIO
                                </Text>
                                <View style={styles.table}>
                                    {QuestionarioDetails(QuotationInfo.clientinfo.questionario.questionarioInfortunio, domandeQuestionarioInfortunio)}
                                </View>
                            </>
                        }
                        {QuotationInfo.quotationtype === "injuries" && QuotationInfo.clientinfo.questionario.questionarioMalattia !== undefined && QuotationInfo.clientinfo.questionario.questionarioMalattia.mal_domanda_1 !== "" &&
                            <>
                                <Text style={styles.subtitle}>
                                    QUESTIONARIO MALATTIA
                                </Text>
                                <View style={styles.table}>
                                    {QuestionarioDetails(QuotationInfo.clientinfo.questionario.questionarioMalattia, domandeQuestionarioMalattia)}
                                </View>
                            </>
                        }
                        {QuotationInfo.quotationtype === "tcm" && QuotationInfo.clientinfo.questionario.questionarioTcm1.tcm_domanda_1 !== "" &&
                            <>
                                <Text style={styles.subtitle}>
                                    Dichiarazione dell'assicurato
                                </Text>
                                <View style={styles.table}>
                                    {QuestionarioDetails(QuotationInfo.clientinfo.questionario.questionarioTcm1, domandeQuestionarioTcm1)}
                                </View>
                            </>
                        }
                        {QuotationInfo.quotationtype === "tcm" && QuotationInfo.clientinfo.questionario.questionarioTcm2.tcm_domanda_1 !== "" &&
                            <>
                                <Text style={styles.subtitle}>
                                    Dichiarazione professionale, sportiva e sanitaria dell'assicurato
                                </Text>
                                <View style={styles.table}>
                                    {QuestionarioDetails(QuotationInfo.clientinfo.questionario.questionarioTcm2, domandeQuestionarioTcm2)}
                                </View>
                            </>
                        }
                        {QuotationInfo.quotationtype === "gf" && QuotationInfo.clientinfo.questionario.questionarioGf.gf_domanda_1 !== "" &&
                            <>
                                <Text style={styles.subtitle}>
                                    Questionario dichiarativo
                                </Text>
                                <View style={styles.table}>
                                    {QuestionarioDetails(QuotationInfo.clientinfo.questionario.questionarioGf, domandeQuestionarioGf)}
                                </View>
                            </>
                        }
                    </View>
                    <Text style={styles.QuotiamoFooter} fixed >
                        {props.row.active_printfooter}
                    </Text>
                    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} fixed />
                </Page>
            }
        </Document>
    );

    return (<MyDocument />)
}

