import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { PosDataContext } from '../containers/logincontext';
import axios from 'axios';
import Auth from '../auth/auth';
import AuthService from '../auth/authservice';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { red } from '@material-ui/core/colors';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MuiAlert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import BackGround from './background';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import validator from 'validator';
import Container from '@material-ui/core/Container';
import Snackbar from '@material-ui/core/Snackbar';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Link from '@material-ui/core/Link';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { getProfessions, parseFloat2Decimals, number_format } from './utils';
import SubjectDetails from './subjectDetails';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Checkbox from '@material-ui/core/Checkbox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { KeyboardDatePicker } from "@material-ui/pickers";
import Paper from '@material-ui/core/Paper';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CheckSvgButton from './checkboxsvg';
import { Card, CardMedia } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import Carousel from 'react-material-ui-carousel';
import QuotationDetails from './quotationdetails';
import QuotationSingleDetail from './quotationsingledetail';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import {
  NAME_APP,
  DAYS_BEFORE_QUOTATION,
  useTypes,
  tipoAlimentazione,
  ElencoMassimaliAutocarri,
  tipoFrazionamento,
} from '../config/config';
import {
  API_URL_GET_INFOBIRTH,
  API_URL_GET_INFOCITYBYCITY,
  API_URL_ALLGUARANTEES,
  API_URL_INFOIMAGES_ALL,
  API_URL_LIST_AGENCIES_USER,
  APP_TOKEN_QUOTIAMO,
  API_URL_SICANIA_LIST,
  API_URL_SERVICE_SICANIA,
  API_URL_SERVICE_DATA_SICANIA,
  API_URL_GET_MODEL,
  API_URL_GET_BRAND,
  API_URL_GET_OUTFITTING,
  API_URL_FACTORIES_LIST,
  REGISTRY_URL,
  API_URL_LIST_SERVICES_AGENCY_USER,
  API_URL_QUOTATION_LIST,
  API_URL_QUOTATION,
} from '../config/config';

const useStyles = makeStyles((theme) => ({
  BoxContainer: {
    padding: "10px"
  },
  fulldiv: {
    width: "-webkit-fill-available"
  },
  FirstDataRow: {
    marginTop: "20px",
    marginBottom: "0px",
  },
  DetailWarranty: {
    marginTop: "5px",
    fontSize: "12px",
    fontWeight: "400"

  },
  SubTitle: {
    marginTop: "20px",
    fontSize: "16px",
    fontWeight: "600"
  },
  button: {
    height: 30
  },
  Carousel: {
    width: "100%"
  },
  Media: {
    height: 800
  },
  Media2: {
    height: 900
  },
  Carousel2: {
    width: "50%"
  },
  TitleCategory: {
    marginTop: "10px",
    fontSize: "16px",
    fontWeight: "600"
  },
  PaddingTop: {
    paddingTop: 30,
  },
  cellroot: {
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  prezzo: {
    align: 'right'
  },
  titleTable: {
    flex: '1 1 100%',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  linear_class: {
    width: '20%',
    '& > * + *': {
      marginTop: theme.spacing(2),
      zIndex: theme.zIndex.drawer + 2,
    },
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  root: {
    width: '90%'
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    alignItems: 'center',
    flexDirection: 'column',
  },
  preventivo: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 800,
  },
  image: {
    width: 180,
    height: 60,
  },
  imgLogo: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '50px',
    marginBottom: '40px'
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  fixedHeight: {
    height: 240,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  pos: {
    marginBottom: 12,
  },
  containerButton: {
    alignItems: 'right',
    textAlign: 'right',
    marginBottom: '10px',
    marginTop: '10px'
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
    alignItems: 'center'
  },
  formControl: {
    width: '100%'
  },
  progress: {
    display: "none"
  },
  iconRoot: {
    color: red
  },
  linearProgressDiv: {
    width: '90%'
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function TextFieldCustomMassimale(props) {
  return (
    <TextField label="Massimale" variant="outlined" {...props} />
  );
}

const drawerWidth = 240;

function getSteps() {
  return ['Targa', 'Anagrafiche', 'Veicolo', 'Situazione', 'ARD/CVT', 'Preventivo', 'Modifica/Conferma'];
}

const getInfoBirth = async (token, url, cf, nome, cognome) => {
  try {
    const result = await axios.get(url + API_URL_GET_INFOBIRTH, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      params: {
        cf: cf,
        nome: nome,
        cognome: cognome
      }
    });
    return result;
  } catch (e) {
    return Promise.reject(e.response);
  }
};

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const getListOfCity = async (token, url, search) => {
  try {
    const result = await axios.get(url + API_URL_GET_INFOCITYBYCITY, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      params: {
        city: search
      }
    });
    return result;
  } catch (e) {
    return Promise.reject(e.response);
  }
};

const checkSicAniaData = async (tokenApp, pos) => {
  try {
    const result = await axios.get(API_URL_SICANIA_LIST + "/" + pos, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + tokenApp,
      }
    });
    return result;
  } catch (e) {
    return Promise.reject(e.response);
  }
};

const checkSicAnia = async (type, targa, token, url, username, password, code, insuranceStatus, onlyATRC, extraFiscalCode, sharetoken) => {
  const json = JSON.stringify({
    username: username,
    password: password,
    licensePlate: targa,
    authority: code,
    onlyATRC: onlyATRC,
    insuranceStatus: insuranceStatus,
    applicationToken: APP_TOKEN_QUOTIAMO,
    extraFiscalCode: extraFiscalCode
  }
  );
  let callurl = url + API_URL_SERVICE_SICANIA + "/" + type + "/sicania";

  if (type === "share") {
    callurl = url + API_URL_SERVICE_DATA_SICANIA + "/" + type + "/" + sharetoken;
  }
  try {
    const result = await axios.post(callurl, json, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      }
    });
    return result;
  } catch (e) {
    return Promise.reject(e.response);
  }
};

function ReturnDefaultinsuranceYears(posinfo, datiania, drivingLicenceYear) {
  let defaultret = "";
  var RespPrinc = [];
  for (let i = 10; i >= 0; i--) {
    if (datiania.dataATRC !== undefined && datiania.dataATRC !== null) {
      switch (i) {
        case 0:
          RespPrinc.push(datiania.dataATRC.atrcRespPrincAnno0);
          break;
        case 1:
          RespPrinc.push(datiania.dataATRC.atrcRespPrincAnno1);
          break;
        case 2:
          RespPrinc.push(datiania.dataATRC.atrcRespPrincAnno2);
          break;
        case 3:
          RespPrinc.push(datiania.dataATRC.atrcRespPrincAnno3);
          break;
        case 4:
          RespPrinc.push(datiania.dataATRC.atrcRespPrincAnno4);
          break;
        case 5:
          RespPrinc.push(datiania.dataATRC.atrcRespPrincAnno5);
          break;
        case 6:
          RespPrinc.push(datiania.dataATRC.atrcRespPrincAnno6);
          break;
        case 7:
          RespPrinc.push(datiania.dataATRC.atrcRespPrincAnno7);
          break;
        case 8:
          RespPrinc.push(datiania.dataATRC.atrcRespPrincAnno8);
          break;
        case 9:
          RespPrinc.push(datiania.dataATRC.atrcRespPrincAnno9);
          break;
        case 10:
          RespPrinc.push(datiania.dataATRC.atrcRespPrincAnno10);
          break;
        default:
          break;
      }
    }
  }

  if (posinfo.defAg !== null && JSON.parse(posinfo.defAg).insuranceYearsVan !== undefined ? true : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).insuranceYearsVan !== undefined ? true : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).insuranceYearsVan !== undefined ? true : false))) {
    //Calcola in base all'attestato di rischio
    if ((posinfo.defAg !== null && JSON.parse(posinfo.defAg).insuranceYearsVan !== undefined ? JSON.parse(posinfo.defAg).insuranceYearsVan : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).insuranceYearsVan !== undefined ? JSON.parse(posinfo.defGrp).insuranceYearsVan : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).insuranceYearsVan !== undefined ? JSON.parse(posinfo.defOrg).insuranceYearsVan : "0"))) === "1") {
      let calcola = 10;
      let trovato = false;
      RespPrinc.forEach((item, index) => {
        if (trovato === false) {
          if (item !== "NA" && item !== "--") {
            calcola = index;
            trovato = true;
          }
        }
      });
      defaultret = 10 - calcola;
    }
  }

  return defaultret;
}

const getModels = async (token, url, brand) => {
  try {
    const result = await axios.get(url + API_URL_GET_MODEL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      params: {
        brand: brand
      }
    });
    return result;
  } catch (e) {
    return Promise.reject(e.response);
  }
};

const getBrand = async (token, url) => {
  try {
    const result = await axios.get(url + API_URL_GET_BRAND, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      }
    });
    return result;
  } catch (e) {
    return Promise.reject(e.response);
  }
};

const getOutfitting = async (token, url, brand, model, homologationcode, registrationdate) => {
  try {
    const result = await axios.get(url + API_URL_GET_OUTFITTING, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      params: {
        brand: brand,
        model: model,
        homologationcode: homologationcode,
        registrationdate: registrationdate

      }
    });
    return result;
  } catch (e) {
    return Promise.reject(e.response);
  }
};

const checkPreventivo = async (tipoprev, targa, datiania, company, token, companyOrigin, conventionslist, guarantees, guaranteesSelect, guaranteesValue, flexCodeFascia) => {
  if (company.multisession === 1 && company.username === "" && company.password === "" && company.companyCode === "" && company.companyParams === "" && company.nodeCode === "") {
    if (company.paramurl === "hdi") {
      const result = {
        data: {
          error: "Servizio non disponibile",
          message: "Limite utenze in uso raggiunto, riprovare tra qualche minuto",
          path: "/getquotation",
          status: 503,
          errorCode: 1003,
          timestamp: moment().format("YYYY-MM-DD HH:mm:ss")
        }
      };
      return result;
    } else {
      const result = {
        data: {
          error: "Servizio non disponibile",
          message: "Utenze non disponibili, riprovare tra qualche minuto",
          path: "/getquotation",
          status: 503,
          errorCode: 1003,
          timestamp: moment().format("YYYY-MM-DD HH:mm:ss")
        }
      };
      return result;
    }
  }

  let listCVT = [];
  let convenzioneAttiva = CheckConvenzioni(datiania, company, tipoprev, conventionslist);
  guarantees.forEach((element, index) => {
    if (guaranteesSelect[index]) {
      let massimale = 0;
      let massimalePerc = 0;
      let franchigia = 0;
      let franchigiaPerc = 0;
      let limite = 0;
      let limitePerc = 0;
      let scopertoPerc = 0;
      let scoperto = 0;

      if (company.guarantees_info[index].franchigia !== null) {
        if (isNaN(company.guarantees_info[index].franchigia) && company.guarantees_info[index].franchigia.indexOf('%') !== -1) {
          franchigiaPerc = Number(company.guarantees_info[index].franchigia.replace('%', ''));
        } else {
          franchigia = Number(company.guarantees_info[index].franchigia);
        }
      }

      if (company.guarantees_info[index].massimale !== null) {
        if (isNaN(company.guarantees_info[index].massimale) && company.guarantees_info[index].massimale.indexOf('%') !== -1) {
          massimalePerc = Number(company.guarantees_info[index].massimale.replace('%', ''));
        } else {
          massimale = Number(company.guarantees_info[index].massimale);
        }
      }

      if (element["input_type"] !== 0) {
        /*if (isNaN(guaranteesValue[index]) && guaranteesValue[index].indexOf('%') !== -1) {
          massimalePerc = Number(guaranteesValue[index].replace('%', ''));
        } else {
          massimale = Number(guaranteesValue[index]);
        }*/
        if (isNaN(guaranteesValue[index]) && guaranteesValue[index].indexOf('%') !== -1) {
          franchigiaPerc = Number(guaranteesValue[index].replace('%', ''));
        } else {
          franchigia = Number(guaranteesValue[index]);
        }
      } else {
        if (company.guarantees_info[index].franchigia !== null) {
          if (isNaN(company.guarantees_info[index].franchigia) && company.guarantees_info[index].franchigia.indexOf('%') !== -1) {
            franchigiaPerc = Number(company.guarantees_info[index].franchigia.replace('%', ''));
          } else {
            franchigia = Number(company.guarantees_info[index].franchigia);
          }
        }
      }

      if (company.guarantees_info[index].limite_risarcimento_perc !== null) {
        limitePerc = Number(company.guarantees_info[index].limite_risarcimento_perc);
      }

      if (company.guarantees_info[index].limite_risarcimento_val !== null) {
        limite = Number(company.guarantees_info[index].limite_risarcimento_val);
      }

      if (company.guarantees_info[index].scoperto_perc !== null) {
        scopertoPerc = Number(company.guarantees_info[index].scoperto_perc);
      }

      if (company.guarantees_info[index].scoperto_val !== null) {
        scoperto = Number(company.guarantees_info[index].scoperto_val);
      }

      let tipoCalcolo = company.guarantees_info[index].tipo_calcolo !== null ? (company.guarantees_info[index].tipo_calcolo !== "default" ? company.guarantees_info[index].tipo_calcolo : company.guarantees_info[index].tipo_calcolo_default) : company.guarantees_info[index].tipo_calcolo_default;
      if (tipoCalcolo === "comp") {
        if (company.guarantees_info[index].service_parameters_code !== null) {
          company.guarantees_info[index].service_parameters_code.split(",").forEach(elementcode => {
            listCVT.push(
              {
                codice: elementcode,
                massimale: massimale,
                massimalePerc: massimalePerc,
                franchigia: franchigia,
                franchigiaPerc: franchigiaPerc,
                limite: limite,
                limitePerc: limitePerc,
                scoperto: scoperto,
                scopertoPerc: scopertoPerc
              }
            );
          });
        } else {
          listCVT.push(
            {
              codice: element["code"],
              massimale: massimale,
              massimalePerc: massimalePerc,
              franchigia: franchigia,
              franchigiaPerc: franchigiaPerc,
              limite: limite,
              limitePerc: limitePerc,
              scoperto: scoperto,
              scopertoPerc: scopertoPerc
            }
          );
        }
      }
    }
  });

  switch (company.paramurl) {
    case "generali":
      break;
    default:
      flexCodeFascia = "";
      break;
  }

  const json = JSON.stringify({
    applicationToken: APP_TOKEN_QUOTIAMO,
    username: company.username,
    password: company.password,
    companyCode: company.companyCode,
    companyParams: company.companyParams,
    nodeCode: company.nodeCode,
    commerciale: (company.commerciale === null || company.commerciale === "") ? "00493" : company.commerciale,
    agZona: (company.agZona === null || company.agZona === "") ? "00493" : company.agZona,
    reteVendita: "001",
    contractorInfo: {
      name: datiania.contractorInfo.name,
      surname: datiania.contractorInfo.surname,
      birthDate: datiania.contractorInfo.birthDate,
      gender: datiania.contractorInfo.gender,
      companyName: datiania.contractorInfo.companyName,
      phoneNumber: company.usedefaultphonenumber === 1 ? company.phonenumber : datiania.contractorInfo.phoneNumber,
      email: company.usedefaultemail === 1 ? company.email : datiania.contractorInfo.email,
      fiscalCode: datiania.contractorInfo.fiscalCode,
      vatNumber: datiania.contractorInfo.vatNumber,
      //profession: datiania.contractorInfo.profession,
      //qualification: datiania.contractorInfo.qualification,
      //maritalStatus: datiania.contractorInfo.maritalStatus,
      personType: (datiania.contractorInfo.subjectType === "1" || datiania.contractorInfo.subjectType === "") ? "FIS" : "GIU",
      subjectType: (datiania.contractorInfo.subjectType === "") ? "1" : datiania.contractorInfo.subjectType,
      residenceInfo: {
        toponym: datiania.contractorInfo.residenceInfo.toponym,
        address: datiania.contractorInfo.residenceInfo.address,
        streetNumber: datiania.contractorInfo.residenceInfo.streetNumber,
        city: datiania.contractorInfo.residenceInfo.city,
        cap: datiania.contractorInfo.residenceInfo.cap,
        provinceAbbr: datiania.contractorInfo.residenceInfo.provinceAbbr,
        istat: datiania.contractorInfo.residenceInfo.istat,
      },
      locationInfo: {
        toponym: datiania.contractorInfo.residenceInfo.toponym,
        address: datiania.contractorInfo.residenceInfo.address,
        streetNumber: datiania.contractorInfo.residenceInfo.streetNumber,
        city: datiania.contractorInfo.residenceInfo.city,
        cap: datiania.contractorInfo.residenceInfo.cap,
        provinceAbbr: datiania.contractorInfo.residenceInfo.provinceAbbr,
        istat: datiania.contractorInfo.residenceInfo.istat,
      }
    },
    ownerInfo: {
      name: datiania.ownerInfo.name,
      surname: datiania.ownerInfo.surname,
      birthDate: datiania.ownerInfo.birthDate,
      gender: datiania.ownerInfo.gender,
      companyName: datiania.ownerInfo.companyName,
      phoneNumber: company.usedefaultphonenumber === 1 ? company.phonenumber : datiania.ownerInfo.phoneNumber,
      email: company.usedefaultemail === 1 ? company.email : datiania.ownerInfo.email,
      fiscalCode: datiania.ownerInfo.fiscalCode,
      vatNumber: datiania.ownerInfo.vatNumber,
      //profession: datiania.ownerInfo.profession,
      //qualification: datiania.ownerInfo.qualification,
      //maritalStatus: datiania.ownerInfo.maritalStatus,
      personType: (datiania.ownerInfo.subjectType === "1" || datiania.ownerInfo.subjectType === "") ? "FIS" : "GIU",
      subjectType: (datiania.ownerInfo.subjectType === "") ? "1" : datiania.ownerInfo.subjectType,
      residenceInfo: {
        toponym: datiania.ownerInfo.residenceInfo.toponym,
        address: datiania.ownerInfo.residenceInfo.address,
        streetNumber: datiania.ownerInfo.residenceInfo.streetNumber,
        city: datiania.ownerInfo.residenceInfo.city,
        cap: datiania.ownerInfo.residenceInfo.cap,
        provinceAbbr: datiania.ownerInfo.residenceInfo.provinceAbbr,
        istat: datiania.ownerInfo.residenceInfo.istat,
      },
      locationInfo: {
        toponym: datiania.ownerInfo.residenceInfo.toponym,
        address: datiania.ownerInfo.residenceInfo.address,
        streetNumber: datiania.ownerInfo.residenceInfo.streetNumber,
        city: datiania.ownerInfo.residenceInfo.city,
        cap: datiania.ownerInfo.residenceInfo.cap,
        provinceAbbr: datiania.ownerInfo.residenceInfo.provinceAbbr,
        istat: datiania.ownerInfo.residenceInfo.istat,
      }
    },
    vehicleInfo: {
      insuranceStatus: tipoprev,
      licensePlate: targa,
      horsePower: datiania.horsePower,
      power: datiania.power,
      homologationCode: datiania.homologationCode,
      brand: datiania.brand,
      model: datiania.model,
      value: datiania.usedValue,
      codiceMotornet: datiania.codiceMotornet,
      registrationDate: datiania.registrationDate,
      policyExpirationDate: moment(datiania.policyExpirationDate, "YYYY-MM-DD").format("YYYY-MM-DD"),
      vehicleType: datiania.vehicleType,
      towHook: datiania.towHook === "SI" ? true : false,
      supply: datiania.supply,
      insuranceYears: datiania.insuranceYears,
      purchaseDate: datiania.purchaseDate,
      alarm: datiania.alarm,
      use: datiania.use,
      //carBody: datiania.carBody,
      //kmAnnui: datiania.kmAnnui,
      dataATRC: datiania.dataATRC,
      weight: datiania.weight,
    },
    quoteInfo: {
      insuranceCompanyOrigin: companyOrigin,
      insuranceYearsCU1: datiania.insuranceYearsCU1,
      flex: convenzioneAttiva.flex,
      flexCode: flexCodeFascia !== "" ? flexCodeFascia : convenzioneAttiva.code,
      flexDescription: convenzioneAttiva.description,
      conventionDiscount: "100",
      //guideType: (datiania.guideType === "LIB" || datiania.guideType === undefined) ? "LIB" : datiania.guideType,
      cuOrigin: datiania.cuOrigin,
      cuAssignment: datiania.cuAssignment,
      declarationOrigin: datiania.declarationOrigin,
      effectiveDate: moment(datiania.effectiveDate, "YYYY-MM-DD").format("YYYY-MM-DD"),
      maximal: datiania.maximal,
      splittingUp: datiania.splittingUp,
      revengeWaiver: datiania.revengeWaiver,
      listaCVT: listCVT,
    }
  });

  const url_service = company.url + API_URL_QUOTATION + "/" + company.type + "/" + (company.type === "share" ? company.sharetoken : company.paramurl);
  try {
    const result = await axios.post(url_service, json, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      }
    });
    return result;
  } catch (e) {
    return Promise.reject(e.response);
  }
}

function CheckConvenzioni(datiania, company, tipoprev, conventionslist) {
  let convention = {
    code: "",
    description: "",
    flex: 0
  };

  let percmax = 0;
  let convComp = false;

  /*if (tipoprev !== "RIN") {
    return convention;
  }

  if (company.paramurl === "groupama") {
    if (datiania.insuranceCompany === undefined) {
      return convention;
    }

    if (datiania.insuranceCompany.indexOf("GROUPAMA") === -1) {
      if (datiania.dataATRC !== undefined) {
        let numerosinistri = 0;
        for (let i = 0; i <= 9; i++) {
          if (ReturnInfoSinistriAnno(datiania, i, 0) !== "NA" && ReturnInfoSinistriAnno(datiania, i, 0) !== "ND" && ReturnInfoSinistriAnno(datiania, i, 0) !== "--") {
            numerosinistri += Number(ReturnInfoSinistriAnno(datiania, i, 0));
          }
          if (ReturnInfoSinistriAnno(datiania, i, 4) !== "NA" && ReturnInfoSinistriAnno(datiania, i, 4) !== "ND" && ReturnInfoSinistriAnno(datiania, i, 4) !== "--") {
            numerosinistri += Number(ReturnInfoSinistriAnno(datiania, i, 4));
          }
        }
        if (numerosinistri > 1) return convention;
      } else {
        return convention;
      }
    }
  }*/

  conventionslist.forEach(conventions => {
    if (company.facid === conventions.id_factory) {
      let convention_accept = CheckActiveConvention(conventions, datiania, tipoprev);
      if (convention_accept) {
        let value_flex = 0;
        if (conventions.discounts_rules !== null) {
          JSON.parse(conventions.discounts_rules).forEach(
            single_discount_rules => {
              let selectCVT = "RCA";
              if (single_discount_rules.hasOwnProperty(selectCVT)) {
                value_flex = single_discount_rules[selectCVT].perc;
              }
            }
          );
        }
        if (value_flex >= percmax) {
          convention.code = conventions.code;
          convention.description = conventions.description;
          convention.flex = value_flex;
          percmax = value_flex;
          if (conventions.companyapply === 1) {
            convComp = true;
          } else {
            convComp = false;
          }
        }
      }
    }
  });
  if (convComp === false) {
    convention = {
      code: "",
      description: "",
      flex: 0
    };
    return convention;
  }
  return convention;
}

function CheckActiveConvention(conventions, datiania, tipoprev) {
  let convention_accept = true;
  let checkAnniValorizzati = false;
  let checkAnniSinistri = false;
  let MaxNumeroSinistri = false;
  let MinNumeroSinistri = false;

  if (conventions.convention_rules !== null) {
    JSON.parse(conventions.convention_rules).forEach((single_rules) => {
      if (single_rules.AtrValue !== undefined) {
        if (single_rules.AtrValue.value !== null && single_rules.AtrValue.value !== "") {
          if (!isNaN(single_rules.AtrValue.value)) {
            checkAnniValorizzati = Number(single_rules.AtrValue.value);
            if (checkAnniValorizzati > 10) {
              checkAnniValorizzati = 10;
            }
            if (checkAnniValorizzati <= 0) {
              checkAnniValorizzati = 1;
            }
          }
          if (!CheckValidATRC(datiania) || CheckAnniValorizzati(datiania) < checkAnniValorizzati) {
            convention_accept = false;
          }
        }
      }
      if (single_rules.AtrCheckNumber !== undefined) {
        if (single_rules.AtrCheckNumber.value !== null && single_rules.AtrCheckNumber.value !== "") {
          MaxNumeroSinistri = Number(single_rules.AtrCheckNumber.value);
        }
      }
      if (single_rules.AtrCheckNumberMin !== undefined) {
        if (single_rules.AtrCheckNumberMin.value !== null && single_rules.AtrCheckNumberMin.value !== "") {
          MinNumeroSinistri = Number(single_rules.AtrCheckNumberMin.value);
        }
      }
      if (single_rules.AtrCheck !== undefined) {
        if (single_rules.AtrCheck.value !== null && single_rules.AtrCheck.value !== "") {
          checkAnniSinistri = Number(single_rules.AtrCheck.value);
          if (checkAnniSinistri > 10) {
            checkAnniSinistri = 11;
          }
          if (checkAnniSinistri <= 0) {
            checkAnniSinistri = 1;
          }
        }
      }
      if (single_rules.ProvinceList !== undefined) {
        if (single_rules.ProvinceList.value !== null && single_rules.ProvinceList.value !== "") {
          //non trovate le province
          let elencoProvince = single_rules.ProvinceList.value.split(",");
          //elencoProvinceConvenzione
          if (elencoProvince.indexOf(datiania.contractorInfo.residenceInfo.provinceAbbr) === -1) {
            convention_accept = false;
          }
        }
      }
      if (single_rules.BirthNation !== undefined) {
        if (single_rules.BirthNation.value !== null && single_rules.BirthNation.value !== "") {
          //Verifico se nato in paese straniero
          if (!isNaN(single_rules.BirthNation.value)) {
            var res = datiania.contractorInfo.fiscalCode.substring(11, 12).toUpperCase();
            if (parseInt(single_rules.BirthNation.value) === 1) {
              if (res === "Z") {
                convention_accept = false;
              }
            } else if (parseInt(single_rules.BirthNation.value) === 2) {
              if (res !== "Z") {
                convention_accept = false;
              }
            }
          }
        }
      }
      if (single_rules.PaymentMode !== undefined) {
        if (single_rules.PaymentMode.value !== null && single_rules.PaymentMode.value !== "") {
          if (Number(datiania.splittingUp) !== Number(single_rules.PaymentMode.value)) {
            convention_accept = false;
          }
        }
      }
      if (single_rules.PrevType !== undefined) {
        if (single_rules.PrevType.value !== null && single_rules.PrevType.value !== "") {
          if (tipoprev !== single_rules.PrevType.value) {
            convention_accept = false;
          }
        }
      }
      if (single_rules.anniVeicolo !== undefined) {
        if (single_rules.anniVeicolo.value !== null && single_rules.anniVeicolo.value !== "") {

          //Verifico il veicolo supera il limite vetustà
          var annoReg = moment(datiania.registrationDate);
          var annoOggi = moment();

          if (Number(annoOggi.diff(annoReg, 'years', true)) > Number(single_rules.anniVeicolo.value)) {
            convention_accept = false;
          }
        }
      }
      if (single_rules.pesoVeicolo !== undefined) {
        if (single_rules.pesoVeicolo.value !== null && single_rules.pesoVeicolo.value !== "") {
          if (getPesoInIntervalloConv(single_rules.pesoVeicolo.value, datiania.weight) === false) {
            convention_accept = false;
          }
        }
      }
    });
    if (CheckValidATRC(datiania) && MaxNumeroSinistri !== false && checkAnniSinistri !== false) {
      if (CheckATRC(datiania, MaxNumeroSinistri, checkAnniSinistri) === false) {
        convention_accept = false;
      }
    }
    if (CheckValidATRC(datiania) && MinNumeroSinistri !== false && checkAnniSinistri !== false) {
      if (CheckMinATRC(datiania, MinNumeroSinistri, checkAnniSinistri) === false) {
        convention_accept = false;
      }
    }
  }
  return convention_accept;
}

function CheckValidATRC(datiania) {
  if (datiania.dataATRC === undefined) return false;
  //ATRC Deve essere dell'anno corrente
  if (datiania.dataATRC.anno0Atrc >= moment().format("YYYY")) {
    return true;
  } else {
    return false;
  }
}

function CheckAnniValorizzati(datiania) {
  let ultimoAnno = 0;
  for (let i = 0; i <= 10; i++) {
    if (ReturnInfoSinistriAnno(datiania, i, 0) !== "NA" && ReturnInfoSinistriAnno(datiania, i, 0) !== "ND" && ReturnInfoSinistriAnno(datiania, i, 0) !== "--") {
      ultimoAnno = i + 1;
    } else {
      break;
    }
  }
  return ultimoAnno;
}

function CheckATRC(datiania, sinistriCheck, numeroAnniCheck) {
  //Secondo controllo verifica numero di sinistri
  let numerosinistri = 0;
  for (let i = 0; i < numeroAnniCheck; i++) {
    if (ReturnInfoSinistriAnno(datiania, i, 0) !== "NA" && ReturnInfoSinistriAnno(datiania, i, 0) !== "ND" && ReturnInfoSinistriAnno(datiania, i, 0) !== "--") {
      numerosinistri += Number(ReturnInfoSinistriAnno(datiania, i, 0));
    }
    if (ReturnInfoSinistriAnno(datiania, i, 4) !== "NA" && ReturnInfoSinistriAnno(datiania, i, 4) !== "ND" && ReturnInfoSinistriAnno(datiania, i, 4) !== "--") {
      numerosinistri += Number(ReturnInfoSinistriAnno(datiania, i, 4));
    }
  }
  if (numerosinistri > sinistriCheck) return false;
}

function CheckMinATRC(datiania, sinistriCheck, numeroAnniCheck) {
  //Secondo controllo verifica numero di sinistri
  let numerosinistri = 0;
  for (let i = 0; i < numeroAnniCheck; i++) {
    if (ReturnInfoSinistriAnno(datiania, i, 0) !== "NA" && ReturnInfoSinistriAnno(datiania, i, 0) !== "ND" && ReturnInfoSinistriAnno(datiania, i, 0) !== "--") {
      numerosinistri += Number(ReturnInfoSinistriAnno(datiania, i, 0));
    }
    if (ReturnInfoSinistriAnno(datiania, i, 4) !== "NA" && ReturnInfoSinistriAnno(datiania, i, 4) !== "ND" && ReturnInfoSinistriAnno(datiania, i, 4) !== "--") {
      numerosinistri += Number(ReturnInfoSinistriAnno(datiania, i, 4));
    }
  }
  if (numerosinistri < sinistriCheck) return false;
}

function ReturnInfoSinistriAnno(datiania, anno, quale) {
  switch (anno) {
    case 0:
      switch (quale) {
        case 0: return datiania.dataATRC.atrcRespPrincAnno0;
        case 1: return datiania.dataATRC.atrcRespPrincDanniCoseAnno0;
        case 2: return datiania.dataATRC.atrcRespPrincDanniPersAnno0;
        case 3: return datiania.dataATRC.atrcRespPrincDanniMistiAnno0;
        case 4: return datiania.dataATRC.atrcRespPariAnno0;
        case 5: return datiania.dataATRC.atrcRespPariDanniCoseAnno0;
        case 6: return datiania.dataATRC.atrcRespPariDanniPersAnno0;
        case 7: return datiania.dataATRC.atrcRespPariDanniMistiAnno0;
        default: return null;
      }
    case 1:
      switch (quale) {
        case 0: return datiania.dataATRC.atrcRespPrincAnno1;
        case 1: return datiania.dataATRC.atrcRespPrincDanniCoseAnno1;
        case 2: return datiania.dataATRC.atrcRespPrincDanniPersAnno1;
        case 3: return datiania.dataATRC.atrcRespPrincDanniMistiAnno1;
        case 4: return datiania.dataATRC.atrcRespPariAnno1;
        case 5: return datiania.dataATRC.atrcRespPariDanniCoseAnno1;
        case 6: return datiania.dataATRC.atrcRespPariDanniPersAnno1;
        case 7: return datiania.dataATRC.atrcRespPariDanniMistiAnno1;
        default: return null;
      }
    case 2:
      switch (quale) {
        case 0: return datiania.dataATRC.atrcRespPrincAnno2;
        case 1: return datiania.dataATRC.atrcRespPrincDanniCoseAnno2;
        case 2: return datiania.dataATRC.atrcRespPrincDanniPersAnno2;
        case 3: return datiania.dataATRC.atrcRespPrincDanniMistiAnno2;
        case 4: return datiania.dataATRC.atrcRespPariAnno2;
        case 5: return datiania.dataATRC.atrcRespPariDanniCoseAnno2;
        case 6: return datiania.dataATRC.atrcRespPariDanniPersAnno2;
        case 7: return datiania.dataATRC.atrcRespPariDanniMistiAnno2;
        default: return null;
      }
    case 3:
      switch (quale) {
        case 0: return datiania.dataATRC.atrcRespPrincAnno3;
        case 1: return datiania.dataATRC.atrcRespPrincDanniCoseAnno3;
        case 2: return datiania.dataATRC.atrcRespPrincDanniPersAnno3;
        case 3: return datiania.dataATRC.atrcRespPrincDanniMistiAnno3;
        case 4: return datiania.dataATRC.atrcRespPariAnno3;
        case 5: return datiania.dataATRC.atrcRespPariDanniCoseAnno3;
        case 6: return datiania.dataATRC.atrcRespPariDanniPersAnno3;
        case 7: return datiania.dataATRC.atrcRespPariDanniMistiAnno3;
        default: return null;
      }
    case 4:
      switch (quale) {
        case 0: return datiania.dataATRC.atrcRespPrincAnno4;
        case 1: return datiania.dataATRC.atrcRespPrincDanniCoseAnno4;
        case 2: return datiania.dataATRC.atrcRespPrincDanniPersAnno4;
        case 3: return datiania.dataATRC.atrcRespPrincDanniMistiAnno4;
        case 4: return datiania.dataATRC.atrcRespPariAnno4;
        case 5: return datiania.dataATRC.atrcRespPariDanniCoseAnno4;
        case 6: return datiania.dataATRC.atrcRespPariDanniPersAnno4;
        case 7: return datiania.dataATRC.atrcRespPariDanniMistiAnno4;
        default: return null;
      }
    case 5:
      switch (quale) {
        case 0: return datiania.dataATRC.atrcRespPrincAnno5;
        case 1: return datiania.dataATRC.atrcRespPrincDanniCoseAnno5;
        case 2: return datiania.dataATRC.atrcRespPrincDanniPersAnno5;
        case 3: return datiania.dataATRC.atrcRespPrincDanniMistiAnno5;
        case 4: return datiania.dataATRC.atrcRespPariAnno5;
        case 5: return datiania.dataATRC.atrcRespPariDanniCoseAnno5;
        case 6: return datiania.dataATRC.atrcRespPariDanniPersAnno5;
        case 7: return datiania.dataATRC.atrcRespPariDanniMistiAnno5;
        default: return null;
      }
    case 6:
      switch (quale) {
        case 0: return datiania.dataATRC.atrcRespPrincAnno6;
        case 1: return datiania.dataATRC.atrcRespPrincDanniCoseAnno6;
        case 2: return datiania.dataATRC.atrcRespPrincDanniPersAnno6;
        case 3: return datiania.dataATRC.atrcRespPrincDanniMistiAnno6;
        case 4: return datiania.dataATRC.atrcRespPariAnno6;
        case 5: return datiania.dataATRC.atrcRespPariDanniCoseAnno6;
        case 6: return datiania.dataATRC.atrcRespPariDanniPersAnno6;
        case 7: return datiania.dataATRC.atrcRespPariDanniMistiAnno6;
        default: return null;
      }
    case 7:
      switch (quale) {
        case 0: return datiania.dataATRC.atrcRespPrincAnno7;
        case 1: return datiania.dataATRC.atrcRespPrincDanniCoseAnno7;
        case 2: return datiania.dataATRC.atrcRespPrincDanniPersAnno7;
        case 3: return datiania.dataATRC.atrcRespPrincDanniMistiAnno7;
        case 4: return datiania.dataATRC.atrcRespPariAnno7;
        case 5: return datiania.dataATRC.atrcRespPariDanniCoseAnno7;
        case 6: return datiania.dataATRC.atrcRespPariDanniPersAnno7;
        case 7: return datiania.dataATRC.atrcRespPariDanniMistiAnno7;
        default: return null;
      }
    case 8:
      switch (quale) {
        case 0: return datiania.dataATRC.atrcRespPrincAnno8;
        case 1: return datiania.dataATRC.atrcRespPrincDanniCoseAnno8;
        case 2: return datiania.dataATRC.atrcRespPrincDanniPersAnno8;
        case 3: return datiania.dataATRC.atrcRespPrincDanniMistiAnno8;
        case 4: return datiania.dataATRC.atrcRespPariAnno8;
        case 5: return datiania.dataATRC.atrcRespPariDanniCoseAnno8;
        case 6: return datiania.dataATRC.atrcRespPariDanniPersAnno8;
        case 7: return datiania.dataATRC.atrcRespPariDanniMistiAnno8;
        default: return null;
      }
    case 9:
      switch (quale) {
        case 0: return datiania.dataATRC.atrcRespPrincAnno9;
        case 1: return datiania.dataATRC.atrcRespPrincDanniCoseAnno9;
        case 2: return datiania.dataATRC.atrcRespPrincDanniPersAnno9;
        case 3: return datiania.dataATRC.atrcRespPrincDanniMistiAnno9;
        case 4: return datiania.dataATRC.atrcRespPariAnno9;
        case 5: return datiania.dataATRC.atrcRespPariDanniCoseAnno9;
        case 6: return datiania.dataATRC.atrcRespPariDanniPersAnno9;
        case 7: return datiania.dataATRC.atrcRespPariDanniMistiAnno9;
        default: return null;
      }
    case 10:
      switch (quale) {
        case 0: return datiania.dataATRC.atrcRespPrincAnno10;
        case 1: return datiania.dataATRC.atrcRespPrincDanniCoseAnno10;
        case 2: return datiania.dataATRC.atrcRespPrincDanniPersAnno10;
        case 3: return datiania.dataATRC.atrcRespPrincDanniMistiAnno10;
        case 4: return datiania.dataATRC.atrcRespPariAnno10;
        case 5: return datiania.dataATRC.atrcRespPariDanniCoseAnno10;
        case 6: return datiania.dataATRC.atrcRespPariDanniPersAnno10;
        case 7: return datiania.dataATRC.atrcRespPariDanniMistiAnno10;
        default: return null;
      }
    default: return null;
  }
}

function getPesoInIntervallo(intervallo, peso) {
  const splitIntervallo = intervallo.split("-");
  let min = Number(splitIntervallo[0]);
  let max = Number(splitIntervallo[1]);
  if (peso > min && peso <= max) {
    return true;
  }
  return false;
}

function getPesoInIntervalloConv(intervallo, peso) {
  const splitIntervallo = intervallo.split("-");
  let min = Number(splitIntervallo[0]);
  let max = Number(splitIntervallo[1]);
  if (peso >= min && peso < max) {
    return true;
  }
  return false;
}

export default function QuotationVan(props) {
  const classes = useStyles();
  const steps = getSteps();
  const location = useLocation();

  const [, setPosActive] = React.useContext(PosDataContext);
  const [loading, setLoading] = React.useState(false);
  const [tipoprev, setTipoPrev] = React.useState('RIN');
  const [targa, setTarga] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [listCity, setlistCity] = React.useState([]);
  const [alertseverity, setAlertSeverity] = React.useState("success");
  const [alertmsg, setAlertMsg] = React.useState(false);
  const [prevRQ, setPrevRQ] = React.useState(0);
  const [prevRS, setPrevRS] = React.useState(0);
  const [datipreventivo, setdatipreventivo] = React.useState([]);
  const [agencieslists, setagencieslists] = React.useState([]);
  const [pos, setPos] = React.useState('');
  const [token, setToken] = React.useState(null);
  const [guarantees, setGuarantees] = React.useState([]);
  const [guaranteesSelect, setGuaranteesSelect] = React.useState([]);
  const [guaranteesValue, setGuaranteesValue] = React.useState([]);
  const [images, setImages] = React.useState([]);
  const [urlservicequotiamo, seturlservicequotiamo] = React.useState("");
  const [posinfo, setPosInfo] = React.useState(null);
  const [orgid, setOrgId] = React.useState(null);
  const [listProfessions, setlistProfessions] = React.useState([]);
  const [datiania, setdatiania] = React.useState([]);
  const [PreCompile, setPreCompile] = React.useState(false);
  const [sameOwner, setSameOwner] = React.useState(true);
  const [sameDriver, setSameDriver] = React.useState(true);
  const [progress, setProgress] = React.useState(0);
  const [listModels, setlistModels] = React.useState([]);
  const [listOutfitting, setlistOutfitting] = React.useState([]);
  const [listBrands, setlistBrands] = React.useState([]);
  const value = queryString.parse(location.search);
  const token810DN = value.checktoken;
  const idDN = value.id;
  const codiceDN = value.codice;
  const [factories, setFactories] = React.useState([]);
  const [companyData, setcompanyData] = React.useState([]);
  const [NumberQuotation, setNumberQuotation] = React.useState(false);
  let contaPreventiviDaFare = 0;
  let contaPreventiviFatti = 0;
  const [flexCode, setFlexCode] = React.useState("");
  const [factoriesHub, setFactoriesHub] = React.useState([]);

  React.useEffect(() => {
    const url = API_URL_LIST_AGENCIES_USER;
    axios.get(url,
      {
        headers: {
          'Authorization': 'Bearer ' + Auth.getToken(),
          'Content-Type': 'application/json'
        }
      }
    ).then(payload => {
      setagencieslists(payload.data);
      if (payload.data.length > 0) {
        setPos(payload.data[0].id);
        seturlservicequotiamo(payload.data[0].url);
        setPosInfo(payload.data[0]);
        setPosActive(payload.data[0]);
        setOrgId(payload.data[0].orgid);
        localStorage.setItem('pos', JSON.stringify(payload.data[0]));

        AuthService.signin(payload.data[0].username, payload.data[0].password, payload.data[0].url).then(
          payload => {
            setToken(payload);
          }).catch(error => { });
        const url = API_URL_INFOIMAGES_ALL + "/van/" + payload.data[0].id;
        axios.get(url,
          {
            headers: {
              'Authorization': 'Bearer ' + Auth.getToken(),
              'Content-Type': 'application/json'
            }
          }
        ).then(payload => {
          setImages(payload.data.images)
        });
      }
    });
  }, [setPosActive]);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 1000 ? 1 : prevProgress + 1));
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  React.useEffect(() => {
    if (orgid !== null) {
      const url = API_URL_ALLGUARANTEES + "/enabled/van/" + orgid;
      axios.get(url,
        {
          headers: {
            'Authorization': 'Bearer ' + Auth.getToken(),
            'Content-Type': 'application/json'
          }
        }
      ).then(payload => {
        let temp = [];
        let tempVal = [];
        payload.data.guarantees.forEach(element => {
          temp.push(false);
          if (element.input_type === 2) {
            tempVal.push(element.list_value.split(",")[0]);
          } else {
            tempVal.push("");
          }
        });
        setGuaranteesSelect(temp);
        setGuaranteesValue(tempVal);
        setGuarantees(payload.data.guarantees);
      });
    }
  }, [orgid]);

  React.useEffect(() => {
    const url = API_URL_FACTORIES_LIST;
    axios.get(url,
      {
        headers: {
          'Authorization': 'Bearer ' + Auth.getToken(),
          'Content-Type': 'application/json'
        }
      }
    ).then(payload => {
      setFactories(payload.data.factories);
    });
  }, []);

  React.useEffect(() => {
    if (idDN !== undefined) {
      const url = REGISTRY_URL;
      axios.post(url, {
        "id": idDN,
        "codice": codiceDN
      },
        {
          headers: {
            'Authorization': 'Bearer ' + token810DN,
            'Content-Type': 'application/json'
          }
        }
      ).then(payload => {
        setPreCompile(payload.data.result);
        setTarga(payload.data.result[0].risposta);
      });
    }
  }, [idDN, codiceDN, token810DN]);

  React.useEffect(() => {
    const url = API_URL_LIST_AGENCIES_USER;
    axios.get(url,
      {
        headers: {
          'Authorization': 'Bearer ' + Auth.getToken(),
          'Content-Type': 'application/json'
        }
      }
    ).then(payload => {
      setagencieslists(payload.data);
      if (payload.data.length > 0) {
        setPos(payload.data[0].id);
        seturlservicequotiamo(payload.data[0].url);
        setPosInfo(payload.data[0]);
        setPosActive(payload.data[0]);
        setOrgId(payload.data[0].orgid);
        localStorage.setItem('pos', JSON.stringify(payload.data[0]));
        AuthService.signin(payload.data[0].username, payload.data[0].password, payload.data[0].url).then(
          payload => {
            setToken(payload);
          }).catch(error => { });
        const url = API_URL_INFOIMAGES_ALL + "/van/" + payload.data[0].id;
        axios.get(url,
          {
            headers: {
              'Authorization': 'Bearer ' + Auth.getToken(),
              'Content-Type': 'application/json'
            }
          }
        ).then(payload => {
          setImages(payload.data.images)
        });
      }
    });
  }, [setPosActive]);

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleBack = () => {
    if (activeStep === 5) {
      window.stop();
      setdatipreventivo([]);
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChangePos = (event) => {
    agencieslists.forEach(element => {
      if (element.id === event.target.value) {
        setPos(event.target.value);
        seturlservicequotiamo(element.url)
        setPosInfo(element);
        setPosActive(element);
        setOrgId(element.orgid);
        localStorage.setItem('pos', JSON.stringify(element));

        AuthService.signin(element.username, element.password, element.url).then(
          payload => {
            setToken(payload);
          }).catch(error => { });
        /*const urlGuar = API_URL_ALLGUARANTEES + "/enabled/van/" + element.orgid;
        axios.get(urlGuar,
          {
            headers: {
              'Authorization': 'Bearer ' + Auth.getToken(),
              'Content-Type': 'application/json'
            }
          }
        ).then(payload => {
          let temp = [];
          let tempVal = [];
          payload.data.guarantees.forEach(elementGuar => {
            temp.push(false);
            if (elementGuar.input_type === 2) {
              tempVal.push(elementGuar.list_value.split(",")[0]);
            } else {
              tempVal.push("");
            }
          });
          setGuaranteesSelect(temp);
          setGuaranteesValue(tempVal);
          setGuarantees(payload.data.guarantees);
        });*/
        const url = API_URL_INFOIMAGES_ALL + "/van/" + event.target.value;
        axios.get(url,
          {
            headers: {
              'Authorization': 'Bearer ' + Auth.getToken(),
              'Content-Type': 'application/json'
            }
          }
        ).then(payload => {
          setImages(payload.data.images)
        });
      }
    });
  }

  const handleChangeType = (event) => {
    setTipoPrev(event.target.value);
  };

  const handleChangeRegistrationDate = event => {
    setdatiania({
      ...datiania,
      registrationDate: moment(event).format("YYYY-MM-DD")
    });
  };

  const handleChange = event => {
    setdatiania({
      ...datiania,
      [event.target.name]: event.target.value
    });
  };

  const handleChangePurchaseDate = event => {
    setdatiania({
      ...datiania,
      purchaseDate: moment(event).format("YYYY-MM-DD")
    });
  };

  const handleClick = name => event => {
    let new_guaranteesSelect = guaranteesSelect;
    new_guaranteesSelect[name] = !guaranteesSelect[name];
    setGuaranteesSelect(new_guaranteesSelect);
  }

  const handleChangeFreeInput = index => event => {
    let newVal = guaranteesValue;
    newVal[index] = event.floatValue;
    setGuaranteesValue(newVal);
  };

  const handleChangeList = index => event => {
    let newVal = guaranteesValue;
    newVal[index] = event.target.value;
    setGuaranteesValue(newVal);
  };

  const handleChangeDateEffective = event => {
    setdatiania({
      ...datiania,
      effectiveDate: moment(event).format("YYYY-MM-DD")
    });
  };

  const checkcompanyData = async (tokenApp, pos) => {
    try {
      const result = await axios.get(API_URL_LIST_SERVICES_AGENCY_USER + "/van/" + pos, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + tokenApp,
        }
      });
      return result;
    } catch (e) {
      return Promise.reject(e.response);
    }
  };

  const HandleSaveQuotation = (companyListPrev) => {
    const userData = Auth.getUser();
    /*let proflabel = "";
    listProfessions.forEach(prof => {
      if (prof.id === datiania.contractorInfo.profession) {
        proflabel = prof.description;
      }
    });
    let qualificationlabel = "";
    livelliIstruzione.forEach(qual => {
      if (qual.id === datiania.qualification) {
        qualificationlabel = qual.description;
      }
    });
    let statoCivilelabel = "";
    statoCivile.forEach(statociv => {
      if (statociv.id === datiania.maritalStatus) {
        statoCivilelabel = statociv.description;
      }
    });*/

    const json = JSON.stringify({
      clientinfo: datiania,
      /*clientlabelinfo: {
        profession: proflabel,
        qualification: qualificationlabel,
        maritalStatus: statoCivilelabel
      },*/
      vehicleInfo: {
        insuranceStatus: tipoprev,
        licensePlate: targa,
        vehicleType: datiania.vehicleType,
        fascia: flexCode,
      },
      quoteInfo: {

      }
    });

    const list = [];
    companyListPrev.forEach((company) => {
      company.guarantees_select = guaranteesSelect;
      list.push(
        {
          company: company,
          datipre: datipreventivo[company.sfo_id + company.paramurl + company.type]
        }
      )
    });

    const url = API_URL_QUOTATION_LIST;
    axios.put(url, {
      product: window.location.pathname,
      id_user: userData.id,
      id_agency: pos,
      clientinfo: json,
      detail: JSON.stringify(list),
      select: "",
    }, {
      headers: {
        'Authorization': 'Bearer ' + Auth.getToken(),
        'Content-Type': 'application/json'
      }
    }).then(payload => {
      if (payload.status === 200) {
        setAlertMsg("Inserimento dati avvenuto con successo");
        setAlertSeverity("success");
        setNumberQuotation(payload.data.numberquotation);
      } else {
        setAlertMsg("Errore durante l'inserimento dati");
        setAlertSeverity("error");
      }
      setOpen(true);
    }).catch((error) => {
      setAlertMsg(error.response.data.error);
      setAlertSeverity("error");
      setOpen(true);
    });
  }

  const handleChangeOption = (event) => {
    setFlexCode(event.target.value);
  };

  const handleNext = () => {
    switch (activeStep) {
      case 0:
        if (pos === "") {
          setAlertMsg("Nessun punto vendita abilitato, impossibile effettuare il preventivo");
          setAlertSeverity("error");
          setOpen(true);
          break;
        }

        checkcompanyData(Auth.getToken(), pos).then(
          payload => {
            let companyListPrev = payload.data.services;
            if (companyListPrev.length < 1) {
              setAlertMsg("Prodotto non attivo per questo punto vendita");
              setAlertSeverity("error");
              setOpen(true);
              return;
            }

            if (tipoprev !== "CVT" && targa === "") {
              setAlertMsg("Inserire la targa per proseguire");
              setAlertSeverity("error");
              setOpen(true);
              return;
            }

            if ((tipoprev === "VOL" || tipoprev === "PRI") && flexCode === "") {
              setAlertMsg("Profilo non gradito");
              setAlertSeverity("error");
              setOpen(true);
              return;
            }

            getProfessions(token, urlservicequotiamo).then(payload => {
              setlistProfessions(payload.data);
            });

            getListOfCity(token, urlservicequotiamo, "").then(payload => {
              setlistCity(payload.data);
            });

            setLoading(true);

            if (targa !== "") {
              checkSicAniaData(Auth.getToken(), pos).then(
                payload => {
                  const datiCollegamentoAnia = payload.data.sicania_services[0];
                  checkSicAnia(datiCollegamentoAnia.type, targa, token, urlservicequotiamo, datiCollegamentoAnia.username, datiCollegamentoAnia.password, datiCollegamentoAnia.code, tipoprev, false, "", datiCollegamentoAnia.sharetoken).then(
                    payload => {
                      let newdatiania;
                      if (payload.data.error !== undefined) {
                        newdatiania = {
                          contractorInfo: {
                            subjectType: "1",
                            name: "",
                            surname: "",
                            birthDate: "",
                            companyName: "",
                            gender: "M",
                            fiscalCode: "",
                            birthCity: "",
                            birthProvince: "",
                            birthRegion: "",
                            birthNationAbbr: "",
                            birthNation: "",
                            email: "",
                            phoneNumber: "",
                            profession: "",
                            qualification: "",
                            maritalStatus: "",
                            residenceInfo: {
                              city: "",
                              cap: "",
                              provinceAbbr: "",
                              toponym: "",
                              address: "",
                              streetNumber: "",
                              istat: "",
                            }
                          },
                          ownerInfo: {
                            subjectType: "1",
                            name: "",
                            surname: "",
                            birthDate: "",
                            companyName: "",
                            gender: "M",
                            fiscalCode: "",
                            birthCity: "",
                            birthProvince: "",
                            birthRegion: "",
                            birthNationAbbr: "",
                            birthNation: "",
                            email: "",
                            phoneNumber: "",
                            profession: "",
                            qualification: "",
                            maritalStatus: "",
                            residenceInfo: {
                              city: "",
                              cap: "",
                              provinceAbbr: "",
                              toponym: "",
                              address: "",
                              streetNumber: "",
                              istat: "",
                            }
                          },
                          effectiveDate: moment().format("YYYY-MM-DD"),
                          policyExpirationDate: "",
                          brand: "Altro",
                          power: "",
                          displacement: "",
                          horsePower: "",
                          supply: "",
                          outfitting: "Altro",
                          newValue: "",
                          usedValue: "",
                          vehicleType: "C",
                          model: "Altro",
                          codiceMotornet: "Altro",
                          drivingLicenseMonth: "",
                          drivingLicenseYear: "",
                          nSons1: "",
                          nSons2: "",
                          nSons3: "",
                          nSons4: "",
                          sons: "",
                          drivingLicenseType: "",
                          drivingLicensePoints: "",
                          //kmAnnui: "",
                          //guideType: "",
                          //youngestLicensedAge: "",
                          maximal: "3",
                          splittingUp: 1,
                          insuranceYears: "",
                          currentAward: "",
                          weight: 0,
                          use: "",
                          dataATRC: {
                            anno0Atrc: moment().format("YYYY"),
                            atrcRespPrincAnno0: "--",
                            atrcRespPrincDanniCoseAnno0: "--",
                            atrcRespPrincDanniPersAnno0: "--",
                            atrcRespPrincDanniMistiAnno0: "--",
                            atrcRespPariAnno0: "--",
                            atrcRespPariDanniCoseAnno0: "--",
                            atrcRespPariDanniPersAnno0: "--",
                            atrcRespPariDanniMistiAnno0: "--",
                            atrcRespPrincAnno1: "--",
                            atrcRespPrincDanniCoseAnno1: "--",
                            atrcRespPrincDanniPersAnno1: "--",
                            atrcRespPrincDanniMistiAnno1: "--",
                            atrcRespPariAnno1: "--",
                            atrcRespPariDanniCoseAnno1: "--",
                            atrcRespPariDanniPersAnno1: "--",
                            atrcRespPariDanniMistiAnno1: "--",
                            atrcRespPrincAnno2: "--",
                            atrcRespPrincDanniCoseAnno2: "--",
                            atrcRespPrincDanniPersAnno2: "--",
                            atrcRespPrincDanniMistiAnno2: "--",
                            atrcRespPariAnno2: "--",
                            atrcRespPariDanniCoseAnno2: "--",
                            atrcRespPariDanniPersAnno2: "--",
                            atrcRespPariDanniMistiAnno2: "--",
                            atrcRespPrincAnno3: "--",
                            atrcRespPrincDanniCoseAnno3: "--",
                            atrcRespPrincDanniPersAnno3: "--",
                            atrcRespPrincDanniMistiAnno3: "--",
                            atrcRespPariAnno3: "--",
                            atrcRespPariDanniCoseAnno3: "--",
                            atrcRespPariDanniPersAnno3: "--",
                            atrcRespPariDanniMistiAnno3: "--",
                            atrcRespPrincAnno4: "--",
                            atrcRespPrincDanniCoseAnno4: "--",
                            atrcRespPrincDanniPersAnno4: "--",
                            atrcRespPrincDanniMistiAnno4: "--",
                            atrcRespPariAnno4: "--",
                            atrcRespPariDanniCoseAnno4: "--",
                            atrcRespPariDanniPersAnno4: "--",
                            atrcRespPariDanniMistiAnno4: "--",
                            atrcRespPrincAnno5: "--",
                            atrcRespPrincDanniCoseAnno5: "--",
                            atrcRespPrincDanniPersAnno5: "--",
                            atrcRespPrincDanniMistiAnno5: "--",
                            atrcRespPariAnno5: "--",
                            atrcRespPariDanniCoseAnno5: "--",
                            atrcRespPariDanniPersAnno5: "--",
                            atrcRespPariDanniMistiAnno5: "--",
                            atrcRespPrincAnno6: "--",
                            atrcRespPrincDanniCoseAnno6: "--",
                            atrcRespPrincDanniPersAnno6: "--",
                            atrcRespPrincDanniMistiAnno6: "--",
                            atrcRespPariAnno6: "--",
                            atrcRespPariDanniCoseAnno6: "--",
                            atrcRespPariDanniPersAnno6: "--",
                            atrcRespPariDanniMistiAnno6: "--",
                            atrcRespPrincAnno7: "--",
                            atrcRespPrincDanniCoseAnno7: "--",
                            atrcRespPrincDanniPersAnno7: "--",
                            atrcRespPrincDanniMistiAnno7: "--",
                            atrcRespPariAnno7: "--",
                            atrcRespPariDanniCoseAnno7: "--",
                            atrcRespPariDanniPersAnno7: "--",
                            atrcRespPariDanniMistiAnno7: "--",
                            atrcRespPrincAnno8: "--",
                            atrcRespPrincDanniCoseAnno8: "--",
                            atrcRespPrincDanniPersAnno8: "--",
                            atrcRespPrincDanniMistiAnno8: "--",
                            atrcRespPariAnno8: "--",
                            atrcRespPariDanniCoseAnno8: "--",
                            atrcRespPariDanniPersAnno8: "--",
                            atrcRespPariDanniMistiAnno8: "--",
                            atrcRespPrincAnno9: "--",
                            atrcRespPrincDanniCoseAnno9: "--",
                            atrcRespPrincDanniPersAnno9: "--",
                            atrcRespPrincDanniMistiAnno9: "--",
                            atrcRespPariAnno9: "--",
                            atrcRespPariDanniCoseAnno9: "--",
                            atrcRespPariDanniPersAnno9: "--",
                            atrcRespPariDanniMistiAnno9: "--",
                            atrcRespPrincAnno10: "--",
                            atrcRespPrincDanniCoseAnno10: "--",
                            atrcRespPrincDanniPersAnno10: "--",
                            atrcRespPrincDanniMistiAnno10: "--",
                            atrcRespPariAnno10: "--",
                            atrcRespPariDanniCoseAnno10: "--",
                            atrcRespPariDanniPersAnno10: "--",
                            atrcRespPariDanniMistiAnno10: "--"
                          },
                          "listDataCARD": [

                          ],
                        };
                      } else {
                        newdatiania = payload.data;
                      }

                      /*
                      Imposta i parametri di dafault
                      se presenti nei default aziendali altrimenti li inizializza a vuoto
                      */
                      //newdatiania.contractorInfo.profession = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).professionVan !== undefined ? JSON.parse(posinfo.defAg).professionVan : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).professionVan !== undefined ? JSON.parse(posinfo.defGrp).professionVan : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).professionVan !== undefined ? JSON.parse(posinfo.defOrg).professionVan : "")));
                      //newdatiania.contractorInfo.qualification = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).qualificationVan !== undefined ? JSON.parse(posinfo.defAg).qualificationVan : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).qualificationVan !== undefined ? JSON.parse(posinfo.defGrp).qualificationVan : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).qualificationVan !== undefined ? JSON.parse(posinfo.defOrg).qualificationVan : "")));
                      //newdatiania.contractorInfo.maritalStatus = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).maritalStatusVan !== undefined ? JSON.parse(posinfo.defAg).maritalStatusVan : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).maritalStatusVan !== undefined ? JSON.parse(posinfo.defGrp).maritalStatusVan : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).maritalStatusVan !== undefined ? JSON.parse(posinfo.defOrg).maritalStatusVan : "")));
                      newdatiania.contractorInfo.residenceInfo.toponym = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).toponymVan !== undefined ? JSON.parse(posinfo.defAg).toponymVan : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).toponymVan !== undefined ? JSON.parse(posinfo.defGrp).toponymVan : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).toponymVan !== undefined ? JSON.parse(posinfo.defOrg).toponymVan : "")));
                      newdatiania.contractorInfo.residenceInfo.address = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).addressVan !== undefined ? JSON.parse(posinfo.defAg).addressVan : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).addressVan !== undefined ? JSON.parse(posinfo.defGrp).addressVan : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).addressVan !== undefined ? JSON.parse(posinfo.defOrg).addressVan : "")));
                      newdatiania.contractorInfo.residenceInfo.streetNumber = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).streetNumberVan !== undefined ? JSON.parse(posinfo.defAg).streetNumberVan : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).streetNumberVan !== undefined ? JSON.parse(posinfo.defGrp).streetNumberVan : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).streetNumberVan !== undefined ? JSON.parse(posinfo.defOrg).streetNumberVan : "")));
                      newdatiania.contractorInfo.email = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).emailVan !== undefined ? JSON.parse(posinfo.defAg).emailVan : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).emailVan !== undefined ? JSON.parse(posinfo.defGrp).emailVan : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).emailVan !== undefined ? JSON.parse(posinfo.defOrg).emailVan : "")));
                      newdatiania.contractorInfo.phoneNumber = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).phoneNumberVan !== undefined ? JSON.parse(posinfo.defAg).phoneNumberVan : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).phoneNumberVan !== undefined ? JSON.parse(posinfo.defGrp).phoneNumberVan : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).phoneNumberVan !== undefined ? JSON.parse(posinfo.defOrg).phoneNumberVan : "")));
                      newdatiania.ownerInfo.residenceInfo.toponym = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).toponymVan !== undefined ? JSON.parse(posinfo.defAg).toponymVan : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).toponymVan !== undefined ? JSON.parse(posinfo.defGrp).toponymVan : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).toponymVan !== undefined ? JSON.parse(posinfo.defOrg).toponymVan : "")));
                      newdatiania.ownerInfo.residenceInfo.address = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).addressVan !== undefined ? JSON.parse(posinfo.defAg).addressVan : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).addressVan !== undefined ? JSON.parse(posinfo.defGrp).addressVan : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).addressVan !== undefined ? JSON.parse(posinfo.defOrg).addressVan : "")));
                      newdatiania.ownerInfo.residenceInfo.streetNumber = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).streetNumberVan !== undefined ? JSON.parse(posinfo.defAg).streetNumberVan : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).streetNumberVan !== undefined ? JSON.parse(posinfo.defGrp).streetNumberVan : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).streetNumberVan !== undefined ? JSON.parse(posinfo.defOrg).streetNumberVan : "")));
                      //newdatiania.kmAnnui = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).kmAnnuiVan !== undefined ? JSON.parse(posinfo.defAg).kmAnnuiVan : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).kmAnnuiVan !== undefined ? JSON.parse(posinfo.defGrp).kmAnnuiVan : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).kmAnnuiVan !== undefined ? JSON.parse(posinfo.defOrg).kmAnnuiVan : "")));
                      //newdatiania.guideType = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).guideTypeVan !== undefined ? JSON.parse(posinfo.defAg).guideTypeVan : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).guideTypeVan !== undefined ? JSON.parse(posinfo.defGrp).guideTypeVan : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).guideTypeVan !== undefined ? JSON.parse(posinfo.defOrg).guideTypeVan : "")));
                      newdatiania.maximal = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).maximalVan !== undefined ? JSON.parse(posinfo.defAg).maximalVan : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).maximalVan !== undefined ? JSON.parse(posinfo.defGrp).maximalVan : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).maximalVan !== undefined ? JSON.parse(posinfo.defOrg).maximalVan : "3")));
                      newdatiania.splittingUp = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).splittingUpVan !== undefined ? JSON.parse(posinfo.defAg).splittingUpVan : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).splittingUpVan !== undefined ? JSON.parse(posinfo.defGrp).splittingUpVan : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).splittingUpVan !== undefined ? JSON.parse(posinfo.defOrg).splittingUpVan : 1)));
                      newdatiania.insuranceYearsCU1 = 0;
                      newdatiania.insuranceYears = ReturnDefaultinsuranceYears(posinfo, newdatiania, newdatiania.contractorInfo.birthDate !== "" ? moment(newdatiania.contractorInfo.birthDate, "YYYY-MM-DD").add(18, "years").add(2, "months").format("YYYY") : "");
                      newdatiania.currentAward = "";
                      if (newdatiania.contractorInfo.residenceInfo.city === null) {
                        newdatiania.contractorInfo.residenceInfo.cap = "";
                        newdatiania.contractorInfo.residenceInfo.provinceAbbr = "";
                        newdatiania.contractorInfo.residenceInfo.city = "";
                        newdatiania.contractorInfo.residenceInfo.istat = "";
                      }
                      if (newdatiania.contractorInfo.residenceInfo.toponym === null) {
                        newdatiania.contractorInfo.residenceInfo.toponym = "";
                      }
                      if (newdatiania.contractorInfo.residenceInfo.address === null) {
                        newdatiania.contractorInfo.residenceInfo.address = "";
                      }
                      if (newdatiania.contractorInfo.residenceInfo.streetNumber === null) {
                        newdatiania.contractorInfo.residenceInfo.streetNumber = "";
                      }
                      if (newdatiania.ownerInfo.residenceInfo.city === null) {
                        newdatiania.ownerInfo.residenceInfo.cap = "";
                        newdatiania.ownerInfo.residenceInfo.provinceAbbr = "";
                        newdatiania.ownerInfo.residenceInfo.city = "";
                        newdatiania.ownerInfo.residenceInfo.istat = "";
                      }
                      newdatiania.alarm = "2"; //Generico

                      if (posinfo.defAg !== null && JSON.parse(posinfo.defAg).effectiveDateVan !== undefined ? true : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).effectiveDateVan !== undefined ? true : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).effectiveDateVan !== undefined ? true : false))) {
                        //Imposta al 15 o a fine mese
                        if ((posinfo.defAg !== null && JSON.parse(posinfo.defAg).effectiveDateVan !== undefined ? JSON.parse(posinfo.defAg).effectiveDateVan : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).effectiveDateVan !== undefined ? JSON.parse(posinfo.defGrp).effectiveDateVan : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).effectiveDateVan !== undefined ? JSON.parse(posinfo.defOrg).effectiveDateVan : "2"))) === "1") {
                          if (moment(newdatiania.effectiveDate, "YYYY-MM-DD").format("DD") > 15) {
                            newdatiania.effectiveDate = moment(datiania.effectiveDate).endOf('month');
                          } else {
                            newdatiania.effectiveDate = moment(datiania.effectiveDate, "YYYY-MM-DD").format("YYYY-MM") + "-15";
                          }
                        }
                      }

                      let n = 0;
                      for (const cvt of guarantees) {
                        if (tipoprev !== "CVT" && cvt.code === "RCA") {
                          guaranteesSelect[n] = true;
                        }
                        if (posinfo.defAg !== null && JSON.parse(posinfo.defAg)["VAN_" + cvt.code] !== undefined ? true : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp)["VAN_" + cvt.code] !== undefined ? true : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg)["VAN_" + cvt.code] !== undefined ? true : false))) {
                          let flag = (posinfo.defAg !== null && JSON.parse(posinfo.defAg)["VAN_" + cvt.code] !== undefined ? JSON.parse(posinfo.defAg)["VAN_" + cvt.code] : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp)["VAN_" + cvt.code] !== undefined ? JSON.parse(posinfo.defGrp)["VAN_" + cvt.code] : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg)["VAN_" + cvt.code] !== undefined ? JSON.parse(posinfo.defOrg)["VAN_" + cvt.code] : 0)));
                          if (flag > 0) {
                            guaranteesSelect[n] = true;
                          }
                        }
                        n++;
                      }

                      if (PreCompile) {
                        let addressElement = PreCompile.indirizzo.split(" ");

                        if (newdatiania.contractorInfo.fiscalCode === PreCompile.codicefiscale) {
                        } else {
                        }

                        newdatiania.contractorInfo.residenceInfo.toponym = addressElement[0];
                        addressElement.splice(0, 1);
                        let indirizzo = addressElement.join(" ");
                        newdatiania.contractorInfo.residenceInfo.address = indirizzo;
                        newdatiania.contractorInfo.residenceInfo.streetNumber = PreCompile.numerocivico;
                        newdatiania.contractorInfo.residenceInfo.provinceAbbr = PreCompile.provincia;
                        newdatiania.contractorInfo.residenceInfo.cap = PreCompile.cap;
                        newdatiania.contractorInfo.residenceInfo.istat = PreCompile.istat;
                        newdatiania.contractorInfo.fiscalCode = PreCompile.codicefiscale;

                        getInfoBirth(token, urlservicequotiamo, PreCompile.codicefiscale, PreCompile.nome, PreCompile.cognome).then(payload => {
                          newdatiania.contractorInfo.birthCity = payload.data.city;
                          newdatiania.contractorInfo.birthProvince = payload.data.province;
                          newdatiania.contractorInfo.birthRegion = payload.data.region;
                          newdatiania.contractorInfo.birthNationAbbr = payload.data.nationAbbr;
                          newdatiania.contractorInfo.birthNation = payload.data.nation;
                          newdatiania.contractorInfo.gender = payload.data.gender;
                          newdatiania.contractorInfo.birthDate = payload.data.birthDate;
                        });

                        newdatiania.contractorInfo.residenceInfo.city = PreCompile.comune;
                        newdatiania.contractorInfo.phoneNumber = PreCompile.telefonomobile;
                        newdatiania.contractorInfo.email = PreCompile.email;
                        newdatiania.contractorInfo.name = PreCompile.nome;
                        newdatiania.contractorInfo.surname = PreCompile.cognome;

                        //Massimale
                        //1: {domanda: 'Massimali RCA', risposta: '5000000', sottodomanda1: '', sottorisposta1: '', sottodomanda2: '', …}
                        if (Number(PreCompile[1].risposta) >= 10000000) {
                          newdatiania.maximal = '3';
                        } else {
                          if (Number(PreCompile[1].risposta)) {
                            newdatiania.maximal = '3';
                          }
                        }
                        //Km annui
                        //3: {domanda: 'KM annui', risposta: '7000', sottodomanda1: '', sottorisposta1: '', sottodomanda2: '', …}
                        /*if (Number(PreCompile[3].risposta)) {
                          if (Number(PreCompile[3].risposta) < 10000) {
                            newdatiania.kmAnnui = '1';
                          } else if (Number(PreCompile[3].risposta) < 20000) {
                            newdatiania.kmAnnui = '2';
                          } else if (Number(PreCompile[3].risposta) < 30000) {
                            newdatiania.kmAnnui = '3';
                          } else {
                            newdatiania.kmAnnui = '4';
                          }
                        }*/
                        //usedValue Valore Veicolo
                        //5: {domanda: 'Valore veicolo', risposta: '1000', sottodomanda1: '', sottorisposta1: '', sottodomanda2: '', …}
                        if (Number(PreCompile[5].risposta)) {
                          newdatiania.usedValue = Number(PreCompile[5].risposta);
                          newdatiania.infoCar[0].usedValue = Number(PreCompile[5].risposta)
                        }
                        //Incendio / Furto
                        //6: {domanda: 'Incendio / Furto', risposta: '', sottodomanda1: 'Capitale', sottorisposta1: '10000', sottodomanda2: 'Capitale', …}
                        if (Number(PreCompile[6].sottorisposta1) > 0) {
                          newdatiania.furto = true;
                        }
                        //7: {domanda: 'Collisione comunque verificatasi', risposta: '', sottodomanda1: 'Capitale', sottorisposta1: '', sottodomanda2: 'Capitale', …}
                        //8: {domanda: 'Collisione con altro veicolo identificato', risposta: '', sottodomanda1: 'Capitale', sottorisposta1: '', sottodomanda2: 'Capitale', …}
                        //9: {domanda: 'Collisione ungulati', risposta: '', sottodomanda1: 'Capitale', sottorisposta1: '', sottodomanda2: 'Capitale', …}
                        if (Number(PreCompile[7].sottorisposta1) > 0 || Number(PreCompile[8].sottorisposta1) > 0) {
                          newdatiania.collisione = true;
                        }
                        //10: {domanda: 'Eventi naturali', risposta: '', sottodomanda1: 'Capitale', sottorisposta1: '5000', sottodomanda2: 'Capitale', …}
                        if (Number(PreCompile[10].sottorisposta1) > 0) {
                          newdatiania.eventiNaturali = true;
                        }
                        //11: {domanda: 'Eventi sociopolitici', risposta: '', sottodomanda1: 'Capitale', sottorisposta1: '4000', sottodomanda2: 'Capitale', …}
                        //12: {domanda: 'Atti vandalici', risposta: '', sottodomanda1: 'Capitale', sottorisposta1: '3000', sottodomanda2: 'Capitale', …}
                        if (Number(PreCompile[11].sottorisposta1) > 0 || Number(PreCompile[12].sottorisposta1) > 0) {
                          newdatiania.attiVandalici = true;
                        }
                        //13: {domanda: 'Rottura cristalli', risposta: '', sottodomanda1: 'Capitale', sottorisposta1: '1000', sottodomanda2: 'Capitale', …}
                        if (Number(PreCompile[13].sottorisposta1) > 0) {
                          newdatiania.cristalli = true;
                        }
                        //14: {domanda: 'Infortuni del conducente', risposta: '', sottodomanda1: 'Capitale', sottorisposta1: '50000', sottodomanda2: 'Capitale', …}
                        if (Number(PreCompile[14].sottorisposta1) > 0) {
                          newdatiania.infortunioConducente = true;
                        }
                        //18: {domanda: 'Premio', risposta: '550', sottodomanda1: '', sottorisposta1: '', sottodomanda2: '', …}
                        if (Number(PreCompile[18].risposta) > 0) {
                          newdatiania.currentAward = Number(PreCompile[18].risposta);
                        }
                        //25: {domanda: 'Guida esperta', risposta: '1', sottodomanda1: '', sottorisposta1: '', sottodomanda2: '', …}
                        /*if (Number(PreCompile[25].risposta) === 1) {
                          newdatiania.guideType = 'ESP';
                        }
                        if (Number(PreCompile[25].risposta) === 2) {
                          newdatiania.guideType = 'LIB';
                        }*/
                        //26: {domanda: 'Guida esclusiva', risposta: '2', sottodomanda1: '', sottorisposta1: '', sottodomanda2: '', …}
                        //27: {domanda: 'Conducente sotto i 25 anni', risposta: '1', sottodomanda1: '', sottorisposta1: '', sottodomanda2: '', …}
                        if (Number(PreCompile[27].risposta) === 1) {
                          newdatiania.youngestLicensedAge = '24';
                        }
                        //28: {domanda: 'Rinuncia alla rivalsa', risposta: '1', sottodomanda1: '', sottorisposta1: '', sottodomanda2: '', …}
                        if (Number(PreCompile[28].risposta) === 1) {
                          newdatiania.revengeWaiver = true;
                        }
                        //29: {domanda: 'Assistenza', risposta: '1', sottodomanda1: '', sottorisposta1: '', sottodomanda2: '', …}
                        if (Number(PreCompile[29].risposta) === 1) {
                          newdatiania.assistenzaStradale = true;
                        }
                        //30: {domanda: 'Sospenzione patente', risposta: '', sottodomanda1: '', sottorisposta1: '', sottodomanda2: '', …}
                        //31: {domanda: 'Tutela legale', risposta: '1', sottodomanda1: '', sottorisposta1: '', sottodomanda2: '', …}
                        if (Number(PreCompile[31].risposta) === 1) {
                          newdatiania.tutelaLegale = true;
                        }
                      }

                      if (newdatiania.contractorInfo.vatNumber !== '') {
                        if (newdatiania.contractorInfo.vatNumber === newdatiania.ownerInfo.vatNumber) {
                          setSameOwner(true);
                        } else {
                          setSameOwner(false);
                        }
                      } else {
                        if (newdatiania.contractorInfo.fiscalCode === newdatiania.ownerInfo.fiscalCode) {
                          setSameOwner(true);
                        } else {
                          setSameOwner(false);
                        }
                      }

                      if (payload.data.errorCode === 2006 || payload.data.errorCode === 1006 || payload.data.errorCode === 3002 || payload.data.errorCode === 3001) {
                        setAlertMsg(payload.data.message);
                        setAlertSeverity("error");
                        setOpen(true);
                        setLoading(false);
                        return;
                      }

                      if (payload.data.error !== undefined) {
                        setAlertMsg("Non è stato possibile contattare ANIA, inserire i dati mancanti");
                        setAlertSeverity("warning");
                        setOpen(true);
                        setdatiania(newdatiania);
                        setActiveStep((prevActiveStep) => prevActiveStep + 1);
                        setLoading(false);
                      } else {
                        /*if (moment().add(DAYS_BEFORE_QUOTATION, "days") <= moment(payload.data.effectiveDate, "YYYY-MM-DD")) {
                          setAlertMsg(<div>Questo veicolo risulta già assicurato con data di scadenza: {moment(payload.data.effectiveDate, "YYYY-MM-DD").format("DD-MM-YYYY")}<br />Non è possibile quotare assicurazioni con data di scadenza superiore al {moment().add(DAYS_BEFORE_QUOTATION, "days").format("DD-MM-YYYY")}</div>);
                          setAlertSeverity("error");
                          setOpen(true);
                          setLoading(false);
                          return;
                        }*/

                        if (payload.data.vehicleType !== "C" && payload.data.vehicleType !== "R" && payload.data.vehicleType !== "") {
                          setAlertMsg("Non è possibile quotare tipi veicoli diversi da AUTOCARRI/RIMORCHI");
                          setAlertSeverity("error");
                          setOpen(true);
                          setLoading(false);
                          return;
                        }

                        if ((newdatiania.contractorInfo.gender === null || newdatiania.contractorInfo.gender === "") && newdatiania.contractorInfo.fiscalCode !== "") {
                          newdatiania.contractorInfo.subjectType = "4";
                        }

                        setdatiania({
                          ...newdatiania,
                          outfitting: newdatiania.infoCar[0].outfitting,
                          newValue: newdatiania.infoCar[0].newValue,
                          usedValue: newdatiania.infoCar[0].usedValue,
                          model: newdatiania.infoCar[0].model,
                          //carBody: newdatiania.infoCar[0].carBody,
                          power: newdatiania.infoCar[0].power,
                          displacement: newdatiania.infoCar[0].displacement,
                          horsePower: newdatiania.infoCar[0].horsePower,
                          supply: newdatiania.infoCar[0].supply,
                          codiceMotornet: newdatiania.infoCar[0].codiceMotornet,
                        });

                        setActiveStep((prevActiveStep) => prevActiveStep + 1);
                        setLoading(false);
                      }
                    }).catch(error => {
                      alert("QUOTIAMO service non abilitato o non raggiungibile (A1)");
                      setLoading(false);
                    }
                    );
                }).catch(error => {
                  alert("QUOTIAMO service non abilitato o non raggiungibile (3)");
                  setLoading(false);
                }
                );
            } else {
              let newdatiania = {
                contractorInfo: {
                  subjectType: "1",
                  name: "",
                  surname: "",
                  birthDate: "",
                  companyName: "",
                  gender: "M",
                  fiscalCode: "",
                  birthCity: "",
                  birthProvince: "",
                  birthRegion: "",
                  birthNationAbbr: "",
                  birthNation: "",
                  email: "",
                  phoneNumber: "",
                  profession: "",
                  qualification: "",
                  maritalStatus: "",
                  residenceInfo: {
                    city: "",
                    cap: "",
                    provinceAbbr: "",
                    toponym: "",
                    address: "",
                    streetNumber: "",
                    istat: "",
                  }
                },
                ownerInfo: {
                  subjectType: "1",
                  name: "",
                  surname: "",
                  birthDate: "",
                  gender: "M",
                  fiscalCode: "",
                  companyName: "",
                  birthCity: "",
                  birthProvince: "",
                  birthRegion: "",
                  birthNationAbbr: "",
                  birthNation: "",
                  email: "",
                  phoneNumber: "",
                  profession: "",
                  qualification: "",
                  maritalStatus: "",
                  residenceInfo: {
                    city: "",
                    cap: "",
                    provinceAbbr: "",
                    toponym: "",
                    address: "",
                    streetNumber: "",
                    istat: "",
                  }
                },
                effectiveDate: moment().format("YYYY-MM-DD"),
                policyExpirationDate: "",
                brand: "Altro",
                //carBody: "",
                power: "",
                displacement: "",
                horsePower: "",
                supply: "",
                outfitting: "Altro",
                newValue: "",
                usedValue: "",
                model: "Altro",
                codiceMotornet: "Altro",
                //kmAnnui: "",
                //guideType: "",
                maximal: "3",
                vehicleType: "C",
                splittingUp: 1,
                insuranceYears: "",
                currentAward: "",
                weight: 0,
                use: "",
                dataATRC: {
                  anno0Atrc: moment().format("YYYY"),
                  atrcRespPrincAnno0: "--",
                  atrcRespPrincDanniCoseAnno0: "--",
                  atrcRespPrincDanniPersAnno0: "--",
                  atrcRespPrincDanniMistiAnno0: "--",
                  atrcRespPariAnno0: "--",
                  atrcRespPariDanniCoseAnno0: "--",
                  atrcRespPariDanniPersAnno0: "--",
                  atrcRespPariDanniMistiAnno0: "--",
                  atrcRespPrincAnno1: "--",
                  atrcRespPrincDanniCoseAnno1: "--",
                  atrcRespPrincDanniPersAnno1: "--",
                  atrcRespPrincDanniMistiAnno1: "--",
                  atrcRespPariAnno1: "--",
                  atrcRespPariDanniCoseAnno1: "--",
                  atrcRespPariDanniPersAnno1: "--",
                  atrcRespPariDanniMistiAnno1: "--",
                  atrcRespPrincAnno2: "--",
                  atrcRespPrincDanniCoseAnno2: "--",
                  atrcRespPrincDanniPersAnno2: "--",
                  atrcRespPrincDanniMistiAnno2: "--",
                  atrcRespPariAnno2: "--",
                  atrcRespPariDanniCoseAnno2: "--",
                  atrcRespPariDanniPersAnno2: "--",
                  atrcRespPariDanniMistiAnno2: "--",
                  atrcRespPrincAnno3: "--",
                  atrcRespPrincDanniCoseAnno3: "--",
                  atrcRespPrincDanniPersAnno3: "--",
                  atrcRespPrincDanniMistiAnno3: "--",
                  atrcRespPariAnno3: "--",
                  atrcRespPariDanniCoseAnno3: "--",
                  atrcRespPariDanniPersAnno3: "--",
                  atrcRespPariDanniMistiAnno3: "--",
                  atrcRespPrincAnno4: "--",
                  atrcRespPrincDanniCoseAnno4: "--",
                  atrcRespPrincDanniPersAnno4: "--",
                  atrcRespPrincDanniMistiAnno4: "--",
                  atrcRespPariAnno4: "--",
                  atrcRespPariDanniCoseAnno4: "--",
                  atrcRespPariDanniPersAnno4: "--",
                  atrcRespPariDanniMistiAnno4: "--",
                  atrcRespPrincAnno5: "--",
                  atrcRespPrincDanniCoseAnno5: "--",
                  atrcRespPrincDanniPersAnno5: "--",
                  atrcRespPrincDanniMistiAnno5: "--",
                  atrcRespPariAnno5: "--",
                  atrcRespPariDanniCoseAnno5: "--",
                  atrcRespPariDanniPersAnno5: "--",
                  atrcRespPariDanniMistiAnno5: "--",
                  atrcRespPrincAnno6: "--",
                  atrcRespPrincDanniCoseAnno6: "--",
                  atrcRespPrincDanniPersAnno6: "--",
                  atrcRespPrincDanniMistiAnno6: "--",
                  atrcRespPariAnno6: "--",
                  atrcRespPariDanniCoseAnno6: "--",
                  atrcRespPariDanniPersAnno6: "--",
                  atrcRespPariDanniMistiAnno6: "--",
                  atrcRespPrincAnno7: "--",
                  atrcRespPrincDanniCoseAnno7: "--",
                  atrcRespPrincDanniPersAnno7: "--",
                  atrcRespPrincDanniMistiAnno7: "--",
                  atrcRespPariAnno7: "--",
                  atrcRespPariDanniCoseAnno7: "--",
                  atrcRespPariDanniPersAnno7: "--",
                  atrcRespPariDanniMistiAnno7: "--",
                  atrcRespPrincAnno8: "--",
                  atrcRespPrincDanniCoseAnno8: "--",
                  atrcRespPrincDanniPersAnno8: "--",
                  atrcRespPrincDanniMistiAnno8: "--",
                  atrcRespPariAnno8: "--",
                  atrcRespPariDanniCoseAnno8: "--",
                  atrcRespPariDanniPersAnno8: "--",
                  atrcRespPariDanniMistiAnno8: "--",
                  atrcRespPrincAnno9: "--",
                  atrcRespPrincDanniCoseAnno9: "--",
                  atrcRespPrincDanniPersAnno9: "--",
                  atrcRespPrincDanniMistiAnno9: "--",
                  atrcRespPariAnno9: "--",
                  atrcRespPariDanniCoseAnno9: "--",
                  atrcRespPariDanniPersAnno9: "--",
                  atrcRespPariDanniMistiAnno9: "--",
                  atrcRespPrincAnno10: "--",
                  atrcRespPrincDanniCoseAnno10: "--",
                  atrcRespPrincDanniPersAnno10: "--",
                  atrcRespPrincDanniMistiAnno10: "--",
                  atrcRespPariAnno10: "--",
                  atrcRespPariDanniCoseAnno10: "--",
                  atrcRespPariDanniPersAnno10: "--",
                  atrcRespPariDanniMistiAnno10: "--"
                },
                "listDataCARD": [

                ],
              };
              /*
                      Imposta i parametri di dafault
                      se presenti nei default aziendali altrimenti li inizializza a vuoto
                      */
              //newdatiania.contractorInfo.profession = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).professionVan !== undefined ? JSON.parse(posinfo.defAg).professionVan : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).professionVan !== undefined ? JSON.parse(posinfo.defGrp).professionVan : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).professionVan !== undefined ? JSON.parse(posinfo.defOrg).professionVan : "")));
              //newdatiania.contractorInfo.qualification = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).qualificationVan !== undefined ? JSON.parse(posinfo.defAg).qualificationVan : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).qualificationVan !== undefined ? JSON.parse(posinfo.defGrp).qualificationVan : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).qualificationVan !== undefined ? JSON.parse(posinfo.defOrg).qualificationVan : "")));
              //newdatiania.contractorInfo.maritalStatus = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).maritalStatusVan !== undefined ? JSON.parse(posinfo.defAg).maritalStatusVan : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).maritalStatusVan !== undefined ? JSON.parse(posinfo.defGrp).maritalStatusVan : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).maritalStatusVan !== undefined ? JSON.parse(posinfo.defOrg).maritalStatusVan : "")));
              newdatiania.contractorInfo.residenceInfo.toponym = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).toponymVan !== undefined ? JSON.parse(posinfo.defAg).toponymVan : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).toponymVan !== undefined ? JSON.parse(posinfo.defGrp).toponymVan : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).toponymVan !== undefined ? JSON.parse(posinfo.defOrg).toponymVan : "")));
              newdatiania.contractorInfo.residenceInfo.address = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).addressVan !== undefined ? JSON.parse(posinfo.defAg).addressVan : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).addressVan !== undefined ? JSON.parse(posinfo.defGrp).addressVan : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).addressVan !== undefined ? JSON.parse(posinfo.defOrg).addressVan : "")));
              newdatiania.contractorInfo.residenceInfo.streetNumber = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).streetNumberVan !== undefined ? JSON.parse(posinfo.defAg).streetNumberVan : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).streetNumberVan !== undefined ? JSON.parse(posinfo.defGrp).streetNumberVan : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).streetNumberVan !== undefined ? JSON.parse(posinfo.defOrg).streetNumberVan : "")));
              newdatiania.contractorInfo.email = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).emailVan !== undefined ? JSON.parse(posinfo.defAg).emailVan : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).emailVan !== undefined ? JSON.parse(posinfo.defGrp).emailVan : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).emailVan !== undefined ? JSON.parse(posinfo.defOrg).emailVan : "")));
              newdatiania.contractorInfo.phoneNumber = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).phoneNumberVan !== undefined ? JSON.parse(posinfo.defAg).phoneNumberVan : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).phoneNumberVan !== undefined ? JSON.parse(posinfo.defGrp).phoneNumberVan : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).phoneNumberVan !== undefined ? JSON.parse(posinfo.defOrg).phoneNumberVan : "")));
              newdatiania.ownerInfo.residenceInfo.toponym = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).toponymVan !== undefined ? JSON.parse(posinfo.defAg).toponymVan : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).toponymVan !== undefined ? JSON.parse(posinfo.defGrp).toponymVan : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).toponymVan !== undefined ? JSON.parse(posinfo.defOrg).toponymVan : "")));
              newdatiania.ownerInfo.residenceInfo.address = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).addressVan !== undefined ? JSON.parse(posinfo.defAg).addressVan : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).addressVan !== undefined ? JSON.parse(posinfo.defGrp).addressVan : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).addressVan !== undefined ? JSON.parse(posinfo.defOrg).addressVan : "")));
              newdatiania.ownerInfo.residenceInfo.streetNumber = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).streetNumberVan !== undefined ? JSON.parse(posinfo.defAg).streetNumberVan : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).streetNumberVan !== undefined ? JSON.parse(posinfo.defGrp).streetNumberVan : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).streetNumberVan !== undefined ? JSON.parse(posinfo.defOrg).streetNumberVan : "")));
              //newdatiania.kmAnnui = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).kmAnnuiVan !== undefined ? JSON.parse(posinfo.defAg).kmAnnuiVan : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).kmAnnuiVan !== undefined ? JSON.parse(posinfo.defGrp).kmAnnuiVan : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).kmAnnuiVan !== undefined ? JSON.parse(posinfo.defOrg).kmAnnuiVan : "")));
              //newdatiania.guideType = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).guideTypeVan !== undefined ? JSON.parse(posinfo.defAg).guideTypeVan : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).guideTypeVan !== undefined ? JSON.parse(posinfo.defGrp).guideTypeVan : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).guideTypeVan !== undefined ? JSON.parse(posinfo.defOrg).guideTypeVan : "")));
              newdatiania.maximal = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).maximalVan !== undefined ? JSON.parse(posinfo.defAg).maximalVan : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).maximalVan !== undefined ? JSON.parse(posinfo.defGrp).maximalVan : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).maximalVan !== undefined ? JSON.parse(posinfo.defOrg).maximalVan : "3")));
              newdatiania.splittingUp = (posinfo.defAg !== null && JSON.parse(posinfo.defAg).splittingUpVan !== undefined ? JSON.parse(posinfo.defAg).splittingUpVan : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).splittingUpVan !== undefined ? JSON.parse(posinfo.defGrp).splittingUpVan : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).splittingUpVan !== undefined ? JSON.parse(posinfo.defOrg).splittingUpVan : 1)));
              newdatiania.insuranceYearsCU1 = 0;
              newdatiania.insuranceYears = ReturnDefaultinsuranceYears(posinfo, newdatiania, newdatiania.contractorInfo.birthDate !== "" ? moment(newdatiania.contractorInfo.birthDate, "YYYY-MM-DD").add(18, "years").add(2, "months").format("YYYY") : "");
              newdatiania.currentAward = "";
              if (newdatiania.contractorInfo.residenceInfo.city === null) {
                newdatiania.contractorInfo.residenceInfo.cap = "";
                newdatiania.contractorInfo.residenceInfo.provinceAbbr = "";
                newdatiania.contractorInfo.residenceInfo.city = "";
                newdatiania.contractorInfo.residenceInfo.istat = "";
              }
              if (newdatiania.contractorInfo.residenceInfo.toponym === null) {
                newdatiania.contractorInfo.residenceInfo.toponym = "";
              }
              if (newdatiania.contractorInfo.residenceInfo.address === null) {
                newdatiania.contractorInfo.residenceInfo.address = "";
              }
              if (newdatiania.contractorInfo.residenceInfo.streetNumber === null) {
                newdatiania.contractorInfo.residenceInfo.streetNumber = "";
              }
              if (newdatiania.ownerInfo.residenceInfo.city === null) {
                newdatiania.ownerInfo.residenceInfo.cap = "";
                newdatiania.ownerInfo.residenceInfo.provinceAbbr = "";
                newdatiania.ownerInfo.residenceInfo.city = "";
                newdatiania.ownerInfo.residenceInfo.istat = "";
              }
              newdatiania.alarm = "2"; //Generico

              if (posinfo.defAg !== null && JSON.parse(posinfo.defAg).effectiveDateVan !== undefined ? true : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).effectiveDateVan !== undefined ? true : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).effectiveDateVan !== undefined ? true : false))) {
                //Imposta al 15 o a fine mese
                if ((posinfo.defAg !== null && JSON.parse(posinfo.defAg).effectiveDateVan !== undefined ? JSON.parse(posinfo.defAg).effectiveDateVan : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp).effectiveDateVan !== undefined ? JSON.parse(posinfo.defGrp).effectiveDateVan : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg).effectiveDateVan !== undefined ? JSON.parse(posinfo.defOrg).effectiveDateVan : "2"))) === "1") {
                  if (moment(newdatiania.effectiveDate, "YYYY-MM-DD").format("DD") > 15) {
                    newdatiania.effectiveDate = moment(datiania.effectiveDate).endOf('month');
                  } else {
                    newdatiania.effectiveDate = moment(datiania.effectiveDate, "YYYY-MM-DD").format("YYYY-MM") + "-15";
                  }
                }
              }

              let n = 0;
              for (const cvt of guarantees) {
                if (tipoprev !== "CVT" && cvt.code === "RCA") {
                  guaranteesSelect[n] = true;
                }
                if (posinfo.defAg !== null && JSON.parse(posinfo.defAg)["VAN_" + cvt.code] !== undefined ? true : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp)["VAN_" + cvt.code] !== undefined ? true : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg)["VAN_" + cvt.code] !== undefined ? true : false))) {
                  let flag = (posinfo.defAg !== null && JSON.parse(posinfo.defAg)["VAN_" + cvt.code] !== undefined ? JSON.parse(posinfo.defAg)["VAN_" + cvt.code] : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp)["VAN_" + cvt.code] !== undefined ? JSON.parse(posinfo.defGrp)["VAN_" + cvt.code] : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg)["VAN_" + cvt.code] !== undefined ? JSON.parse(posinfo.defOrg)["VAN_" + cvt.code] : 0)));
                  if (flag > 0) {
                    guaranteesSelect[n] = true;
                  }
                }
                n++;
              }

              if (PreCompile) {
                let addressElement = PreCompile.indirizzo.split(" ");

                if (newdatiania.contractorInfo.fiscalCode === PreCompile.codicefiscale) {
                } else {
                }

                newdatiania.contractorInfo.residenceInfo.toponym = addressElement[0];
                addressElement.splice(0, 1);
                let indirizzo = addressElement.join(" ");
                newdatiania.contractorInfo.residenceInfo.address = indirizzo;
                newdatiania.contractorInfo.residenceInfo.streetNumber = PreCompile.numerocivico;
                newdatiania.contractorInfo.residenceInfo.provinceAbbr = PreCompile.provincia;
                newdatiania.contractorInfo.residenceInfo.cap = PreCompile.cap;
                newdatiania.contractorInfo.residenceInfo.istat = PreCompile.istat;
                newdatiania.contractorInfo.fiscalCode = PreCompile.codicefiscale;

                getInfoBirth(token, urlservicequotiamo, PreCompile.codicefiscale, PreCompile.nome, PreCompile.cognome).then(payload => {
                  newdatiania.contractorInfo.birthCity = payload.data.city;
                  newdatiania.contractorInfo.birthProvince = payload.data.province;
                  newdatiania.contractorInfo.birthRegion = payload.data.region;
                  newdatiania.contractorInfo.birthNationAbbr = payload.data.nationAbbr;
                  newdatiania.contractorInfo.birthNation = payload.data.nation;
                  newdatiania.contractorInfo.gender = payload.data.gender;
                  newdatiania.contractorInfo.birthDate = payload.data.birthDate;
                });

                newdatiania.contractorInfo.residenceInfo.city = PreCompile.comune;
                newdatiania.contractorInfo.phoneNumber = PreCompile.telefonomobile;
                newdatiania.contractorInfo.email = PreCompile.email;
                newdatiania.contractorInfo.name = PreCompile.nome;
                newdatiania.contractorInfo.surname = PreCompile.cognome;

                if (newdatiania.contractorInfo.vatNumber !== '') {
                  if (newdatiania.contractorInfo.vatNumber === newdatiania.ownerInfo.vatNumber) {
                    setSameOwner(true);
                  } else {
                    setSameOwner(false);
                  }
                } else {
                  if (newdatiania.contractorInfo.fiscalCode === newdatiania.ownerInfo.fiscalCode) {
                    setSameOwner(true);
                  } else {
                    setSameOwner(false);
                  }
                }

                //Massimale
                //1: {domanda: 'Massimali RCA', risposta: '5000000', sottodomanda1: '', sottorisposta1: '', sottodomanda2: '', …}
                if (Number(PreCompile[1].risposta) >= 10000000) {
                  newdatiania.maximal = '3';
                } else {
                  if (Number(PreCompile[1].risposta)) {
                    newdatiania.maximal = '3';
                  }
                }
                //Km annui
                //3: {domanda: 'KM annui', risposta: '7000', sottodomanda1: '', sottorisposta1: '', sottodomanda2: '', …}
                /*if (Number(PreCompile[3].risposta)) {
                  if (Number(PreCompile[3].risposta) < 10000) {
                    newdatiania.kmAnnui = '1';
                  } else if (Number(PreCompile[3].risposta) < 20000) {
                    newdatiania.kmAnnui = '2';
                  } else if (Number(PreCompile[3].risposta) < 30000) {
                    newdatiania.kmAnnui = '3';
                  } else {
                    newdatiania.kmAnnui = '4';
                  }
                }*/
                //usedValue Valore Veicolo
                //5: {domanda: 'Valore veicolo', risposta: '1000', sottodomanda1: '', sottorisposta1: '', sottodomanda2: '', …}
                if (Number(PreCompile[5].risposta)) {
                  newdatiania.usedValue = Number(PreCompile[5].risposta);
                  newdatiania.infoCar[0].usedValue = Number(PreCompile[5].risposta)
                }
                //Incendio / Furto
                //6: {domanda: 'Incendio / Furto', risposta: '', sottodomanda1: 'Capitale', sottorisposta1: '10000', sottodomanda2: 'Capitale', …}
                if (Number(PreCompile[6].sottorisposta1) > 0) {
                  newdatiania.furto = true;
                }
                //7: {domanda: 'Collisione comunque verificatasi', risposta: '', sottodomanda1: 'Capitale', sottorisposta1: '', sottodomanda2: 'Capitale', …}
                //8: {domanda: 'Collisione con altro veicolo identificato', risposta: '', sottodomanda1: 'Capitale', sottorisposta1: '', sottodomanda2: 'Capitale', …}
                //9: {domanda: 'Collisione ungulati', risposta: '', sottodomanda1: 'Capitale', sottorisposta1: '', sottodomanda2: 'Capitale', …}
                if (Number(PreCompile[7].sottorisposta1) > 0 || Number(PreCompile[8].sottorisposta1) > 0) {
                  newdatiania.collisione = true;
                }
                //10: {domanda: 'Eventi naturali', risposta: '', sottodomanda1: 'Capitale', sottorisposta1: '5000', sottodomanda2: 'Capitale', …}
                if (Number(PreCompile[10].sottorisposta1) > 0) {
                  newdatiania.eventiNaturali = true;
                }
                //11: {domanda: 'Eventi sociopolitici', risposta: '', sottodomanda1: 'Capitale', sottorisposta1: '4000', sottodomanda2: 'Capitale', …}
                //12: {domanda: 'Atti vandalici', risposta: '', sottodomanda1: 'Capitale', sottorisposta1: '3000', sottodomanda2: 'Capitale', …}
                if (Number(PreCompile[11].sottorisposta1) > 0 || Number(PreCompile[12].sottorisposta1) > 0) {
                  newdatiania.attiVandalici = true;
                }
                //13: {domanda: 'Rottura cristalli', risposta: '', sottodomanda1: 'Capitale', sottorisposta1: '1000', sottodomanda2: 'Capitale', …}
                if (Number(PreCompile[13].sottorisposta1) > 0) {
                  newdatiania.cristalli = true;
                }
                //14: {domanda: 'Infortuni del conducente', risposta: '', sottodomanda1: 'Capitale', sottorisposta1: '50000', sottodomanda2: 'Capitale', …}
                if (Number(PreCompile[14].sottorisposta1) > 0) {
                  newdatiania.infortunioConducente = true;
                }
                //18: {domanda: 'Premio', risposta: '550', sottodomanda1: '', sottorisposta1: '', sottodomanda2: '', …}
                if (Number(PreCompile[18].risposta) > 0) {
                  newdatiania.currentAward = Number(PreCompile[18].risposta);
                }
                //25: {domanda: 'Guida esperta', risposta: '1', sottodomanda1: '', sottorisposta1: '', sottodomanda2: '', …}
                /*if (Number(PreCompile[25].risposta) === 1) {
                  newdatiania.guideType = 'ESP';
                }
                if (Number(PreCompile[25].risposta) === 2) {
                  newdatiania.guideType = 'LIB';
                }*/
                //26: {domanda: 'Guida esclusiva', risposta: '2', sottodomanda1: '', sottorisposta1: '', sottodomanda2: '', …}
                //27: {domanda: 'Conducente sotto i 25 anni', risposta: '1', sottodomanda1: '', sottorisposta1: '', sottodomanda2: '', …}
                if (Number(PreCompile[27].risposta) === 1) {
                  newdatiania.youngestLicensedAge = '24';
                }
                //28: {domanda: 'Rinuncia alla rivalsa', risposta: '1', sottodomanda1: '', sottorisposta1: '', sottodomanda2: '', …}
                if (Number(PreCompile[28].risposta) === 1) {
                  newdatiania.revengeWaiver = true;
                }
                //29: {domanda: 'Assistenza', risposta: '1', sottodomanda1: '', sottorisposta1: '', sottodomanda2: '', …}
                if (Number(PreCompile[29].risposta) === 1) {
                  newdatiania.assistenzaStradale = true;
                }
                //30: {domanda: 'Sospenzione patente', risposta: '', sottodomanda1: '', sottorisposta1: '', sottodomanda2: '', …}
                //31: {domanda: 'Tutela legale', risposta: '1', sottodomanda1: '', sottorisposta1: '', sottodomanda2: '', …}
                if (Number(PreCompile[31].risposta) === 1) {
                  newdatiania.tutelaLegale = true;
                }
              }

              /*if ((newdatiania.contractorInfo.gender === null || newdatiania.contractorInfo.gender === "") && newdatiania.contractorInfo.fiscalCode !== "") {
                newdatiania.contractorInfo.subjectType = "4";
                newdatiania.guideType = "LIB";
              }*/

              setdatiania({
                ...newdatiania,
              });

              setActiveStep((prevActiveStep) => prevActiveStep + 1);
              setLoading(false);
            }
          }
        ).catch(error => {
          setAlertMsg("Prodotto non attivo per questo punto vendita");
          setAlertSeverity("error");
          setOpen(true);
          return;
        });
        break;
      case 1:
        if (datiania.contractorInfo.residenceInfo.toponym === "" ||
          datiania.contractorInfo.residenceInfo.address === "" ||
          datiania.contractorInfo.residenceInfo.streetNumber === "" ||
          datiania.contractorInfo.residenceInfo.city === "" ||
          datiania.contractorInfo.residenceInfo.cap === "" ||
          datiania.contractorInfo.residenceInfo.provinceAbbr === ""
        ) {
          setAlertMsg("Dati indirizzo contraente non completi");
          setAlertSeverity("error");
          setOpen(true);
          break;
        }

        if (datiania.contractorInfo.residenceInfo.provinceAbbr.length > 2) {
          setAlertMsg("Sigla Provincia massimo 2 lettere");
          setAlertSeverity("error");
          setOpen(true);
          break;
        }

        if (datiania.drivingLicenseYear === undefined) datiania.drivingLicenseYear = "";
        if (datiania.drivingLicensePoints === undefined) datiania.drivingLicensePoints = "";

        if (datiania.contractorInfo.subjectType === "" || datiania.contractorInfo.subjectType === "1") {
          if (datiania.contractorInfo.name === "" ||
            datiania.contractorInfo.surname === "" ||
            datiania.contractorInfo.birthDate === "" ||
            datiania.contractorInfo.gender === "" ||
            datiania.contractorInfo.fiscalCode === "" ||
            datiania.contractorInfo.birthDate === undefined
            //datiania.contractorInfo.profession === "" ||
            //datiania.contractorInfo.qualification === 0 ||
            //datiania.contractorInfo.maritalStatus === 0 ||
            //datiania.contractorInfo.maritalStatus === "" ||
            //datiania.contractorInfo.qualification === "" ||
            //datiania.contractorInfo.profession === undefined ||
            //datiania.contractorInfo.qualification === undefined ||
            //datiania.contractorInfo.maritalStatus === undefined
          ) {
            if (datiania.contractorInfo.birthDate === undefined || datiania.contractorInfo.birthDate === "") {
              setAlertMsg("Codice fiscale contraente errato");
            } else {
              setAlertMsg("Dati contraente non completi");
            }
            setAlertSeverity("error");
            setOpen(true);
            break;
          }
        } else {
          if (datiania.contractorInfo.vatNumber === undefined) datiania.contractorInfo.vatNumber = "";
          if (datiania.contractorInfo.companyName === undefined) datiania.contractorInfo.companyName = "";

          if (datiania.contractorInfo.vatNumber === "" || datiania.contractorInfo.companyName === "") {
            setAlertMsg("Dati contraente non completi");
            setAlertSeverity("error");
            setOpen(true);
            break;
          }
        }

        if (datiania.contractorInfo.email === undefined) datiania.contractorInfo.email = "";
        if (!validator.isEmail(datiania.contractorInfo.email)) {
          setAlertMsg("Email contraente non valida");
          setAlertSeverity("error");
          setOpen(true);
          break;
        }

        if (datiania.contractorInfo.phoneNumber === undefined) datiania.contractorInfo.phoneNumber = "";
        var pattern = new RegExp(/^[0-9\b]+$/);
        if (datiania.contractorInfo.phoneNumber === "" || datiania.contractorInfo.phoneNumber.length < 10 || !(validator.isMobilePhone(datiania.contractorInfo.phoneNumber, 'it-IT') /*|| pattern.test(datiania.contractorInfo.phoneNumber)*/)) {
          setAlertMsg("Numero cellulare contraente non valido");
          setAlertSeverity("error");
          setOpen(true);
          break;
        }

        if (sameOwner) {
          setdatiania({
            ...datiania,
            ownerInfo: {
              ...datiania.contractorInfo,
            },
          });
        }
        if (sameDriver) {
          setdatiania({
            ...datiania,
            driverInfo: {
              ...datiania.contractorInfo,
            }
          });
        }

        if (sameOwner === false) {
          if (datiania.ownerInfo.residenceInfo.toponym === "" ||
            datiania.ownerInfo.residenceInfo.address === "" ||
            datiania.ownerInfo.residenceInfo.streetNumber === "" ||
            datiania.ownerInfo.residenceInfo.city === "" ||
            datiania.ownerInfo.residenceInfo.cap === "" ||
            datiania.ownerInfo.residenceInfo.provinceAbbr === ""
          ) {
            setAlertMsg("Dati indirizzo intestatario non completi");
            setAlertSeverity("error");
            setOpen(true);
            break;
          }

          if (datiania.ownerInfo.residenceInfo.provinceAbbr.length > 2) {
            setAlertMsg("Sigla Provincia intestatario massimo 2 lettere");
            setAlertSeverity("error");
            setOpen(true);
            break;
          }

          if (datiania.ownerInfo.subjectType === "" || datiania.ownerInfo.subjectType === "1") {
            if (datiania.ownerInfo.name === "" ||
              datiania.ownerInfo.surname === "" ||
              datiania.ownerInfo.birthDate === "" ||
              datiania.ownerInfo.gender === "" ||
              datiania.ownerInfo.fiscalCode === "" ||
              datiania.ownerInfo.birthDate === undefined
            ) {
              if (datiania.ownerInfo.birthDate === undefined || datiania.ownerInfo.birthDate === "") {
                setAlertMsg("Codice fiscale intestatario errato");
              } else {
                setAlertMsg("Dati intestatario non completi");
              }
              setAlertSeverity("error");
              setOpen(true);
              break;
            }
          } else {
            if (datiania.ownerInfo.vatNumber === undefined) datiania.ownerInfo.vatNumber = "";
            if (datiania.ownerInfo.companyName === undefined) datiania.ownerInfo.companyName = "";

            if (datiania.ownerInfo.vatNumber === "" || datiania.ownerInfo.companyName === "") {
              setAlertMsg("Dati intestatario non completi");
              setAlertSeverity("error");
              setOpen(true);
              break;
            }
          }

          if (datiania.ownerInfo.email === undefined) datiania.ownerInfo.email = "";
          if (!validator.isEmail(datiania.ownerInfo.email)) {
            setAlertMsg("Email intestatario non valida");
            setAlertSeverity("error");
            setOpen(true);
            break;
          }

          if (datiania.ownerInfo.phoneNumber === undefined) datiania.ownerInfo.phoneNumber = "";
          var pattern = new RegExp(/^[0-9\b]+$/);
          if (datiania.ownerInfo.phoneNumber === "" || datiania.ownerInfo.phoneNumber.length < 10 || !(validator.isMobilePhone(datiania.ownerInfo.phoneNumber, 'it-IT') /*|| pattern.test(datiania.contractorInfo.phoneNumber)*/)) {
            setAlertMsg("Numero cellulare intestatario non valido");
            setAlertSeverity("error");
            setOpen(true);
            break;
          }
        }

        if (datiania.contractorInfo.subjectType === "" || datiania.contractorInfo.subjectType === "1") {
          getInfoBirth(token, urlservicequotiamo, datiania.contractorInfo.fiscalCode, datiania.contractorInfo.name, datiania.contractorInfo.surname).then(
            payload => {
              if (payload.data.status !== undefined) {
                setAlertMsg(payload.data.message);
                setAlertSeverity("error");
                setOpen(true);
                return;
              } else {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
              }
            }
          );
        } else {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        break;
      case 2:
        if (datiania.use === "") {
          setAlertMsg("Selezionare un tipo di uso");
          setAlertSeverity("error");
          setOpen(true);
          break;
        }

        if (isNaN(datiania.weight) || Number(datiania.weight) <= 0) {
          setAlertMsg("Inseriro peso veicolo (in KG)");
          setAlertSeverity("error");
          setOpen(true);
          break;
        }

        if (isNaN(datiania.usedValue) || Number(datiania.usedValue) <= 0) {
          setAlertMsg("Il valore del veicolo deve essere > 0");
          setAlertSeverity("error");
          setOpen(true);
          break;
        }

        if (isNaN(datiania.newValue) === false) {
          if (Number(datiania.newValue) > 0 && Number(datiania.usedValue) > Number(datiania.newValue)) {
            setAlertMsg("Il valore del veicolo non può essere maggiore del prezzo di listino");
            setAlertSeverity("error");
            setOpen(true);
            break;
          }
        }

        if (datiania.brand === "") {
          setAlertMsg("Inserire la MARCA del veicolo");
          setAlertSeverity("error");
          setOpen(true);
          break;
        }
        if (datiania.model === "") {
          setAlertMsg("Inserire il MODELLO del veicolo");
          setAlertSeverity("error");
          setOpen(true);
          break;
        }
        if (datiania.outfitting === "") {
          datiania.outfitting = "Altro";
        }

        var checkDate = moment(datiania.registrationDate, "YYYY-MM-DD", true);
        if (checkDate.isValid() === false) {
          setAlertMsg("Data immatricolazione non valida");
          setAlertSeverity("error");
          setOpen(true);
          break;
        }

        checkDate = moment(datiania.purchaseDate, "YYYY-MM-DD", true);
        if (checkDate.isValid() === false) {
          setAlertMsg("Data di acquisto non valida");
          setAlertSeverity("error");
          setOpen(true);
          break;
        }

        /*if (datiania.kmAnnui === '0' || datiania.kmAnnui === null || datiania.kmAnnui === undefined || datiania.kmAnnui === "") {
          setAlertMsg("Impostare i km annui percorsi");
          setAlertSeverity("error");
          setOpen(true);
          break;
        }*/

        if (tipoprev === "CVT") {
          setActiveStep((prevActiveStep) => prevActiveStep + 2);
        } else {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        break;
      case 3:
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        break;
      case 4:
        /*if (datiania.effectiveDate === null || datiania.effectiveDate > moment().add(1, "months").endOf('month').format("YYYY-MM-DD")) {
          setAlertMsg("La data di decorrenza della polizza non può essere maggiore del " + moment().add(1, "months").endOf('month').format("DD-MM-YYYY"));
          setAlertSeverity("error");
          setOpen(true);
          break;
        }*/

        if (guarantees.length < 1) {
          setAlertMsg("Nessuna garanzia selezionabile, contattare l'admin di sistema");
          setAlertSeverity("error");
          setOpen(true);
          break;
        }

        if (datiania.effectiveDate === null || datiania.effectiveDate < moment().format("YYYY-MM-DD")) {
          setAlertMsg("Il preventivo non può essere retroattivo, la data di decorrenza della polizza non può essere minore di " + moment().format("DD-MM-YYYY"));
          setAlertSeverity("error");
          setOpen(true);
          break;
        }

        if (datiania.maximal === "" || datiania.maximal === "0" || datiania.maximal === undefined) {
          setAlertMsg("Selezionare il massimale");
          setAlertSeverity("error");
          setOpen(true);
          break;
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setdatipreventivo([]);
        let array_remove = [];

        AuthService.signin(posinfo.username, posinfo.password, posinfo.url).then(
          payload => {
            setToken(payload);

            checkcompanyData(Auth.getToken(), pos).then(
              payload => {
                let companyListPrev = payload.data.services;
                let conventionslist = payload.data.conventions;
                let companyOriginCode = [];
                setFactoriesHub(payload.data.factorieshub);
                if (datiania.insuranceCompany !== undefined) {
                  companyOriginCode = datiania.insuranceCompany.split("-");
                }

                let companyOrigin = 0;
                let companyOriginString = "";
                if (companyOriginCode.length > 1) {
                  companyOrigin = Number(companyOriginCode[0]);
                  companyOriginString = companyOriginCode[0];
                }

                // CONTROLLI RIMOZIONE COMPAGNIE
                if (tipoprev === "RIN") {
                  let newCompanyListPrev = [];
                  companyListPrev.forEach(function (company) {
                    // RIMOZIONE COMPAGNIE
                    let removeCompany = false;
                    switch (company.paramurl) {
                      case "generali":
                        if (companyOrigin === 57 || companyOrigin === 418 || companyOrigin === 5) {
                          removeCompany = true;
                        }
                        break;
                      case "groupama":
                      case "groupamanexus":
                        if (companyOrigin === 151) {
                          removeCompany = true;
                        }
                        break;
                      case "lloyds":
                        let cvtLloyds = false;
                        guarantees.forEach((element, index) => {
                          if (guaranteesSelect[index]) {
                            if (element.code === "ENS" || element.code === "INCFUR" || element.code === "CRIS" || element.code === "KAS") {
                              cvtLloyds = true;
                            }
                          }
                        });
                        if (!cvtLloyds) {
                          removeCompany = true;
                        }
                        break;
                      case "mapfre":
                        if (datiania.vehicleType === "C") {
                          let cvtMap = false;
                          guarantees.forEach((element, index) => {
                            if (guaranteesSelect[index]) {
                              if (element.code === "ASSST" || element.code === "ASSEL" || element.code === "ASSCL" || element.code === "ASSES") {
                                cvtMap = true;
                              }
                            }
                          });
                          if (!cvtMap) {
                            removeCompany = true;
                          }
                        } else {
                          removeCompany = true;
                        }
                        break;
                      default:
                        break;
                    }
                    if (!removeCompany) {
                      newCompanyListPrev.push(company);
                    }
                  });
                  companyListPrev = newCompanyListPrev;
                } else if (tipoprev === "CVT") {
                  let newCompanyListPrev = [];
                  companyListPrev.forEach(function (company) {
                    // RIMOZIONE COMPAGNIE
                    let removeCompany = false;
                    switch (company.paramurl) {
                      case "generali":
                        removeCompany = true;
                        break;
                      case "groupama":
                      case "groupamanexus":
                        removeCompany = true;
                        break;
                      case "lloyds":
                        let cvtLloyds = false;
                        guarantees.forEach((element, index) => {
                          if (guaranteesSelect[index]) {
                            if (element.code === "ENS" || element.code === "INCFUR" || element.code === "CRIS" || element.code === "KAS") {
                              cvtLloyds = true;
                            }
                          }
                        });
                        if (!cvtLloyds) {
                          removeCompany = true;
                        }
                        break;
                      case "mapfre":
                        if (datiania.vehicleType === "C") {
                          let cvtMap = false;
                          guarantees.forEach((element, index) => {
                            if (guaranteesSelect[index]) {
                              if (element.code === "ASSST" || element.code === "ASSEL" || element.code === "ASSCL" || element.code === "ASSES") {
                                cvtMap = true;
                              }
                            }
                          });
                          if (!cvtMap) {
                            removeCompany = true;
                          }
                        } else {
                          removeCompany = true;
                        }
                        break;
                      default:
                        break;
                    }
                    if (!removeCompany) {
                      newCompanyListPrev.push(company);
                    }
                  });
                  companyListPrev = newCompanyListPrev;
                } else if (tipoprev !== "CVT") {
                  let newCompanyListPrev = [];
                  companyListPrev.forEach(function (company) {
                    // RIMOZIONE COMPAGNIE
                    let removeCompany = false;
                    switch (company.paramurl) {
                      case "lloyds":
                        let cvtLloyds = false;
                        guarantees.forEach((element, index) => {
                          if (guaranteesSelect[index]) {
                            if (element.code === "ENS" || element.code === "INCFUR" || element.code === "CRIS" || element.code === "KAS") {
                              cvtLloyds = true;
                            }
                          }
                        });
                        if (!cvtLloyds) {
                          removeCompany = true;
                        }
                        break;
                      case "mapfre":
                        if (datiania.vehicleType === "C") {
                          let cvtMap = false;
                          guarantees.forEach((element, index) => {
                            if (guaranteesSelect[index]) {
                              if (element.code === "ASSST" || element.code === "ASSEL" || element.code === "ASSCL" || element.code === "ASSES") {
                                cvtMap = true;
                              }
                            }
                          });
                          if (!cvtMap) {
                            removeCompany = true;
                          }
                        } else {
                          removeCompany = true;
                        }
                        break;
                      default:
                        break;
                    }
                    if (!removeCompany) {
                      newCompanyListPrev.push(company);
                    }
                  });
                  companyListPrev = newCompanyListPrev;
                }

                array_remove.sort((a, b) => b - a);
                array_remove.forEach(function (index) {
                  companyListPrev.splice(index, 1);
                });

                companyListPrev.forEach((company, indexcompany) => {
                  guarantees.forEach((element, index) => {
                    if (guaranteesSelect[index]) {
                      if (element["input_type"] !== 0) {
                        companyListPrev[indexcompany].guarantees_info[index].franchigia = Number(guaranteesValue[index]);
                      }
                    }
                  });
                });

                setcompanyData(companyListPrev);

                let AddOnService = [];

                setPrevRS(0);
                setPrevRQ(companyListPrev.length);

                contaPreventiviDaFare = companyListPrev.length;
                contaPreventiviFatti = 0;

                if (sameOwner) {
                  datiania.ownerInfo = datiania.contractorInfo;
                }
                if (sameDriver) {
                  datiania.driverInfo = datiania.contractorInfo;
                }

                companyListPrev.map((company) => (
                  checkPreventivo(tipoprev, targa, datiania, company, token, companyOriginString, conventionslist, guarantees, guaranteesSelect, guaranteesValue, flexCode).then(
                    payload => {
                      const newDatiPreventivo = datipreventivo;

                      if (payload.data.prices !== undefined) {
                        payload.data.prices.forEach(element => {
                          let forceMessage = false;
                          let convenzioneAttiva = CheckConvenzioni(datiania, company, tipoprev, conventionslist);
                          element.convenzioneAttiva = convenzioneAttiva;
                          let percmax = -20;
                          if (element.convenzioneAttiva.code === "") {
                            conventionslist.forEach(conventions => {
                              if (company.facid === conventions.id_factory && conventions.companyapply === 0) {
                                let convention_accept = CheckActiveConvention(conventions, datiania, tipoprev);
                                if (convention_accept) {
                                  let value_flex = 0;
                                  if (conventions.discounts_rules !== null) {
                                    JSON.parse(conventions.discounts_rules).forEach(
                                      single_discount_rules => {
                                        let selectCVT = "RCA";
                                        if (single_discount_rules.hasOwnProperty(selectCVT)) {
                                          value_flex = single_discount_rules[selectCVT].perc;
                                        }
                                      }
                                    );
                                  }
                                  if (value_flex > percmax) {
                                    company.discount_description = conventions.description;
                                    company.discounts_rules = conventions.discounts_rules;
                                    percmax = value_flex;
                                  }
                                }
                              }
                            });
                          }

                          let sconto_totale = 0;
                          let nettoTotalePartenza = 0;
                          let nettoTotaleScontato = 0;
                          let differenzaImposte = 0;
                          if (company.discount_description !== null && company.discounts_rules !== null) {
                            element.listResponseCVT.forEach(
                              cvt => {
                                if (cvt.code !== "CFE" && cvt.code !== "TAX") {
                                  let selectCVT = "";
                                  nettoTotalePartenza += cvt.price;
                                  cvt.originalprice = cvt.price;
                                  selectCVT = cvt.code;
                                  if (selectCVT !== "") {
                                    JSON.parse(company.discounts_rules).forEach(
                                      single_discount_rules => {
                                        if (single_discount_rules.hasOwnProperty(selectCVT)) {
                                          if (single_discount_rules[selectCVT].val !== null && single_discount_rules[selectCVT].val !== "0" &&
                                            cvt.price >= parseFloat(single_discount_rules[selectCVT].val)) {
                                            sconto_totale += parseFloat(single_discount_rules[selectCVT].val);
                                            cvt.price -= parseFloat(single_discount_rules[selectCVT].val);
                                          }
                                          if (single_discount_rules[selectCVT].perc !== null && single_discount_rules[selectCVT].perc !== "0") {
                                            sconto_totale += (cvt.price * (parseFloat(single_discount_rules[selectCVT].perc))) / 100;
                                            cvt.price -= (cvt.price * (parseFloat(single_discount_rules[selectCVT].perc))) / 100;
                                          }
                                          nettoTotaleScontato += cvt.price;
                                        }
                                      }
                                    )
                                  }
                                }
                              }
                            );

                            if (company.paramurl === "groupamanexus") {
                              element.listResponseCVT.forEach(
                                cvt => {
                                  if (cvt.code === "TAX") {
                                    let imposteTotale = cvt.price;
                                    let scontoPercTax = (nettoTotaleScontato * 100) / nettoTotalePartenza;
                                    cvt.price = (cvt.price * scontoPercTax) / 100;
                                    differenzaImposte = imposteTotale - cvt.price;
                                  }
                                }
                              );
                            }
                          }

                          element.TotalPrice -= parseFloat2Decimals(sconto_totale);
                          element.price -= parseFloat2Decimals(sconto_totale);
                          if (company.paramurl === "groupamanexus") {
                            element.TotalPrice -= parseFloat(differenzaImposte);
                            element.price -= parseFloat(differenzaImposte);
                          }

                          let addCalcoloCvt = 0;
                          let addCalcoloCvtRatino = 0;
                          guarantees.forEach((elementGuar, indexGuar) => {
                            if (guaranteesSelect[indexGuar]) {
                              let calcoloPercValue = 0;
                              let calcoloPercRatino = 0;
                              let tipoCalcolo = company.guarantees_info[indexGuar].tipo_calcolo !== null ? (company.guarantees_info[indexGuar].tipo_calcolo !== "default" ? company.guarantees_info[indexGuar].tipo_calcolo : company.guarantees_info[indexGuar].tipo_calcolo_default) : company.guarantees_info[indexGuar].tipo_calcolo_default;
                              let serviceParameters = company.guarantees_info[indexGuar].tipo_calcolo !== null ? (company.guarantees_info[indexGuar].tipo_calcolo !== "default" ? company.guarantees_info[indexGuar].service_parameters : company.guarantees_info[indexGuar].service_parameters_default) : company.guarantees_info[indexGuar].service_parameters_default;
                              let tipoArrotondamento = company.guarantees_info[indexGuar].tipo_arrotondamento_calcolo;
                              if (tipoCalcolo === "perc") {
                                if (company.paramurl === "generali") {
                                  if (datiania.vehicleType === "R") {
                                    calcoloPercValue = parseFloat2Decimals((element.price * (Number(serviceParameters))) / 100);
                                  } else {
                                    calcoloPercValue = parseFloat2Decimals(((element.price - 30) * (Number(serviceParameters))) / 100);
                                  }
                                } else {
                                  calcoloPercValue = parseFloat2Decimals((element.price * (Number(serviceParameters))) / 100);
                                }

                                if (tipoArrotondamento === "sup") {
                                  calcoloPercValue = Math.ceil(calcoloPercValue);
                                } else if (tipoArrotondamento === "inf") {
                                  calcoloPercValue = Math.floor(calcoloPercValue);
                                } else if (tipoArrotondamento === "mat") {
                                  calcoloPercValue = Math.round(calcoloPercValue);
                                }
                                addCalcoloCvt += calcoloPercValue;
                                let guarInfo =
                                {
                                  code: elementGuar["code"],
                                  nameCVT: elementGuar["description"],
                                  selected: true,
                                  price: calcoloPercValue
                                };
                                element.listResponseCVT.push(guarInfo);
                                if (company.paramurl === "generali" && (elementGuar["code"] === "NETAA" || elementGuar["code"] === "NETAB")) {
                                  element.listResponseCVT.forEach((elementRatino, indexGuar) => {
                                    if (elementRatino["code"] === "RAT") {
                                      calcoloPercRatino = parseFloat2Decimals((elementRatino["price"] * (Number(serviceParameters))) / 100);
                                      addCalcoloCvtRatino += calcoloPercRatino;
                                    }
                                  });
                                }
                              } else if (tipoCalcolo === "value") {
                                calcoloPercValue = Number(serviceParameters);
                                addCalcoloCvt += parseFloat2Decimals(calcoloPercValue);
                                let guarInfo =
                                {
                                  code: elementGuar["code"],
                                  nameCVT: elementGuar["description"],
                                  selected: true,
                                  price: parseFloat2Decimals(calcoloPercValue)
                                };
                                element.listResponseCVT.push(guarInfo);
                                if (company.paramurl === "generali" && (elementGuar["code"] === "NETAA" || elementGuar["code"] === "NETAB")) {
                                  element.listResponseCVT.forEach((elementRatino, indexGuar) => {
                                    if (elementRatino["code"] === "RAT") {
                                      calcoloPercRatino = Number(serviceParameters);
                                      addCalcoloCvtRatino += parseFloat2Decimals(calcoloPercRatino);
                                    }
                                  });
                                }
                              }
                            }
                          });

                          let rataRatino = 0;
                          if (company.paramurl === "generali") {
                            element.listResponseCVT.forEach((elementCvt, indexGuar) => {
                              if (elementCvt["code"] === "RAT") {
                                elementCvt["price"] += addCalcoloCvtRatino;
                              } else if (elementCvt["code"] === "IMPRAT") {
                                elementCvt["price"] += addCalcoloCvtRatino;
                                rataRatino = elementCvt["price"];
                              }
                            });
                          }

                          element.TotalPrice += addCalcoloCvt;
                          element.price += addCalcoloCvt;

                          let NumRate = 1;
                          //let splitPrice = element.price;
                          if (element.splittingUp !== undefined && !isNaN(element.splittingUp)) {
                            NumRate = parseFloat2Decimals(element.splittingUp);
                          }
                          /*if (element.splitPrice !== undefined && !isNaN(element.splitPrice)) {
                            splitPrice = parseFloat2Decimals(element.splitPrice);
                          } else {
                            splitPrice = element.price;
                          }
                          splitPrice += (addCalcoloCvt / NumRate).toFixed(2);*/

                          // COMMISSIONI
                          if (company.FinalCommissionsType !== null && company.FinalCommissionsType === "euro") {
                            if (Number(company.FinalCommissionsDefault) >= 0) {
                              element.Commissions = company.FinalCommissionsDefault * NumRate;
                            } else {
                              if (Number(company.FinalCommissionsMax)) {
                                element.Commissions = company.FinalCommissionsMax * NumRate;
                              } else {
                                element.Commissions = 0;
                              }
                            }
                          } else {
                            if (Number(company.FinalCommissionsDefault) >= 0) {
                              element.Commissions = element.price * company.FinalCommissionsDefault / 100;
                              element.Commissions = element.Commissions.toFixed(2);
                            } else {
                              if (Number(company.FinalCommissionsMax)) {
                                element.Commissions = element.price * company.FinalCommissionsMax / 100;
                                element.Commissions = element.Commissions.toFixed(2);
                              } else {
                                element.Commissions = 0;
                              }
                            }
                          }

                          // DIRITTI
                          if (company.FinalfeeType !== null && company.FinalfeeType === "perc") {
                            if (Number(company.FinalfeeDefault) >= 0) {
                              element.Fees = element.price * company.FinalfeeDefault / 100;
                              element.Fees = element.Fees.toFixed(2);
                            } else {
                              if (Number(company.FinalfeeMax)) {
                                element.Fees = element.price * company.FinalfeeMax / 100;
                                element.Fees = element.Fees.toFixed(2);
                              } else {
                                element.Fees = 0;
                              }
                            }
                          } else {
                            if (Number(company.FinalfeeDefault) >= 0) {
                              element.Fees = company.FinalfeeDefault * NumRate;
                            } else {
                              if (Number(company.FinalfeeMax)) {
                                element.Fees = company.FinalfeeMax * NumRate;
                              } else {
                                element.Fees = 0;
                              }
                            }
                          }

                          element.TotalPrice = parseFloat2Decimals(element.price) + parseFloat2Decimals(element.Commissions) + parseFloat2Decimals(element.Fees);
                          element.ListService = [];
                          AddOnService.forEach(service => {
                            let priceservice = 0;
                            if (service.type === "perc") {
                              priceservice = element.price * service.value / 100;
                              priceservice = parseFloat2Decimals(priceservice);
                            } else {
                              priceservice = service.value;
                            }
                            element.TotalPrice += parseFloat2Decimals(priceservice);
                            let ServiceInfo = {
                              description: service.description,
                              priceservice: priceservice
                            };
                            element.ListService.push(ServiceInfo);
                          });

                          if (forceMessage) {
                            let Message = {
                              code: "01",
                              message: "Premio da confermare, polizza soggetta ad autorizzazione direzionale"
                            };
                            element.messages.push(Message);
                          }

                          /*if (company.paramurl === "generali" && rataRatino !== 0) {
                            let Message = {
                              code: "01",
                              message: "Scadenza fissa 15 marzo 1° rata " + parseFloat2Decimals(rataRatino).toString().replace(".", ",") + " €"
                            };
                            element.messages.push(Message);
                          }*/

                          if (company.paramurl === "generali" && rataRatino !== 0) {
                            element.TotalRate = parseFloat2Decimals(rataRatino);
                            element.TotalRate = element.TotalRate.toFixed(2);
                          } else {
                            element.TotalRate = parseFloat2Decimals(element.TotalPrice / NumRate);
                            element.TotalRate = element.TotalRate.toFixed(2);
                          }

                          element.only_cvt = company.only_cvt;
                        });
                      }

                      newDatiPreventivo[company.sfo_id + company.paramurl + company.type] = payload.data;
                      setdatipreventivo(newDatiPreventivo);
                      setPrevRS(prevCount => prevCount + 1);
                      contaPreventiviFatti++;
                      if (contaPreventiviFatti === contaPreventiviDaFare && contaPreventiviDaFare > 0) {
                        HandleSaveQuotation(companyListPrev);
                      }
                      if (prevRQ === prevRS) {
                        setLoading(false);
                      }
                    }).catch(error => {
                      setPrevRS(prevCount => prevCount + 1);
                      contaPreventiviFatti++;
                      if (contaPreventiviFatti === contaPreventiviDaFare && contaPreventiviDaFare > 0) {
                        HandleSaveQuotation(companyListPrev);
                      }
                      if (prevRQ === prevRS)
                        setLoading(false);
                    })
                ));
              }
            ).catch(error => {
              setPrevRS(prevCount => prevCount + 1);
              contaPreventiviFatti++;
              if (prevRQ === prevRS)
                setLoading(false);
            });
          }
        ).catch(error => { });
        break;
      case 5:
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        break;
      default:
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        break;
    }
  }

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <>
          <Grid container spacing={3}>
            <Grid item xs={4} />
            <Grid item xs={12} md={4}>
              <Box pb={4}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="labelPOS">Punto vendita</InputLabel>
                  <Select
                    labelId="labelPOS"
                    id="pos"
                    value={pos}
                    onChange={handleChangePos}
                    label="Punto vendita"
                  >
                    {agencieslists.length > 0 && agencieslists.map(option => (
                      <MenuItem
                        key={"ag_" + option.id}
                        value={option.id}
                      >
                        {option.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Box pb={2}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="labeltipoprev">Tipo di preventivo</InputLabel>
                  <Select
                    labelId="labeltipoprev"
                    id="tipoprev"
                    value={tipoprev}
                    onChange={handleChangeType}
                    label="Tipo di preventivo"
                  >
                    <MenuItem key="tipo_rinnovo" value="RIN">
                      Rinnovo polizza in scadenza o appena scaduta
                    </MenuItem>
                    <MenuItem key="tipo_prima" value="PRI">
                      Prima immatricolazione
                    </MenuItem>
                    <MenuItem key="tipo_voltura" value="VOL">
                      Voltura al PRA
                    </MenuItem>
                    <MenuItem key="tipo_cvt" value="CVT">
                      Solo CVT
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>

              {tipoprev !== "CVT" &&
                <Box pb={2}>
                  <TextValidator
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="targa"
                    label="Targa Veicolo"
                    name="targa"
                    autoFocus
                    value={targa}
                    validators={['required', 'minStringLength:7']}
                    errorMessages={['Campo obbligatorio', 'Targa troppo corta']}
                    onChange={(e) => setTarga(e.target.value.toUpperCase())} />
                </Box>
              }

              {tipoprev === "CVT" &&
                <Box pb={2}>
                  <TextValidator
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="targa"
                    label="Targa Veicolo"
                    name="targa"
                    autoFocus
                    value={targa}
                    validators={['minStringLength:7']}
                    errorMessages={['Targa troppo corta']}
                    onChange={(e) => setTarga(e.target.value.toUpperCase())} />
                </Box>
              }

              {tipoprev === "VOL" &&
                <Box pb={2}>
                  <FormControl component="fieldset">
                    <FormLabel>Il contraente ha già assicurato un altro autocarro in FASCIA 1?</FormLabel>
                    <RadioGroup aria-label="prevoption" name="prevoption" value={flexCode} onChange={handleChangeOption}>
                      <FormControlLabel value="" control={<Radio />} label="No" />
                      <FormControlLabel value="FASCIA3" control={<Radio />} label="Altro autocarro in FASCIA 1 (4+1 anni senza sinistri) già in copertura con la convenzione" />
                    </RadioGroup>
                  </FormControl>
                </Box>
              }

              {tipoprev === "PRI" &&
                <Box pb={2}>
                  <FormControl component="fieldset">
                    <FormLabel>Il contraente ha già assicurato un altro autocarro in FASCIA 1 o FASCIA 2?</FormLabel>
                    <RadioGroup aria-label="prevoption" name="prevoption" value={flexCode} onChange={handleChangeOption}>
                      <FormControlLabel value="" control={<Radio />} label="No" />
                      <FormControlLabel value="FASCIA1" control={<Radio />} label="Altro autocarro in FASCIA 1 (4+1 anni senza sinistri) già in copertura con la convenzione" />
                      <FormControlLabel value="FASCIA2" control={<Radio />} label="Altro autocarro in FASCIA 2 (3+1 anni senza sinistri) già in copertura con la convenzione" />
                    </RadioGroup>
                  </FormControl>
                </Box>
              }
            </Grid>
            <Grid item xs={4} />
          </Grid>
        </>;
      case 1:
        return <>
          <SubjectDetails
            datatype={"van"}
            type={"contractorInfo"}
            token={token}
            urlservicequotiamo={urlservicequotiamo}
            datiquotazione={datiania}
            setdatiquotazione={setdatiania}
            listProfessions={listProfessions}
            listCity={listCity}
            posinfo={posinfo}
          />
          {true &&
            <Accordion defaultExpanded={sameOwner === false}>
              <AccordionSummary
                expandIcon={sameOwner === false && <ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id="additional-actions1-header"
              >
                <FormControlLabel
                  aria-label="Acknowledge"
                  onClick={(event) => {
                    setSameOwner(!sameOwner);
                    event.stopPropagation();
                  }
                  }
                  onFocus={(event) => event.stopPropagation()}
                  control={<Checkbox checked={sameOwner} />}
                  label="L'intestatario coincide con il contraente"
                />
              </AccordionSummary>
              {sameOwner === false &&
                <AccordionDetails>
                  <SubjectDetails
                    datatype={"car"}
                    type={"ownerInfo"}
                    token={token}
                    urlservicequotiamo={urlservicequotiamo}
                    datiquotazione={datiania}
                    setdatiquotazione={setdatiania}
                    listProfessions={listProfessions}
                    listCity={listCity}
                    posinfo={posinfo}
                  />
                </AccordionDetails>
              }
            </Accordion>
          }
          {false &&
            <Accordion disabled>
              <AccordionSummary
                expandIcon={sameDriver === false && <ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id="additional-actions1-header"
              >
                <FormControlLabel
                  aria-label="Acknowledge"
                  onClick={(event) => {
                    setSameDriver(!sameDriver);
                    event.stopPropagation();
                  }
                  }
                  onFocus={(event) => event.stopPropagation()}
                  control={<Checkbox checked={sameDriver} />}
                  label="Il conducente coincide con il contraente"
                />
              </AccordionSummary>
              {sameDriver === false &&
                <AccordionDetails>
                  <SubjectDetails
                    datatype={"car"}
                    type={"driverInfo"}
                    token={token}
                    urlservicequotiamo={urlservicequotiamo}
                    datiquotazione={datiania}
                    setdatiquotazione={setdatiania}
                    listProfessions={listProfessions}
                    listCity={listCity}
                    posinfo={posinfo}
                  />
                </AccordionDetails>
              }
            </Accordion>
          }
        </>;
      case 2:
        return <div className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <KeyboardDatePicker
                label="Data immatricolazione"
                value={datiania.registrationDate}
                onChange={handleChangeRegistrationDate}
                format='dd/MM/yyyy'
                clearLabel="Conferma"
                cancelLabel="Annulla"
                name="registrationDate"
                fullWidth
                required
                inputVariant="outlined"
                margin="normal"
                disableFuture
              />
            </Grid>

            <Grid item xs={4}>
              <KeyboardDatePicker
                label="Data di acquisto"
                value={datiania.purchaseDate}
                onChange={handleChangePurchaseDate}
                format='dd/MM/yyyy'
                clearLabel="Conferma"
                cancelLabel="Annulla"
                name="purchaseDate"
                fullWidth
                required
                inputVariant="outlined"
                margin="normal"
                disableFuture
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                margin="normal"
                fullWidth
                id="use"
                label="Uso"
                name="use"
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                value={datiania.use}
                variant="outlined"
              >
                {useTypes.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={4}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="telaio"
                label="Numero telaio"
                name="frame"
                autoFocus
                disabled
                value={datiania.frame}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="codiceOmologazione"
                label="Codice di omologazione"
                name="homologationCode"
                disabled
                value={datiania.homologationCode}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="codiceMotornet"
                label="Codice MotorNet"
                name="codiceMotornet"
                disabled
                autoFocus
                value={datiania.codiceMotornet}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={4}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="brand"
                label="Marca"
                name="brand"
                // value={datiania.brand !== "" ? datiania.brand : "Altro"}
                value={datiania.brand}
                onChange={handleChange}
              />
              {/* <Autocomplete
                options={listBrands.map((option) => option)}
                defaultValue={datiania.brand !== "" ? datiania.brand : "Altro"}
                id="brand"
                label="Marca"
                name="brand"
                autoHighlight
                onChange={(event, newValue) => {
                  setdatiania({
                    ...datiania,
                    brand: newValue,
                  });
                  getModels(token, urlservicequotiamo, newValue).then(payload => {
                    setlistModels(payload.data);
                  });
                }}
                renderInput={(params) => <TextField {...params} label="Marca" margin="normal" variant="outlined" />}
              /> */}
            </Grid>
            <Grid item xs={4}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="model"
                label="Modello"
                name="model"
                // value={datiania.model !== "" ? datiania.model : "Altro"}
                value={datiania.model}
                onChange={handleChange}
              />
              {/* <Autocomplete
                options={listModels.map((option) => option)}
                defaultValue={datiania.model !== "" ? datiania.model : "Altro"}
                id="model"
                label="Modello"
                name="model"
                autoHighlight
                onChange={(event, newValue) => {
                  setdatiania({
                    ...datiania,
                    model: newValue,
                  });
                  getOutfitting(token, urlservicequotiamo, datiania.brand, newValue, datiania.homologationCode, datiania.registrationDate).then(payload => {
                    setlistOutfitting(payload.data);
                  });
                }}
                renderInput={(params) => <TextField {...params} label="Modello" margin="normal" variant="outlined" />}
              /> */}
            </Grid>
            <Grid item xs={4}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="allestimento"
                label="Allestimento"
                name="outfitting"
                // value={datiania.outfitting !== "" ? datiania.outfitting : "Altro"}
                value={datiania.outfitting}
                onChange={handleChange}
              />
              {/* <Autocomplete
                options={listOutfitting.map((option) => option.outfitting)}
                defaultValue={datiania.outfitting !== "" ? datiania.outfitting : "Altro"}
                id="allestimento"
                label="Allestimento"
                name="outfitting"
                autoHighlight
                onChange={(event, newValue) => {
                  listOutfitting.map((option) => {
                    if (newValue === option.outfitting) {
                      setdatiania({
                        ...datiania,
                        outfitting: newValue,
                        newValue: option.newValue,
                        usedValue: option.usedValue,
                        codiceMotornet: option.codiceMotornet,
                        //carBody: option.carBody,
                        power: option.power,
                        displacement: option.displacement,
                        horsePower: option.horsePower,
                        supply: option.supply
                      });
                      return option
                    }
                    return false;
                  })
                }}
                renderInput={(params) => <TextField {...params} label="Allestimento" margin="normal" variant="outlined" />}
              /> */}
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            {/*<Grid item xs={4}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="carrozzeria"
                label="Carrozzeria"
                name="carBody"
                autoFocus
                value={datiania.carBody}
                onChange={handleChange}
              />
              </Grid>*/}
            <Grid item xs={4}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="newValue"
                label="Prezzo di listino"
                name="newValue"
                autoFocus
                value={datiania.newValue}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="usedValue"
                label="Valore"
                name="usedValue"
                autoFocus
                value={datiania.usedValue}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="weight"
                label="Peso (KG)"
                name="weight"
                autoFocus
                required
                value={datiania.weight}
                onChange={handleChange}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={4}>
              <TextField
                margin="normal"
                fullWidth
                label="Alimentazione"
                name="supply"
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                value={datiania.supply === undefined ? "B" : datiania.supply}
                variant="outlined"
              >
                {tipoAlimentazione.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                margin="normal"
                fullWidth
                label="Gancio traino"
                name="towHook"
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                value={datiania.towHook}
                variant="outlined"
              >
                <option key="towHookNo" value="false">
                  NO
                </option>
                <option key="towHookYes" value="true">
                  SI
                </option>
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                margin="normal"
                fullWidth
                label="Antifurto"
                name="alarm"
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                value={datiania.alarm}
                variant="outlined"
              >
                <option key="GEN" value="2">
                  Generico
                </option>
                <option key="SAT" value="1">
                  Satellitare GPS
                </option>
                <option key="ASS" value="3">
                  Assente
                </option>
              </TextField>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={4}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="cilindrata"
                label="Cilindrata"
                name="displacement"
                autoFocus
                value={datiania.displacement}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="potenza"
                label="Potenza"
                name="power"
                autoFocus
                value={datiania.power}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="cavalliFiscali"
                label="Cavalli Fiscali"
                name="horsePower"
                autoFocus
                value={datiania.horsePower}
                onChange={handleChange}
              />
            </Grid>
          </Grid>

          {/*<Grid container spacing={3}>
            <Grid item xs={4}>
              <Autocomplete
                options={kmAnnuiOption}
                id="kmAnnui"
                label="Km annui percorsi"
                name="kmAnnui"
                disableClearable
                getOptionLabel={(option) => option.description}
                value={returnElement(kmAnnuiOption, datiania.kmAnnui)}
                autoHighlight
                required
                onChange={(event, newValue) => {
                  setdatiania({
                    ...datiania,
                    kmAnnui: newValue.id,
                  });
                }}
                renderInput={(params) => <TextField {...params} required label="Km annui percorsi" margin="normal" variant="outlined" />}
              />
              </Grid>
            
            <Grid item xs={4}></Grid>
          </Grid>*/}
        </div>;
      case 3:
        var cols = [];
        var RespPrinc = [];
        var DanniCosePrinc = [];
        var DanniPersPrinc = [];
        var DanniMistiPrinc = [];

        var RespPar = [];
        var DanniCosePar = [];
        var DanniPersPar = [];
        var DanniMistiPar = [];

        for (let i = 10; i >= 0; i--) {
          if (datiania.dataATRC !== undefined && datiania.dataATRC !== null) {
            cols.push(datiania.dataATRC.anno0Atrc - i);
            switch (i) {
              case 0:
                RespPrinc.push(datiania.dataATRC.atrcRespPrincAnno0);
                DanniCosePrinc.push(datiania.dataATRC.atrcRespPrincDanniCoseAnno0);
                DanniPersPrinc.push(datiania.dataATRC.atrcRespPrincDanniPersAnno0);
                DanniMistiPrinc.push(datiania.dataATRC.atrcRespPrincDanniMistiAnno0);

                RespPar.push(datiania.dataATRC.atrcRespPariAnno0);
                DanniCosePar.push(datiania.dataATRC.atrcRespPariDanniCoseAnno0);
                DanniPersPar.push(datiania.dataATRC.atrcRespPariDanniPersAnno0);
                DanniMistiPar.push(datiania.dataATRC.atrcRespPariDanniMistiAnno0);
                break;
              case 1:
                RespPrinc.push(datiania.dataATRC.atrcRespPrincAnno1);
                DanniCosePrinc.push(datiania.dataATRC.atrcRespPrincDanniCoseAnno1);
                DanniPersPrinc.push(datiania.dataATRC.atrcRespPrincDanniPersAnno1);
                DanniMistiPrinc.push(datiania.dataATRC.atrcRespPrincDanniMistiAnno1);

                RespPar.push(datiania.dataATRC.atrcRespPariAnno1);
                DanniCosePar.push(datiania.dataATRC.atrcRespPariDanniCoseAnno1);
                DanniPersPar.push(datiania.dataATRC.atrcRespPariDanniPersAnno1);
                DanniMistiPar.push(datiania.dataATRC.atrcRespPariDanniMistiAnno1);
                break;
              case 2:
                RespPrinc.push(datiania.dataATRC.atrcRespPrincAnno2);
                DanniCosePrinc.push(datiania.dataATRC.atrcRespPrincDanniCoseAnno2);
                DanniPersPrinc.push(datiania.dataATRC.atrcRespPrincDanniPersAnno2);
                DanniMistiPrinc.push(datiania.dataATRC.atrcRespPrincDanniMistiAnno2);

                RespPar.push(datiania.dataATRC.atrcRespPariAnno2);
                DanniCosePar.push(datiania.dataATRC.atrcRespPariDanniCoseAnno2);
                DanniPersPar.push(datiania.dataATRC.atrcRespPariDanniPersAnno2);
                DanniMistiPar.push(datiania.dataATRC.atrcRespPariDanniMistiAnno2);
                break;
              case 3:
                RespPrinc.push(datiania.dataATRC.atrcRespPrincAnno3);
                DanniCosePrinc.push(datiania.dataATRC.atrcRespPrincDanniCoseAnno3);
                DanniPersPrinc.push(datiania.dataATRC.atrcRespPrincDanniPersAnno3);
                DanniMistiPrinc.push(datiania.dataATRC.atrcRespPrincDanniMistiAnno3);

                RespPar.push(datiania.dataATRC.atrcRespPariAnno3);
                DanniCosePar.push(datiania.dataATRC.atrcRespPariDanniCoseAnno3);
                DanniPersPar.push(datiania.dataATRC.atrcRespPariDanniPersAnno3);
                DanniMistiPar.push(datiania.dataATRC.atrcRespPariDanniMistiAnno3);
                break;
              case 4:
                RespPrinc.push(datiania.dataATRC.atrcRespPrincAnno4);
                DanniCosePrinc.push(datiania.dataATRC.atrcRespPrincDanniCoseAnno4);
                DanniPersPrinc.push(datiania.dataATRC.atrcRespPrincDanniPersAnno4);
                DanniMistiPrinc.push(datiania.dataATRC.atrcRespPrincDanniMistiAnno4);

                RespPar.push(datiania.dataATRC.atrcRespPariAnno4);
                DanniCosePar.push(datiania.dataATRC.atrcRespPariDanniCoseAnno4);
                DanniPersPar.push(datiania.dataATRC.atrcRespPariDanniPersAnno4);
                DanniMistiPar.push(datiania.dataATRC.atrcRespPariDanniMistiAnno4);
                break;
              case 5:
                RespPrinc.push(datiania.dataATRC.atrcRespPrincAnno5);
                DanniCosePrinc.push(datiania.dataATRC.atrcRespPrincDanniCoseAnno5);
                DanniPersPrinc.push(datiania.dataATRC.atrcRespPrincDanniPersAnno5);
                DanniMistiPrinc.push(datiania.dataATRC.atrcRespPrincDanniMistiAnno5);

                RespPar.push(datiania.dataATRC.atrcRespPariAnno5);
                DanniCosePar.push(datiania.dataATRC.atrcRespPariDanniCoseAnno5);
                DanniPersPar.push(datiania.dataATRC.atrcRespPariDanniPersAnno5);
                DanniMistiPar.push(datiania.dataATRC.atrcRespPariDanniMistiAnno5);
                break;
              case 6:
                RespPrinc.push(datiania.dataATRC.atrcRespPrincAnno6);
                DanniCosePrinc.push(datiania.dataATRC.atrcRespPrincDanniCoseAnno6);
                DanniPersPrinc.push(datiania.dataATRC.atrcRespPrincDanniPersAnno6);
                DanniMistiPrinc.push(datiania.dataATRC.atrcRespPrincDanniMistiAnno6);

                RespPar.push(datiania.dataATRC.atrcRespPariAnno6);
                DanniCosePar.push(datiania.dataATRC.atrcRespPariDanniCoseAnno6);
                DanniPersPar.push(datiania.dataATRC.atrcRespPariDanniPersAnno6);
                DanniMistiPar.push(datiania.dataATRC.atrcRespPariDanniMistiAnno6);
                break;
              case 7:
                RespPrinc.push(datiania.dataATRC.atrcRespPrincAnno7);
                DanniCosePrinc.push(datiania.dataATRC.atrcRespPrincDanniCoseAnno7);
                DanniPersPrinc.push(datiania.dataATRC.atrcRespPrincDanniPersAnno7);
                DanniMistiPrinc.push(datiania.dataATRC.atrcRespPrincDanniMistiAnno7);

                RespPar.push(datiania.dataATRC.atrcRespPariAnno7);
                DanniCosePar.push(datiania.dataATRC.atrcRespPariDanniCoseAnno7);
                DanniPersPar.push(datiania.dataATRC.atrcRespPariDanniPersAnno7);
                DanniMistiPar.push(datiania.dataATRC.atrcRespPariDanniMistiAnno7);
                break;
              case 8:
                RespPrinc.push(datiania.dataATRC.atrcRespPrincAnno8);
                DanniCosePrinc.push(datiania.dataATRC.atrcRespPrincDanniCoseAnno8);
                DanniPersPrinc.push(datiania.dataATRC.atrcRespPrincDanniPersAnno8);
                DanniMistiPrinc.push(datiania.dataATRC.atrcRespPrincDanniMistiAnno8);

                RespPar.push(datiania.dataATRC.atrcRespPariAnno8);
                DanniCosePar.push(datiania.dataATRC.atrcRespPariDanniCoseAnno8);
                DanniPersPar.push(datiania.dataATRC.atrcRespPariDanniPersAnno8);
                DanniMistiPar.push(datiania.dataATRC.atrcRespPariDanniMistiAnno8);
                break;
              case 9:
                RespPrinc.push(datiania.dataATRC.atrcRespPrincAnno9);
                DanniCosePrinc.push(datiania.dataATRC.atrcRespPrincDanniCoseAnno9);
                DanniPersPrinc.push(datiania.dataATRC.atrcRespPrincDanniPersAnno9);
                DanniMistiPrinc.push(datiania.dataATRC.atrcRespPrincDanniMistiAnno9);

                RespPar.push(datiania.dataATRC.atrcRespPariAnno9);
                DanniCosePar.push(datiania.dataATRC.atrcRespPariDanniCoseAnno9);
                DanniPersPar.push(datiania.dataATRC.atrcRespPariDanniPersAnno9);
                DanniMistiPar.push(datiania.dataATRC.atrcRespPariDanniMistiAnno9);
                break;
              case 10:
                RespPrinc.push(datiania.dataATRC.atrcRespPrincAnno10);
                DanniCosePrinc.push(datiania.dataATRC.atrcRespPrincDanniCoseAnno10);
                DanniPersPrinc.push(datiania.dataATRC.atrcRespPrincDanniPersAnno10);
                DanniMistiPrinc.push(datiania.dataATRC.atrcRespPrincDanniMistiAnno10);

                RespPar.push(datiania.dataATRC.atrcRespPariAnno10);
                DanniCosePar.push(datiania.dataATRC.atrcRespPariDanniCoseAnno10);
                DanniPersPar.push(datiania.dataATRC.atrcRespPariDanniPersAnno10);
                DanniMistiPar.push(datiania.dataATRC.atrcRespPariDanniMistiAnno10);
                break;
              default:
                break;
            }
          } else {
            cols.push(moment().format("YYYY") - i);
            RespPrinc.push("--");
            DanniCosePrinc.push("--");
            DanniPersPrinc.push("--");
            DanniMistiPrinc.push("--");

            RespPar.push("--");
            DanniCosePar.push("--");
            DanniPersPar.push("--");
            DanniMistiPar.push("--");
          }
        }

        return <div className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="impresaAssicuratrice"
                label="Compagnia di provenienza"
                name="insuranceCompany"
                autoFocus
                value={datiania.insuranceCompany}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="declarationOrigin"
                label="Tipologia di tariffa"
                name="declarationOrigin"
                autoFocus
                value={datiania.declarationOrigin}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="policyExpirationDate"
                label="Data scadenza contratto"
                name="policyExpirationDate"
                autoFocus
                value={moment(datiania.policyExpirationDate, "YYYY-MM-DD").format("DD/MM/YYYY")}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={2}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="cuProvenienza"
                label="CU di provenienza"
                name="cuOrigin"
                autoFocus
                value={datiania.cuOrigin}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="cuAssegnazione"
                label="CU di assegnazione"
                name="cuAssignment"
                autoFocus
                value={datiania.cuAssignment}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                disabled={Number(datiania.cuOrigin) !== 1}
                variant="outlined"
                margin="normal"
                fullWidth
                id="insuranceYearsCU1"
                label="Anni in classe 1"
                name="insuranceYearsCU1"
                autoFocus
                value={datiania.insuranceYearsCU1}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="currentAward"
                label="Premio attuale €"
                name="currentAward"
                autoFocus
                value={datiania.currentAward}
                onChange={handleChange}
              />
            </Grid>
          </Grid>

          <Grid className={classes.PaddingTop} container spacing={3}>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell>Tipo sinistri</StyledTableCell>
                      {cols.map((col, index) => (
                        <StyledTableCell key={index} align="right">{col}</StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        <ArrowDropDownIcon />
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        Sinistri con responsabilità principale
                      </StyledTableCell>
                      {RespPrinc.map((col, index) => (
                        <StyledTableCell key={index} align="right">{col}</StyledTableCell>
                      ))}
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        di cui con danni a: COSE
                      </StyledTableCell>
                      {DanniCosePrinc.map((col, index) => (
                        <StyledTableCell key={index} align="right">{col}</StyledTableCell>
                      ))}
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        di cui con danni a: PERSONE
                      </StyledTableCell>
                      {DanniPersPrinc.map((col, index) => (
                        <StyledTableCell key={index} align="right">{col}</StyledTableCell>
                      ))}
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        di cui con danni a: MISTI
                      </StyledTableCell>
                      {DanniMistiPrinc.map((col, index) => (
                        <StyledTableCell key={index} align="right">{col}</StyledTableCell>
                      ))}
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                        <ArrowDropDownIcon />
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        Sinistri con responsabilità paritaria
                      </StyledTableCell>
                      {RespPar.map((col, index) => (
                        <StyledTableCell key={index} align="right">{col}</StyledTableCell>
                      ))}
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        di cui con danni a: COSE
                      </StyledTableCell>
                      {DanniCosePar.map((col, index) => (
                        <StyledTableCell key={index} align="right">{col}</StyledTableCell>
                      ))}
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        di cui con danni a: PERSONE
                      </StyledTableCell>
                      {DanniPersPar.map((col, index) => (
                        <StyledTableCell key={index} align="right">{col}</StyledTableCell>
                      ))}
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="th" scope="row">
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        di cui con danni a: MISTI
                      </StyledTableCell>
                      {DanniMistiPar.map((col, index) => (
                        <StyledTableCell key={index} align="right">{col}</StyledTableCell>
                      ))}
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </div>;
      case 4:
        return <div className={classes.paper}>
          <Grid container spacing={0}>
            {guarantees.map((warranty, index) => (
              <>
                {(index === 0 || guarantees[index - 1].categoriesdescription !== warranty.categoriesdescription) &&
                  <Grid key={"cat_" + index} item xs={12}>
                    <Typography className={classes.TitleCategory}>
                      {warranty.categoriesdescription}
                    </Typography>
                  </Grid>
                }
                {tipoprev !== "CVT" &&
                  <>
                    {warranty.intervallo_peso === null &&
                      <Grid key={index} item xs={2}>
                        {warranty.code === "RCA" && tipoprev !== "CVT" &&
                          <CheckSvgButton disabled={true} label={warranty.description} svg={warranty.image} status={guaranteesSelect[index]} click={handleClick(index)} />
                        }
                        {warranty.code !== "RCA" &&
                          <CheckSvgButton disabled={posinfo.defAg !== null && JSON.parse(posinfo.defAg)["VAN_" + warranty.code] !== undefined ? JSON.parse(posinfo.defAg)["VAN_" + warranty.code] >= 2 : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp)["VAN_" + warranty.code] !== undefined ? true : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg)["VAN_" + warranty.code] !== undefined ? true : false)) &&
                            (((posinfo.defAg !== null && JSON.parse(posinfo.defAg)["VAN_" + warranty.code] !== undefined ? JSON.parse(posinfo.defAg)["VAN_" + warranty.code] : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp)["VAN_" + warranty.code] !== undefined ? JSON.parse(posinfo.defGrp)["VAN_" + warranty.code] : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg)["VAN_" + warranty.code] !== undefined ? JSON.parse(posinfo.defOrg)["VAN_" + warranty.code] : 0))) & 2) >= 2)} label={warranty.description} svg={warranty.image} status={guaranteesSelect[index]} click={handleClick(index)} />
                        }

                        <Box className={classes.BoxContainer}>
                          {warranty.input_type === 1 && <>
                            <NumberFormat disabled={!guaranteesSelect[index]} onValueChange={handleChangeFreeInput(index)} customInput={TextFieldCustomMassimale} fixedDecimalScale="true" decimalScale="0" value={guaranteesValue[index]} displayType={'input'} decimalSeparator={" "} thousandSeparator={"."} prefix={'€ '} />
                          </>}

                          {warranty.input_type === 2 && <>
                            <TextField
                              disabled={!guaranteesSelect[index]}
                              fullWidth
                              label="Franchigia"
                              onChange={handleChangeList(index)}
                              select
                              SelectProps={{ native: true }}
                              value={guaranteesValue[index]}
                              variant="outlined"
                            >
                              {warranty.list_value.split(",").map(option => (
                                <option
                                  key={option}
                                  value={option}
                                >
                                  {option.indexOf("%") >= 0 && "" + option}
                                  {option.indexOf("%") === -1 && "€ " + number_format(option, 0, ",", ".")}
                                </option>
                              ))}
                            </TextField>
                          </>
                          }
                        </Box>
                      </Grid>
                    }
                    {warranty.intervallo_peso !== null && getPesoInIntervallo(warranty.intervallo_peso, datiania.weight) === true &&
                      <Grid key={index} item xs={2}>
                        <CheckSvgButton disabled={posinfo.defAg !== null && JSON.parse(posinfo.defAg)["VAN_" + warranty.code] !== undefined ? JSON.parse(posinfo.defAg)["VAN_" + warranty.code] >= 2 : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp)["VAN_" + warranty.code] !== undefined ? true : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg)["VAN_" + warranty.code] !== undefined ? true : false)) &&
                          (((posinfo.defAg !== null && JSON.parse(posinfo.defAg)["VAN_" + warranty.code] !== undefined ? JSON.parse(posinfo.defAg)["VAN_" + warranty.code] : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp)["VAN_" + warranty.code] !== undefined ? JSON.parse(posinfo.defGrp)["VAN_" + warranty.code] : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg)["VAN_" + warranty.code] !== undefined ? JSON.parse(posinfo.defOrg)["VAN_" + warranty.code] : 0))) & 2) >= 2)} label={warranty.description} svg={warranty.image} status={guaranteesSelect[index]} click={handleClick(index)} />

                        <Box className={classes.BoxContainer}>
                          {warranty.input_type === 1 && <>
                            <NumberFormat disabled={!guaranteesSelect[index]} onValueChange={handleChangeFreeInput(index)} customInput={TextFieldCustomMassimale} fixedDecimalScale="true" decimalScale="0" value={guaranteesValue[index]} displayType={'input'} decimalSeparator={" "} thousandSeparator={"."} prefix={'€ '} />
                          </>}

                          {warranty.input_type === 2 && <>
                            <TextField
                              disabled={!guaranteesSelect[index]}
                              fullWidth
                              label="Massimale"
                              onChange={handleChangeList(index)}
                              select
                              SelectProps={{ native: true }}
                              value={guaranteesValue[index]}
                              variant="outlined"
                            >
                              {warranty.list_value.split(",").map(option => (
                                <option
                                  key={option}
                                  value={option}
                                >
                                  {option.indexOf("%") >= 0 && "" + option}
                                  {option.indexOf("%") === -1 && "€ " + number_format(option, 0, ",", ".")}
                                </option>
                              ))}
                            </TextField>
                          </>
                          }
                        </Box>
                      </Grid>
                    }
                  </>
                }
                {tipoprev === "CVT" && warranty.code !== "RCA" &&
                  <>
                    {warranty.intervallo_peso === null &&
                      <Grid key={index} item xs={2}>
                        {warranty.code === "RCA" && tipoprev !== "CVT" &&
                          <CheckSvgButton disabled={true} label={warranty.description} svg={warranty.image} status={guaranteesSelect[index]} click={handleClick(index)} />
                        }
                        {warranty.code !== "RCA" &&
                          <CheckSvgButton disabled={posinfo.defAg !== null && JSON.parse(posinfo.defAg)["VAN_" + warranty.code] !== undefined ? JSON.parse(posinfo.defAg)["VAN_" + warranty.code] >= 2 : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp)["VAN_" + warranty.code] !== undefined ? true : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg)["VAN_" + warranty.code] !== undefined ? true : false)) &&
                            (((posinfo.defAg !== null && JSON.parse(posinfo.defAg)["VAN_" + warranty.code] !== undefined ? JSON.parse(posinfo.defAg)["VAN_" + warranty.code] : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp)["VAN_" + warranty.code] !== undefined ? JSON.parse(posinfo.defGrp)["VAN_" + warranty.code] : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg)["VAN_" + warranty.code] !== undefined ? JSON.parse(posinfo.defOrg)["VAN_" + warranty.code] : 0))) & 2) >= 2)} label={warranty.description} svg={warranty.image} status={guaranteesSelect[index]} click={handleClick(index)} />
                        }

                        <Box className={classes.BoxContainer}>
                          {warranty.input_type === 1 && <>
                            <NumberFormat disabled={!guaranteesSelect[index]} onValueChange={handleChangeFreeInput(index)} customInput={TextFieldCustomMassimale} fixedDecimalScale="true" decimalScale="0" value={guaranteesValue[index]} displayType={'input'} decimalSeparator={" "} thousandSeparator={"."} prefix={'€ '} />
                          </>}

                          {warranty.input_type === 2 && <>
                            <TextField
                              disabled={!guaranteesSelect[index]}
                              fullWidth
                              label="Franchigia"
                              onChange={handleChangeList(index)}
                              select
                              SelectProps={{ native: true }}
                              value={guaranteesValue[index]}
                              variant="outlined"
                            >
                              {warranty.list_value.split(",").map(option => (
                                <option
                                  key={option}
                                  value={option}
                                >
                                  {option.indexOf("%") >= 0 && "" + option}
                                  {option.indexOf("%") === -1 && "€ " + number_format(option, 0, ",", ".")}
                                </option>
                              ))}
                            </TextField>
                          </>
                          }
                        </Box>
                      </Grid>
                    }
                    {warranty.intervallo_peso !== null && getPesoInIntervallo(warranty.intervallo_peso, datiania.weight) === true &&
                      <Grid key={index} item xs={2}>
                        <CheckSvgButton disabled={posinfo.defAg !== null && JSON.parse(posinfo.defAg)["VAN_" + warranty.code] !== undefined ? JSON.parse(posinfo.defAg)["VAN_" + warranty.code] >= 2 : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp)["VAN_" + warranty.code] !== undefined ? true : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg)["VAN_" + warranty.code] !== undefined ? true : false)) &&
                          (((posinfo.defAg !== null && JSON.parse(posinfo.defAg)["VAN_" + warranty.code] !== undefined ? JSON.parse(posinfo.defAg)["VAN_" + warranty.code] : (posinfo.defGrp !== null && JSON.parse(posinfo.defGrp)["VAN_" + warranty.code] !== undefined ? JSON.parse(posinfo.defGrp)["VAN_" + warranty.code] : (posinfo.defOrg !== null && JSON.parse(posinfo.defOrg)["VAN_" + warranty.code] !== undefined ? JSON.parse(posinfo.defOrg)["VAN_" + warranty.code] : 0))) & 2) >= 2)} label={warranty.description} svg={warranty.image} status={guaranteesSelect[index]} click={handleClick(index)} />

                        <Box className={classes.BoxContainer}>
                          {warranty.input_type === 1 && <>
                            <NumberFormat disabled={!guaranteesSelect[index]} onValueChange={handleChangeFreeInput(index)} customInput={TextFieldCustomMassimale} fixedDecimalScale="true" decimalScale="0" value={guaranteesValue[index]} displayType={'input'} decimalSeparator={" "} thousandSeparator={"."} prefix={'€ '} />
                          </>}

                          {warranty.input_type === 2 && <>
                            <TextField
                              disabled={!guaranteesSelect[index]}
                              fullWidth
                              label="Massimale"
                              onChange={handleChangeList(index)}
                              select
                              SelectProps={{ native: true }}
                              value={guaranteesValue[index]}
                              variant="outlined"
                            >
                              {warranty.list_value.split(",").map(option => (
                                <option
                                  key={option}
                                  value={option}
                                >
                                  {option.indexOf("%") >= 0 && "" + option}
                                  {option.indexOf("%") === -1 && "€ " + number_format(option, 0, ",", ".")}
                                </option>
                              ))}
                            </TextField>
                          </>
                          }
                        </Box>
                      </Grid>
                    }
                  </>
                }
              </>
            ))}
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={3}>
              <KeyboardDatePicker
                label="Data decorrenza"
                value={datiania.effectiveDate}
                onChange={handleChangeDateEffective}
                format='dd/MM/yyyy'
                clearLabel="Conferma"
                cancelLabel="Annulla"
                name="effectiveDate"
                fullWidth
                required
                invalidDateMessage="Data non valida"
                //maxDateMessage="Non è possibile effettuare un preventivo con una scadenza superiore alla fine del mese successivo"
                minDateMessage="Il preventivo non può essere retroattivo"
                minDate={moment()}
                //maxDate={moment().add(1, "months").endOf('month')}
                inputVariant="outlined"
                margin="normal"
              />
            </Grid>

            {/*<Grid item xs={3}>
              <TextField
                margin="normal"
                fullWidth
                label="Tipo di guida"
                name="guideType"
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                value={datiania.guideType === undefined ? "LIB" : datiania.guideType}
                variant="outlined"
              >
                {tipoGuida.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
                </Grid>*/}

            <Grid item xs={3}>
              <TextField
                margin="normal"
                fullWidth
                label="Massimale"
                name="maximal"
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                value={datiania.maximal}
                variant="outlined"
              >
                {ElencoMassimaliAutocarri.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={3}>
              <TextField
                margin="normal"
                fullWidth
                label="Frazionamento"
                name="splittingUp"
                onChange={handleChange}
                required
                select
                SelectProps={{ native: true }}
                value={datiania.splittingUp}
                variant="outlined"
              >
                {tipoFrazionamento.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </div>;
      case 5:
        return <Paper className={classes.paper}>
          <div className={classes.progress}>{progress}</div>
          {prevRS < prevRQ &&
            <div className={classes.linearProgressDiv}>
              <LinearProgressWithLabel value={100 * prevRS / prevRQ} />
              {images.length > 0 &&
                <Carousel
                  className={classes.Carousel}
                  autoPlay={true}
                  indicators={true}
                  timeout={500}
                  cycleNavigation={true}
                  navButtonsAlwaysVisible={true}
                  navButtonsAlwaysInvisible={false}
                  fullHeightHover={true}
                >
                  {
                    images.map((item, index) => {
                      return <CardMedia
                        className={classes.Media}
                        image={item.image}
                      >
                      </CardMedia>
                    })
                  }
                </Carousel>
              }
            </div>
          }

          {companyData.map((company, index) =>
            <Box className={classes.root} m={2} >
              <QuotationDetails key={index} factories={factories} factoriesHub={factoriesHub} company={company} datipre={datipreventivo[company.sfo_id + company.paramurl + company.type]} saveActive={false} />
            </Box>
          )}

          {prevRS === prevRQ && images.length > 0 &&
            <Carousel
              className={classes.Carousel}
              autoPlay={true}
              animation={"fade"}
              indicators={true}
              timeout={500}
              cycleNavigation={true}
              navButtonsAlwaysVisible={true}
              navButtonsAlwaysInvisible={false}
              fullHeightHover={true}
            >
              {
                images.map((item, index) => {
                  return <CardMedia
                    key={index}
                    className={classes.Media2}
                    image={item.image}
                  >
                  </CardMedia>
                })
              }
            </Carousel>
          }
        </Paper>;
      case 6:
        return <div className={classes.paper}>
          {NumberQuotation &&
            <QuotationSingleDetail numberquotation={NumberQuotation} visual={true} />
          }
        </div>;
      default:
        return 'Unknown step';
    }
  }

  return (
    <main className={classes.content}>
      <BackGround open={loading} />
      <ValidatorForm onSubmit={() => { }} onError={errors => console.log(errors)}>
        <div className={classes.appBarSpacer} />

        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid className={classes.containerButton} item md={8}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" href="/" >
                  {NAME_APP}
                </Link>
                <Link color="inherit" href="/van" >
                  Autocarro
                </Link>
                <Typography color="textPrimary">Richiesta preventivo {targa}</Typography>
              </Breadcrumbs>
            </Grid>

            <Grid className={classes.containerButton} item md={4}>
              <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                Indietro
              </Button>
              {activeStep < steps.length &&
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  className={classes.button}
                  disabled={activeStep === steps.length - 1 || (activeStep === 5 && prevRS < prevRQ && prevRQ > 0)}
                >
                  Avanti
                </Button>
              }
            </Grid>
          </Grid>

          <Card>
            <CardContent>
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>

              <div className={classes.wrapper}>
                {activeStep === steps.length ?
                  (
                    <div>
                      <Typography className={classes.instructions}>
                        Richiesta Preventivo completata
                      </Typography>
                      <Button onClick={handleReset} className={classes.button}>
                        Nuovo preventivo
                      </Button>
                    </div>
                  ) :
                  (
                    <>
                      {getStepContent(activeStep)}
                    </>
                  )
                }
              </div>
            </CardContent>
          </Card>

          <Grid container spacing={3}>
            <Grid className={classes.containerButton} item md={12}>
              <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                Indietro
              </Button>
              {activeStep < steps.length &&
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  className={classes.button}
                  disabled={activeStep === steps.length - 1 || (activeStep === 5 && prevRS < prevRQ && prevRQ > 0)}
                >
                  Avanti
                </Button>
              }
            </Grid>
          </Grid>
        </Container>

        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={handleClose} severity={alertseverity}>
            {alertmsg}
          </Alert>
        </Snackbar>
      </ValidatorForm>
    </main>
  );
}