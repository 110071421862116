import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { id_cliente, ReturnInfoClient } from '../containers/logincontext';
import { API_URL_MULTISESSION } from '../config/config';
import BlockIcon from '@material-ui/icons/Block';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import LinkIcon from '@material-ui/icons/Link';
import { green, red } from '@material-ui/core/colors';
import Tooltip from '@material-ui/core/Tooltip';
import axios from 'axios';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Badge from '@material-ui/core/Badge';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Auth from '../auth/auth';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  alignFab: {
    marginLeft: 'auto',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  img: {
    display: 'block',
    maxWidth: '100%',
    maxHeight: '40px',
  },
  red_fill: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
  },
  green_fill: {
    color: '#fff',
    backgroundColor: green[500],
  },
  red: {
    color: red[500]
  },
  green: {
    color: green[500]
  },
  customBadgeOk: {
    backgroundColor: green[500],
    color: "white"
  },
  customBadgeKo: {
    backgroundColor: red[500],
    color: "white"
  }
}));

export default function MultiSession(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [alertseverity, setAlertSeverity] = React.useState("success");
  const [alertmsg, setAlertMsg] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <MaterialTable
        title="Multisessione"
        columns={[
          {
            title: 'Stato',
            field: 'status',
            editable: false,
            align: 'center',
            render: rowData => <Tooltip title={rowData.status === 1 ? "Connessione OK" : "Connessione KO"}>
              <Badge badgeContent={rowData.status === 1 ? <LinkIcon /> : <LinkOffIcon />} classes={rowData.status === 1 ? { badge: classes.customBadgeOk } : { badge: classes.customBadgeKo }} />
            </Tooltip>
          },
          {
            title: 'Nome utente',
            field: 'username',
          },
          {
            title: 'Password/Secret',
            field: 'password',
          },
          {
            title: 'Codice',
            field: 'companyCode',
          },
          {
            title: 'Parametri',
            field: 'companyParams',
          },
          {
            title: 'Codice Nodo',
            field: 'nodeCode',
          },
          {
            title: 'Attivo',
            field: 'enabled',
            lookup: { 1: 'Abilitato', 0: 'Disabilitato' },
            render: rowData => <Tooltip title={rowData.enabled === 1 ? "Abilitato" : "Disabilitato"}>
              {rowData.enabled === 1 ? <CheckCircleOutlineIcon className={classes.green} /> : <BlockIcon className={classes.red} />}
            </Tooltip>
          },
          {
            title: 'Monosessione',
            field: 'monosession',
            lookup: { 1: 'Si', 0: 'No' },
            render: rowData => <Tooltip title={rowData.monosession === 1 ? "Si" : "No"}>
              {rowData.monosession === 1 ? <CheckCircleOutlineIcon className={classes.green} /> : <BlockIcon className={classes.red} />}
            </Tooltip>
          }
        ]}
        localization={{
          pagination: {
            labelDisplayedRows: '{from}-{to} di {count}',
            labelRowsSelect: 'per pagina',
            labelRowsPerPage: 'per pagina',
            firstAriaLabel: 'prima pagina',
            firstTooltip: 'prima pagina',
            previousAriaLabel: 'pagina precedente',
            previousTooltip: 'pagina precedente',
            nextAriaLabel: 'pagina successiva',
            nextTooltip: 'pagina successiva',
            lastAriaLabel: 'ultima pagina',
            lastTooltip: 'ultima pagina',
          },
          toolbar: {
            nRowsSelected: '{0} elementi selezionati',
            searchTooltip: 'Ricerca',
            searchPlaceholder: 'Ricerca'
          },
          header: {
            actions: ''
          },
          body: {
            emptyDataSourceMessage: 'Nessun record da visualizzare',
            filterRow: {
              filterTooltip: 'Filtri'
            },
            editRow: {
              saveTooltip: "Conferma",
              cancelTooltip: "Annulla",
              deleteText: "Conferma cancellazione?"
            },
            addTooltip: "Aggiungi",
            deleteTooltip: "Cancella",
            editTooltip: "Modifica",
          }
        }}
        options={{
          pageSize: 5,
          pageSizeOptions: [5, 10],
          paginationType: "stepped",
          search: false,
          headerStyle: {
            backgroundColor: ReturnInfoClient(id_cliente, "primary_color"),
            color: '#ffffff',
            '&:hover': {
              color: '#ffffff',
            },
            '&:active': {
              color: '#ffffff',
            }
          }

        }}
        data={query =>
          new Promise((resolve, reject) => {
            let url = API_URL_MULTISESSION + "/" + props.id;
            url += '?per_page=' + query.pageSize
            url += '&page=' + (query.page + 1)

            if (query.search.length > 3) {
              url += '&search=' + query.search
            }
            if (query.orderBy !== undefined) {
              url += '&orderby=' + query.orderBy.field
              url += '&orderdirection=' + query.orderDirection
            }
            const requestOptions = {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Auth.getToken(),
              },
            };
            fetch(url, requestOptions).then(
              response => response.json()
            )
              .then(
                result => {
                  resolve(
                    {
                      data: result.data,
                      page: result.current_page - 1,
                      totalCount: result.total,
                    }
                  )
                }
              )
          }
          )
        }
        editable={{
          onRowAdd: newData =>
            new Promise((resolve, reject) => {
              const url = API_URL_MULTISESSION;
              axios.put(url, {
                id_ser_fac_org: props.id,
                enabled: newData.enabled === undefined ? true : newData.enabled,
                username: newData.username,
                password: newData.password,
                companyCode: newData.companyCode,
                nodeCode: newData.nodeCode,
                companyParams: newData.companyParams,
                monosession: newData.monosession,
              },
                {
                  headers: {
                    'Authorization': 'Bearer ' + Auth.getToken(),
                    'Content-Type': 'application/json'
                  }
                }
              ).then(payload => {
                if (payload.status === 200) {
                  setAlertMsg("Inserimento dati avvenuto con successo");
                  setAlertSeverity("success");
                } else {
                  setAlertMsg("Errore durante l'inserimento dati");
                  setAlertSeverity("error");
                }
                setOpen(true);
              }).catch((error) => {
                setAlertMsg(error.response.data.error);
                setAlertSeverity("error");
                setOpen(true);
              });
              resolve();
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const index = oldData.id;
              const url = API_URL_MULTISESSION + "/" + index;
              axios.patch(url, {
                enabled: newData.enabled === undefined ? true : newData.enabled,
                username: newData.username,
                password: newData.password,
                companyCode: newData.companyCode,
                nodeCode: newData.nodeCode,
                companyParams: newData.companyParams,
                monosession: newData.monosession,
              },
                {
                  headers: {
                    'Authorization': 'Bearer ' + Auth.getToken(),
                    'Content-Type': 'application/json'
                  }
                }
              ).then(payload => {
                if (payload.status === 200) {
                  setAlertMsg("Aggiornamento avvenuto con successo");
                  setAlertSeverity("success");
                } else {
                  setAlertMsg("Errore durante l'aggiornamento");
                  setAlertSeverity("error");
                }
                setOpen(true);
              }).catch((error) => {
                setAlertMsg(error.response.data.error);
                setAlertSeverity("error");
                setOpen(true);
              });
              resolve();
            }),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              const index = oldData.id;
              const url = API_URL_MULTISESSION + "/" + index;
              axios.delete(url,
                {
                  headers: {
                    'Authorization': 'Bearer ' + Auth.getToken(),
                    'Content-Type': 'application/json'
                  }
                }
              ).then(payload => {
                if (payload.status === 200) {
                  setAlertMsg("Cancellazione avvenuta con successo");
                  setAlertSeverity("success");
                } else {
                  setAlertMsg("Errore durante la cancellazione");
                  setAlertSeverity("error");
                }
                setOpen(true);
              }).catch((error) => {
                setAlertMsg(error.response.data.error);
                setAlertSeverity("error");
                setOpen(true);
              });
              resolve();
            })
        }}
      />

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={alertseverity}>
          {alertmsg}
        </Alert>
      </Snackbar>
    </>
  );
};