import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { TextValidator } from 'react-material-ui-form-validator';
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  API_URL_GET_INFOBIRTH
} from '../config/config';
import axios from 'axios';
import { drivingLicenseTypes, genders, statoCivile, livelliIstruzione, namemonths, subjectTypes } from '../config/config';
import { returnElement, returnElementValue } from './utils'
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%'
  }
}));

const getInfoBirth = async (token, url, cf, nome, cognome) => {
  try {
    const result = await axios.get(url + API_URL_GET_INFOBIRTH, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      params: {
        cf: cf,
        nome: nome,
        cognome: cognome
      }
    });
    return result;
  } catch (e) {
    return Promise.reject(e.response);
  }
};

function ReturnDefaultinsuranceYears(type, posinfo, datiquotazione, drivingLicenceYear) {
  let defaultret = "";
  var RespPrinc = [];

  for (let i = 10; i >= 0; i--) {
    if (datiquotazione.dataATRC !== undefined && datiquotazione.dataATRC !== null) {
      switch (i) {
        case 0:
          RespPrinc.push(datiquotazione.dataATRC.atrcRespPrincAnno0);
          break;
        case 1:
          RespPrinc.push(datiquotazione.dataATRC.atrcRespPrincAnno1);
          break;
        case 2:
          RespPrinc.push(datiquotazione.dataATRC.atrcRespPrincAnno2);
          break;
        case 3:
          RespPrinc.push(datiquotazione.dataATRC.atrcRespPrincAnno3);
          break;
        case 4:
          RespPrinc.push(datiquotazione.dataATRC.atrcRespPrincAnno4);
          break;
        case 5:
          RespPrinc.push(datiquotazione.dataATRC.atrcRespPrincAnno5);
          break;
        case 6:
          RespPrinc.push(datiquotazione.dataATRC.atrcRespPrincAnno6);
          break;
        case 7:
          RespPrinc.push(datiquotazione.dataATRC.atrcRespPrincAnno7);
          break;
        case 8:
          RespPrinc.push(datiquotazione.dataATRC.atrcRespPrincAnno8);
          break;
        case 9:
          RespPrinc.push(datiquotazione.dataATRC.atrcRespPrincAnno9);
          break;
        case 10:
          RespPrinc.push(datiquotazione.dataATRC.atrcRespPrincAnno10);
          break;
        default:
          break;
      }
    }
  }

  if (posinfo.defaultparameter !== null && JSON.parse(posinfo.defaultparameter).insuranceYears !== undefined) {
    //Calcola in base all'attestato di rischio
    if (JSON.parse(posinfo.defaultparameter).insuranceYears === "1") {
      let calcola = 10;
      let trovato = false;
      RespPrinc.forEach((item, index) => {
        if (trovato === false) {
          if (item !== "NA" && item !== "--") {
            calcola = index;
            trovato = true;
          }
        }
      });
      defaultret = 10 - calcola;
    }

    //Calcola in base alla data di conseguimento della patente
    if (JSON.parse(posinfo.defaultparameter).insuranceYears === "2") {
      var secondDate = new Date().getFullYear();
      if (datiquotazione[type].subjectType === "" || datiquotazione[type].subjectType === "1") {
        if (drivingLicenceYear !== "") {
          defaultret = secondDate - drivingLicenceYear;
        }
      }
    }
  }

  return defaultret;
}

export default function SubjectDetails(props) {
  const classes = useStyles();
  const [alertseverity, setAlertSeverity] = React.useState("success");
  const [alertmsg, setAlertMsg] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleChangeFiscalCode = event => {
    const codicefiscale = event.target.value;
    if (codicefiscale.length === 16) {
      getInfoBirth(props.token, props.urlservicequotiamo, codicefiscale, props.datiquotazione[props.type].name, props.datiquotazione[props.type].surname).then(payload => {
        if (payload.data.status === undefined) {
          if (props.datatype === "car") {
            props.setdatiquotazione({
              ...props.datiquotazione,
              [props.type]: {
                ...props.datiquotazione[props.type],
                fiscalCode: codicefiscale,
                birthCity: payload.data.city,
                birthProvince: payload.data.province,
                birthRegion: payload.data.region,
                birthNationAbbr: payload.data.nationAbbr,
                birthNation: payload.data.nation,
                gender: payload.data.gender,
                birthDate: payload.data.birthDate
              },
              drivingLicenseMonth: moment(payload.data.birthDate, "YYYY-MM-DD").add(18, "years").add(2, "months").format("M"),
              drivingLicenseYear: moment(payload.data.birthDate, "YYYY-MM-DD").add(18, "years").add(2, "months").format("YYYY"),
              insuranceYears: ReturnDefaultinsuranceYears(props.type, props.posinfo, props.datiquotazione, moment(payload.data.birthDate, "YYYY-MM-DD").add(18, "years").add(2, "months").format("YYYY")),
            });
          } else if (props.datatype === "van") {
            props.setdatiquotazione({
              ...props.datiquotazione,
              [props.type]: {
                ...props.datiquotazione[props.type],
                fiscalCode: codicefiscale,
                birthCity: payload.data.city,
                birthProvince: payload.data.province,
                birthRegion: payload.data.region,
                birthNationAbbr: payload.data.nationAbbr,
                birthNation: payload.data.nation,
                gender: payload.data.gender,
                birthDate: payload.data.birthDate
              },
              insuranceYears: ReturnDefaultinsuranceYears(props.type, props.posinfo, props.datiquotazione, moment(payload.data.birthDate, "YYYY-MM-DD").add(18, "years").add(2, "months").format("YYYY")),
            });
          } else {
            props.setdatiquotazione({
              ...props.datiquotazione,
              [props.type]: {
                ...props.datiquotazione[props.type],
                fiscalCode: codicefiscale,
                birthCity: payload.data.city,
                birthProvince: payload.data.province,
                birthRegion: payload.data.region,
                birthNationAbbr: payload.data.nationAbbr,
                birthNation: payload.data.nation,
                gender: payload.data.gender,
                birthDate: payload.data.birthDate
              }
            });
          }
        } else {
          props.setdatiquotazione({
            ...props.datiquotazione,
            [props.type]: {
              ...props.datiquotazione[props.type],
              fiscalCode: codicefiscale
            }
          });
          setAlertMsg(payload.data.message);
          setAlertSeverity("error");
          setOpen(true);
        }
      });
    } else {
      if (props.datatype === "car") {
        props.setdatiquotazione({
          ...props.datiquotazione,
          [props.type]: {
            ...props.datiquotazione[props.type],
            fiscalCode: codicefiscale,
            birthCity: "",
            birthProvince: "",
            birthRegion: "",
            birthNationAbbr: "",
            birthNation: "",
            gender: "",
            birthDate: ""
          },
          drivingLicenseMonth: "",
          drivingLicenseYear: ""
        });
      } else {
        props.setdatiquotazione({
          ...props.datiquotazione,
          [props.type]: {
            ...props.datiquotazione[props.type],
            fiscalCode: codicefiscale,
            birthCity: "",
            birthProvince: "",
            birthRegion: "",
            birthNationAbbr: "",
            birthNation: "",
            gender: "",
            birthDate: ""
          }
        });
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleChangeOwner = (owner) => (event) => {
    props.setdatiquotazione({
      ...props.datiquotazione,
      [owner]: {
        ...props.datiquotazione[owner],
        [event.target.name]: event.target.value
      }
    });
  };

  const handleChangeOwnerResidenceInfo = (owner) => (event) => {
    props.setdatiquotazione({
      ...props.datiquotazione,
      [owner]: {
        ...props.datiquotazione[owner],
        residenceInfo: {
          ...props.datiquotazione[owner].residenceInfo,
          [event.target.name]: event.target.value
        }
      }
    });
  };

  const handleChange = event => {
    props.setdatiquotazione({
      ...props.datiquotazione,
      [event.target.name]: event.target.value
    });
  };

  React.useEffect(() => {
    const codicefiscale = props.datiquotazione[props.type].fiscalCode;
    if (codicefiscale.length === 16 &&
      (props.datatype === "house" || props.datatype === "injuries" || props.datatype === "tcm" || props.datatype === "rcp" || props.datatype === "gf")
      && props.datiquotazione[props.type].birthDate === "") {
      getInfoBirth(props.token, props.urlservicequotiamo, codicefiscale, props.datiquotazione[props.type].name, props.datiquotazione[props.type].surname).then(payload => {
        props.setdatiquotazione({
          ...props.datiquotazione,
          [props.type]: {
            ...props.datiquotazione[props.type],
            fiscalCode: codicefiscale,
            birthCity: payload.data.city,
            birthProvince: payload.data.province,
            birthRegion: payload.data.region,
            birthNationAbbr: payload.data.nationAbbr,
            birthNation: payload.data.nation,
            gender: payload.data.gender,
            birthDate: payload.data.birthDate
          }
        });
      });
    }
  }, [props]);

  return <div className={classes.paper}>
    <Typography variant="h6">
      {props.type === "contractorInfo" && "Dati contraente"}
      {props.type === "ownerInfo" && "Dati intestatario"}
      {props.type === "driverInfo" && "Dati guidatore"}
      {props.type === "amministratoreInfo" && "Dati amministratore"}
    </Typography>
    <Grid container spacing={3}>
      {props.datatype !== "rcp" &&
        <>
          {props.datatype === "gf" && props.type === "ownerInfo" &&
            <Grid item xs={6}>
              <TextField
                margin="normal"
                fullWidth
                label="Tipo di soggetto"
                name="subjectType"
                onChange={handleChangeOwner(props.type)}
                required
                select
                SelectProps={{ native: true }}
                value={props.datiquotazione[props.type].subjectType === "" ? "1" : props.datiquotazione[props.type].subjectType}
                variant="outlined"
                disabled={props.datatype === "injuries" || props.datatype === "tcm" ? true : false}
              >
                {subjectTypes.map(option => (
                  <>
                    {option.value === 8 && props.datatype === "gf" &&
                      <option
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    }
                    {option.value !== 8 &&
                      <option
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    }
                  </>
                ))}
              </TextField>
            </Grid>
          }
          {props.datatype !== "gf" &&
            <Grid item xs={6}>
              <TextField
                margin="normal"
                fullWidth
                label="Tipo di soggetto"
                name="subjectType"
                onChange={handleChangeOwner(props.type)}
                required
                select
                SelectProps={{ native: true }}
                value={props.datiquotazione[props.type].subjectType === "" ? "1" : props.datiquotazione[props.type].subjectType}
                variant="outlined"
                disabled={props.datatype === "injuries" || props.datatype === "tcm" ? true : false}
              >
                {subjectTypes.map(option => (
                  <>
                    {option.value === 8 && props.datatype === "gf" &&
                      <option
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    }
                    {option.value !== 8 &&
                      <option
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    }
                  </>
                ))}
              </TextField>
            </Grid>
          }
        </>
      }
      <Grid item xs={6}>
        {(props.datatype === "tcm") && props.datiquotazione[props.type].subjectType !== "" && props.datiquotazione[props.type].subjectType === "1" &&
          <Autocomplete
            options={props.listProfessions}
            id="profession"
            label="Professione"
            name="profession"
            disableClearable
            getOptionLabel={(option) => option.description}
            autoHighlight
            required
            value={returnElement(props.listProfessions, props.datiquotazione[props.type].profession)}
            onChange={(event, newValue) => {
              props.setdatiquotazione({
                ...props.datiquotazione,
                [props.type]: {
                  ...props.datiquotazione[props.type],
                  profession: newValue.id,
                }
              });
            }}
            renderInput={(params) => <TextField required {...params} label="Professione" margin="normal" variant="outlined" />}
          />
        }
        {(props.datatype === "injuries") && props.datiquotazione[props.type].subjectType !== "" && props.datiquotazione[props.type].subjectType === "1" &&
          <Autocomplete
            options={props.listProfessions}
            id="profession"
            label="Professione"
            name="profession"
            disableClearable
            getOptionLabel={(option) => option.label}
            autoHighlight
            required
            value={returnElementValue(props.listProfessions, props.datiquotazione[props.type].profession)}
            onChange={(event, newValue) => {
              props.setdatiquotazione({
                ...props.datiquotazione,
                [props.type]: {
                  ...props.datiquotazione[props.type],
                  profession: newValue.value,
                }
              });
            }}
            renderInput={(params) => <TextField required {...params} label="Professione" margin="normal" variant="outlined" />}
          />
        }
      </Grid>
    </Grid>

    {(props.datatype === "injuries") && props.datiquotazione[props.type].subjectType !== "" && props.datiquotazione[props.type].subjectType === "1" &&
      <Grid container spacing={3}>
        <Grid item xs={6}>
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            aria-label="Acknowledge"
            onClick={(event) => {
              props.setdatiquotazione({
                ...props.datiquotazione,
                [event.target.name]: !props.datiquotazione.lavoratoreDipendente
              });
              event.stopPropagation();
            }}
            onFocus={(event) => event.stopPropagation()}
            control={<Checkbox name="lavoratoreDipendente" checked={props.datiquotazione.lavoratoreDipendente} />}
            label="Lavoratore dipendente"
          />
        </Grid>
      </Grid>
    }

    {(props.datiquotazione[props.type].subjectType !== "" && props.datiquotazione[props.type].subjectType !== "1") &&
      <Fragment>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextValidator
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="companyName"
              label="Ragione sociale"
              name="companyName"
              value={props.datiquotazione[props.type].companyName}
              onChange={handleChangeOwner(props.type)}
              validators={['required']}
              errorMessages={['Campo obbligatorio']}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextValidator
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="vatNumber"
              label="P.IVA"
              name="vatNumber"
              value={props.datiquotazione[props.type].vatNumber}
              onChange={handleChangeOwner(props.type)}
              validators={['required']}
              errorMessages={['Campo obbligatorio']}
            />
          </Grid>
          <Grid item xs={6}>
            <TextValidator
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="codiceFiscale"
              label="Codice Fiscale"
              name="fiscalCode"
              value={props.datiquotazione[props.type].fiscalCode}
              onChange={handleChangeOwner(props.type)}
              validators={['required']}
              errorMessages={['Campo obbligatorio']}
            />
          </Grid>
        </Grid>
      </Fragment>
    }

    {(props.datiquotazione[props.type].subjectType === "" || props.datiquotazione[props.type].subjectType === "1") &&
      <Fragment>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextValidator
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="surname"
              label="Cognome"
              name="surname"
              value={props.datiquotazione[props.type].surname}
              onChange={handleChangeOwner(props.type)}
              validators={['required']}
              errorMessages={['Campo obbligatorio']}
            />
          </Grid>
          <Grid item xs={6}>
            <TextValidator
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="name"
              label="Nome"
              name="name"
              value={props.datiquotazione[props.type].name}
              onChange={handleChangeOwner(props.type)}
              validators={['required']}
              errorMessages={['Campo obbligatorio']}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextValidator
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="codiceFiscale"
              label="Codice Fiscale"
              name="fiscalCode"
              value={props.datiquotazione[props.type].fiscalCode}
              onChange={handleChangeFiscalCode}
              validators={['required']}
              errorMessages={['Campo obbligatorio']}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="birthDate"
              label="Data di nascita"
              name="birthDate"
              disabled
              value={props.datiquotazione[props.type].birthDate !== "" ? moment(props.datiquotazione[props.type].birthDate, "YYYY-MM-DD").format("DD/MM/YYYY") : ""}
              onChange={handleChangeOwner(props.type)}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              margin="normal"
              fullWidth
              label="Sesso"
              name="gender"
              onChange={handleChangeOwner(props.type)}
              disabled
              select
              SelectProps={{ native: true }}
              value={props.datiquotazione[props.type].gender}
              variant="outlined"
            >
              {genders.map(option => (
                <option
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </TextField>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              margin="normal"
              disabled
              fullWidth
              id="birthCity"
              label="Comune di nascita"
              name="birthCity"
              value={props.datiquotazione[props.type].birthCity}
              onChange={handleChangeOwner([props.type])}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>

          <Grid item xs={2}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="birthProvince"
              label="Provincia di nascita"
              name="birthProvince"
              disabled
              value={props.datiquotazione[props.type].birthProvince}
              onChange={handleChangeOwner(props.type)}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>

          <Grid item xs={2}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              disabled
              id="birthRegion"
              label="Regione di nascita"
              name="birthRegion"
              value={props.datiquotazione[props.type].birthRegion}
              onChange={handleChangeOwner(props.type)}
            />
          </Grid>

          <Grid item xs={2}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="birthNation"
              label="Stato di nascita"
              name="birthNation"
              disabled
              value={props.datiquotazione[props.type].birthNation}
              onChange={handleChangeOwner(props.type)}
            />
          </Grid>
        </Grid>

        {(props.datatype === "car" || props.datatype === "motorbike") &&
          <>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Autocomplete
                  options={props.listProfessions}
                  id="profession"
                  label="Professione"
                  name="profession"
                  disableClearable
                  getOptionLabel={(option) => option.description}
                  autoHighlight
                  required
                  value={returnElement(props.listProfessions, props.datiquotazione[props.type].profession)}
                  onChange={(event, newValue) => {
                    props.setdatiquotazione({
                      ...props.datiquotazione,
                      [props.type]: {
                        ...props.datiquotazione[props.type],
                        profession: newValue.id,
                      }
                    });
                  }}
                  renderInput={(params) => <TextField required {...params} label="Professione" margin="normal" variant="outlined" />}
                />
              </Grid>

              <Grid item xs={4}>
                <Autocomplete
                  options={livelliIstruzione}
                  id="qualification"
                  label="Livello di istruzione"
                  name="qualification"
                  disableClearable
                  getOptionLabel={(option) => option.description}
                  autoHighlight
                  required
                  value={returnElement(livelliIstruzione, props.datiquotazione[props.type].qualification)}
                  onChange={(event, newValue) => {
                    props.setdatiquotazione({
                      ...props.datiquotazione,
                      [props.type]: {
                        ...props.datiquotazione[props.type],
                        qualification: newValue.id,
                      }
                    });
                  }}
                  renderInput={(params) => <TextField required {...params} label="Livello di istruzione" margin="normal" variant="outlined" />}
                />
              </Grid>

              <Grid item xs={4}>
                <Autocomplete
                  options={statoCivile}
                  id="maritalStatus"
                  label="Stato civile"
                  name="maritalStatus"
                  disableClearable
                  getOptionLabel={(option) => option.description}
                  autoHighlight
                  required
                  value={returnElement(statoCivile, props.datiquotazione[props.type].maritalStatus)}
                  onChange={(event, newValue) => {
                    props.setdatiquotazione({
                      ...props.datiquotazione,
                      [props.type]: {
                        ...props.datiquotazione[props.type],
                        maritalStatus: newValue.id,
                      }
                    });
                  }}
                  renderInput={(params) => <TextField required {...params} label="Stato civile" margin="normal" variant="outlined" />}
                />
              </Grid>
            </Grid>
          </>
        }

        {props.datatype === "car" && props.type !== "ownerInfo" &&
          <>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <TextField
                  margin="normal"
                  fullWidth
                  label="Hai figli?"
                  name="sons"
                  onChange={handleChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={props.datiquotazione.sons}
                  variant="outlined"
                >
                  <option key="sonsNothing" value="">
                  </option>
                  <option key="sonsNo" value={false}>
                    NO
                  </option>
                  <option key="sonsYes" value={true}>
                    SI
                  </option>
                </TextField>
              </Grid>

              {props.datiquotazione.sons === "true" &&
                <Fragment>
                  <Grid item xs={2}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="nSons1"
                      label="Figli 0-3 anni"
                      name="nSons1"
                      value={props.datiquotazione.nSons1}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="nSons2"
                      label="Figli 4-16 anni"
                      name="nSons2"
                      value={props.datiquotazione.nSons2}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="nSons3"
                      label="Figli 17-25 anni"
                      name="nSons3"
                      value={props.datiquotazione.nSons3}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="nSons4"
                      label="Figli 26 anni o più"
                      name="nSons4"
                      value={props.datiquotazione.nSons4}
                      onChange={handleChange}
                    />
                  </Grid>
                </Fragment>
              }
            </Grid>
          </>
        }
      </Fragment>
    }

    <Typography variant="h6">Indirizzo</Typography>
    {/* props.datatype !== "injuries" && */}
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <TextValidator
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="toponym"
          label="Via,piazza,ecc"
          name="toponym"
          value={props.datiquotazione[props.type].residenceInfo.toponym}
          onChange={handleChangeOwnerResidenceInfo(props.type)}
          validators={['required', 'minStringLength:2']}
          errorMessages={['Campo obbligatorio', 'Campo obbligatorio almeno 2 caratteri']}
        />
      </Grid>
      <Grid item xs={6}>
        <TextValidator
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="address"
          label="Indirizzo"
          name="address"
          value={props.datiquotazione[props.type].residenceInfo.address}
          onChange={handleChangeOwnerResidenceInfo(props.type)}
          validators={['required']}
          errorMessages={['Campo obbligatorio']}
        />
      </Grid>
      <Grid item xs={2}>
        <TextValidator
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="streetNumber"
          label="Numero civico"
          name="streetNumber"
          value={props.datiquotazione[props.type].residenceInfo.streetNumber}
          onChange={handleChangeOwnerResidenceInfo(props.type)}
          validators={['required']}
          errorMessages={['Campo obbligatorio']}
        />
      </Grid>
    </Grid>

    <Grid container spacing={3}>
      <Grid item xs={2}>
        <TextValidator
          variant="outlined"
          margin="normal"
          required
          fullWidth
          disabled
          id="cap"
          label="cap"
          name="cap"
          value={props.datiquotazione[props.type].residenceInfo.cap}
          onChange={handleChangeOwnerResidenceInfo(props.type)}
          validators={['required']}
          errorMessages={['Campo obbligatorio']}
        />
      </Grid>
      <Grid item xs={8}>
        <Autocomplete
          options={props.listCity}
          defaultValue={{
            cap: props.datiquotazione[props.type].residenceInfo.cap,
            city: props.datiquotazione[props.type].residenceInfo.city,
            provinceAbbr: props.datiquotazione[props.type].residenceInfo.provinceAbbr
          }}
          getOptionSelected={(option, value) => option.city === value.city && option.cap === value.cap}
          getOptionLabel={(option) => {
            return (`${option.city}`)
          }}
          id="comune"
          label="Comune *"
          name="city"
          autoHighlight
          onChange={(event, newValue) => {
            props.listCity.map((option) => {
              if (newValue === option) {
                props.setdatiquotazione({
                  ...props.datiquotazione,
                  [props.type]: {
                    ...props.datiquotazione[props.type],
                    residenceInfo: {
                      ...props.datiquotazione[props.type].residenceInfo,
                      city: option.city,
                      cap: option.cap,
                      provinceAbbr: option.provinceAbbr,
                      istat: option.istat
                    }
                  }
                });
                return option
              }
              return false;
            }
            )
          }}
          renderInput={(params) => <TextField {...params} label="Comune *" margin="normal" variant="outlined" />}
          renderOption={(option) => {
            return (`${option.city} (${option.cap})`)
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <TextValidator
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="provinceAbbr"
          label="Provincia (Sigla)"
          name="provinceAbbr"
          disabled
          value={props.datiquotazione[props.type].residenceInfo.provinceAbbr}
          onChange={handleChangeOwner(props.type)}
          validators={['required', 'maxStringLength:2']}
          errorMessages={['Campo obbligatorio']}
          maxLength={2}
        />
      </Grid>
    </Grid>

    <Grid container spacing={3}>
      <Grid item xs={6}>
        <TextValidator
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          name="email"
          value={props.datiquotazione[props.type].email}
          onChange={handleChangeOwner(props.type)}
          validators={['required', 'isEmail']}
          errorMessages={['Campo obbligatorio', 'Email non valida']}
        />
      </Grid>
      <Grid item xs={6}>
        <TextValidator
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="phoneNumber"
          label="Numero cellulare"
          name="phoneNumber"
          value={props.datiquotazione[props.type].phoneNumber}
          onChange={handleChangeOwner(props.type)}
          validators={['required']}
          errorMessages={['Campo obbligatorio']}
        />
      </Grid>
    </Grid>

    {props.datatype === "car" && props.type !== "ownerInfo" && (props.datiquotazione[props.type].subjectType === "" || props.datiquotazione[props.type].subjectType === "1") &&
      <Fragment>
        <Typography variant="h6">Dati patente</Typography>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Autocomplete
              options={drivingLicenseTypes}
              id="drivingLicenseType"
              label="Tipo di patente"
              name="drivingLicenseType"
              disableClearable
              getOptionLabel={(option) => option.description}
              value={returnElement(drivingLicenseTypes, props.datiquotazione.drivingLicenseType)}
              autoHighlight
              onChange={(event, newValue) => {
                props.setdatiquotazione({
                  ...props.datiquotazione,
                  drivingLicenseType: newValue.id,
                });
              }}
              renderInput={(params) => <TextField {...params} label="Tipo di patente" margin="normal" variant="outlined" />}
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              margin="normal"
              fullWidth
              label="Mese conseguimento patente"
              name="drivingLicenseMonth"
              onChange={handleChange}
              select
              SelectProps={{ native: true }}
              value={props.datiquotazione.drivingLicenseMonth}
              variant="outlined"
            >
              {namemonths.map(option => (
                <option
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={3}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="drivingLicenseYear"
              label="Anno conseguimento patente"
              name="drivingLicenseYear"
              value={props.datiquotazione.drivingLicenseYear}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="drivingLicensePoints"
              label="Punti patente"
              name="drivingLicensePoints"
              value={props.datiquotazione.drivingLicensePoints}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </Fragment>
    }

    {(props.datatype === "bike" || props.datatype === "motorbike") && props.type !== "ownerInfo" && (props.datiquotazione[props.type].subjectType === "" || props.datiquotazione[props.type].subjectType === "1") &&
      <Fragment>
        <Typography variant="h6">Dati patente</Typography>
        <Grid container spacing={3}>

          <Grid item xs={3}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="drivingLicenseYear"
              label="Anno conseguimento patente"
              name="drivingLicenseYear"
              value={props.datiquotazione.drivingLicenseYear}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </Fragment>
    }

    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <Alert onClose={handleClose} severity={alertseverity}>
        {alertmsg}
      </Alert>
    </Snackbar>
  </div>;
}
