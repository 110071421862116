import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { green, red } from '@material-ui/core/colors';
import { API_URL_GUARANTEES, inputTipoCalcoloListsDefault, inputTipoArrotondamentoListsDefault } from '../config/config';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Auth from '../auth/auth';
import TableHead from '@material-ui/core/TableHead';
import { Select } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  alignFab: {
    marginLeft: 'auto',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  img: {
    display: 'block',
    maxWidth: '100%',
    maxHeight: '40px',
    minWidth: '50px',

  },
  img_service: {
    display: 'block',
    maxWidth: '40px',
    maxHeight: '20px',
  },
  red: {
    color: red[500],
  },
  green: {
    color: green[500],
  },
  hiddenDiv: {
    display: 'none'
  },
}));

export default function EditGuaranteesParameters(props) {
  const classes = useStyles();
  const [data, setData] = React.useState(props.data);

  const [open, setOpen] = React.useState(false);
  const [alertseverity, setAlertSeverity] = React.useState("success");
  const [alertmsg, setAlertMsg] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleChangeValue = (id) => (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value
    });
  };

  const handleChangeTipoCalcolo = (id) => (event) => {
    setData({
      ...data,
      tipo_calcolo_default: event.target.value
    });
  }

  const handleChangeTipoArrotondamento = (id) => (event) => {
    setData({
      ...data,
      tipo_arrotondamento_calcolo: event.target.value
    });
  }

  const handleSave = () => {
    new Promise((resolve, reject) => {
      const index = props.data.id;
      let url;
      url = API_URL_GUARANTEES + "/" + index;
      axios.patch(url, {
        ...props.data,
        tipo_calcolo_default: data.tipo_calcolo_default,
        service_parameters_default: data.service_parameters_default,
        tipo_arrotondamento_calcolo: data.tipo_arrotondamento_calcolo,
        intervallo_peso: data.intervallo_peso,
      },
        {
          headers: {
            'Authorization': 'Bearer ' + Auth.getToken(),
            'Content-Type': 'application/json'
          }
        }
      ).then(payload => {
        if (payload.status === 200) {
          setAlertMsg("Aggiornamento avvenuto con successo");
          setAlertSeverity("success");
        } else {
          setAlertMsg("Errore durante l'aggiornamento");
          setAlertSeverity("error");
        }
        setOpen(true);
      }).catch((error) => {
        setAlertMsg(error.response.data.error);
        setAlertSeverity("error");
        setOpen(true);
      });
      resolve();
    })
  }

  return (
    <Card >
      <CardContent>
        <TableContainer className={classes.table}>
          <Table aria-label="Parametri default garanzia">
            <TableHead>
              <TableRow>
                <TableCell>Nome parametro</TableCell>
                <TableCell>Default</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  Tipo di calcolo
                </TableCell>
                <TableCell>
                  <Select
                    value={data.tipo_calcolo_default}
                    onChange={handleChangeTipoCalcolo(data.id)}
                    label="Tipo di calcolo"
                  >
                    {inputTipoCalcoloListsDefault.map(option => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                      >
                        {option.description}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Parametri di servizio
                </TableCell>
                <TableCell>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="service_parameters_default"
                    autoFocus
                    value={data.service_parameters_default}
                    onChange={handleChangeValue(data.id)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Tipo di arrotondamento (per %)
                </TableCell>
                <TableCell>
                  <Select
                    value={data.tipo_arrotondamento_calcolo}
                    onChange={handleChangeTipoArrotondamento(data.id)}
                    label="Tipo di arrotondamento (per %)"
                  >
                    {inputTipoArrotondamentoListsDefault.map(option => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                      >
                        {option.description}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Intervallo peso (in KG) per la visualizzazione, valori divisi da "-" (Solo autocarri)
                </TableCell>
                <TableCell>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="intervallo_peso"
                    autoFocus
                    value={data.intervallo_peso}
                    onChange={handleChangeValue(data.id)}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={alertseverity}>
            {alertmsg}
          </Alert>
        </Snackbar>
      </CardContent>
      <CardActions>
        <Button onClick={handleSave} startIcon={<SaveIcon />} size="small">Salva</Button>
      </CardActions>
    </Card>
  );
};