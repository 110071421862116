import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { API_URL_QUOTATION_LIST } from '../config/config';
import axios from 'axios';
import Auth from '../auth/auth';
import CircularProgress from '@material-ui/core/CircularProgress';
import { id_cliente, ReturnInfoClient } from '../containers/logincontext';
import { Helmet } from "react-helmet";
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useParams } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import NumberFormat from 'react-number-format';
import { parseFloat2Decimals } from './utils';
import Table from '@material-ui/core/Table';

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalpaper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  orglogo: {
    maxHeight: "30px",
  },
  factorylogo: {
    maxHeight: "30px"
  },
  msgResponse: {
    fontSize: "19px",
    fontWeight: 600,
    textAlign: "center",
    padding: "10px"
  },
  TableInfo: {
    width: "100%",
  },
  CardInfo: {
    width: "100%",
  },
  Cell1: {
    width: "60%"
  },
  Cell2: {
    width: "40%"
  }
}));

export default function ResultPayment(props) {
  const classes = useStyles();
  const [paymentState, setpaymentState] = React.useState(0);
  /*let numberquotation  = props.numberquotation;
  let paymentresult  = props.paymentresult;          */
  let { numberquotation } = useParams();
  let { paymentresult } = useParams();

  const [factory, setFactory] = React.useState(false);
  const [quotation, setQuotation] = React.useState(false);

  React.useEffect(() => {
    const search = window.location.search;
    if (paymentresult !== undefined && (paymentresult === "paymentdone" || paymentresult === "paymentcancel")) {
      const url = API_URL_QUOTATION_LIST + "/" + numberquotation + "/" + paymentresult + search;
      axios.get(url,
        {
          headers: {
            'Authorization': 'Bearer ' + Auth.getToken(),
            'Content-Type': 'application/json'
          }
        }
      ).then(payload => {
        if (payload.status === 200) {
          setQuotation(payload.data.quotation);
          setFactory(payload.data.factory);
          if (payload.data.status === 1) {
            setpaymentState(1);
          } else {
            setpaymentState(2);
          }
          //window.location.href = "/quotationsingle/"+numberquotation;              
        } else {
          setpaymentState(2);
        }
      });
    }
  }, [numberquotation, paymentresult]);

  return (
    <Container component="main" maxWidth="xs">
      <Helmet>
        <title>{ReturnInfoClient(id_cliente, "nome_cliente")}</title>
      </Helmet>
      <CssBaseline />
      <div className={classes.paper}>
        <div>
          <img src={ReturnInfoClient(id_cliente, "logo")} alt={ReturnInfoClient(id_cliente, "nome_cliente")} className="siteLogo" />
        </div>

        {quotation !== false &&
          <div>
            <img src={quotation.active_image} alt={quotation.active_businessname} className={classes.orglogo} />
          </div>
        }

        <Card className={classes.CardInfo}>
          {quotation !== false &&
            <TableContainer className={classes.TableInfo} component={Paper} >
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Preventivo numero</TableCell>
                    <TableCell align="right">{numberquotation}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <img src={factory.image} alt={factory.description} className={classes.factorylogo} />
                    </TableCell>
                    <TableCell component="th" scope="row">
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Nr. preventivo {factory.description}
                    </TableCell>
                    <TableCell align="right" component="th" scope="row">
                      {JSON.parse(quotation.select).nrQuotation}
                    </TableCell>
                  </TableRow>
                  {JSON.parse(quotation.select).listResponseCVT.map((singlecvt, index) =>
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {singlecvt.nameCVT}
                      </TableCell>
                      <TableCell align="right" component="th" scope="row">
                        {false &&
                          <NumberFormat fixedDecimalScale="true" decimalScale="2" value={parseFloat(singlecvt.price)} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} />
                        }
                      </TableCell>
                    </TableRow>
                  )}
                  {false &&
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Commissioni
                      </TableCell>
                      <TableCell align="right" component="th" scope="row">
                        <NumberFormat fixedDecimalScale="true" decimalScale="2" value={JSON.parse(quotation.select).Commissions} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} />
                      </TableCell>
                    </TableRow>
                  }
                  {false &&
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Diritti
                      </TableCell>
                      <TableCell align="right" component="th" scope="row">
                        <NumberFormat fixedDecimalScale="true" decimalScale="2" value={JSON.parse(quotation.select).Fees} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} />
                      </TableCell>
                    </TableRow>
                  }
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Totale preventivo
                    </TableCell>
                    <TableCell align="right" component="th" scope="row">
                      <NumberFormat fixedDecimalScale="true" decimalScale="2" value={parseFloat2Decimals(JSON.parse(quotation.select).TotalPrice)} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Spese incasso
                    </TableCell>
                    <TableCell align="right" component="th" scope="row">
                      <NumberFormat fixedDecimalScale="true" decimalScale="2" value={parseFloat2Decimals(quotation.payment_import - JSON.parse(quotation.select).TotalPrice)} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Totale
                    </TableCell>
                    <TableCell align="right" component="th" scope="row">
                      <NumberFormat fixedDecimalScale="true" decimalScale="2" value={quotation.payment_import} displayType={'text'} decimalSeparator={","} thousandSeparator={"."} suffix={' €'} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          }
          <Typography color="textPrimary" className={classes.msgResponse}>
            {paymentresult !== undefined && paymentresult === "paymentcancel" &&
              <div>
                Il pagamento è stato annullato
              </div>
            }
            {paymentState === 0 &&
              <div className={classes.root}>
                Verifica pagamento in corso preventivo numero {numberquotation}....
                <CircularProgress />
              </div>}
            {paymentState === 1 &&
              <div className={classes.root}>
                Il pagamento è avvenuto con successo!
              </div>}
            {paymentState === 2 &&
              <div className={classes.root}>
                Si è verificato un errore in fase di pagamento
              </div>}
          </Typography>
        </Card>
      </div>
    </Container>
  );
}
