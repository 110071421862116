import React from 'react';
import Auth from '../auth/auth';
import Header from './header';
import QuotationRcp from './QuotationRcp';
import QuotationRcpFacile from './QuotationRcpFacile';

export default function QuotationRcpRedirect(props) {
    const userData = Auth.getUser();

    return (
        <>
            {userData.id_template === null &&
                <>
                    <Header />
                    <QuotationRcp />
                </>
            }
            {userData.id_template !== null && userData.id_template === 1 && userData.id_collaboratore_sso !== null && userData.id_user_sso !== null &&
                <QuotationRcpFacile />
            }
        </>
    );
}