import React,{useState} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Auth from '../auth/auth';
import {id_cliente,ReturnInfoClient} from '../containers/logincontext';
import {Helmet} from "react-helmet";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {NAME_APP} from '../config/config';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}






const useStyles = makeStyles((theme) => ({
  buttonProgress: {    
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalpaper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUp(pars) {
  const classes = useStyles();
  const [email,setEmail]=useState('');
  const [name,setName]=useState('');
  const [surname,setSurName]=useState('');
  const [values,setValues]=useState({
    password: '',
    showPassword: false
  });
  
  


  const [open, setOpen] = useState(false);
  const [msgmodal,setMsgModal] = useState('');
  const [msgTitle,setMsgTitle] = useState('');
  const [loading, setLoading] = React.useState(false);

  const handleOpen = (title,msg) => {
    setMsgTitle(title);
    setMsgModal(msg);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };


  const signUpUser= (e) =>{
      
      e.preventDefault();     
      
      setLoading(true); 
      Auth.signup(name,surname,email,values.password).then(          
          payload =>{                            
              setLoading(false);
              handleOpen("success","Registrazione avvenuta con successo. Confermare l'email inviata all'indirizzo "+payload.data["email"]);
          }).catch(error=>{
            setLoading(false);
            handleOpen("error",error);
        });
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Helmet>
        <title>{NAME_APP} - Registrazione</title>        
      </Helmet>
      <div className={classes.paper}>
      
        <div>
        <img src={ReturnInfoClient(id_cliente,"logo")} alt={ReturnInfoClient(id_cliente,"nome_cliente")}  className="siteLogo"/>                                                
        </div> 
                
        
        <form className={classes.form} noValidate onSubmit ={signUpUser}>                  


              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="name"
                label="Nome"
                name="name"
                
                autoFocus
                value={name}                            
                onChange ={(e)=> setName(e.target.value)}
              />

             <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="surname"
                label="Cognome"
                name="surname"                
                
                value={surname}                            
                onChange ={(e)=> setSurName(e.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Indirizzo Email"
                name="email"
                autoComplete="email"
                
                value={email}                            
                onChange ={(e)=> setEmail(e.target.value)}
              />

              
            
            <FormControl className={classes.form} variant="outlined">
              <InputLabel htmlFor="password">Password</InputLabel>
              <OutlinedInput
                name="password"
                id="password"            
                
                type={values.showPassword ? 'text' : 'password'}
                value={values.password}
                onChange={handleChange('password')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                } labelWidth={70}
              />
            </FormControl>                                            
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
            className={classes.submit}
          >
            Registrati
          </Button>
          {loading && <CircularProgress size={24} className={classes.buttonProgress} />}          
        </form>
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={msgTitle}>
          {msgmodal}
          </Alert>
      </Snackbar>
      
      
    </Container>
  );
}


/*

<Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="Nome"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Cognome"
                name="lastName"
                autoComplete="lname"
              />
            </Grid>

*/
