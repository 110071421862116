import React from 'react';
import {withStyles,makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';
import MaterialTable from 'material-table';
import {id_cliente,ReturnInfoClient} from '../containers/logincontext';
import {API_URL_ORGANIZATIONS} from '../config/config';
import { green, red } from '@material-ui/core/colors';
import Tooltip from '@material-ui/core/Tooltip';
import CompaniesParametersTable from './companiesParametersTable';
import LockIcon from '@material-ui/icons/Lock';
import Typography from '@material-ui/core/Typography';
/*import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';*/
import {NAME_APP} from '../config/config';
import Auth from '../auth/auth';


const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);



const useStyles = makeStyles((theme) => ({
    alignFab: {
        marginLeft: 'auto',
      },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),      
    },
    img: {            
        maxWidth: '80px',
        maxHeight: '40px',
        justifyContent: 'center'
    },
    red_fill: {
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
    },
    green_fill: {
        color: '#fff',
        backgroundColor: green[500],
    },
    red: {
      color: red[500]
      
    },
    green: {
        color: green[500]        
    },
    customBadgeOk: {
      backgroundColor: green[500],
      color: "white"
    },
    customBadgeKo: {
      backgroundColor: red[500],
      color: "white"
    }
}));

export default function CompaniesParameters() {    
    const classes = useStyles();
    
    

    

    return (
        <main className={classes.content}>                        
            <div className={classes.appBarSpacer} />            
            <Container maxWidth="lg" className={classes.container}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" href="/" >
                      {NAME_APP}
                    </Link>                                        
                    <Typography color="textPrimary">Connessioni fabbriche</Typography>    
                </Breadcrumbs>

                <Card>
                    <CardContent>
                    <MaterialTable 
                        title="Connessioni fabbriche"
                        columns={[                                                                              
                                
                                { 
                                  title: 'Organizzazione', 
                                  field: 'id' ,
                                  align: 'center',                                                                        
                                  render: rowData =><img className={classes.img} alt={rowData.description} src={rowData.image} />                                  
                                },                                
                                { 
                                  title: 'Descrizione', 
                                  field: 'description' ,                                  
                                },
                                { 
                                  title: '', 
                                  field: 'enabled' ,
                                  align: 'center',                                                                        
                                  render: rowData =><div>{rowData.enabled===0 &&
                                    <HtmlTooltip title={<React.Fragment>
                                                            <Typography color="inherit">Organizzazione disabilitata</Typography>                                      
                                                              <em>L'organizzazione è disabilitata</em><br/>
                                                              Per abilitarla andare nel menù <u>Organizzazioni</u>                                        
                                                        </React.Fragment>}>                  
                                            <LockIcon className={classes.red}/>                                     
                                      </HtmlTooltip> 
                                    }
                                    </div>
                                },
                                ]}

                        detailPanel={[
                          {
                            tooltip: 'Verifica parametri connessione',
                            render: rowData => {
                              return <CompaniesParametersTable dataPanel={rowData}  />;                                                            
                            },
                          },
                        ]
                        }

                        
                          
                        
                        localization={{
                            pagination: {
                                labelDisplayedRows: '{from}-{to} di {count}',
                                labelRowsSelect: 'per pagina',
                                labelRowsPerPage : 'per pagina',
                                firstAriaLabel: 'prima pagina',
                                firstTooltip : 'prima pagina',
                                previousAriaLabel: 'pagina precedente',
                                previousTooltip: 'pagina precedente',
                                nextAriaLabel: 'pagina successiva',
                                nextTooltip: 'pagina successiva',
                                lastAriaLabel: 'ultima pagina',
                                lastTooltip: 'ultima pagina',
                            },
                            toolbar: {  
                                nRowsSelected: '{0} elementi selezionati',
                                searchTooltip: 'Ricerca',
                                searchPlaceholder : 'Ricerca'
                            },                            
                            header: {
                                actions: ''
                            },
                            body: {
                                emptyDataSourceMessage: 'Nessun record da visualizzare',
                                filterRow: {
                                    filterTooltip: 'Filtri'
                                },
                                editRow:{
                                    saveTooltip: "Conferma",
                                    cancelTooltip: "Annulla",
                                    deleteText: "Sei sicuro di voler cancellare?"
                                },
                                addTooltip: "Aggiungi",
                                deleteTooltip: "Cancella",
                                editTooltip: "Modifica",
                            }                          
                        }}
                        options={{
                            pageSize: 10,
                            pageSizeOptions: [5,10],
                            paginationType: "stepped",
                            search: true,                            
                            headerStyle: {
                            backgroundColor: ReturnInfoClient(id_cliente,"primary_color"),
                            color:  '#ffffff',    
                                    '&:hover': {
                                        color: '#ffffff',
                                    },
                                    '&:active': {
                                        color: '#ffffff',
                                    }          
                            }
            
                        }}
                        data={query =>
                                new Promise((resolve, reject) => {            
                                    let url = API_URL_ORGANIZATIONS;
                                        url += '?per_page=' + query.pageSize
                                        url += '&page=' + (query.page + 1)

                                    if(query.search.length>3){
                                        url += '&search=' + query.search
                                    }            
                                    if(query.orderBy!==undefined){                                    
                                        url += '&orderby=' + query.orderBy.field
                                        url += '&orderdirection=' + query.orderDirection                                    
                                    }
            
                                    const requestOptions = {                                    
                                        headers: { 
                                            'Content-Type': 'application/json',
                                            'Authorization': 'Bearer '+Auth.getToken(),                
                                        },              
                                    };


                                    fetch(url,requestOptions)            
                                    .then(                  
                                        response => response.json()
                                    )
                                    .then(                
                                        result => {                                      
                                        resolve({
                                        data: result.data,                  
                                        page: result.current_page-1,
                                        totalCount: result.total,
                                        })
                                    })
                                })
                            }
                            
                        />

                    </CardContent>        
                    
                    
                </Card>          
          </Container>                      
        </main>
      );
};



/*

<div>
                                    <CompaniesParametersTable dataPanel={rowData}  />
                                    <Accordion>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                    >
                                      <Typography className={classes.heading}>{rowData.description} - Web Service</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <CompaniesParametersTable dataPanel={rowData} type="ws"/>
                                    </AccordionDetails>
                                  </Accordion>
                                  <Accordion>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel2a-content"
                                      id="panel2a-header"
                                    >
                                      <Typography className={classes.heading}>{rowData.description} - Web Scraping</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <CompaniesParametersTable dataPanel={rowData} type="scrape" />
                                    </AccordionDetails>
                                  </Accordion>                                  
                              </div>                                                                                          

*/