import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BackGround from './background';
import Container from '@material-ui/core/Container';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';
import MaterialTable from 'material-table';
import { id_cliente, ReturnInfoClient } from '../containers/logincontext';
import { API_URL_DISCOUNTS, API_URL_FACTORIES_LIST, API_URL_ORGANIZATIONS_LIST, API_URL_ALLPRODUCTS } from '../config/config';
import { green, red } from '@material-ui/core/colors';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { NAME_APP } from '../config/config';
import Typography from '@material-ui/core/Typography';
import Auth from '../auth/auth';
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from 'moment';
import EditDiscountParameters from './EditDiscountParameters';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DiscountsOrganizationsDetailPanel from './DiscountsOrganizationsDetailPanel';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    alignFab: {
        marginLeft: 'auto',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    img: {
        maxWidth: 'auto',
        maxHeight: '40px',
        justifyContent: 'center'
    },
    red: {
        color: red[500]
    },
    green: {
        color: green[500]

    },
}));

export default function Discounts() {
    const [loading] = React.useState(false);
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [alertseverity, setAlertSeverity] = React.useState("success");
    const [alertmsg, setAlertMsg] = React.useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const [factories, setFactories] = React.useState(null);
    const [productsList, setProductsList] = React.useState([]);

    React.useEffect(() => {

        const url = API_URL_ALLPRODUCTS;
        axios.get(url,
            {
                headers: {
                    'Authorization': 'Bearer ' + Auth.getToken(),
                    'Content-Type': 'application/json'
                }
            }
        ).then(payload => {

            setProductsList(payload.data.products);

        });

    }, []);

    React.useEffect(() => {
        const url = API_URL_FACTORIES_LIST;
        axios.get(url,
            {
                headers: {
                    'Authorization': 'Bearer ' + Auth.getToken(),
                    'Content-Type': 'application/json'
                }
            }
        ).then(payload => {
            let temp = [];
            payload.data.factories.forEach(element => {
                temp.push({ [element.id]: element.description });
            });

            var obj = payload.data.factories.reduce(function (acc, cur, i) {
                acc[cur.id] = cur.description;
                return acc;
            }, {});

            setFactories(obj);


        });

    }, []);

    const [organizationts, setOrganizations] = React.useState([]);

    React.useEffect(() => {
        const url = API_URL_ORGANIZATIONS_LIST;
        axios.get(url,
            {
                headers: {
                    'Authorization': 'Bearer ' + Auth.getToken(),
                    'Content-Type': 'application/json'
                }
            }
        ).then(payload => {


            let temp = [];
            payload.data.organizations.forEach(element => {
                temp.push({ [element.id]: element.description });
            });

            var obj = payload.data.organizations.reduce(function (acc, cur, i) {
                acc[cur.id] = cur.description;
                return acc;
            }, {});

            setOrganizations(obj);

        });

    }, []);

    return (
        <main className={classes.content}>
            <BackGround open={loading} />
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" href="/" >
                        {NAME_APP}
                    </Link>
                    <Typography color="textPrimary">Gestione sconti</Typography>
                </Breadcrumbs>
                <Card>
                    <CardContent>
                        {factories !== null && organizationts !== null &&
                            <MaterialTable
                                title="Gestione sconti"
                                columns={[

                                    { title: 'Descrizione', field: 'description' },
                                    /* { 
                                        title: 'Organizzazione', 
                                        field: 'id_organization',
                                        lookup: organizationts,
                                        render: rowData => organizationts[rowData.id_organization]                                                       
                                                        
                                    }, */
                                    {
                                        title: 'Fabbrica',
                                        field: 'id_factory',
                                        lookup: factories,
                                        render: rowData => factories[rowData.id_factory]

                                    },
                                    {
                                        title: 'Settore',
                                        field: 'product_id',
                                        render: rowData => productsList.filter(data => rowData.product_id === data.id).length > 0 ?
                                            productsList.filter(data => rowData.product_id === data.id)[0].description : "",
                                        editComponent: props => {
                                            return <select value={props.value}
                                                defaultValue={{ label: productsList[0].description, value: productsList[0].id }}
                                                onChange={e => props.onChange(e.target.value)}>
                                                {!Number.isInteger(props.value) &&
                                                    <option value="">Seleziona settore</option>}
                                                {productsList.map((product) =>
                                                    <option value={product.id}>{product.description}</option>)}



                                            </select>
                                        },

                                    },
                                    {
                                        title: 'Valido dal',
                                        field: 'valid_from',
                                        render: rowData => moment(rowData.valid_from).format("DD-MM-YYYY"),
                                        editComponent: props => (
                                            <KeyboardDatePicker
                                                label="Valido dal"
                                                value={props.value}
                                                onChange={e => props.onChange(moment(e).format("YYYY-MM-DD"))}
                                                format='dd/MM/yyyy'
                                                clearLabel="Conferma"
                                                cancelLabel="Annulla"
                                                fullWidth
                                                required
                                                inputVariant="outlined"
                                                margin="normal"
                                            />

                                        )

                                    },
                                    {
                                        title: 'Valido al',
                                        field: 'valid_to',
                                        render: rowData => moment(rowData.valid_to).format("DD-MM-YYYY"),
                                        editComponent: props => (
                                            <KeyboardDatePicker
                                                label="Valido al"
                                                value={props.value}
                                                onChange={e => props.onChange(moment(e).format("YYYY-MM-DD"))}
                                                format='dd/MM/yyyy'
                                                clearLabel="Conferma"
                                                cancelLabel="Annulla"
                                                fullWidth
                                                required
                                                inputVariant="outlined"
                                                margin="normal"
                                            />

                                        )
                                    },
                                    {
                                        title: 'Cumulabile con convenzione',
                                        field: 'cumulative',
                                        lookup: { 1: 'Si', 0: 'No' },

                                    },
                                ]}
                                detailPanel={[
                                    {
                                        tooltip: 'In dettaglio',
                                        /* render: rowData => {
                                            return (<EditDiscountParameters type={1} data={rowData} datatype={productsList.filter(data => rowData.product_id === data.id).length > 0 ? productsList.filter(data => rowData.product_id === data.id)[0].paramurl : ""} />)
                                        }, */
                                        render: rowData => {
                                            return (
                                                <>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel2-header"
                                                        >
                                                            <Typography className={classes.heading}>Dettaglio sconti</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <EditDiscountParameters type={1} data={rowData} datatype={productsList.filter(data => rowData.product_id === data.id).length > 0 ? productsList.filter(data => rowData.product_id === data.id)[0].paramurl : ""} />
                                                        </AccordionDetails>
                                                    </Accordion>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel2-header"
                                                        >
                                                            <Typography className={classes.heading}>Organizzazioni associate</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <DiscountsOrganizationsDetailPanel id_discount={rowData.id} />
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </>
                                            )
                                        },
                                    },
                                ]
                                }
                                localization={{
                                    pagination: {
                                        labelDisplayedRows: '{from}-{to} di {count}',
                                        labelRowsSelect: 'per pagina',
                                        labelRowsPerPage: 'per pagina',
                                        firstAriaLabel: 'prima pagina',
                                        firstTooltip: 'prima pagina',
                                        previousAriaLabel: 'pagina precedente',
                                        previousTooltip: 'pagina precedente',
                                        nextAriaLabel: 'pagina successiva',
                                        nextTooltip: 'pagina successiva',
                                        lastAriaLabel: 'ultima pagina',
                                        lastTooltip: 'ultima pagina',
                                    },
                                    toolbar: {
                                        nRowsSelected: '{0} elementi selezionati',
                                        searchTooltip: 'Ricerca',
                                        searchPlaceholder: 'Ricerca'
                                    },
                                    header: {
                                        actions: ''
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'Nessun record da visualizzare',
                                        filterRow: {
                                            filterTooltip: 'Filtri'
                                        },
                                        editRow: {
                                            saveTooltip: "Conferma",
                                            cancelTooltip: "Annulla",
                                            deleteText: "Sei sicuro di voler cancellare questo prodotto?"
                                        },
                                        addTooltip: "Aggiungi",
                                        deleteTooltip: "Cancella",
                                        editTooltip: "Modifica",
                                    }
                                }}
                                options={{
                                    pageSize: 10,
                                    pageSizeOptions: [5, 10],
                                    paginationType: "stepped",
                                    search: true,
                                    headerStyle: {
                                        backgroundColor: ReturnInfoClient(id_cliente, "primary_color"),
                                        color: '#ffffff',
                                        '&:hover': {
                                            color: '#ffffff',
                                        },
                                        '&:active': {
                                            color: '#ffffff',
                                        }
                                    }

                                }}
                                data={query =>
                                    new Promise((resolve, reject) => {
                                        let url = API_URL_DISCOUNTS;
                                        url += '?per_page=' + query.pageSize
                                        url += '&page=' + (query.page + 1)

                                        if (query.search.length > 3) {
                                            url += '&search=' + query.search
                                        }
                                        if (query.orderBy !== undefined) {
                                            url += '&orderby=' + query.orderBy.field
                                            url += '&orderdirection=' + query.orderDirection
                                        }

                                        const requestOptions = {
                                            headers: {
                                                'Content-Type': 'application/json',
                                                'Authorization': 'Bearer ' + Auth.getToken(),
                                            },
                                        };


                                        fetch(url, requestOptions)
                                            .then(
                                                response => response.json()
                                            )
                                            .then(
                                                result => {
                                                    resolve({
                                                        data: result.data,
                                                        page: result.current_page - 1,
                                                        totalCount: result.total,
                                                    })
                                                })
                                    })
                                }
                                editable={{
                                    onRowAdd: newData =>
                                        new Promise((resolve, reject) => {
                                            const url = API_URL_DISCOUNTS;
                                            axios.put(url, {
                                                description: newData.description,
                                                id_factory: newData.id_factory,
                                                id_organization: newData.id_organization,
                                                discounts_rules: newData.discounts_rules,
                                                valid_from: newData.valid_from,
                                                valid_to: newData.valid_to,
                                                product_id: newData.product_id,
                                                convention_rules: newData.convention_rules,
                                                cumulative: newData.cumulative
                                            },
                                                {
                                                    headers: {
                                                        'Authorization': 'Bearer ' + Auth.getToken(),
                                                        'Content-Type': 'application/json'
                                                    }
                                                }
                                            ).then(payload => {

                                                if (payload.status === 200) {
                                                    setAlertMsg("Inserimento dati avvenuto con successo");
                                                    setAlertSeverity("success");


                                                } else {
                                                    setAlertMsg("Errore durante l'inserimento dati");
                                                    setAlertSeverity("error");
                                                }
                                                setOpen(true);

                                            }).catch((error) => {

                                                setAlertMsg(error.response.data.error);
                                                setAlertSeverity("error");
                                                setOpen(true);
                                            });
                                            resolve();
                                        }),
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise((resolve, reject) => {
                                            const index = oldData.id;
                                            const url = API_URL_DISCOUNTS + "/" + index;
                                            axios.patch(url, {
                                                description: newData.description,
                                                id_factory: newData.id_factory,
                                                id_organization: newData.id_organization,
                                                discounts_rules: newData.discounts_rules,
                                                valid_from: newData.valid_from,
                                                valid_to: newData.valid_to,
                                                product_id: newData.product_id,
                                                convention_rules: newData.convention_rules,
                                                cumulative: newData.cumulative
                                            },
                                                {
                                                    headers: {
                                                        'Authorization': 'Bearer ' + Auth.getToken(),
                                                        'Content-Type': 'application/json'
                                                    }
                                                }
                                            ).then(payload => {
                                                if (payload.status === 200) {
                                                    setAlertMsg("Aggiornamento avvenuto con successo");
                                                    setAlertSeverity("success");
                                                } else {
                                                    setAlertMsg("Errore durante l'aggiornamento");
                                                    setAlertSeverity("error");
                                                }
                                                setOpen(true);
                                            }).catch((error) => {

                                                setAlertMsg(error.response.data.error);
                                                setAlertSeverity("error");
                                                setOpen(true);
                                            });
                                            resolve();
                                        }),
                                    onRowDelete: oldData =>
                                        new Promise((resolve, reject) => {
                                            const index = oldData.id;
                                            const url = API_URL_DISCOUNTS + "/" + index;

                                            axios.delete(url,
                                                {
                                                    headers: {
                                                        'Authorization': 'Bearer ' + Auth.getToken(),
                                                        'Content-Type': 'application/json'
                                                    }
                                                }
                                            ).then(payload => {

                                                if (payload.status === 200) {
                                                    setAlertMsg("Cancellazione avvenuta con successo");
                                                    setAlertSeverity("success");


                                                } else {
                                                    setAlertMsg("Errore durante la cancellazione");
                                                    setAlertSeverity("error");
                                                }
                                                setOpen(true);
                                            }).catch((error) => {

                                                setAlertMsg(error.response.data.error);
                                                setAlertSeverity("error");
                                                setOpen(true);
                                            });
                                            resolve();
                                        }),
                                }}
                            />
                        }
                    </CardContent>
                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity={alertseverity} closeText="Chiudi">
                            {alertmsg}
                        </Alert>
                    </Snackbar>
                </Card>
            </Container>
        </main>
    );
};