import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import HelpIcon from '@material-ui/icons/Help';
import SettingsIcon from '@material-ui/icons/Settings';
import LaunchIcon from '@material-ui/icons/Launch';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Auth from '../auth/auth';
//import {UserDataContext,OrganizationsDataContext,id_cliente,ReturnInfoClient} from '../containers/logincontext';
import { UserDataContext, id_cliente, ReturnInfoClient, PosDataContext } from '../containers/logincontext';
import Link from '@material-ui/core/Link';
//import Auth from '../auth/auth';
import Avatar from '@material-ui/core/Avatar';
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import Box from '@material-ui/core/Box';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
/*import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';*/
import Divider from '@material-ui/core/Divider';
import ApartmentIcon from '@material-ui/icons/Apartment';
import CloudQueueIcon from '@material-ui/icons/CloudQueue';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import StoreIcon from '@material-ui/icons/Store';
import { SUPER_ADMIN, ADMIN } from '../config/config';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import BuildIcon from '@material-ui/icons/Build';
import CropFreeIcon from '@material-ui/icons/CropFree';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import LocalGroceryStoreIcon from '@material-ui/icons/LocalGroceryStore';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import BugReportIcon from '@material-ui/icons/BugReport';
//import PermMediaIcon from '@material-ui/icons/PermMedia';                                
//import AccountTreeIcon from '@material-ui/icons/AccountTree';
import EuroIcon from '@material-ui/icons/Euro';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import CategoryIcon from '@material-ui/icons/Category';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({

  menuIconClass: {
    marginRight: theme.spacing(1),
  },
  img: {
    display: 'block',
    maxWidth: '100%',
    maxHeight: '40px',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  siteLogo: {
    maxHeight: 20,
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  }
}));

//#3f51b5

export default function Header() {
  const [user] = React.useContext(UserDataContext);
  const [posActive] = React.useContext(PosDataContext);

  const classes = useStyles();
  /*const organizations=Auth.getOrganizations();
  const [org,setOrg] = React.useState(OrganizationsDataContext);*/
  const [anchorEl, setAnchorEl] = React.useState(null);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };




  /*
  <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>

          //, open && classes.appBarShift
  */


  /*
  <FormControl className={classes.formControl}>
                <Select          
                id="id_org_active"
                value={org.id}
                onChange={handleChangeOrganization}
              >          
                  {organizations.map((organization) => ( 
                    <MenuItem key={organization.id} value={organization.id}>
                      
                      {organization.logo ===null ? organization.description :
                          <img className={classes.img} alt={organization.description} src={"images/organizations/"+organization.logo} />}
                      </MenuItem>
                  ))                                                                                        
                }
                
              </Select>
              {org.id}
          </FormControl>
  
  */

  React.useEffect(() => {
    let myInterval = setInterval(() => {
      Auth.getToken();
      /*const expires = +localStorage.getItem('app-token-expires');
      const res =  expires - (new Date()) ;        
      const seconds=Math.trunc(res / 1000);                */


    }, 5000)
    return () => {
      clearInterval(myInterval);
    };
  }, []);
  return (
    <>
      <CssBaseline />
      <AppBar style={{ backgroundColor: ReturnInfoClient(id_cliente, "appbar_color") }} position="absolute" className={clsx(classes.appBar)}>
        <Toolbar className={classes.toolbar}>
          <a href="/" >
            <img style={{ height: '48px', width: 'auto', paddingRight: '10px', paddingTop: '5px' }} src={ReturnInfoClient(id_cliente, "logo")} alt="logo" className="siteLogo" />
          </a>
          <Typography component="h1" align="center" color="textPrimary" variant="h4" noWrap className={classes.title}></Typography>
          {posActive !== null && posActive !== undefined &&
            <img style={{ height: '48px', width: 'auto', paddingRight: '10px', paddingTop: '5px' }} src={posActive.active_image} alt="logo" className="siteLogo" />
          }
          <IconButton color={ReturnInfoClient(id_cliente, "color")} onClick={handleClick}>
            <MenuIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem disabled onClick={handleClose}>
              <Box align="center" m={2}>
                <Avatar
                  className={classes.orange}
                >
                  {user.name && user.name.charAt(0)}
                  {user.surname && user.surname.charAt(0)}
                </Avatar>
                <Typography >
                  {user.name} {user.surname}
                </Typography>
              </Box>
            </MenuItem>
            {user.level >= SUPER_ADMIN &&
              <Link href="/account">
                <MenuItem onClick={handleClose}><AccountCircleIcon className={classes.menuIconClass} />Il mio account</MenuItem>
              </Link>
            }
            <Divider />
            <Link href="/quotationlist">
              <MenuItem className={classes.menu} onClick={handleClose}><LocalOfferIcon className={classes.menuIconClass} />Preventivi</MenuItem>
            </Link>
            <Link href="/qrcode">
              <MenuItem className={classes.menu} onClick={handleClose}><CropFreeIcon className={classes.menuIconClass} />Cerca preventivo con QRCode</MenuItem>
            </Link>
            {/* user.level >= ADMIN &&
              <Link href="/quotationlistbydate">
                <MenuItem className={classes.menu} onClick={handleClose}><BugReportIcon className={classes.menuIconClass} />Report preventivi</MenuItem>
              </Link>
            */ }
            {user.level >= ADMIN &&
              <Link href="/quotationlistbydate">
                <MenuItem className={classes.menu} onClick={handleClose}><BugReportIcon className={classes.menuIconClass} />Estrazione report preventivi</MenuItem>
              </Link>
            }
            {user.level === SUPER_ADMIN &&
              <div>
                <Divider />
                <Link href="/goods">
                  <MenuItem className={classes.menu} onClick={handleClose}><StoreIcon className={classes.menuIconClass} />Beni</MenuItem>
                </Link>
                <Link href="/products">
                  <MenuItem className={classes.menu} onClick={handleClose}><LocalGroceryStoreIcon className={classes.menuIconClass} />Settori</MenuItem>
                </Link>
                <Link href="/categories">
                  <MenuItem className={classes.menu} onClick={handleClose}><CategoryIcon className={classes.menuIconClass} />Categorie</MenuItem>
                </Link>
                <Link href="/guarantees">
                  <MenuItem className={classes.menu} onClick={handleClose}><LocalActivityIcon className={classes.menuIconClass} />Garanzie</MenuItem>
                </Link>
                <Link href="/factories">
                  <MenuItem className={classes.menu} onClick={handleClose}><CloudQueueIcon className={classes.menuIconClass} />Fabbriche</MenuItem>
                </Link>
              </div>
            }
            {user.level >= ADMIN &&
              <>
                <Divider />
                <Link href="/companiesparameters">
                  <MenuItem className={classes.menu} onClick={handleClose}><SettingsInputComponentIcon className={classes.menuIconClass} />Connessioni fabbriche</MenuItem>
                </Link>
              </>
            }
            {user.level >= SUPER_ADMIN &&
              <>
                <Link href="/sicania">
                  <MenuItem className={classes.menu} onClick={handleClose}><AccountBalanceWalletIcon className={classes.menuIconClass} />Parametri Ania</MenuItem>
                </Link>
                <Link href="/connectors">
                  <MenuItem className={classes.menu} onClick={handleClose}><LaunchIcon className={classes.menuIconClass} />Connettori</MenuItem>
                </Link>
              </>
            }
            {user.level >= ADMIN &&
              <div>
                <Divider />
                <Link href="/organizations">
                  <MenuItem className={classes.menu} onClick={handleClose}><ApartmentIcon className={classes.menuIconClass} />Organizzazioni</MenuItem>
                </Link>
                <Link href="/groups">
                  <MenuItem className={classes.menu} onClick={handleClose}><GroupWorkIcon className={classes.menuIconClass} />Gruppi</MenuItem>
                </Link>
                <Link href="/agencies">
                  <MenuItem className={classes.menu} onClick={handleClose}><HomeWorkIcon className={classes.menuIconClass} />Punti vendita</MenuItem>
                </Link>
                {user.level >= SUPER_ADMIN &&
                  <Link href="/userslist">
                    <MenuItem className={classes.menu} onClick={handleClose}><PeopleAltIcon className={classes.menuIconClass} />Utenti</MenuItem>
                  </Link>
                }
              </div>
            }
            {/* user.level >= ADMIN &&
              <div>
                <Divider />
                <Link href="/discounts">
                  <MenuItem className={classes.menu} onClick={handleClose}><EuroIcon className={classes.menuIconClass} />Gestione sconti</MenuItem>
                </Link>
                {user.level >= SUPER_ADMIN &&
                  <Link href="/payments">
                    <MenuItem className={classes.menu} onClick={handleClose}><CreditCardIcon className={classes.menuIconClass} />Sistemi pagamento</MenuItem>
                  </Link>
                }
                {user.level >= ADMIN &&
                  <Link href="/conventions">
                    <MenuItem className={classes.menu} onClick={handleClose}><CardMembershipIcon className={classes.menuIconClass} />Convenzioni</MenuItem>
                  </Link>
                }
              </div>
              */}
            {user.level === SUPER_ADMIN &&
              <div>
                <Divider />
                <Link href="/discounts">
                  <MenuItem className={classes.menu} onClick={handleClose}><EuroIcon className={classes.menuIconClass} />Gestione sconti</MenuItem>
                </Link>
                <Link href="/payments">
                  <MenuItem className={classes.menu} onClick={handleClose}><CreditCardIcon className={classes.menuIconClass} />Sistemi pagamento</MenuItem>
                </Link>
                <Link href="/conventions">
                  <MenuItem className={classes.menu} onClick={handleClose}><CardMembershipIcon className={classes.menuIconClass} />Convenzioni</MenuItem>
                </Link>
              </div>
            }
            {user.level === SUPER_ADMIN &&
              <div>
                <Divider />
                <Link href="/">
                  <MenuItem className={classes.menu} onClick={handleClose}>
                    <BuildIcon className={classes.menuIconClass} />
                    Parametri generali
                  </MenuItem>
                </Link>
              </div>
            }
            {
              posActive !== null && posActive !== undefined &&
              posActive.linkuserguide !== undefined &&
              posActive.linkuserguide !== null &&
              posActive.linkuserguide !== "" &&
              <>
                <Divider />
                <Link href={posActive.linkuserguide} target="_blank" rel="noopener">
                  <MenuItem className={classes.menu} onClick={handleClose}>
                    <HelpIcon className={classes.menuIconClass} />
                    Guida all'uso
                  </MenuItem>
                </Link>
              </>
            }
            {user.level >= ADMIN &&
              posActive !== null && posActive !== undefined &&
              posActive.linksetupguide !== undefined &&
              posActive.linksetupguide !== null &&
              posActive.linksetupguide !== "" &&
              <>
                <Link href={posActive.linksetupguide} target="_blank" rel="noopener">
                  <MenuItem className={classes.menu} onClick={handleClose}>
                    <SettingsIcon className={classes.menuIconClass} />
                    Manuale di configurazione
                  </MenuItem>
                </Link>
              </>
            }
            <Divider />
            <Link href="/logout">
              <MenuItem className={classes.menu} onClick={handleClose}>
                <ExitToAppIcon className={classes.menuIconClass} />
                Logout
              </MenuItem>
            </Link>
          </Menu>
        </Toolbar>
      </AppBar>
    </>
  );
}

