import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { id_cliente, ReturnInfoClient } from '../containers/logincontext';
import { API_URL_INFOIMAGES, API_URL_GROUPS_ALL, API_URL_ALLPRODUCTS } from '../config/config';
import { green, red } from '@material-ui/core/colors';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Auth from '../auth/auth';
import Container from '@material-ui/core/Container';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    alignFab: {
        marginLeft: 'auto',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    img: {
        maxWidth: 'auto',
        maxHeight: '40px',
        justifyContent: 'center'
    },
    red: {
        color: red[500]
    },
    green: {
        color: green[500]

    },
}));

export default function InfoImages(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [alertseverity, setAlertSeverity] = React.useState("success");
    const [alertmsg, setAlertMsg] = React.useState(false);
    const [listgroups, setGroups] = React.useState([]);

    const [productsList, setProductsList] = React.useState([]);

    React.useEffect(() => {
        const url = API_URL_ALLPRODUCTS;
        axios.get(url,
            {
                headers: {
                    'Authorization': 'Bearer ' + Auth.getToken(),
                    'Content-Type': 'application/json'
                }
            }
        ).then(payload => {
            let temp = [];
            payload.data.products.forEach(element => {
                temp.push({ [element.id]: element.description });
            });

            var obj = payload.data.products.reduce(function (acc, cur, i) {
                acc[cur.id] = cur.description;
                return acc;
            }, {});

            setProductsList(obj);
        });
    }, []);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();

            fileReader.readAsDataURL(file);
            fileReader.onload = (() => {
                resolve(fileReader.result)
            });

            fileReader.onerror = ((error) => {
                reject(error);
            })
        })
    }

    const uploadImage = async (e, props) => {
        const file = e.target.files[0];

        const base64 = await convertBase64(file);
        props.onChange(base64);
    };

    React.useEffect(() => {
        const url = API_URL_GROUPS_ALL + "/" + props.orgid;
        axios.get(url,
            {
                headers: {
                    'Authorization': 'Bearer ' + Auth.getToken(),
                    'Content-Type': 'application/json'
                }
            }
        ).then(payload => {
            let temp = [];
            payload.data.forEach(element => {
                temp.push({ [element.id]: element.description });
            });

            var obj = payload.data.reduce(function (acc, cur, i) {
                acc[cur.id] = cur.description;
                return acc;
            }, {});

            setGroups(obj)
        });
    }, [props.orgid]);

    return (<>
        <Container maxWidth="lg" className={classes.container}>
            <MaterialTable
                title="Immagini informative"
                columns={[
                    {
                        title: 'Immagine',
                        field: 'image',
                        align: 'center',
                        render: rowData => <img alt={rowData.description} className={classes.img} src={rowData.image} />,
                        editComponent: props => {
                            return <div>
                                <input type="file" onChange={(e) => {
                                    const base64 = uploadImage(e, props);
                                    props.onChange(base64);
                                }} />

                                <img alt="Immagine da caricare" className={classes.img} src={props.value} />

                            </div>
                        }
                    },
                    {
                        title: 'Descrizione',
                        field: 'description'
                    },
                    {
                        title: 'Gruppo',
                        field: 'id_group',
                        lookup: listgroups,
                        render: rowData => listgroups[rowData.id_group]
                    },
                    {
                        title: 'Settore',
                        field: 'product_id',
                        lookup: productsList,
                        render: rowData => productsList[rowData.product_id]

                    },
                    { title: 'Attivo dal', field: 'valid_from' },
                    { title: 'Attivo al', field: 'valid_to' },
                    {
                        title: 'Link',
                        field: 'link'
                    },
                ]}
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} di {count}',
                        labelRowsSelect: 'per pagina',
                        labelRowsPerPage: 'per pagina',
                        firstAriaLabel: 'prima pagina',
                        firstTooltip: 'prima pagina',
                        previousAriaLabel: 'pagina precedente',
                        previousTooltip: 'pagina precedente',
                        nextAriaLabel: 'pagina successiva',
                        nextTooltip: 'pagina successiva',
                        lastAriaLabel: 'ultima pagina',
                        lastTooltip: 'ultima pagina',
                    },
                    toolbar: {
                        nRowsSelected: '{0} elementi selezionati',
                        searchTooltip: 'Ricerca',
                        searchPlaceholder: 'Ricerca'
                    },
                    header: {
                        actions: ''
                    },
                    body: {
                        emptyDataSourceMessage: 'Nessun record da visualizzare',
                        filterRow: {
                            filterTooltip: 'Filtri'
                        },
                        editRow: {
                            saveTooltip: "Conferma",
                            cancelTooltip: "Annulla",
                            deleteText: "Sei sicuro di voler cancellare?"
                        },
                        addTooltip: "Aggiungi",
                        deleteTooltip: "Cancella",
                        editTooltip: "Modifica",
                    }
                }}
                options={{
                    pageSize: 10,
                    pageSizeOptions: [5, 10],
                    paginationType: "stepped",
                    search: true,
                    headerStyle: {
                        backgroundColor: ReturnInfoClient(id_cliente, "primary_color"),
                        color: '#ffffff',
                        '&:hover': {
                            color: '#ffffff',
                        },
                        '&:active': {
                            color: '#ffffff',
                        }
                    }
                }}
                data={query =>
                    new Promise((resolve, reject) => {
                        let url = API_URL_INFOIMAGES + "/" + props.orgid;
                        url += '?per_page=' + query.pageSize
                        url += '&page=' + (query.page + 1)

                        if (query.search.length > 3) {
                            url += '&search=' + query.search
                        }
                        if (query.orderBy !== undefined) {
                            url += '&orderby=' + query.orderBy.field
                            url += '&orderdirection=' + query.orderDirection
                        }

                        const requestOptions = {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + Auth.getToken(),
                            },
                        };

                        fetch(url, requestOptions)
                            .then(
                                response => response.json()
                            )
                            .then(
                                result => {
                                    resolve({
                                        data: result.data,
                                        page: result.current_page - 1,
                                        totalCount: result.total,
                                    })
                                })
                    })
                }
                editable={{
                    onRowAdd: newData =>
                        new Promise((resolve, reject) => {
                            const url = API_URL_INFOIMAGES + "/" + props.orgid;
                            axios.put(url, {
                                description: newData.description,
                                id_organization: newData.id_organization,
                                id_group: newData.id_group,
                                product_id: newData.product_id,
                                valid_from: newData.valid_from,
                                valid_to: newData.valid_to,
                                image: newData.image,
                                link: newData.link
                            },
                                {
                                    headers: {
                                        'Authorization': 'Bearer ' + Auth.getToken(),
                                        'Content-Type': 'application/json'
                                    }
                                }
                            ).then(payload => {
                                if (payload.status === 200) {
                                    setAlertMsg("Inserimento dati avvenuto con successo");
                                    setAlertSeverity("success");
                                } else {
                                    setAlertMsg("Errore durante l'inserimento dati");
                                    setAlertSeverity("error");
                                }
                                setOpen(true);
                            }).catch((error) => {
                                setAlertMsg(error.response.data.error);
                                setAlertSeverity("error");
                                setOpen(true);
                            });
                            resolve();
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            const index = oldData.id;
                            const url = API_URL_INFOIMAGES + "/" + index;
                            axios.patch(url, {
                                description: newData.description,
                                id_organization: newData.id_organization,
                                id_group: newData.id_group,
                                valid_from: newData.valid_from,
                                valid_to: newData.valid_to,
                                product_id: newData.product_id,
                                image: newData.image,
                                link: newData.link
                            },
                                {
                                    headers: {
                                        'Authorization': 'Bearer ' + Auth.getToken(),
                                        'Content-Type': 'application/json'
                                    }
                                }
                            ).then(payload => {
                                if (payload.status === 200) {
                                    setAlertMsg("Aggiornamento avvenuto con successo");
                                    setAlertSeverity("success");
                                } else {
                                    setAlertMsg("Errore durante l'aggiornamento");
                                    setAlertSeverity("error");
                                }
                                setOpen(true);
                            }).catch((error) => {
                                setAlertMsg(error.response.data.error);
                                setAlertSeverity("error");
                                setOpen(true);
                            });
                            resolve();
                        }),
                    onRowDelete: oldData =>
                        new Promise((resolve, reject) => {
                            const index = oldData.id;
                            const url = API_URL_INFOIMAGES + "/" + index;

                            axios.delete(url,
                                {
                                    headers: {
                                        'Authorization': 'Bearer ' + Auth.getToken(),
                                        'Content-Type': 'application/json'
                                    }
                                }
                            ).then(payload => {
                                if (payload.status === 200) {
                                    setAlertMsg("Cancellazione avvenuta con successo");
                                    setAlertSeverity("success");
                                } else {
                                    setAlertMsg("Errore durante la cancellazione");
                                    setAlertSeverity("error");
                                }
                                setOpen(true);
                            }).catch((error) => {
                                setAlertMsg(error.response.data.error);
                                setAlertSeverity("error");
                                setOpen(true);
                            });
                            resolve();
                        }),
                }}
            />


            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={alertseverity}>
                    {alertmsg}
                </Alert>
            </Snackbar>
        </Container>
    </>

    );
};