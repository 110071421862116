import React from 'react';
import {makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import { green, red } from '@material-ui/core/colors';
import {API_URL_ALLGOODS,API_URL_AGENCY} from '../config/config';
import axios   from 'axios';


import Auth from '../auth/auth';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditDiscountsData from './EditDiscountsData';
import EditPaymentsData from './EditPaymentsData';
import EditConventionsData from './EditConventionsData';
import EditFactoriesProperties from './EditFactoriesProperties';


const useStyles = makeStyles((theme) => ({
    root:{
        width:"100%"
    },
    alignFab: {
        marginLeft: 'auto',
      },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),      
    },
    img: {    
        display: 'block',
        maxWidth: '100%',
        maxHeight: '40px',
        minWidth: '50px'
    },

    img_service: {    
        display: 'block',
        maxWidth: '40px',
        maxHeight: '20px',
    },
    red: {
        color: red[500],        
    },
    green: {
        color: green[500],        
    },
    hiddenDiv:{
        display:'none'
    }
}));



export default function UserAgencyDetailPanel(props) {
    const classes = useStyles();       
    const [goods,setGoods]= React.useState([]);    
    const [agency,setAgency]= React.useState(null);    
     
    const agency_id=props.agency.substring('ag_'.length);

    React.useEffect(() => {                
      const url=API_URL_AGENCY;
          axios.get(url+"/"+agency_id,
          {
              headers:{
              'Authorization': 'Bearer '+Auth.getToken(),
              'Content-Type': 'application/json'
              }
          }
      ).then(payload=>{                       
        setAgency(payload.data.agency);                                   
      }) ;        
              
    },[agency_id]);      

    React.useEffect(() => {                
      const url=API_URL_ALLGOODS;
          axios.get(url,
          {
              headers:{
              'Authorization': 'Bearer '+Auth.getToken(),
              'Content-Type': 'application/json'
              }
          }
      ).then(payload=>{                       
        setGoods(payload.data.goods);                                   
      }) ;        
              
    },[]);      
      
    




    return (
    <div className={classes.root}>
    
      {goods.map((good,index) => (
          <Accordion key={index} disabled={!good.enabled}>
              <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel2-header"
              >
              <Typography className={classes.heading}>Fabbriche abilitate: {good.description}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                  {agency!==null &&
                    <EditFactoriesProperties user={props.user} type="usr" datagroups={agency} good={good}/>                                                    
                  }
              </AccordionDetails>
              
          </Accordion>
          ))
        }

                  {agency!==null &&
                      <>
                      <Accordion>
                            <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                              >
                                  <Typography >Sconti</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <EditDiscountsData type="usr" id={props.user} orgid={agency.orgid} idag={agency.id} idgrp={agency.id_group}/>
                              </AccordionDetails>     
                      </Accordion>

                      <Accordion>
                            <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                              >
                                  <Typography >Convenzioni</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <EditConventionsData type="usr" id={props.user} orgid={agency.orgid} idag={agency.id} idgrp={agency.id_group}/>
                              </AccordionDetails>     
                      </Accordion>

                      
                      <Accordion>
                          <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel2-header"
                          >
                              <Typography className={classes.heading}>Sistemi di pagamento</Typography>
                          </AccordionSummary>
                          <AccordionDetails>                                                        
                              <EditPaymentsData type="usr" id={props.user}  orgid={agency.orgid}/>
                          </AccordionDetails>                                                        
                      </Accordion>
                      </>
                  }
        
      </div>
    );
};

