import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { Link as RouterLink } from 'react-router-dom'
import {NAME_APP} from '../config/config';



const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  paper:{
      textAlign:"center"
  },
  
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),      
  },
  img: {            
      maxWidth: 'auto',
      maxHeight: '40px',
      justifyContent: 'center'
  },
  cardContentClass:{
    height:'150px'
  }
  
}));

export default function ProductSelect() {
  const classes = useStyles();

  return (
    <main className={classes.content}>                        
            <div className={classes.appBarSpacer} />            
            <Container maxWidth="lg" className={classes.container}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" href="/" >
                      {NAME_APP}
                    </Link>                    
                    
                </Breadcrumbs>
                
                <Grid container spacing={3}>
                      <Grid item xs={4}>
                        <Link underline='none' component={RouterLink} to='/'>
                        <Card className={classes.root}>
                          <CardActionArea>
                            <CardMedia
                              component="img"
                              alt="Preventivo auto"
                              height="140"
                              image="images/products/auto.jpg"
                              title="Preventivo auto"
                            />
                            <CardContent className={classes.cardContentClass}>
                              <Typography gutterBottom variant="h5" component="h2">
                                Preventivo auto
                              </Typography>
                              
                              <Typography  variant="body2" color="textSecondary" component="p">
                              Rinnovo polizza in scadenza o appena scaduta, prima immatricolazione o Voltura al PRA
                              </Typography>
                              
                            </CardContent>
                          </CardActionArea>
                          <CardActions>                      
                              <Button size="small" color="primary">
                                Nuovo preventivo
                              </Button>
                            </CardActions>
                          </Card>
                          </Link>
                      </Grid>
                      <Grid item xs={4}>
                        <Link underline='none' component={RouterLink} to='/'>
                        <Card className={classes.root}>
                          <CardActionArea>
                            <CardMedia
                              component="img"
                              alt="Preventivo polizza Vita"
                              height="140"
                              image="images/products/vita.jpg"
                              title="Preventivo polizza Vita"
                            />
                            <CardContent className={classes.cardContentClass}>
                              <Typography gutterBottom variant="h5" component="h2">
                                Preventivo polizza Vita
                              </Typography>
                              <Typography variant="body2" color="textSecondary" component="p">
                              Preventivo polizza Vita
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                          <CardActions>                      
                              <Button size="small" color="primary">
                                Nuovo preventivo
                              </Button>
                            </CardActions>
                          </Card>
                          </Link>
                      </Grid>
                      <Grid item xs={4}>
                        <Link underline='none' component={RouterLink} to='/'>
                        <Card className={classes.root}>
                          <CardActionArea>
                            <CardMedia
                              component="img"
                              alt="Preventivo casa"
                              height="140"
                              image="images/products/casa.jpg"
                              title="Preventivo casa"
                            />
                            <CardContent className={classes.cardContentClass}>
                              <Typography gutterBottom variant="h5" component="h2">
                                Preventivo auto
                              </Typography>
                              <Typography variant="body2" color="textSecondary" component="p">
                              Polizza casa
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                          <CardActions>                      
                              <Button size="small" color="primary">
                                Nuovo preventivo
                              </Button>
                            </CardActions>
                          </Card>
                          </Link>
                      </Grid>
                  </Grid>
                  
            </Container>                      
        </main>
  );
}