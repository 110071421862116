import React, { useEffect, useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Auth from '../auth/auth';
import { UserDataContext, id_cliente, ReturnInfoClient } from '../containers/logincontext';
import { Helmet } from "react-helmet";
import Typography from '@material-ui/core/Typography';
import { Redirect } from 'react-router';
import { Skeleton } from '@material-ui/lab';
import { useParams } from "react-router-dom";




const useStyles = makeStyles((theme) => ({

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalpaper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ConfirmLogin(pars) {
  const classes = useStyles();
  const [, setUser] = React.useContext(UserDataContext);
  const [toHome, setToHome] = useState(false);
  //const [toRegister,setToRegister]=useState(false);

  let { check_token } = useParams();
  let { id } = useParams();
  let { codice } = useParams();

  const [validationmsg, setvalidationmsg] = useState(<div>
    <Typography variant="h6" >
      Verifica login in corso...
    </Typography>
    <Skeleton variant="rect" width={210} height={118} />
  </div>
  );

  useEffect(() => {
    Auth.verifytoken(check_token).then(
      payload => {
        Auth.signinAppWith810(payload.user.login, payload.user.email, payload.company.code).then(payload => {

          setUser(payload.user);
          setTimeout(() => setToHome(true, 5000));
        }).catch(error => {
          //setTimeout(()=>setToRegister(true,5000));
          setvalidationmsg(<div>
            <Typography variant="h6" >
              Utente non abilitato, richiedere attivazione
            </Typography>
          </div>)
        });
      }).catch(error => {
        setvalidationmsg(<div>
          <Typography variant="h6" >
            Utente non abilitato, richiedere attivazione
          </Typography>
        </div>)
      }

      );
  }, [check_token, setUser, codice, id]);

  /* https://810-dev.finital.it/php7/web/ph810run?appName=AnalisiSinistri&appUrl=http://localhost:3000/&livsottomenu=m_sinistri */

  return (
    <Container component="main" maxWidth="xs">

      {toHome ? <Redirect to={id === undefined ? '/' : '/?codice=' + codice + "&id=" + id + "&checktoken=" + check_token} /> : null}
      <Helmet>
        <title>Conferma Login | {ReturnInfoClient(id_cliente, "nome_cliente")}</title>
      </Helmet>
      <CssBaseline />
      <div className={classes.paper}>

        <div>
          <img src={ReturnInfoClient(id_cliente, "logo")} alt={ReturnInfoClient(id_cliente, "nome_cliente")} className="siteLogo" />
        </div>

        <div>
          {validationmsg}
        </div>
      </div>
    </Container>
  );



}

